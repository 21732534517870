import {BooleanDecoder, Data, field, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {CartItem} from './CartItem'

export class OrderItem extends CartItem {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    // Status booleans
    @field({decoder: BooleanDecoder})
    produced = false

    @field({decoder: BooleanDecoder})
    delivered = false

    // Status booleans
    @field({
        decoder: BooleanDecoder, version: 45, upgrade: function (this: OrderItem) {
            return this.produced
        },
    })
    printed = false

    /**
     * ID of the user that is preparing this order
     * We use null because vue is not detecting undefined changes
     * This is only set for top level items (= items with parentId undefined).
     * Sub-items that have a parentId will always be printed by the main-product device.
     */
    @field({decoder: StringDecoder, optional: true})
    @field({decoder: StringDecoder, nullable: true, version: 7, upgrade: (v) => v ?? null, downgrade: (v) => v ?? undefined})
    deviceId: string | null = null

    static decode(data: Data): OrderItem {
        return super.decode(data) as OrderItem
    }
}

export const OrderItemPatch = OrderItem.patchType()
