import {ArrayDecoder, AutoEncoder, field} from '@simonbackx/simple-encoding'

import {Cart} from './Cart'
import {IDCartItem} from './IDCartItem'

export class IDCart extends AutoEncoder {
    /**
     * Returns all top-level items (not including possible sub-items that are linked to them)
     */
    @field({decoder: new ArrayDecoder(IDCartItem)})
    items: Array<IDCartItem>

    /**
     * Returns all sub-items that are linked to parent cartItems
     * eg:
     * "Product pizzamenu (pizzaA+drinkA)" === mainItem (with option <Option>pizzaA and <Option>drinkA that have productId set to <Product>pizzaA.id & <Product>drinkA.id)
     * "pizzaA & drinkA" === subItems (these will have the cartItem.parentId set to mainItem.id)
     * Linked means that one of the options of the original mainItem references the linkedItem.product via mainItem.optiongroups.option.productLink
     * We will have 3 items in the order (1main & 2 linkedItems)
     */
    @field({decoder: new ArrayDecoder(IDCartItem), optional: true})
    subItems: Array<IDCartItem> = []

    /**
     * Returns all items in cart (including sub-items that are linked to parent cartItems)
     */
    get allItems(): Array<IDCartItem> {
        return this.items.concat(this.subItems)
    }

    /**
     * @param cartItemId - Id of the cartItem you want to retrieve subItems for
     */
    getSubItemsForCartItemId(cartItemId: string): Array<IDCartItem> {
        return this.subItems.filter(el => el.parentId === cartItemId)
    }

    /**
     * Returns an array with all items + their subItems (menu-products)
     */
    get bundledItems(): Array<{
        item: IDCartItem
        subItems: Array<IDCartItem>
    }> {
        return this.items.map(item => {
            return {
                item,
                subItems: this.getSubItemsForCartItemId(item.cartItemId),
            }
        })
    }

    static from(cart: Cart): IDCart {
        return IDCart.create({
            items: cart.items.map(i => IDCartItem.from(i)),
            subItems: cart.subItems.map(i => IDCartItem.from(i)),
        })
    }
}
