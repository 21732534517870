





























import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import DRSTBoxItem from '../DRSTBoxItem.vue'

@Component({
    components: {
        DRSTBoxItem,
    },
})
export default class TextareaRow extends Mixins(NavigationMixin) {
    @Prop({})
    value!: string

    @Prop({default: ''})
    label!: string

    @Prop({default: ''})
    autocomplete!: string

    @Prop({default: ''})
    placeholder!: string

    @Prop({default: 'text'})
    type!: string

    @Prop({default: false})
    disabled!: boolean

    hasFocus = false

    get text() {
        return this.value
    }

    set text(value: string) {
        this.$emit('input', value)
    }

    onFocus() {
        this.hasFocus = true
    }

    blur() {
        this.hasFocus = false
        this.text = this.text.trim();
        (this.$refs.input as any)?.blur()
    }

    focus() {
        (this.$refs.input as any)?.focus()
    }
}
