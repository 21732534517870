
























import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import DRSTBoxItem from '@dorst/components/src/DRSTBoxItem.vue'
import DRSTFloatingFooter from '@dorst/components/src/DRSTFloatingFooter.vue'
import DRSTFooterBox from '@dorst/components/src/DRSTFooterBox.vue'
import DRSTNavigationBar from '@dorst/components/src/DRSTNavigationBar.vue'
import Spinner from '@dorst/components/src/Spinner.vue'
import {LandingRedirectInfo} from '@dorst/structures'

@Component({
    components: {
        DRSTNavigationBar,
        DRSTBoxItem,
        DRSTFloatingFooter,
        DRSTFooterBox,
        Spinner,
    },
})
export default class ShopNotFoundView extends Mixins(NavigationMixin) {
    @Prop()
    error!: Error

    @Prop()
    redirectData?: LandingRedirectInfo

    get hasRedirect() {
        return this.redirectData?.redirectUrl ? true : false
    }

    redirectBack() {
        if (!this.redirectData) {
            return
        }
        window.location.assign(this.redirectData.redirectUrl)
    }
}
