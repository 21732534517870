var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ContextMenu",
    _vm._b({}, "ContextMenu", { x: _vm.x, y: _vm.y }, false),
    _vm._l(_vm.options, function(option) {
      return _c(
        "DRSTBoxItem",
        {
          key: option.label,
          attrs: { selectable: true },
          on: {
            click: function($event) {
              return _vm.callback(option.callback)
            }
          }
        },
        [
          _vm._v("\n        " + _vm._s(option.label) + "\n        "),
          _vm.selected && option.value && _vm.selected.includes(option.value)
            ? _c("template", { slot: "right" }, [
                _c("img", {
                  attrs: {
                    src: require("@dorst/assets/icons/primary/check-line.svg")
                  }
                })
              ])
            : _vm._e()
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }