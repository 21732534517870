import {ArrayDecoder, AutoEncoder, BooleanDecoder, field, PatchType, StringDecoder} from '@simonbackx/simple-encoding'

import {IDCategory} from './IDCategory'
import {IDShopConsumer} from './IDShopConsumer'
import {Image} from './Image'
import {Language, TranslatedString, TranslatedStringDecoder, TranslatedStringPatch} from './Language'
import {Product} from './Product'

export class Category extends AutoEncoder {
    @field({decoder: StringDecoder})
    id: string

    @field({decoder: StringDecoder})
    @field({
        decoder: TranslatedStringDecoder,
        version: 56,
        upgrade: (str: string): TranslatedString => {
            return new TranslatedString({
                [Language.Dutch]: str,
            })
        },
        downgrade: (name: TranslatedString): string => {
            return name.getForLanguage(Language.Dutch)
        },
        upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
            if (!str) {
                return new TranslatedStringPatch({})
            }
            return new TranslatedStringPatch({[Language.Dutch]: str})
        },
        downgradePatch: (name: PatchType<TranslatedString>): PatchType<string> => {
            if (name[Language.Dutch]) {
                return name[Language.Dutch] ?? ''
            }
            return undefined
        },
    })
    name = new TranslatedString({})

    @field({decoder: TranslatedStringDecoder, version: 69, upgrade: () => new TranslatedString({})})
    description = new TranslatedString({})

    @field({decoder: Image, nullable: true})
    image: Image | null = null

    @field({decoder: new ArrayDecoder(Product)})
    products: Array<Product>

    @field({decoder: new ArrayDecoder(Category)})
    categories: Array<Category>

    /** If true the menu will not be shown on the consumer side. (used for products linked to options in a menu) */
    @field({decoder: BooleanDecoder, optional: true})
    isOptionProductsMenu = false

    defaultName(lang: Language): string {
        switch (lang) {
            case Language.Dutch:
                return 'Naamloos'
            case Language.French:
                return 'Sans titre'
            case Language.English:
                return 'Unnamed'
            default:
                return 'Unnamed'
        }
    }

    defaultNameForI18n(i18n: {locale: string}) {
        let lang = i18n.locale.substr(0, 2)
        if (!(Object.values(Language) as Array<string>).includes(lang)) {
            lang = Language.English
        }
        return this.defaultName(lang as Language)
    }

    getName(lang: Language): string {
        if (this.name.isEmpty()) {
            return this.defaultName(lang)
        }
        return this.name.getForLanguage(lang)
    }

    getNameForI18n(i18n: {locale: string}): string {
        if (this.name.isEmpty()) {
            return this.defaultNameForI18n(i18n)
        }
        return this.name.getForI18n(i18n as any)
    }

    static fromID(category: IDCategory, shop: IDShopConsumer) {
        // First create a reference so we can use the reference in infinite category cycles
        const cat = Category.create({
            id: category.id,
            name: category.name,
            description: category.description,
            isOptionProductsMenu: category.isOptionProductsMenu,
            products: category.productIds.flatMap((id) => {
                const p = shop.products.find(p => p.id == id)
                if (p !== undefined && p.visibleIn(shop.timezone)) {
                    return [p]
                }
                return []
            }),
            categories: [],
            image: category.image,
        });

        // Save the reference to prevent infinite loop
        (category as any).tmpCategory = cat

        // get the categories
        cat.categories = category.categoryIds.flatMap((id) => {
            const c = shop.categories.find(c => c.id == id)
            if (c && (c as any).tmpCategory) {
                return (c as any).tmpCategory as Category
            }
            if (c) {
                const cat = Category.fromID(c, shop)
                if (cat.products.length > 0 || cat.categories.length > 0) {
                    return [cat]
                }
            }
            return []
        })

        // Clear the reference
        delete (category as any).tmpCategory

        // Filter
        if (cat.products.length == 0 && cat.categories.length == 1) {
            // Flatten!
            cat.products = cat.categories[0].products
            cat.categories = cat.categories[0].categories
        }

        return cat
    }
}
