



















import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {ContextMenu, DRSTBoxItem} from '@dorst/components'

@Component({
    components: {
        ContextMenu,
        DRSTBoxItem,
    },
})
export default class SimpleContextMenu extends Mixins(NavigationMixin) {
    @Prop({default: 0})
    x!: number

    @Prop({default: 0})
    y!: number

    @Prop()
    selected?: Array<string>

    @Prop({required: true})
    options!: Array<{label: string; value?: string; callback?: () => void}>

    callback(callback?: () => void) {
        this.pop()

        if (callback) {
            callback()
        }
    }
}
