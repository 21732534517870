import {AutoEncoder, field, StringDecoder} from '@simonbackx/simple-encoding'

export class CashlessInfo extends AutoEncoder {
    @field({decoder: StringDecoder})
    paymentMethod: string

    @field({decoder: StringDecoder})
    card: string

    @field({decoder: StringDecoder, nullable: true})
    validation: string | null = null
}
