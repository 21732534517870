import {AutoEncoder, EnumDecoder, field} from '@simonbackx/simple-encoding'

export enum PaymentStatus {
    AwaitingConfirmation = 'AwaitingConfirmation',
    Cancelled = 'Cancelled',
    Failed = 'Failed',
    Pending = 'Pending',
    Processing = 'Processing',
    Refunded = 'Refunded',
    Succeeded = 'Succeeded',
    Timeout = 'Timeout',
}

export class PaymentStatusResponseWrapper extends AutoEncoder {
    @field({decoder: new EnumDecoder(PaymentStatus)})
    paymentStatus: PaymentStatus
}
