import {AutoEncoder, BooleanDecoder, EnumDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {TranslatedString, TranslatedStringDecoder} from './Language'

export enum TagTarget {
    SHOP = 'shop',
    PRODUCT = 'product',
}

export enum TagType {
    FILTER = 'filter',
    TAG = 'tag',
}

export enum TagKey {
    /* Note: this is a hidden tag meant for use only by POS product syncing */
    Allergens = 'Allergens',
    Almonds = 'Almonds',
    Barley = 'Barley',
    BrazilNuts = 'BrazilNuts',
    Cashew = 'Cashew',
    Celery = 'Celery',
    Crustaceans = 'Crustaceans',
    Egg = 'Egg',
    Fish = 'Fish',
    /* Note: this is a hidden tag meant for use only by POS product syncing */
    Gluten = 'Gluten',
    Hazelnuts = 'Hazelnuts',
    Lactose = 'Lactose',
    Lupin = 'Lupin',
    MacadamiaNuts = 'MacadamiaNuts',
    Molluscs = 'Molluscs',
    Mustard = 'Mustard',
    /* Note: this is a hidden tag meant for use only by POS product syncing */
    Nuts = 'Nuts',
    Oats = 'Oats',
    Peanuts = 'Peanuts',
    Pecans = 'Pecans',
    Pistachios = 'Pistachios',
    Rye = 'Rye',
    Sesame = 'Sesame',
    Soy = 'Soy',
    Spelt = 'Spelt',
    Sulphite = 'Sulphite',
    Walnuts = 'Walnuts',
    Wheat = 'Wheat',
}

export class IDTag extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: TranslatedStringDecoder})
    name = new TranslatedString({})
}

export class Tag extends IDTag {
    @field({decoder: new EnumDecoder(TagType)})
    type: TagType

    @field({decoder: new EnumDecoder(TagTarget)})
    target: TagTarget
}

export class TagBackoffice extends Tag {
    @field({decoder: BooleanDecoder, optional: true})
    enabled = true

    @field({decoder: BooleanDecoder, optional: true})
    hidden = false
}

export const TagPatch = Tag.patchType()
export const TagBackofficePatch = TagBackoffice.patchType()
