import {
    CartItem,
    Category,
    OrderConsumer,
    PaymentMethod,
    Product,
} from '@dorst/structures'

export interface AnalyticsEvents {
    onAddProductToCart: (cartItem: CartItem) => void
    onCartView: () => void
    onCategoryView: (category: Category, products: Array<Product>) => void
    onHomepageView: (categories: Array<Category>) => void
    onHomepageViewWithSegmentedControl: (products: Array<Product>) => void
    onInitiateCheckout: () => void
    onOrderConfirmation: (order: OrderConsumer) => void
    onProductView: (cartItem: CartItem) => void
    onRemoveFromCart: (cartItem: CartItem) => void
    onSelectPaymentMethod: (info: PaymentMethod) => void
    revokeConsent: () => void
}

export abstract class AnalyticProcessor implements AnalyticsEvents {
    abstract name: string
    abstract grantConsent(): Promise<void>
    abstract isConfigured(): boolean
    abstract onAddProductToCart(cartItem: CartItem): void
    abstract onCartView(): void
    abstract onCategoryView(category: Category, products: Array<Product>): void
    abstract onHomepageView(categories: Array<Category>): void
    abstract onHomepageViewWithSegmentedControl(products: Array<Product>): void
    abstract onInitiateCheckout(): void
    abstract onOrderConfirmation(order: OrderConsumer): void
    abstract onProductView(cartItem: CartItem): void
    abstract onRemoveFromCart(cartItem: CartItem): void
    abstract onSelectPaymentMethod(info: PaymentMethod): void
    abstract revokeConsent(): void
}
