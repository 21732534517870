import {ArrayDecoder, AutoEncoder, field, StringDecoder} from '@simonbackx/simple-encoding'

import {DITable} from './DigitinTable'

export class DIFloor extends AutoEncoder {
    @field({decoder: StringDecoder})
    floorplanname: string

    @field({decoder: StringDecoder})
    groupID: string

    @field({decoder: StringDecoder})
    floorID: string

    @field({decoder: new ArrayDecoder(DITable), optional: true, defaultValue: () => []})
    tables: Array<DITable>
}
