












import {ComponentWithProperties, FramedComponent, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import LoadingCover from './LoadingCover.vue'

/**
 * This component will automatically show the root if we have a valid token. If the user logs out, we'll automatically show the login view
*/
@Component({
    components: {
        FramedComponent,
        LoadingCover,
    },
})
export default class ResolvePromiseView extends Mixins(NavigationMixin) {
    @Prop({required: true})
    promise: () => Promise<any>

    @Prop({default: null})
    propertyName: string | null

    @Prop({default: false})
    multipleTimes: boolean

    @Prop({default: null})
    root: ComponentWithProperties | null

    resolved = false

    @Prop()
    failureHandler: (e: Error) => {} | undefined

    mounted() {
        this.run()
    }

    activated() {
        if (this.multipleTimes) {
            this.run()
        }
    }

    run() {
        this.resolved = false
        this.promise().then((value) => {
            if (this.propertyName && this.root) {
                if (this.root.properties.root) {
                    this.root.properties.root.properties[this.propertyName] = value
                } else {
                    this.root.properties[this.propertyName] = value
                }
            }

            this.resolved = true
        }).catch(e => {
            if (this.failureHandler) {
                this.failureHandler(e)
            }
        })
    }

    async shouldNavigateAway(): Promise<boolean> {
        if (!this.root) {
            return true
        }
        console.log(this.root)
        return await this.root.shouldNavigateAway()
    }
}
