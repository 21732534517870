



















import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

import {isRtl} from '@dorst/frontend-translations'

@Component({
})
export default class SegmentedControl extends Vue {
    selectedIndex = 0
    @Prop({type: Array})
    items!: Array<any>
    @Prop({default: null})
    labels!: Array<string> | null

    @Prop()
    value!: any

    @Watch('value')
    onValueChanged(val: any, oldVal: any) {
        for (const [index, item] of this.items.entries()) {
            if (item === val) {
                this.selectedIndex = index
                return
            }
        }
    }

    get isRtl(): boolean {
        return isRtl()
    }

    mounted() {
        this.selectedIndex = this.items.indexOf(this.value)
    }

    selectItem(index) {
        this.selectedIndex = index
        this.$emit('input', this.items[index])
    }
}
