




























import {Component, Prop, Vue} from 'vue-property-decorator'

import ClickedOutsideDirective from './ClickedOutsideDirective'

@Component({
    directives: {
        clickedOutside: ClickedOutsideDirective,
    },
})

export default class AvailabilityLabel extends Vue {
    @Prop({required: true, type: String})
    productId: string

    @Prop({required: true, type: Array})
    availabilityTexts: Array<string>

    collapsed: boolean = false

    onClickedOutside() {
        this.collapsed = false
    }

    toggleCollapse() {
        this.collapsed = !this.collapsed
    }
}
