var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view" },
    [
      _c("DRSTNavigationBar", { attrs: { title: _vm.categoryName } }, [
        _vm.canPop
          ? _c("button", {
              staticClass: "button icon back",
              attrs: { slot: "left" },
              on: { click: _vm.pop },
              slot: "left"
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "main",
        [
          _c("h1", [_vm._v(_vm._s(_vm.categoryName))]),
          _vm._v(" "),
          _vm.categoryDescription
            ? _c("p", {
                staticClass: "category-description",
                domProps: { textContent: _vm._s(_vm.categoryDescription) }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("OfferCard"),
          _vm._v(" "),
          _vm.categories.length > 0
            ? _c(
                "DRSTList",
                _vm._l(_vm.categories, function(category) {
                  return _c(
                    "DRSTBoxItem",
                    {
                      key: category.id,
                      attrs: { selectable: true },
                      on: {
                        click: function($event) {
                          return _vm.goToCategory(category)
                        }
                      }
                    },
                    [
                      _c("p", { staticClass: "style-row-title-1" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.getCategoryName(category)) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("button", {
                        staticClass: "button icon disclosure",
                        attrs: { slot: "right" },
                        slot: "right"
                      })
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.products.length > 0
            ? _c(
                "DRSTBox",
                _vm._l(_vm.products, function(product) {
                  return _c("ProductBoxItem", {
                    key: product.id,
                    attrs: { product: product },
                    on: {
                      click: function($event) {
                        return _vm.goToProduct(product)
                      }
                    }
                  })
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.shop.disclaimer
        ? _c("div", {
            staticClass: "disclaimer",
            domProps: { textContent: _vm._s(_vm.shop.disclaimer) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.CartManager.cart.items.length > 0 &&
      _vm.shop.enableOrdering &&
      _vm.CartManager.orderingAllowedForCustomerType
        ? _c("DRSTFloatingFooter", [
            _c(
              "button",
              {
                staticClass: "button primary fill columns",
                on: { click: _vm.next }
              },
              [
                _c("span", { staticClass: "icon-shadow" }, [
                  _c("span", { staticClass: "icon cart" })
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("customer.products.openCart")))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "counter" }, [
                  _vm._v(_vm._s(_vm.CartManager.cart.getCount()))
                ])
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }