var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progress-bar" }, [
    _c("div", {
      staticClass: "progress-bar--filler",
      style: "width: " + _vm.normalisedPercentage + "%;"
    }),
    _vm._v(" "),
    _vm.showValue
      ? _c("div", { staticClass: "progress-bar--value" }, [
          _vm._v("\n        " + _vm._s(_vm.normalisedPercentage) + "%\n    ")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }