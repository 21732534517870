import {AutoEncoder, field, NumberDecoder, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {SortHelper} from './SortHelper'

export function generateBoolPermutations(n: number): Array<Array<boolean>> {
    const total = Math.pow(2, n)

    const perms = Array.from({length: total}, () => [] as Array<boolean>)

    for (let i = 0; i < n; i++) {
        const pow = Math.pow(2, i)
        const toggles = Math.pow(2, n - i)

        let value = false
        for (let j = 0; j < toggles; j++) {
            for (let k = 0; k < pow; k++) {
                perms[j * pow + k].unshift(value)
            }

            value = !value
        }
    }

    return perms
}

export class OptimalFillItem extends AutoEncoder {
    @field({decoder: StringDecoder, optional: true})
    id = uuidv4()

    @field({decoder: NumberDecoder})
    value: number
}

export class OptimalFill {
    static calcPerm(items: Array<OptimalFillItem>, max: number): Array<boolean> {
        const perms = generateBoolPermutations(items.length).reverse()

        const sum = (items: Array<OptimalFillItem>, perm: Array<boolean>) => {
            return items.reduce((total, item, idx) => perm[idx] ? total + item.value : total, 0)
        }

        // If all fit return all
        if (sum(items, perms[0]) <= max) {
            return Array.from({length: items.length}, () => true)
        }

        // If none fit return none
        if (!items.some((item) => item.value <= max)) {
            return Array.from({length: items.length}, () => false)
        }

        // Remove all true and all false cases
        perms.shift()
        perms.pop()

        let bestCase = Array.from({length: items.length}, () => false)
        let bestValue = 0

        // Sort items so largest is first
        const sortedItems = [...items].sort((a, b) => {
            return b.value - a.value
        })

        for (const perm of perms) {
            const value = sum(sortedItems, perm)

            if (value > max) {
                continue
            }

            if (value > bestValue) {
                bestCase = perm
                bestValue = value
            }
        }

        const sortedIndexMap = new Map(sortedItems.map((item, idx) => [item.id, idx]))
        const indices = items.map(item => sortedIndexMap.get(item.id) ?? -1)

        return SortHelper.restore(bestCase, indices)
    }

    static calc(items: Array<OptimalFillItem>, max: number): Array<OptimalFillItem> {
        const bestCase = this.calcPerm(items, max)

        return items.filter((_, idx) => bestCase[idx])
    }
}
