export enum CompanyLegalEntity {
    Unknown = 'Unknown', // Eenmanszaak
    SoleProprietorship = 'SoleProprietorship', // Eenmanszaak
    PrivateLimitedCompany = 'PrivateLimitedCompany', // BV
    PublicLimitedCompany = 'PublicLimitedCompany', // NV
    GeneralPartnership = 'GeneralPartnership', // VOF
    LimitedLiabilityCooperative = 'LimitedLiabilityCooperative', // CVBA/CV
    LimitedPartnership = 'LimitedPartnership', // Comm. V
    NonprofitAssociation = 'NonprofitAssociation', // VZW
    Vereniging = 'Vereniging',
    SARL = 'SARL',
    EURL = 'EURL',
    SAS = 'SAS',
    EBVBA = 'EBVBA',
    SCA = 'SCA',
    SCS = 'SCS',
    CivilCompany = 'CivilCompany',
    Partnership = 'Partnership',
    NGO = 'NGO',
}

interface i18n {
    t: (key: string, options?: any) => string
}

export class CompanyLegalEntityHelper {
    static getName(entity: CompanyLegalEntity, i18n: i18n) {
        switch (entity) {
            case CompanyLegalEntity.Unknown:
                return i18n.t('companyLegalEntities.unknown')
            case CompanyLegalEntity.SoleProprietorship:
                return i18n.t('companyLegalEntities.soleProprietorship')
            case CompanyLegalEntity.PrivateLimitedCompany:
                return i18n.t('companyLegalEntities.privateLimitedCompany')
            case CompanyLegalEntity.PublicLimitedCompany:
                return i18n.t('companyLegalEntities.publicLimitedCompany')
            case CompanyLegalEntity.GeneralPartnership:
                return i18n.t('companyLegalEntities.generalPartnership')
            case CompanyLegalEntity.LimitedLiabilityCooperative:
                return i18n.t('companyLegalEntities.limitedLiabilityCooperative')
            case CompanyLegalEntity.LimitedPartnership:
                return i18n.t('companyLegalEntities.limitedPartnership')
            case CompanyLegalEntity.NonprofitAssociation:
                return i18n.t('companyLegalEntities.nonprofitAssociation')
            case CompanyLegalEntity.Vereniging:
                return i18n.t('companyLegalEntities.vereniging')
            case CompanyLegalEntity.SARL:
                return i18n.t('companyLegalEntities.sarl')
            case CompanyLegalEntity.EURL:
                return i18n.t('companyLegalEntities.eurl')
            case CompanyLegalEntity.SAS:
                return i18n.t('companyLegalEntities.sas')
            case CompanyLegalEntity.EBVBA:
                return i18n.t('companyLegalEntities.ebvba')
            case CompanyLegalEntity.SCA:
                return i18n.t('companyLegalEntities.sca')
            case CompanyLegalEntity.SCS:
                return i18n.t('companyLegalEntities.scs')
            case CompanyLegalEntity.CivilCompany:
                return i18n.t('companyLegalEntities.civilCompany')
            case CompanyLegalEntity.Partnership:
                return i18n.t('companyLegalEntities.partnerShip')
            case CompanyLegalEntity.NGO:
                return i18n.t('companyLegalEntities.ngo')
            default: {
                const t: never = entity
                throw new Error(`Unknown legal entity: ${t}`)
            }
        }
    }
}
