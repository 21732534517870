var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view order-view" },
    [
      _c("DRSTNavigationBar", {
        attrs: { title: _vm.$t("customer.order.placed") }
      }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "message" },
        [
          _c(
            "div",
            {
              staticClass: "message-wrapper",
              class: {
                "hide-background-image": _vm.hideBgImageOnOrderConfirmedMessage
              }
            },
            [
              _c("h1", [_vm._v(_vm._s(_vm.$t("customer.order.title")))]),
              _vm._v(" "),
              _vm.customMessage
                ? _c("p", { staticStyle: { "white-space": "pre-line" } }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.customMessage) +
                        "\n            "
                    )
                  ])
                : _c(
                    "p",
                    [
                      _vm.order.consumptionMode ===
                      _vm.ConsumptionOptionType.DineIn
                        ? [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("customer.order.text")) +
                                "\n                "
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.order.consumptionMode ===
                      _vm.ConsumptionOptionType.TakeAway
                        ? [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("customer.order.textTakeAway")) +
                                "\n                "
                            )
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.order.consumptionMode ===
                      _vm.ConsumptionOptionType.Delivery
                        ? [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("customer.order.textDelivery")) +
                                "\n                "
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
              _vm._v(" "),
              _vm.innovendPickupInfo !== undefined
                ? _c(
                    "DRSTBox",
                    {
                      staticClass: "innovend-pickup-info",
                      attrs: {
                        title: _vm.$t("customer.order.innovend.pickupInfo")
                      }
                    },
                    [
                      _c("DRSTBoxItem", [
                        _c("p", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$t("customer.order.innovend.lockerLabel")
                              ) +
                              ":\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { attrs: { slot: "right" }, slot: "right" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.innovendPickupInfo.lockerName) +
                              "\n                    "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("DRSTBoxItem", [
                        _c("p", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(
                                _vm.$t("customer.order.innovend.codeLabel")
                              ) +
                              ":\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", { attrs: { slot: "right" }, slot: "right" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.innovendPickupInfo.code) +
                              "\n                    "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("DRSTBoxItem", [
                        _c("img", {
                          staticClass: "innovend-qr-code",
                          attrs: {
                            src: _vm.innovendPickupInfo.qrCode,
                            alt: _vm.$t("customer.order.innovend.qrCode")
                          }
                        })
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "show-details-button",
                  on: { click: _vm.showDetails }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("customer.order.detailsButton")) +
                      "\n            "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("OfferCard")
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "DRSTFooterBox",
        [
          _c("DRSTBoxItem", [
            _c("p", { staticClass: "table-label" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("customer.order.number")) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "order-view-order-nr",
                attrs: { slot: "right" },
                slot: "right"
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.order.orderNumber) +
                    "\n            "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _vm.order.table
            ? _c("DRSTBoxItem", [
                _c("p", { staticClass: "table-label" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.tableName) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "table-label",
                    attrs: { slot: "right" },
                    slot: "right"
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.tablePath) +
                        "\n            "
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.order.deliveryDate
            ? _c("DRSTBoxItem", [
                _c("p", { staticClass: "table-label" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.order.deliveryAddress
                          ? _vm.$t("general.deliveryDate")
                          : _vm.$t("general.pickupDate")
                      ) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _vm.isToday
                  ? _c("p", { attrs: { slot: "right" }, slot: "right" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("time")(_vm.order.deliveryDate)) +
                          " – " +
                          _vm._s(
                            _vm._f("time")(
                              _vm.addInterval(_vm.order.deliveryDate)
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  : _c("p", { attrs: { slot: "right" }, slot: "right" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm._f("dateTimeShort")(_vm.order.deliveryDate)
                          ) +
                          " – " +
                          _vm._s(
                            _vm._f("time")(
                              _vm.addInterval(_vm.order.deliveryDate)
                            )
                          ) +
                          "\n            "
                      )
                    ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.order.deliveryAddress
            ? _c("DRSTBoxItem", { staticClass: "delivery-address-box" }, [
                _c("p", { staticClass: "table-label" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("customer.details.deliveryAddressTitle")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c("p", { attrs: { slot: "right" }, slot: "right" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.order.deliveryAddress.toString()) +
                      "\n            "
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("DRSTBoxItem", [
            _c("p", { staticClass: "table-label" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.$t("general.orderAmount")) +
                  "\n            "
              )
            ]),
            _vm._v(" "),
            _c("p", { attrs: { slot: "right" }, slot: "right" }, [
              _vm._v(
                "\n                " +
                  _vm._s(
                    _vm._f("price")(_vm.orderAmount, { forceDisplay: true })
                  ) +
                  "\n            "
              )
            ])
          ]),
          _vm._v(" "),
          _vm.cardID
            ? _c("DRSTBoxItem", [
                _c("p", { staticClass: "table-label" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("general.balance")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { attrs: { slot: "right" }, slot: "right" },
                  [
                    _vm.cardBalance
                      ? [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm._f("price")(_vm.cardBalance, {
                                  forceDisplay: true
                                })
                              ) +
                              "\n                "
                          )
                        ]
                      : _c("Spinner")
                  ],
                  2
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("DRSTFloatingFooter", [
        _c(
          "button",
          { staticClass: "button primary fill", on: { click: _vm.close } },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("customer.order.button")) +
                "\n        "
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }