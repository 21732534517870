










































































import {
    ComponentWithProperties,
    HistoryManager,
    NavigationController,
    NavigationMixin,
} from '@simonbackx/vue-app-navigation'
import {Meta} from '@sophosoft/vue-meta-decorator'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import {TealiumManager} from '../classes/TealiumManager'
import {environment} from '../environments/environment'
import LanguageSwitcher from './LanguageSwitcher.vue'
import ProductBoxItem from './ProductBoxItem.vue'
import ShopView from './ShopView.vue'
import {
    DRSTBox,
    DRSTBoxItem,
    DRSTExternalLinksFooter,
    DRSTFloatingFooter,
    DRSTList,
    DRSTNavigationBar,
    LoadingCover,
} from '@dorst/components'
import {Category, ConsumptionOptionType, ShopFull} from '@dorst/structures'

@Component({
    components: {
        DRSTNavigationBar,
        DRSTList,
        DRSTBox,
        DRSTBoxItem,
        ProductBoxItem,
        DRSTFloatingFooter,
        DRSTExternalLinksFooter,
        LoadingCover,
        LanguageSwitcher,
    },
})
export default class ShopSplashView extends Mixins(NavigationMixin) {
    @Meta
    getMetaInfo() {
        return {
            title: ServerManager.shop.name,
        }
    }

    @Prop()
    callback?: (shop: ShopFull) => void

    ServerManager = ServerManager
    CartManager = CartManager

    ConsumptionOptionType = ConsumptionOptionType

    lastSync = new Date()
    selectedCategorySaved: Category | null = null

    mounted() {
        HistoryManager.setUrl(`/${ServerManager.shop.uri}`)
    }

    async activated() {
        HistoryManager.setUrl(`/${ServerManager.shop.uri}`)

        if (environment.features.tealium) {
            void TealiumManager.onHomepageView()
        }

        if (this.lastSync <= new Date(new Date().getTime() - 60 * 1000)) {
            this.lastSync = new Date()
            await ServerManager.loadShop().catch(console.error)
        }
    }

    beforeDestroy() {
        if (this.callback) {
            this.callback(ServerManager.shop)
        }
    }

    get shop() {
        return this.ServerManager.shop
    }

    get logo() {
        return this.shop.personalisation?.logo?.getPathForSize(undefined, 70 * (window.devicePixelRatio ?? 1))
    }

    get hideLogoOnSplashScreen(): boolean {
        return this.shop.personalisation?.hideLogoOnSplashScreen ?? false
    }

    get logoIncludesShopName(): boolean {
        return this.shop.personalisation?.logoIncludesShopName ?? false
    }

    get allConsumptionOptionsEnabled(): boolean {
        return this.dineInEnabled && this.takeAwayEnabled && this.deliveryEnabled
    }

    get hasExternalLinksEnabled(): boolean {
        return this.shop.externalLinks?.enabled ?? false
    }

    get dineInEnabled(): boolean {
        return this.shop.consumptionOptions.dineIn.enabled
    }

    get takeAwayEnabled(): boolean {
        return this.shop.consumptionOptions.takeAway.enabled
    }

    get deliveryEnabled(): boolean {
        return this.shop.consumptionOptions.delivery.enabled
    }

    selectMode(mode: ConsumptionOptionType) {
        if (this.CartManager.getConsumptionMode() !== mode) {
            console.info('Cart deleted because consumption mode doesn\'t match current consumptionMode')
            this.CartManager.clear()
        }
        this.CartManager.setConsumptionMode(mode)
        this.show(new ComponentWithProperties(NavigationController, {root: new ComponentWithProperties(ShopView)}))
    }
}
