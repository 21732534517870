/** Seperate file because of circular dependencies. */
import {ArrayDecoder, field} from '@simonbackx/simple-encoding'

import {Product} from './Product'
import {Upsell, UpsellGroup} from './Upsell'

export class UpsellWithProduct extends Upsell {
    @field({decoder: Product})
    product: Product
}

export class UpsellGroupWithProducts extends UpsellGroup {
    @field({decoder: new ArrayDecoder(UpsellWithProduct)})
    upsells: Array<UpsellWithProduct> = []
}
