import {ArrayDecoder, AutoEncoder, BooleanDecoder, field, PatchType, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {CategoryMeta} from './CategoryMeta'
import {Image} from './Image'
import {Language, TranslatedString, TranslatedStringDecoder, TranslatedStringPatch} from './Language'

export class IDCategory extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: StringDecoder})
    @field({
        decoder: TranslatedStringDecoder,
        version: 56,
        upgrade: (str: string): TranslatedString => {
            return new TranslatedString({
                [Language.Dutch]: str,
            })
        },
        downgrade: (name: TranslatedString): string => {
            return name.getForLanguage(Language.Dutch)
        },
        upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
            if (!str) {
                return new TranslatedStringPatch({})
            }
            return new TranslatedStringPatch({[Language.Dutch]: str})
        },
        downgradePatch: (name: PatchType<TranslatedString>): PatchType<string> => {
            if (name[Language.Dutch]) {
                return name[Language.Dutch] ?? ''
            }
            return undefined
        },
    })
    name: TranslatedString = new TranslatedString({})

    @field({decoder: BooleanDecoder, version: 4})
    enabled = true

    @field({decoder: TranslatedStringDecoder, version: 69, defaultValue: () => new TranslatedString({}), upgrade: () => new TranslatedString({})})
    description = new TranslatedString({})

    @field({decoder: new ArrayDecoder(StringDecoder)})
    productIds: Array<string> = []

    @field({decoder: new ArrayDecoder(StringDecoder)})
    categoryIds: Array<string> = []

    @field({decoder: Image, nullable: true, optional: true})
    image: Image | null = null

    /** If true the menu will not be shown on the consumer side. (used for products linked to options in a menu) */
    @field({decoder: BooleanDecoder, optional: true})
    isOptionProductsMenu = false

    @field({decoder: CategoryMeta, optional: true})
    meta = CategoryMeta.create({})

    getAvailableLanguages(): Array<Language> {
        return Object.keys(this.name.translations) as Array<Language>
    }

    getName(lang: Language): string {
        if (this.name.isEmpty()) {
            return this.defaultName(lang)
        }
        return this.name.getForLanguage(lang)
    }

    getNameForI18n(i18n: {locale: string}): string {
        if (this.name.isEmpty()) {
            return this.defaultNameForI18n(i18n)
        }
        return this.name.getForI18n(i18n as any)
    }

    defaultName(lang: Language): string {
        switch (lang) {
            case Language.Dutch:
                return 'Naamloos'
            case Language.French:
                return 'Sans titre'
            case Language.English:
                return 'Unnamed'
            default:
                return 'Unnamed'
        }
    }

    defaultNameForI18n(i18n: {locale: string}) {
        let lang = i18n.locale.substr(0, 2)
        if (!(Object.values(Language) as Array<string>).includes(lang)) {
            lang = Language.English
        }
        return this.defaultName(lang as Language)
    }
}

export const IDCategoryPatch = IDCategory.patchType()
