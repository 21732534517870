







































import {Decoder} from '@simonbackx/simple-encoding'
import {ComponentWithProperties, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins} from 'vue-property-decorator'

import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import InnovendPollingView from './InnovendPollingView.vue'
import OrderPosValidationPollingView from './OrderPosValidationPollingView.vue'
import OrderView from './OrderView.vue'
import ShopView from './ShopView.vue'
import DRSTBoxItem from '@dorst/components/src/DRSTBoxItem.vue'
import DRSTFloatingFooter from '@dorst/components/src/DRSTFloatingFooter.vue'
import DRSTFooterBox from '@dorst/components/src/DRSTFooterBox.vue'
import DRSTNavigationBar from '@dorst/components/src/DRSTNavigationBar.vue'
import Spinner from '@dorst/components/src/Spinner.vue'
import {OrderConsumer, OrderResponse, PaymentStatus} from '@dorst/structures'

@Component({
    components: {
        DRSTNavigationBar,
        DRSTBoxItem,
        DRSTFloatingFooter,
        DRSTFooterBox,
        Spinner,
    },
})
export default class OrderPollView extends Mixins(NavigationMixin) {
    order: OrderConsumer | null = null
    pollCount = 0
    timer: any = null

    get paidUsingCashless(): boolean {
        return this.order?.paymentMethod.isCashless() ?? false
    }

    mounted() {
        this.timer = setTimeout(this.poll.bind(this), 3000 + Math.min(10 * 1000, this.pollCount * 1000))
    }

    poll() {
        this.timer = null
        const orderId = new URL(window.location.href).searchParams.get('id')
        ServerManager.getServer()
            .request({
                method: 'POST',
                path: `/consumer/shops/${ServerManager.shop.id}/order/${orderId}/exchange`,
                decoder: OrderResponse as Decoder<OrderResponse>,
            }).then(({data}) => {
                const order = data.order
                if (order.paymentStatus == PaymentStatus.Succeeded) {
                    // navigate to order success view instead of doing in this view
                    const navigation = this.navigationController
                    history.replaceState({}, ServerManager.shop?.name ?? '', `/${ServerManager.shop?.uri}` ?? '')
                    if (data.innovendValidationRequired) {
                        this.present(new ComponentWithProperties(
                            InnovendPollingView,
                            {
                                order: data.order,
                            },
                        ))
                    } else if (data.posValidationRequired) {
                        this.present(new ComponentWithProperties(OrderPosValidationPollingView, {order: data.order}))
                    } else {
                        this.present(new ComponentWithProperties(OrderView, {order: data.order}))
                    }
                    window.setTimeout(() => {
                        navigation?.push(new ComponentWithProperties(ShopView, {shop: ServerManager.shop}), false, 1, true)
                    }, 350)
                } else {
                    this.order = order
                }
            }).catch(e => {
                // todo: handle this
                if (e.type === 'abort') {
                    return
                }

                console.error(e)
            }).finally(() => {
                this.pollCount++
                if (this.order && (this.order.paymentStatus == PaymentStatus.Succeeded || this.order.paymentStatus == PaymentStatus.Failed)) {
                    return
                }
                this.timer = setTimeout(this.poll.bind(this), 3000 + Math.min(10 * 1000, this.pollCount * 1000))
            })
    }

    beforeDestroy() {
        if (this.timer) {
            clearTimeout(this.timer)
            this.timer = null
        }
    }

    close() {
        if (!ServerManager.shop) {
            return
        }
        // Push and replace
        history.replaceState({}, ServerManager.shop.name, `/${ServerManager.shop.uri}`)
        this.navigationController?.push(new ComponentWithProperties(ShopView, {shop: ServerManager.shop}), true, 1, true)
    }

    get isPending(): boolean {
        return this.order?.paymentStatus === PaymentStatus.Pending
            || this.order?.paymentStatus === PaymentStatus.Processing
    }
}
