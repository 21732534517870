import {ArrayDecoder, AutoEncoder, EnumDecoder, field, IntegerDecoder, NumberDecoder, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {I18n} from './I18n'

export class DeliveryLocationHelper {

    static transformDistance(
        amount: number,
        fromUnit: DeliveryRangeUnit,
        toUnit: DeliveryRangeUnit,
    ): number {
        if (fromUnit === toUnit) {
            return amount
        }

        return amount / DeliveryRange.convertToMetersMap[fromUnit] * DeliveryRange.convertToMetersMap[toUnit]
    }

    static getTypeTranslation(type: DeliveryLocationType, i18n: I18n) {
        switch (type) {
            case DeliveryLocationType.PostalCodes:
                return i18n.t('backoffice.settings.deliveryLocationType.postalCodes')
            case DeliveryLocationType.Ranges:
                return i18n.t('backoffice.settings.deliveryLocationType.ranges')
            default: {
                const t: never = type
                throw new Error(`Could not get translation for unknown Delivery location type ${t}`)
            }
        }
    }

    static getDeliveryRangeUnitShort(type: DeliveryRangeUnit, i18n: I18n) {
        switch (type) {
            case DeliveryRangeUnit.km:
                return i18n.t('backoffice.settings.deliveryRangeUnit.km')
            case DeliveryRangeUnit.m:
                return i18n.t('backoffice.settings.deliveryRangeUnit.m')
            default: {
                const t: never = type
                throw new Error(`Unknown Delivery range unit ${t}`)
            }
        }
    }
}

export enum DeliveryLocationType {
    PostalCodes = 'Postalcodes',
    Ranges = 'Ranges',
}

export enum DeliveryRangeUnit {
    km = 'km',
    m = 'm',
}

export class PostalCode extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: StringDecoder})
    code: string
}

export class DeliveryRange extends AutoEncoder {

    static convertToMetersMap: {[k in DeliveryRangeUnit]: number} = {
        km: 0.001,
        m: 1,
    }

    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: NumberDecoder})
    amount: number = 0

    @field({decoder: new EnumDecoder(DeliveryRangeUnit)})
    unit: DeliveryRangeUnit = DeliveryRangeUnit.km

    transformAmount(to: DeliveryRangeUnit): number {
        return DeliveryLocationHelper.transformDistance(this.amount, this.unit, to)
    }
}

export const PostalCodePatch = PostalCode.patchType()

export class DeliveryLocation extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: new EnumDecoder(DeliveryLocationType), optional: true})
    type: DeliveryLocationType = DeliveryLocationType.PostalCodes

    @field({decoder: new ArrayDecoder(PostalCode)})
    postalCodes: Array<PostalCode> = []

    @field({decoder: DeliveryRange, optional: true})
    range: DeliveryRange = DeliveryRange.create({})

    @field({decoder: NumberDecoder, defaultValue: () => 15})
    minDeliveryTime: number

    @field({decoder: IntegerDecoder, defaultValue: () => 0})
    price: number

    @field({decoder: IntegerDecoder, defaultValue: () => 0})
    minOrderAmount: number

    @field({decoder: StringDecoder, nullable: true, optional: true})
    productId: string | null = null
}

export const DeliveryLocationPatch = DeliveryLocation.patchType()
