var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view" },
    [
      _c(
        "DRSTNavigationBar",
        { attrs: { title: _vm.$t("customer.order.detailTitle") } },
        [
          _c("button", {
            staticClass: "button icon back",
            attrs: { slot: "left" },
            on: { click: _vm.pop },
            slot: "left"
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "main",
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("customer.order.detailTitle")))]),
          _vm._v(" "),
          _c("OrderDetailBox", { attrs: { order: _vm.order } })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }