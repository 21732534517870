import {ArrayDecoder, AutoEncoder, field, IntegerDecoder, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {CartItem, CartItemVariant} from './CartItem'
import {IDCartItemOption} from './IDCartItemOption'

export class IDCartItemVariant extends AutoEncoder {
    /*
    * Id of the chosen variant
    */
    @field({decoder: StringDecoder})
    variantId: string

    /**
     * Options that the customer has chosen
    */
    @field({decoder: new ArrayDecoder(IDCartItemOption)})
    options: Array<IDCartItemOption>

    static from(item: CartItemVariant): IDCartItemVariant {
        return IDCartItemVariant.create({
            variantId: item.variantId,
            options: item.options.map(option => IDCartItemOption.from(option)),
        })
    }
}

export class IDCartItem extends AutoEncoder {
    @field({decoder: StringDecoder, optional: true})
    cartItemId: string = uuidv4() // should be the same as cartItem so that the option-product link does not break.

    @field({decoder: StringDecoder})
    productId: string

    /**
     * Used for option-products (menu item)
     * Will contain the ID of the parent cartitem
     * */
    @field({decoder: StringDecoder, optional: true})
    parentId?: string

    @field({decoder: IDCartItemVariant, version: 75})
    selectedVariantInfo: IDCartItemVariant

    @field({decoder: IntegerDecoder, defaultValue: () => 1})
    amount = 1

    static from(item: CartItem): IDCartItem {
        return IDCartItem.create({
            cartItemId: item.id,
            parentId: item.parentId,
            productId: item.product.id,
            selectedVariantInfo: IDCartItemVariant.from(item.selectedVariantInfo),
            amount: item.amount,
        })
    }
}
