var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.elementName,
    {
      tag: "component",
      staticClass: "drst-box-item",
      class: Object.assign({}, _vm.$attrs.class, {
        selectable: _vm.selectable
      }),
      on: {
        click: function($event) {
          _vm.$el.focus()
          _vm.$emit("click", $event)
        }
      }
    },
    [
      _c("div", { staticClass: "left" }, [_vm._t("left")], 2),
      _vm._v(" "),
      _c("div", { staticClass: "main" }, [
        _c("div", [
          _c("div", { staticClass: "middle" }, [_vm._t("default")], 2),
          _vm._v(" "),
          _c("div", { staticClass: "right" }, [_vm._t("right")], 2)
        ]),
        _vm._v(" "),
        _c("hr")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }