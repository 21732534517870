var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view delivery-address-view" },
    [
      _c(
        "DRSTNavigationBar",
        {
          attrs: {
            title: _vm.title
              ? _vm.title
              : _vm.$t("customer.details.deliveryAddressTitle")
          }
        },
        [
          _vm.canPop
            ? _c("button", {
                staticClass: "button icon back",
                attrs: { slot: "left" },
                on: { click: _vm.pop },
                slot: "left"
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.canDismiss
            ? _c("button", {
                staticClass: "button icon close",
                attrs: { slot: "left" },
                on: { click: _vm.dismiss },
                slot: "left"
              })
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "main",
        [
          _c("h1", [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.title
                    ? _vm.title
                    : _vm.$t("customer.details.deliveryAddressTitle")
                ) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("DRSTAddressMatrixInputBox", {
            attrs: {
              matrix: _vm.addressMatrix,
              domain: _vm.ServerManager.domain,
              "default-country": _vm.editAddress.country
            },
            model: {
              value: _vm.editAddress,
              callback: function($$v) {
                _vm.editAddress = $$v
              },
              expression: "editAddress"
            }
          }),
          _vm._v(" "),
          _vm._l(_vm.errors, function(error, idx) {
            return _c(
              "RemarkBlock",
              { key: "address-error-" + idx, attrs: { type: "error" } },
              [_vm._v("\n            " + _vm._s(error) + "\n        ")]
            )
          }),
          _vm._v(" "),
          _c("SpinnerButton", { attrs: { loading: _vm.saving } }, [
            _c(
              "button",
              { staticClass: "button primary fill", on: { click: _vm.save } },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("general.save")) +
                    "\n            "
                )
              ]
            )
          ])
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }