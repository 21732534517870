























import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {DRSTBoxItem} from '@dorst/components'
import {OrderItem} from '@dorst/structures'

@Component({
    components: {
        DRSTBoxItem,
    },
})
export default class OrderItemRow extends Mixins(NavigationMixin) {

    @Prop({required: true, type: OrderItem})
    item: OrderItem

    @Prop({required: true, type: Array})
    subItems: Array<OrderItem>

    get name() {
        return this.item.product.getNameForI18n(this.$i18n as any)
    }

    getOptionPrice() {
        return this.item.getPrices(this.subItems).price
    }

    getOptionDescription(): string {
        return this.item.optionDescriptionForI18n(this.subItems, this.$i18n as any)
    }
}
