var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view" },
    [
      _c(
        "DRSTNavigationBar",
        { attrs: { title: _vm.$t("customer.details.title") } },
        [
          _c("button", {
            staticClass: "button icon back",
            attrs: { slot: "left" },
            on: { click: _vm.pop },
            slot: "left"
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "main",
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("customer.details.title")))]),
          _vm._v(" "),
          _vm.error
            ? _c("RemarkBlock", { attrs: { type: "error" } }, [
                _c("p", [_vm._v(_vm._s(_vm.error))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "DRSTBox",
            [
              _vm.nameRequired
                ? [
                    !_vm.shouldSplitName
                      ? _c("TextInputRow", {
                          attrs: {
                            minlength: "2",
                            autocomplete: "name",
                            placeholder: _vm.$t(
                              "customer.details.namePlaceholder"
                            )
                          },
                          model: {
                            value: _vm.name,
                            callback: function($$v) {
                              _vm.name = $$v
                            },
                            expression: "name"
                          }
                        })
                      : [
                          _c("TextInputRow", {
                            attrs: {
                              minlength: "2",
                              autocomplete: "given-name",
                              placeholder: _vm.$t(
                                "customer.details.firstNamePlaceholder"
                              )
                            },
                            model: {
                              value: _vm.firstName,
                              callback: function($$v) {
                                _vm.firstName = $$v
                              },
                              expression: "firstName"
                            }
                          }),
                          _vm._v(" "),
                          _c("TextInputRow", {
                            attrs: {
                              minlength: "2",
                              autocomplete: "family-name",
                              placeholder: _vm.$t(
                                "customer.details.lastNamePlaceholder"
                              )
                            },
                            model: {
                              value: _vm.lastName,
                              callback: function($$v) {
                                _vm.lastName = $$v
                              },
                              expression: "lastName"
                            }
                          })
                        ]
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.emailRequired
                ? _c("TextInputRow", {
                    attrs: {
                      autocomplete: "email",
                      type: "email",
                      placeholder: _vm.$t(
                        "backoffice.onboarding.emailPlaceholder"
                      )
                    },
                    model: {
                      value: _vm.email,
                      callback: function($$v) {
                        _vm.email = $$v
                      },
                      expression: "email"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.phoneRequired
                ? _c("TelephoneRow", {
                    attrs: {
                      "default-country-code": _vm.defaultCountry,
                      placeholder: _vm.$t("customer.details.phonePlaceholder")
                    },
                    model: {
                      value: _vm.phone,
                      callback: function($$v) {
                        _vm.phone = $$v
                      },
                      expression: "phone"
                    }
                  })
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _vm.isDineInMode && _vm.phoneRequired && _vm.enableVoiceNotifications
            ? _c("RemarkBlock", [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("customer.details.phoneRequiredDescription"))
                  )
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.addressRequired
            ? [
                _c("AddressBookBox", {
                  attrs: {
                    title: _vm.$t("customer.details.deliveryAddressTitle")
                  },
                  on: {
                    select: function($event) {
                      _vm.address = $event.address
                      _vm.location = $event.location
                    }
                  }
                }),
                _vm._v(" "),
                _c("RemarkBlock", [
                  _c("div", [
                    _c("h4", { staticClass: "delivery-location--header" }, [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.deliveryDescriptionTitle) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      { staticClass: "delivery-location--descriptions" },
                      _vm._l(_vm.deliveryDescriptions, function(
                        deliveryLocationDescription
                      ) {
                        return _c("li", [
                          _c(
                            "div",
                            { staticClass: "delivery-location--items" },
                            [
                              _vm._l(
                                _vm.getItemsToShowForDeliveryLocationDescription(
                                  deliveryLocationDescription
                                ),
                                function(item) {
                                  return _c(
                                    "span",
                                    {
                                      class: {
                                        active:
                                          _vm.address !== null &&
                                          item === _vm.address.postalCode
                                      }
                                    },
                                    [_vm._v(_vm._s(item))]
                                  )
                                }
                              ),
                              _vm._v(" "),
                              deliveryLocationDescription.expandable &&
                              !_vm.isDeliveryLocationCollapsed(
                                deliveryLocationDescription.id
                              )
                                ? _c("span", [_vm._v("...")])
                                : _vm._e()
                            ],
                            2
                          ),
                          _vm._v(" "),
                          deliveryLocationDescription.expandable
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleDeliveryLocationDescription(
                                        deliveryLocationDescription
                                      )
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        _vm.isDeliveryLocationCollapsed(
                                          deliveryLocationDescription.id
                                        )
                                          ? _vm.$t(
                                              "customer.details.hideMorePostalCodes"
                                            )
                                          : _vm.$t(
                                              "customer.details.showMorePostalCodes"
                                            )
                                      ) +
                                      "\n                            "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("br"),
                          _vm._v(" "),
                          _c(
                            "span",
                            { staticStyle: { "white-space": "pre-line" } },
                            [
                              _vm._v(
                                _vm._s(
                                  deliveryLocationDescription.feeAndMinAmount
                                )
                              )
                            ]
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ])
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("DRSTFloatingFooter", [
        _c(
          "button",
          {
            staticClass: "button primary fill",
            on: {
              click: function($event) {
                return _vm.goToNext()
              }
            }
          },
          [
            _vm.isNextTimeSlot
              ? [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.isTakeAwayMode
                          ? _vm.$t("customer.details.selectTimeSlotButton")
                          : _vm.$t(
                              "customer.details.selectDeliveryTimeSlotButton"
                            )
                      ) +
                      "\n            "
                  )
                ]
              : [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.tableButtonText) +
                      "\n            "
                  )
                ]
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }