import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {ServerManager} from '../classes/ServerManager'
import {OpeningHourHelper} from '../helpers/OpeningHourHelper'
import {PruneHelper} from '../helpers/PruneHelper'
import {
    CartItem,
    CartItemOption,
    I18n,
    Option,
    OptionAmountType,
    OptionGroup,
    OptionGroupMode,
    Product,
    Resolution,
} from '@dorst/structures'

@Component
export class ProductViewMixin extends Mixins(NavigationMixin) {
    OptionAmountType = OptionAmountType

    @Prop({required: true, type: CartItem})
    cartItem!: CartItem

    @Prop({required: false, type: Array})
    subCartItems: Array<CartItem>

    editCartItem = this.cartItem.clone()
    editSubCartItems: Array<CartItem> = []
    hasTriedToAdd = false

    forceUpdateCanOrder = 0
    checkOpeningHours: ReturnType<typeof OpeningHourHelper.getStartStop>

    mounted() {
        this.editSubCartItems = this.subCartItems ?? []
        this.checkOpeningHours = OpeningHourHelper.getStartStop(() => this.forceUpdateCanOrder++)
        this.checkOpeningHours.start()
    }

    beforeDestroy() {
        this.checkOpeningHours.stop()
    }

    get defaultPrice(): number {
        if (this.cartItem.product.showAbsolutePrices) {
            return this.selectedVariant.price
        }
        const map = new Map<String, number>()
        const linkedProducts = ServerManager.shop.getLinkedProducts(this.editCartItem.product)
        for (const variant of this.productVariants) {
            const {
                item,
                subItems,
            } = CartItem.fromProductAndVariantId(this.editCartItem.product, variant.id, linkedProducts)
            if (item !== null) {
                map.set(variant.id, item.getPrices(subItems).price)
            }
        }
        return map.size > 0 ? Math.min(...map.values()) : 0
    }

    get productVariants() {
        return this.editCartItem.product.variants
    }

    get addButtonDisabled() {
        return (
            !this.canOrder
            || (this.optionAmountErrors.length > 0 && this.hasTriedToAdd)
        )
    }

    get selectedVariantId() {
        return this.editCartItem.selectedVariantInfo.variantId
    }

    set selectedVariantId(val: string) {
        if (val === this.selectedVariantId) {
            return
        }
        this.editCartItem.selectedVariantInfo.variantId = val
        this.editCartItem.selectedVariantInfo.options = this.getCartItemOptions(this.bundledOptionGroups)
    }

    get selectedVariant() {
        return this.editCartItem.selectedVariant
    }

    get optionGroupPriceOffsets(): Map<string, number> {
        const map = new Map<string, number>()
        for (const optionGroup of this.editCartItem.selectedVariant.optionGroups) {
            if (
                optionGroup.mode !== OptionGroupMode.Single
                || this.isPartOfLinkedGroup(optionGroup)
                || this.editCartItem.product.showAbsolutePrices
            ) {
                map.set(optionGroup.id, 0)
                continue
            }
            const priceOffset = optionGroup.defaultOption === null
                ? optionGroup.options[0].priceChange
                : optionGroup.defaultOption.priceChange

            map.set(optionGroup.id, priceOffset)
        }
        return map
    }

    get consumptionMode() {
        return this.editCartItem.product.consumptionMode
    }

    get bundledOptionGroups(): Array<Array<OptionGroup>> {
        return this.selectedVariant.bundleOptionsForVariant()
    }

    get imageResolution() {
        if (this.editCartItem.product.images[0].resolutions.length == 0) {
            return new Resolution({
                file: this.editCartItem.product.images[0].source,
                width: 0,
                height: 0,
            })
        }
        return this.editCartItem.product.images[0].getResolutionForSize(document.documentElement.clientWidth)
    }

    get imageWidth() {
        return this.imageResolution.width == 0 ? undefined : this.imageResolution.width
    }

    get imageHeight() {
        return this.imageResolution.height == 0 ? undefined : this.imageResolution.height
    }

    get backgroundImage() {
        return `url(${encodeURI(this.imageResolution.file.getPublicPath())})`
    }

    get paddingBottom() {
        const width = this.imageWidth
        const height = this.imageHeight
        return width === undefined || height === undefined
            ? undefined
            : `${height / width * 100}%`
    }

    get name() {
        return this.editCartItem.product.getNameForI18n(this.$i18n as any)
    }

    get description() {
        return this.editCartItem.product.description.getForI18n(this.$i18n as any)
    }

    get isAvailable() {
        if (this.forceUpdateCanOrder) {/**/} // force update hack
        return this.cartItem.product.isAvailableOnDate(new Date(), this.shop.timezone)
    }

    get isClosed() {
        if (this.forceUpdateCanOrder) {/**/} // force update hack
        return this.shop.consumptionOptions.dineIn.isClosedOn(new Date(), this.shop.timezone)
    }

    get canOrder() {
        if (this.forceUpdateCanOrder) {/**/} // force update hack
        return this.shop.canOrderProduct(this.cartItem.product)
    }

    get availabilityTexts() {
        return this.cartItem.product.getAvailabilityTexts(this.shop.timezone, this.$i18n as I18n)
    }

    get shop() {
        return ServerManager.shop
    }

    get optionAmountMap(): Record<string, {
        minAmount: number
        maxAmount: number
        amount: number
        name: string
    }> {
        const groupObj = {}
        for (const option of this.editCartItem.options) {
            const {amount} = option
            const {minAmount, maxAmount, id} = option.optionGroup

            groupObj[id] = {
                minAmount: minAmount,
                maxAmount: maxAmount,
                amount: (groupObj[id]?.amount ?? 0) + amount,
                name: option.optionGroup.name.getForI18n(this.$i18n),
            }
        }

        for (const group of this.selectedVariant.optionGroups) {
            if (group.minAmount !== null && group.minAmount > 0 && groupObj[group.id] === undefined) {
                groupObj[group.id] = {
                    minAmount: group.minAmount,
                    maxAmount: group.maxAmount,
                    amount: 0,
                    name: group.name.getForI18n(this.$i18n),
                }
            }
        }
        return groupObj
    }

    get optionAmountErrors() {
        const errors: Array<{groupId: string; errorType: Partial<OptionAmountType>; amount: number}> = []
        for (const [id, {maxAmount, minAmount, amount}] of Object.entries(this.optionAmountMap)) {
            if ((minAmount ?? 0) > amount) {
                errors.push({errorType: OptionAmountType.Min, groupId: id, amount: minAmount})
            }
            if ((maxAmount ?? Infinity) < amount) {
                errors.push({errorType: OptionAmountType.Max, groupId: id, amount: maxAmount})
            }
        }

        return errors
    }

    get computedVariantPrices(): Map<string, number> {
        const map = new Map<string, number>()
        const linkedProducts = ServerManager.shop.getLinkedProducts(this.editCartItem.product)
        for (const variant of this.productVariants) {
            const {
                item,
                subItems,
            } = CartItem.fromProductAndVariantId(this.editCartItem.product, variant.id, linkedProducts)
            if (item !== null) {
                map.set(variant.id, item.getPrices(subItems).price)
            }
        }
        const minPrice = Math.min(...map.values())

        for (const [key, value] of map.entries()) {
            map.set(key, value - minPrice)
        }

        return map
    }

    errorsForGroup(optionGroupBundle: Array<OptionGroup>): Map<string, boolean> {
        return optionGroupBundle.reduce((acc, curr) => {
            acc.set(curr.id, this.groupHasAmountError(curr.id))
            return acc
        }, new Map<string, boolean>())
    }

    isPartOfLinkedGroup(optionGroup: OptionGroup): boolean {
        return this.bundledOptionGroups.some(bundle => {
            if (bundle.length < 2) {
                return false
            }
            return bundle.map(og => og.id).includes(optionGroup.id)
        })
    }

    getCartItemOptions(bundledOptionGroups: Array<Array<OptionGroup>>): Array<CartItemOption> {
        const cartItemOptions: Array<CartItemOption> = []
        for (const [defaultGroup] of bundledOptionGroups) {
            if (defaultGroup === undefined) {
                continue
            }
            const alreadyExists = this.editCartItem.options.some(o => o.optionGroup.id === defaultGroup.id)
            if (alreadyExists) {
                continue
            }

            if (defaultGroup.mode === OptionGroupMode.Single) {
                // single (always a default)
                const optionToSet = defaultGroup.defaultOption ?? defaultGroup.options[0]
                const [success, cartItemId] = this.addOptionProductIfNeeded(optionToSet)
                if (!success) {
                    console.error(`failed to add optionProduct for option with id ${optionToSet.id}`)
                    continue
                }
                cartItemOptions.push(CartItemOption.create({
                    optionGroup: defaultGroup,
                    option: optionToSet,
                    amount: 1,
                    subCartItemId: cartItemId,
                }))
            } else {
                // multiple selects
                for (const option of defaultGroup.options) {
                    if ((option.minAmount !== null && option.minAmount !== 0)
                        || option.defaultAmount !== null && option.defaultAmount !== 0
                    ) {
                        const [success, cartItemId] = this.addOptionProductIfNeeded(option)
                        if (!success) {
                            console.error(`failed to add optionProduct for option with id ${option.id}`)
                            continue
                        }
                        cartItemOptions.push(CartItemOption.create({
                            optionGroup: defaultGroup,
                            option: option,
                            amount: Math.max(0, option.minAmount ?? 0, option.defaultAmount ?? 0),
                            subCartItemId: cartItemId,
                        }))
                    }
                }

                if (defaultGroup.options.length === 1 && defaultGroup.minAmount !== null) {
                    const [success, cartItemId] = this.addOptionProductIfNeeded(defaultGroup.options[0])
                    if (!success) {
                        console.error(`failed to add optionProduct for option with id ${defaultGroup.options[0].id}`)
                        continue
                    }
                    cartItemOptions.push(CartItemOption.create({
                        optionGroup: defaultGroup,
                        option: defaultGroup.options[0],
                        amount: defaultGroup.minAmount,
                        subCartItemId: cartItemId,
                    }))
                }
            }
        }
        return cartItemOptions
    }

    addOptionProductIfNeeded(option: Option): [boolean, string | null] {
        if (option.productLink === undefined) {
            return [true, null]
        }
        let optionProduct = this.getProductById(option.productLink.productId, false)
        if (optionProduct === null) {
            console.error(`Could not find optionProduct with ID: ${option.productLink!.productId}`)
            return [false, null]
        }
        optionProduct = Product.formatProductForOption(optionProduct, option)
        const {cartItem} = CartItem.defaultForProduct(optionProduct, []) // pass empty array because menu-items linked to menu-items is not supported.
        cartItem.selectedVariantInfo.options = this.getCartItemOptions(this.bundledOptionGroups)
        this.addOptionProduct(cartItem.overwriteOptionLinkData(
            this.editCartItem.id,
            option,
        ))
        return [true, cartItem.id]
    }

    addOptionProduct(cartItem: CartItem) {
        this.removeOptionProduct(cartItem.id)
        this.editSubCartItems.push(cartItem)
    }

    getProductById(id: string, excludeMenuProducts: boolean): Product | null {
        return PruneHelper.getProductById(ServerManager.shop, this.consumptionMode, id, excludeMenuProducts)
    }

    groupHasAmountError(optionGroupId: string) {
        return this.optionAmountErrors.some(e => e.groupId === optionGroupId)
    }

    removeOptionProduct(id: string) {
        this.editSubCartItems = this.editSubCartItems.filter(el => el.id !== id)
    }
}
