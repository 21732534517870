var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view" },
    [
      _c("DRSTNavigationBar", { attrs: { title: _vm.name } }, [
        _c("button", {
          staticClass: "button icon back",
          attrs: { slot: "left", "data-testid": "product-view-back-button" },
          on: { click: _vm.pop },
          slot: "left"
        }),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "price", attrs: { slot: "right" }, slot: "right" },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("price")(_vm.defaultPrice)) +
                "\n        "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "main",
        [
          _c("h1", [_vm._v(_vm._s(_vm.name))]),
          _vm._v(" "),
          _vm.editCartItem.product.images.length > 0
            ? _c("div", { staticClass: "product-image" }, [
                _c("div", {
                  staticClass: "spacer",
                  style: { paddingBottom: _vm.paddingBottom }
                }),
                _vm._v(" "),
                _c("img", {
                  attrs: { src: _vm.imageResolution.file.getPublicPath() }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.availabilityTexts.length > 0
            ? _c(
                "div",
                { staticClass: "availability-text" },
                [
                  _vm.availabilityTexts.length === 1
                    ? _c("p", [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.availabilityTitle) +
                            " " +
                            _vm._s(_vm.availabilityTexts[0]) +
                            "\n            "
                        )
                      ])
                    : [
                        _c("p", [_vm._v(_vm._s(_vm.availabilityTitle))]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          _vm._l(_vm.availabilityTexts, function(
                            availabilityText,
                            index
                          ) {
                            return _c(
                              "li",
                              {
                                key:
                                  _vm.editCartItem.id +
                                  "_" +
                                  index +
                                  "_availabilityText"
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(availabilityText) +
                                    "\n                    "
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.description
            ? _c("p", {
                staticClass: "product-description",
                domProps: { textContent: _vm._s(_vm.description) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.tags
            ? _c(
                "DRSTBox",
                {
                  attrs: {
                    title: _vm.$t("customer.products.allergensBoxTitle"),
                    "with-bg": false
                  }
                },
                [
                  _c("DRSTBoxItem", { staticClass: "no-left-padding" }, [
                    _vm._v(
                      "\n                " + _vm._s(_vm.tags) + "\n            "
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.productVariants.length > 1
            ? _c("ProductVariantBox", {
                attrs: {
                  "prices-map": _vm.computedVariantPrices,
                  "show-absolute-prices":
                    _vm.editCartItem.product.showAbsolutePrices,
                  "product-variants": _vm.productVariants,
                  editable: _vm.shop.enableOrdering
                },
                model: {
                  value: _vm.selectedVariantId,
                  callback: function($$v) {
                    _vm.selectedVariantId = $$v
                  },
                  expression: "selectedVariantId"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.bundledOptionGroups, function(optionGroupGroup) {
            return [
              optionGroupGroup.length == 1
                ? _c("OptionGroupBox", {
                    key: _vm.selectedVariantId + "-" + optionGroupGroup[0].id,
                    attrs: {
                      "sub-items": _vm.editSubCartItems,
                      "consumption-mode": _vm.consumptionMode,
                      "edit-cart-item": _vm.editCartItem,
                      "option-group": optionGroupGroup[0],
                      editable: _vm.shop.enableOrdering,
                      "price-offset": _vm.optionGroupPriceOffsets.get(
                        optionGroupGroup[0].id
                      ),
                      "has-amount-error": _vm.groupHasAmountError(
                        optionGroupGroup[0].id
                      )
                    },
                    on: {
                      removeOptionProduct: function($event) {
                        return _vm.removeOptionProduct($event)
                      },
                      addOptionProduct: function($event) {
                        return _vm.addOptionProduct($event)
                      }
                    },
                    model: {
                      value: _vm.editCartItem.selectedVariantInfo.options,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.editCartItem.selectedVariantInfo,
                          "options",
                          $$v
                        )
                      },
                      expression: "editCartItem.selectedVariantInfo.options"
                    }
                  })
                : _c("OptionGroupGroupBox", {
                    key:
                      _vm.selectedVariantId +
                      "-" +
                      optionGroupGroup.map(function(el) {
                        return el.id
                      }),
                    attrs: {
                      "sub-items": _vm.editSubCartItems,
                      "consumption-mode": _vm.consumptionMode,
                      "edit-cart-item": _vm.editCartItem,
                      "option-groups": optionGroupGroup,
                      editable: _vm.shop.enableOrdering,
                      "option-group-price-offsets": _vm.optionGroupPriceOffsets,
                      "amount-errors-map": _vm.errorsForGroup(optionGroupGroup)
                    },
                    on: {
                      removeOptionProduct: function($event) {
                        return _vm.removeOptionProduct($event)
                      },
                      addOptionProduct: function($event) {
                        return _vm.addOptionProduct($event)
                      }
                    },
                    model: {
                      value: _vm.editCartItem.selectedVariantInfo.options,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.editCartItem.selectedVariantInfo,
                          "options",
                          $$v
                        )
                      },
                      expression: "editCartItem.selectedVariantInfo.options"
                    }
                  })
            ]
          }),
          _vm._v(" "),
          _vm.canOrder
            ? _c(
                "DRSTBox",
                {
                  attrs: {
                    title: _vm.$t("customer.products.amountTitle"),
                    "data-testid": "product-amount-box"
                  }
                },
                [
                  _c(
                    "DRSTBoxItem",
                    { staticClass: "style-row-title-2" },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$tc(
                              "general.amountNumber",
                              _vm.editCartItem.amount
                            )
                          ) +
                          "\n                "
                      ),
                      _c("Stepper", {
                        attrs: { slot: "right" },
                        slot: "right",
                        model: {
                          value: _vm.editCartItem.amount,
                          callback: function($$v) {
                            _vm.$set(_vm.editCartItem, "amount", $$v)
                          },
                          expression: "editCartItem.amount"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "DRSTBox",
            _vm._l(_vm.selectedUpsells, function(item) {
              return _c("CartItemRow", {
                key: "selected-upsell-" + item.id + _vm.forceUpdateUpsells,
                attrs: {
                  "use-unit-price": true,
                  "upsell-row": true,
                  "cart-item": item,
                  "sub-items": []
                },
                on: {
                  click: function($event) {
                    return _vm.editUpsell(item)
                  },
                  delete: function($event) {
                    return _vm.deleteUpsell(item)
                  }
                }
              })
            }),
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.upsellGroups, function(upsellGroup, i) {
            return _c(
              "DRSTBox",
              {
                key: "upsell-" + i,
                staticClass: "upsell-box",
                attrs: { title: _vm._f("i18n")(upsellGroup.description) }
              },
              _vm._l(upsellGroup.upsells, function(upsellItem) {
                return _c("UpsellBoxItem", {
                  key: upsellItem.id,
                  attrs: { product: upsellItem.product },
                  on: { addUpsell: _vm.addUpsell }
                })
              }),
              1
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.shop.disclaimer
        ? _c("div", {
            staticClass: "disclaimer",
            domProps: { textContent: _vm._s(_vm.shop.disclaimer) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "TransitionExpand",
        [
          _vm.optionAmountErrors.length > 0
            ? _c(
                "DRSTFooterBox",
                { staticClass: "error-box" },
                _vm._l(_vm.optionAmountErrors, function(error) {
                  return _c("DRSTBoxItem", { key: error.groupId }, [
                    _c(
                      "div",
                      {
                        staticClass: "error-box-left",
                        attrs: { slot: "left" },
                        slot: "left"
                      },
                      [
                        _c("div", { staticClass: "icon error" }),
                        _vm._v(" "),
                        _c("p", { staticClass: "error-name" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.optionAmountMap[error.groupId].name) +
                              "\n                    "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    error.errorType === _vm.OptionAmountType.Min
                      ? _c(
                          "p",
                          {
                            staticClass: "error-amount",
                            attrs: { slot: "right" },
                            slot: "right"
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$t(
                                    "consumer.optionAmountErrors.minimum",
                                    { amount: error.amount }
                                  )
                                ) +
                                "\n                "
                            )
                          ]
                        )
                      : error.errorType === _vm.OptionAmountType.Max
                      ? _c(
                          "p",
                          {
                            staticClass: "error-amount",
                            attrs: { slot: "right" },
                            slot: "right"
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$t(
                                    "consumer.optionAmountErrors.maximum",
                                    { amount: error.amount }
                                  )
                                ) +
                                "\n                "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      !_vm.hideOrderButtons
        ? _c(
            "DRSTFloatingFooter",
            { attrs: { "data-testid": "product-view-footer" } },
            [
              _c(
                "button",
                {
                  staticClass: "button primary fill",
                  class: { disabled: _vm.addButtonDisabled },
                  attrs: { disabled: _vm.addButtonDisabled },
                  on: { click: _vm.addAllToBasket }
                },
                [
                  _vm._v(
                    "\n            " + _vm._s(_vm.addButtonText) + "\n        "
                  )
                ]
              )
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }