





















import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class DRSTNavigationBar extends Vue {
    @Prop({default: '', type: String})
    title!: string

    @Prop({default: true, type: Boolean})
    sticky!: boolean

    @Prop({default: false, type: Boolean})
    alwaysShowTitle!: boolean

    scrolled = false
    scrollElement: HTMLElement | null = null

    get showTitle() {
        return this.scrolled || this.alwaysShowTitle
    }

    getScrollElement(element: HTMLElement | null = null): HTMLElement {
        if (!element) {
            element = this.$el as HTMLElement
        }

        const style = window.getComputedStyle(element)
        if (
            style.overflowY == 'scroll'
            || style.overflow == 'scroll'
            || style.overflow == 'auto'
            || style.overflowY == 'auto'
        ) {
            return element
        } else {
            if (!element.parentElement) {
                return document.documentElement
            }
            return this.getScrollElement(element.parentElement)
        }
    }

    deactivated() {
        if (!this.scrollElement) {
            return
        }
        if (this.scrollElement === document.documentElement) {
            window.removeEventListener('scroll', this.onScroll)
        } else {
            this.scrollElement.removeEventListener('scroll', this.onScroll)
        }
        this.scrollElement = null
    }

    mounted() {
        if (this.sticky) {
            this.addListener()
        }
    }

    addListener() {
        if (this.scrollElement) {
            return
        }
        this.scrollElement = this.getScrollElement()
        if (this.scrollElement === document.documentElement) {
            window.addEventListener('scroll', this.onScroll, {passive: true})
        } else {
            this.scrollElement.addEventListener('scroll', this.onScroll, {passive: true})
        }
    }

    activated() {
        // temp fix for element not yet in dom
        window.setTimeout(() => {
            if (this.sticky) {
                this.addListener()
            }
        }, 500)
    }

    onScroll() {
        if (!this.scrollElement) {
            return
        }
        const scroll = this.scrollElement.scrollTop
        if (scroll > 30) {
            this.scrolled = true
        } else if (scroll < 15) {
            this.scrolled = false
        }
    }
}
