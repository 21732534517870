import {Category, ConsumptionOptionType, Product} from '@dorst/structures'

interface Prunable {
    categories: Array<Category>
    products: Array<Product>
    isOptionProductsMenu?: boolean
}

export class PruneHelper {
    static filterCategories(prunable: Prunable, mode: ConsumptionOptionType): Array<Category> {
        return prunable.categories.filter(c => !this.shouldBePruned(c, mode))
    }

    /**
     *
     * @param prunable
     * @param mode
     * @param excludeMenuProducts
     *  Makes sure we do not show menu products as stand-alone in the frontend.
     *  Should be set to false when trying to find a product linked to an option.
     * @returns {Product[]}
     */
    static filterProducts(prunable: Prunable, mode: ConsumptionOptionType, excludeMenuProducts = true): Array<Product> {
        let excludedIds: Array<string> = []
        if (excludeMenuProducts) {
            excludedIds = prunable.categories.reduce<Array<string>>((acc, curr) => {
                if (curr.isOptionProductsMenu) {
                    acc.push(...curr.products.map(el => el.id))
                }
                return acc
            }, [])
        }

        return prunable.products.filter(p => p.consumptionMode === mode && !excludedIds.includes(p.id))
    }

    static shouldBePruned(prunable: Prunable, mode: ConsumptionOptionType): boolean {
        if (prunable.isOptionProductsMenu === true) {
            return true
        }
        if (prunable.products.length > 0) {
            return !prunable.products.some(p => p.consumptionMode === mode)
        }
        if (prunable.categories.length > 0) {
            return !prunable.categories.some(c => !this.shouldBePruned(c, mode))
        }
        return true
    }

    static getProductById(prunable: Prunable, mode: ConsumptionOptionType, productId: string, excludeMenuProducts = true): Product | null {
        const possibleProducts = PruneHelper.filterProducts(prunable, mode, excludeMenuProducts)
        return possibleProducts.find(el => {
            return el.id === productId
        }) ?? null
    }
}
