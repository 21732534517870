















import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {ServerManager} from '../classes/ServerManager'
import {PriceHelper} from '../helpers/PriceHelper'
import {i18n} from '@dorst/frontend-translations'
import {isNullOrEmpty} from '@dorst/helpers'
import {VolumeUpsellProposal as VolumeUpsellProposalInterface} from '@dorst/structures'

type VolumeUpsellProposal = VolumeUpsellProposalInterface

@Component({})
export default class VolumeUpsellProposalBox extends Mixins(NavigationMixin) {
    @Prop({required: true, type: Object})
    volumeUpsellsProposal: VolumeUpsellProposal

    @Prop({required: true, type: Number})
    cartItemPrice: number

    get proposalAmount() {
        return this.volumeUpsellsProposal.item.amount
    }

    get proposalPrice() {
        return this.volumeUpsellsProposal.item.getPrices().price
    }

    get formattedProposalPrice() {
        const formattedPrice = PriceHelper.format({
            price: this.volumeUpsellsProposal.item.getPrices().price,
        })
        if (isNullOrEmpty(formattedPrice)) {
            return null
        }
        return ` (${formattedPrice})`
    }

    get showPrices() {
        return !ServerManager.shop.enableHidePrices && this.proposalPriceDifference !== null
    }

    get proposalPriceDifference(): string | null {
        const difference = this.cartItemPrice - this.proposalPrice
        return difference > 0
            ? PriceHelper.format({
                price: difference,
                display: 'name',
            })
            : null
    }

    get productName() {
        return this.volumeUpsellsProposal.item.getSelectedVariantName(this.$i18n)
    }
}
