import {
    AutoEncoder,
    AutoEncoderPatchType,
    BooleanDecoder,
    DateDecoder,
    field,
    PartialWithoutMethods,
    PatchType,
    StringDecoder,
} from '@simonbackx/simple-encoding'

import {
    TranslatedString,
    TranslatedStringDecoder,
    TranslatedStringPatch,
} from './Language'

export class ShopMessage extends AutoEncoder {
    @field({decoder: StringDecoder})
    title = ''

    @field({decoder: StringDecoder})
    text = ''
}

export class TranslatedShopMessage extends AutoEncoder {
    @field({
        decoder: BooleanDecoder,
        version: 91,
        upgrade: function (this: TranslatedShopMessage) {
            return !this.title.isEmpty() || !this.text.isEmpty()
        },
    })
    isEnabled: boolean = false

    @field({decoder: TranslatedStringDecoder, optional: true})
    title = new TranslatedString({})

    @field({decoder: TranslatedStringDecoder, optional: true})
    text = new TranslatedString({})

    /**
     * Eg. for the shopSettings.customerMessage we set the lastUpdatedAt in order to be able
     * to determine when a message has been de-activated and re-activated later.
     */
    @field({decoder: DateDecoder, optional: true})
    lastUpdatedAt?: Date
}

export const TranslatedShopMessageToTranslatedShopMessage = {
    /* No downgrade functions needed because the newly introduced "isEnabled"
     property will just be stripped off from those older structures */
    upgrade: (translatedShopMessage: TranslatedShopMessage | null | undefined): TranslatedShopMessage | null => {
        return translatedShopMessage == null
            ? null
            : TranslatedShopMessage.create({
                isEnabled: !translatedShopMessage.title.isEmpty() || !translatedShopMessage.text.isEmpty(),
                title: translatedShopMessage.title,
                text: translatedShopMessage.text,
            })
    },
    upgradePatch: (translatedShopMessage: PartialWithoutMethods<AutoEncoderPatchType <TranslatedShopMessage>>): AutoEncoderPatchType<TranslatedShopMessage> => {
        const patch = TranslatedShopMessage.patch({
            isEnabled: translatedShopMessage.title === undefined || translatedShopMessage.text === undefined,
        })

        patch.title = translatedShopMessage.title
        patch.text = translatedShopMessage.text
        return patch
    },
}

export const TranslatedStringToTranslatedShopMessage = {
    upgrade: (translatedString: TranslatedString | null): TranslatedShopMessage | null => {
        return translatedString === null
            ? null
            : TranslatedShopMessage.create({
                isEnabled: !translatedString.isEmpty(),
                text: translatedString,
            })
    },
    downgrade: (translatedShopMessage: TranslatedShopMessage | null): TranslatedString | null => {
        return translatedShopMessage?.text ?? null
    },
    upgradePatch: (translatedStringPatch: PatchType<TranslatedString>): AutoEncoderPatchType<TranslatedShopMessage> => {
        return TranslatedShopMessage.patch({
            text: translatedStringPatch,
        })
    },
    downgradePatch: (translatedShopMessage: PartialWithoutMethods<AutoEncoderPatchType<TranslatedShopMessage>>): PatchType<TranslatedString> => {
        return translatedShopMessage.text ?? new TranslatedStringPatch({})
    },
}
