import {
    AutoEncoder,
    BooleanDecoder,
    EnumDecoder,
    field,
    NumberDecoder,
    StringDecoder,
} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {CartItem} from './CartItem'
import {I18n} from './I18n'
import {Product, ProductVariant} from './Product'
import {ProductBackoffice} from './ProductBackoffice'

export enum VolumeUpsellMode {
    EXACT = 'Exact',
    MULTIPLES = 'Multiples',
    EXACT_MULTIPLES = 'Exact multiples',
}

/**
 * A Volume upsell is used to push larger variants when smaller ones are selected.
 * e.g.: when ordering 2x Water-Small suggest to upgrade to to 1x Water-Large
 * or: when ordering 6x glass of wine -> upgrade it to 1x bottle
 * Possible to link variants of the same product or other products.
 */
export class VolumeUpsell extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: NumberDecoder})
    shopId: number

    @field({decoder: NumberDecoder, optional: true})
    maxAmount: number = 2

    @field({decoder: NumberDecoder, optional: true})
    minAmount: number = 2

    @field({decoder: new EnumDecoder(VolumeUpsellMode), optional: true})
    mode: VolumeUpsellMode = VolumeUpsellMode.MULTIPLES

    @field({decoder: StringDecoder})
    productAid: string

    @field({decoder: StringDecoder})
    variantAid: string

    @field({decoder: StringDecoder})
    productBid: string

    @field({decoder: StringDecoder})
    variantBid: string

    @field({decoder: BooleanDecoder, optional: true})
    enabled: boolean = true

    get hasValidRange() {
        return this.minAmount >= 2 && this.maxAmount >= this.minAmount
    }
}

export class VolumeUpsellWithBackofficeProduct extends VolumeUpsell {
    /**
     * Contains the linked product => product.id === productBid
     */
    @field({decoder: ProductBackoffice})
    product: ProductBackoffice

    /**
     * Contains the linked variant => variant.id === variantBid
     */
    get variant(): ProductVariant | null {
        return this.product.variants.find(variant => variant.id === this.variantBid) ?? null
    }
}

export class VolumeUpsellWithProduct extends VolumeUpsell {
    /**
     * Contains the linked product => product.id === productBid
     */
    @field({decoder: Product})
    product: Product

    /**
     * Contains the linked variant => variant.id === variantBid
     */
    get variant(): ProductVariant | null {
        return this.product.variants.find(variant => variant.id === this.variantBid) ?? null
    }
}

export interface VolumeUpsellProposal {
    item: CartItem
    subItems: Array<CartItem>
}

export class VolumeUpsellHelper {
    /**
     * @param originalAmount - amount of productA currently in the cart.
     * @param volumeUpsells - All the volume upssels that are configured for the variant inside originalCartItem.
     */
    static calculateVolumeUpsell(originalCartItem: CartItem, volumeUpsells: Array<VolumeUpsellWithProduct>): VolumeUpsellProposal | null {
        const originalAmount = originalCartItem.amount
        if (volumeUpsells.length === 0 || originalAmount <= 1) {
            return null
        }

        const upsellToPropose = [...volumeUpsells]
            .sort((a, b) => b.maxAmount - a.maxAmount)
            .find(volumeUpsells => {
                return volumeUpsells.hasValidRange
                    && volumeUpsells.enabled
                    && volumeUpsells.minAmount <= originalAmount
                    && (
                        volumeUpsells.maxAmount >= originalAmount
                        || volumeUpsells.mode === VolumeUpsellMode.MULTIPLES
                        || volumeUpsells.mode === VolumeUpsellMode.EXACT_MULTIPLES && originalAmount % volumeUpsells.maxAmount === 0
                    )
            })
        if (upsellToPropose === undefined) {
            return null
        }

        const {item, subItems} = CartItem.fromProductAndVariantId(upsellToPropose.product, upsellToPropose.variantBid)

        if (item === null) {
            return null
        }

        if (upsellToPropose.mode === VolumeUpsellMode.EXACT) {
            item.amount = 1
        }

        item.amount = upsellToPropose.mode === VolumeUpsellMode.EXACT
            ? 1
            : Math.max(1, Math.ceil(originalAmount / upsellToPropose.maxAmount))

        return {
            item,
            subItems,
        }
    }

    static getModeLabel(mode: VolumeUpsellMode, i18n: I18n): string {
        switch (mode) {
            case VolumeUpsellMode.EXACT:
                return i18n.t('common.enums.volumeUpsellsMode.exact')
            case VolumeUpsellMode.MULTIPLES:
                return i18n.t('common.enums.volumeUpsellsMode.multiples')
            case VolumeUpsellMode.EXACT_MULTIPLES:
                return i18n.t('common.enums.volumeUpsellsMode.exactMultiples')
            default: {
                // compile error = not all caught
                const m: never = mode
                throw new Error(`Unknown volume upsell mode: ${m}`)
            }
        }
    }
}
