




















































































































































import {ComponentWithProperties, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {DateTime} from 'luxon'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {Cashless} from '../actions/Cashless'
import {AnalyticsController} from '../analytics/AnalyticsController'
import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import {TealiumManager} from '../classes/TealiumManager'
import {environment} from '../environments/environment'
import OfferCard from './OfferCard.vue'
import OrderDetailView from './OrderDetailView.vue'
import {DRSTBox, DRSTBoxItem, DRSTFloatingFooter, DRSTFooterBox, DRSTNavigationBar, Spinner} from '@dorst/components'
import {DateHelper} from '@dorst/helpers'
import {ConsumptionOptionType, InnovendPickupInfo, OrderConsumer, ShopTypeHelper} from '@dorst/structures'
import {Formatter} from '@dorst/validation'

@Component({
    components: {
        DRSTNavigationBar,
        DRSTBox,
        DRSTBoxItem,
        DRSTFloatingFooter,
        DRSTFooterBox,
        Spinner,
        OfferCard,
    },
    filters: {
        time: (date: Date) => Formatter.time(date, ServerManager.shop.timezone),
        dateTimeShort: (date: Date) => Formatter.dateTimeShort(date, ServerManager.shop.timezone),
    },
})
export default class OrderView extends Mixins(NavigationMixin) {
    @Prop({required: true})
    order: OrderConsumer

    @Prop()
    innovendPickupInfo: InnovendPickupInfo

    cardBalance: number | null = null

    ConsumptionOptionType = ConsumptionOptionType

    get customMessage(): undefined | string {
        return ServerManager.shop.orderConfirmedMessage?.isEnabled
            ? ServerManager.shop.orderConfirmedMessage.text?.getForI18n(this.$i18n)
            : undefined
    }

    get hideBgImageOnOrderConfirmedMessage() {
        return ServerManager.shop.personalisation?.personalisationExceptions?.hideBgImageOnOrderConfirmedMessage ?? false
    }

    get isTableMode(): boolean {
        return ShopTypeHelper.isTableMode(ServerManager.shop.type)
    }

    get tableName(): string {
        if (this.order.consumptionMode == ConsumptionOptionType.TakeAway) {
            return this.$t('general.takeAwayLocation').toString()
        }
        return this.isTableMode ? this.$t('general.table').toString() : this.$t('general.place').toString()
    }

    get tablePath(): string {
        if (!this.order.table) {
            return ''
        }

        const tableCategoriesToDisplay = ServerManager.shop.enableTableCategories
            ? this.order.tableCategories.map(c => c.name.trim())
            : []

        return [...tableCategoriesToDisplay, this.order.table.name.trim()].join(' - ')
    }

    get cardID(): string {
        return this.order.paymentDetails?.cardID ?? ''
    }

    get verification(): string | null {
        return this.order.paymentDetails?.verification ?? null
    }

    get orderAmount(): number {
        return this.order.price
    }

    get isToday(): boolean {
        return this.order.consumptionMode === ConsumptionOptionType.DineIn ? true : DateHelper.isSameDay(DateTime.local(), DateTime.fromJSDate(this.order.deliveryDate!))
    }

    mounted() {
        if (environment.features.tealium) {
            const slot = this.order.deliveryDate ? `${Formatter.time(this.order.deliveryDate, ServerManager.shop.timezone)} - ${Formatter.time(this.addInterval(this.order.deliveryDate), ServerManager.shop.timezone)}` : null
            void TealiumManager.onOrderConfirmation(this.$i18n as any, this.order, slot)
        }
        AnalyticsController.get().event('onOrderConfirmation', this.order)

        CartManager.clear()

        if (!this.cardID) {
            return
        }

        const cashless = new Cashless(ServerManager.shop.id, this.order.paymentMethod)
        cashless.GetBalance(this.cardID, this.verification)
            .then(({card, value}) => {
                if (this.cardID === card) {
                    this.cardBalance = value
                }
            }).catch((e) => {
                this.cardBalance = null

                console.error(e)
            })
    }

    addInterval(start: Date): Date {
        return new Date(start.getTime() + ServerManager.shop.consumptionOptions.takeAway.interval * 60 * 1000)
    }

    showDetails() {
        this.present(new ComponentWithProperties(OrderDetailView, {order: this.order}))
    }

    close() {
        this.navigationController?.popToRoot()
    }
}
