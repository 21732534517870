import { render, staticRenderFns } from "./CategoryView.vue?vue&type=template&id=5364947d&scoped=true&"
import script from "./CategoryView.vue?vue&type=script&lang=ts&"
export * from "./CategoryView.vue?vue&type=script&lang=ts&"
import style0 from "./CategoryView.vue?vue&type=style&index=0&id=5364947d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5364947d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wannes.vermeulen/Repositories/dorst-app/dorst/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5364947d')) {
      api.createRecord('5364947d', component.options)
    } else {
      api.reload('5364947d', component.options)
    }
    module.hot.accept("./CategoryView.vue?vue&type=template&id=5364947d&scoped=true&", function () {
      api.rerender('5364947d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/CategoryView.vue"
export default component.exports