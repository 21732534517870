



























import {ComponentWithProperties, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins} from 'vue-property-decorator'

import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import {TealiumManager} from '../classes/TealiumManager'
import {DRSTBox, DRSTBoxItem} from '@dorst/components'
import {CartItem, Category, ConsumptionOptionType, Image, Product} from '@dorst/structures'

interface Offer {
    category?: {[key in ConsumptionOptionType]?: string}
    product?: {[key in ConsumptionOptionType]?: string}
    text: string
}

const searchCategory = (id: string, categories: Array<Category>): Category | undefined => {
    for (const category of categories) {
        if (category.id === id) {
            return category
        }

        if (category.categories.length > 0) {
            const hit = searchCategory(id, category.categories)
            if (hit) {
                return hit
            }
        }
    }
    return undefined
}

@Component({
    components: {
        DRSTBox,
        DRSTBoxItem,
    },
})
export default class OfferCard extends Mixins(NavigationMixin) {
    TealiumManager = TealiumManager

    // @Prop({})
    // offer: Offer;

    get offer(): Offer | null {
        return this.TealiumManager.activeOffer
    }

    get offerImg(): Image | null {
        if (!this.offer) {
            return null
        }

        const mode = CartManager.getConsumptionMode()

        // Category offer
        if (this.offer.category) {
            const categoryId = this.offer.category[mode]
            if (!categoryId) {
                return null
            }

            const category = searchCategory(categoryId, ServerManager.shop.categories)
            if (!category) {
                return null
            }

            const product = category.products[0]
            return product.images[0]
        }

        // Product offer
        if (this.offer.product) {
            const productId = this.offer.product[mode]
            if (!productId) {
                return null
            }

            const product = ServerManager.shop.products.find(it => it.id === productId)
            if (!product) {
                return null
            }

            return product.images[0]
        }

        return null
    }

    get offerImgPath(): string | null {
        return this.offerImg?.getPathForSize(64 * (window.devicePixelRatio ?? 1), 64 * (window.devicePixelRatio ?? 1)) ?? null
    }

    acceptOffer() {
        if (!this.offer) {
            return
        }

        const mode = CartManager.getConsumptionMode()

        if (this.offer.category) {
            const categoryId = this.offer.category[mode]
            if (!categoryId) {
                return
            }

            const category = searchCategory(categoryId, ServerManager.shop.categories)
            if (!category) {
                return
            }

            void this.goToCategory(category)
        }

        if (this.offer.product) {
            const productId = this.offer.product[mode]
            if (!productId) {
                return null
            }

            const product = ServerManager.shop.products.find(it => it.id === productId)
            if (!product) {
                return null
            }

            void this.goToProduct(product)
        }
    }

    async goToProduct(product: Product) {
        const {cartItem, subItems} = CartItem.defaultForProduct(product, ServerManager.shop.getLinkedProducts(product))
        const component = (await import('./ProductView.vue')).default
        this.show(new ComponentWithProperties(component, {cartItem, subCartItems: subItems}))
    }

    async goToCategory(category: Category) {
        console.log(category)
        const component = (await import('./CategoryView.vue')).default
        this.show(new ComponentWithProperties(component, {category}))
    }
}
