var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.root && _vm.resolved
        ? _c("FramedComponent", {
            key: _vm.root.key,
            attrs: { root: _vm.root }
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.root && _vm.resolved ? _vm._t("default") : _vm._e(),
      _vm._v(" "),
      !_vm.resolved ? _c("LoadingCover") : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }