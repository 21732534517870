import {AutoEncoder, field, StringDecoder} from '@simonbackx/simple-encoding'
import {SimpleError} from '@simonbackx/simple-errors'

// We *MUST* import from package here, since importing from either `@dorst/structures` or `../../index` will cause
// a nasty circular dependency (and will break the ShopSettings test)
import {encoding} from '../../package.json'

export class DeliverectLogin extends AutoEncoder {
    @field({decoder: StringDecoder, optional: true})
    clientId?: string

    @field({decoder: StringDecoder, optional: true})
    clientSecret?: string

    @field({decoder: StringDecoder, optional: true})
    channelLocationId?: string

    @field({decoder: StringDecoder, optional: true})
    channelLinkId?: string

    get channelStatusWebhookPath() {
        return `/v${encoding.version}/integrations/deliverect/channelStatus`
    }

    verify() {
        if (this.clientId === undefined || this.clientSecret === undefined) {
            throw new SimpleError({code: 'invalid_credentials', message: 'The given credentials are not valid.'})
        }
    }
}

export const DeliverectLoginPatch = DeliverectLogin.patchType()
