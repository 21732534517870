
































import {Component, Prop, Vue} from 'vue-property-decorator'

import {isNullOrEmpty} from '@dorst/helpers'
import {ShopExternalLinks} from '@dorst/structures'

@Component
export default class DRSTExternalLinksFooter extends Vue {
    @Prop({required: true, type: ShopExternalLinks})
    links: ShopExternalLinks

    get hasWebsiteOnly() {
        return !isNullOrEmpty(this.links.website)
            && isNullOrEmpty(this.links.facebook)
            && isNullOrEmpty(this.links.instagram)
    }
}
