export const createNodeAndDownload = (dataurl: string, filename: string) => {
    const downloadAnchorNode = document.createElement('a')
    downloadAnchorNode.setAttribute('href', dataurl)
    downloadAnchorNode.setAttribute('download', filename)
    document.body.appendChild(downloadAnchorNode) // required for firefox
    downloadAnchorNode.click()
    downloadAnchorNode.remove()
}

export class ScriptLoader {

    private static loadingScripts = new Map<string, Promise<void>>()

    static async loadScript(id: string, src: string): Promise<void> {
        const alreadyLoadingPromise = this.loadingScripts.get(id)
        if (alreadyLoadingPromise !== undefined) {
            return alreadyLoadingPromise
        }

        const element = document.getElementById(id)
        if (element !== null) {
            // Script already loaded
            return
        }

        const promise = new Promise<void>((resolve, reject) => {
            const script = document.createElement('script')
            script.src = src
            script.id = id
            script.addEventListener('error', (e) => {
                this.loadingScripts.delete(id)
                script.remove()
                reject(new Error(`Could not load ${id} script with src ${src}`))
            })
            script.addEventListener('load', e => {
                this.loadingScripts.delete(id)
                resolve()
            })
            document.body.append(script)
        })

        this.loadingScripts.set(id, promise)

        return promise
    }
}
