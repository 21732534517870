import Vue from 'vue'
import VueI18n from 'vue-i18n'

import {Language, PersonalisedText} from '@dorst/structures'

// Load i18n plugin
Vue.use(VueI18n)

let locale = window.navigator.languages ? window.navigator.languages[0] : null
locale = locale || window.navigator.language || (window.navigator as any).browserLanguage || (window.navigator as any).userLanguage

if (!locale) {
    locale = 'en'
}
const supportedLanguages = ['nl', 'fr', 'en', 'ar', 'pt']
const rtlLanguages: Array<string> = ['ar']

let translationOverrides: Record<string, Record<string, string>> = {}
let translations = {}

// DO NOT RELY ON THIS CONST, IT CAN CHANGE via switchLanguage
// Only contains the initial language
const lang = locale.substr(0, 2)

if (!supportedLanguages.includes(lang)) {
    // Fallback to default
    locale = 'en'
}

export const i18n = new VueI18n({
    locale: locale, // set locale
    fallbackLocale: 'en',
    messages: {}, // set locale messages
})

export async function loadLanguageAsync(lang: string): Promise<boolean> {
    // split locale into language
    lang = lang.substr(0, 2)

    if (!supportedLanguages.includes(lang)) {
        // Fallback to default
        lang = 'en'
    }

    if (isRtl(lang)) {
        // Enable rtl on body element
        document.body.dir = 'rtl' // other things are defined in body.scss
    } else {
        document.body.dir = 'ltr'
    }

    // language already loaded, skip importing
    if (Object.keys(translations).includes(lang)) {
        return true
    }

    // language not loaded yet, import from json and store in translations
    const messages = await import(/* webpackChunkName: "lang-[request]" */ `@dorst/frontend-translations/src/${lang}.json`)
    if (messages) {
        translations = {...translations, [lang]: messages.default}
        i18n.setLocaleMessage(lang, messages.default)
        return true
    }
    return false
}

export function isRtl(lang?: string): boolean {
    const language = lang ?? i18n.locale.substr(0, 2)
    return rtlLanguages.includes(language)
}

export function applyRtlWindow(x: number, lang?: string): number {
    const language = lang ?? i18n.locale.substr(0, 2)
    return isRtl(language) ? window.innerWidth - x : x
}

export async function switchLanguage(locale: string, shouldApplyOverrides = false) {
    if (i18n.locale == locale) {
        return
    }

    await loadLanguageAsync(locale)
    i18n.locale = locale

    if (shouldApplyOverrides) {
        applyOverrides(locale.substr(0, 2))
    }
}

const l = locale

// Load the language async (we do not bundle a single language into this build!)
export async function load() {
    await loadLanguageAsync(l)
}

export async function applyShopTranslationOverrides(shopTextPersonalisation: Array<PersonalisedText> = [], currentLanguage: string) {
    createOverrideMap(shopTextPersonalisation)
    applyOverrides(currentLanguage)
}

// merges overrides on top of translations (or clears them) on shop change
function applyOverrides(currentLanguage: string) {
    const copy = {...translations[currentLanguage]}

    if (translationOverrides[currentLanguage]) {
        for (const [path, text] of Object.entries(translationOverrides[currentLanguage])) {
            updateObjProp(copy, text, path)
        }
    }

    i18n.setLocaleMessage(currentLanguage, copy)
}

function createOverrideMap(shopTextPersonalisation: Array<PersonalisedText>) {
    const overrideMap = {}
    for (const personalisedText of shopTextPersonalisation) {
        for (const locale of Object.keys(personalisedText.value.translations)) {
            const overrideForLanguage = personalisedText.value.getForLanguageStrict(locale as Language)

            if (overrideForLanguage?.length) {
                overrideMap[locale] = {...overrideMap[locale], [personalisedText.id]: overrideForLanguage}
            }
        }
    }

    translationOverrides = overrideMap
}

const updateObjProp = (obj: Record<string, any>, value: string, propPath: string) => {
    const [head, ...rest] = propPath.split('.')

    if (rest.length === 0) {
        obj[head] = value
    } else {
        updateObjProp(obj[head], value, rest.join('.'))
    }
}
