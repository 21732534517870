var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "stepper",
      class: { "has-visible-value": _vm.hasVisibleValue },
      attrs: { "data-testid": "stepper" }
    },
    [
      _c(
        "button",
        {
          staticClass: "button stepper-minus-button",
          attrs: { disabled: _vm.decrementButtonDisabled },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.minus($event)
            }
          }
        },
        [_c("span", { staticClass: "icon minus" })]
      ),
      _vm._v(" "),
      !_vm.hasVisibleValue
        ? _c("hr")
        : _vm._t(
            "value",
            [
              _c("span", { staticClass: "stepper-value" }, [
                _vm._v(_vm._s(_vm.checkboxValue))
              ])
            ],
            { value: _vm.checkboxValue }
          ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button stepper-plus-button",
          attrs: { disabled: _vm.incrementButtonDisabled },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.plus($event)
            }
          }
        },
        [_c("span", { staticClass: "icon plus" })]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }