import {
    ArrayDecoder,
    AutoEncoder,
    BooleanDecoder,
    EnumDecoder,
    field,
    IntegerDecoder,
    ObjectData,
    PartialWithoutMethods,
    StringDecoder,
} from '@simonbackx/simple-encoding'
import {DateTime} from 'luxon'
import {v4 as uuidv4} from 'uuid'

import {AvailabilityPeriodType, Version} from '../index'
import {ConsumptionOptionType} from './ConsumptionOptions'
import {Image} from './Image'
import {Language, TranslatedString, TranslatedStringDecoder} from './Language'
import {LegacyProduct} from './LegacyProduct'
import {Option} from './Option'
import {OptionGroup, OptionGroupGroup} from './OptionGroup'
import {ProductAvailabilityPeriod} from './ProductAvailabilityPeriod'
import {ProductMeta, VariantMeta} from './ProductMeta'
import {ProductPrice} from './ProductPrice'
import {IDTag} from './Tag'
import {WeekDay, WeekdayHelper} from './WeekDay'
import {Formatter} from '@dorst/validation'

interface i18n {
    t: (key: string, options?: any) => string
}

export class ProductVariant extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: TranslatedStringDecoder})
    name: TranslatedString = new TranslatedString({})

    /**
     * The price of this variant.
     */
    @field({decoder: IntegerDecoder})
    price = 0

    @field({decoder: new ArrayDecoder(OptionGroup)})
    optionGroups: Array<OptionGroup> = []

    get isValid(): boolean {
        return this.optionGroups.every(optionGroup => optionGroup.isValid)
    }

    get hasOptionGroups(): boolean {
        return this.optionGroups.length > 0
    }

    /** Hold which optionGroups are related and should be shown as 1 */
    @field({decoder: new ArrayDecoder(OptionGroupGroup), optional: true})
    optionGroupLinks: Array<OptionGroupGroup> = []

    @field({decoder: BooleanDecoder})
    enabled = true

    @field({decoder: BooleanDecoder})
    default = false

    @field({decoder: VariantMeta, optional: true})
    meta?: VariantMeta

    getName(lang: Language): string {
        if (this.name.isEmpty()) {
            return this.defaultName(lang)
        }
        return this.name.getForLanguage(lang)
    }

    defaultName(lang: Language): string {
        switch (lang) {
            case Language.Dutch:
                return 'Naamloos'
            case Language.French:
                return 'Sans titre'
            case Language.English:
                return 'Unnamed'
            case Language.Arabic:
                return 'بدون اسم'
            case Language.Portuguese:
                return 'Sem nome'
            default:
                return 'Unnamed'
        }
    }

    bundleOptionsForVariant(): Array<Array<OptionGroup>> {
        if (this.optionGroupLinks.length == 0) {
            return this.optionGroups.map(el => [el])
        } else {
            const groups: Array<Array<OptionGroup>> = []
            const addedIds: Array<string> = []
            for (const optionGroup of this.optionGroups) {
                if (addedIds.includes(optionGroup.id)) {
                    continue
                }
                const optionGroupLink = this.optionGroupLinks.find(el => el.optionGroupIds.includes(optionGroup.id))
                if (optionGroupLink !== undefined) {
                    const group = this.optionGroups.filter(el => optionGroupLink.optionGroupIds.includes(el.id))
                    addedIds.push(...optionGroupLink.optionGroupIds)
                    groups.push(group)
                } else if (!addedIds.includes(optionGroup.id)) {
                    groups.push([optionGroup])
                    addedIds.push(optionGroup.id)
                }
            }
            return groups
        }
    }

}

export class Product extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: TranslatedStringDecoder})
    name = new TranslatedString({})

    @field({decoder: TranslatedStringDecoder})
    description = new TranslatedString({})

    @field({decoder: TranslatedStringDecoder})
    recipe = new TranslatedString({})

    @field({decoder: StringDecoder, nullable: true})
    preparationLocationId: string | null = null

    @field({decoder: BooleanDecoder})
    enabled = true

    @field({decoder: new ArrayDecoder(Image)})
    images: Array<Image> = []

    // Empty array means always available
    @field({decoder: new ArrayDecoder(ProductAvailabilityPeriod)})
    availabilityPeriods: Array<ProductAvailabilityPeriod> = []

    @field({decoder: StringDecoder, nullable: true, version: 73, defaultValue: () => null})
    categoryId: string | null = null // default to Food & Beverages for Facebook (will never show up in categories on backoffice).

    @field({decoder: new ArrayDecoder(IDTag), version: 73, upgrade: () => [], defaultValue: () => []})
    tags: Array<IDTag>

    @field({decoder: IntegerDecoder})
    VAT = 21

    @field({decoder: new EnumDecoder(ConsumptionOptionType)})
    consumptionMode: ConsumptionOptionType = ConsumptionOptionType.DineIn

    @field({decoder: IntegerDecoder, nullable: true, optional: true})
    stock: number | null = null

    @field({decoder: ProductMeta, optional: true})
    meta?: ProductMeta

    @field({decoder: new ArrayDecoder(ProductVariant)})
    variants: Array<ProductVariant> = [ProductVariant.create({})]

    /**
     * When true, show the price of this variant in the radio button row
     * When false, show the difference between the cheapest variant and this one ( default for topbrands )
     * */
    @field({decoder: BooleanDecoder, optional: true})
    showAbsolutePrices: boolean = true

    get defaultVariant(): ProductVariant {
        const found = this.variants.find(el => el.default)
        return found ?? this.variants[0]
    }

    getName(lang: Language): string {
        if (this.name.isEmpty()) {
            return this.defaultName(lang)
        }
        return this.name.getForLanguage(lang)
    }

    getNameForI18n(i18n: {locale: string}): string {
        if (this.name.isEmpty()) {
            return this.defaultNameForI18n(i18n)
        }
        return this.name.getForI18n(i18n as any)
    }

    defaultName(lang: Language): string {
        switch (lang) {
            case Language.Dutch:
                return 'Naamloos'
            case Language.French:
                return 'Sans titre'
            case Language.English:
                return 'Unnamed'
            case Language.Arabic:
                return 'بدون اسم'
            case Language.Portuguese:
                return 'Sem nome'
            default:
                return 'Unnamed'
        }
    }

    defaultNameForI18n(i18n: {locale: string}) {
        let lang = i18n.locale.substr(0, 2)
        if (!(Object.values(Language) as Array<string>).includes(lang)) {
            lang = Language.English
        }
        return this.defaultName(lang as Language)
    }

    getBestPrice(): number {
        return this.getAllPrices()[0] ?? 0
    }

    getAllPrices(): Array<number> {
        return this.variants.map(el => el.price).sort((a, b) => a - b)
    }

    /**
     * Returns a score for a given query
     * @param query
     * @param i18n
     */
    matchQuery(query: string, i18n: {locale: string}): number {
        const lowerQuery = query.toLowerCase()
        if (
            this.getNameForI18n(i18n).toLowerCase().includes(lowerQuery)
        ) {
            return 1
        }
        return 0
    }

    /**
     * Sort logic:
     * 1: Place date ranges first
     * 2: Order them by startDate (ascending)
     * 3: Order the other weekdays (Monday in front of Tuesday etc.)
     */
    get sortedAvailabilityPeriods(): Array<ProductAvailabilityPeriod> {
        return [...this.availabilityPeriods].sort((a: ProductAvailabilityPeriod, b: ProductAvailabilityPeriod) => {
            const aIsDateRange = a.type === AvailabilityPeriodType.DateRange
            const bIsDateRange = b.type === AvailabilityPeriodType.DateRange
            if (aIsDateRange && !bIsDateRange) {
                return -1
            } else if (bIsDateRange && !aIsDateRange) {
                return 1
            } else if (aIsDateRange && bIsDateRange) {
                return (a.dateRange?.startDate?.getTime() ?? 0) - (b.dateRange?.startDate?.getTime() ?? 0)
            } else {
                return (a.days[0] ?? 0) - (b.days[0] ?? 0)
            }
        })
    }

    getAvailabilityTexts(timezone: string, i18n: i18n): Array<string> {
        return this.sortedAvailabilityPeriods.reduce<Array<string>>((acc, period) => {
            if (!period.isValid()) {
                return acc
            }
            if (period.type === AvailabilityPeriodType.DateRange) {
                if (period.dateRange === undefined
                    || period.dateRange.startDate === undefined
                    || period.dateRange.endDate === undefined) {
                    return acc
                }

                const {startDate, endDate} = period.dateRange

                const from = DateTime.fromJSDate(startDate).toFormat('dd/MM/yy')
                const to = DateTime.fromJSDate(endDate).toFormat('dd/MM/yy')

                if (DateTime.now() > DateTime.fromJSDate(startDate)) {
                    acc.push(i18n.t('common.availability.to', {to}))
                } else if (from === to) {
                    acc.push(i18n.t('common.availability.on', {text: from}))
                } else {
                    acc.push(i18n.t('common.availability.fromTo', {from, to}))
                }
            } else {
                let dayText = ''
                if (period.days.length === 7) {
                    dayText = i18n.t('common.availability.everyDay')
                } else if (period.days.length < 4) {
                    // List days available
                    dayText = period.days.map(d => WeekdayHelper.getName(d, i18n)).join(', ')
                } else {
                    // List days not available (for example: not on monday)
                    const inverseDays: Array<WeekDay> = []
                    for (const day of Object.values(WeekDay)) {
                        if (typeof day !== 'number' || period.days.includes(day)) {
                            continue
                        }
                        inverseDays.push(day)
                    }
                    const inverseDaysWithNames = inverseDays
                        .map(d => WeekdayHelper.getName(d, i18n))
                        .join(', ')

                    dayText = `${i18n.t('common.availability.everyDayExceptOn')} ${inverseDaysWithNames}`
                }

                // Add the start and end hour to the text
                let hourText = ''

                const startMinutesToTime = Formatter.minutesToTime(period.startTime)
                const endMinutesToTime = Formatter.minutesToTime(period.endTime)
                if (period.startTime > 0 || period.endTime < 24 * 60) {
                    hourText = i18n.t(
                        'common.availability.fromTo',
                        {from: startMinutesToTime, to: endMinutesToTime},
                    )
                }

                acc.push(`${dayText} ${hourText}`.trim())
            }
            return acc
        }, [])
    }

    get validAvailabilityPeriodWeekdays() {
        return this.availabilityPeriods
            .filter(p => p.isValid()
                && p.type === AvailabilityPeriodType.WeekDays)
    }

    get validAvailabilityPeriodDateRanges() {
        return this.availabilityPeriods
            .filter(p => p.isValid()
                && p.type === AvailabilityPeriodType.DateRange)
    }

    /**
     * Check if the product is available on a specified date using its `availabilityPeriods`.
     * Used to check if a dine-in product is orderable (no need to check time slots).
     * @param forDate Date (with time) to check availability for.
     * @param timezone Timezone of the shop to use.
     * @param matchWhenAvailableLaterToday When passing true it will also match if it is unavailable
     * right now but will become available later today (e.g. it's 12.00 and from 16.00 onwards the
     * product is available)
     * @returns {boolean} True when the product can be ordered on the specified date (or later when
     * matchWhenAvailableLaterToday is true). False otherwise.
     */
    isAvailableOnDate(forDate: Date, timezone: string, matchWhenAvailableLaterToday = false): boolean {
        // If we have date ranges, at least one should match the date.
        const dateRangeAvailabilityPeriods = this.validAvailabilityPeriodDateRanges
        const hasMatchingDateRangePeriods = dateRangeAvailabilityPeriods.length === 0
            || dateRangeAvailabilityPeriods.some(period => period.doesMatchDate(forDate, timezone, matchWhenAvailableLaterToday))

        // If we have a weekdays period, at least one should match the date.
        const weekDayAvailabilityPeriods = this.validAvailabilityPeriodWeekdays
        const hasMatchingWeekDayPeriods = weekDayAvailabilityPeriods.length === 0
            || weekDayAvailabilityPeriods.some(period => period.doesMatchDate(forDate, timezone, matchWhenAvailableLaterToday))

        return hasMatchingDateRangePeriods && hasMatchingWeekDayPeriods
    }

    /**
     * Returns true for all ConsumptionOptionTypes that are not dinein.
     * If it's DineIn, we will check if you can order the product now or later today.
     * @param timezone - Timezone of the shop
     * @returns {boolean} - Whether we need to show the product to the consumer or not.
     */
    visibleIn(timezone: string): boolean {
        return this.consumptionMode !== ConsumptionOptionType.DineIn
            || this.isAvailableOnDate(new Date(), timezone, true)
    }

    getAvailableLanguages(): Array<Language> {
        let langObjects = [this.name.translations, this.description.translations]
        for (const variant of this.variants) {
            if (variant.optionGroups.length > 0) {
                const optionGroupTranslations = variant.optionGroups.map(el => el.name.translations)
                langObjects = [...langObjects, ...optionGroupTranslations]
            }
        }
        return Object.keys(Object.assign({}, ...langObjects)) as Array<Language>
    }

    clone(): Product {
        return Product.decode(new ObjectData(this.encode({version: Version}), {version: Version}))
    }

    cloneWithNewIds(): Product {
        return Product.create({
            id: uuidv4(),
            name: this.name,
            description: this.description,
            recipe: this.recipe,
            preparationLocationId: this.preparationLocationId,
            enabled: this.enabled,
            images: this.images.map(image => {
                return Image.create({
                    ...image,
                    id: uuidv4(),
                })
            }),
            availabilityPeriods: this.availabilityPeriods.map(availabilityPeriod => {
                return ProductAvailabilityPeriod.create({
                    ...availabilityPeriod,
                    id: uuidv4(),
                })
            }),
            categoryId: this.categoryId,
            tags: this.tags,
            VAT: this.VAT,
            consumptionMode: this.consumptionMode,
            stock: this.stock,
            meta: this.meta,
            variants: this.variants.map(variant => {
                return ProductVariant.create({
                    ...variant,
                    id: uuidv4(),
                    optionGroups: variant.optionGroups.map(optionGroup => {
                        return OptionGroup.create({
                            ...optionGroup,
                            id: uuidv4(),
                        })
                    }),
                    optionGroupLinks: variant.optionGroupLinks.map(optionGroupLink => {
                        return OptionGroupGroup.create({
                            ...optionGroupLink,
                            id: uuidv4(),
                        })
                    }),
                })
            }),
            showAbsolutePrices: this.showAbsolutePrices,
        })
    }

    stripNonConsumerData() {
        delete this.meta
        for (const variant of this.variants) {
            delete variant.meta
            if (variant.optionGroups.length > 0) {
                for (const optionGroup of variant.optionGroups) {
                    delete optionGroup.meta
                    for (const option of optionGroup.options) {
                        delete option.meta
                    }
                }
            }
        }
    }

    static createConsumer(p: PartialWithoutMethods<Product>): Product {
        const product = Product.create({...p})
        product.stripNonConsumerData()
        return product
    }

    hasAdditionalChoices() {
        return this.variants.length > 1
            || this.variants[0].optionGroups.length > 0
            || (
                this.variants[0].optionGroups.length == 1
                && this.variants[0].optionGroups[0].options.length > 1
            )
    }

    /**
     * Remove certain variants/optiongroups or change price.
     * */
    static formatProductForOption(product: Product, option: Option): Product {
        if (option.productLink === undefined) {
            return product
        }
        product = product.clone()
        const newPrice = option.productLink.useProductPrice ? null : option.priceChange
        const cheapestVariant: number = product.getBestPrice()
        product.variants = product.variants.reduce<Array<ProductVariant>>((accVariants, currVariant) => {
            if (option.productLink!.disabledVariants.includes(currVariant.id)) {
                return accVariants
            }
            if (option.productLink!.disabledOptionGroups.length > 0) {
                currVariant.optionGroups = currVariant.optionGroups.filter(optionGroup => !option.productLink!.disabledOptionGroups.includes(optionGroup.id))
            }
            if (newPrice !== null) {
                currVariant.price = currVariant.price - cheapestVariant + newPrice
            }
            accVariants.push(currVariant)
            return accVariants
        }, [])
        return product
    }

    static fromLegacy(legacyProduct: LegacyProduct): Product {
        return Product.create({
            ...legacyProduct,
            variants: legacyProduct.prices.map(price => {
                return ProductVariant.create({
                    id: price.id,
                    price: price.price,
                    optionGroups: legacyProduct.optionGroups.map(og => {
                        og.options = og.options.map(el => {
                            return Option.create({...el, id: uuidv4()})
                        })
                        return OptionGroup.create({...og, id: uuidv4()})
                    }),
                    default: true,
                    enabled: true,
                    name: new TranslatedString(price.name ? {[Language.Dutch]: price.name} : {}),
                })
            }),
        })
    }

    static toLegacy(product: Product): LegacyProduct {
        return LegacyProduct.create({
            ...product,
            optionGroups: product.variants[0].optionGroups,
            prices: product.variants.map(el => {
                return ProductPrice.create({
                    id: el.id,
                    price: el.price,
                    name: el.name.getForLanguage(Language.Dutch),
                })
            }),
        })
    }
}
