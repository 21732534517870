import {Decoder} from '@simonbackx/simple-encoding'
import {RequestResult} from '@simonbackx/simple-networking'

import {ServerManager} from '../classes/ServerManager'
import {ShopDomain as ShopDomainStruct} from '@dorst/structures'

type RequiredNonNull<T> = {
    [P in keyof T]-?: NonNullable<T[P]>
}

export async function ShopDomain(id: string | number): Promise<RequestResult<ShopDomainStruct>> {
    return new Promise((resolve, reject) => {
        ServerManager.getServer()
            .request({
                method: 'GET',
                path: `/consumer/shops/${id}/domain`,
                body: undefined,
                query: undefined,
                timeout: 5 * 60 * 1000,
                decoder: ShopDomainStruct as Decoder<ShopDomainStruct>,
            })
            .then(resolve).catch(reject)
    })
}
