var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "tag",
      staticClass: "tag",
      class: { selectable: _vm.selectable },
      on: { click: _vm.onClick }
    },
    [
      _vm.$slots.left
        ? _c("div", { staticClass: "left" }, [_vm._t("left")], 2)
        : _vm._e(),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _vm.removeable
        ? _c("button", { staticClass: "button icon close" })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }