var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DRSTBoxItem",
    {
      staticClass: "telephone-row",
      attrs: { "element-name": _vm.elementName, selectable: true },
      on: {
        click: function($event) {
          _vm.select()
          _vm.$emit("click", $event)
        }
      }
    },
    [
      _c("div", [
        _vm.label
          ? _c("div", { attrs: { name: "label" } }, [
              _c(
                "label",
                { staticClass: "text-label", attrs: { for: "input" } },
                [
                  _vm._v(
                    "\n                " + _vm._s(_vm.label) + "\n            "
                  )
                ]
              )
            ])
          : _c(
              "div",
              [
                _c("TelephoneInput", {
                  ref: "input",
                  attrs: {
                    "default-country-code": _vm.defaultCountryCode,
                    placeholder: _vm.placeholder,
                    disabled: _vm.disabled
                  },
                  model: {
                    value: _vm.phoneNumber,
                    callback: function($$v) {
                      _vm.phoneNumber = $$v
                    },
                    expression: "phoneNumber"
                  }
                })
              ],
              1
            )
      ]),
      _vm._v(" "),
      _vm.label
        ? _c(
            "template",
            { slot: "right" },
            [
              _c("TelephoneInput", {
                ref: "input",
                attrs: {
                  "default-country-code": _vm.defaultCountryCode,
                  placeholder: _vm.placeholder,
                  disabled: _vm.disabled
                },
                model: {
                  value: _vm.phoneNumber,
                  callback: function($$v) {
                    _vm.phoneNumber = $$v
                  },
                  expression: "phoneNumber"
                }
              }),
              _vm._v(" "),
              _vm._t("right")
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }