import {AutoEncoder, field, IntegerDecoder, StringDecoder} from '@simonbackx/simple-encoding'

import {Option} from './Option'
import {OptionGroup} from './OptionGroup'

export class CartItemOption extends AutoEncoder {
    @field({decoder: OptionGroup})
    optionGroup: OptionGroup

    @field({decoder: Option})
    option: Option

    /** If there is a product linked to the option we add a new cartItem and save its id here */
    @field({decoder: StringDecoder, nullable: true, optional: true})
    subCartItemId: string | null = null

    @field({decoder: IntegerDecoder, defaultValue: () => 1})
    amount: number
}
