export class UtilHelperStatic {
    copyToClipboard(text) {
        /* create text field */
        const input = document.createElement('input')
        input.style.opacity = '0'
        input.value = text
        document.body.appendChild(input)
        /* Select the text */
        input.select()
        input.setSelectionRange(0, 99999) /* For mobile devices */
        /* Copy the text inside the text field */
        document.execCommand('copy')
        /* Cleanup input */
        input.remove()
    }
}

const instance = new UtilHelperStatic()
export const UtilHelper = instance
