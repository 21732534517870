import {AutoEncoder, field, NumberDecoder, StringDecoder} from '@simonbackx/simple-encoding'

import {CartItemOption} from './CartItemOption'

export class IDCartItemOption extends AutoEncoder {
    @field({decoder: StringDecoder})
    optionGroupId: string

    @field({decoder: StringDecoder})
    optionId: string

    @field({decoder: NumberDecoder, defaultValue: () => 1})
    amount: number

    /** If there is a product linked to the option we add a new cartItem and save its id here */
    @field({decoder: StringDecoder, nullable: true, optional: true})
    subcartItemId: string | null = null

    static from(option: CartItemOption): IDCartItemOption {
        return IDCartItemOption.create({
            optionGroupId: option.optionGroup.id,
            optionId: option.option.id,
            amount: option.amount,
            subcartItemId: option.subCartItemId,
        })
    }
}
