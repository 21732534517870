import {AutoEncoder, BooleanDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'

export class ShopExternalLinks extends AutoEncoder {
    @field({decoder: BooleanDecoder, optional: true})
    enabled = false

    @field({decoder: StringDecoder, nullable: true, optional: true})
    facebook: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    instagram: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    website: string | null = null
}

export const ShopExternalLinksPatch = ShopExternalLinks.patchType()
