






























import {ComponentWithProperties, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {POSSystemActions} from '../actions/POSSystem'
import {CartManager} from '../classes/CartManager'
import OrderDetailBox from './OrderDetailBox.vue'
import OrderDetailView from './OrderDetailView.vue'
import OrderItemRow from './OrderItemRow.vue'
import OrderView from './OrderView.vue'
import {DRSTNavigationBar, Spinner} from '@dorst/components'
import {OrderConsumer, POSSystemOrderValidationStatus} from '@dorst/structures'

@Component({
    components: {
        DRSTNavigationBar,
        Spinner,
        OrderItemRow,
        OrderDetailBox,
    },
})
export default class OrderPosValidationPollingView extends Mixins(NavigationMixin) {
    @Prop({required: true})
    order: OrderConsumer

    /**
     * After how many minutes of polling we have to mark it as failed?
     */
    pollingDurationInMinutes = 2

    /**
     * How long should we wait between requests?
     */
    pollingSpeedInSeconds = 5

    posValidated = POSSystemOrderValidationStatus.PENDING
    pollingInterval?: number

    get errorOnValidation() {
        return this.posValidated === POSSystemOrderValidationStatus.ERROR
    }

    pollForStatus() {
        if (this.pollingInterval !== undefined) {
            clearInterval(this.pollingInterval)
            delete this.pollingInterval
        }

        const handler = () => {
            void POSSystemActions.PosOrderValidationStatus(this.order)
                .then((status) => {
                    this.posValidated = status
                    if (this.posValidated === POSSystemOrderValidationStatus.VALID) {
                        clearInterval(this.pollingInterval)
                        delete this.pollingInterval
                        this.show(new ComponentWithProperties(OrderView, {order: this.order}))
                    } else if (this.posValidated === POSSystemOrderValidationStatus.ERROR) {
                        clearInterval(this.pollingInterval)
                        delete this.pollingInterval
                    }
                })
        }
        this.pollingInterval = setInterval(handler, this.pollingSpeedInSeconds * 1000) as unknown as number
    }

    mounted() {
        this.pollForStatus()
        setTimeout(() => {
            clearInterval(this.pollingInterval)
            delete this.pollingInterval
            this.posValidated = POSSystemOrderValidationStatus.ERROR
        }, this.pollingDurationInMinutes * 60 * 1000) // if not validated after 2 minutes show error screen
    }

    showDetails() {
        this.present(new ComponentWithProperties(OrderDetailView, {order: this.order}))
    }
}
