



























import {Component, Prop, Vue} from 'vue-property-decorator'

import {TranslatedString} from '@dorst/structures'

@Component({
    model: {
        prop: 'title',
        event: 'input',
    },
})
export default class DRSTThumbnail extends Vue {
    @Prop({default: '', type: String})
    title!: string

    @Prop({default: {}, type: TranslatedString})
    description!: TranslatedString

    @Prop({default: '', type: String})
    imageUrl!: string

    @Prop({default: false, type: Boolean})
    selectable!: boolean

    @Prop({default: 'cover', type: String})
    imageFit: string

    get translatedDescription() {
        return this.description.getForI18n(this.$i18n as any)
    }
}
