import {Data, Decoder, Encodeable, EncodeContext} from '@simonbackx/simple-encoding'
import {SimpleError} from '@simonbackx/simple-errors'

export class PartialResponse<Result extends Encodeable | Array<Encodeable>> implements Encodeable {
    result: Result
    errors?: Array<SimpleError>

    constructor(data: {result: Result; errors?: Array<SimpleError>}) {
        this.result = data.result
        this.errors = data.errors
    }

    encode(context: EncodeContext) {
        return {
            result: Array.isArray(this.result) ? this.result.map(r => r.encode(context)) : (this.result as Encodeable).encode(context),
            errors: this.errors?.map(r => r.encode(context)),
        }
    }
}

export class PartialResponseBuilder<Result extends Encodeable | Array<Encodeable>> implements Decoder<PartialResponse<Result>> {
    resultDecoder: Decoder<Result>

    constructor(resultDecoder: Decoder<Result>) {
        this.resultDecoder = resultDecoder
    }

    decode(data: Data) {
        return new PartialResponse<Result>({
            result: data.field('result').decode(this.resultDecoder),
            errors: data.optionalField('errors')?.array(SimpleError),
        })
    }
}
