var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drst-view kiosk-mode" }, [
    _c(
      "div",
      { staticClass: "kiosk-status" },
      [
        _c("span", [
          _vm._v(_vm._s(_vm.$t("consumer.kioskMode.currentStatus")))
        ]),
        _vm._v(" "),
        _c("Toggle", {
          model: {
            value: _vm.kioskModeEnabled,
            callback: function($$v) {
              _vm.kioskModeEnabled = $$v
            },
            expression: "kioskModeEnabled"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c("a", { staticClass: "button primary", on: { click: _vm.goToMenu } }, [
      _vm._v(_vm._s(_vm.$t("consumer.kioskMode.goToMenu")))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }