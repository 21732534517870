import {Data, Decoder} from '@simonbackx/simple-encoding'
import {SimpleError} from '@simonbackx/simple-errors'

import {I18n} from './I18n'
import {Currency} from '@dorst/enums'

// If we add countries check if the countryCodes.json has the same CountryCode !
const countrySlugs = ['BE', 'NL', 'FR', 'AE', 'GB', 'IN'] as const
export type Country = typeof countrySlugs[number]

export function ParseCountrySlug(slug: string): Country {
    if (countrySlugs.includes(slug as Country)) {
        return slug as Country
    }
    throw new SimpleError({
        code: 'invalid_field',
        message: 'Received an invalid country',
        human: 'Dit land is ongeldig',
        field: 'country',
    })
}

class CountryDecoderStatic implements Decoder<Country> {
    decode(data: Data): Country {
        const str = data.string.toUpperCase()

        if (str.length != 2) {
            throw new SimpleError({
                code: 'invalid_field',
                message: 'Received an invalid country',
                human: 'Dit land is ongeldig',
                field: data.currentField,
            })
        }
        return ParseCountrySlug(str)
    }
}

// We export an instance to prevent creating a new instance every time we need to decode a number
export const CountryDecoder = new CountryDecoderStatic()

export class CountryHelper {
    static getName(country: Country, i18n: I18n) {
        switch (country) {
            case 'BE':
                return i18n.t('countries.belgium')
            case 'NL':
                return i18n.t('countries.netherlands')
            case 'FR':
                return i18n.t('countries.france')
            case 'AE':
                return i18n.t('countries.unitedArabEmirates')
            case 'GB':
                return i18n.t('countries.unitedKingdom')
            case 'IN':
                return i18n.t('countries.india')
            default: {
                throw new Error(`Country ${country} not supported`)
            }
        }
    }

    /** pass the domain for a specific domain list. */
    static getList(i18n: I18n, domain?: string): Array<{text: string; value: Country}> {
        console.log(domain)
        const allDomains: Array<{text: string; value: Country}> = [
            {
                text: this.getName('BE', i18n),
                value: 'BE',
            },
            {
                text: this.getName('NL', i18n),
                value: 'NL',
            },
            {
                text: this.getName('FR', i18n),
                value: 'FR',
            },
        // Temporary removed GB
        // {
        //     text: this.getName("GB", i18n),
            //     value: "GB"
            // },
            {
                text: this.getName('AE', i18n),
                value: 'AE',
            },
            {
                text: this.getName('IN', i18n),
                value: 'IN',
            },
        ]
        let allowed = ['FR', 'BE', 'NL', 'AE', 'IN']
        if (domain === 'soif.app') {
            allowed = ['FR', 'BE', 'NL']
        } else if (domain === 'dorst.app') {
            allowed = ['BE', 'NL', 'FR']
        } else if (domain === 'order24.com') {
            allowed = ['AE', 'IN']
        }

        return allDomains.filter(el => allowed.includes(el.value))
    }

    static getVATRates(country: Country) {
        switch (country) {
            case 'BE':
                return [0, 6, 12, 21]
            case 'NL':
                return [9, 21]
            case 'FR':
                return [2.1, 5.5, 10, 20]
            case 'AE':
                return [5]
            case 'GB':
                return [0, 5, 20]
            case 'IN':
                return [0, 2.5, 5, 6, 12, 18, 28]
            default: {
                throw new Error(`Country ${country} not supported`)
            }
        }
    }

    static getDefaultVATRate(country: Country) {
        switch (country) {
            case 'BE':
            case 'NL':
                return 21
            case 'AE':
                return 5
            case 'FR':
            case 'GB':
                return 20
            case 'IN':
                return 18
            default: {
                throw new Error(`Country ${country} not supported`)
            }
        }
    }

    static getVATForTransactionFees(country: Country) {
        switch (country) {
            case 'BE':
                return 0.21
            case 'AE':
                return 0.05
            case 'NL':
            case 'FR':
            case 'IN':
            default: {
                return 0
            }
        }
    }

    static getCurrency(country: Country): Currency {
        switch (country) {
            case 'BE':
                return Currency.EUR
            case 'NL':
                return Currency.EUR
            case 'FR':
                return Currency.EUR
            case 'AE':
                return Currency.AED
            case 'GB':
                return Currency.GBP
            case 'IN':
                return Currency.INR
            default: {
                throw new Error(`Country ${country} not supported`)
            }
        }
    }

    static getTimezones(country: Country): Array<string> {
        switch (country) {
            case 'BE':
                return ['Europe/Brussels']
            case 'NL':
                return ['Europe/Amsterdam']
            case 'FR':
                return ['Europe/Paris']
            case 'AE':
                return ['Asia/Dubai']
            case 'GB':
                return ['Europe/London']
            case 'IN':
                return ['Asia/Kolkata']
            default: {
                throw new Error(`Country ${country} not supported`)
            }
        }
    }
}
