import {field, IntegerDecoder, StringDecoder} from '@simonbackx/simple-encoding'

import {DecodableLanguageRecord} from '../DecodableRecord'
import {Language} from '../Language'
import {DeliverectTranslatable} from './DeliverectTranslatable'

export class DeliverectModifier extends DeliverectTranslatable {
    @field({decoder: StringDecoder})
    _id: string

    @field({decoder: StringDecoder})
    account: string

    @field({decoder: StringDecoder})
    location: string

    @field({decoder: IntegerDecoder})
    productType: number

    @field({decoder: StringDecoder})
    plu: string

    @field({decoder: IntegerDecoder})
    price: number

    @field({decoder: StringDecoder})
    name: string

    @field({decoder: DecodableLanguageRecord})
    nameTranslations: Partial<Record<Language, string>> = {}

    @field({decoder: StringDecoder})
    description: string

    @field({decoder: DecodableLanguageRecord})
    descriptionTranslations: Partial<Record<Language, string>> = {}

    @field({decoder: IntegerDecoder, optional: true})
    deliveryTax?: number

    @field({decoder: IntegerDecoder, optional: true})
    takeawayTax?: number

    @field({decoder: IntegerDecoder, optional: true})
    eatInTax?: number

    @field({decoder: StringDecoder})
    imageUrl: string

    @field({decoder: IntegerDecoder})
    max: number

    @field({decoder: IntegerDecoder})
    min: number

    @field({decoder: IntegerDecoder, optional: true})
    defaultQuantity?: number

    // Unused possible fields
    // priceLevels: Record<string, unknown>
    // sortOrder: number
    // multiply: number
    // posProductId: string
    // posProductCategoryId: Array<string>
    // subProducts: Array<unknown>
    // productTags: Array<unknown>
    // posCategoryIds: Array<string>
    // multiMax: number
    // capacityUsages: Array<unknown>
    // parentId: string
    // snoozed: boolean
    // subProductSortOrder: Array<unknown>
}
