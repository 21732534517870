var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "verify-icon" },
    [
      _vm.valid
        ? _c("div", { staticClass: "icon-wrapper check" }, [
            _c("div", { staticClass: "icon check" })
          ])
        : _vm.valid === null
        ? _c("Spinner", { attrs: { name: "loading-spinner" } })
        : _c("div", { staticClass: "icon error" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }