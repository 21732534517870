import {AutoEncoder, EnumDecoder, field} from '@simonbackx/simple-encoding'

import {ConsumptionOptionType} from './ConsumptionOptions'
import {IDCart} from './IDCart'

export class IDValidateCart extends AutoEncoder {
    @field({decoder: IDCart})
    cart: IDCart

    @field({decoder: new EnumDecoder(ConsumptionOptionType)})
    consumptionMode: ConsumptionOptionType = ConsumptionOptionType.DineIn
}
