











import {ComponentWithProperties, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins} from 'vue-property-decorator'

import LanguageSwitcherContextMenu from './LanguageSwitcherContextMenu.vue'
import {applyRtlWindow} from '@dorst/frontend-translations'

@Component
export default class EditLanguageSwitch extends Mixins(NavigationMixin) {
    get currentLanguage() {
        return this.$i18n.locale.substr(0, 2)
    }

    selectOtherLanguage(event) {
        const displayedComponent = new ComponentWithProperties(
            LanguageSwitcherContextMenu,
            {
                x: applyRtlWindow(event.clientX),
                y: event.clientY + 10,
            },
        )
        this.present(displayedComponent.setDisplayStyle('overlay'))
    }
}
