





















import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({})
export default class Tag extends Vue {
    @Prop({required: true})
    title: string

    @Prop({required: false, default: true})
    removeable: boolean

    @Prop({required: false, default: true})
    selectable: boolean

    onClick() {
        if (this.removeable) {
            this.$emit('removeTag')
        }
    }
}
