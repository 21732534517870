import {AutoEncoder, field, StringDecoder} from '@simonbackx/simple-encoding'

import {Language, TranslatedString} from '../Language'

export class TBTranslateableText extends AutoEncoder {
    @field({decoder: StringDecoder, optional: true})
    languageId?: string

    @field({decoder: StringDecoder, optional: true})
    languageValue?: string
}

export class TBTranslateHelper {
    static getTranslation(translateableTexts: Array<TBTranslateableText>): TranslatedString {
        const mappedText: Partial<Record<Language, string>> = {}
        translateableTexts.forEach(el => {
            switch (el.languageId) {
                case 'NLB':
                    mappedText[Language.Dutch] = el.languageValue ?? ''
                    break
                case 'FRB':
                    mappedText[Language.French] = el.languageValue ?? ''
                    break
                case 'ENU':
                    mappedText[Language.English] = el.languageValue ?? ''
                    break
                default:
                    console.error(`Failed to get topbrands translation for language ${el.languageId}`)
                    break
            }
        })
        return new TranslatedString(mappedText)
    }
}
