import {AutoEncoder, EnumDecoder, field, NumberDecoder, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {TranslatedString, TranslatedStringDecoder} from './Language'
import {CurrencyType} from '@dorst/enums'
import {Formatter, SignDisplay} from '@dorst/validation'

export enum ExtraCostType {
    Inclusive = 'Inclusive',
    Exclusive = 'Exclusive',
}

export enum ExtraCostSymbolType {
    Valuta = 'Valuta',
    Percentage = 'Percentage',
}

export class ExtraCost extends AutoEncoder {
    @field({decoder: StringDecoder})
    id: string = uuidv4()

    @field({decoder: TranslatedStringDecoder})
    name: TranslatedString = new TranslatedString({})

    @field({decoder: new EnumDecoder(ExtraCostType)})
    extraCostType = ExtraCostType.Exclusive

    @field({decoder: NumberDecoder})
    value = 0

    @field({decoder: new EnumDecoder(ExtraCostSymbolType)})
    symbol: ExtraCostSymbolType = ExtraCostSymbolType.Valuta

    @field({decoder: NumberDecoder, nullable: true})
    min: number | null = null

    @field({decoder: NumberDecoder, nullable: true})
    max: number | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    productId: string | null = null

    calculatedValue(cartPrice: number): number {
        return this.symbol === ExtraCostSymbolType.Percentage ? (cartPrice / 100) * (this.value / 100) : this.value
    }

    format(
        {
            cartPrice,
            currency,
            locale,
            signDisplay,
        }: {
            cartPrice: number
            currency: CurrencyType
            locale: string | Array<string>
            signDisplay?: SignDisplay
        },
    ): string {
        const price = this.calculatedValue(cartPrice)
        const formattedPrice = Formatter.price({
            currencyCode: currency,
            display: 'symbol',
            locale,
            price,
            signDisplay,
        })

        if (this.symbol === ExtraCostSymbolType.Percentage) {
            return `(${Formatter.percentage(this.value)}) ${formattedPrice}`
        } else {
            return formattedPrice
        }
    }
}
