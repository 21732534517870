import {
    ArrayDecoder,
    AutoEncoder,
    BooleanDecoder,
    DateDecoder,
    EnumDecoder,
    field,
    IntegerDecoder,
    NumberDecoder,
    StringDecoder,
} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

export class InnovendMachine extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: StringDecoder})
    deviceName: string = ''

    @field({decoder: StringDecoder})
    deviceId: string = ''

    @field({decoder: StringDecoder})
    password: string = ''
}

export class InnovendSettings extends AutoEncoder {
    @field({decoder: BooleanDecoder})
    enabled: boolean = false

    @field({decoder: new ArrayDecoder(InnovendMachine)})
    machines: Array<InnovendMachine> = []
}

export enum InnovendStatus {
    CREATED = '1',
    FILLED = '3',
    DELIVERED = '6',
    CANCELLED = '8',
    RETURNED = '10',
    RETURN_COLLECTED = '12',
    /**
     * added this ourselves, only used om failed payment to no longer reserve its spot.
     */
    PAYMENT_CANCELLED = '98',
    /**
     * added this ourselves, only used when polling for the status on a wrong order.
     */
    ERROR = '99',
}

export class InnovendPickupInfo extends AutoEncoder {
    @field({decoder: StringDecoder})
    lockerName: string

    @field({decoder: StringDecoder})
    code: string

    /**
     * Passed as Base64 data blob: `data:image/png;base64,${base64OfImage}`
     */
    @field({decoder: StringDecoder})
    qrCode: string
}

export class InnovendStatusWrapper extends AutoEncoder {
    @field({decoder: new EnumDecoder(InnovendStatus)})
    status: InnovendStatus

    @field({decoder: InnovendPickupInfo, optional: true})
    innovendPickupInfo?: InnovendPickupInfo
}

export class InnovendPickupDelivery extends AutoEncoder {
    @field({decoder: StringDecoder})
    MachineId: string

    /**
     * Price to be paid at locker
     */
    @field({decoder: NumberDecoder, optional: true})
    Price: number = 0

    @field({decoder: NumberDecoder, optional: true})
    PriceExclVat: number = 0

    @field({decoder: NumberDecoder, optional: true})
    PriceVatPercentage: number = 0

    @field({decoder: StringDecoder})
    Description: string

    @field({decoder: StringDecoder})
    FirstName: string

    @field({decoder: StringDecoder})
    LastName: string

    /**
     * If we send an email address, Innovend will also send emails to inform the user about their order.
     */
    @field({decoder: StringDecoder, optional: true})
    Email?: string

    @field({decoder: BooleanDecoder, optional: true})
    Returnable = false

    @field({decoder: BooleanDecoder, optional: true})
    IsStartStatusFilled = false

    @field({decoder: BooleanDecoder, optional: true})
    IsStartStatusDelivered = false

    @field({decoder: BooleanDecoder, optional: true})
    IsStartStatusReturned = false

    @field({decoder: StringDecoder})
    OrderNr: string

    @field({decoder: StringDecoder, optional: true})
    BadgeCode?: string

    /**
     * Format YYYYMMdd eg. 20200131
     */
    @field({decoder: StringDecoder})
    DeliveryDate: string

    /**
     * Default to empty string = they will generate a random unlock code.
     */
    @field({decoder: StringDecoder, optional: true})
    UnlockCode: string = ''

    @field({decoder: StringDecoder, optional: true})
    PrescriptionCode: string

    @field({decoder: StringDecoder})
    ReturnUrl: string

    static formatDate(date: Date) {
        return `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}`
    }
}

export class ExchangeInnovendBody extends AutoEncoder {
    @field({decoder: StringDecoder})
    orderNumber: string

    @field({decoder: StringDecoder})
    lockerName: string

    @field({decoder: StringDecoder})
    code: string

    @field({decoder: StringDecoder})
    clientId: string

    @field({decoder: StringDecoder})
    machineId: string

    @field({decoder: DateDecoder})
    date: Date

    @field({decoder: new EnumDecoder(InnovendStatus)})
    status: InnovendStatus

    @field({decoder: StringDecoder})
    motorNrList: string
}

export class InnovendAvailableLockerResponse extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    MachineId: number

    @field({decoder: IntegerDecoder})
    BrokenLocker: number

    @field({decoder: IntegerDecoder})
    LockerCount: number

    @field({decoder: IntegerDecoder})
    BookedLocker: number

    /**
     * Can not use dateDecoder because we get a formatted string instead of timestamp.
     */
    @field({decoder: StringDecoder})
    TargetDate: string

    get date() {
        return new Date(this.TargetDate)
    }
}
