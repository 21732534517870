









































































































import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import DRSTBoxItem from '../DRSTBoxItem.vue'
import VerifyIcon from '../utils/VerifyIcon.vue'

@Component({
    components: {
        DRSTBoxItem,
        VerifyIcon,
    },
})
export default class TextInputRow extends Mixins(NavigationMixin) {
    @Prop({})
    value!: string | null

    @Prop({default: 'label'})
    elementName!: string

    @Prop({default: null})
    inputmode!: string | null

    @Prop({default: ''})
    label!: string

    @Prop({default: ''})
    symbol!: string

    @Prop({default: ''})
    autocomplete!: string

    @Prop({default: ''})
    placeholder!: string

    @Prop({default: 'text'})
    type!: string

    @Prop({default: false})
    disabled!: boolean

    @Prop({default: false})
    readonly!: boolean

    @Prop({default: '524288'})
    maxlength!: string

    @Prop({default: '0'})
    minlength!: string

    @Prop({default: false})
    multiple!: boolean

    /** boolean = valid/invalid and null = unknown/pending validation - undefined = no verification shown. */
    @Prop({required: false, default: undefined})
    verifyIcon?: boolean | null | undefined

    @Prop({required: false})
    validateFunction?: (text: string) => boolean

    valid: boolean | null = null

    get showVerification() {
        return typeof this.verifyIcon !== 'undefined'
    }

    editingType = this.type
    togglePassword() {
        this.editingType = this.editingType.toLowerCase() === 'password' ? 'text' : 'password'
    }

    get text() {
        return this.value ?? ''
    }

    set text(value: string) {
        this.$emit('input', value)
    }

    blur($event) {
        this.valid = this.validateTextLength()
            && (
                this.validateFunction === undefined || this.text === ''
                    ? true
                    : this.validateFunction(this.text)
            );
        (this.$refs.input as any)?.blur()
        this.$emit('blur', $event)
    }

    validateTextLength() {
        return this.text.length >= parseInt(this.minlength) && this.text.length <= parseInt(this.maxlength)
    }

    focus() {
        this.valid = null;
        (this.$refs.input as any)?.focus()
    }

    onFocusInput($event) {
        this.valid = null
        this.$emit('focus', $event)
    }

    select() {
        (this.$refs.input as any)?.select()
    }

    selectIfNotFocus(event) {
        if (event.target === document.activeElement) {
            return
        }
        event.target.select()
        event.preventDefault()
    }
}
