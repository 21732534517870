import {Decoder} from '@simonbackx/simple-encoding'

import {ServerManager} from '../classes/ServerManager'
import {Discount, DiscountInfo} from '@dorst/structures'

export class DiscountActions {
    static async VerifyDiscount(code: string, verification: string | null): Promise<Discount> {
        return (
            await ServerManager.getServer()
                .request({
                    method: 'POST',
                    path: `/consumer/shops/${ServerManager.shop.id}/discounts/verify`,
                    body: DiscountInfo.create({code, verification: verification === '' ? null : verification}),
                    decoder: Discount as Decoder<Discount>,
                })
        ).data
    }
}
