var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "telephone-input-box",
      class: { invalid: _vm.valid === false }
    },
    [
      _c(
        "div",
        {
          staticClass: "cm-country--wrapper",
          on: {
            click: function($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.toggleDropdown($event)
            }
          }
        },
        [
          _c("span", { staticClass: "country-code" }, [
            _vm._v(_vm._s(_vm.dialCode))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "icon arrow-down-simple" })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "telephone-input-wrapper" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.phone,
              expression: "phone"
            }
          ],
          ref: "input",
          staticClass: "text-input-row",
          attrs: {
            disabled: _vm.disabled,
            type: "tel",
            placeholder: _vm.placeholder,
            autocomplete: "tel",
            inputmode: "tel"
          },
          domProps: { value: _vm.phone },
          on: {
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.phone = $event.target.value
              },
              function($event) {
                _vm.text = $event.target.value
              }
            ],
            blur: _vm.blur
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }