
















































import {patchContainsChanges, PatchType} from '@simonbackx/simple-encoding'
import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {googleGeoCoding} from '../actions/location'
import {ServerManager} from '../classes/ServerManager'
import {
    DRSTAddressMatrixInputBox,
    DRSTBox,
    DRSTBoxItem,
    DRSTNavigationBar,
    RemarkBlock,
    SelectRow,
    SpinnerButton,
    TextInputRow,
} from '@dorst/components'
import {AddressField, InputFieldHelper} from '@dorst/frontend-helpers'
import {Address, GeoCodingRequest, ParseCountrySlug, Version} from '@dorst/structures'

@Component({
    components: {
        DRSTAddressMatrixInputBox,
        DRSTBox,
        RemarkBlock,
        DRSTBoxItem,
        DRSTNavigationBar,
        SelectRow,
        SpinnerButton,
        TextInputRow,
    },
})
export default class DeliveryAddressView extends Mixins(NavigationMixin) {
    ServerManager = ServerManager

    @Prop({required: false})
    title: string | undefined

    @Prop({required: true})
    address: Address

    @Prop({required: true})
    onSave: (patch: PatchType<Address>) => void

    @Prop({required: false})
    onDelete: () => null

    force = 0

    get addressMatrix() {
        if (this.force) {/** */}
        const addressMatrix = InputFieldHelper.getMatrixForCountry(ParseCountrySlug(this.editAddress.country))
        // move country to first
        addressMatrix.matrix.sort((a, b) => {
            if (a.addressField === b.addressField) {
                return 0
            }

            return a.addressField === AddressField.COUNTRY ? -1 : 1
        })
        return addressMatrix
    }

    addressPatch = Address.patch({})
    get editAddress() {
        if (this.force) {/** */}
        return this.address.patch(this.addressPatch)
    }

    set editAddress(address: Address) {
        this.addressPatch = this.addressPatch.patch({...address})
        this.force++
    }

    errors: Array<string> = []
    saving = false
    async save() {
        if (this.saving) {
            return
        }
        this.saving = true
        this.errors = []
        for (const key of Object.keys(this.editAddress)) {
            if (key === 'latLng') {
                this.addressPatch['latLng'] = null
                continue
            }
            const val = this.editAddress[key]

            if (!this.addressMatrix.matrix.some(el => el.addressField.toString() === key)) {
                // remove unneeded fields values
                this.addressPatch[key] = ''
                continue
            }

            if (val === undefined || val === null || val === '') {
                // validate required fields
                switch (key) {
                    case AddressField.NUMBER:
                        this.errors.push(this.$i18n.t('customer.details.errors.noAddressNumber').toString())
                        break
                    case AddressField.STREET:
                        this.errors.push(this.$i18n.t('customer.details.errors.noAddressStreet').toString())
                        break
                    case AddressField.CITY:
                        this.errors.push(this.$i18n.t('customer.details.errors.noAddressCity').toString())
                        break
                    case AddressField.POSTALCODE:
                        this.errors.push(this.$i18n.t('customer.details.errors.noAddressPostalCode').toString())
                        break
                    case AddressField.COUNTRY:
                        this.errors.push(this.$i18n.t('customer.details.errors.noCountry').toString())
                        break
                    case AddressField.ADDRESS1:
                        this.errors.push(this.$i18n.t('customer.details.errors.noAddress1').toString())
                        break
                    case AddressField.ADDRESS2:
                        // not required
                        break
                    default:
                        console.error(`unknown address field ${key}`)
                        break
                }
            }
        }

        if (this.errors.length > 0) {
            this.saving = false
            return
        }

        if (!patchContainsChanges(this.addressPatch, this.address, {version: Version})) {
            this.saving = false
            this.pop()
        }

        // find latlng for address
        const latLng = await googleGeoCoding(GeoCodingRequest.create({
            address: this.editAddress,
        }))

        this.addressPatch.latLng = latLng.latLng

        this.onSave(this.addressPatch)
        this.saving = false
        this.pop()
    }

    /**
    Left code underneath in comment if we would want to add the places api suggestion search bar again..

    @Watch('query')
    _onQuery(query: string) {
        this.onQuery(query)
    }

    onQuery(query: string) {
        void gisAutocomplete(GisAutocompleteRequest.create({session: this.session, query, country: this.country}))
            .then(({results}) => this.results = results)
    }

    onFocus() {
        this.showResults = true
        document.body.addEventListener('click', this.onClick)
    }

    onBlur() {
        this.showResults = false
        document.body.removeEventListener('click', this.onClick)
    }

    onSelect(result: GisAutocompleteResult) {
        void gisAutocompleteDetails(GisAutocompleteDetailRequest.create({session: this.session, placeId: result.placeId}))
            .then(({address}) => {
                this.number = address.number ?? ''
                this.street = address.street ?? ''
                this.city = address.city
                this.postalCode = address.postalCode ?? ''
                this.country = address.country

                this.force++

                this.onBlur()
            })
    }

    onClick(e: MouseEvent) {
        this.showResults = false
    }

    clear() {
        this.query = ''
    }
    */
}
