import {AutoEncoder, field, StringDecoder} from '@simonbackx/simple-encoding'

export class DITable extends AutoEncoder {
    @field({decoder: StringDecoder})
    tableCell: string

    @field({decoder: StringDecoder})
    tableName: string

    @field({decoder: StringDecoder})
    groupID: string

    @field({decoder: StringDecoder})
    floorID: string
}
