var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view" },
    [
      _c(
        "DRSTNavigationBar",
        { attrs: { title: _vm.$t("customer.payment.title") } },
        [
          _c("button", {
            staticClass: "button icon back",
            attrs: { slot: "left" },
            on: { click: _vm.pop },
            slot: "left"
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "main",
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("customer.payment.title")))]),
          _vm._v(" "),
          _vm.error
            ? _c("RemarkBlock", { attrs: { type: "error" } }, [
                _c("p", [_vm._v(_vm._s(_vm.error))])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "DRSTBox",
            { staticClass: "payment-method-box" },
            _vm._l(_vm.paymentMethods, function(method) {
              return _c(
                "DRSTBoxItem",
                {
                  key: method.id,
                  class: {
                    "payment-method-disabled": _vm.shouldBeDisabled(method)
                  },
                  attrs: { selectable: !_vm.shouldBeDisabled(method) },
                  on: {
                    click: function($event) {
                      return _vm.selectPaymentMethod(method)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " + _vm._s(_vm.getName(method)) + " "
                  ),
                  _c("span", { staticClass: "payment-method-not-available" }, [
                    _vm._v(_vm._s(_vm.getMinOrderAmountForPayment(method)))
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "payment-method-row-right",
                      attrs: { slot: "right" },
                      slot: "right"
                    },
                    [
                      _vm._l(_vm.getImages(method), function(image) {
                        return _c("span", [
                          _c("img", {
                            key: image,
                            attrs: {
                              src: image,
                              width: "40",
                              height: "30",
                              alt: _vm.getName(method)
                            }
                          })
                        ])
                      }),
                      _vm._v(" "),
                      _c("Radio", {
                        attrs: { name: "payment_method", value: method },
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                          }
                        },
                        model: {
                          value: _vm.paymentMethod,
                          callback: function($$v) {
                            _vm.paymentMethod = $$v
                          },
                          expression: "paymentMethod"
                        }
                      })
                    ],
                    2
                  )
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _vm.isCashless
            ? _c(
                "DRSTBox",
                { attrs: { title: _vm.$t("customer.payment.details") } },
                [
                  _c("TextInputRow", {
                    attrs: {
                      placeholder: _vm.cardIDPlaceholder,
                      inputmode: _vm.cashlessInputMode,
                      label: _vm.$t("customer.payment.cardID")
                    },
                    on: { focus: _vm.cardIDFocus, blur: _vm.cardIDBlur },
                    model: {
                      value: _vm.cardID,
                      callback: function($$v) {
                        _vm.cardID = $$v
                      },
                      expression: "cardID"
                    }
                  }),
                  _vm._v(" "),
                  _vm.verificationMethod === _vm.VerificationMethod.None ||
                  (!_vm.cardNeedsVerification.loading &&
                    _vm.cardNeedsVerification.value === false)
                    ? [
                        _vm.cardID &&
                        !_vm.cardExists.loading &&
                        _vm.cardExists.value
                          ? _c(
                              "DRSTBoxItem",
                              [
                                _c("p", { staticClass: "table-label" }, [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.$t("customer.payment.verification")
                                      ) +
                                      "\n                    "
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "template",
                                  { slot: "right" },
                                  [
                                    _c("Radio", {
                                      staticClass: "verification-success",
                                      attrs: {
                                        "model-value": "checked",
                                        value: "checked"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              2
                            )
                          : _vm._e()
                      ]
                    : [
                        _vm.verificationMethod ===
                        _vm.VerificationMethod.Birthdate
                          ? _c(
                              "DRSTBoxItem",
                              [
                                _c("p", { staticClass: "table-label" }, [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.$t("customer.payment.dateOfBirth")
                                      ) +
                                      "\n                    "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("DateInput", {
                                  attrs: { slot: "right" },
                                  slot: "right",
                                  model: {
                                    value: _vm.verification,
                                    callback: function($$v) {
                                      _vm.verification = $$v
                                    },
                                    expression: "verification"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                  _vm._v(" "),
                  _vm.cardExists.loading || _vm.cardNeedsVerification.loading
                    ? [
                        _c(
                          "DRSTBoxItem",
                          [
                            _c("p", { staticClass: "table-label" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$t("customer.payment.cardChecking")
                                  ) +
                                  "\n                    "
                              )
                            ]),
                            _vm._v(" "),
                            _c("Spinner", {
                              attrs: { slot: "right" },
                              slot: "right"
                            })
                          ],
                          1
                        )
                      ]
                    : [
                        _vm.cardExists.value === false
                          ? _c(
                              "DRSTBoxItem",
                              [
                                _c("p", { staticClass: "table-label" }, [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "customer.payment.cardNumberInvalid"
                                        )
                                      ) +
                                      "\n                    "
                                  )
                                ]),
                                _vm._v(" "),
                                _c("template", { slot: "right" }, [
                                  _c("div", { staticClass: "icon error" })
                                ])
                              ],
                              2
                            )
                          : _vm._e()
                      ],
                  _vm._v(" "),
                  _vm.enableTopup && _vm.cardExists.value
                    ? _c("DRSTBoxItem", [
                        _c("p", { staticClass: "table-label" }, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("customer.topup.button")) +
                              "\n                "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "topup-button",
                            attrs: { slot: "right" },
                            on: {
                              click: function($event) {
                                return _vm.beforeNext(_vm.gotoTopup)
                              }
                            },
                            slot: "right"
                          },
                          [_c("button", { staticClass: "button icon plus" })]
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isCashless
                    ? _c("RemarkBlock", [
                        _c("p", [_vm._v(_vm._s(_vm.cardIDDescription))])
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.enableTips
            ? _c(
                "DRSTBox",
                { attrs: { title: _vm.$t("customer.payment.tipTitle") } },
                [
                  _vm.canTipRounded
                    ? _c(
                        "button",
                        {
                          staticClass: "button primary text",
                          attrs: { slot: "right" },
                          on: { click: _vm.roundTip },
                          slot: "right"
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.$t("customer.payment.tipRoundButton")
                              ) +
                              "\n            "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "TextInputRow",
                    {
                      staticClass: "style-row-title-2",
                      on: { blur: _vm.onBlurTip },
                      model: {
                        value: _vm.editTipString,
                        callback: function($$v) {
                          _vm.editTipString = $$v
                        },
                        expression: "editTipString"
                      }
                    },
                    [
                      _c("Stepper", {
                        attrs: { slot: "right", minimum: 0, step: 50 },
                        nativeOn: {
                          click: function($event) {
                            $event.stopPropagation()
                          }
                        },
                        slot: "right",
                        model: {
                          value: _vm.checkout.tip,
                          callback: function($$v) {
                            _vm.$set(_vm.checkout, "tip", $$v)
                          },
                          expression: "checkout.tip"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.discount
            ? _c(
                "DRSTBox",
                { attrs: { title: _vm.$t("consumer.discounts.active") } },
                [
                  _c(
                    "DRSTBoxItem",
                    { staticClass: "right-stack" },
                    [
                      _c("div", { staticClass: "discount-code-wrapper" }, [
                        _c("span", { staticClass: "discount-code" }, [
                          _vm._v(_vm._s(_vm.discount.code))
                        ])
                      ]),
                      _vm._v(" "),
                      _c("template", { slot: "right" }, [
                        _vm._v(
                          "\n                    - " +
                            _vm._s(
                              _vm.formatDiscountDetails(_vm.discount.details)
                            ) +
                            "\n                    "
                        ),
                        _c("button", {
                          staticClass: "button icon close",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.removeDiscount($event)
                            }
                          }
                        })
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.discountSources.length > 0
            ? _c(
                "div",
                {
                  staticClass: "add-extra-button",
                  on: { click: _vm.activateDiscount }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("consumer.discounts.apply")) +
                      "\n        "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.remarksAllowed
            ? [
                !_vm.hasCheckoutDescription
                  ? _c(
                      "div",
                      {
                        staticClass: "add-extra-button",
                        on: {
                          click: function($event) {
                            _vm.editCheckoutDescription = ""
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("consumer.remark.apply")) +
                            "\n            "
                        )
                      ]
                    )
                  : _c(
                      "DRSTBox",
                      { attrs: { title: _vm.$t("consumer.remark.title") } },
                      [
                        _c("TextareaRow", {
                          attrs: { placeholder: "" },
                          model: {
                            value: _vm.editCheckoutDescription,
                            callback: function($$v) {
                              _vm.editCheckoutDescription = $$v
                            },
                            expression: "editCheckoutDescription"
                          }
                        })
                      ],
                      1
                    )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.shop.disclaimer
        ? _c("div", {
            staticClass: "disclaimer",
            domProps: { textContent: _vm._s(_vm.shop.disclaimer) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "DRSTFooterBox",
        [
          _vm.cardExists.value && _vm.cardBalance.value !== null
            ? _c(
                "DRSTBoxItem",
                [
                  _c("p", { staticClass: "table-label" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("general.balance")) +
                        "\n            "
                    )
                  ]),
                  _vm._v(" "),
                  _vm.cardBalance.loading
                    ? _c("Spinner", { attrs: { slot: "right" }, slot: "right" })
                    : _c(
                        "p",
                        {
                          staticClass: "total-price",
                          attrs: { slot: "right" },
                          slot: "right"
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm._f("price")(_vm.cardBalance.value, {
                                  forceDisplay: true
                                })
                              ) +
                              "\n            "
                          )
                        ]
                      )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.checkout.tip > 0 ||
          _vm.checkout.deliveryPrice > 0 ||
          _vm.checkout.discount !== null ||
          _vm.checkout.exclusiveExtraCosts.length > 0
            ? _c("DRSTBoxItem", [
                _c("p", { staticClass: "table-label" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.$t("general.subtotal")))])
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "total-price",
                    attrs: { slot: "right" },
                    slot: "right"
                  },
                  [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm._f("price")(_vm.cartPrice, { forceDisplay: true })
                        )
                      ),
                      _c("strong")
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.checkout.tip
            ? _c("DRSTBoxItem", [
                _c("p", { staticClass: "table-label" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("customer.payment.tipTitle")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "total-price",
                    attrs: { slot: "right" },
                    slot: "right"
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("price")(_vm.checkout.tip, {
                            forceDisplay: true
                          })
                        ) +
                        "\n            "
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.checkout.deliveryPrice
            ? _c("DRSTBoxItem", [
                _c("p", { staticClass: "table-label" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("general.deliveryFee")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "total-price",
                    attrs: { slot: "right" },
                    slot: "right"
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("price")(_vm.checkout.deliveryPrice, {
                            forceDisplay: true
                          })
                        ) +
                        "\n            "
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.exclusiveExtraCosts, function(extracost) {
            return _c(
              "DRSTBoxItem",
              { key: extracost.id, staticClass: "extra-cost" },
              [
                _c("p", { staticClass: "table-label" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.translateForI18n(extracost.name)) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "total-price",
                    attrs: { slot: "right" },
                    slot: "right"
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.formatExtraCostValue(extracost)) +
                        "\n            "
                    )
                  ]
                )
              ]
            )
          }),
          _vm._v(" "),
          _vm.discount
            ? _c("DRSTBoxItem", [
                _c("p", { staticClass: "table-label" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.$t("general.discount")))])
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "total-price",
                    attrs: { slot: "right" },
                    slot: "right"
                  },
                  [
                    _c("strong", [
                      _vm._v(
                        "- " +
                          _vm._s(
                            _vm._f("price")(_vm.checkout.getDiscount(), {
                              forceDisplay: true
                            })
                          )
                      )
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("DRSTBoxItem", [
            _c("p", { staticClass: "table-label" }, [
              _c("strong", [_vm._v(_vm._s(_vm.$t("general.total")))])
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "total-price",
                attrs: { slot: "right" },
                slot: "right"
              },
              [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm._f("price")(_vm.checkout.getPrice(), {
                        forceDisplay: true
                      })
                    )
                  )
                ])
              ]
            )
          ]),
          _vm._v(" "),
          !_vm.cardBalance.loading &&
          _vm.cardBalance.value !== null &&
          _vm.cardExists.value
            ? _c("DRSTBoxItem", [
                _c("p", { staticClass: "table-label" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.$t("general.newBalance")) +
                      "\n            "
                  )
                ]),
                _vm._v(" "),
                _vm.sufficientBalance
                  ? _c(
                      "p",
                      {
                        staticClass: "total-price",
                        attrs: { slot: "right" },
                        slot: "right"
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm._f("price")(_vm.newBalance, {
                                forceDisplay: true
                              })
                            ) +
                            "\n            "
                        )
                      ]
                    )
                  : _c(
                      "p",
                      {
                        staticClass: "total-price",
                        attrs: { slot: "right" },
                        slot: "right"
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(_vm.$t("general.insufficient")) +
                            "\n            "
                        )
                      ]
                    )
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.didAcceptPolicies
            ? _c(
                "DRSTBoxItem",
                {
                  staticClass: "policy-boxes",
                  class: {
                    "hide-background-image": _vm.hideBgImageOnPolicyCheckboxes
                  }
                },
                [
                  _c(
                    "label",
                    { staticClass: "policy-box" },
                    [
                      _c("Checkbox", {
                        model: {
                          value: _vm.termsPolicy,
                          callback: function($$v) {
                            _vm.termsPolicy = $$v
                          },
                          expression: "termsPolicy"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "i18n",
                        {
                          attrs: { path: "customer.details.terms", tag: "span" }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "button inline",
                              attrs: { slot: "link" },
                              on: { click: _vm.openTerms },
                              slot: "link"
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("customer.details.termsLink")) +
                                  "\n                    "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "policy-box" },
                    [
                      _c("Checkbox", {
                        model: {
                          value: _vm.privacyPolicy,
                          callback: function($$v) {
                            _vm.privacyPolicy = $$v
                          },
                          expression: "privacyPolicy"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "i18n",
                        {
                          attrs: {
                            path: "customer.details.privacyPolicy",
                            tag: "span"
                          }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "button inline",
                              attrs: { slot: "link" },
                              on: { click: _vm.openPrivacy },
                              slot: "link"
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("customer.details.privacyPolicyLink")
                                ) + "\n                    "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "DRSTFloatingFooter",
        [
          _c("SpinnerButton", { attrs: { loading: _vm.loading } }, [
            _vm.isCashless && !_vm.cardID
              ? _c(
                  "button",
                  {
                    staticClass: "button primary fill",
                    attrs: { disabled: true }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$t("customer.payment.button.emptyCardNumber")
                        ) +
                        "\n            "
                    )
                  ]
                )
              : _vm.cardExists.value === false
              ? _c(
                  "button",
                  {
                    staticClass: "button primary fill",
                    attrs: { disabled: true }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$t("customer.payment.button.invalidCardNumber")
                        ) +
                        "\n            "
                    )
                  ]
                )
              : _vm.sufficientBalance || _vm.cardBalance.value === null
              ? _c(
                  "button",
                  {
                    staticClass: "button primary fill",
                    attrs: { disabled: !_vm.canPay },
                    on: {
                      click: function($event) {
                        return _vm.beforeNext(_vm.placeOrder)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.payButtonText) +
                        "\n            "
                    )
                  ]
                )
              : _vm.enableTopup
              ? _c(
                  "button",
                  {
                    staticClass: "button primary fill",
                    attrs: { disabled: !_vm.canPay },
                    on: {
                      click: function($event) {
                        return _vm.beforeNext(_vm.gotoTopup)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.topupButtonText) +
                        "\n            "
                    )
                  ]
                )
              : _c(
                  "button",
                  {
                    staticClass: "button primary fill",
                    attrs: { disabled: true }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.$t("general.insufficientBalance")) +
                        "\n            "
                    )
                  ]
                )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }