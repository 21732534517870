import {ArrayDecoder, AutoEncoder, field, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {Address} from './Address'

export class AddressBookEntry extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: Address, defaultValue: () => Address.createDefault()})
    address: Address

    @field({decoder: StringDecoder, optional: true})
    label: string | undefined = undefined

    // We can add things like label, favourite,... later on
}

export const AddressBookEntryPatch = AddressBookEntry.patchType()

export class AddressBook extends AutoEncoder {
    @field({decoder: new ArrayDecoder(AddressBookEntry)})
    entries: Array<AddressBookEntry> = []

    @field({decoder: StringDecoder, nullable: true})
    lastSelected: string | null = null
}

export const AddressBookPatch = AddressBook.patchType()
