import {AutoEncoder, BooleanDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'

import {OrderConsumer} from './Order'

export class OrderResponse extends AutoEncoder {
    @field({decoder: StringDecoder, optional: true})
    paymentUrl?: string

    /**
     * Needed when we need to do a POST to paymentURL instead of the default GET
     */
    @field({decoder: StringDecoder, optional: true})
    redirectMethod?: string

    @field({decoder: BooleanDecoder, optional: true})
    posValidationRequired = false

    @field({decoder: BooleanDecoder, optional: true})
    innovendValidationRequired = false
    /**
     * Needed when we need to include POST data to the paymentURL, this value is a JSON string with key value pairs
     */
    @field({decoder: StringDecoder, optional: true})
    redirectData?: string

    @field({decoder: OrderConsumer})
    order: OrderConsumer
}
