






























































































































import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {DateTime} from 'luxon'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {GetDayWithTimeslots} from '../actions/Timeslots'
import {CartManager} from '../classes/CartManager'
// import {SMAKManager} from '../classes/SMAKManager'
import {ServerManager} from '../classes/ServerManager'
import {DRSTBox, DRSTBoxItem, DRSTFloatingFooter, DRSTNavigationBar, Radio, Spinner, SpinnerButton} from '@dorst/components'
import {DateHelper} from '@dorst/helpers'
import {ConsumptionOptionType, DayWithTimeslots, Timeslot} from '@dorst/structures'
import {Formatter} from '@dorst/validation'

@Component({
    components: {
        DRSTNavigationBar,
        DRSTBox,
        DRSTBoxItem,
        DRSTFloatingFooter,
        Radio,
        Spinner,
        SpinnerButton,
    },
    filters: {
        minuteToTime(minutes) {
            return Formatter.minutesToTime(minutes)
        },
    },
})
export default class TimeSlotSelectionView extends Mixins(NavigationMixin) {
    @Prop({default: undefined})
    callback?: (NavigationMixin) => void

    @Prop({default: undefined})
    buttonText?: string

    refreshTimer: number

    // @Prop({default: false})
    // smakSelection: boolean

    day = this.deliveryDate
    dayWithTimeslots: DayWithTimeslots | null = null
    loading = true
    saveLoading = false
    shouldSetInitialDate = true
    initialDate: Date | null = null

    get dateWithDay() {
        if (!this.day) {
            return ''
        }
        return Formatter.dateWithDay(this.day, this.timezone)
    }

    mounted() {
        this.initComponent().catch(e => console.error(e))
    }

    activated() {
        this.initComponent().catch(e => console.error(e))
    }

    deactivated() {
        clearInterval(this.refreshTimer)
    }

    beforeDestroy() {
        clearInterval(this.refreshTimer)
    }

    get deliveryDate() {
        // return this.smakSelection ? SMAKManager.filterDeliveryDate : CartManager.checkout.deliveryDate
        return CartManager.checkout.deliveryDate
    }

    get timezone() {
        /* if (this.smakSelection) {
            return 'Europe/Brussels'
        }*/
        return ServerManager.shop.timezone
    }

    async initComponent() {
        if (this.day !== null) {
            const day = DateTime.fromJSDate(this.day).set({hour: 0, minute: 0, second: 0, millisecond: 0})
            const currentDay = DateTime.local().set({hour: 0, minute: 0, second: 0, millisecond: 0})
            if (day.valueOf() < currentDay.valueOf()) {
                this.day = currentDay.toJSDate()
            }

            // The assumption made in `reload()` below only stands if the first load call made does not contain a
            // date param. If it does however, disable the skipping mechanism entirely.
            this.shouldSetInitialDate = false
        }

        // Refresh every minute
        this.refreshTimer = window.setInterval(() => {
            this.reload(false).catch(e => console.error(e))
        }, 60000)

        await this.reload()
        this.selectTimeslotIfNeeded()
    }

    /** returns dates for the next 7 days */
    get possibleDates() {
        /* if (!this.smakSelection) {
            return []
        }*/
        const possibleDates: Array<Date> = [new Date()]
        for (let i = 1; i < 7; i++) {
            possibleDates.push(DateTime.local().plus({day: i}).startOf('day').toJSDate())
        }
        return possibleDates
    }

    get showingToday() {
        const date = new Date()
        return this.day !== null && (this.day.getDate() === date.getDate() && this.day.getMonth() === date.getMonth() && this.day.getFullYear() === date.getFullYear())
    }

    async reload(clear = true) {
        this.loading = true
        if (clear) {
            this.dayWithTimeslots = null
        }
        try {
            /* if (this.smakSelection && this.day !== null) {
                // create timeslots starting now and ending 7days in the future @ 24.00 (slots of 30minutes)
                const interval = 30
                const startDay = this.showingToday ? new Date() : DateTime.fromJSDate(this.day).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toJSDate() // if day is today we only want minutes starting from now.
                const startTime = (startDay.getMinutes() + (startDay.getMinutes() % 30 > 0 ? interval - startDay.getMinutes() % 30 : 0)) + (startDay.getHours() * 60)
                const endTime = 1440
                const amountOfTimeslots = (endTime - startTime) / interval
                const timeslots = Array.from({length: amountOfTimeslots}).map((_, idx) => Timeslot.create({
                    start: startTime + interval * idx,
                    end: startTime + interval * (idx + 1),
                }))

                const next = this.possibleDates.find(el => {
                    if (this.day === null) {
                        return undefined
                    }
                    const dayCopy = new Date(this.day)
                    return el.getDate() === new Date(dayCopy.setDate(dayCopy.getDate() + 1)).getDate()
                })
                const prev = this.possibleDates.find(el => {
                    if (this.day === null) {
                        return undefined
                    }
                    const dayCopy = new Date(this.day)
                    return el.getDate() === new Date(dayCopy.setDate(dayCopy.getDate() - 1)).getDate()
                })
                this.dayWithTimeslots = DayWithTimeslots.create({date: this.day, timeslots: timeslots, next: next, previous: prev})
            }*/

            this.dayWithTimeslots = await CartManager.getDayWithTimeslots(this.consumptionMode, this.day ?? undefined)

            if (this.day === null) {
                this.day = this.dayWithTimeslots.date
            }

            if (this.shouldSetInitialDate && this.initialDate === null) {
                this.initialDate = this.dayWithTimeslots.date
            }

            // Once we reach the initial date that was set, we don't want to show a previous arrow since we know that there are
            // no previous slots
            if (this.initialDate !== null && this.dayWithTimeslots.date.getTime() === this.initialDate.getTime()) {
                this.dayWithTimeslots.previous = null
            }
        } catch (e) {
            console.error(e)
        }

        this.loading = false
    }

    get consumptionMode(): ConsumptionOptionType {
        /* if (this.smakSelection) {
            return SMAKManager.filterConsumptionOption
        }*/
        return CartManager.checkout.consumptionMode
    }

    get takeAwayMode(): boolean {
        return this.consumptionMode === ConsumptionOptionType.TakeAway
    }

    get deliveryMode(): boolean {
        return this.consumptionMode === ConsumptionOptionType.Delivery
    }

    get timeslots(): Array<Timeslot> {
        return this.dayWithTimeslots?.timeslots || []
    }

    isFirstAvailableTimeslot(timeslot) {
        return !this.dayWithTimeslots?.previous && this.dayWithTimeslots?.timeslots[0].start == timeslot
    }

    get selectedTimeslot(): (number | null) {
        if (this.day === null || this.deliveryDate === null) {
            return null
        }
        /* if (this.smakSelection && SMAKManager.filterDeliveryDate == null && this.showingToday) {
            return this.dayWithTimeslots?.timeslots[0].start ?? null
        }*/

        const deliveryDay = DateTime.fromJSDate(this.deliveryDate).setZone(this.timezone)
        const currentDay = DateTime.fromJSDate(this.day).setZone(this.timezone)
        if (DateHelper.isSameDay(deliveryDay, currentDay)) {
            return deliveryDay.hour * 60 + deliveryDay.minute
        }
        return null
    }

    set selectedTimeslot(timeslotStart: number | null) {
        /* if (this.smakSelection) {
            if (timeslot === null) {
                SMAKManager.filterDeliveryDate = null
                return
            }
            if (this.isFirstAvailableTimeslot(timeslot) && this.showingToday) {
                SMAKManager.filterDeliveryDate = null
            } else if (this.day !== null) {
                const selectedDate = DateTime.fromJSDate(this.day).setZone(this.timezone).set({
                    hour: 0,
                    minute: timeslot,
                    second: 0,
                    millisecond: 0,
                })
                SMAKManager.filterDeliveryDate = selectedDate.toJSDate()
            }
        }*/
        if (timeslotStart === null) {
            CartManager.setDeliveryDate(null)
            return
        }

        if (this.day === null) {
            return
        }

        const selectedDate = DateTime.fromJSDate(this.day).setZone(this.timezone).set({
            hour: 0,
            minute: timeslotStart,
            second: 0,
            millisecond: 0,
        })

        CartManager.setDeliveryDate(selectedDate.toJSDate())
    }

    get validDeliveryDate(): boolean {
        /* if (this.smakSelection && this.deliveryDate == null) {
            return true
        } // this means asap slot.*/

        if (!this.deliveryDate || !this.dayWithTimeslots) {
            return false
        }

        // We don't have a valid selection if we navigated to a different day than the current selected one
        if (Formatter.date(this.deliveryDate, this.timezone) != Formatter.date(this.dayWithTimeslots.date, this.timezone)) {
            return false
        }

        const date = DateTime.fromJSDate(this.deliveryDate).setZone(this.timezone)
        const wanted = date.hour * 60 + date.minute
        return this.dayWithTimeslots.timeslots.some(({start, end}) => start <= wanted && end > wanted)
    }

    selectTimeslotIfNeeded() {
        if (this.validDeliveryDate) {
            return
        }

        if (this.dayWithTimeslots && this.dayWithTimeslots.timeslots.length > 0) {
            this.selectedTimeslot = this.dayWithTimeslots.timeslots[0].start
        }
    }

    get hasPreviousDay(): boolean {
        return this.dayWithTimeslots?.previous !== null
    }

    previousDay() {
        if (!this.dayWithTimeslots?.previous) {
            return
        }
        this.day = this.dayWithTimeslots?.previous
        this.reload().catch(e => console.error(e))
    }

    get hasNextDay(): boolean {
        return this.dayWithTimeslots?.next != null
    }

    nextDay() {
        if (!this.dayWithTimeslots?.next) {
            return
        }

        this.day = this.dayWithTimeslots?.next
        this.reload().catch(e => console.error(e))
    }

    async save() {
        /* if (!this.smakSelection && !this.deliveryDate) {
            return
        }*/

        this.saveLoading = true

        try {
            await this.reload(false)
            if (this.validDeliveryDate) {
                if (this.callback) {
                    this.callback(this)
                } else {
                    this.pop()
                }
            }
        } finally {
            this.saveLoading = false
        }
    }

    get itemAvailabilityTexts() {
        return CartManager.checkout.cart.items.reduce<{[key: string]: Array<string>}>((acc, currItem) => {
            if (currItem.product.availabilityPeriods.length === 0) {
                return acc
            }
            const availabilityTexts = currItem.product.getAvailabilityTexts(this.timezone, this.$i18n as any)
            if (availabilityTexts.length > 0) {
                const productName = currItem.product.name.getForI18n(this.$i18n)
                acc[productName] = availabilityTexts
            }
            return acc
        }, {})
    }
}
