var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ContextMenu",
    _vm._b({}, "ContextMenu", { x: _vm.x, y: _vm.y }, false),
    _vm._l(_vm.enabledLanguages, function(language) {
      return _c(
        "DRSTBoxItem",
        {
          key: language,
          class: { isActive: _vm.isActive(language) },
          attrs: {
            "element-name": "label",
            selectable: true,
            "data-testid": "language-switcher-language"
          },
          on: {
            click: function($event) {
              _vm.setLanguage(language)
              _vm.pop()
            }
          }
        },
        [
          _vm._v(
            "\n        " + _vm._s(_vm.getLanguageName(language)) + "\n        "
          ),
          _vm.isActive(language)
            ? _c("template", { slot: "right" }, [
                _c("span", { staticClass: "icon check" })
              ])
            : _vm._e()
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }