var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "language-switcher",
      attrs: { "data-testid": "consumers-language-switcher" },
      on: { click: _vm.selectOtherLanguage }
    },
    [
      _vm._v("\n    " + _vm._s(_vm.currentLanguage) + "\n    "),
      _c("span", { staticClass: "icon arrow-down-simple" })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }