export type NotNull<T> = T extends null ? never : T
export type NotUndefined<T> = T extends undefined ? never : T
export type NotNullOrUndefined<T> = NotNull<NotUndefined<T>>

export function isNotNull<T>(input: T): input is NotNull<T> {
    return input !== null
}

export function isNotUndefined<T>(value: T): value is NotUndefined<T> {
    return value !== undefined
}

export function isNotNullOrUndefined<T>(value: T): value is NotNullOrUndefined<T> {
    return value != null
}

export function isNullOrEmpty(value: Array<any> | null | undefined): value is null | undefined | []
export function isNullOrEmpty(value: string | null | undefined): value is null | undefined | ''
export function isNullOrEmpty(
    value: Array<any> | string | null | undefined,
): value is null | undefined | '' | [] {
    return value == null || value.length === 0
}

export type Optional<V, K extends keyof V> = Pick<Partial<V>, K> & Omit<V, K>
export type RequiredProperties<Type, Key extends keyof Type> = Type & {
    [Property in Key]-?: Type[Property];
}

type AllKeys<T> = T extends unknown ? keyof T : never
type Id<T> = T extends infer U ? {[K in keyof U]: U[K]} : never
type _ExclusiveUnion<T, K extends PropertyKey> =
    T extends unknown
        ? Id<T & Partial<Record<Exclude<K, keyof T>, never>>>
        : never
/**
 * Only allow one of the union types and not a combination of both.
 * https://stackoverflow.com/questions/46370222/why-does-a-b-allow-a-combination-of-both
 * https://github.com/Microsoft/TypeScript/issues/14094
 */
export type ExclusiveUnion<T> = _ExclusiveUnion<T, AllKeys<T>>
