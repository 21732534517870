import {SHA1} from 'crypto-js'

import {environment} from '../environments/environment'
import {PruneHelper} from '../helpers/PruneHelper'
import {CartManager} from './CartManager'
import {ServerManager} from './ServerManager'
import {
    CartItem,
    Category,
    ConsumptionOptionType,
    OrderConsumer,
    TranslatedString,
} from '@dorst/structures'

type WindowWithTealium = Window & typeof globalThis & {asyncCall?: (obj: any) => void; refreshOffers?: () => void}

interface I18n {
    locale: string
}

interface TealiumRecord {
    id: string

    category?: TealiumItem
    subcategory?: TealiumItem
    product?: TealiumItem
}

interface TealiumItem {
    id: string
    name: TranslatedString
}

interface Offer {
    id: string
    category?: {[key in ConsumptionOptionType]?: string}
    product?: {[key in ConsumptionOptionType]?: string}
    text: string
}

const COSTA_SHOP_ID = 5

const offers: {banner1: Offer; banner2: Offer; banner3: Offer} = {
    banner1: {
        id: 'b57b4264-0df0-420e-92bf-5cb274952050',
        category: {
            [ConsumptionOptionType.DineIn]: 'e2eb4ed9-3c93-44f0-9915-86c0d30db0a8',
            [ConsumptionOptionType.TakeAway]: '121e4d6f-95a0-45cf-b477-0389ac18b19f',
        },
        text: `Get an extra cup of coffee for free with your next order`,
    },
    banner2: {
        id: '6ae82cd3-7309-4802-b3fb-9c9578f8c860',
        category: {
            [ConsumptionOptionType.DineIn]: '763cab16-f632-4540-bda3-f3aa80920ebf',
            [ConsumptionOptionType.TakeAway]: '53e8076e-a1be-45df-815a-c0d01fcab7c5',
        },
        text: `Get a free muffin with your next order`,
    },
    banner3: {
        id: '46cf301a-1724-4542-9458-5d618fb506e5',
        product: {
            [ConsumptionOptionType.DineIn]: 'c39ca53f-cad3-421c-bfaf-6deabcb0ebd2',
            [ConsumptionOptionType.TakeAway]: '4e882833-bbd2-4461-b9c1-66626dac9443',
        },
        text: `Get a free Panini with your next order`,
    },
}

export class TealiumManagerStatic {
    lastCategory: string | null = null

    internalActiveOffer: Offer | null

    get lookup() {
        return ServerManager.shop.categories.reduce((acc, cat) => [
            ...acc,
            {id: cat.id, category: {id: cat.id, name: cat.name}},
            ...cat.categories.reduce((sacc, subcat) => [
                ...sacc,
                {id: subcat.id, category: {id: cat.id, name: cat.name}, subcategory: {id: subcat.id, name: subcat.name}},
                ...subcat.products.reduce((pacc, prod) => [
                    ...pacc,
                    {id: prod.id, category: {id: cat.id, name: cat.name}, subcategory: {id: subcat.id, name: subcat.name}, product: {id: prod.id, name: prod.name}},
                ], [] as Array<TealiumRecord>),
            ], [] as Array<TealiumRecord>),
        ], [] as Array<TealiumRecord>).reduce((acc, val) => ({...acc, [val.id]: val}), {} as Record<string, TealiumRecord>)
    }

    get identifiers() {
        const firstName = CartManager.checkout.customer?.firstName
        const lastName = CartManager.checkout.customer?.lastName
        const phone = CartManager.checkout.customer?.phone
        const email = CartManager.checkout.customer?.email

        return {
            uid: firstName && lastName ? SHA1(`${firstName}${lastName}`).toString() : undefined,
            uphone: phone ? SHA1(phone).toString() : undefined,
            mobile: phone ? phone.replaceAll(/[ +]/g, '') : undefined,
            uemail: email ? SHA1(email).toString() : undefined,
        }
    }

    get activeOffer(): Offer | null {
        if (!environment.features.tealium || ServerManager.shop.id !== COSTA_SHOP_ID) {
            return null
        }
        return this.internalActiveOffer
    }

    async onHomepageView() {
        this.call({
            shopname: ServerManager.shop.name,
            page: 'homepage',

            ...this.identifiers,
        })
    }

    async onCategoryView(i18n: I18n, category: Category) {
        const subcategories = PruneHelper.filterCategories(category, CartManager.checkout.consumptionMode)

        this.call({
            shopname: ServerManager.shop.name,
            mode: CartManager.checkout.consumptionMode,
            page: 'categoryview',

            category: category.name.getForI18n(i18n),
            categoryid: category.id,
            subcategories: subcategories.map(it => it.name.getForI18n(i18n)),
            subcategoryids: subcategories.map(it => it.id),

            ...this.identifiers,
        })
    }

    async onSubcategoryView(i18n: I18n, subcategory: Category) {
        if (this.lastCategory === subcategory.id) {
            this.lastCategory = null
            return
        }
        this.lastCategory = null

        const {category} = this.lookup[subcategory.id]
        const products = PruneHelper.filterProducts(subcategory, CartManager.checkout.consumptionMode)

        this.call({
            shopname: ServerManager.shop.name,
            mode: CartManager.checkout.consumptionMode,
            page: 'subcategoryview',

            category: category!.name.getForI18n(i18n),
            categoryid: category!.id,
            subcategory: subcategory.name.getForI18n(i18n),
            subcategoryid: subcategory.id,

            products: products.map(it => it.name.getForI18n(i18n)),
            productids: products.map(it => it.id),
            prices: products.map(it => it.getBestPrice() / 100),

            ...this.identifiers,
        })
    }

    async onProductView(i18n: I18n, item: CartItem) {
        const {category, subcategory} = this.lookup[item.product.id]

        this.call({
            shopname: ServerManager.shop.name,
            mode: CartManager.checkout.consumptionMode,
            page: 'productview',

            category: category!.name.getForI18n(i18n),
            categoryid: category!.id,
            subcategory: subcategory!.name.getForI18n(i18n),
            subcategoryid: subcategory!.id,

            product: item.product.name.getForI18n(i18n),
            productid: item.product.id,
            price: item.product.getBestPrice() / 100,

            ...this.identifiers,
        })
    }

    async onAddProductToCart(i18n: I18n, item: CartItem) {
        const {subcategory} = this.lookup[item.product.id]
        this.lastCategory = subcategory!.id
    }

    async onCartView(i18n: I18n) {
        const categoryMappedItems = CartManager.cart.items.map((it) => this.lookup[it.product.id])
        this.call({
            shopname: ServerManager.shop.name,
            mode: CartManager.checkout.consumptionMode,
            page: 'cartview',

            cartid: CartManager.cart.id,

            categories: categoryMappedItems.map((it) => it.category!.name.getForI18n(i18n)),
            categoryids: categoryMappedItems.map((it) => it.category!.id),
            subcategories: categoryMappedItems.map((it) => it.subcategory!.name.getForI18n(i18n)),
            subcategoryids: categoryMappedItems.map((it) => it.subcategory!.id),

            products: CartManager.cart.items.map(it => it.product.name.getForI18n(i18n)),
            productids: CartManager.cart.items.map(it => it.product.id),
            quantities: CartManager.cart.items.map(it => it.amount),
            subtotals: CartManager.cart.items.map(it => it.getPrices(CartManager.cart.subItems).price / 100),
            total: CartManager.cart.getPrice() / 100,

            ...this.identifiers,
        })
    }

    async onOrderConfirmation(i18n: I18n, order: OrderConsumer, slot: string | null) {
        const categoryMappedItems = order.items.map((it) => this.lookup[it.product.id])
        this.call({
            shopname: ServerManager.shop.name,
            mode: order.consumptionMode,
            page: 'orderplaced',

            cartid: CartManager.cart.id,

            categories: categoryMappedItems.map((it) => it.category!.name.getForI18n(i18n)),
            categoryids: categoryMappedItems.map((it) => it.category!.id),
            subcategories: categoryMappedItems.map((it) => it.subcategory!.name.getForI18n(i18n)),
            subcategoryids: categoryMappedItems.map((it) => it.subcategory!.id),

            products: order.items.map(it => it.product.name.getForI18n(i18n)),
            productids: order.items.map(it => it.product.id),
            quantities: order.items.map(it => it.amount),
            subtotals: order.items.map(it => it.getPrices(order.subItems).price / 100),
            total: order.price / 100,

            ...slot && {slot},

            ...this.identifiers,
        })
    }

    refreshOffers() {
        const cookies = document.cookie.split('; ').reduce((acc, val) => {
            const [k, v] = val.split('=')
            acc[k] = v
            return acc
        }, {})
        if (cookies['banner1'] === '1') {
            this.internalActiveOffer = offers.banner1
        } else if (cookies['banner2'] === '1') {
            this.internalActiveOffer = offers.banner2
        } else if (cookies['banner3'] === '1') {
            this.internalActiveOffer = offers.banner3
        } else {
            this.internalActiveOffer = null
        }
    }

    call(obj: any) {
        const it = window as WindowWithTealium
        if (it.asyncCall) {
            it.asyncCall(obj)
        }
    }
}

export const TealiumManager = new TealiumManagerStatic()

TealiumManager.refreshOffers();

(window as WindowWithTealium).refreshOffers = () => {
    TealiumManager.refreshOffers()
}
