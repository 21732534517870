import {AutoEncoder, field, StringDecoder} from '@simonbackx/simple-encoding'

export class DIProduct extends AutoEncoder {
    @field({decoder: StringDecoder})
    productName: string

    @field({decoder: StringDecoder})
    available: string

    @field({decoder: StringDecoder})
    deleted: string

    @field({decoder: StringDecoder})
    categoryID: string

    @field({decoder: StringDecoder})
    productID: string

    @field({decoder: StringDecoder})
    vOrder: string

    @field({decoder: StringDecoder, optional: true})
    price: string

    @field({decoder: StringDecoder, optional: true})
    dynamicPrice: string

    @field({decoder: StringDecoder, optional: true})
    vat: string

    @field({decoder: StringDecoder, optional: true})
    fixedVat: string

    @field({decoder: StringDecoder, optional: true, defaultValue: () => null, nullable: true})
    modifiers: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true, defaultValue: () => null})
    productDescription: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true, defaultValue: () => null})
    productImage: string | null = null

    @field({decoder: StringDecoder, optional: true})
    groupID: string
}
