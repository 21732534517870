import {CartManager} from './CartManager'

export class KioskModeService {
    private static readonly localStorageKey = 'kioskModeEnabled'

    static isKioskModeEnabled(): boolean {
        return localStorage.getItem(this.localStorageKey) === 'true'
    }

    static setKioskModeEnabled(value: boolean): void {
        localStorage.setItem(this.localStorageKey, value ? 'true' : 'false')
        CartManager.setCustomerType()
    }
}
