import {AutoEncoder, BooleanDecoder, EnumDecoder, field, NumberDecoder, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {ConsumptionOptionType} from './ConsumptionOptions'
import {I18n} from './I18n'
import {Image} from './Image'
import {PaymentProvider} from './PaymentProvider'

class TransactionFees extends AutoEncoder {
    @field({decoder: NumberDecoder, defaultValue: () => 0})
    fixed: number

    /**
     * 1.5 = 1.5%
     */
    @field({decoder: NumberDecoder, defaultValue: () => 0})
    percentage: number

    static default(type: PaymentMethodType): TransactionFees {
        switch (type) {
            case PaymentMethodType.PointOfSale:
                return TransactionFees.create({fixed: 0, percentage: 0})
            case PaymentMethodType.Starnet:
                return TransactionFees.create({fixed: 0, percentage: 0})
            case PaymentMethodType.CreditCard:
                return TransactionFees.create({fixed: 17, percentage: 1.5})
            default:
                return TransactionFees.create({fixed: 17, percentage: 0})
        }
    }
}

export enum PaymentMethodType {
    ApplePay = 'ApplePay',
    Bancontact = 'Bancontact',
    BancontactMobile = 'BancontactMobile',
    CreditCard = 'CreditCard',
    Digitin = 'Digitin',
    GooglePay = 'GooglePay',
    IDeal = 'IDeal',
    Masterpass = 'Masterpass',
    MBWay = 'MBWay',
    Multibanco = 'Multibanco',
    Payconiq = 'Payconiq',
    PayPal = 'PayPal',
    PointOfSale = 'PointOfSale',
    RatePay = 'RatePay',
    RatePayDirectDebit = 'RatePayDirectDebit',
    SamsungPay = 'SamsungPay',
    SepaDirectDebit = 'SepaDirectDebit',
    Sofort = 'Sofort',
    Starnet = 'Starnet',
    VisaClickToPay = 'VisaClickToPay',
}

export enum VerificationMethod {
    None = 'None',
    Birthdate = 'Birthdate',
}

export class VerificationMethodHelper {
    static getName(method: VerificationMethod, i18n: I18n) {
        switch (method) {
            case VerificationMethod.None:
                return i18n.t('verificationMethods.none')
            case VerificationMethod.Birthdate:
                return i18n.t('verificationMethods.birthdate')
            default: {
                // compile error = not all caught
                const t: never = method
                throw new Error(`Unknown verification method: ${t}`)
            }
        }
    }
}

export class PaymentMethodSettings extends AutoEncoder {
    @field({decoder: StringDecoder, nullable: true})
    starnetIP: string | null = null

    @field({decoder: BooleanDecoder, optional: true})
    starnetUIDMode = false

    @field({decoder: StringDecoder, nullable: true, version: 68})
    shopIdentifier: string | null = null

    @field({decoder: StringDecoder, nullable: true, version: 68})
    apiKey: string | null = null

    @field({decoder: BooleanDecoder, nullable: true, version: 44})
    hideOtherPaymentMethods: boolean | null = null

    @field({decoder: new EnumDecoder(VerificationMethod), nullable: true, version: 46, upgrade: () => null})
    verificationMethod: VerificationMethod | null = null

    @field({decoder: BooleanDecoder, nullable: true, version: 46, upgrade: () => true})
    enableTopup: boolean | null = null

    @field({decoder: StringDecoder, nullable: true, version: 48, upgrade: () => null})
    cardIDRegex: string | null = null

    @field({decoder: StringDecoder, nullable: true, version: 48, upgrade: () => null})
    cardIDPlaceholder: string | null = null

    @field({decoder: StringDecoder, nullable: true, version: 48, upgrade: () => null})
    cardIDDescription: string | null = null
}

export class PaymentMethod extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: new EnumDecoder(PaymentMethodType)})
    type: PaymentMethodType

    @field({
        decoder: new EnumDecoder(PaymentProvider), version: 57, upgrade: function (this: PaymentMethod) {
            return this.getOldPaymentProvider()
        },
    })
    provider: PaymentProvider // No default to prevent forgetting to set this correctly

    @field({decoder: StringDecoder, nullable: true, version: 42, optional: true})
    name: string | null = null

    @field({decoder: Image, nullable: true, version: 42, optional: true})
    image: Image | null = null

    @field({decoder: PaymentMethodSettings, nullable: true, version: 42, optional: true})
    settings: PaymentMethodSettings | null = null

    @field({
        decoder: TransactionFees, version: 54, upgrade: function (this: PaymentMethod) {
            if (this.getPaymentProvider() === PaymentProvider.Paynl) {
                return TransactionFees.create({fixed: 17, percentage: this.type === PaymentMethodType.CreditCard ? 1.5 : 0})
            }
            return TransactionFees.create({fixed: 0, percentage: 0})
        }, defaultValue: () => TransactionFees.create({}),
    })
    transactionFees: TransactionFees

    getName(i18n: I18n, mode: ConsumptionOptionType = ConsumptionOptionType.DineIn, hasPayconic = false) {
        if (this.name) {
            return this.name
        }

        switch (this.type) {
            case PaymentMethodType.ApplePay:
                return i18n.t('paymentMethods.applePay')
            case PaymentMethodType.Bancontact:
                return hasPayconic ? i18n.t('paymentMethods.bancontact') : i18n.t('paymentMethods.bancontactOrPayconiq')
            case PaymentMethodType.BancontactMobile:
                return i18n.t('paymentMethods.bancontactMobile')
            case PaymentMethodType.CreditCard:
                return i18n.t('paymentMethods.creditCard')
            case PaymentMethodType.Digitin:
                return i18n.t('paymentMethods.digitin')
            case PaymentMethodType.GooglePay:
                return i18n.t('paymentMethods.googlePay')
            case PaymentMethodType.IDeal:
                return i18n.t('paymentMethods.iDeal')
            case PaymentMethodType.Masterpass:
                return i18n.t('paymentMethods.masterpass')
            case PaymentMethodType.MBWay:
                return i18n.t('paymentMethods.mbWay')
            case PaymentMethodType.Multibanco:
                return i18n.t('paymentMethods.multibanco')
            case PaymentMethodType.Payconiq:
                return i18n.t('paymentMethods.payconiq')
            case PaymentMethodType.PayPal:
                return i18n.t('paymentMethods.payPal')
            case PaymentMethodType.PointOfSale:
                switch (mode) {
                    case ConsumptionOptionType.DineIn:
                        return i18n.t('paymentMethods.pointOfSale')
                    case ConsumptionOptionType.TakeAway:
                        return i18n.t('paymentMethods.pointOfSaleTakeAway')
                    case ConsumptionOptionType.Delivery:
                        return i18n.t('paymentMethods.pointOfSaleDelivery')
                    default: {
                        // compile error = not all caught
                        const t: never = mode
                        throw new Error(`Unknown consumption mode: ${t}`)
                    }
                }
            case PaymentMethodType.RatePay:
                return i18n.t('paymentMethods.ratePay')
            case PaymentMethodType.RatePayDirectDebit:
                return i18n.t('paymentMethods.ratePayDirectDebit')
            case PaymentMethodType.SamsungPay:
                return i18n.t('paymentMethods.samsungPay')
            case PaymentMethodType.SepaDirectDebit:
                return i18n.t('paymentMethods.sepaDirectDebit')
            case PaymentMethodType.Sofort:
                return i18n.t('paymentMethods.sofort')
            case PaymentMethodType.Starnet:
                return i18n.t('paymentMethods.starnet')
            case PaymentMethodType.VisaClickToPay:
                return i18n.t('paymentMethods.visaClickToPay')
            default: {
                // compile error = not all caught
                const t: never = this.type
                throw new Error(`Unknown payment method: ${t}`)
            }
        }
    }

    getShortDescription(i18n: I18n) {
        switch (this.type) {
            case PaymentMethodType.PointOfSale:
                return i18n.t('paymentMethods.notPaid')
            default:
                return i18n.t('paymentMethods.paid')
        }
    }

    getDescription(i18n: I18n, mode: ConsumptionOptionType = ConsumptionOptionType.DineIn) {
        switch (this.type) {
            case PaymentMethodType.PointOfSale:
                switch (mode) {
                    case ConsumptionOptionType.DineIn:
                        return i18n.t('paymentMethods.pointOfSale')
                    case ConsumptionOptionType.TakeAway:
                        return i18n.t('paymentMethods.pointOfSaleTakeAway')
                    case ConsumptionOptionType.Delivery:
                        return i18n.t('paymentMethods.pointOfSaleDelivery')
                    default: {
                        // compile error = not all caught
                        const t: never = mode
                        throw new Error(`Unknown consumption mode: ${t}`)
                    }
                }
            default:
                return i18n.t('paymentMethods.paidWith', {method: this.getName(i18n)})
        }
    }

    isCashless(): boolean {
        switch (this.type) {
            case PaymentMethodType.Starnet:
            case PaymentMethodType.Digitin:
                return true
            default:
                return false
        }
    }

    /** @deprecated */
    private getOldPaymentProvider(): PaymentProvider {
        switch (this.type) {
            case PaymentMethodType.PointOfSale:
                return PaymentProvider.None
            case PaymentMethodType.Starnet:
                return PaymentProvider.None
            case PaymentMethodType.Digitin:
                return PaymentProvider.None
            default:
                return PaymentProvider.Paynl
        }
    }

    /** @deprecated */
    getPaymentProvider(): PaymentProvider {
        return this.provider
    }
}
