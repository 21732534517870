import {AutoEncoder, EnumDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {DiscountType} from './Discount'

export class DiscountSource extends AutoEncoder {
    @field({decoder: StringDecoder})
    id: string = uuidv4()

    @field({decoder: new EnumDecoder(DiscountType)})
    type: DiscountType
}
