import {ArrayDecoder, AutoEncoder, field, IntegerDecoder, StringDecoder} from '@simonbackx/simple-encoding'

export class UDeparment extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    id: number

    @field({decoder: StringDecoder, defaultValue: () => ''})
    name: string = ''

    @field({decoder: IntegerDecoder})
    number: number

    @field({decoder: new ArrayDecoder(IntegerDecoder), optional: true, defaultValue: () => []})
    salesAreaIds: Array<number>

    @field({decoder: IntegerDecoder})
    supplementOptionId: number

    @field({decoder: IntegerDecoder})
    condimentOptionId: number

    @field({decoder: IntegerDecoder})
    groupId: number

    @field({decoder: new ArrayDecoder(IntegerDecoder), optional: true, defaultValue: () => []})
    specialArticleIds: Array<number>

    @field({decoder: StringDecoder})
    hqId: string
}
