import {AutoEncoder, field, StringDecoder} from '@simonbackx/simple-encoding'

export class CountryDialCode extends AutoEncoder {
    @field({decoder: StringDecoder})
    name: string

    @field({decoder: StringDecoder, optional: true})
    dialCode?: string

    @field({decoder: StringDecoder})
    code: string
}
