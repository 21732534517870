import {ArrayDecoder, AutoEncoder, DateDecoder, field, IntegerDecoder} from '@simonbackx/simple-encoding'

export class Timeslot extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    start: number

    @field({decoder: IntegerDecoder})
    end: number
}

export class DayWithTimeslots extends AutoEncoder {
    @field({decoder: DateDecoder})
    date: Date = new Date()

    @field({decoder: DateDecoder, nullable: true})
    previous: Date | null = null

    @field({decoder: DateDecoder, nullable: true})
    next: Date | null = null

    @field({decoder: new ArrayDecoder(Timeslot)})
    timeslots: Array<Timeslot> = []
}
