



























import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {PriceHelper} from '../../helpers/PriceHelper'
import DRSTBox from '@dorst/components/src/DRSTBox.vue'
import DRSTBoxItem from '@dorst/components/src/DRSTBoxItem.vue'
import Radio from '@dorst/components/src/inputs/Radio.vue'
import {ProductVariant, TranslatedString} from '@dorst/structures'

@Component({
    components: {
        DRSTBoxItem,
        DRSTBox,
        Radio,
    },
})

export default class ProductVariantBox extends Mixins(NavigationMixin) {
    @Prop({required: true})
    productVariants: Array<ProductVariant>

    @Prop({required: true})
    pricesMap: Map<string, number>

    @Prop({required: true})
    showAbsolutePrices: boolean

    @Prop({required: true})
    editable: boolean

    @Prop({required: true})
    value: string

    setSelectedVariant(value) {
        this.$emit('input', value)
    }

    translate(str: TranslatedString) {
        return str.getForI18n(this.$i18n as any)
    }

    getPriceForVariant(variant: ProductVariant): string | null {
        if (this.showAbsolutePrices) {
            return variant.price === 0 ? null : PriceHelper.format({price: variant.price})
        } else {
            const price = this.pricesMap.get(variant.id) ?? 0
            return price === 0
                ? null
                : PriceHelper.format({
                    price,
                    signDisplay: 'exceptZero',
                })
        }
    }
}
