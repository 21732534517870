












import {Component, Prop, Vue} from 'vue-property-decorator'

/**
 * Same as box, but without the visible background, radius and shadow
*/
@Component
export default class DRSTList extends Vue {
    @Prop({default: '', type: String})
    title!: string
}
