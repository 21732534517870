




































import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({
    model: {
        prop: 'modelValue',
        event: 'change',
    },
})
export default class Radio extends Vue {
    @Prop({default: '', type: String})
    name!: string

    @Prop({default: ''})
    value!: any

    @Prop({})
    modelValue!: any

    @Prop({default: false})
    disabled: boolean

    get radioButtonValue() {
        return this.modelValue
    }

    set radioButtonValue(value) {
        this.$emit('change', value)
    }
}
