var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DRSTBoxItem",
    {
      staticClass: "cart-item",
      attrs: { selectable: true, "data-testid": "cart-item-row" },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "amount-times", attrs: { slot: "left" }, slot: "left" },
        [
          _vm._v("\n        " + _vm._s(_vm.cartItem.amount) + "\n        "),
          _c("span", { staticClass: "icon times" })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "cart-item-detail" },
        [
          _c("h3", [
            _c(
              "span",
              [
                _vm._v(_vm._s(_vm.name)),
                _vm.formattedUnitPrice != null
                  ? [_vm._v(_vm._s(_vm.formattedUnitPrice))]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c("button", { staticClass: "button icon disclosure" })
          ]),
          _vm._v(" "),
          _vm.availabilityTexts.length > 0
            ? _c("AvailabilityLabel", {
                attrs: {
                  "product-id": _vm.cartItem.product.id,
                  "availability-texts": _vm.availabilityTexts
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("pre", { staticClass: "description" }, [
            _vm._v(_vm._s(_vm.optionDescription))
          ]),
          _vm._v(" "),
          _c(
            "footer",
            [
              _c("Stepper", {
                on: { input: _vm.change },
                nativeOn: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                },
                model: {
                  value: _vm.cartItem.amount,
                  callback: function($$v) {
                    _vm.$set(_vm.cartItem, "amount", $$v)
                  },
                  expression: "cartItem.amount"
                }
              }),
              _vm._v(" "),
              _c("button", {
                staticClass: "button icon trash",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.deleteItem()
                  }
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "price" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm._f("price")(_vm.price)) +
                    "\n            "
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.upsellRow && _vm.volumeUpsellsProposal !== null
        ? _c("VolumeUpsellProposalBox", {
            attrs: {
              "volume-upsells-proposal": _vm.volumeUpsellsProposal,
              "cart-item-price": _vm.price
            },
            on: { swap: _vm.convertIntoVolumeUpsellsProposal }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }