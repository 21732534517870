export enum WeekDay {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7,
}

interface i18n {
    t: (key: string, options?: any) => string
}

export class WeekdayHelper {
    static getName(weekday: WeekDay, i18n: i18n) {
        switch (weekday) {
            case 1:
                return i18n.t('general.weekDays.monday').toString()
            case 2:
                return i18n.t('general.weekDays.tuesday').toString()
            case 3:
                return i18n.t('general.weekDays.wednesday').toString()
            case 4:
                return i18n.t('general.weekDays.thursday').toString()
            case 5:
                return i18n.t('general.weekDays.friday').toString()
            case 6:
                return i18n.t('general.weekDays.saturday').toString()
            case 7:
                return i18n.t('general.weekDays.sunday').toString()
            default:
                return '?'
        }
    }

    static getNext(weekday: WeekDay): WeekDay {
        const n = weekday + 1
        if (n > 7) {
            return WeekDay.Monday
        }
        return n
    }

    static getPrevious(weekday: WeekDay): WeekDay {
        const n = weekday - 1
        if (n < 1) {
            return WeekDay.Sunday
        }
        return n
    }

    static get fullWeek(): Array<WeekDay> {
        return [WeekDay.Monday, WeekDay.Tuesday, WeekDay.Wednesday, WeekDay.Thursday, WeekDay.Friday, WeekDay.Saturday, WeekDay.Sunday]
    }
}
