var render, staticRenderFns
import script from "./TransitionExpand.vue?vue&type=script&lang=ts&"
export * from "./TransitionExpand.vue?vue&type=script&lang=ts&"
import style0 from "./TransitionExpand.vue?vue&type=style&index=0&id=9957c2b4&scoped=true&lang=scss&"
import style1 from "./TransitionExpand.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9957c2b4",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wannes.vermeulen/Repositories/dorst-app/dorst/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9957c2b4')) {
      api.createRecord('9957c2b4', component.options)
    } else {
      api.reload('9957c2b4', component.options)
    }
    
  }
}
component.options.__file = "components/src/transitions/TransitionExpand.vue"
export default component.exports