var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "drst-list" }, [
    _vm.title
      ? _c("h2", [_vm._v("\n        " + _vm._s(_vm.title) + "\n    ")])
      : _vm._e(),
    _vm._v(" "),
    _c("div", [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }