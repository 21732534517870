import {DateTime} from 'luxon'

export class DateHelper {
    /**
     * Checks if two given DateTimes are the same day (of the same year)
     * @param dateA
     * @param dateB
     */
    static isSameDay(dateA: DateTime, dateB: DateTime) {
        return (
            dateA.hasSame(dateB, 'day')
            && dateA.hasSame(dateB, 'month')
            && dateA.hasSame(dateB, 'year')
        )
    }

    /**
     * Returns the difference in days (as an absolute value).
     * @param dateA - Date 1 (to compare with 2)
     * @param dateB - Date 2 (to compare with 1)
     */
    static dayDiff(dateA: DateTime, dateB: DateTime): number {
        return Math.abs(dateA.diff(dateB, 'days').days)
    }

    /**
     * Sets the time of a given DateTime to 0
     * @param date
     */
    static truncate(date: DateTime) {
        return date.set({hour: 0, minute: 0, second: 0, millisecond: 0})
    }

    /**
     * Checks if the time of a given DateTime falls between two minute-based offsets.
     * @param date
     * @param startTimeMinutes
     * @param endTimeMinutes
     */
    static isBetweenTimes(date: DateTime, startTimeMinutes: number, endTimeMinutes: number) {
        const dateTimeMinutes = date.hour * 60 + date.minute
        return dateTimeMinutes >= startTimeMinutes && dateTimeMinutes <= endTimeMinutes
    }

    static isPast(d: Date): boolean {
        return d.getTime() < new Date().getTime()
    }
}
