import {EnumDecoder} from '@simonbackx/simple-encoding'

import {ConsumptionOptionType} from './ConsumptionOptions'

export enum ShopType {
    BarLounge = 'BarLounge',
    BeachClub = 'BeachClub',
    Cafe = 'Cafe',
    Cinema = 'Cinema',
    CloudKitchenMulti = 'CloudKitchenMulti',
    CloudKitchenSingle = 'CloudKitchenSingle',
    CoffeeBar = 'CoffeeBar',
    Default = 'Default',
    DessertBakes = 'DessertBakes',
    FoodCourt = 'FoodCourt',
    Hotel = 'Hotel',
    LargeVenue = 'LargeVenue',
    LeisureParkIn = 'LeisureParkIn',
    LeisureParkInOut = 'LeisureParkInOut',
    LeisureParkOut = 'LeisureParkOut',
    Other = 'Other',
    PoolBar = 'PoolBar',
    PopUp = 'PopUp',
    Restaurant = 'Restaurant',
    RestaurantCasual = 'RestaurantCasual',
    RestaurantFine = 'RestaurantFine',
    RestaurantQuick = 'RestaurantQuick',
    SportsStadium = 'SportsStadium',
}

export const ShopTypeDecoder = new EnumDecoder(ShopType)

interface i18n {
    t: (key: string, options?: any) => string
}

export enum Pathname {
    Table = 'table',
    Place = 'place',
    Location = 'location',
}

export class ShopTypeHelper {
    static getName(shopType: ShopType, i18n: i18n) {
        switch (shopType) {
        case ShopType.BarLounge:
            return i18n.t('shopTypes.barLounge')
        case ShopType.BeachClub:
             return i18n.t('shopTypes.beachClub')
        case ShopType.Cafe:
            return i18n.t('shopTypes.cafe')
        case ShopType.Cinema:
            return i18n.t('shopTypes.cinema')
        case ShopType.CloudKitchenMulti:
            return i18n.t('shopTypes.cloudKitchenMulti')
        case ShopType.CloudKitchenSingle:
            return i18n.t('shopTypes.cloudKitchenSingle')
        case ShopType.CoffeeBar:
            return i18n.t('shopTypes.coffeeBar')
        case ShopType.Default:
            return i18n.t('shopTypes.default')
        case ShopType.DessertBakes:
            return i18n.t('shopTypes.dessertBakes')
        case ShopType.FoodCourt:
            return i18n.t('shopTypes.foodCourt')
        case ShopType.Hotel:
            return i18n.t('shopTypes.hotel')
        case ShopType.Restaurant:
            return i18n.t('shopTypes.restaurant')
        case ShopType.LargeVenue:
            return i18n.t('shopTypes.largeVenue')
        case ShopType.LeisureParkIn:
            return i18n.t('shopTypes.leisureParkIn')
        case ShopType.LeisureParkInOut:
            return i18n.t('shopTypes.leisureParkInOut')
        case ShopType.LeisureParkOut:
            return i18n.t('shopTypes.leisureParkOut')
        case ShopType.Other:
            return i18n.t('shopTypes.other')
        case ShopType.PoolBar:
            return i18n.t('shopTypes.poolBar')
        case ShopType.PopUp:
            return i18n.t('shopTypes.popUp')
        case ShopType.RestaurantCasual:
            return i18n.t('shopTypes.restaurantCasual')
        case ShopType.RestaurantFine:
            return i18n.t('shopTypes.restaurantFine')
        case ShopType.RestaurantQuick:
            return i18n.t('shopTypes.restaurantQuick')
        case ShopType.SportsStadium:
            return i18n.t('shopTypes.sportsStadium')
        default: {
            const t: never = shopType
            throw new Error(`Unknown shop type: ${t}`)
        }
        }
    }

    static getPathname(shopType: ShopType, type: ConsumptionOptionType) {
        if (type !== ConsumptionOptionType.DineIn) {
            return Pathname.Location
        }
        return this.getSpecific(shopType, Pathname.Table, Pathname.Place)
    }

    static getSpecific<T>(shopType: ShopType, tableT: T, placeT: T): T {
        switch (shopType) {
        case ShopType.LargeVenue:
            return placeT
        case ShopType.SportsStadium:
            return placeT
        default:
            return tableT
        }
    }

    static isTableMode(shopType: ShopType): boolean {
        return this.getSpecific(shopType, true, false)
    }
}
