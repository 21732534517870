import {AutoEncoder, BooleanDecoder, DateDecoder, EnumDecoder, field, IntegerDecoder, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {DiscountDetails, DiscountMode} from './DiscountDetails'
import {DiscountMeta} from './DiscountMeta'

export enum DiscountType {
    VOUCHER = 'Voucher',
    EMPLOYEE = 'Employee',
}

export enum DiscountUse {
    SINGLE_USE = 'SingleUse',
    MULTI_USE = 'MultiUse',
}

export class Discount extends AutoEncoder {
    @field({decoder: StringDecoder})
    id: string = uuidv4()

    /* When `null`, the discount is a "global" discount */
    @field({decoder: IntegerDecoder, nullable: true})
    shopId: number | null = null

    @field({decoder: StringDecoder, optional: true})
    description = ''

    @field({decoder: StringDecoder})
    code: string

    @field({decoder: StringDecoder, nullable: true, optional: true})
    verification: string | null = null

    @field({decoder: new EnumDecoder(DiscountType)})
    type: DiscountType

    @field({decoder: new EnumDecoder(DiscountUse)})
    use: DiscountUse = DiscountUse.SINGLE_USE

    @field({decoder: DateDecoder, nullable: true, optional: true})
    from: Date | null = null

    @field({decoder: DateDecoder, nullable: true, optional: true})
    to: Date | null = null

    @field({decoder: DiscountDetails, optional: true})
    details: DiscountDetails = DiscountDetails.create({})

    @field({decoder: BooleanDecoder, optional: true})
    used = false

    @field({decoder: BooleanDecoder, optional: true})
    enabled = false

    @field({decoder: DiscountMeta, optional: true})
    meta: DiscountMeta = DiscountMeta.create({})

    get shouldBeDisabled() {
        return (this.code === '') || (this.verification === '' && this.type === DiscountType.EMPLOYEE) || (this.details.mode === DiscountMode.NONE)
    }

    get isGlobal() {
        return this.shopId === null
    }

    static getCodeLength() {
        return 8
    }

    calculateForPrice(price: number) {
        const {mode, absolute, relative} = this.details

        switch (mode) {
            case DiscountMode.NONE:
                return 0
            case DiscountMode.ABSOLUTE:
                return Math.min(price, absolute ?? 0)
            case DiscountMode.RELATIVE:
                return Math.round(Math.min(100, (relative ?? 0) / 100) * price / 100)
            default: {
                const t: never = mode
                throw new Error(`Unsupported discount mode: ${t}`)
            }
        }
    }
}
