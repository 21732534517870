















































import {Component, Prop, Vue} from 'vue-property-decorator'

import TransitionExpand from './transitions/TransitionExpand.vue'

@Component({
    components: {
        TransitionExpand,
    },
})
export default class DRSTBoxExpandableItem extends Vue {
    @Prop({default: 'article', type: String})
    elementName!: string

    @Prop({default: false, type: Boolean})
    selectable!: boolean

    @Prop({default: false, type: Boolean})
    expanded!: boolean

    /**
     * When true, we store the toggle state inside this component itself and only use expanded as initial value.
     */
    @Prop({default: false, type: Boolean})
    toggleOnClick!: boolean

    @Prop({default: false, type: Boolean})
    showChevron!: boolean

    @Prop({default: false, type: Boolean})
    rightStack!: boolean

    expandToggled = false

    mounted() {
        if (this.toggleOnClick) {
            this.expandToggled = this.expanded
        }
    }

    get isExpanded() {
        return this.toggleOnClick
            ? this.expandToggled
            : this.expanded
    }

    onClick($event) {
        if (this.toggleOnClick) {
            this.expandToggled = !this.expandToggled
            this.$emit('expandState', this.expandToggled)
        }
        this.$emit('click', $event)
    }
}
