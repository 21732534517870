






































































import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {DiscountActions} from '../actions/Discounts'
import {ServerManager} from '../classes/ServerManager'
import {
    DateInput,
    DRSTBox,
    DRSTBoxItem,
    DRSTFloatingFooter,
    DRSTNavigationBar,
    Radio,
    RemarkBlock,
    Spinner,
    SpinnerButton,
    TextInputRow,
    Toggle,
} from '@dorst/components'
import {Discount, DiscountSource, DiscountType} from '@dorst/structures'

@Component({
    components: {
        DRSTBox,
        RemarkBlock,
        DRSTBoxItem,
        DRSTFloatingFooter,
        DRSTNavigationBar,
        DateInput,
        Radio,
        Spinner,
        SpinnerButton,
        TextInputRow,
        Toggle,
    },
})
export default class DiscountSelectionView extends Mixins(NavigationMixin) {
    @Prop({required: true})
    applyDiscount: (discount: Discount) => void

    DiscountType = DiscountType

    selectedSource: DiscountSource = this.discountSources[0]

    editCode: string = ''
    verification: string = ''

    error: string | null = null

    get discountSources() {
        return ServerManager.shop.discountSources
    }

    get code() {
        return this.editCode
    }

    set code(code) {
        this.editCode = code.toUpperCase()
    }

    get canVerify(): boolean {
        if (this.code === '') {
            return false
        }
        if (this.verification === '' && this.selectedSource.type === DiscountType.EMPLOYEE) {
            return false
        }
        return true
    }

    async verifyDiscount() {
        this.error = null

        try {
            const discount = await DiscountActions.VerifyDiscount(this.code, this.verification)
            this.applyDiscount(discount)
            this.pop()
        } catch (error) {
            this.error = this.$t('consumer.discounts.invalid').toString()
        }
    }

    getName(source: DiscountSource) {
        switch (source.type) {
            case DiscountType.VOUCHER:
                return this.$t('backoffice.discounts.voucher').toString()
            case DiscountType.EMPLOYEE:
                return this.$t('backoffice.discounts.employeeDiscount').toString()
            default: {
                const t: never = source.type
                throw new Error(`Unsupported discount use type: ${t}`)
            }
        }
    }

    selectSource(source: DiscountSource) {
        this.selectedSource = source
        this.error = null
    }
}
