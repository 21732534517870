export class Validator {

    static email(email: string) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return regex.test(email)
    }

    static cardID(cardID: string, regex?: string | null): boolean {
        const re = regex ? new RegExp(regex) : /^\d{8,9}$/
        return re.test(cardID)
    }

    static birthdate(date: string): boolean {
        const re = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[012])\/(19|20)\d\d$/
        return re.test(date)
    }

    static ip(ip: string) {
        const validator = new RegExp('^((25[0-5]|(2[0-4]|1\\d|[1-9]|)\\d)(\\.(?!$)|$)){4}$')
        return validator.test(ip)
    }

    static startsWithHttpOrHttps(url: string): boolean {
        return url.startsWith('http://') || url.startsWith('https://')
    }

    static isInstagramUrl(url: string): boolean {
        return url.startsWith('https://instagram.com/') || url.startsWith('https://www.instagram.com/')
    }

    static isFacebookUrl(url: string): boolean {
        return url.startsWith('https://facebook.com/') || url.startsWith('https://www.facebook.com/')
    }
}

export class PasswordValidator {
    static hasValidLength(password, minLength = 10) {
        return password.length >= minLength
    }

    static hasAnyUppercase(password) {
        return /[A-Z]/.test(password)
    }

    static hasAnyLowercase(password) {
        return /[a-z]/.test(password)
    }

    static hasAnyDigit(password) {
        return /[0-9]/.test(password)
    }

    static hasAnySpecialChar(password) {
        return /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/.test(password)
    }

    static hasAnyConsecutiveChars(password) {
        return /([a-zA-Z0-9])\1\1/.test(password)
    }

    static hasValidComplexity(password) {
        return [
            PasswordValidator.hasAnyUppercase(password),
            PasswordValidator.hasAnyLowercase(password),
            PasswordValidator.hasAnyDigit(password),
            PasswordValidator.hasAnySpecialChar(password),
            !this.hasAnyConsecutiveChars(password),
        ].filter(it => it).length >= 3
    }
}
