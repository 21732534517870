











































import {ComponentWithProperties, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import {PruneHelper} from '../helpers/PruneHelper'
import ProductBoxItem from './ProductBoxItem.vue'
import ProductView from './ProductView.vue'
import DRSTBox from '@dorst/components/src/DRSTBox.vue'
import DRSTBoxItem from '@dorst/components/src/DRSTBoxItem.vue'
import DRSTFloatingFooter from '@dorst/components/src/DRSTFloatingFooter.vue'
import DRSTList from '@dorst/components/src/DRSTList.vue'
import DRSTNavigationBar from '@dorst/components/src/DRSTNavigationBar.vue'
import LoadingCover from '@dorst/components/src/LoadingCover.vue'
import TextInputRow from '@dorst/components/src/inputs/TextInputRow.vue'
import {CartItem, Product, ShopFull} from '@dorst/structures'

@Component({
    components: {
        DRSTNavigationBar,
        DRSTList,
        DRSTBox,
        DRSTBoxItem,
        ProductBoxItem,
        DRSTFloatingFooter,
        LoadingCover,
        TextInputRow,
    },
})
export default class SearchView extends Mixins(NavigationMixin) {
    @Prop()
    shop: ShopFull
    query = ''

    goToProduct(product: Product) {
        const {cartItem, subItems} = CartItem.defaultForProduct(product, ServerManager.shop.getLinkedProducts(product))
        this.show(new ComponentWithProperties(ProductView, {cartItem, shop: this.shop, subCartItems: subItems}))
    }

    get consumptionMode() {
        return CartManager.getConsumptionMode()
    }

    get products() {
        if (this.query.length == 0) {
            return []
        }

        const results: Array<{score: number; product: Product}> = []
        for (const product of PruneHelper.filterProducts(this.shop, this.consumptionMode)) {
            const score = product.matchQuery(this.query, this.$i18n as any)
            if (score > 0 && product.visibleIn(this.shop.timezone)) {
                results.push({
                    score: score,
                    product,
                })
            }
        }
        results.sort((a, b) => a.score > b.score ? 1 : -1)
        // wrap in destructured new map to make it unique (needed for duplicated items that exists on multiple categories)
        // we use the id as key in vue so it would crash if we have multiple with the same id
        return [...new Map(results.map(r => [r.product.id, r.product])).values()]
    }

    mounted() {
        // This does not work on Safari iOS, but we can only make this work if safari doesn't scroll when we focus immediately...
        setTimeout(() => {
            this.focus()
        }, 350)
    }

    focus() {
        (this.$refs.input as any)?.focus()
    }

    close(e) {
        (this.$refs.input as any)?.blur()
        setTimeout(() => {
            this.pop()
        }, 250)
    }
}
