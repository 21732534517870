var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "radio",
      class: { disabled: _vm.disabled },
      attrs: { "data-testid": "dorst-radiobutton" }
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.radioButtonValue,
            expression: "radioButtonValue"
          }
        ],
        attrs: { type: "radio", name: _vm.name, disabled: _vm.disabled },
        domProps: {
          value: _vm.value,
          checked: _vm._q(_vm.radioButtonValue, _vm.value)
        },
        on: {
          change: function($event) {
            _vm.radioButtonValue = _vm.value
          }
        }
      }),
      _vm._v(" "),
      _c("div", [
        _c("div", [
          _c(
            "svg",
            {
              attrs: {
                width: "11",
                height: "9",
                viewBox: "0 0 11 9",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  d: "M1 4.5L4 8L9.5 1",
                  stroke: "white",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", [_vm._t("default")], 2)
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }