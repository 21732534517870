var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "volume-proposal-box" }, [
    _vm.showPrices
      ? _c("p", { staticClass: "save-money-description" }, [
          _c("span", { staticClass: "icon info" }),
          _vm._v(
            "\n        " +
              _vm._s(
                _vm.$t("customer.volumeUpsells.saveMoneyDescription", {
                  price: _vm.proposalPriceDifference
                })
              ) +
              "\n    "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "button",
      {
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.$emit("swap")
          }
        }
      },
      [
        _vm._v(
          "\n        " +
            _vm._s(
              _vm.$t("customer.volumeUpsells.saveMoneyButtonText", {
                product:
                  (_vm.proposalAmount > 1 ? _vm.proposalAmount + "x " : "") +
                  _vm.productName
              })
            ) +
            " " +
            _vm._s(_vm.formattedProposalPrice) +
            "\n    "
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }