import {ArrayDecoder, AutoEncoder, BooleanDecoder, EnumDecoder, field, IntegerDecoder, ObjectData, PartialWithoutMethods, PatchType, StringDecoder} from '@simonbackx/simple-encoding'
import {DateTime} from 'luxon'
import {v4 as uuidv4} from 'uuid'

import {AvailabilityPeriodType, Version} from '../index'
import {ConsumptionOptionType} from './ConsumptionOptions'
import {Image} from './Image'
import {Language, TranslatedString, TranslatedStringDecoder, TranslatedStringPatch} from './Language'
import {OptionGroup} from './OptionGroup'
import {ProductAvailabilityPeriod} from './ProductAvailabilityPeriod'
import {ProductMeta} from './ProductMeta'
import {ProductPrice} from './ProductPrice'
import {UpsellGroup} from './Upsell'
import {WeekDay, WeekdayHelper} from './WeekDay'
import {Formatter} from '@dorst/validation'

interface i18n {
    t: (key: string, options?: any) => string
}

/** @deprecated Use Product (with variants) */
export class LegacyProduct extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: StringDecoder})
    @field({
        decoder: TranslatedStringDecoder,
        version: 28,
        upgrade: (str: string): TranslatedString => {
            return new TranslatedString({
                [Language.Dutch]: str,
            })
        },
        downgrade: (name: TranslatedString): string => {
            return name.getForLanguage(Language.Dutch)
        },
        upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
            if (!str) {
                return new TranslatedStringPatch({})
            }
            return new TranslatedStringPatch({[Language.Dutch]: str})
        },
        downgradePatch: (name: PatchType<TranslatedString>): PatchType<string> => {
            if (name.translations[Language.Dutch]) {
                return name.translations[Language.Dutch] ?? ''
            }
            return undefined
        },
    })
    name = new TranslatedString({})

    @field({decoder: StringDecoder})
    @field({
        decoder: TranslatedStringDecoder,
        version: 28,
        upgrade: (str: string): TranslatedString => {
            return new TranslatedString({
                [Language.Dutch]: str,
            })
        },
        downgrade: (name: TranslatedString): string => {
            return name.getForLanguage(Language.Dutch)
        },
        upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
            if (!str) {
                return new TranslatedStringPatch({})
            }
            return new TranslatedStringPatch({[Language.Dutch]: str})
        },
        downgradePatch: (name: PatchType<TranslatedString>): PatchType<string> => {
            if (name.translations[Language.Dutch]) {
                return name.translations[Language.Dutch] ?? ''
            }
            return undefined
        },
    })
    description = new TranslatedString({})

    @field({decoder: TranslatedStringDecoder, version: 67, defaultValue: () => new TranslatedString({}), upgrade: () => new TranslatedString({})})
    recipe = new TranslatedString({})

    @field({decoder: StringDecoder, version: 26, nullable: true})
    preparationLocationId: string | null = null

    @field({decoder: BooleanDecoder, version: 4})
    enabled = true

    /**
     * The prices of this product. If only one is present, you don't need to display them, you can just pick the price of the first one
     */
    @field({decoder: new ArrayDecoder(ProductPrice), defaultValue: () => [ProductPrice.create({name: '', price: 0})]})
    prices: Array<ProductPrice>

    @field({decoder: new ArrayDecoder(OptionGroup), defaultValue: () => []})
    optionGroups: Array<OptionGroup>

    @field({decoder: new ArrayDecoder(Image), defaultValue: () => []})
    images: Array<Image>

    /**
     * Empty array means always available
     */
    @field({decoder: new ArrayDecoder(ProductAvailabilityPeriod), version: 22, upgrade: () => [], defaultValue: () => []})
    availabilityPeriods: Array<ProductAvailabilityPeriod>

    @field({decoder: IntegerDecoder, version: 13, upgrade: () => 21})
    VAT = 21

    @field({decoder: new EnumDecoder(ConsumptionOptionType), version: 52, upgrade: () => ConsumptionOptionType.DineIn})
    consumptionMode: ConsumptionOptionType = ConsumptionOptionType.DineIn

    @field({decoder: ProductMeta, version: 36, optional: true})
    meta: ProductMeta = ProductMeta.create({})

    getName(lang: Language): string {
        if (this.name.isEmpty()) {
            return this.defaultName(lang)
        }
        return this.name.getForLanguage(lang)
    }

    getNameForI18n(i18n: {locale: string}): string {
        if (this.name.isEmpty()) {
            return this.defaultNameForI18n(i18n)
        }
        return this.name.getForI18n(i18n as any)
    }

    defaultName(lang: Language): string {
        switch (lang) {
            case Language.Dutch:
                return 'Naamloos'
            case Language.French:
                return 'Sans titre'
            case Language.English:
                return 'Unnamed'
            default:
                return 'Unnamed'
        }
    }

    defaultNameForI18n(i18n: {locale: string}) {
        let lang = i18n.locale.substr(0, 2)
        if (!(Object.values(Language) as Array<string>).includes(lang)) {
            lang = Language.English
        }
        return this.defaultName(lang as Language)
    }

    getBestPrice(): number {
        return this.getAllPrices()[0] ?? 0
    }

    getAllPrices(): Array<number> {
        return this.prices.map(p => p.price).sort()
    }

    /**
     * Returns a score for a given query
     * @param query
     */
    matchQuery(query: string, i18n: {locale: string}): number {
        const lowerQuery = query.toLowerCase()
        if (
            this.getNameForI18n(i18n).toLowerCase().includes(lowerQuery)
        ) {
            return 1
        }
        return 0
    }

    periodDoesMatchWeekday(date: Date, timezone: string, period: ProductAvailabilityPeriod): boolean {
        if (period.type !== AvailabilityPeriodType.WeekDays) {
            console.error('Calling match weekday on a non weekday period.')
            return false
        }
        const dateTime = DateTime.fromJSDate(date).setZone(timezone)

        const day = dateTime.weekday
        const time = dateTime.hour * 60 + dateTime.minute

        if (!period.days.includes(day)) {
            // Keep showing past midnight until we reach the end time
            if (period.doesContinuePastMidnight) {
                const d = WeekdayHelper.getPrevious(day)
                if (period.days.includes(d)) {
                    if (time < period.endTime) {
                        // Midnight cycle
                        return true
                    }
                    return false
                }
            }

            return false
        }
        return true
    }

    getAvailabilityText(timezone: string, i18n: i18n): string {
        if (this.availabilityPeriods.length == 0) {
            return ''
        }

        const date = new Date()

        let availableToday = false
        const intervals: Array<string> = []
        for (const period of this.availabilityPeriods) {
            if (this.periodDoesMatchWeekday(date, timezone, period)) {
                availableToday = true
                intervals.push(`${Formatter.minutesToTime(period.startTime)} - ${period.endTime == 60 * 24 ? Formatter.minutesToTime(period.endTime - 1) : Formatter.minutesToTime(period.endTime)}`)
            }
        }

        const days = this.availabilityPeriods.flatMap(p => p.days)
        const filteredDays: Array<string> = []
        // Sort + filter
        for (const day of Object.values(WeekDay)) {
            if (typeof day != 'number') {
                continue
            }
            if (days.includes(day)) {
                filteredDays.push(WeekdayHelper.getName(day, i18n))
            }
        }

        if (!availableToday) {
            return i18n.t('common.fields.products.onlyAvailableOn', {weekdays: filteredDays.join(', ')})
        }

        return i18n.t('common.fields.products.onlyBetween', {times: intervals.join(', ')})
    }

    isAvailableIn(timezone: string): boolean {
        if (this.availabilityPeriods.length == 0) {
            return true
        }

        const date = new Date()

        for (const period of this.availabilityPeriods) {
            if (period.doesMatchDate(date, timezone)) {
                return true
            }
        }

        return false
    }

    visibleIn(timezone: string): boolean {
        if (this.availabilityPeriods.length == 0) {
            return true
        }

        const date = new Date()

        for (const period of this.availabilityPeriods) {
            if (period.doesMatchDate(date, timezone, true)) {
                return true
            }
        }

        return false
    }

    getAvailableLanguages(): Array<Language> {
        return Object.keys({...this.name.translations, ...this.description.translations}) as Array<Language>
    }

    clone(): LegacyProduct {
        return LegacyProduct.decode(new ObjectData(this.encode({version: Version}), {version: Version}))
    }

    cloneConsumer(): LegacyProduct {
        const product = this.clone()
        return product
    }

    static createConsumer(p: PartialWithoutMethods<LegacyProduct>): LegacyProduct {
        const prod = LegacyProduct.create({...p})
        return prod
    }
}

export class LegacyProductBackoffice extends LegacyProduct {
    @field({decoder: StringDecoder, nullable: true, upgrade: () => null, version: 12})
    templateId: string | null = null

    @field({decoder: StringDecoder, nullable: true, upgrade: () => null, version: 50})
    templateVariantId: string | null = null

    @field({decoder: BooleanDecoder, version: 12})
    useTemplateName = false

    @field({decoder: BooleanDecoder, version: 12})
    useTemplateDescription = false

    @field({decoder: BooleanDecoder, version: 12})
    useTemplateImages = false

    @field({
        decoder: BooleanDecoder, version: 13, upgrade: function (this: LegacyProductBackoffice) {
            if (this.templateId) {
                return true
            }
            return false
        },
    })
    useTemplateVAT = false

    @field({decoder: ProductMeta, version: 36, defaultValue: () => ProductMeta.create({})})
    meta: ProductMeta = ProductMeta.create({})

    @field({decoder: new ArrayDecoder(UpsellGroup), optional: true, version: 73, defaultValue: () => []})
    upsellGroups: Array<UpsellGroup>
}
