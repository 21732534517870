var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "option-group-group-box" },
    [
      _c("SegmentedControl", {
        attrs: {
          value: _vm.selectedGroupId,
          items: _vm.optionGroups.map(function(el) {
            return el.id
          }),
          labels: _vm.tabLabels
        },
        on: {
          input: function($event) {
            return _vm.setActiveGroup($event)
          }
        }
      }),
      _vm._v(" "),
      _vm._l(_vm.selectedGroup, function(group) {
        return _c("OptionGroupBox", {
          key: _vm.editCartItem.selectedVariantInfo.variantId + "-" + group.id,
          attrs: {
            "part-of-linked-group": true,
            "only-annotation": true,
            value: _vm.value,
            "consumption-mode": _vm.consumptionMode,
            "edit-cart-item": _vm.editCartItem,
            "option-group": group,
            "sub-items": _vm.subItems,
            editable: _vm.editable,
            "price-offset": _vm.optionGroupPriceOffsets.get(
              _vm.selectedGroupId
            ),
            "has-amount-error": _vm.groupHasAmountError(group.id)
          },
          on: {
            input: function($event) {
              return _vm.changedOptions($event)
            },
            removeOptionProduct: function($event) {
              return _vm.removeOptionProduct($event)
            },
            addOptionProduct: function($event) {
              return _vm.addOptionProduct($event)
            },
            optionChanged: function($event) {
              return _vm.$emit("optionChanged")
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }