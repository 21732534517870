export class RegexpHelper {
    static isOwnedSubDomain(): RegExp {
        return new RegExp(/.*\.(dorst\.app|dorst\.dev|01\.staging\.dorst\.app|smakelijk\.app|localdining\.be|order24\.com|soif\.app|thirstapp\.co\.uk|dorst\.local)$/)
    }

    static isOwnedRootDomain(): RegExp {
        return new RegExp(/^(dorst\.app|dorst\.dev|01\.staging\.dorst\.app|smakelijk\.app|localdining\.be|order24\.com|soif\.app|thirstapp\.co\.uk|dorst\.local)$/)
    }

    static isConsumersEndpointUri(): RegExp {
        return new RegExp(/\/v[0-9]{2,3}\/consumer\//)
    }
}
