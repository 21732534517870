import {ArrayDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'

import {DecodableLanguageRecord} from '../DecodableRecord'
import {Language} from '../Language'
import {DeliverectTranslatable} from './DeliverectTranslatable'

export class DeliverectCategory extends DeliverectTranslatable {
    @field({decoder: StringDecoder})
    _id: string

    @field({decoder: StringDecoder})
    account: string

    @field({decoder: StringDecoder})
    name: string

    @field({decoder: DecodableLanguageRecord})
    nameTranslations: Partial<Record<Language, string>> = {}

    @field({decoder: StringDecoder})
    description: string

    @field({decoder: DecodableLanguageRecord})
    descriptionTranslations: Partial<Record<Language, string>> = {}

    @field({decoder: StringDecoder, nullable: true})
    imageUrl: string | null = null

    @field({decoder: new ArrayDecoder(StringDecoder)})
    products: Array<string> = []

    @field({decoder: StringDecoder})
    menu: string

    // Unused possible fields
    // availabilities: []
}
