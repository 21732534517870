

















import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import OrderDetailBox from './OrderDetailBox.vue'
import OrderItemRow from './OrderItemRow.vue'
import {DRSTBox, DRSTNavigationBar} from '@dorst/components'
import {OrderConsumer} from '@dorst/structures'

@Component({
    components: {
        DRSTNavigationBar,
        DRSTBox,
        OrderItemRow,
        OrderDetailBox,
    },
})
export default class OrderDetailView extends Mixins(NavigationMixin) {
    @Prop({required: true})
    order: OrderConsumer
}
