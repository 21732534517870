












import {Component, Prop, Vue} from 'vue-property-decorator'

import Spinner from './Spinner.vue'

@Component({
    components: {Spinner},
})
export default class SpinnerButton extends Vue {
    @Prop({default: false, type: Boolean})
    loading!: boolean
}
