var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "fade", mode: "out-in", appear: "" } },
    [
      _c(
        "div",
        { staticClass: "loading-cover" },
        [_c("Spinner", { staticClass: "gray centered" })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }