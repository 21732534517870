var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DRSTBox",
    { attrs: { title: _vm.title } },
    [
      _vm._l(_vm.order.items, function(item) {
        return _c("OrderItemRow", {
          key: item.id,
          attrs: { item: item, "sub-items": _vm.getSubItems(item) }
        })
      }),
      _vm._v(" "),
      _vm.order.description
        ? _c(
            "DRSTBoxItem",
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("consumer.remark.title")) +
                  "\n        "
              ),
              _c("template", { slot: "right" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.order.description) +
                    "\n        "
                )
              ])
            ],
            2
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }