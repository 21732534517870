























import {ComponentWithProperties, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {ContextMenu, DRSTBoxItem} from '@dorst/components'
import {ConsumptionOptionType, ConsumptionOptionTypeHelper} from '@dorst/structures'
import {Formatter} from '@dorst/validation'

@Component({
    components: {
        ContextMenu,
        DRSTBoxItem,
    },
})
export default class ConsumptionModeSelectionContextMenu extends Mixins(NavigationMixin) {
    @Prop({default: 0})
    x!: number

    @Prop({default: 0})
    y!: number

    @Prop({default: () => ConsumptionOptionType.DineIn})
    mode: ConsumptionOptionType

    @Prop({default: () => []})
    modes: Array<ConsumptionOptionType>

    @Prop()
    setMode!: (mode: ConsumptionOptionType) => void

    getModeName(mode: ConsumptionOptionType) {
        return ConsumptionOptionTypeHelper.getName(mode, this.$i18n as any)
    }
}
