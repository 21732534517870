import {ArrayDecoder, AutoEncoder, BooleanDecoder, EnumDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {ConsumptionOptionType} from './ConsumptionOptions'
import {IDShopConsumer} from './IDShopConsumer'
import {IDTableCategory} from './IDTableCategory'
import {Table} from './Table'

export class TableCategory extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: StringDecoder})
    name = ''

    @field({decoder: BooleanDecoder})
    enabled = true

    @field({decoder: StringDecoder})
    number = '1'

    /**
     * For which consumption option this should be available
     */
    @field({decoder: new EnumDecoder(ConsumptionOptionType), version: 63})
    type = ConsumptionOptionType.DineIn

    @field({decoder: new ArrayDecoder(Table), defaultValue: () => []})
    tables: Array<Table>

    @field({decoder: new ArrayDecoder(TableCategory), defaultValue: () => []})
    tableCategories: Array<TableCategory>

    static fromID(category: IDTableCategory, shop: IDShopConsumer) {
        // First create a reference so we can use the reference in infinite table category cycles
        const cat = TableCategory.create({
            id: category.id,
            name: category.name,
            enabled: category.enabled,
            number: category.number,
            type: category.type,
            tables: category.tableIds.flatMap((id) => {
                const t = shop.tables.find(p => p.id == id)
                return t ? [t] : []
            }),
            tableCategories: [],
        });

        // Save the reference to prevent infinite loop
        (category as any).tmpCategory = cat

        // get the categories
        cat.tableCategories = category.tableCategoryIds.flatMap((id) => {
            const c = shop.tableCategories.find(c => c.id == id)
            if (c && (c as any).tmpCategory) {
                return (c as any).tmpCategory as TableCategory
            }
            if (c) {
                const cat = TableCategory.fromID(c, shop)
                if (cat.tables.length > 0 || cat.tableCategories.length > 0) {
                    return [cat]
                }
            }
            return []
        })

        // Clear the reference
        delete (category as any).tmpCategory

        return cat
    }
}
