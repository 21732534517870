import {
    ArrayDecoder,
    AutoEncoderPatchType,
    BooleanDecoder,
    EnumDecoder,
    field,
    PartialWithoutMethods,
    PatchType,
    StringDecoder,
} from '@simonbackx/simple-encoding'

import {Address} from './Address'
import {CartItem} from './CartItem'
import {Category} from './Category'
import {Checkout} from './Checkout'
import {ConsumptionOptions, ConsumptionOptionType, ConsumptionOptionTypeHelper} from './ConsumptionOptions'
import {ExtraCost, ExtraCostType} from './ExtraCost'
import {IDShopConsumer} from './IDShopConsumer'
import {Language, TranslatedString, TranslatedStringDecoder, TranslatedStringPatch} from './Language'
import {PaymentMethod, PaymentMethodType} from './PaymentMethod'
import {PaymentProvider} from './PaymentProvider'
import {Product} from './Product'
import {ProductStack} from './ProductStack'
import {Shop} from './Shop'
import {
    ShopMessage,
    TranslatedShopMessage,
    TranslatedShopMessageToTranslatedShopMessage,
    TranslatedStringToTranslatedShopMessage,
} from './ShopMessage'
import {Table} from './Table'
import {TableCategory} from './TableCategory'
import {TransactionFees} from './TransactionFees'
import {UpsellGroup} from './Upsell'
import {UpsellGroupWithProducts, UpsellWithProduct} from './UpsellWithProduct'
import {VolumeUpsell, VolumeUpsellWithProduct} from './VolumeUpsell'
import {DiscountSource} from './discounts/DiscountSource'
import {Currency} from '@dorst/enums'

/**
 * ShopFull = Consumers FE shop structure
 */
export class ShopFull extends Shop {
    @field({decoder: new EnumDecoder(Currency), version: 52, upgrade: () => Currency.EUR})
    currency: Currency

    /**
     * null = don't ask for dine in; ask for take away / delivery (default)
     * true = always ask
     * false = never ask (also not for delivery/take away)
     */
    @field({decoder: BooleanDecoder, version: 52, upgrade: () => false})
    @field({decoder: BooleanDecoder, nullable: true, version: 64, upgrade: (old: boolean) => old ? true : null, downgrade: (old: boolean | null) => old === true})
    enableEmail: boolean | null = null

    @field({decoder: BooleanDecoder, optional: true})
    enableName = true

    /**
     * null = default value (alwasy ask for now because of covid)
     * true = always ask (to collect data)
     * false = never ask (also not for delivery/take away)
     */
    @field({decoder: BooleanDecoder, nullable: true, version: 64})
    @field({
        decoder: BooleanDecoder, nullable: true, version: 78, upgrade: (old) => {
            if (old === null) {
                return false
            }
            return old
        },
    })
    enablePhone = false

    @field({decoder: BooleanDecoder, version: 52, upgrade: () => true})
    enableSeparatedName = true

    @field({decoder: BooleanDecoder})
    enableRemarkField = false

    @field({decoder: BooleanDecoder, optional: true})
    enableHidePrices = false

    /**
     * When the order system is off, this property determines whether any order buttons such as
     * _Add to cart_ are shown to customers. Shops that only use Dorst for displaying their menu
     * would find their customers seeing a button stating _This shop is closed_ with this option set
     * to `false`.
     */
    @field({decoder: BooleanDecoder})
    hideOrderButtonsWhenOrderSystemIsOff = false

    @field({decoder: StringDecoder, version: 52, upgrade: () => 'Europe/Brussels'})
    timezone = 'Europe/Brussels'

    @field({decoder: new ArrayDecoder(Product)})
    products: Array<Product>

    /** Contains only the main categories */
    @field({decoder: new ArrayDecoder(Category)})
    categories: Array<Category>

    @field({decoder: new ArrayDecoder(Table)})
    tables: Array<Table>

    /** Contains only the main table categories */
    @field({decoder: new ArrayDecoder(TableCategory)})
    tableCategories: Array<TableCategory>

    @field({decoder: new ArrayDecoder(new EnumDecoder(PaymentMethodType)), version: 11, upgrade: () => []})
    @field({
        decoder: new ArrayDecoder(PaymentMethod),
        version: 41,
        upgrade: (old: Array<PaymentMethodType>) => {
            return old.map(type => PaymentMethod.create({type, transactionFees: TransactionFees.default(type, PaymentProvider.Paynl), provider: PaymentProvider.Paynl}))
        },
        downgrade: (n: Array<PaymentMethod>) => {
            return n.map(({type}) => type)
        },
    })
    paymentMethods: Array<PaymentMethod>

    @field({decoder: StringDecoder, nullable: true, version: 20, upgrade: () => null})
    @field({
        decoder: TranslatedStringDecoder,
        version: 71,
        nullable: true,
        upgrade: (str: string | null): TranslatedString | null => {
            return str ? new TranslatedString({
                [Language.Dutch]: str,
            }) : null
        },
        downgrade: (translatedStr: TranslatedString | null): string | null => {
            return translatedStr ? translatedStr.getForLanguage(Language.Dutch) : null
        },
        upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
            if (!str) {
                return new TranslatedStringPatch({})
            }
            return new TranslatedStringPatch({[Language.Dutch]: str})
        },
        downgradePatch: (translatedStr: PatchType<TranslatedString>): PatchType<string> => {
            if (translatedStr[Language.Dutch]) {
                return translatedStr[Language.Dutch] ?? ''
            }
            return undefined
        },
    })
    @field({
        decoder: TranslatedShopMessage,
        version: 91,
        nullable: true,
        optional: true,
        ...TranslatedStringToTranslatedShopMessage,
    })
    orderConfirmedMessage: TranslatedShopMessage | null = null

    @field({decoder: BooleanDecoder, version: 23, upgrade: () => false})
    enableTips = false

    @field({decoder: BooleanDecoder, version: 32, upgrade: () => false})
    enableVoiceNotifications = false

    @field({decoder: BooleanDecoder, version: 43, upgrade: () => false, optional: true}) // ORDER24 fix
    enableTableCategories = false

    @field({decoder: StringDecoder, nullable: true, optional: true})
    facebookPixelId: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    googleTagManagerId: string | null = null

    @field({decoder: Address, version: 40, upgrade: () => null, nullable: true})
    address: Address | null = null

    @field({decoder: ShopMessage, version: 47, nullable: true, upgrade: () => null})
    @field({
        decoder: TranslatedShopMessage,
        nullable: true,
        version: 71,
        upgrade: (shopMessage: ShopMessage | null): TranslatedShopMessage | null => {
            if (!shopMessage) {
                return null
            }
            return TranslatedShopMessage.create({
                title: new TranslatedString({
                    [Language.Dutch]: shopMessage.title,
                }),
                text: new TranslatedString({
                    [Language.Dutch]: shopMessage.text,
                }),
            })
        },
        downgrade: (translatedShopMessage: TranslatedShopMessage | null): ShopMessage | null => {
            if (!translatedShopMessage) {
                return null
            }
            return ShopMessage.create({
                title: translatedShopMessage.title.getForLanguage(Language.Dutch),
                text: translatedShopMessage.text.getForLanguage(Language.Dutch),
            })
        },
        upgradePatch: (shopMessage: PartialWithoutMethods<AutoEncoderPatchType<ShopMessage>>): AutoEncoderPatchType<TranslatedShopMessage> => {
            const patch = TranslatedShopMessage.patch({})
            if (shopMessage.title) {
                patch.title = new TranslatedStringPatch({[Language.Dutch]: shopMessage.title})
            }
            if (shopMessage.text) {
                patch.text = new TranslatedStringPatch({[Language.Dutch]: shopMessage.text})
            }
            return patch
        },
        downgradePatch: (translatedShopMessage: PartialWithoutMethods<AutoEncoderPatchType<TranslatedShopMessage>>): AutoEncoderPatchType<ShopMessage> | undefined => {
            return ShopMessage.patch({title: translatedShopMessage.title?.[Language.Dutch] ?? '', text: translatedShopMessage.text?.[Language.Dutch] ?? ''})
        },
    })
    @field({
        decoder: TranslatedShopMessage,
        nullable: true,
        optional: true,
        version: 91,
        ...TranslatedShopMessageToTranslatedShopMessage,
    })
    customerMessage: TranslatedShopMessage | null = null

    /** public shop description */
    @field({decoder: TranslatedShopMessage, version: 70, nullable: true, optional: true, upgrade: () => null})
    @field({
        decoder: TranslatedShopMessage,
        nullable: true,
        version: 91,
        ...TranslatedShopMessageToTranslatedShopMessage,
    })
    shopMessage: TranslatedShopMessage | null = null

    @field({decoder: StringDecoder, version: 66, nullable: true, optional: true})
    disclaimer: string | null = null

    @field({decoder: ConsumptionOptions, version: 52, upgrade: () => ConsumptionOptions.create({})})
    consumptionOptions: ConsumptionOptions

    @field({decoder: StringDecoder, nullable: true, version: 61, upgrade: () => ''})
    scoverId = ''

    @field({decoder: new ArrayDecoder(UpsellGroup), version: 73, upgrade: () => []})
    upsellGroups: Array<UpsellGroup>

    @field({decoder: new ArrayDecoder(VolumeUpsell), version: 87, upgrade: () => []})
    volumeUpsells: Array<VolumeUpsell>

    @field({decoder: new ArrayDecoder(DiscountSource), optional: true})
    discountSources: Array<DiscountSource> = []

    getUpsellGroupsForProduct(productId: string, consumptionMode: ConsumptionOptionType): Array<UpsellGroupWithProducts> {
        const productStack = this.getProductStack(productId, consumptionMode)
        if (productStack == null) {
            return []
        }
        const parentCategoriesStack: Array<Category> = productStack.categoryStack ?? []

        const upsellGroups = this.upsellGroups.filter(ug => {
            if (ug.consumptionMode !== consumptionMode) { // only for this consumptionmode
                return false
            }
            if (ug.productId === productId) { // upsellgroup is attached to the product
                return true
            }
            if (ug.categoryId === null) { // upsellgroup is not attached to a category
                return false
            }
            return parentCategoriesStack.some(el => { // upsellgroup-category is parent of product
                return el.id === ug.categoryId
            })
        })
        if (!upsellGroups) {
            return []
        }

        const upsellGroupsWithProducts: Array<UpsellGroupWithProducts> = []
        for (const usg of upsellGroups) {
            const upsells: Array<UpsellWithProduct> = []

            for (const us of usg.upsells) {
                const product = this.getProductById(us.upsellId)
                if (
                    product === undefined
                    || !product.enabled
                    || product.stock === 0
                    || (
                        product.consumptionMode === ConsumptionOptionType.DineIn
                        && !product.isAvailableOnDate(new Date(), this.timezone)
                    )
                ) {
                    continue
                }
                upsells.push(UpsellWithProduct.create({
                    ...us,
                    product: product,
                }))
            }

            upsellGroupsWithProducts.push(
                UpsellGroupWithProducts.create({
                    ...usg,
                    upsells: upsells,
                }),
            )
        }

        // sort so that category upsells are last
        return upsellGroupsWithProducts.filter(el => el.upsells.length > 0).sort((a, b) => {
            if (a.categoryId === b.categoryId) {
                return 0
            } else if (a.categoryId === null) {
                return -1
            } else {
                return 1
            }
        })
    }

    getProductById(id: string): Product | undefined {
        return this.products.find(el => {
            return el.id === id
        })
    }

    getApplicableExtraCosts(checkout: Checkout): Array<ExtraCost> {
        return this.consumptionOptions[ConsumptionOptionTypeHelper.getSlug(checkout.consumptionMode)].extraCosts.filter(el => {
            return el.value > 0 && ((!el.min || checkout.cart.getPrice() >= el.min) && (!el.max || checkout.cart.getPrice() <= el.max))
        }).sort((a: ExtraCost, b: ExtraCost) => (a.extraCostType === ExtraCostType.Inclusive ? 1 : 0) > (b.extraCostType === ExtraCostType.Inclusive ? 1 : 0) ? 1 : -1) ?? []
    }

    static fromID(shop: IDShopConsumer): ShopFull {
        const shopFull = ShopFull.create({
            address: shop.address,
            categories: shop.categories.filter(c => shop.mainCategoryIds.includes(c.id)).flatMap(c => {
                const cat = Category.fromID(c, shop)
                if (cat.products.length > 0 || cat.categories.length > 0) {
                    return [cat]
                }
                return []
            }),
            consumptionOptions: shop.consumptionOptions,
            currency: shop.currency,
            customerMessage: shop.customerMessage,
            disclaimer: shop.disclaimer,
            discountSources: shop.discountSources,
            domain: shop.domain,
            enableEmail: shop.enableEmail,
            enableName: shop.enableName,
            enableOrdering: shop.enableOrdering,
            enablePhone: shop.enablePhone,
            enableRemarkField: shop.enableRemarkField,
            enableHidePrices: shop.enableHidePrices,
            enableSeparatedName: shop.enableSeparatedName,
            enableTableCategories: shop.enableTableCategories,
            enableTips: shop.enableTips,
            enableVoiceNotifications: shop.enableVoiceNotifications,
            externalLinks: shop.externalLinks,
            facebookPixelId: shop.facebookPixelId,
            googleTagManagerId: shop.googleTagManagerId,
            hideOrderButtonsWhenOrderSystemIsOff: shop.hideOrderButtonsWhenOrderSystemIsOff,
            id: shop.id,
            language: shop.language,
            name: shop.name,
            orderConfirmedMessage: shop.orderConfirmedMessage,
            paymentMethods: shop.paymentMethods,
            personalisation: shop.personalisation,
            products: shop.products,
            scoverId: shop.scoverId,
            shopMessage: shop.shopMessage,
            tableCategories: shop.tableCategories.filter(c => shop.mainTableCategoryIds.includes(c.id)).flatMap(c => {
                const cat = TableCategory.fromID(c, shop)
                if (cat.tables.length > 0 || cat.tableCategories.length > 0) {
                    return [cat]
                }
                return []
            }),
            tables: shop.tables,
            timezone: shop.timezone,
            type: shop.type,
            upsellGroups: shop.upsellGroups,
            uri: shop.uri,
            volumeUpsells: shop.volumeUpsells,
        })

        if (shopFull.categories.length == 1 && shopFull.categories[0].products.length == 0) {
            // Flatten
            shopFull.categories = shopFull.categories[0].categories
        }

        return shopFull
    }

    getProductStack(productId: string, consumptionMode: ConsumptionOptionType): ProductStack | null {
        const targetProduct = this.products.find(el => el.id === productId && el.consumptionMode === consumptionMode)
        if (!targetProduct) {
            return null
        }
        const prodCategories = this.getProductCategories(this.categories, productId)
        const catStack = prodCategories.found ? prodCategories.categories : []
        const {cartItem, subItems} = CartItem.defaultForProduct(targetProduct, this.getLinkedProducts(targetProduct))
        return ProductStack.create({
            cartItem,
            subItems,
            categoryStack: catStack,
            squashRootCategories: this.categories.length <= 3,
        })
    }

    getProductCategories(categories: Array<Category>, productId: string, catStack: Array<Category> = []): {found: boolean; categories: Array<Category>} {
        let found = false
        const ret = [
            ...catStack,
            ...categories.reduce((prev, next) => {
                if (found) {
                    return prev
                }
                prev.push(next)
                if (next.categories.length) {
                    const catResult = this.getProductCategories(next.categories, productId, prev)
                    if (catResult.found) {
                        found = true
                        prev = catResult.categories
                    }
                } else if (next.products.find(el => el.id === productId)) {
                    found = true
                }
                if (!found) {
                    return []
                }
                return prev
            }, [] as Array<Category>),
        ]

        return {
            found: found,
            categories: ret,
        }
    }

    /**
     * Returns the products that are linked to optiongroups within the product passed (menu-products)
     */
    getLinkedProducts(product: Product): Array<Product> {
        const linkedProducts: Array<Product> = []
        for (const variant of product.variants) {
            for (const optionGroup of variant.optionGroups) {
                for (const option of optionGroup.options) {
                    if (option.productLink !== undefined && option.productLink.productId !== '') {
                        const linkedProduct = this.getProductById(option.productLink.productId)
                        if (linkedProduct === undefined) {
                            console.error('optiongroup product not found (this should never happen)')
                            continue
                        }
                        linkedProducts.push(Product.formatProductForOption(linkedProduct, option))
                    }
                }
            }
        }
        return linkedProducts
    }

    getVolumeUpsellsWithConsumerProducts(productId: string, variantId?: string): Array<VolumeUpsellWithProduct> {
        return this.volumeUpsells.reduce<Array<VolumeUpsellWithProduct>>((acc, curr) => {
            if (curr.productAid === productId && (variantId === undefined || curr.variantAid === variantId)) {
                const product = this.getProductById(curr.productBid)
                if (
                    product !== undefined
                    && product.variants.some(el => el.id === curr.variantBid)
                    && this.canOrderProduct(product)
                ) {
                    acc.push(
                        VolumeUpsellWithProduct.create({
                            ...curr,
                            product,
                        }),
                    )
                }
            }
            return acc
        }, [])
    }

    canOrderProduct(product: Product) {
        if (!this.enableOrdering) {
            return false
        }

        if (product.consumptionMode !== ConsumptionOptionType.DineIn) {
            return true
        }

        if (!product.isAvailableOnDate(new Date(), this.timezone)) {
            return false
        }

        if (this.consumptionOptions.dineIn.enableDineInOpeningHours) {
            return !this.consumptionOptions.dineIn.isClosedOn(new Date(), this.timezone)
        }

        return true
    }
}
