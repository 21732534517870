import {
    ArrayDecoder,
    AutoEncoder,
    BooleanDecoder,
    EnumDecoder,
    field,
    IntegerDecoder,
    NumberDecoder,
    PatchType,
    StringDecoder,
} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {Language, TranslatedString, TranslatedStringDecoder, TranslatedStringPatch} from './Language'
import {Option} from './Option'
import {OptionGroupMeta} from './ProductMeta'

export enum OptionGroupMode {
    Single = 'Single',
    Multiple = 'Multiple',
    MultipleWithAmounts = 'MultipleWithAmounts',
}

export enum OptionAmountType {
    Min = 'min',
    Max = 'max',
    Default = 'default',
}

export class OptionGroupGroup extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: new ArrayDecoder(StringDecoder)})
    optionGroupIds: Array<string> = []
}

export class OptionGroup extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string
    /**
     * Name of this price. You can leave this empty if you only have one price
     */

    @field({decoder: StringDecoder})
    @field({
        decoder: TranslatedStringDecoder,
        version: 69,
        upgrade: (str: string): TranslatedString => {
            return new TranslatedString({
                [Language.Dutch]: str,
            })
        },
        downgrade: (name: TranslatedString): string => {
            return name.getForLanguage(Language.Dutch)
        },
        upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
            if (!str) {
                return new TranslatedStringPatch({})
            }
            return new TranslatedStringPatch({[Language.Dutch]: str})
        },
        downgradePatch: (name: PatchType<TranslatedString>): PatchType<string> => {
            if (name[Language.Dutch]) {
                return name[Language.Dutch] ?? ''
            }
            return undefined
        },
    })
    name: TranslatedString = new TranslatedString({})

    @field({decoder: BooleanDecoder, field: 'multipleChoice'})
    @field({
        decoder: new EnumDecoder(OptionGroupMode),
        version: 72,
        upgrade: function (oldVal: boolean) {
            if (oldVal) {
                return OptionGroupMode.Multiple
            }
            return OptionGroupMode.Single
        },
        downgrade: function (newVal: OptionGroupMode) {
            return newVal !== OptionGroupMode.Single
        },
    })
    mode: OptionGroupMode = OptionGroupMode.Single

    @field({decoder: IntegerDecoder, nullable: true, version: 72, upgrade: () => null})
    minAmount: number | null = null

    @field({decoder: IntegerDecoder, nullable: true, version: 72, upgrade: () => null})
    maxAmount: number | null = null

    /**
     * Amount of free options (on top of default options)
     * do not charge for x amount of this option (but also do not subtract if free included > amount)
     * */
    @field({decoder: NumberDecoder, optional: true})
    freeOptionsAmount: number = 0

    /** If set preselect that option otherwise use first option. */
    @field({decoder: StringDecoder, nullable: true, version: 82, upgrade: () => null})
    defaultSelectedId: string | null = null

    /**
     * Returns the amount of default options in this group. (not including free options === amountIncludedInProductPrice)
     */
    get defaultOptionsAmount() {
        return this.options.reduce((acc, curr) => {
            acc += curr.defaultAmount ?? 0
            return acc
        }, 0)
    }

    get defaultOption(): Option | null {
        if (this.mode != OptionGroupMode.Single) {
            return null
        }
        if (this.defaultSelectedId) {
            const defaultSelected = this.options.find(el => {
                return el.id === this.defaultSelectedId
            })
            if (defaultSelected) {
                return defaultSelected
            }
        }
        return this.options[0] ?? null
    }

    @field({decoder: IntegerDecoder, nullable: true, version: 18, upgrade: () => null})
    VAT: number | null = null

    @field({decoder: new ArrayDecoder(Option)})
    options: Array<Option> = [Option.create({})]

    /** Used for Top Brands = extra products as option so we need to send extra ids in order */
    @field({decoder: OptionGroupMeta, optional: true})
    meta?: OptionGroupMeta

    get isValid(): boolean {
        return (
                this.mode !== OptionGroupMode.Single
                && (this.minAmount === null || this.minAmount === 0)
            ) || this.options.filter(option => option.enabled).length > 0
    }
}

export const OptionGroupPatch = OptionGroup.patchType()
