import {
    ArrayDecoder,
    AutoEncoder,
    DateDecoder,
    EnumDecoder,
    field,
    IntegerDecoder,
    StringDecoder,
} from '@simonbackx/simple-encoding'
import {DateTime} from 'luxon'
import {v4 as uuidv4} from 'uuid'

import {I18n} from './I18n'
import {WeekDay, WeekdayHelper} from './WeekDay'
import {Timeslot} from '@dorst/structures/src/Timeslot'
import {Formatter} from '@dorst/validation'

export enum AvailabilityPeriodType {
    WeekDays = 'weekDays',
    DateRange = 'dateRange',
}

export class AvailabilityPeriodTypeHelper {
    static getAvailabilityTypeText(type: AvailabilityPeriodType, i18n: I18n): string {
        switch (type) {
            case AvailabilityPeriodType.WeekDays:
                return i18n.t('common.availability.weekDays').toString()
            case AvailabilityPeriodType.DateRange:
                return i18n.t('common.availability.dateRange').toString()
        }
    }

    static getAvailabilityPeriodTypes() {
        return Object.values(AvailabilityPeriodType)
    }
}

export type DateRangeDateType = 'startDate' | 'endDate'

export class DateRange extends AutoEncoder {
    @field({decoder: DateDecoder, optional: true})
    startDate?: Date

    @field({decoder: DateDecoder, optional: true})
    endDate?: Date

    isValid() {
        return this.startDate !== undefined && this.endDate !== undefined
            && this.startDate.getTime() <= this.endDate.getTime()
    }

    isDateValid(type: DateRangeDateType) {
        if (type === 'startDate') {
            return this.startDate !== undefined
                && this.endDate === undefined
                || this.isValid()
        } else if (type === 'endDate') {
            return this.endDate !== undefined
                && this.startDate === undefined
                || this.isValid()
        }
    }
}

export class ProductAvailabilityPeriod extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({
        decoder: new EnumDecoder(AvailabilityPeriodType),
        version: 84,
        upgrade: () => {
            return AvailabilityPeriodType.WeekDays
        },
    })
    type: AvailabilityPeriodType = AvailabilityPeriodType.WeekDays

    @field({decoder: new ArrayDecoder(new EnumDecoder(WeekDay))})
    days: Array<WeekDay> = WeekdayHelper.fullWeek

    @field({decoder: DateRange, optional: true})
    dateRange?: DateRange

    /**
     * Starttime in minutes since start of day (60 = 1:00, 12:23 = 743)
     * Only used for Type Weekdays ( dateRange has the time set in start- & enddate )
     */
    @field({decoder: IntegerDecoder})
    startTime = 0

    /**
     * Endtime in minutes (exclusive) since start of day (60 = 1:00, 12:23 = 743)
     * Only used for Type Weekdays ( dateRange has the time set in start- & enddate )
     */
    @field({decoder: IntegerDecoder})
    endTime: number = 60 * 24 // (= 0:00)

    isValid(): boolean {
        if (this.type === AvailabilityPeriodType.WeekDays) {
            const alwaysAvailable = this.days.length === 7 && this.startTime === 0 && this.endTime === 24 * 60
            return this.days.length > 0
                && (this.startTime >= 0 && this.startTime <= 24 * 60)
                && (this.endTime >= 0 && this.endTime <= 24 * 60)
                && !alwaysAvailable
        } else if (this.type === AvailabilityPeriodType.DateRange) {
            return this.dateRange !== undefined && this.dateRange.isValid()
        }
        return false
    }

    get doesContinuePastMidnight() {
        return this.endTime < this.startTime && this.endTime > 0
    }

    /**
     * Used to see if the product is available at the moment (or later when matchWhenAvailableLaterToday is true)
     * @param date - Date and time we want to check availability for
     * @param timezone - Shop timezone to use
     * @param matchWhenAvailableLaterToday - When passing true it will also match if it is unavailable right now but
     *  will become available later today (e.g. it's 12am and from 4pm onwards the product is available)
     * @return {boolean}
     */
    doesMatchDate(date: Date, timezone: string, matchWhenAvailableLaterToday = false) {
        if (this.type === AvailabilityPeriodType.WeekDays) {
            const dateTime = DateTime.fromJSDate(date).setZone(timezone)
            let weekday = dateTime.weekday
            const time = dateTime.hour * 60 + dateTime.minute

            /* If this slot continues past midnight e.g. 20.00-04.00
               And the current time is lower then the endtime, this means it's the next day (e.g. 02.00)
               In which case we need to set the (js)day to the previous one so we can match with the right period. */
            if (this.doesContinuePastMidnight && time < this.endTime) {
                weekday = WeekdayHelper.getPrevious(weekday)
            }

            if (!this.days.includes(weekday)) {
                return false
            }

            if (time < this.startTime) {
                return matchWhenAvailableLaterToday
            }

            if (!this.doesContinuePastMidnight && time >= this.endTime) {
                return false
            }

            return true
        } else {
            return this.hasValidDateRangeForDate(date)
        }
    }

    /**
     *
     * Used to see if the product is available at the moment (or later when matchWhenAvailableLaterToday is true)
     * @param timeslot - Timeslot with start and end in minutes
     * @param date - Date on which this timeslot is set
     * @param timezone - Shop timezone to use
     * @return {boolean}
     */
    doesMatchTimeSlot(timeslot: Timeslot, date: Date, timezone: string): boolean {
        if (this.type === AvailabilityPeriodType.WeekDays) {
            // We need to construct a Date for the start and end of this period in order to compare it with the timeslot for overlap.
            const dateTime = DateTime.fromJSDate(date, {zone: timezone})
            let weekday = dateTime.weekday
            const time = dateTime.hour * 60 + dateTime.minute
            const {hours: startHours, minutes: startMinutes} = Formatter.minutesToHoursAndMinutes(this.startTime)
            const {hours: endHours, minutes: endMinutes} = Formatter.minutesToHoursAndMinutes(this.endTime)
            const startDate = dateTime.set({hour: startHours}).set({minute: startMinutes})
            const endDate = dateTime.set({hour: endHours}).set({minute: endMinutes})

            if (this.doesContinuePastMidnight) {
                // If this period can continue past midnight we have to determine which date we are on right now.
                // Endtime will be something like 02:00 so if our current time is < 2:00 we are already the next day.
                if (time < this.endTime) {
                    startDate.minus({hour: 24})
                    weekday = WeekdayHelper.getPrevious(weekday)
                } else {
                    endDate.plus({hour: 24})
                }
            }

            return this.days.includes(weekday)
                && this.hasValidRangeForTimeslot(timeslot, date, timezone, startDate.toJSDate(), endDate.toJSDate())
        } else {
            return this.dateRange !== undefined
                && this.dateRange.startDate !== undefined
                && this.dateRange.endDate !== undefined
                && this.hasValidRangeForTimeslot(timeslot, date, timezone, this.dateRange.startDate, this.dateRange.endDate)
        }
    }

    hasValidDateRangeForDate(date: Date) {
        return this.dateRange !== undefined
            && this.dateRange.startDate !== undefined
            && this.dateRange.endDate !== undefined
            && this.dateRange.startDate <= date
            && this.dateRange.endDate > date
    }

    /**
     * Check whether the passed timeslot and products availability period have overlap = valid
     * @param timeslot - Timeslot for which we want to check the compatibility with
     * @param date - Date the timeslot is configured for.
     * @param timezone - Timezone of the shop
     * @param periodStartDate - Start of the period we will check
     * @param periodEndDate - End of the period we will check
     * @returns {boolean} - Returns whether the timeslot is valid for the passed period start&end.
     */
    hasValidRangeForTimeslot(timeslot: Timeslot, date: Date, timezone: string, periodStartDate: Date, periodEndDate: Date) {
        const hoursAndMinutesStart = Formatter.minutesToHoursAndMinutes(timeslot.start)
        const timeslotStartDate = DateTime.fromJSDate(date, {zone: timezone}).set({hour: hoursAndMinutesStart.hours}).set({minute: hoursAndMinutesStart.minutes}).toJSDate()
        const hoursAndMinutesEnd = Formatter.minutesToHoursAndMinutes(timeslot.end)
        const timeslotEndDate = DateTime.fromJSDate(date, {zone: timezone}).set({hour: hoursAndMinutesEnd.hours}).set({minute: hoursAndMinutesEnd.minutes}).toJSDate()
        return periodStartDate < periodEndDate
            && periodStartDate < timeslotEndDate
            && periodEndDate > timeslotStartDate
    }
}

export const ProductAvailabilityPeriodPatch = ProductAvailabilityPeriod.patchType()
