var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DRSTBox",
        {
          class: { "has-errors": _vm.hasAmountError },
          attrs: {
            "only-annotation": _vm.onlyAnnotation,
            title: _vm.optionGroupName,
            "data-testid": "option-group-box"
          }
        },
        [
          _vm.optionGroup.maxAmount || _vm.optionGroup.minAmount
            ? _c("div", { attrs: { slot: "annotation" }, slot: "annotation" }, [
                _vm.showMinMaxLabels
                  ? _c("div", { staticClass: "option-group-amount-error" }, [
                      _vm.optionGroup.maxAmount && _vm.optionGroup.minAmount
                        ? _c("div", { staticClass: "option-amount-line" }, [
                            _vm.optionGroup.maxAmount ===
                            _vm.optionGroup.minAmount
                              ? _c("p", [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "consumer.product.optionGroupExactAmount",
                                          { amount: _vm.optionGroup.minAmount }
                                        )
                                      ) +
                                      "\n                        "
                                  ),
                                  _vm.hasAmountError
                                    ? _c("span", { staticClass: "icon error" })
                                    : _vm._e()
                                ])
                              : _c("p", [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.$t(
                                          "consumer.product.optionGroupMinAndMaxAmount",
                                          {
                                            minAmount:
                                              _vm.optionGroup.minAmount,
                                            maxAmount: _vm.optionGroup.maxAmount
                                          }
                                        )
                                      )
                                  ),
                                  _vm.hasAmountError
                                    ? _c("span", { staticClass: "icon error" })
                                    : _vm._e()
                                ])
                          ])
                        : _vm.optionGroup.minAmount
                        ? _c("p", { staticClass: "option-amount-line" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$t(
                                    "consumer.product.optionGroupMinimumAmount",
                                    { minAmount: _vm.optionGroup.minAmount }
                                  )
                                )
                            ),
                            _vm.hasAmountError
                              ? _c("span", { staticClass: "icon error" })
                              : _vm._e()
                          ])
                        : _vm.optionGroup.maxAmount
                        ? _c("p", { staticClass: "option-amount-line" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$t(
                                    "consumer.product.optionGroupMaximumAmount",
                                    { maxAmount: _vm.optionGroup.maxAmount }
                                  )
                                )
                            ),
                            _vm.hasAmountError
                              ? _c("span", { staticClass: "icon error" })
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.optionGroup.mode !== _vm.OptionGroupMode.Single &&
                _vm.optionGroup.freeOptionsAmount > 0
                  ? _c("p", [
                      _vm._v(
                        "\n                " +
                          _vm._s(
                            _vm.$tc(
                              "customer.products.freeAmountsOfChoice",
                              _vm.optionGroup.freeOptionsAmount
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showMinMaxLabels
            ? _c("template", { slot: "right" }, [
                _vm.hasAmountError
                  ? _c("div", { staticClass: "icon error" })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "option-group-amount-ratio option-amount-line"
                  },
                  [
                    _c(
                      "span",
                      {
                        class: {
                          "option-group-amount-error": _vm.hasAmountError
                        }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.currentTotalOptionAmount) +
                            "\n                "
                        )
                      ]
                    ),
                    _vm._v(
                      " / " +
                        _vm._s(
                          _vm.currentTotalOptionAmount <
                            _vm.optionGroup.minAmount
                            ? _vm.optionGroup.minAmount
                            : _vm.optionGroup.maxAmount
                        ) +
                        "\n            "
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.optionGroup.options, function(option) {
            return _c(
              "DRSTBoxItem",
              {
                directives: [
                  {
                    name: "longpress",
                    rawName: "v-longpress",
                    value: function(event) {
                      return _vm.configureSubItem(event, option)
                    },
                    expression: "(event) => configureSubItem(event, option)"
                  }
                ],
                key: option.id,
                staticClass: "option-group-row",
                class: {
                  "has-option-amounts": _vm.isMultipleWithAmounts
                },
                attrs: {
                  "element-name": "div",
                  selectable:
                    !_vm.isMultipleWithAmounts && !_vm.maxHasBeenReached,
                  "data-testid": "option-group-row"
                },
                on: {
                  click: function($event) {
                    return _vm.onSelectOption(option)
                  }
                }
              },
              [
                _c("div", { staticClass: "option-group-row-left" }, [
                  _vm.hasAnOptionWithImage
                    ? _c("span", {
                        staticClass: "option-img",
                        style: {
                          backgroundImage: _vm.getOptionThumbnail(option)
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("span", { staticClass: "option-name" }, [
                    _vm._v(_vm._s(_vm.getOptionName(option)) + " ")
                  ]),
                  _vm._v(" "),
                  _vm.getDisplayOptionLabels(option)
                    ? _c("span", { staticClass: "option-label-wrapper" }, [
                        _vm.getDisplayIncludedLabel(option)
                          ? _c("span", { staticClass: "option-label" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$t(
                                      "consumer.product.optionsAmountIncludedInPrice",
                                      {
                                        amount:
                                          option.amountIncludedInProductPrice
                                      }
                                    )
                                  ) +
                                  "\n                    "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.getDisplayAdditionalOptionsLabel(option)
                          ? _c("span", { staticClass: "option-label" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.$t(
                                      "consumer.product.additionalOptionsAvailable"
                                    )
                                  ) +
                                  "\n                    "
                              )
                            ])
                          : _vm._e()
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "option-group-row-right",
                    attrs: { slot: "right" },
                    slot: "right"
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "option-group-row-price" },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.getOptionPriceDescription(
                                option,
                                _vm.optionGroup
                              )
                            ) +
                            "\n                    "
                        ),
                        option.amountIncludedInProductPrice > 0
                          ? [_vm._v("*")]
                          : _vm._e()
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.editable && _vm.isSingleSelection
                      ? _c("Radio", {
                          ref: "option-input-" + option.id,
                          refInFor: true,
                          attrs: {
                            "disable-input-on-click": true,
                            "model-value": _vm.selectedOption,
                            value: option,
                            name: "optiongroup_" + _vm.optionGroup.id
                          }
                        })
                      : _vm.editable && _vm.isMultiple
                      ? _c("Checkbox", {
                          ref: "option-input-" + option.id,
                          refInFor: true,
                          attrs: {
                            checked: _vm.isOptionActive(option),
                            name: "option_" + option.id,
                            disabled: true
                          }
                        })
                      : _vm.editable && _vm.isMultipleWithAmounts
                      ? _c("Stepper", {
                          ref: "option-input-" + option.id,
                          refInFor: true,
                          staticClass: "option-group-stepper",
                          attrs: {
                            value: _vm.amountMap[option.id],
                            minimum: _vm.getOptionMinimum(option),
                            maximum: _vm.getOptionMaximum(option),
                            "has-visible-value": true,
                            "disable-on-max-or-min": true
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            },
                            plus: function($event) {
                              return _vm.onMultipleWithAmountsOptionSelect(
                                option
                              )
                            },
                            minus: function($event) {
                              return _vm.onMultipleWithAmountsOptionSelect(
                                option,
                                "subtract"
                              )
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]
            )
          }),
          _vm._v(" "),
          _vm.hasDefaultOptions
            ? _c("RemarkBlock", [
                _c("p", [
                  _vm._v(
                    "* " +
                      _vm._s(
                        _vm.$t("customer.products.includedInPriceDisclaimer")
                      )
                  )
                ])
              ])
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }