var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view" },
    [
      _c("DRSTNavigationBar", [
        _vm.canPop
          ? _c("button", {
              staticClass: "button icon back",
              attrs: { slot: "left" },
              on: { click: _vm.pop },
              slot: "left"
            })
          : _vm.hasRedirect
          ? _c("button", {
              staticClass: "button icon back",
              attrs: { slot: "left" },
              on: { click: _vm.redirectBack },
              slot: "left"
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("main", { staticClass: "message" }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("pageNotFound.title")))]),
        _vm._v(" "),
        _c("p", [_vm._v(_vm._s(_vm.$t("pageNotFound.message")))])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }