import {
    Address,
    AddressType,
    CompanyLegalEntity,
    Country,
    PartnerCode,
    SalesPerson,
    Subscription,
} from '@dorst/structures'

export interface FormRow {
    fields: Array<DorstFormField>
    title?: string
    withBg: boolean
}

export interface DorstFormData {
    submitForm: () => Promise<any>
    submitFormButtonI18nDefinition?: string
    registerFieldsMatrix: Array<Array<RegisterFieldBundle>>
    formType: FormType
    currentStep: number
    getStepTitleI18nDefinition: (step: number) => string
    getStepIntroI18nDefinition: (step: number) => string

    // if using DorstFormFieldType.ADDRESS or MAINADDRESS
    country?: Country
    partner?: PartnerCode | null
    getAddressMatrix?: (fieldData: DorstFormField) => AddressMatrix
    getAddressMatrixForCountry?: (country: string | Country) => AddressMatrix

    shouldNavigateAway: (confirmationMessage: string) => boolean

}

export enum FormType {
    ONBOARDING = 'Onboarding',
    EDITCOMPANYINFO = 'EditCompanyInfo',
    EDITUSERINFO = 'EditUserInfo',
}

export enum DorstFormFieldType {
    TEXTFIELD = 'Text',
    LEGALENTITYSELECT = 'LegalEntitySelect',
    ADDRESS = 'Address',
    MAINADDRESS = 'MainAddress', // mainaddress is same as address but updates all allowed inputs to the country selected
    POLICYCHECKBOX = 'PolicyCheckbox',
    PHONE = 'Phone',
    PASSWORD = 'Password',
    MAINCOUNTRYSELECT = 'MainCountry',
    SUBSCRIPTIONSELECT = 'SubscriptionSelect',
}

export enum FieldValidationType {
    NOTEMPTY = 'NotEmpty',
    MINCHARS = 'MinChars',
    MINCHARSIFOTHERFIELD = 'MinCharsIfOtherField', // if another field is filled in this field also needs x amount of chars
    SAMEAS = 'SameAs',
    PHONE = 'Phone',
    EMAIL = 'Email',
    COUNTRY = 'Country',
    ADDRESS = 'Address',
    PASSWORD = 'Password',
    SUBSCRIPTION = 'Subscription',
    UNIQUEURI = 'Uniqueuri',
    VALIDLEGALENTITY = 'ValidLegalEntity',
}

export enum AddressField {
    NUMBER = 'number',
    STREET = 'street',
    CITY = 'city',
    POSTALCODE = 'postalCode',
    COUNTRY = 'country',
    ADDRESS1 = 'address1',
    ADDRESS2 = 'address2',
}

interface i18n {
    t: (key: string, options?: any) => string
}

export class FieldValidation {
    constructor(struct: {type: FieldValidationType; errorMessageI18nDefinition: string; linkedFieldLabel?: string; amount?: number; orNull?: boolean; includePasswordPolicy?: boolean}) {
        this.type = struct.type
        this.errorMessageI18nDefinition = struct.errorMessageI18nDefinition
        this.linkedFieldLabel = struct.linkedFieldLabel
        this.amount = struct.amount
        if (typeof struct.orNull != 'undefined') {
            this.orNull = struct.orNull
        }
        if (typeof struct.includePasswordPolicy != 'undefined') {
            this.includePasswordPolicy = struct.includePasswordPolicy
        }
    }

    type: FieldValidationType
    errorMessageI18nDefinition: string
    /** does value null validate for this rule? */
    orNull = false
    /** used for SAMEAS / UNIQUEURI */
    linkedFieldLabel?: string
    /** used for MINCHARS */
    amount?: number
    /** used for password policy */
    includePasswordPolicy = false
}

export class AddressMatrixEntry {
    constructor(struct: {addressField: AddressField; addressFieldPlaceholderI18nDefinition: string; fieldValidation: Array<FieldValidation>}) {
        this.addressField = struct.addressField
        this.addressFieldPlaceholderI18nDefinition = struct.addressFieldPlaceholderI18nDefinition
        this.fieldValidation = struct.fieldValidation
    }
    addressField: AddressField
    addressFieldPlaceholderI18nDefinition: string
    fieldValidation: Array<FieldValidation>
}
export class AddressMatrix {
    constructor(matrix: Array<AddressMatrixEntry>) {
        this.matrix = matrix
    }
    matrix: Array<AddressMatrixEntry>
}

export interface DorstFormFieldDataInput {
    addressMatrix?: AddressMatrix
    allowedCountries?: Array<Country>
    label: string
    labelI18nDefinition?: string
    linkI18nDefinition?: string
    placeholderI18nDefinition?: string
    type: DorstFormFieldType
    validateOnInput?: {showIfEmpty: boolean; errorOnly: boolean; valid: boolean | null} | null
    validationRules: Array<FieldValidation>
}

export class DorstFormFieldData {
    constructor(struct: DorstFormFieldDataInput) {
        this.label = struct.label
        this.type = struct.type
        this.validationRules = struct.validationRules
        if (struct.allowedCountries) {
            this.allowedCountries = struct.allowedCountries
        }
        if (struct.labelI18nDefinition) {
            this.labelI18nDefinition = struct.labelI18nDefinition
        }
        if (struct.placeholderI18nDefinition) {
            this.placeholderI18nDefinition = struct.placeholderI18nDefinition
        }
        if (struct.addressMatrix) {
            this.addressMatrix = struct.addressMatrix
        }
        if (struct.linkI18nDefinition) {
            this.linkI18nDefinition = struct.linkI18nDefinition
        }
        if (struct.validateOnInput) {
            this.validateOnInput = struct.validateOnInput
        }
    }

    label: string

    type: DorstFormFieldType

    /** null == no validation ||| {valid: null} == unknown */
    validateOnInput: {showIfEmpty: boolean; errorOnly: boolean; valid: boolean | null} | null = null

    validationRules: Array<FieldValidation>

    /** undefined == all countries allowed... */
    allowedCountries?: Array<Country>

    labelI18nDefinition?: string
    placeholderI18nDefinition?: string

    /** Used for DorstFormFieldType.ADDRESS */
    addressMatrix?: AddressMatrix

    /** Used for checkboxes */
    linkI18nDefinition?: string
}

export interface DorstFormFieldInput {
    addressMatrix?: AddressMatrix
    admin?: boolean
    isAllowed?: boolean
    allowedCountries?: Array<Country>
    duplicationValue?: {get: () => any}
    label: string
    labelI18nDefinition?: string
    linkI18nDefinition?: string
    model: {get: () => any; set: (val: any) => void}
    placeholderI18nDefinition?: string
    type: DorstFormFieldType
    validateOnInput?: {showIfEmpty: boolean; errorOnly: boolean; valid: boolean | null} | null
    validationRules: Array<FieldValidation>
}

/** adds model logic to the DorstFormFieldData */
export class DorstFormField extends DorstFormFieldData {
    constructor(struct: DorstFormFieldInput) {
        super(struct)
        this.model = struct.model
        if (struct.duplicationValue) {
            this.duplicationValue = struct.duplicationValue
        }
        if (typeof struct.admin != 'undefined') {
            this.admin = struct.admin
        }
        if (struct.isAllowed !== undefined) {
            this.isAllowed = struct.isAllowed
        }
    }
    /** if only admin should see the field */
    admin = false

    isAllowed: boolean = true

    /** If filled in we will show a button to copy the value from the referenced field */
    duplicationValue: {get: () => any}
    model: {
        get: () => any
        set: (val: any) => void
    }

    shouldShowField(country: Country | null, isAdmin = false) {
        if (!isAdmin && this.admin) {
            return false
        }
        if (!country) {
            return true
        }
        return this.type === DorstFormFieldType.MAINCOUNTRYSELECT || typeof this.allowedCountries === 'undefined' || this.allowedCountries.includes(country)
    }
}

export class RegisterFieldBundle {
    constructor(struct: {titleI18nDefinition?: string; disclaimerI18nDefinition?: string; fields: Array<DorstFormField>; withBg?: boolean}) {
        this.fields = struct.fields.filter(field => field.isAllowed)
        if (struct.titleI18nDefinition) {
            this.titleI18nDefinition = struct.titleI18nDefinition
        }
        if (struct.disclaimerI18nDefinition) {
            this.disclaimerI18nDefinition = struct.disclaimerI18nDefinition
        }
        this.withBg = struct.withBg === undefined ? true : struct.withBg
    }

    titleI18nDefinition?: string
    disclaimerI18nDefinition?: string

    fields: Array<DorstFormField>

    withBg: boolean
}

export class AllowedLegalEntityOption {
    constructor(struct: {companyLegalEntity: CompanyLegalEntity; allowedCountries: Array<Country>}) {
        this.companyLegalEntity = struct.companyLegalEntity
        this.allowedCountries = struct.allowedCountries
    }
    companyLegalEntity: CompanyLegalEntity
    allowedCountries: Array<Country>
}

export class InputFieldHelper {
    static getAddressFieldPlaceholder(field: AddressField, i18n: i18n): string {
        switch (field) {
            case AddressField.NUMBER:
                return i18n.t('general.address.number')
            case AddressField.STREET:
                return i18n.t('general.address.street')
            case AddressField.CITY:
                return i18n.t('general.address.city')
            case AddressField.POSTALCODE:
                return i18n.t('general.address.postalCode')
            case AddressField.COUNTRY:
                return i18n.t('general.address.chooseCountry')
            case AddressField.ADDRESS1:
                return i18n.t('general.address.address1')
            case AddressField.ADDRESS2:
                return i18n.t('general.address.address2')
            default:
                throw new Error(`Unknown address field: ${field}`)
        }
    }

    static defaultMatrix = new AddressMatrix(
        [
            new AddressMatrixEntry({
                addressField: AddressField.STREET,
                fieldValidation: [
                    new FieldValidation({
                        type: FieldValidationType.MINCHARS,
                        amount: 1,
                        errorMessageI18nDefinition: 'backoffice.onboarding.errors.companyAddressStreetEmpty',
                    }),
                ],
                addressFieldPlaceholderI18nDefinition: 'general.address.street',
            }),
            new AddressMatrixEntry({
                addressField: AddressField.NUMBER,
                fieldValidation: [
                    new FieldValidation({
                        type: FieldValidationType.MINCHARS,
                        amount: 1,
                        errorMessageI18nDefinition: 'backoffice.onboarding.errors.companyAddressNumberEmpty',
                    }),
                ],
                addressFieldPlaceholderI18nDefinition: 'general.address.number',
            }),
            new AddressMatrixEntry({
                addressField: AddressField.POSTALCODE,
                fieldValidation: [
                    new FieldValidation({
                        type: FieldValidationType.MINCHARS,
                        amount: 1,
                        errorMessageI18nDefinition: 'backoffice.onboarding.errors.companyAddressPostalCodeEmpty',
                    }),
                ],
                addressFieldPlaceholderI18nDefinition: 'general.address.postalCode',
            }),
            new AddressMatrixEntry({
                addressField: AddressField.CITY,
                fieldValidation: [
                    new FieldValidation({
                        type: FieldValidationType.MINCHARS,
                        amount: 1,
                        errorMessageI18nDefinition: 'backoffice.onboarding.errors.companyAddressCityEmpty',
                    }),
                ],
                addressFieldPlaceholderI18nDefinition: 'general.address.city',
            }),
            new AddressMatrixEntry({
                addressField: AddressField.COUNTRY,
                fieldValidation: [
                    new FieldValidation({
                        type: FieldValidationType.COUNTRY,
                        errorMessageI18nDefinition: 'backoffice.onboarding.errors.chooseACountry',
                    }),
                ],
                addressFieldPlaceholderI18nDefinition: 'general.address.chooseCountry',
            }),
        ],
    )

    static internationalMatrix = new AddressMatrix(
        [
            new AddressMatrixEntry({
                addressField: AddressField.ADDRESS1,
                fieldValidation: [
                    new FieldValidation({
                        type: FieldValidationType.MINCHARS,
                        amount: 1,
                        errorMessageI18nDefinition: 'backoffice.onboarding.errors.companyAddressNumberEmpty',
                    }),
                ],
                addressFieldPlaceholderI18nDefinition: 'general.address.address1',
            }),
            new AddressMatrixEntry({
                addressField: AddressField.ADDRESS2,
                fieldValidation: [
                    new FieldValidation({
                        type: FieldValidationType.MINCHARS,
                        amount: 1,
                        errorMessageI18nDefinition: 'backoffice.onboarding.errors.companyAddressNumberEmpty',
                    }),
                ],
                addressFieldPlaceholderI18nDefinition: 'general.address.address2',
            }),
            new AddressMatrixEntry({
                addressField: AddressField.CITY,
                fieldValidation: [
                    new FieldValidation({
                        type: FieldValidationType.MINCHARS,
                        amount: 1,
                        errorMessageI18nDefinition: 'backoffice.onboarding.errors.companyAddressNumberEmpty',
                    }),
                ],
                addressFieldPlaceholderI18nDefinition: 'general.address.city',
            }),
            new AddressMatrixEntry({
                addressField: AddressField.COUNTRY,
                fieldValidation: [
                    new FieldValidation({
                        type: FieldValidationType.MINCHARS,
                        amount: 1,
                        errorMessageI18nDefinition: 'backoffice.onboarding.errors.companyAddressCountryEmpty',
                    }),
                ],
                addressFieldPlaceholderI18nDefinition: 'general.address.chooseCountry',
            }),
        ],
    )

    static getMatrixForCountry(country: Country): AddressMatrix {
        const type = Address.getAddressTypeForCountry(country)
        switch (type) {
            case AddressType.DEFAULT:
                return this.defaultMatrix
            case AddressType.INTERNATIONAL:
                return this.internationalMatrix
            default:
                throw `unknown country: ${country}`
        }
    }

    static getSubscriptionOptions(): Array<Subscription> {
        return Object.values(Subscription)
    }

    static getLegalEntitiesOptionsForCountry(country: Country, step: number | null = null): Array<AllowedLegalEntityOption> {
        const options = [
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.SoleProprietorship, // eenmanszaak
                allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
            }),
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.PrivateLimitedCompany, // BV
                allowedCountries: ['BE', 'NL'],
            }),
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.PublicLimitedCompany, // NV
                allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
            }),
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.GeneralPartnership, // VOF
                allowedCountries: ['BE', 'NL', 'FR'],
            }),
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.LimitedLiabilityCooperative, // CVBA/VA
                allowedCountries: ['BE', 'NL'],
            }),
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.LimitedPartnership, // Comm. V
                allowedCountries: ['BE', 'NL'],
            }),
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.NonprofitAssociation, // VZW
                allowedCountries: ['BE'],
            }),
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.Vereniging,
                allowedCountries: ['NL'],
            }),
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.SARL,
                allowedCountries: ['FR'],
            }),
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.EURL,
                allowedCountries: ['FR'],
            }),
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.SAS,
                allowedCountries: ['FR'],
            }),
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.EBVBA,
                allowedCountries: ['BE', 'FR', 'IN'],
            }),
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.SCA,
                allowedCountries: ['FR'],
            }),
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.SCS,
                allowedCountries: ['FR'],
            }),
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.CivilCompany,
                allowedCountries: ['AE'],
            }),
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.Partnership,
                allowedCountries: ['AE', 'IN'],
            }),
            new AllowedLegalEntityOption({
                companyLegalEntity: CompanyLegalEntity.NGO,
                allowedCountries: ['IN'],
            }),
        ]
        for (const [key, val] of Object.entries(options)) {
            if (!val.allowedCountries.includes(country)) {
                options[key] = undefined
                continue
            }
            if (step !== null) {
                if (options[key].step !== step) {
                    options[key] = undefined
                }
            }
        }
        return options.filter((el) => {
            return typeof el != 'undefined'
        })
    }
}

export const RegisterFieldTemplates: {[k: string]: DorstFormFieldData} = {
    country: new DorstFormFieldData({
        label: 'country',
        type: DorstFormFieldType.MAINCOUNTRYSELECT,
        placeholderI18nDefinition: 'general.address.chooseCountry',
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.COUNTRY,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.chooseACountry',
            }),
        ],
    }),
    subscription: new DorstFormFieldData({
       label: 'subscription',
       type: DorstFormFieldType.SUBSCRIPTIONSELECT,
       placeholderI18nDefinition: 'backoffice.onboarding.chooseSubscription',
       validationRules:
           [
               new FieldValidation({
                   type: FieldValidationType.NOTEMPTY,
                   errorMessageI18nDefinition: 'backoffice.onboarding.errors.chooseASubscription',
               }),
           ],
        allowedCountries: ['NL'],
    }),

    companyName: new DorstFormFieldData({
        label: 'companyName',
        type: DorstFormFieldType.TEXTFIELD,
        placeholderI18nDefinition: 'backoffice.onboarding.companyNamePlaceholder',
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.MINCHARS,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.companyNameEmpty',
                amount: 2,
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
    }),
    kvkNumber: new DorstFormFieldData({
        label: 'kvkNumber',
        type: DorstFormFieldType.TEXTFIELD,
        placeholderI18nDefinition: 'backoffice.onboarding.kvkNumberPlaceholder',
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.MINCHARS,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.kvkInvalid',
                amount: 8,
            }),
        ],
        allowedCountries: ['NL'],
    }),
    siret: new DorstFormFieldData({
        label: 'siret',
        type: DorstFormFieldType.TEXTFIELD,
        placeholderI18nDefinition: 'backoffice.onboarding.siretNumberPlaceholder',
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.MINCHARS,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.siretNumberInvalid',
                amount: 14,
            }),
        ],
        allowedCountries: ['FR'],
    }),
    dedLicence: new DorstFormFieldData({
        label: 'DEDlicense',
        type: DorstFormFieldType.TEXTFIELD,
        placeholderI18nDefinition: 'backoffice.onboarding.dedLicensePlaceholder',
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.MINCHARS,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.dedInvalid',
                amount: 5,
            }),
        ],
        allowedCountries: ['AE'],
    }),
    cin: new DorstFormFieldData({
        label: 'cin',
        type: DorstFormFieldType.TEXTFIELD,
        placeholderI18nDefinition: 'backoffice.onboarding.cinNumberPlaceholder',
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.MINCHARS,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.cinNumberInvalid',
                amount: 5,
            }),
        ],
        allowedCountries: ['IN'],
    }),
    vat: new DorstFormFieldData({
        label: 'vatNumber',
        type: DorstFormFieldType.TEXTFIELD,
        placeholderI18nDefinition: 'backoffice.onboarding.vatNumberPlaceholder',
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.MINCHARS,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.vatInvalid',
                amount: 8,
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR'],
    }),
    legalForm: new DorstFormFieldData({
        label: 'legalForm',
        type: DorstFormFieldType.LEGALENTITYSELECT,
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.NOTEMPTY,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.legalEntityEmpty',
            }),
            new FieldValidation({
                type: FieldValidationType.VALIDLEGALENTITY,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.invalidLegalEntity',
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
    }),
    companyAddress: new DorstFormFieldData({
        label: 'companyAddress',
        type: DorstFormFieldType.MAINADDRESS,
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.ADDRESS,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.invalidAddress',
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
    }),
    shopName: new DorstFormFieldData({
        label: 'shopName',
        type: DorstFormFieldType.TEXTFIELD,
        placeholderI18nDefinition: 'backoffice.onboarding.shopNamePlaceholder',
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.NOTEMPTY,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.emptyShopName',
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
    }),
    shopUri: new DorstFormFieldData({
        label: 'uri',
        type: DorstFormFieldType.TEXTFIELD,
        placeholderI18nDefinition: 'backoffice.onboarding.shopUriPlaceholder',
        validateOnInput: {
            showIfEmpty: false,
            errorOnly: false,
            valid: null,
        },
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.NOTEMPTY,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.emptyShopUri',
            }),
            new FieldValidation({
                type: FieldValidationType.UNIQUEURI,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.shopUriInUse',
                linkedFieldLabel: 'shopName',
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
    }),
    shopAddress: new DorstFormFieldData({
        label: 'shopAddress',
        placeholderI18nDefinition: 'backoffice.onboarding.sameAsCompanyAddress',
        type: DorstFormFieldType.ADDRESS,
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.ADDRESS,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.invalidAddress',
                orNull: true,
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
    }),
    takeAwayAddress: new DorstFormFieldData({
        label: 'takeAwayAddress',
        type: DorstFormFieldType.ADDRESS,
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.ADDRESS,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.invalidAddress',
                orNull: true,
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
    }),
    firstName: new DorstFormFieldData({
        label: 'firstName',
        type: DorstFormFieldType.TEXTFIELD,
        placeholderI18nDefinition: 'backoffice.onboarding.firstNamePlaceholder',
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.MINCHARS,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.firstNameEmpty',
                amount: 2,
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
    }),
    lastName: new DorstFormFieldData({
        label: 'lastName',
        type: DorstFormFieldType.TEXTFIELD,
        placeholderI18nDefinition: 'backoffice.onboarding.lastNamePlaceholder',
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.MINCHARS,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.lastNameEmpty',
                amount: 2,
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
    }),
    email: new DorstFormFieldData({
        label: 'email',
        type: DorstFormFieldType.TEXTFIELD,
        placeholderI18nDefinition: 'backoffice.onboarding.emailPlaceholder',
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.NOTEMPTY,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.emailAddressEmpty',
            }),
            new FieldValidation({
                type: FieldValidationType.EMAIL,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.invalidEmail',
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
    }),
    password: new DorstFormFieldData({
        label: 'password',
        type: DorstFormFieldType.PASSWORD,
        placeholderI18nDefinition: 'backoffice.onboarding.passwordPlaceholder',
        validateOnInput: {
            showIfEmpty: false,
            errorOnly: false,
            valid: null,
        },
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.PASSWORD,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.passwordNotSecure',
                includePasswordPolicy: true,
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
    }),
    passwordRepeat: new DorstFormFieldData({
        label: 'passwordRepeat',
        type: DorstFormFieldType.PASSWORD,
        placeholderI18nDefinition: 'backoffice.onboarding.passwordPlaceholder',
        validateOnInput: {
            showIfEmpty: false,
            errorOnly: false,
            valid: null,
        },
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.SAMEAS,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.passwordNotEqual',
                linkedFieldLabel: 'password',
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
    }),
    phone: new DorstFormFieldData({
        label: 'phone',
        type: DorstFormFieldType.PHONE,
        placeholderI18nDefinition: 'backoffice.onboarding.phonePlaceholder',
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.NOTEMPTY,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.emptyPhoneNumber',
            }),
            new FieldValidation({
                type: FieldValidationType.PHONE,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.invalidPhoneNumber',
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
    }),
    usagePolicy: new DorstFormFieldData({
        label: 'usagePolicy',
        type: DorstFormFieldType.POLICYCHECKBOX,
        placeholderI18nDefinition: 'customer.details.terms',
        linkI18nDefinition: 'backoffice.onboarding.basicAgreementLink',
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.NOTEMPTY,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.policiesNotAccepted',
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
    }),

    usagePolicyProcent: new DorstFormFieldData({
        label: 'usagePolicyProcent',
        type: DorstFormFieldType.POLICYCHECKBOX,
        placeholderI18nDefinition: 'customer.details.terms',
        linkI18nDefinition: 'backoffice.onboarding.basicAgreementLink',
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.NOTEMPTY,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.policiesNotAccepted',
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR'],
    }),
    privacyPolicy: new DorstFormFieldData({
        label: 'privacyPolicy',
        type: DorstFormFieldType.POLICYCHECKBOX,
        placeholderI18nDefinition: 'customer.details.privacyPolicy',
        linkI18nDefinition: 'customer.details.privacyPolicyLink',
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.NOTEMPTY,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.policiesNotAccepted',
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
    }),
    processorPolicy: new DorstFormFieldData({
        label: 'processorPolicy',
        type: DorstFormFieldType.POLICYCHECKBOX,
        placeholderI18nDefinition: 'customer.details.terms',
        linkI18nDefinition: 'backoffice.onboarding.processorPolicyLink',
        validationRules: [
            new FieldValidation({
                type: FieldValidationType.NOTEMPTY,
                errorMessageI18nDefinition: 'backoffice.onboarding.errors.policiesNotAccepted',
            }),
        ],
        allowedCountries: ['BE', 'NL', 'FR'],
    }),
    newsletterOptIn: new DorstFormFieldData({
        label: 'newsletterOptIn',
        type: DorstFormFieldType.POLICYCHECKBOX,
        placeholderI18nDefinition: 'customer.details.newsletterOptIn',
        validationRules: [],
        allowedCountries: ['BE', 'NL', 'FR', 'AE', 'IN'],
    }),
}
