var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DRSTBoxItem",
    {
      staticClass: "text-input-row",
      class: {
        "has-label right-input": !!_vm.label,
        "no-label": !_vm.label,
        invalid: _vm.valid === false
      },
      attrs: { "element-name": _vm.elementName, selectable: !_vm.disabled },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _vm._t("left", null, { slot: "left" }),
      _vm._v(" "),
      _vm.label
        ? [
            _vm._t("label", [
              _c("div", {
                staticClass: "text-label",
                domProps: { textContent: _vm._s(_vm.label) },
                on: { click: _vm.select }
              })
            ]),
            _vm._v(" "),
            _c(
              "template",
              { slot: "right" },
              [
                _c("input", {
                  ref: "input",
                  staticClass: "text-input-row",
                  attrs: {
                    disabled: _vm.disabled,
                    readonly: _vm.readonly,
                    type: _vm.editingType,
                    minlength: _vm.minlength,
                    maxlength: _vm.maxlength,
                    multiple: _vm.multiple,
                    placeholder: _vm.placeholder,
                    autocomplete: _vm.autocomplete,
                    autocorrect: "off",
                    inputmode: _vm.inputmode
                  },
                  domProps: { value: _vm.text },
                  on: {
                    input: function($event) {
                      _vm.text = $event.target.value
                    },
                    mousedown: _vm.selectIfNotFocus,
                    focus: _vm.onFocusInput,
                    change: function($event) {
                      return _vm.$emit("change", $event)
                    },
                    blur: _vm.blur
                  }
                }),
                _vm._v(" "),
                _vm.symbol
                  ? _c("span", [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.symbol) +
                          "\n            "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.type.toLowerCase() === "password"
                  ? _c("span", {
                      staticClass: "icon eye",
                      class: { closed: _vm.editingType === "text" },
                      attrs: { tabindex: "-1" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.togglePassword($event)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showVerification
                  ? _c("VerifyIcon", {
                      class: { hidden: _vm.text.length === 0 },
                      attrs: { valid: _vm.verifyIcon }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm._t("right", null, { slot: "right" })
          ]
        : [
            _c(
              "div",
              { staticClass: "main-input-wrapper" },
              [
                _c("input", {
                  ref: "input",
                  staticClass: "text-input-row",
                  attrs: {
                    disabled: _vm.disabled,
                    readonly: _vm.readonly,
                    type: _vm.editingType,
                    minlength: _vm.minlength,
                    maxlength: _vm.maxlength,
                    placeholder: _vm.placeholder,
                    autocomplete: _vm.autocomplete,
                    autocorrect: "off",
                    inputmode: _vm.inputmode
                  },
                  domProps: { value: _vm.text },
                  on: {
                    input: function($event) {
                      _vm.text = $event.target.value
                    },
                    mousedown: _vm.selectIfNotFocus,
                    focus: _vm.onFocusInput,
                    change: function($event) {
                      return _vm.$emit("change", $event)
                    },
                    blur: _vm.blur
                  }
                }),
                _vm._v(" "),
                _vm.type.toLowerCase() === "password"
                  ? _c("span", {
                      staticClass: "icon eye",
                      class: { closed: _vm.editingType === "text" },
                      attrs: { tabindex: "-1" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.togglePassword($event)
                        }
                      }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showVerification
                  ? _c("VerifyIcon", { attrs: { valid: _vm.verifyIcon } })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm._t("right", null, { slot: "right" })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }