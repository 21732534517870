import {AutoEncoder, field, NumberDecoder, StringDecoder} from '@simonbackx/simple-encoding'

export class TBEstablishment extends AutoEncoder {
    @field({decoder: StringDecoder, optional: true})
    no?: string

    @field({decoder: StringDecoder, optional: true})
    name?: string

    @field({decoder: NumberDecoder, optional: true})
    storeType?: number

    @field({decoder: StringDecoder, optional: true})
    regionCode?: string

    @field({decoder: StringDecoder, optional: true})
    storePriceClass?: string
}

// VB API
// [
//     {
//       "no": "W407",
//       "name": "Wasbar Antwerpen Graanmarkt",
//       "storeType": 1,
//       "regionCode": "ANT",
//       "storePriceClass": ""
//     }
//   ]
