import {AutoEncoder, field, NumberDecoder} from '@simonbackx/simple-encoding'

import {PaymentMethodType} from './PaymentMethod'
import {PaymentProvider} from './PaymentProvider'

export class TransactionFees extends AutoEncoder {
    @field({decoder: NumberDecoder, defaultValue: () => 0})
    fixed: number

    @field({decoder: NumberDecoder, defaultValue: () => 0})
    percentage: number

    static default(type: PaymentMethodType, provider: PaymentProvider): TransactionFees {
        if (provider === PaymentProvider.Network) {
            return TransactionFees.create({fixed: 50, percentage: 2.5})
        }

        if (provider === PaymentProvider.Adyen) {
            return TransactionFees.create({fixed: 0, percentage: 0})
        }

        switch (type) {
            case PaymentMethodType.PointOfSale:
                return TransactionFees.create({fixed: 0, percentage: 0})
            case PaymentMethodType.Starnet:
                return TransactionFees.create({fixed: 0, percentage: 0})
            case PaymentMethodType.Digitin:
                return TransactionFees.create({fixed: 0, percentage: 0})
            case PaymentMethodType.CreditCard:
                return TransactionFees.create({fixed: 17, percentage: 1.5})
            default:
                return TransactionFees.create({fixed: 17, percentage: 0})
        }
    }
}
