import {RegexpHelper} from './RegexpHelper'

class DomainsHelperStatic {
    isCorsAllowedForHost(host) {
        if (process.env.NODE_ENV !== 'production') {
            return true
        }
        return this.isOwnedSubDomain(host)
    }

    isCorsAllowedForUri(uri) {
        if (process.env.NODE_ENV !== 'production') {
            return true
        }
        return this.isConsumersEndpointUri(uri)
    }

    isConsumersEndpointUri(uri: string): boolean {
        return RegexpHelper.isConsumersEndpointUri().test(uri)
    }

    // to test whether it is for example dashboard.[dorst.app|soif.app|...]
    isOwnedSubDomain(url: string): boolean {
        return RegexpHelper.isOwnedSubDomain().test(url)
    }

    isOwnedRootDomain(url: string): boolean {
        return RegexpHelper.isOwnedRootDomain().test(url)
    }
}

const instance = new DomainsHelperStatic()
Object.freeze(instance)

export const DomainsHelper = instance
