import {AutoEncoder, BooleanDecoder, field, NumberDecoder, StringDecoder} from '@simonbackx/simple-encoding'

import {ProductSyncedFields} from './ProductMeta'
import {CloudPosSubproduct} from './cloudpos/CloudPosSubproduct'
import {DeliverectModifier} from './deliverect/DeliverectModifier'
import {DIModifierOption} from './digitin/DigitinModifier'
import {LProduct} from './lightspeed/LProduct'
import {UProduct} from './untill/UProduct'

export class TBOptionMeta extends AutoEncoder {
    @field({decoder: StringDecoder, nullable: true, optional: true})
    itemId: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    categoryId: string | null = null

    /**
     * If filled in the option will be combined into 1 (e.g. product: PIZZA | variant LARGE | option
     * CHEEZY CHRUST => 1 TBProduct "large pizza cheezy crust" = 1 navisionId)
     */
    @field({decoder: StringDecoder, nullable: true, optional: true})
    priceGroup1: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    priceGroup2: string | null = null

    @field({decoder: NumberDecoder, nullable: true, optional: true})
    navisionPrice: number | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    navisionId: string | null = null

    /**
     * If set to true, we do not need to send this option to their api
     * */
    @field({decoder: BooleanDecoder, optional: true})
    isNoneOption = false
}

export class OptionMeta extends AutoEncoder {
    /**
     * Keeps track of which fields that should get synced with external product databases
     */
    @field({decoder: ProductSyncedFields, defaultValue: () => ProductSyncedFields.create({}), optional: true})
    syncedFields: ProductSyncedFields

    @field({decoder: LProduct, nullable: true, optional: true})
    lightspeedL: LProduct | null = null

    @field({decoder: TBOptionMeta, nullable: true, optional: true})
    topBrands: TBOptionMeta | null = null

    @field({decoder: UProduct, version: 79, nullable: true, optional: true})
    untill: UProduct | null = null

    @field({decoder: DIModifierOption, version: 79, nullable: true, optional: true})
    digitin: DIModifierOption | null = null

    @field({decoder: CloudPosSubproduct, nullable: true, optional: true})
    cloudpos: CloudPosSubproduct | null = null

    @field({decoder: DeliverectModifier, version: 79, nullable: true, optional: true})
    deliverect: DeliverectModifier | null = null

    get isExternal(): boolean {
        return Boolean(this.lightspeedL || this.untill || this.topBrands || this.digitin || this.cloudpos || this.deliverect)
    }
}
