



















import {Component, Prop, Vue} from 'vue-property-decorator'

import Spinner from '../Spinner.vue'

@Component({
    components: {
        Spinner,
    },
})
export default class VerifyIcon extends Vue {
    /** null == pending / boolean => valid or not */
    @Prop({default: null})
    valid!: boolean | null
}
