















import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({})
export default class StatusToggle extends Vue {
    @Prop()
    value: boolean | null
}
