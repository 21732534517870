






































































































































































import {SimpleError} from '@simonbackx/simple-errors'
import {ComponentWithProperties, HistoryManager} from '@simonbackx/vue-app-navigation'
import {debounce} from 'debounce'
import {Component, Mixins, Watch} from 'vue-property-decorator'

import {AnalyticsController} from '../analytics/AnalyticsController'
import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import {TealiumManager} from '../classes/TealiumManager'
import {environment} from '../environments/environment'
import {OpeningHourHelper} from '../helpers/OpeningHourHelper'
import {PriceHelper} from '../helpers/PriceHelper'
import {CheckoutMixin} from '../mixins/CheckoutMixin'
import CartItemRow from './CartItemRow.vue'
import EditCartItemOptionsView from './EditCartItemOptionsView.vue'
import OfferCard from './OfferCard.vue'
import UserDetailsView from './UserDetailsView.vue'
import {
    DRSTBox,
    DRSTBoxItem,
    DRSTFloatingFooter,
    DRSTFooterBox,
    DRSTNavigationBar,
    RemarkBlock,
    SpinnerButton,
} from '@dorst/components'
import {defaultLocale} from '@dorst/frontend-helpers'
import {i18n} from '@dorst/frontend-translations'
import {
    CartItem,
    ConsumptionOptionType,
    ConsumptionOptionTypeHelper,
    Customer,
    ExtraCost,
    TranslatedString,
} from '@dorst/structures'

@Component({
    components: {
        CartItemRow,
        DRSTBox,
        RemarkBlock,
        DRSTBoxItem,
        DRSTFloatingFooter,
        DRSTFooterBox,
        DRSTNavigationBar,
        OfferCard,
        SpinnerButton,
    },
})
export default class CartView extends Mixins(CheckoutMixin) {
    CartManager = CartManager
    hasTriedToContinueWithError = false
    loading = false

    forceUpdateCanOrder = 0
    checkOpeningHours: ReturnType<typeof OpeningHourHelper.getStartStop>

    getSubItemsForCartItemId(item: CartItem): Array<CartItem> {
        return this.cart.getSubItemsForCartItemId(item.id)
    }

    get checkout() {
        return this.CartManager.checkout
    }

    get cart() {
        return this.checkout.cart
    }

    get hasValidationErrors() {
        return this.validationErrors.length > 0
    }

    get addButtonDisabled() {
        return !this.canCheckout || this.hasValidationErrors && this.hasTriedToContinueWithError
    }

    get validationErrors() {
        const errors: Array<string> = []
        if (this.minOrderAmountNotReached) {
            errors.push(
                this.$i18n.t(
                    'customer.details.errors.priceBelowMinOrderAmount',
                    {
                        price: PriceHelper.format({
                            price: this.minOrderAmount - this.cartPrice,
                            forceDisplay: true,
                        }),
                    },
                ).toString(),
            )
        }
        return errors
    }

    get minOrderAmount() {
        return Math.min(ServerManager.shop.consumptionOptions[ConsumptionOptionTypeHelper.getSlug(CartManager.checkout.consumptionMode)].minOrderAmountOnlinePayment, ServerManager.shop.consumptionOptions[ConsumptionOptionTypeHelper.getSlug(CartManager.checkout.consumptionMode)].minOrderAmountWaiterPayment)
    }

    get minOrderAmountNotReached() {
        return this.cartPrice < this.minOrderAmount
    }

    get errors(): Array<SimpleError> {
        return this.CartManager.cartErrors ?? []
    }

    get shop() {
        return ServerManager.shop
    }

    get canCheckout(): boolean {
        if (this.forceUpdateCanOrder) {/**/} // force update hack

        if (this.cart.items.length === 0) {
            return false
        }

        if (
            this.checkout.consumptionMode === ConsumptionOptionType.DineIn
            && this.shop.consumptionOptions.dineIn.enableDineInOpeningHours
        ) {
            // We allow customers to check out 5 minutes after the shop closes
            return !this.shop.consumptionOptions.dineIn.isClosedOn(new Date(), this.shop.timezone, 5)
        }

        return true
    }

    get displayCartPrices() {
        return !ServerManager.shop.enableHidePrices
    }

    get checkoutButtonText() {
        return this.canCheckout ? this.$t('customer.cart.button') : this.$t('customer.order.shopClosed')
    }

    mounted() {
        HistoryManager.setUrl(ServerManager.shop.uri.length > 0 ? `/${ServerManager.shop.uri}/cart` : '/cart')

        this.applyExtraCosts()

        this.checkOpeningHours = OpeningHourHelper.getStartStop(() => this.forceUpdateCanOrder++)
        this.checkOpeningHours.start()
    }

    activated() {
        this.trackEvents()
        this.onEditCart(this.errors.length === 0)
    }

    trackEvents() {
        if (environment.features.tealium) {
            void TealiumManager.onCartView(this.$i18n as any)
        }
        AnalyticsController.get().event('onCartView')
    }

    beforeDestroy() {
        this.checkOpeningHours.stop()
    }

    formatExtraCostValue(extraCost: ExtraCost) {
        return extraCost.format({
            cartPrice: this.cartPrice,
            currency: ServerManager.shop.currency,
            locale: defaultLocale,
        })
    }

    translateForI18n(str: TranslatedString) {
        return str.getForI18n(this.$i18n as any)
    }

    get inclusiveExtraCosts(): Array<ExtraCost> {
        return this.checkout.inclusiveExtraCosts
    }

    get exclusiveExtraCosts(): Array<ExtraCost> {
        return this.checkout.exclusiveExtraCosts
    }

    get extraCosts(): Array<ExtraCost> {
        return this.checkout.extraCosts
    }

    get cartPrice() {
        return this.cart.getPrice()
    }

    @Watch('cartPrice')
    onCartPriceChange() {
        this.applyExtraCosts()
    }

    applyExtraCosts() {
        this.checkout.extraCosts = ServerManager.shop.getApplicableExtraCosts(this.checkout)
    }

    onEditCart(popOnEmpty = true) {
        this.applyExtraCosts()
        if ((popOnEmpty && this.cart.items.length === 0) || this.shop.hideOrderButtonsWhenOrderSystemIsOff || !CartManager.orderingAllowedForCustomerType) {
            this.exitCartView()
        }
        this.saveCart()
    }

    exitCartView() {
        void this.navigationController?.pop({animated: false})
    }

    isExtraCostApplicable(extra: ExtraCost): boolean {
        return !extra.min || (this.cartPrice >= extra.min) && (!extra.max || this.cartPrice <= extra.max)
    }

    get inclusiveExtraCost() {
        return this.checkout.inclusiveExtraCost
    }

    get exclusiveExtraCost() {
        return this.checkout.exclusiveExtraCost
    }

    clear() {
        if (confirm(this.$t('customer.cart.clearConfirmation').toString())) {
            CartManager.clear()
            this.onEditCart()
        }
    }

    deleteItem(item: CartItem, popOnEmpty = true) {
        CartManager.deleteItem(item)
        this.onEditCart(popOnEmpty)
    }

    saveCart() {
        CartManager.save()
    }

    clickedCartItem = debounce((cartItem: CartItem) => {
        this.present(new ComponentWithProperties(EditCartItemOptionsView, {
            cartItem: cartItem,
            subCartItems: this.getSubItemsForCartItemId(cartItem),
            editing: true,
            onSave: (newCartItem: CartItem, subItems: Array<CartItem>) => {
                this.deleteItem(cartItem, false)
                for (const subItem of subItems) {
                    this.addCartItem(subItem)
                }
                this.addCartItem(newCartItem)
            },
        }).setDisplayStyle('popup'))
    }, 500, true)

    addCartItem(item: CartItem) {
        CartManager.addItem(item)
        this.onEditCart()
    }

    scrollToErrors() {
        if (!this.validationErrors) {
            return
        }
        const scrollEl = document.querySelector('.validation-errors')
        if (scrollEl) {
            scrollEl.scrollIntoView({behavior: 'smooth'})
        }
    }

    startCheckout() {
        if (this.hasValidationErrors) {
            if (!this.hasTriedToContinueWithError) {
                this.hasTriedToContinueWithError = true
            }
            this.scrollToErrors()
        } else {
            this.loading = true
            CartManager.validate(CartManager.getConsumptionMode()).then((res) => {
                if (res) {
                    AnalyticsController.get().event('onInitiateCheckout')
                    this.goToNext()
                }
            }).catch((e) => {
                console.error(e)
            }).finally(() => {
                this.loading = false
            })
        }
    }

    goToNext() {
        if (this.shouldAskUserDetails()) {
            this.show(new ComponentWithProperties(UserDetailsView))
        } else {
            if (CartManager.checkout.customer === undefined) {
                const customer = Customer.create({
                    locale: i18n.locale.substring(0, 2),
                })
                this.$set(CartManager.checkout, 'customer', customer)
                CartManager.setCustomerType()
            }
            this.saveCart()
            if (this.isNextTimeSlot) {
                this.showTimeSlotView()
            } else if (this.canSkipTableSelection) {
                this.autoSelectTableAndPay(this.dineInTables[0])
            } else {
                this.editTable(false)
            }
        }
    }
}
