








































































































































import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import DRSTBox from '@dorst/components/src/DRSTBox.vue'
import DRSTBoxItem from '@dorst/components/src/DRSTBoxItem.vue'
import DRSTFloatingFooter from '@dorst/components/src/DRSTFloatingFooter.vue'
import DRSTNavigationBar from '@dorst/components/src/DRSTNavigationBar.vue'
import Radio from '@dorst/components/src/inputs/Radio.vue'
import {ConsumptionOptionType, ShopTypeHelper, Table, TableCategory} from '@dorst/structures'

@Component({
    components: {
        DRSTNavigationBar,
        DRSTBox,
        DRSTBoxItem,
        DRSTFloatingFooter,
        Radio,
    },
})
export default class TableSelectionView extends Mixins(NavigationMixin) {
    tables = ServerManager.shop?.tables ?? []
    tableCategories = ServerManager.shop?.tableCategories

    currentTable = CartManager.checkout.table && CartManager.checkout.table.type === CartManager.checkout.consumptionMode ? CartManager.checkout.table : null
    currentTableCategories = (CartManager.checkout.tableCategories ?? []).filter(t => t.type === CartManager.checkout.consumptionMode)

    selectedTableCategories: Array<TableCategory> = []

    mounted() {
        if (!this.currentTable) {
            if (this.currentTableCategories.length > 0) {
                this.currentTableCategories.forEach((el, i) => {
                    this.pushCategory(el, i === this.currentTableCategories.length - 1) // only propagate last one
                })
            }
        }
        this.collapseSingleChoice()
    }

    collapseSingleChoice() {
        if (this.childCategories.length == 1) {
            this.pushCategory(this.childCategories[0], true)
        }
    }

    initialSelectionMode = this.currentTable === null
    showSelect = this.currentTable === null
    showSelected() {
        this.collapseSingleChoice()
        this.showSelect = true
    }

    isActiveCategory(category: TableCategory): boolean {
        return this.currentTableCategories.some(el => el.id === category.id)
    }

    isActiveTable(table: Table): boolean {
        return this.currentTable?.id === table.id
    }

    @Prop({default: undefined})
    callback?: (Table, NavigationMixin) => void

    @Prop({default: undefined})
    buttonText?: string

    get title() {
        if (this.type === ConsumptionOptionType.TakeAway) {
            return this.$t('customer.takeAwayLocations.title').toString()
        }
        return this.isTableMode ? this.$t('customer.tables.title').toString() : this.$t('customer.places.title').toString()
    }

    get selectionName() {
        if (this.type === ConsumptionOptionType.TakeAway) {
            return this.$t('customer.takeAwayLocations.selected').toString()
        }
        return this.isTableMode ? this.$t('customer.tables.selected').toString() : this.$t('customer.places.selected').toString()
    }

    get type() {
        return CartManager.checkout.consumptionMode
    }

    get isTableMode(): boolean {
        return ShopTypeHelper.isTableMode(ServerManager.shop.type)
    }

    get tableCategoriesEnabled(): boolean {
        if (this.type === ConsumptionOptionType.TakeAway) {
            return this.filteredTableCategories.length > 1
        }
        return ServerManager.shop?.enableTableCategories
    }

    get filteredTableCategories() {
        return this.tableCategories.filter(t => t.type === this.type)
    }

    get hasTableCategories(): boolean {
        return this.selectedTableCategories.length > 0
    }

    get childCategories(): Array<TableCategory> {
        return this.hasTableCategories ? this.selectedTableCategories[this.selectedTableCategories.length - 1].tableCategories.filter(t => t.type === this.type) : this.filteredTableCategories
    }

    get hasChildCategories(): boolean {
        return this.childCategories.length > 0
    }

    get childTables(): Array<Table> {
        return this.hasTableCategories ? this.selectedTableCategories[this.selectedTableCategories.length - 1].tables.filter(t => t.type === this.type) : []
    }

    get hasChildTables(): boolean {
        return this.childTables.length > 0
    }

    get tablePath(): string {
        return this.currentTable ? [...this.currentTableCategories.map(c => c.name.trim()), this.currentTable.name.trim()].join(' - ') : ''
    }

    get selectAnotherTitle() {
        if (this.type === ConsumptionOptionType.TakeAway) {
            return this.$t('customer.tables.chooseDifferent').toString()
        }
        return this.isTableMode ? this.$t('customer.tables.selectAnother') : this.$t('customer.places.selectAnother')
    }

    popToMainCat() {
        this.selectedTableCategories = []
    }
    popToCategory(category: TableCategory) {
        const idx = this.selectedTableCategories.findIndex((c) => c.id === category.id)
        this.selectedTableCategories.splice(-1, this.selectedTableCategories.length - idx)
    }

    pushCategory(category: TableCategory, propagateSingleSelects = false) {
        this.selectedTableCategories.push(category)
        if (propagateSingleSelects && category.tableCategories.length == 1) {
            this.pushCategory(category.tableCategories[0], true)
        }
    }

    save() {
        if (!this.currentTable) {
            return
        }
        CartManager.setTable(this.currentTable)
        CartManager.setTableCategories([...this.currentTableCategories])
        if (this.callback) {
            this.callback(this.currentTable, this)
        } else {
            this.pop()
        }
    }
}
