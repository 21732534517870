import {Decoder} from '@simonbackx/simple-encoding'

import {ServerManager} from '../classes/ServerManager'
import {ConsumptionOptionType, DayWithTimeslots, GetTimeslotsRequest} from '@dorst/structures'

export async function GetDayWithTimeslots(consumptionMode: ConsumptionOptionType, productIds: Array<string>, date?: Date): Promise<DayWithTimeslots> {
    return new Promise((resolve, reject) => {
        ServerManager.getServer()
            .request({
                method: 'POST',
                path: `/consumer/shops/${ServerManager.shop.id}/timeslots`,
                body: GetTimeslotsRequest.create({
                    date,
                    productIds,
                    consumptionOptionType: consumptionMode,
                }),
                decoder: DayWithTimeslots as Decoder<DayWithTimeslots>,
            })
            .then(({data}) => resolve(data))
            .catch((e) => reject(e))
    })
}
