var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "checkbox",
      attrs: {
        "data-testid":
          "dorst-checkbox-" + (_vm.checkboxValue ? "checked" : "unchecked")
      }
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.checkboxValue,
            expression: "checkboxValue"
          }
        ],
        attrs: {
          id: _vm.inputId,
          disabled: _vm.disabled,
          type: "checkbox",
          name: _vm.name
        },
        domProps: {
          checked: Array.isArray(_vm.checkboxValue)
            ? _vm._i(_vm.checkboxValue, null) > -1
            : _vm.checkboxValue
        },
        on: {
          click: function($event) {
            $event.stopPropagation()
          },
          change: function($event) {
            var $$a = _vm.checkboxValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.checkboxValue = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.checkboxValue = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.checkboxValue = $$c
            }
          }
        }
      }),
      _vm._v(" "),
      _c("div", [
        _c("div", [
          _c(
            "svg",
            {
              attrs: {
                width: "11",
                height: "9",
                viewBox: "0 0 11 9",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }
            },
            [
              _c("path", {
                attrs: {
                  d: "M1 4.5L4 8L9.5 1",
                  stroke: "white",
                  "stroke-width": "2",
                  "stroke-linecap": "round",
                  "stroke-linejoin": "round"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", [_vm._t("default")], 2)
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }