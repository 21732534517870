import {AutoEncoder, BooleanDecoder, field, IntegerDecoder, StringDecoder} from '@simonbackx/simple-encoding'

export class LCategory extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    id: number

    @field({decoder: StringDecoder})
    name: string

    @field({decoder: BooleanDecoder, optional: true})
    visible = true

    @field({decoder: IntegerDecoder, optional: true})
    sequence = 0

}
