import {AutoEncoder, BooleanDecoder, EnumDecoder, field} from '@simonbackx/simple-encoding'

import {EditMode} from './EditMode'
import {CloudPosLogin} from './cloudpos/CloudPosLogin'
import {DeliverectLogin} from './deliverect/DeliverectLogin'
import {DigitinLogin} from './digitin/DigitinLogin'
import {TopBrandsLoginStruct} from './topbrands/TopBrandsLoginStruct'
import {TouchSystemsLogin} from './touchsystems/TouchSystemsLogin'
import {UntillLogin} from './untill/UntillLogin'

export enum POSSystemType {
    Lightspeed = 'Lightspeed',
    TopBrands = 'Top Brands',
    Untill = 'Untill',
    Digitin = 'Digitin',
    TouchSystems = 'TouchSystems',
    CloudPos = 'CloudPos',
    Deliverect = 'Deliverect',
}

export enum POSSystemMode {
    Push = 'Push',
    Pull = 'Pull',
}

export enum POSSystemSyncStatus {
    Unknown = 'Unknown',
    InProgress = 'In Progress',
    Finished = 'Finished',
    Error = 'Error',
}

export enum POSSystemOrderSendStatus {
    Pending = 'Pending',
    Error = 'Error',
    Sent = 'Sent',
    None = 'None',
}

export enum POSSystemOrderValidationStatus {
    PENDING = 'Pending',
    ERROR = 'Error',
    VALID = 'Valid',
    NONE = 'None',
}

export class POSSystemSyncStatusWrapper extends AutoEncoder {
    @field({decoder: new EnumDecoder(POSSystemSyncStatus)})
    status: POSSystemSyncStatus
}

export class POSSystemOrderValidationStatusWrapper extends AutoEncoder {
    @field({decoder: new EnumDecoder(POSSystemOrderValidationStatus)})
    status: POSSystemOrderValidationStatus
}

export interface LinkConfig {
    autoMarkProduced: boolean
    autoSendToPos: boolean
    editCategories: EditMode
    editProducts: EditMode
    editTableCategories: EditMode
    editTables: EditMode
    mode: POSSystemMode
    syncDiscounts: boolean
    syncProducts: boolean
    syncTables: boolean
    forceRefreshToken: boolean
}

const defaultLinkConfig: LinkConfig = {
    autoMarkProduced: false,
    autoSendToPos: true,
    editCategories: EditMode.SyncedFields,
    editProducts: EditMode.SyncedFields,
    editTableCategories: EditMode.SyncedFields,
    editTables: EditMode.SyncedFields,
    mode: POSSystemMode.Push,
    syncDiscounts: false,
    syncProducts: true,
    syncTables: true,
    forceRefreshToken: false,
}

export class POSSystemLinkConfig {
    static get(type: POSSystemType): LinkConfig {
        return {...defaultLinkConfig, ...this.getOverrides(type)}
    }

    static getOverrides(type: POSSystemType): Partial<LinkConfig> {
        switch (type) {
            case POSSystemType.Lightspeed:
                return {
                    autoMarkProduced: true,
                    syncDiscounts: true,
                    forceRefreshToken: true,
                }
            case POSSystemType.TopBrands:
                return {
                    syncTables: false,
                    editProducts: EditMode.None,
                    editCategories: EditMode.None,
                    editTables: EditMode.All,
                    editTableCategories: EditMode.All,
                }
            case POSSystemType.Untill:
                return {}
            case POSSystemType.Digitin:
                return {}
            case POSSystemType.TouchSystems:
                return {
                    autoMarkProduced: true,
                    syncProducts: false,
                    syncTables: false,
                    editProducts: EditMode.All,
                    editCategories: EditMode.All,
                    editTables: EditMode.All,
                    editTableCategories: EditMode.All,
                    mode: POSSystemMode.Pull,
                }
            case POSSystemType.CloudPos:
                return {
                    autoMarkProduced: true,
                }
            case POSSystemType.Deliverect:
                return {
                    syncProducts: false,
                    autoMarkProduced: true,
                }
            default: {
                throw new Error(`Unsupported POS System ${type}`)
            }
        }
    }
}

export class AutoSendToPosForConsumptionModeConfig extends AutoEncoder {
    @field({decoder: BooleanDecoder, optional: true})
    online = true

    @field({decoder: BooleanDecoder, optional: true})
    pointOfSale = true
}

export class AutoSendToPosConfig extends AutoEncoder {
    @field({decoder: AutoSendToPosForConsumptionModeConfig, optional: true})
    delivery = AutoSendToPosForConsumptionModeConfig.create({})

    @field({decoder: AutoSendToPosForConsumptionModeConfig, optional: true})
    dineIn = AutoSendToPosForConsumptionModeConfig.create({})

    @field({decoder: AutoSendToPosForConsumptionModeConfig, optional: true})
    takeAway = AutoSendToPosForConsumptionModeConfig.create({})
}

export class POSSystemLink extends AutoEncoder {
    @field({decoder: new EnumDecoder(POSSystemType)})
    type: POSSystemType

    @field({decoder: BooleanDecoder, optional: true})
    enabled = true

    /**
     * When you use an POS connection, set here if you want to mark all the orders as 'produced' automatically or not.
     * True by default, so we mark them as produced.
     */
    @field({decoder: BooleanDecoder, optional: true})
    markProduced = true

    /**
     * When you use an POS connection, set here for each consumption type if orders need to be sent to the POS
     * automatically, depending on their payment method.
     */
    @field({decoder: AutoSendToPosConfig, optional: true})
    autoSendToPos = AutoSendToPosConfig.create({})

    /**
     * Integrations
     */

    @field({decoder: BooleanDecoder, optional: true})
    lightspeed?: boolean

    @field({decoder: TopBrandsLoginStruct, optional: true})
    topBrands?: TopBrandsLoginStruct

    @field({decoder: UntillLogin, optional: true})
    untill?: UntillLogin

    @field({decoder: DigitinLogin, optional: true})
    digitin?: DigitinLogin

    @field({decoder: TouchSystemsLogin, optional: true})
    touchSystems?: TouchSystemsLogin

    @field({decoder: CloudPosLogin, optional: true})
    cloudpos?: CloudPosLogin

    @field({decoder: DeliverectLogin, optional: true})
    deliverect?: DeliverectLogin

    get config(): LinkConfig {
        return POSSystemLinkConfig.get(this.type)
    }
}
