import {ArrayDecoder, AutoEncoder, BooleanDecoder, EnumDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {ConsumptionOptionType} from './ConsumptionOptions'
import {TableCategoryMeta} from './TableCategoryMeta'

export class IDTableCategory extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: StringDecoder})
    name = ''

    @field({decoder: BooleanDecoder})
    enabled = true

    @field({decoder: StringDecoder})
    number = ''

    @field({decoder: new ArrayDecoder(StringDecoder)})
    tableIds: Array<string> = []

    @field({decoder: new ArrayDecoder(StringDecoder)})
    tableCategoryIds: Array<string> = []

    /**
     * For which consumption option this should be available
     */
    @field({decoder: new EnumDecoder(ConsumptionOptionType), version: 63})
    type = ConsumptionOptionType.DineIn

    @field({decoder: TableCategoryMeta, optional: true})
    meta = TableCategoryMeta.create({})
}

export const IDTableCategoryPatch = IDTableCategory.patchType()
