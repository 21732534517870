import {Decoder} from '@simonbackx/simple-encoding'
import {RequestResult} from '@simonbackx/simple-networking'

import {ServerManager} from '../classes/ServerManager'
import {Checkout, IDCheckout, OrderRequest, OrderResponse} from '@dorst/structures'

type RequiredNonNull<T> = {
    [P in keyof T]-?: NonNullable<T[P]>
}

export async function order(checkout: Checkout): Promise<RequestResult<OrderResponse>> {
    return new Promise((resolve, reject) => {
        ServerManager.getServer()
            .request({
                method: 'POST',
                path: `/consumer/shops/${ServerManager.shop.id}/order`,
                body: OrderRequest.create({
                    returnUrl: ServerManager.shop.uri ? `${window.location.protocol}//${window.location.host}/${ServerManager.shop.uri}/order` : `${window.location.protocol}//${window.location.host}/order`,
                    checkout: IDCheckout.from(checkout as RequiredNonNull<Checkout>),
                }),
                query: ServerManager.hasPassword() ? {password: ServerManager.password} : undefined,
                timeout: 5 * 60 * 1000,
                decoder: OrderResponse as Decoder<OrderResponse>,
            })
            .then(resolve).catch(reject)
    })
}
