



































import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import {PriceHelper} from '../helpers/PriceHelper'
import {
    AvailabilityLabel,
    DRSTBoxItem,
} from '@dorst/components'
import {isNullOrEmpty} from '@dorst/helpers'
import {CartItem, I18n, Product} from '@dorst/structures'

@Component({
    components: {
        DRSTBoxItem,
        AvailabilityLabel,
    },
})
export default class ProductBoxItem extends Mixins(NavigationMixin) {
    @Prop({required: true, type: Product})
    product: Product

    get name() {
        return this.product.getNameForI18n(this.$i18n as any)
    }

    get description() {
        return this.product.description.getForI18n(this.$i18n as any)
    }

    get imagePath() {
        return this.product.images[0].getPathForSize(64 * (window.devicePixelRatio ?? 1), 64 * (window.devicePixelRatio ?? 1))
    }

    get shop() {
        return ServerManager.shop
    }

    get availabilityTexts() {
        return this.product.getAvailabilityTexts(this.shop.timezone, this.$i18n as I18n)
    }

    getPriceDescription() {
        const linkedProducts = ServerManager.shop.getLinkedProducts(this.product)
        const prices = this.product.variants.flatMap(v => {
            const {item} = CartItem.fromProductAndVariantId(this.product, v.id, linkedProducts)
            return item ? [item.getPrices(CartManager.cart.subItems).unitPrice] : []
        })
        const minPrice = Math.min(...prices)
        const multiplePrices = new Set(prices).size > 1
        const formattedMinPrice = PriceHelper.format({price: minPrice})
        return isNullOrEmpty(formattedMinPrice)
            ? ''
            : multiplePrices ? this.$t('customer.products.fromPrice', {price: formattedMinPrice}) : formattedMinPrice
    }
}
