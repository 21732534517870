import {AutoEncoder, field, StringDecoder} from '@simonbackx/simple-encoding'
import {SimpleError} from '@simonbackx/simple-errors'

export class DigitinLogin extends AutoEncoder {
    @field({decoder: StringDecoder})
    apiKey = ''

    @field({decoder: StringDecoder})
    apiUrl = ''

    @field({decoder: StringDecoder})
    companyId = ''

    @field({decoder: StringDecoder})
    location = ''

    @field({decoder: StringDecoder, nullable: true, defaultValue: () => null})
    groupId: string | null

    verify() {
        if (this.apiUrl === '' || this.apiUrl === '' || this.companyId === '' || this.location === '') {
            throw new SimpleError({code: 'invalid_credentials', message: 'The given credentials are not valid.'})
        }
    }
}

export const DigitinLoginPatch = DigitinLogin.patchType()
