interface TranslatableName<T extends string> {
    name: {
        getForLanguage: <T>(language: T) => string
    }
}

export class SortHelper {
    static byName(alpha: {name: string}, beta: {name: string}) {
        const aname = alpha.name.toLowerCase()
        const bname = beta.name.toLowerCase()
        if (aname < bname) {
            return -1
        }
        if (aname > bname) {
            return 1
        }
        return 0
    }

    static byNumberedName(alpha: {name: string}, beta: {name: string}) {
        const split = (s: string): {prefix: string; numeric: number | null} => {
            const parts = s.trim().split(/([0-9]+)$/)
            if (parts.length === 3) {
                return {prefix: parts[0], numeric: parseInt(parts[1])}
            }
            return {prefix: parts[0], numeric: null}
        }

        const {prefix: aPref, numeric: aNum} = split(alpha.name)
        const {prefix: bPref, numeric: bNum} = split(beta.name)

        if (aNum !== null && bNum !== null) {
            if (aPref === bPref) {
                return aNum - bNum
            }
        }
        return SortHelper.byName(alpha, beta)
    }

    static bySortKey(alpha: {sortKey: string}, beta: {sortKey: string}) {
        const asortKey = alpha.sortKey.toLowerCase()
        const bsortKey = beta.sortKey.toLowerCase()
        if (asortKey < bsortKey) {
            return -1
        }
        if (asortKey > bsortKey) {
            return 1
        }
        return 0
    }

    static byTranslatedName<T extends string>(language: T): (
        alpha: TranslatableName<T>,
        beta: TranslatableName<T>,
    ) => number {
        return (alpha, beta) => {
            const aname = alpha.name.getForLanguage(language).toLowerCase()
            const bname = beta.name.getForLanguage(language).toLowerCase()
            if (aname < bname) {
                return -1
            }
            if (aname > bname) {
                return 1
            }
            return 0
        }
    }

    static byCount(alpha: {count: number}, beta: {count: number}) {
        if (alpha.count < beta.count) {
            return 1
        }
        if (alpha.count > beta.count) {
            return -1
        }
        return 0
    }

    static byAmount(alpha: {amount: number}, beta: {amount: number}) {
        if (alpha.amount < beta.amount) {
            return 1
        }
        if (alpha.amount > beta.amount) {
            return -1
        }
        return 0
    }

    /**
     * Ascending
     */
    static byID(alpha: {id: number}, beta: {id: number}) {
        if (alpha.id < beta.id) {
            return -1
        }
        if (alpha.id > beta.id) {
            return 1
        }
        return 0
    }

    /**
     * e.g. pass [a,c,b,d], [0,2,1,3]
     */
    static restore(arr: Array<any>, sortedIndices: Array<number>) {
        const restored = Array.from({length: arr.length}) as Array<any>

        for (let i = 0; i < arr.length; i++) {
            const idx = sortedIndices[i]
            restored[i] = arr[idx]
        }

        return restored
    }
}
