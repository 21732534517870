var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.editMatrix.matrix, function(field) {
        return [
          field.addressField === _vm.AddressField.COUNTRY
            ? _c("SelectRow", {
                key: field.addressField,
                attrs: {
                  value: _vm.value[field.addressField],
                  options: _vm.availableCountries,
                  label: _vm.InputFieldHelper.getAddressFieldPlaceholder(
                    field.addressField,
                    _vm.$i18n
                  ),
                  placeholder: _vm.InputFieldHelper.getAddressFieldPlaceholder(
                    field.addressField,
                    _vm.$i18n
                  ),
                  disabled: _vm.disabled
                },
                on: {
                  input: function($event) {
                    return _vm.updateValue(field.addressField, $event)
                  }
                }
              })
            : _c(
                "TextInputRow",
                {
                  key: field.addressField,
                  staticClass: "right-input",
                  attrs: {
                    value: _vm.value[field.addressField],
                    "element-name": "div",
                    placeholder: _vm.InputFieldHelper.getAddressFieldPlaceholder(
                      field.addressField,
                      _vm.$i18n
                    ),
                    label: _vm.InputFieldHelper.getAddressFieldPlaceholder(
                      field.addressField,
                      _vm.$i18n
                    ),
                    autocomplete: "addressField",
                    disabled: _vm.disabled
                  },
                  on: {
                    input: function($event) {
                      return _vm.updateValue(field.addressField, $event)
                    }
                  }
                },
                [_c("input", { attrs: { type: "text" } })]
              )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }