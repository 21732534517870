import {AutoEncoder, DateDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'

export class DICategory extends AutoEncoder {
    @field({decoder: StringDecoder, optional: true})
    categoryName: string

    @field({decoder: StringDecoder, optional: true})
    categoryID: string

    @field({decoder: StringDecoder, optional: true})
    vOrder: string

    @field({decoder: DateDecoder, optional: true})
    deleted: Date

    @field({decoder: DateDecoder, optional: true})
    created: Date

    @field({decoder: DateDecoder, optional: true, nullable: true})
    updated: Date | null = null

    @field({decoder: StringDecoder, optional: true})
    removed: string
}
