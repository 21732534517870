var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "remark-block", class: "type--" + _vm.type },
    [
      _vm._t("icon", [
        _vm.useIcon
          ? _c("span", {
              staticClass: "remark-block--icon icon",
              class: _vm.type
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "remark-block--content" },
        [_vm._t("default")],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }