import 'core-js/stable'

import * as Sentry from '@sentry/browser'
import Vue from 'vue'
import VueMeta from 'vue-meta'

import {environment} from './environments/environment'
import {PriceHelper, PriceHelperOptions} from './helpers/PriceHelper'
import * as supportedBrowsers from './supportedBrowsers'
import App from './views/App.vue'
import {addSausageToDOM, registerEasterEgg} from '@dorst/frontend-helpers'
import {i18n} from '@dorst/frontend-translations'
import {RegexpHelper} from '@dorst/helpers'

Vue.use(VueMeta) // dynamic meta tags

if (environment.sentry.enabled) {
    Sentry.init({dsn: environment.sentry.dsn})
}

if (!supportedBrowsers.test(navigator.userAgent)) {
    const match = RegexpHelper.isOwnedSubDomain().exec(location.host)
    if (match) {
        location.replace(`upgrade.${match[1].toString()}`)
    } else {
        location.replace(`upgrade.dorst.app`)
    }
}

if (environment.environment === 'development') {
    // Development mode
    Vue.config.productionTip = false
}

if (environment.easterEggEnabled) {
    registerEasterEgg(() => {
        addSausageToDOM()
    }).catch(console.error)
}

Vue.filter('price', (price: number, options?: PriceHelperOptions) => {
    return PriceHelper.format({
        ...options,
        price,
    })
})

new Vue({
    i18n: i18n,
    render: (h) => h(App),
}).$mount('#app')
const w = window as any

// First we get the viewport height, and we multiply it by 1% to get a value for a vh unit
let currentVh = document.documentElement.clientHeight * 0.01

// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${currentVh}px`)

if (w.visualViewport) {
    let pendingUpdate = false
    const viewportHandler = (event) => {
        if (pendingUpdate) {
            return
        }
        pendingUpdate = true

        requestAnimationFrame(() => {
            pendingUpdate = false
            const viewport = event.target
            const height = viewport.height

            // We execute the same script as before
            const vh = height * 0.01

            if (vh == currentVh) {
                return
            }
            currentVh = vh
            document.documentElement.style.setProperty('--vh', `${vh}px`)
        })
    }

    // w.visualViewport.addEventListener('scroll', viewportHandler);
    w.visualViewport.addEventListener('resize', viewportHandler)
} else {
    // We listen to the resize event
    window.addEventListener(
        'resize',
        () => {
            // We execute the same script as before
            const vh = document.documentElement.clientHeight * 0.01
            if (vh == currentVh) {
                return
            }
            currentVh = vh
            document.documentElement.style.setProperty('--vh', `${vh}px`)
        },
        {passive: true} as EventListenerOptions,
    )

    // We listen to the resize event
    window.addEventListener(
        'focus',
        () => {
            // We execute the same script as before
            const vh = document.documentElement.clientHeight * 0.01

            if (vh == currentVh) {
                return
            }
            currentVh = vh
            document.documentElement.style.setProperty('--vh', `${vh}px`)
        },
        {passive: true} as EventListenerOptions,
    )
}

document.body.addEventListener('touchstart', () => undefined, {passive: true})
document.body.addEventListener('focusin', (e) => {
    e.preventDefault()
})
document.body.addEventListener('focus', (e) => {
    e.preventDefault()
})
