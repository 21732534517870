import { render, staticRenderFns } from "./Tag.vue?vue&type=template&id=a6bffc38&"
import script from "./Tag.vue?vue&type=script&lang=ts&"
export * from "./Tag.vue?vue&type=script&lang=ts&"
import style0 from "./Tag.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wannes.vermeulen/Repositories/dorst-app/dorst/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a6bffc38')) {
      api.createRecord('a6bffc38', component.options)
    } else {
      api.reload('a6bffc38', component.options)
    }
    module.hot.accept("./Tag.vue?vue&type=template&id=a6bffc38&", function () {
      api.rerender('a6bffc38', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/src/Tag.vue"
export default component.exports