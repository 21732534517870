









































import {ComponentWithProperties, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {CartManager} from '../../classes/CartManager'
import {ServerManager} from '../../classes/ServerManager'
import {PriceHelper} from '../../helpers/PriceHelper'
import EditCartItemOptionsView from '../EditCartItemOptionsView.vue'
import DRSTBoxItem from '@dorst/components/src/DRSTBoxItem.vue'
import {CartItem, Product} from '@dorst/structures'

@Component({
    components: {
        DRSTBoxItem,
        EditCartItemOptionsView,
    },
})
export default class UpsellBoxItem extends Mixins(NavigationMixin) {
    @Prop({required: true, type: Product})
    product!: Product

    amount = 0

    cartItem = CartItem.defaultForProduct(this.product, []).cartItem // pass no possible linked products because we do not support menu-products as upsells.

    get name() {
        return this.product.getNameForI18n(this.$i18n as any)
    }

    get description() {
        return this.product.description.getForI18n(this.$i18n as any)
    }

    get imagePath() {
        return this.product.images[0].getPathForSize(64 * (window.devicePixelRatio ?? 1), 64 * (window.devicePixelRatio ?? 1))
    }

    get shop() {
        return ServerManager.shop
    }

    get availabilityTexts() {
        return this.product.getAvailabilityTexts(this.shop.timezone, this.$i18n as any)
    }

    clickedItem() {
        if (this.product.variants.length > 1 || this.product.defaultVariant.optionGroups.length > 0) {
            this.present(new ComponentWithProperties(EditCartItemOptionsView, {
                cartItem: this.cartItem,
                // Note: not using the second parameter `subItems` for now.
                onSave: (editCartItem: CartItem) => {
                    this.$emit('addUpsell', editCartItem.cloneWithNewId())
                },
            }).setDisplayStyle('popup'))
        } else {
            this.$emit('addUpsell', this.cartItem.cloneWithNewId())
        }
    }

    get priceDescription() {
        if (this.shop.enableHidePrices) {
            return ''
        }
        if (this.product.variants.length > 1) {
            return this.product.variants.flatMap(v => {
                const {item} = CartItem.fromProductAndVariantId(this.product, v.id, []) // pass no possible linked products because we do not support menu-products as upsells.
                if (item) {
                    return [
                        PriceHelper.format({
                            price: item.getPrices(CartManager.cart.subItems).unitPrice,
                        }),
                    ]
                }
                return []
            }).join(', ')
        } else {
            const cartItem = CartItem.defaultForProduct(this.product, []).cartItem // pass no possible linked products because we do not support menu-products as upsells.
            return PriceHelper.format({
                price: cartItem.getPrices(CartManager.cart.subItems).unitPrice,
            })
        }
    }
}

