const validCodeChars = '23456789ABCDEFGHJKLMNPQRSTUVWXYZ'.split('') // Length 32

export class RandomKey {
    static generate(length = 8): string {
        const array = new Uint8Array(length)
        crypto.getRandomValues(array) // [0-255] (including 255)

        // Map rand value to char [0-255] --> [0-31]
        const result: Array<string> = []
        for (const n of array) {
            const char = validCodeChars[n % validCodeChars.length]
            result.push(char)
        }
        return result.join('')
    }
}
