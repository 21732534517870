import {Data, Encodeable, EncodeContext} from '@simonbackx/simple-encoding'

export class CartItemPrice implements Encodeable {
    /**
     * Price per piece, in cents
     */
    unitPrice: number

    /**
     * Price in total, in cents
     */
    price: number

    constructor(data: {unitPrice: number; price: number}) {
        this.unitPrice = data.unitPrice
        this.price = data.price
    }

    static decode(data: Data): CartItemPrice {
        return new CartItemPrice({
            unitPrice: data.field('unitPrice').number,
            price: data.field('price').number,
        })
    }

    encode(context: EncodeContext) {
        return {
            unitPrice: this.unitPrice,
            price: this.price,
        }
    }
}
