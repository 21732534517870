import {Decoder} from '@simonbackx/simple-encoding'

import {ServerManager} from '../classes/ServerManager'
import {CashlessInfo, EncodeableBoolean, EncodeableInteger, PaymentMethod, VerificationMethod} from '@dorst/structures'

export class Cashless {
    shopId: number
    paymentMethod: PaymentMethod

    constructor(shopId: number, paymentMethod: PaymentMethod) {
        this.shopId = shopId
        this.paymentMethod = paymentMethod
    }

    GetVerificationMethod(): VerificationMethod {
        return this.paymentMethod.settings?.verificationMethod ?? VerificationMethod.None
    }

    async CardExists(card: string): Promise<{card: string; value: boolean}> {
        return new Promise((resolve, reject) => {
            ServerManager.getServer()
                .request({
                    method: 'POST',
                    path: `/consumer/shops/${ServerManager.shop.id}/cashless/exists`,
                    body: CashlessInfo.create({paymentMethod: this.paymentMethod.id, card}),
                    query: ServerManager.hasPassword() ? {password: ServerManager.password} : undefined,
                    decoder: EncodeableBoolean as Decoder<EncodeableBoolean>,
                })
                .then(({data: {value}}) => resolve({card, value}))
                .catch(reject)
        })
    }

    async CardNeedsVerification(card: string): Promise<{card: string; value: boolean}> {
        return new Promise((resolve, reject) => {
            ServerManager.getServer()
                .request({
                    method: 'POST',
                    path: `/consumer/shops/${ServerManager.shop.id}/cashless/needs-verification`,
                    body: CashlessInfo.create({paymentMethod: this.paymentMethod.id, card}),
                    query: ServerManager.hasPassword() ? {password: ServerManager.password} : undefined,
                    decoder: EncodeableBoolean as Decoder<EncodeableBoolean>,
                })
                .then(({data: {value}}) => resolve({card, value}))
                .catch(reject)
        })
    }

    async VerifyCard(card: string, validation: string | null): Promise<{card: string; validation: string | null; value: boolean}> {
        return new Promise((resolve, reject) => {
            ServerManager.getServer()
                .request({
                    method: 'POST',
                    path: `/consumer/shops/${ServerManager.shop.id}/cashless/verify`,
                    body: CashlessInfo.create({paymentMethod: this.paymentMethod.id, card, validation}),
                    query: ServerManager.hasPassword() ? {password: ServerManager.password} : undefined,
                    decoder: EncodeableBoolean as Decoder<EncodeableBoolean>,
                })
                .then(({data: {value}}) => resolve({card, validation, value}))
                .catch(reject)
        })
    }

    async GetBalance(card: string, validation: string | null): Promise<{card: string; value: number}> {
        return new Promise((resolve, reject) => {
            ServerManager.getServer()
                .request({
                    method: 'POST',
                    path: `/consumer/shops/${ServerManager.shop.id}/cashless/balance`,
                    body: CashlessInfo.create({paymentMethod: this.paymentMethod.id, card, validation}),
                    query: ServerManager.hasPassword() ? {password: ServerManager.password} : undefined,
                    decoder: EncodeableInteger as Decoder<EncodeableInteger>,
                })
                .then(({data: {value}}) => resolve({card, value}))
                .catch(reject)
        })
    }
}
