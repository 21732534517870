import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import {AnalyticProcessor} from './AnalyticsProcessorInterface'
import {ScriptLoader} from '@dorst/frontend-helpers'
import {isNullOrEmpty} from '@dorst/helpers'
import {
    CartItem,
    Category,
    OrderConsumer,
    PaymentMethod,
    Product,
} from '@dorst/structures'

/**
 * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce
 */
export class GoogleTagManagerAnalytics extends AnalyticProcessor {
    name = 'GoogleTagManager'

    constructor() {
        super()
        window.dataLayer = [
            {
                // Whitelist allowed tags
                // see https://developers.google.com/tag-platform/tag-manager/web/restrict
                'gtm.allowlist': [
                    'google', // Only allow scripts by Google and data sent to Google
                ],
            },
        ]
    }

    isConfigured(): boolean {
        return !isNullOrEmpty(ServerManager.shop.googleTagManagerId)
    }

    async grantConsent(): Promise<void> {
        const id = ServerManager.shop.googleTagManagerId

        if (isNullOrEmpty(id)) {
            return
        }

        this.send({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        })

        const url = new URL('https://www.googletagmanager.com/gtm.js')
        url.searchParams.set('id', id)
        url.searchParams.set('l', 'dataLayer') // Set the layer that this GTM will use

        await ScriptLoader.loadScript(this.name, url.toString())
    }

    /**
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#add_to_cart
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#add_to_cart
     */
    onAddProductToCart(cartItem: CartItem) {
        this.ecommerce('add_to_cart', {
            currency: ServerManager.shop.currency,
            items: [this.cartItemToEcommerce(cartItem)],
            value: cartItem.getPrices().price / 100,
        })
    }

    /**
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_cart
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#view_cart
     */
    onCartView() {
        this.ecommerce('view_cart', {
            currency: ServerManager.shop.currency,
            price: CartManager.cart.getPrice() / 100,
            items: CartManager.cart.items.map(this.cartItemToEcommerce),
        })
    }

    /**
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_item_list
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#view_item_list
     */
    onCategoryView(category: Category, products: Array<Product>) {
        this.ecommerce('view_item_list', {
            item_list_id: category.id,
            item_list_name: category.getNameForI18n({locale: ServerManager.shop.language}),
            items: products.map((product, index) => ({
                item_id: product.id,
                item_name: product.name.getForI18n({locale: ServerManager.shop.language}),
                currency: ServerManager.shop.currency,
                index,
                price: product.getBestPrice() / 100,
                quantity: 1,
            })),
        })
    }

    onHomepageView(categories: Array<Category>) {
        this.send({
            event: 'viewHomePage',
        })
    }

    /**
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_item_list
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#view_item_list
     */
    onHomepageViewWithSegmentedControl(products: Array<Product>) {
        this.ecommerce('view_item_list', {
            items: products.map((product, index) => ({
                item_id: product.id,
                item_name: product.name.getForI18n({locale: ServerManager.shop.language}),
                currency: ServerManager.shop.currency,
                index,
                price: product.getBestPrice() / 100,
                quantity: 1,
            })),
        })
    }

    /**
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#begin_checkout
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#begin_checkout
     */
    onInitiateCheckout() {
        this.ecommerce('begin_checkout', {
            coupon: CartManager.checkout.discount?.code,
            currency: ServerManager.shop.currency,
            items: CartManager.cart.items.map(this.cartItemToEcommerce),
            value: CartManager.cart.getPrice() / 100,
        })
    }

    /**
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#purchase
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#purchase
     */
    onOrderConfirmation(order: OrderConsumer) {
        this.ecommerce('purchase', {
            coupon: order.discount?.code,
            currency: order.currency,
            items: order.items.map(this.cartItemToEcommerce),
            transaction_id: order.uuid,
            value: order.price / 100,
        })
    }

    /**
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#view_item
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#view_item
     */
    onProductView(cartItem: CartItem) {
        this.ecommerce('view_item', {
            currency: ServerManager.shop.currency,
            items: [this.cartItemToEcommerce(cartItem)],
            value: cartItem.getPrices().unitPrice / 100,
        })
    }

    /**
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#remove_from_cart
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#remove_from_cart
     */
    onRemoveFromCart(cartItem: CartItem) {
        this.ecommerce('remove_from_cart', {
            currency: ServerManager.shop.currency,
            items: [this.cartItemToEcommerce(cartItem)],
            value: cartItem.getPrices().price / 100,
        })
    }

    /**
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm#add_payment_info
     * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#add_payment_info
     */
    onSelectPaymentMethod(info: PaymentMethod): void {
        this.ecommerce('add_payment_info', {
            coupon: CartManager.checkout.discount?.code,
            currency: ServerManager.shop.currency,
            items: CartManager.cart.items.map(this.cartItemToEcommerce),
            value: CartManager.cart.getPrice() / 100,
            payment_type: info.type,
        })
    }

    revokeConsent() {
        // Prevent further custom events.
        // Unfortunately we need to reload to page to unload all tags
        window.dataLayer = undefined
    }

    private cartItemToEcommerce(item: CartItem, index?: number) {
        return {
            currency: ServerManager.shop.currency,
            index: index,
            item_id: item.id,
            item_name: item.product.getNameForI18n({locale: ServerManager.shop.language}),
            item_variant: item.getSelectedVariantName({locale: ServerManager.shop.language}),
            price: item.getPrices().unitPrice / 100,
            quantity: item.amount,
        }
    }

    /**
     * https://developers.google.com/analytics/devguides/collection/ga4/ecommerce?client_type=gtm
     */
    private ecommerce(event: string, data: Record<string, any>): void {
        this.send({ecommerce: null})
        this.send({
            event,
            ecommerce: data,
        })
    }

    /**
     * https://developers.google.com/tag-platform/tag-manager/web/datalayer
     */
    private send(event: Record<string, any>) {
        window.dataLayer?.push(event)
    }
}
