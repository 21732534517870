import {ArrayDecoder, AutoEncoder, DateDecoder, EnumDecoder, field, IntegerDecoder, StringDecoder} from '@simonbackx/simple-encoding'

import {Address} from './Address'
import {Cart} from './Cart'
import {ConsumptionOptionType} from './ConsumptionOptions'
import {Customer} from './Customer'
import {ExtraCost, ExtraCostSymbolType, ExtraCostType} from './ExtraCost'
import {PaymentDetails} from './PaymentDetails'
import {PaymentMethod, PaymentMethodType} from './PaymentMethod'
import {PaymentProvider} from './PaymentProvider'
import {Table} from './Table'
import {TableCategory} from './TableCategory'
import {TransactionFees} from './TransactionFees'
import {Discount} from './discounts/Discount'

export class Checkout extends AutoEncoder {
    @field({decoder: Cart})
    cart: Cart

    @field({decoder: Customer, optional: true})
    customer?: Customer

    @field({decoder: Table, optional: true})
    table?: Table

    @field({decoder: DateDecoder, nullable: true, version: 52})
    deliveryDate: (Date | null) = null

    @field({decoder: Address, version: 59, nullable: true, upgrade: () => null})
    deliveryAddress: Address | null = null

    @field({decoder: IntegerDecoder, version: 59, upgrade: () => 0})
    deliveryPrice = 0

    @field({decoder: StringDecoder, nullable: true, optional: true})
    deliveryCostPosProductId: string | null = null

    @field({decoder: new ArrayDecoder(ExtraCost), version: 76, defaultValue: () => []})
    extraCosts: Array<ExtraCost>

    @field({decoder: new ArrayDecoder(TableCategory), optional: true, version: 43})
    tableCategories?: Array<TableCategory>

    @field({decoder: new EnumDecoder(PaymentMethodType), optional: true})
    @field({
        decoder: new EnumDecoder(PaymentMethodType), version: 24, nullable: true, upgrade: (old) => {
            if (old === undefined) {
                return null
            }
            return old
        },
        downgrade: (n) => {
            if (n === null) {
                return undefined
            }
            return n
        },
    })
    @field({
        decoder: PaymentMethod, version: 41, nullable: true, upgrade: (old: PaymentMethodType | null) => {
            if (old === null) {
                return null
            }
            return PaymentMethod.create({type: old, transactionFees: TransactionFees.default(old, PaymentProvider.Paynl), provider: PaymentProvider.Paynl})
        }, downgrade: (n: PaymentMethod | null) => {
            if (n === null) {
                return null
            }
            return n.type
        },
    })
    paymentMethod: PaymentMethod | null = null

    @field({decoder: PaymentDetails, nullable: true, version: 42, optional: true})
    paymentDetails: PaymentDetails | null = null

    @field({decoder: IntegerDecoder, version: 23, upgrade: () => 0})
    tip = 0

    @field({decoder: new EnumDecoder(ConsumptionOptionType), version: 52, upgrade: () => ConsumptionOptionType.DineIn})
    consumptionMode: ConsumptionOptionType = ConsumptionOptionType.DineIn

    @field({decoder: Discount, nullable: true, optional: true})
    discount: Discount | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    description: string | null = null

    getPrice() {
        return this.cartPrice + this.tip + this.deliveryPrice + this.exclusiveExtraCost - this.getDiscount()
    }

    get cartPrice() {
        return this.cart.getPrice()
    }

    getDiscount() {
        if (!this.discount) {
            return 0
        }
        return this.discount.calculateForPrice(this.cartPrice)
    }

    get inclusiveExtraCosts() {
        return this.extraCosts?.filter(el => el.extraCostType === ExtraCostType.Inclusive)
    }

    get exclusiveExtraCosts() {
        return this.extraCosts?.filter(el => el.extraCostType === ExtraCostType.Exclusive)
    }

    get inclusiveExtraCost(): number {
        if (!this.inclusiveExtraCosts) {
            return 0
        }
        return this.inclusiveExtraCosts.reduce((total, cost) => {
            if (cost.symbol === ExtraCostSymbolType.Percentage) {
                return total + (this.cartPrice / 100) * (cost.value / 100)
            }
            return total + cost.value
        }, 0)
    }

    get exclusiveExtraCost(): number {
        if (!this.exclusiveExtraCosts) {
            return 0
        }
        return this.exclusiveExtraCosts.reduce((total, cost) => {
            if (cost.symbol == ExtraCostSymbolType.Percentage) {
                return total + (this.cartPrice / 100) * (cost.value / 100)
            }
            return total + cost.value
        }, 0)
    }
}
