var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DRSTBoxItem",
    {
      staticClass: "textarea-row",
      class: { "has-label": !!_vm.label },
      attrs: {
        "element-name": "label",
        selectable: !_vm.hasFocus && !_vm.disabled
      },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _c(
        "div",
        [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.text,
                expression: "text"
              }
            ],
            ref: "input",
            staticClass: "textarea-input-row",
            attrs: {
              disabled: _vm.disabled,
              placeholder: _vm.placeholder,
              autocomplete: _vm.autocomplete,
              autocorrect: "off"
            },
            domProps: { value: _vm.text },
            on: {
              input: [
                function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.text = $event.target.value
                },
                function($event) {
                  _vm.text = $event.target.value
                }
              ],
              focus: _vm.onFocus,
              blur: function($event) {
                return _vm.$emit("blur", $event)
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "resizing-text" }, [
            _vm._v("\n            " + _vm._s(_vm.text) + "\n        ")
          ]),
          _vm._v(" "),
          _vm._t("default")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }