var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view table-selection-view" },
    [
      _c("DRSTNavigationBar", { attrs: { title: _vm.title } }, [
        _c("button", {
          staticClass: "button icon back",
          attrs: { slot: "left" },
          on: { click: _vm.pop },
          slot: "left"
        })
      ]),
      _vm._v(" "),
      _c(
        "main",
        [
          _c("h1", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _vm.currentTable && !_vm.initialSelectionMode
            ? [
                _c(
                  "DRSTBox",
                  { attrs: { title: _vm.selectionName } },
                  [
                    _c(
                      "DRSTBoxItem",
                      {
                        staticClass: "table-row-selection",
                        attrs: { selectable: true, "element-name": "label" }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.tablePath) +
                            "\n                    "
                        ),
                        _c(
                          "div",
                          { attrs: { slot: "right" }, slot: "right" },
                          [
                            _c("Radio", {
                              attrs: {
                                value: _vm.currentTable,
                                name: "table-scanned"
                              },
                              model: {
                                value: _vm.currentTable,
                                callback: function($$v) {
                                  _vm.currentTable = $$v
                                },
                                expression: "currentTable"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.showSelect
            ? [
                _vm.tableCategoriesEnabled
                  ? [
                      _vm.hasTableCategories
                        ? _c(
                            "DRSTBox",
                            {
                              attrs: {
                                title:
                                  _vm.currentTable && !_vm.initialSelectionMode
                                    ? _vm.selectAnotherTitle
                                    : ""
                              }
                            },
                            _vm._l(_vm.selectedTableCategories, function(
                              category,
                              index
                            ) {
                              return _c(
                                "DRSTBoxItem",
                                {
                                  key: category.id,
                                  staticClass: "table-row-selection",
                                  class: {
                                    active: _vm.isActiveCategory(category)
                                  },
                                  attrs: {
                                    selectable: true,
                                    "element-name": "label"
                                  },
                                  on: {
                                    click: function($event) {
                                      index === 0
                                        ? _vm.popToMainCat()
                                        : _vm.popToCategory(category)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(category.name) +
                                      "\n                        "
                                  ),
                                  _c(
                                    "div",
                                    { attrs: { slot: "right" }, slot: "right" },
                                    [
                                      _c("button", {
                                        staticClass:
                                          "button icon arrow-down-simple"
                                      })
                                    ]
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "DRSTBox",
                        {
                          attrs: {
                            title:
                              _vm.currentTable &&
                              !_vm.hasTableCategories &&
                              !_vm.initialSelectionMode
                                ? _vm.selectAnotherTitle
                                : ""
                          }
                        },
                        [
                          _vm.hasChildCategories
                            ? _vm._l(_vm.childCategories, function(category) {
                                return _c(
                                  "DRSTBoxItem",
                                  {
                                    key: category.id,
                                    staticClass: "table-row-selection",
                                    class: {
                                      active: _vm.isActiveCategory(category)
                                    },
                                    attrs: {
                                      selectable: true,
                                      "element-name": "label"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.pushCategory(category, true)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(category.name) +
                                        "\n                            "
                                    ),
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "right" },
                                        slot: "right"
                                      },
                                      [
                                        _c("button", {
                                          staticClass: "button icon disclosure"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasChildTables
                            ? _vm._l(_vm.childTables, function(table) {
                                return _c(
                                  "DRSTBoxItem",
                                  {
                                    key: table.id,
                                    staticClass: "table-row-selection",
                                    class: { active: _vm.isActiveTable(table) },
                                    attrs: {
                                      selectable: true,
                                      "element-name": "label"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(table.name) +
                                        "\n                            "
                                    ),
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "right" },
                                        slot: "right"
                                      },
                                      [
                                        _c("Radio", {
                                          attrs: {
                                            name: "table",
                                            value: table
                                          },
                                          on: {
                                            change: function($event) {
                                              _vm.currentTableCategories = [].concat(
                                                _vm.selectedTableCategories
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.currentTable,
                                            callback: function($$v) {
                                              _vm.currentTable = $$v
                                            },
                                            expression: "currentTable"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              })
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  : [
                      _c(
                        "DRSTBox",
                        {
                          attrs: {
                            title:
                              _vm.currentTable && !_vm.initialSelectionMode
                                ? _vm.selectAnotherTitle
                                : ""
                          }
                        },
                        _vm._l(_vm.filteredTableCategories[0].tables, function(
                          table
                        ) {
                          return _c(
                            "DRSTBoxItem",
                            {
                              key: table.id,
                              staticClass: "table-row-selection",
                              class: { active: _vm.isActiveTable(table) },
                              attrs: {
                                selectable: true,
                                "element-name": "label"
                              }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(table.name) +
                                  "\n                        "
                              ),
                              _c(
                                "div",
                                { attrs: { slot: "right" }, slot: "right" },
                                [
                                  _c("Radio", {
                                    attrs: { name: "table", value: table },
                                    model: {
                                      value: _vm.currentTable,
                                      callback: function($$v) {
                                        _vm.currentTable = $$v
                                      },
                                      expression: "currentTable"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        }),
                        1
                      )
                    ]
              ]
            : [
                _c(
                  "div",
                  {
                    staticClass: "show-selection-button",
                    on: { click: _vm.showSelected }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.selectAnotherTitle) +
                        "\n            "
                    )
                  ]
                )
              ]
        ],
        2
      ),
      _vm._v(" "),
      _vm.currentTable
        ? _c("DRSTFloatingFooter", [
            _c(
              "button",
              { staticClass: "button primary fill", on: { click: _vm.save } },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.buttonText ? _vm.buttonText : _vm.$t("general.save")
                    ) +
                    "\n        "
                )
              ]
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }