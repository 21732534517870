var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DRSTBox",
    _vm._l(_vm.productVariants, function(variant) {
      return _c(
        "DRSTBoxItem",
        {
          key: variant.id,
          staticClass: "product-variant-row",
          attrs: { selectable: true, "element-name": "label" }
        },
        [
          _vm._v(
            "\n        " + _vm._s(_vm.translate(variant.name)) + "\n        "
          ),
          _c(
            "div",
            {
              staticClass: "product-variant-row-right",
              attrs: { slot: "right" },
              slot: "right"
            },
            [
              _vm.getPriceForVariant(variant) !== null
                ? _c("span", [_vm._v(_vm._s(_vm.getPriceForVariant(variant)))])
                : _vm._e(),
              _vm._v(" "),
              _vm.editable
                ? _c("Radio", {
                    attrs: {
                      value: variant.id,
                      "model-value": _vm.value,
                      name: "variants_radios"
                    },
                    on: { change: _vm.setSelectedVariant }
                  })
                : _vm._e()
            ],
            1
          )
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }