import {ArrayDecoder, AutoEncoder, Data, Decoder, Encodeable, EncodeContext, field, IntegerDecoder, StringDecoder} from '@simonbackx/simple-encoding'

export class LPaginatedResponse<Result extends Encodeable> implements Encodeable {
    results: Array<Result>

    constructor(data: {results: Array<Result>}) {
        this.results = data.results
    }

    encode(context: EncodeContext) {
        return {
            results: this.results.map(r => r.encode(context)),
        }
    }
}

export class LPaginatedResponseDecoder<Result extends Encodeable> implements Decoder<LPaginatedResponse<Result>> {
    resultDecoder: Decoder<Result>

    constructor(resultDecoder: Decoder<Result>) {
        this.resultDecoder = resultDecoder
    }

    decode(data: Data) {
        return new LPaginatedResponse<Result>({
            results: data.field('results').array(this.resultDecoder),
        })
    }
}

export class LTable extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    id: number

    @field({decoder: StringDecoder})
    name: string

    @field({decoder: StringDecoder})
    type: string

}

export class LFloor extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    id: number

    @field({decoder: StringDecoder})
    name: string

    @field({decoder: new ArrayDecoder(LTable)})
    tables: Array<LTable>
}

