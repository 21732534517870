var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.locked
    ? _c("span", {
        staticClass: "icon lock",
        class: {
          "margin-start": _vm.marginPosition === "start",
          "margin-end": _vm.marginPosition === "end",
          "margin-both": _vm.marginPosition === "both"
        },
        attrs: {
          alt: _vm.$t("general.onlyEditableInPosSystem", {
            posSystem: _vm.meta.posType
          }),
          title: _vm.$t("general.onlyEditableInPosSystem", {
            posSystem: _vm.meta.posType
          })
        }
      })
    : _c("img", {
        staticClass: "pos-icon",
        class: {
          "margin-start": _vm.marginPosition === "start",
          "margin-end": _vm.marginPosition === "end",
          "margin-both": _vm.marginPosition === "both"
        },
        attrs: {
          src: _vm.posSystemIcon.src,
          alt: _vm.posSystemIcon.alt,
          title: _vm.$t("general.linkedToPosSystem", {
            posSystem: _vm.posSystemIcon.alt
          })
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }