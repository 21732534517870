var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DRSTBox",
    _vm._b({}, "DRSTBox", _vm.$attrs, false),
    [
      _c("template", { slot: "right" }, [_vm._t("right")], 2),
      _vm._v(" "),
      _c("template", { slot: "annotation" }, [_vm._t("annotation")], 2),
      _vm._v(" "),
      _vm.items.length > _vm.maxItems
        ? _c(
            "TextInputRow",
            {
              ref: "input",
              attrs: {
                autocomplete: "search",
                placeholder: _vm.$t("customer.search.placeholder"),
                type: "search"
              },
              model: {
                value: _vm.query,
                callback: function($$v) {
                  _vm.query = $$v
                },
                expression: "query"
              }
            },
            [
              _c("span", {
                staticClass: "icon search",
                attrs: { slot: "left" },
                slot: "left"
              }),
              _vm._v(" "),
              _c("button", {
                staticClass: "button icon close",
                attrs: { slot: "right" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.clear($event)
                  }
                },
                slot: "right"
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.filteredItems, function(item) {
        return [_vm._t("default", null, { item: item })]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }