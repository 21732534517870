

















import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {ContextMenu, DRSTBoxItem} from '@dorst/components'

@Component({
    components: {
        ContextMenu,
        DRSTBoxItem,
    },
})
export default class AddressContextMenu extends Mixins(NavigationMixin) {
    @Prop({default: 0})
    x!: number

    @Prop({default: 0})
    y!: number

    @Prop()
    onEdit!: () => void

    @Prop()
    onDelete!: () => void
}
