














































































































































































import {ObjectData} from '@simonbackx/simple-encoding'
import {
    ComponentWithProperties,
    HistoryManager,
    NavigationController,
    NavigationMixin,
} from '@simonbackx/vue-app-navigation'
import {Meta} from '@sophosoft/vue-meta-decorator'
import {DateTime} from 'luxon'
import {Component, Mixins, Prop, Watch} from 'vue-property-decorator'

import {AnalyticsController} from '../analytics/AnalyticsController'
import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import {TealiumManager} from '../classes/TealiumManager'
import {environment} from '../environments/environment'
import {PruneHelper} from '../helpers/PruneHelper'
import CartView from './CartView.vue'
import CategoryView from './CategoryView.vue'
import ConsumptionModeSelectionContextMenu from './ConsumptionModeSelectionContextMenu.vue'
import LanguageSwitcher from './LanguageSwitcher.vue'
import OfferCard from './OfferCard.vue'
import ProductBoxItem from './ProductBoxItem.vue'
import ProductView from './ProductView.vue'
import SearchView from './SearchView.vue'
import ShopMessageView from './ShopMessageView.vue'
import {
    DRSTBox,
    DRSTBoxItem,
    DRSTExternalLinksFooter,
    DRSTFloatingFooter,
    DRSTList,
    DRSTNavigationBar,
    LoadingCover,
    SegmentedControl,
} from '@dorst/components'
import {applyRtlWindow, i18n} from '@dorst/frontend-translations'
import {isNullOrEmpty} from '@dorst/helpers'
import {
    CartItem,
    Category,
    ConsumptionOptionType,
    ConsumptionOptionTypeHelper,
    DeliveryConsumptionOption,
    DineInConsumptionOption,
    LandingRedirectInfo,
    Language,
    Product,
    ShopFull,
    TakeAwayConsumptionOption,
    TranslatedShopMessage,
    Version,
} from '@dorst/structures'

@Component({
    components: {
        DRSTNavigationBar,
        DRSTList,
        DRSTBox,
        DRSTBoxItem,
        ProductBoxItem,
        DRSTFloatingFooter,
        DRSTExternalLinksFooter,
        LoadingCover,
        SegmentedControl,
        OfferCard,
        LanguageSwitcher,
    },
})
export default class ShopView extends Mixins(NavigationMixin) {
    CartManager = CartManager

    @Meta
    getMetaInfo() {
        return {
            title: this.shop.name,
        }
    }

    @Prop()
    callback?: (shop: ShopFull) => void

    @Prop()
    redirectData?: LandingRedirectInfo

    @Prop()
    defaultSelectedCategory?: Category

    lastSync = new Date()
    selectedCategorySaved: Category | null = null

    private forcePasswordUpdate = 0

    mounted() {
        if (this.defaultSelectedCategory) {
            this.selectedCategorySaved = this.defaultSelectedCategory
        }
        HistoryManager.setUrl(`/${this.shop.uri}`)
        this.checkMessage()
        this.checkTimeSlotMessage().catch(e => console.error(e))
        this.triggerFacebookPixelEvent()
    }

    async activated() {
        if (this.lastSync <= new Date(new Date().getTime() - 60 * 1000)) {
            this.lastSync = new Date()
            await ServerManager.loadShop().then(shop => {
                this.checkMessage()
            }).catch(console.error)
        }
    }

    get hasExternalLinksEnabled(): boolean {
        return this.shop.externalLinks?.enabled === true
    }

    get hasRedirect() {
        return this.redirectData?.redirectUrl ? true : false
    }

    get showSegmentedCategoriesControl() {
        return this.shopCategories.length <= 3 && this.shopCategories.length >= 2
    }

    triggerFacebookPixelEvent() {
        if (this.showSegmentedCategoriesControl) {
            AnalyticsController.get().event('onHomepageViewWithSegmentedControl', this.selectedProducts)
        } else {
            AnalyticsController.get().event('onHomepageView', this.shopCategories)
        }
    }

    redirectBack() {
        if (!this.redirectData) {
            return
        }
        window.location.assign(this.redirectData.redirectUrl)
    }

    beforeDestroy() {
        if (this.callback) {
            this.callback(this.shop)
        }
    }

    showCustomerMessage(customerMessage: TranslatedShopMessage) {
        localStorage.setItem(`lastCustomerMessage-${this.shop.id}`, JSON.stringify({
            lastShownAt: new Date(),
            lastUpdatedAt: customerMessage.lastUpdatedAt,
        }))
        this.present(new ComponentWithProperties(ShopMessageView, {message: this.shop.customerMessage}))
    }

    checkMessage() {
         if (this.shop.customerMessage !== null && this.shop.customerMessage.isEnabled) {
            const customerMessageKey = localStorage.getItem(`lastCustomerMessage-${this.shop.id}`)
            if (!isNullOrEmpty(customerMessageKey)) {
                try {
                    const parsedMessageKey = JSON.parse(customerMessageKey)
                    const lastShownDate = new Date(parsedMessageKey.lastShownAt)
                    const lastUpdatedAtDate = parsedMessageKey.lastUpdatedAt === undefined
                        ? undefined
                        : new Date(parsedMessageKey.lastUpdatedAt)
                    // Do not show message if it has not changed & we showed the message within the last 24hrs.
                    if (
                        this.shop.customerMessage.lastUpdatedAt?.getTime() === lastUpdatedAtDate?.getTime()
                        && DateTime.fromJSDate(lastShownDate).diffNow('hours').hours > -24
                    ) {
                        return
                    }
                } catch (error) {
                    console.error('Failed to parse customerMessageKey, showing anyway.', error)
                }
            }
            setTimeout(() => {
                this.showCustomerMessage(this.shop.customerMessage!)
            }, 300)
        }
    }

    get shopMessageTitle(): string | undefined {
        return this.shop.shopMessage?.isEnabled === true
            ? this.shop.shopMessage.title?.getForI18n(this.$i18n as any)
            : undefined
    }

    get shopMessageText() {
        return this.shop.shopMessage?.isEnabled === true
            ? this.shop.shopMessage.text?.getForI18n(this.$i18n as any)
            : undefined
    }

    // Check if timeslots are still available today
    async checkTimeSlotMessage() {
        const isDineIn = CartManager.checkout.consumptionMode === ConsumptionOptionType.DineIn
        let availabilityConsumptionOption: TakeAwayConsumptionOption | DeliveryConsumptionOption | DineInConsumptionOption
        switch (CartManager.checkout.consumptionMode) {
            case ConsumptionOptionType.DineIn:
                availabilityConsumptionOption = this.shop.consumptionOptions.dineIn
                break
            case ConsumptionOptionType.TakeAway:
                availabilityConsumptionOption = this.shop.consumptionOptions.takeAway
                break
            case ConsumptionOptionType.Delivery:
                availabilityConsumptionOption = this.shop.consumptionOptions.delivery
                break
        }

        const hasConsumptionOptionAvailabilities = !availabilityConsumptionOption.isClosedOn(new Date(), this.shop.timezone)

        // Don't display a notice if there are available slots for the consumption mode, or, specifically for dine-in,
        // if the shop is set up not to use opening hours and you can order at the moment.
        if (
            hasConsumptionOptionAvailabilities
            || (
                isDineIn
                && !this.shop.consumptionOptions.dineIn.enableDineInOpeningHours
                && CartManager.orderingAllowedForCustomerType
                && this.shop.enableOrdering
            )
        ) {
            return
        }

        // For non-dine-in modes, check if there are any available slots
        if (!isDineIn) {
            const {timeslots} = await CartManager.getDayWithTimeslots(CartManager.checkout.consumptionMode, new Date())

            // Still possible to order for today
            if (timeslots.length > 0) {
                return
            }
        }
        if (availabilityConsumptionOption.todayUnavailableMessage?.isEnabled !== true) {
            return
        }
        // show message
        const todayUnavailableMessageText = availabilityConsumptionOption.todayUnavailableMessage.text.getForI18nStrict(this.$i18n)
        const todayUnavailableMessageTitle = availabilityConsumptionOption.todayUnavailableMessage.title.getForI18nStrict(this.$i18n)
        const hasTodayUnavailableMessage = !isNullOrEmpty(todayUnavailableMessageText)

        const presentOptions = {
            titleOverride: todayUnavailableMessageTitle ?? '',
            textOverride: hasTodayUnavailableMessage ? todayUnavailableMessageText : this.getEditUnavailableMessageTextPlaceholder(CartManager.checkout.consumptionMode),
        }
        this.present(new ComponentWithProperties(ShopMessageView, presentOptions))
    }

    getEditUnavailableMessageTextPlaceholder(consumptionOption: ConsumptionOptionType): string {
        switch (consumptionOption) {
            case ConsumptionOptionType.Delivery:
                return this.$t(`backoffice.settings.unavailableMessages.textPlaceHolderDelivery`).toString()
            case ConsumptionOptionType.DineIn:
                return this.$t(`backoffice.settings.unavailableMessages.textPlaceHolderDineIn`).toString()
            case ConsumptionOptionType.TakeAway:
                return this.$t(`backoffice.settings.unavailableMessages.textPlaceHolderTakeAway`).toString()
            default:
                return ''
        }
    }

    get hasPassword() {
        if (this.forcePasswordUpdate > 0) {
            // hacky hacks
        }
        return ServerManager.hasPassword()
    }

    get shop() {
        return ServerManager.shop
    }

    get logo() {
        if (this.shop.personalisation?.hideLogoOnMenu ?? true) {
            return null
        }
        return this.shop.personalisation?.logo?.getPathForSize(undefined, 70 * (window.devicePixelRatio ?? 1))
    }

    get logoIncludesShopName(): boolean {
        if (this.shop.personalisation?.hideLogoOnMenu ?? true) {
            return false
        }
        return this.shop.personalisation?.logoIncludesShopName ?? false
    }

    get selectedCategory(): Category | null {
        if (this.selectedCategorySaved !== null) {
            return this.selectedCategorySaved
        }
        return this.shopCategories[0] ?? null
    }

    set selectedCategory(category: Category | null) {
        if (category !== this.selectedCategorySaved) {
            this.selectedCategorySaved = category
        }
    }

    @Watch('selectedCategory')
    onCategorySwitch() {
        if (environment.features.tealium && this.selectedCategory) {
            void TealiumManager.onCategoryView(this.$i18n as any, this.selectedCategory)
        }
        if (this.selectedCategory !== null) {
            AnalyticsController.get().event('onCategoryView', this.selectedCategory, this.selectedProducts)
        }
    }

    get shopCategories(): Array<Category> {
        return PruneHelper.filterCategories(this.shop, this.consumptionMode)
    }

    get selectedCategories() {
        const categories = this.shopCategories
        if (categories.length >= 2 && categories.length <= 3 && this.selectedCategory) {
            return PruneHelper.filterCategories(this.selectedCategory, this.consumptionMode)
        }
        if (categories.length == 1) {
            return PruneHelper.filterCategories(categories[0], this.consumptionMode)
        }
        return categories
    }

    get selectedProducts() {
        const categories = this.shopCategories
        if (categories.length >= 2 && categories.length <= 3 && this.selectedCategory) {
            return PruneHelper.filterProducts(this.selectedCategory, this.consumptionMode)
        }
        if (categories.length == 1) {
            return PruneHelper.filterProducts(categories[0], this.consumptionMode)
        }
        return []
    }

    get categoriesLabels() {
        return this.shopCategories.map(c => c.getNameForI18n(this.$i18n as any))
    }

    goToProduct(product: Product) {
        const {cartItem, subItems} = CartItem.defaultForProduct(product, this.shop.getLinkedProducts(product))
        this.show(new ComponentWithProperties(ProductView, {cartItem, shop: this.shop, subCartItems: subItems}))
    }

    goToCategory(category: Category) {
        this.show(new ComponentWithProperties(CategoryView, {category, shop: this.shop}))
    }

    goToSearch() {
        this.present(new ComponentWithProperties(SearchView, {shop: this.shop}))
    }

    next() {
        this.present(new ComponentWithProperties(NavigationController, {root: new ComponentWithProperties(CartView)}))
    }

    get consumptionMode(): ConsumptionOptionType {
        return this.CartManager.getConsumptionMode()
    }

    get enabledConsumptionModes(): Array<ConsumptionOptionType> {
        return this.shop.consumptionOptions.getEnabledConsumptionOptions()
    }

    get showConsumptionModeSelector(): boolean {
        return this.enabledConsumptionModes.length > 1 || this.consumptionMode !== ConsumptionOptionType.DineIn
    }

    getModeName(mode: ConsumptionOptionType) {
        return ConsumptionOptionTypeHelper.getName(mode, this.$i18n as any)
    }

    getCategoryName(product): string {
        return product.getNameForI18n(this.$i18n as any)
    }

    removePassword(): void {
        ServerManager.removePassword()
        this.forcePasswordUpdate++
    }

    selectConsumptionMode(event) {
        if (this.enabledConsumptionModes.length <= 1) {
            return
        }

        const displayedComponent = new ComponentWithProperties(
            ConsumptionModeSelectionContextMenu,
            {
                x: applyRtlWindow(event.clientX),
                y: event.clientY + 10,
                mode: this.CartManager.getConsumptionMode(),
                modes: this.enabledConsumptionModes,
                setMode: (mode: ConsumptionOptionType) => {
                    if (this.CartManager.getConsumptionMode() === mode) {
                        return
                    }
                    console.info('Cart deleted because consumption mode doesn\'t match current consumptionMode')
                    this.CartManager.clear()
                    this.CartManager.setConsumptionMode(mode)
                    this.checkTimeSlotMessage().catch(e => console.error(e))
                    this.selectedCategorySaved = null
                },
            },
        )
        this.present(displayedComponent.setDisplayStyle('overlay'))
    }

    get hideOrderButtons() {
        return this.shop.hideOrderButtonsWhenOrderSystemIsOff
            || !CartManager.orderingAllowedForCustomerType
    }

    get scoverLink() {
        if (this.$i18n.locale.substr(0, 2) == Language.Dutch && this.shop.scoverId && this.shop.scoverId != '' && this.shop.scoverId.length == 6) {
            return `https://menu.scover.nl/?id=${this.shop.scoverId}`
        }
        return null
    }

    openExternalUrl(url) {
        const win = window.open(url, '_blank')
        if (win) {
            win.focus()
        }
    }
}
