















































































































































































































































































































































































































import {isSimpleError, isSimpleErrors, SimpleErrors} from '@simonbackx/simple-errors'
import {ComponentWithProperties, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {debounce} from 'debounce'
import {Component, Mixins} from 'vue-property-decorator'

import {Cashless} from '../actions/Cashless'
import {order} from '../actions/order.action'
import {AnalyticsController} from '../analytics/AnalyticsController'
import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import {OpeningHourHelper} from '../helpers/OpeningHourHelper'
import {PaymentMethodHelper} from '../helpers/PaymentMethodHelper'
import {PriceHelper} from '../helpers/PriceHelper'
import DiscountSelectionView from './DiscountSelectionView.vue'
import InnovendPollingView from './InnovendPollingView.vue'
import OrderPosValidationPollingView from './OrderPosValidationPollingView.vue'
import OrderView from './OrderView.vue'
import TopupSelectionView from './TopupSelectionView.vue'
import {
    Checkbox,
    DateInput,
    DRSTBox,
    DRSTBoxItem,
    DRSTFloatingFooter,
    DRSTFooterBox,
    DRSTNavigationBar,
    Radio,
    RemarkBlock,
    Spinner,
    SpinnerButton,
    Stepper,
    TextareaRow,
    TextInputRow,
} from '@dorst/components'
import {defaultLocale} from '@dorst/frontend-helpers'
import {Loadable} from '@dorst/helpers'
import {
    ConsumptionOptionType,
    ConsumptionOptionTypeHelper,
    Discount,
    DiscountDetails,
    DiscountMode,
    ExtraCost,
    PaymentDetails,
    PaymentMethod,
    PaymentMethodType,
    PaymentProvider,
    TranslatedString,
    VerificationMethod,
} from '@dorst/structures'
import {Formatter, Validator} from '@dorst/validation'

function redirectPost(url, data) {
    const form = document.createElement('form')
    document.body.appendChild(form)
    form.method = 'post'
    form.action = url
    for (const name in data) {
        const input = document.createElement('input')
        input.type = 'hidden'
        input.name = name
        input.value = data[name]
        form.appendChild(input)
    }
    form.submit()
}

@Component({
    components: {
        Checkbox,
        DRSTBox,
        RemarkBlock,
        DRSTBoxItem,
        DRSTFloatingFooter,
        DRSTFooterBox,
        DRSTNavigationBar,
        DateInput,
        Radio,
        Spinner,
        SpinnerButton,
        Stepper,
        TextInputRow,
        TextareaRow,
    },
})
export default class PaymentSelectionView extends Mixins(NavigationMixin) {
    VerificationMethod = VerificationMethod

    get checkout() {
        return CartManager.checkout
    }

    loading = false
    editingTip: string | null = null
    error: string | null = null

    cardExists = new Loadable<boolean>()
    cardNeedsVerification = new Loadable<boolean>()
    cardBalance = new Loadable<number>()

    didAcceptPolicies = false
    currentPolicyVersion = 2
    termsPolicy = false
    privacyPolicy = false

    forceUpdateCanOrder = 0
    checkOpeningHours: ReturnType<typeof OpeningHourHelper.getStartStop>

    debouncedOnCardIDChanged: () => void
    debouncedOnVerificationChanged: () => void

    selectPaymentMethod(method: PaymentMethod) {
        this.paymentMethod = method
        AnalyticsController.get().event('onSelectPaymentMethod', method)
    }

    shouldBeDisabled(method: PaymentMethod) {
        return this.cartPrice < (method.type === PaymentMethodType.PointOfSale ? this.minOrderAmountWaiter : this.minOrderAmountOnline)
    }

    created() {
        this.ensureSelectedPaymentMethod()
        this.applyExtraCosts()

        this.debouncedOnCardIDChanged = debounce(this.onCardIDChanged, 500)
        this.debouncedOnVerificationChanged = debounce(this.onVerificationChanged, 500)
    }

    mounted() {
        const lastPolicyVersionAccepted = localStorage.getItem('lastPolicyVersionAccepted')
        if (lastPolicyVersionAccepted) {
            if (parseInt(lastPolicyVersionAccepted) === this.currentPolicyVersion) {
                this.didAcceptPolicies = true
            }
        }

        this.checkOpeningHours = OpeningHourHelper.getStartStop(() => this.forceUpdateCanOrder++)
        this.checkOpeningHours.start()
    }

    activated() {
        this.loading = false
        this.applyExtraCosts()

        this.onCardIDChanged()
        this.onVerificationChanged()
    }

    beforeDestroy() {
        this.checkOpeningHours.stop()
    }

    get cartPrice() {
        return this.checkout.cart.getPrice()
    }

    get hasPayconic(): boolean {
        return this.paymentMethods.some(el => el.type === PaymentMethodType.Payconiq)
    }

    ensureSelectedPaymentMethod() {
        let paymentMethods = ServerManager.shop.paymentMethods

        if (paymentMethods.some(({settings}) => settings?.hideOtherPaymentMethods)) {
            paymentMethods = paymentMethods.filter(({settings}) => settings?.hideOtherPaymentMethods)
        }

        if (!paymentMethods.some(({type}) => type === PaymentMethodType.PointOfSale) && ServerManager.hasPassword()) {
            paymentMethods.push(PaymentMethod.create({
                type: PaymentMethodType.PointOfSale,
                provider: PaymentProvider.None,
            }))
        }

        // This needs to happen in created before anything is made reactive
        if (this.checkout.paymentMethod === null) {
            this.checkout.paymentMethod = paymentMethods[0]
        } else {
            const predicate = this.checkout.paymentMethod.type === PaymentMethodType.PointOfSale
                ? (m: PaymentMethod) => m.type === PaymentMethodType.PointOfSale
                : (m: PaymentMethod) => m.id === this.checkout.paymentMethod?.id

            const m = paymentMethods.find(predicate)
            if (m === undefined) {
                // Not found => reset
                this.checkout.paymentMethod = paymentMethods[0]
            } else {
                this.checkout.paymentMethod = m
            }
        }

        if (!(ServerManager.shop.enableTips && this.checkout.paymentMethod.type != PaymentMethodType.PointOfSale)) {
            this.checkout.tip = 0
        }

        if (this.shouldBeDisabled(this.checkout.paymentMethod)) {
            const method = this.paymentMethods.find(m => !this.shouldBeDisabled(m))
            if (method) {
                this.selectPaymentMethod(method)
            }
        }
    }

    getName(method: PaymentMethod) {
        return method.getName(this.$i18n as any, this.checkout.consumptionMode, this.hasPayconic)
    }

    getImages(method: PaymentMethod): Array<string> {
        return PaymentMethodHelper.getImages(method, this.hasPayconic)
    }

    get minOrderAmountOnline() {
        return ServerManager.shop.consumptionOptions[ConsumptionOptionTypeHelper.getSlug(CartManager.checkout.consumptionMode)].minOrderAmountOnlinePayment
    }

    get minOrderAmountWaiter() {
        return ServerManager.shop.consumptionOptions[ConsumptionOptionTypeHelper.getSlug(CartManager.checkout.consumptionMode)].minOrderAmountWaiterPayment
    }

    getMinOrderAmountForPayment(method: PaymentMethod) {
        return method.type == PaymentMethodType.PointOfSale
            ? this.getMinimumSuffix(this.minOrderAmountWaiter)
            : this.getMinimumSuffix(this.minOrderAmountOnline)
    }

    formatExtraCostValue(extraCost: ExtraCost) {
        return extraCost.format({
            cartPrice: this.cartPrice,
            currency: ServerManager.shop.currency,
            locale: defaultLocale,
        })
    }

    /**
     * Returns the minimum price suffix (if needed)
     */
    getMinimumSuffix(price: number) {
        return price > this.cartPrice
            ? `(min: ${PriceHelper.format({
                price,
                forceDisplay: true,
            })})`
            : ''
    }

    get shop() {
        return ServerManager.shop
    }

    get paymentMethods() {
        let paymentMethods = ServerManager.shop.paymentMethods

        if (paymentMethods.some(({settings}) => settings?.hideOtherPaymentMethods)) {
            paymentMethods = paymentMethods.filter(({settings}) => settings?.hideOtherPaymentMethods)
        }

        if (!paymentMethods.some(({type}) => type === PaymentMethodType.PointOfSale) && ServerManager.hasPassword()) {
            paymentMethods.push(PaymentMethod.create({
                type: PaymentMethodType.PointOfSale,
                provider: PaymentProvider.None,
            }))
        }

        return paymentMethods
    }

    get exclusiveExtraCosts(): Array<ExtraCost> {
        return this.checkout.exclusiveExtraCosts
    }

    get canTipRounded() {
        return this.checkout.getPrice() % 100 != 0
    }

    roundTip() {
        this.checkout.tip += 100 - this.checkout.getPrice() % 100
        this.save()
    }

    get paymentMethod() {
        return this.checkout.paymentMethod
    }

    set paymentMethod(method: PaymentMethod | null) {
        this.checkout.paymentMethod = method
        this.onPaymentMethodChanged()
    }

    get cardID() {
        return this.checkout.paymentDetails?.cardID ?? ''
    }

    set cardID(cardID: string) {
        if (this.checkout.paymentDetails === null) {
            this.checkout.paymentDetails = PaymentDetails.create({cardID})
        } else {
            this.checkout.paymentDetails.cardID = cardID
        }

        this.debouncedOnCardIDChanged()
    }

    get cardIDPlaceholder() {
        const cardIDPlaceholder = this.checkout.paymentMethod?.settings?.cardIDPlaceholder
        return cardIDPlaceholder ? cardIDPlaceholder : '000000000'
    }

    get cardIDDescription() {
        const cardIDDescription = this.checkout.paymentMethod?.settings?.cardIDDescription
        return cardIDDescription ? cardIDDescription : this.$i18n.t('customer.payment.cardIDDescription')
    }

    get verification() {
        return this.checkout.paymentDetails?.verification ?? ''
    }

    set verification(verification: string) {
        if (this.checkout.paymentDetails === null) {
            this.checkout.paymentDetails = PaymentDetails.create({verification})
        } else {
            this.checkout.paymentDetails.verification = verification
        }

        this.debouncedOnVerificationChanged()
    }

    onPaymentMethodChanged() {
        // Set tip to 0 if Pay at POS
        if (this.checkout.paymentMethod?.type == PaymentMethodType.PointOfSale) {
            this.checkout.tip = 0
        }

        // Reset CardID
        if (this.checkout.paymentDetails) {
            this.checkout.paymentDetails.cardID = ''
        }

        // Clear errors & cashless data
        this.error = null
        this.cardExists.reset()
        this.cardNeedsVerification.reset()
        this.cardBalance.reset()
    }

    onCardIDChanged() {
        if (!this.isCashless) {
            return
        }

        this.error = null

        if (this.cardID) {
            this.loadCardExists()
            this.loadCardNeedsVerification()
            this.loadCardBalance()
        } else {
            this.cardExists.reset()
            this.cardNeedsVerification.reset()
            this.cardBalance.reset()
        }
    }

    onVerificationChanged() {
        if (!this.cardNeedsVerification.value || !this.verification) {
            return
        }

        if (this.verificationMethod === VerificationMethod.Birthdate) {
            if (!Validator.birthdate(this.verification)) {
                return
            }
        }

        if (!this.cardBalance.value) {
            this.loadCardBalance()
        }
    }

    loadCardExists() {
        this.cardExists.load(async (ct) => {
            if (!this.cashless) {
                return null
            }

            if (!Validator.cardID(this.cardID, this.cashless.paymentMethod.settings?.cardIDRegex)) {
                return false
            }

            try {
                return (await this.cashless?.CardExists(this.cardID)).value
            } catch (e) {
                if (!ct.isCancelled()) {
                    if (isSimpleError(e) || isSimpleErrors(e)) {
                        console.log('loadCardExists', e.getHuman())
                        this.error = e.getHuman()
                        window.scrollTo(0, 0)
                    }
                }
                return null
            }
        })
    }

    loadCardNeedsVerification() {
        this.cardNeedsVerification.load(async (ct) => {
            if (!this.cashless) {
                return null
            }

            try {
                return (await this.cashless?.CardNeedsVerification(this.cardID)).value
            } catch (e) {
                if (!ct.isCancelled()) {
                    if (isSimpleError(e) || isSimpleErrors(e)) {
                        console.log('loadCardNeedsVerification', e.getHuman())
                        this.error = e.getHuman()
                        window.scrollTo(0, 0)
                    }
                }
                return null
            }
        })
    }

    loadCardBalance() {
        this.cardBalance.load(async (ct) => {
            if (!this.cashless) {
                return null
            }

            try {
                return (await this.cashless?.GetBalance(this.cardID, this.verification)).value
            } catch (e) {
                return null
            }
        })
    }

    get isCashless(): boolean {
        return this.paymentMethod?.isCashless() ?? false
    }

    get cashlessInputMode(): 'numeric' | 'text' {
        if (this.paymentMethod?.settings?.starnetUIDMode) {
            return 'text'
        }
        return 'numeric'
    }

    get cashless(): Cashless | null {
        return this.checkout.paymentMethod && this.isCashless ? new Cashless(ServerManager.shop.id, this.checkout.paymentMethod) : null
    }

    get verificationMethod(): VerificationMethod {
        return this.checkout.paymentMethod?.settings?.verificationMethod ?? VerificationMethod.None
    }

    get hasBirthdateVerification(): boolean {
        return this.verificationMethod === VerificationMethod.Birthdate
    }

    get enableTips() {
        return ServerManager.shop.enableTips && this.checkout.paymentMethod?.type != PaymentMethodType.PointOfSale
    }

    get editTipString() {
        if (this.editingTip !== null) {
            return this.editingTip
        }
        return PriceHelper.format({
            price: this.checkout.tip,
            forceDisplay: true,
        })
    }

    set editTipString(price: string) {
        this.editingTip = price

        price = price.replace(/[^0-9\.,]+/g, '')
        if (!price.includes('.')) {
            price = price.replace(',', '.')
        }

        const v = parseFloat(price)
        if (isNaN(v)) {
            return
        }
        this.checkout.tip = Math.round(v * 100)
        this.save()
    }

    get enableTopup() {
        return this.checkout.paymentMethod?.settings?.enableTopup ?? false
    }

    get newBalance() {
        return (this.cardBalance.value ?? 0) - this.checkout.getPrice()
    }

    get sufficientBalance() {
        return this.newBalance >= 0
    }

    cardIDFocus() {
        const cardIDRegex = this.checkout.paymentMethod?.settings?.cardIDRegex
        if (!cardIDRegex) {
            return
        }

        const prefix = cardIDRegex.match(/^\^([\w\d]+)/)
        if (prefix && this.cardID === '') {
            this.cardID = prefix[1]
        }
    }

    cardIDBlur() {
        const cardIDRegex = this.checkout.paymentMethod?.settings?.cardIDRegex
        if (!cardIDRegex) {
            return
        }

        const prefix = cardIDRegex.match(/^\^([\w\d]+)/)
        if (prefix && this.cardID === prefix[1]) {
            this.cardID = ''
        }
    }

    onBlurTip() {
        this.editingTip = null
        this.save()
    }

    save() {
        CartManager.save()
    }

    beforeNext(next: () => void) {
        if (this.loading) {
            return
        }

        if (!this.didAcceptPolicies && (!this.termsPolicy || !this.privacyPolicy)) {
            this.error = this.$i18n.t('customer.details.errors.policy').toString()
            return
        }
        // Policies accepted!
        localStorage.setItem('lastPolicyVersionAccepted', String(this.currentPolicyVersion))

        if (this.isCashless) {
            if (this.cardExists.value === false) {
                return
            }

            if (!this.sufficientBalance && this.cardBalance.value !== null && !this.enableTopup) {
                return
            }
        }

        this.error = null

        if (!ServerManager.shop) {
            throw new Error('Checkout without shop')
        }

        this.applyExtraCosts()

        if (!this.checkout.customer) {
            return
        }

        if (!this.checkout.paymentMethod) {
            return
        }

        if (this.isCashless) {
            if (!Validator.cardID(this.cardID, this.paymentMethod?.settings?.cardIDRegex)) {
                this.error = this.$i18n.t('customer.payment.errors.invalidCardID').toString()
                return
            }
        }
        if (this.hasBirthdateVerification && this.cardNeedsVerification.value) {
            if (!Validator.birthdate(this.verification)) {
                this.error = this.$i18n.t('customer.payment.errors.invalidDOB').toString()
                return
            }
        }
        this.save()
        next()
    }

    applyExtraCosts() {
        this.checkout.extraCosts = ServerManager.shop.getApplicableExtraCosts(this.checkout)
    }

    async placeOrder() {
        this.loading = true

        // redirect back to cart when invalid.
        if (!await CartManager.validate(CartManager.getConsumptionMode())) {
            this.loading = false
            void this.navigationController?.popToRoot()
            return
        }

        const checkout = CartManager.checkout
        if (checkout.paymentDetails) {
            // Prevent topup hanging around
            checkout.paymentDetails.topup = null
        }

        order(checkout)
            .then(({data}) => {
                CartManager.currentOrderUuid = data.order.uuid
                if (data.paymentUrl) {
                    if (data.redirectMethod && data.redirectMethod == 'POST') {
                        // Some javascript magic to make sure we do a post with some data
                        redirectPost(data.paymentUrl, data.redirectData ? JSON.parse(data.redirectData) : {})
                    } else {
                        window.location.href = data.paymentUrl
                    }
                } else if (data.innovendValidationRequired) {
                    CartManager.clear()
                    this.show(new ComponentWithProperties(
                        InnovendPollingView,
                        {
                            order: data.order,
                        },
                    ))
                } else if (data.posValidationRequired) {
                    CartManager.clear()
                    this.show(new ComponentWithProperties(OrderPosValidationPollingView, {order: data.order}))
                } else {
                    CartManager.clear()
                    this.show(new ComponentWithProperties(OrderView, {order: data.order}))
                }
            }).catch(async (e) => {
                this.onCardIDChanged() // Refresh balance on error

                if (isSimpleErrors(e) || isSimpleError(e)) {
                    if (isSimpleError(e)) {
                        e = new SimpleErrors(e)
                    }

                    // Update shop information
                    await ServerManager.loadShop().catch(e => {
                        console.error(e)
                    })

                    if (e.hasCode('timeslot_not_valid')) {
                        CartManager.setDeliveryDate(null)
                        alert(e.getHuman())
                        this.pop()
                        return
                    }
                    if (e.hasCode('invalid_extra_cost')) {
                        // error with extra costs => recalculate
                        CartManager.checkout.extraCosts = ServerManager.shop.getApplicableExtraCosts(this.checkout)
                    }
                    alert(e.getHuman())
                    this.dismiss()
                } else {
                    console.error(e)
                    alert(this.$i18n.t('customer.order.errors.tooBusy'))
                }
            }).finally(() => {
                this.loading = false
            })
    }

    gotoTopup() {
        this.show(new ComponentWithProperties(TopupSelectionView, {cardBalance: this.cardBalance.value}))
    }

    translateForI18n(str: TranslatedString) {
        return str.getForI18n(this.$i18n as any)
    }

    get remarksAllowed() {
        return this.shop.enableRemarkField
    }

    get hasCheckoutDescription() {
        return this.checkout.description !== null
    }

    get editCheckoutDescription() {
        return this.checkout.description ?? ''
    }

    set editCheckoutDescription(val: string) {
        CartManager.setRemark(val)
    }

    get discountSources() {
        return this.shop.discountSources
    }

    get discount() {
        return this.checkout.discount
    }

    activateDiscount() {
        const comp = new ComponentWithProperties(DiscountSelectionView, {
            applyDiscount: this.applyDiscount,
        })
        this.show(comp)
    }

    applyDiscount(discount: Discount) {
        this.checkout.discount = discount
    }

    removeDiscount() {
        this.checkout.discount = null
    }

    formatDiscountDetails(details: DiscountDetails): string {
        switch (details.mode) {
            case DiscountMode.NONE:
                return this.$t('backoffice.discounts.none').toString()
            case DiscountMode.ABSOLUTE:
                return PriceHelper.format({
                    price: details.absolute ?? 0,
                    forceDisplay: true,
                })
            case DiscountMode.RELATIVE:
                return Formatter.percentage(details.relative ?? 0)
            default: {
                const t: never = details.mode
                throw new Error(`Unsupported discount mode: ${t}`)
            }
        }
    }

    openTerms(event) {
        event.preventDefault()
        import('./policies/TermsPolicyView.vue')
            .then(component => this.present(new ComponentWithProperties(component.default)))
            .catch(error => console.error('Could not load TermsPolicyView', error))
    }

    openPrivacy(event) {
        event.preventDefault()
        import('./policies/PrivacyPolicyView.vue')
            .then(component => this.present(new ComponentWithProperties(component.default)))
            .catch(error => console.error('Could not load PrivacyPolicyView', error))
    }

    get hideBgImageOnPolicyCheckboxes() {
        return ServerManager.shop.personalisation?.personalisationExceptions?.hideBgImageOnPolicyCheckboxes
    }

    get canPay() {
        if (this.forceUpdateCanOrder) {/**/} // force update hack

        if (
            this.checkout.consumptionMode === ConsumptionOptionType.DineIn
            && this.shop.consumptionOptions.dineIn.enableDineInOpeningHours
        ) {
            // We allow customers to pay 5 minutes after the shop closes
            return !this.shop.consumptionOptions.dineIn.isClosedOn(new Date(), this.shop.timezone, 5)
        }

        return true
    }

    get payButtonText() {
        return this.canPay ? this.$t('customer.payment.button.pay') : this.$t('customer.order.shopClosed')
    }

    get topupButtonText() {
        return this.canPay ? this.$t('customer.topup.andOrderButton') : this.$t('customer.order.shopClosed')
    }
}
