import {AutoEncoder, BooleanDecoder, field} from '@simonbackx/simple-encoding'

import {Address, LatLng} from '../Address'

export class GeoCodingRequest extends AutoEncoder {
    @field({decoder: Address})
    address: Address
}

export class GeoCodingResponse extends AutoEncoder {
    @field({decoder: LatLng, nullable: true})
    latLng: LatLng | null
}
