var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.offer
    ? _c(
        "DRSTBox",
        { staticClass: "card" },
        [
          _c(
            "DRSTBoxItem",
            { key: _vm.offer.id, on: { click: _vm.acceptOffer } },
            [
              _c("div", {
                staticClass: "card-img",
                style: {
                  backgroundImage: _vm.offerImgPath
                    ? "url(" + _vm.offerImgPath + ")"
                    : undefined
                },
                attrs: { slot: "left" },
                slot: "left"
              }),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "card-text" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.offer.text) +
                      "\n            "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("button", {
                staticClass: "button icon disclosure",
                attrs: { slot: "right" },
                slot: "right"
              })
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }