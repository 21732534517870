var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view" },
    [
      _c("DRSTNavigationBar", { attrs: { title: _vm.name } }, [
        _c("button", {
          staticClass: "button icon close",
          attrs: { slot: "left" },
          on: { click: _vm.pop },
          slot: "left"
        }),
        _vm._v(" "),
        _c(
          "p",
          { staticClass: "price", attrs: { slot: "right" }, slot: "right" },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm._f("price")(_vm.selectedVariantPrice)) +
                "\n        "
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "main",
        [
          _c("h1", [_vm._v(_vm._s(_vm.editCartItemName))]),
          _vm._v(" "),
          _vm.editCartItem.product.images.length > 0
            ? _c("div", { staticClass: "product-image" }, [
                _c("div", {
                  staticClass: "spacer",
                  style: { paddingBottom: _vm.paddingBottom }
                }),
                _vm._v(" "),
                _c("img", {
                  attrs: { src: _vm.imageResolution.file.getPublicPath() }
                })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.availabilityTexts.length > 0
            ? _c("p", {
                domProps: {
                  textContent: _vm._s(_vm.availabilityTexts.join(", "))
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.description
            ? _c("p", {
                staticClass: "product-description",
                domProps: { textContent: _vm._s(_vm.description) }
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.volumeUpsellView && _vm.productVariants.length > 1
            ? _c("ProductVariantBox", {
                attrs: {
                  "prices-map": _vm.computedVariantPrices,
                  "show-absolute-prices": _vm.showAbsolutePrices,
                  "product-variants": _vm.productVariants,
                  editable: _vm.shop.enableOrdering
                },
                model: {
                  value: _vm.selectedVariantId,
                  callback: function($$v) {
                    _vm.selectedVariantId = $$v
                  },
                  expression: "selectedVariantId"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.bundledOptionGroups, function(optionGroupGroup) {
            return [
              optionGroupGroup.length == 1
                ? _c("OptionGroupBox", {
                    key: _vm.selectedVariantId + "-" + optionGroupGroup[0].id,
                    attrs: {
                      "sub-items": _vm.editSubCartItems,
                      "consumption-mode": _vm.consumptionMode,
                      "edit-cart-item": _vm.editCartItem,
                      "option-group": optionGroupGroup[0],
                      editable: _vm.shop.enableOrdering,
                      "price-offset": _vm.optionGroupPriceOffsets.get(
                        optionGroupGroup[0].id
                      ),
                      "has-amount-error": _vm.groupHasAmountError(
                        optionGroupGroup[0].id
                      )
                    },
                    on: {
                      removeOptionProduct: function($event) {
                        return _vm.removeOptionProduct($event)
                      },
                      addOptionProduct: function($event) {
                        return _vm.addOptionProduct($event)
                      },
                      optionChanged: _vm.optionChanged
                    },
                    model: {
                      value: _vm.editCartItem.selectedVariantInfo.options,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.editCartItem.selectedVariantInfo,
                          "options",
                          $$v
                        )
                      },
                      expression: "editCartItem.selectedVariantInfo.options"
                    }
                  })
                : _c("OptionGroupGroupBox", {
                    key:
                      _vm.selectedVariantId +
                      "-" +
                      optionGroupGroup.map(function(el) {
                        return el.id
                      }),
                    attrs: {
                      "sub-items": _vm.editSubCartItems,
                      "consumption-mode": _vm.consumptionMode,
                      "edit-cart-item": _vm.editCartItem,
                      "option-groups": optionGroupGroup,
                      editable: _vm.shop.enableOrdering,
                      "option-group-price-offsets": _vm.optionGroupPriceOffsets,
                      "amount-errors-map": _vm.errorsForGroup(optionGroupGroup)
                    },
                    on: {
                      removeOptionProduct: function($event) {
                        return _vm.removeOptionProduct($event)
                      },
                      addOptionProduct: function($event) {
                        return _vm.addOptionProduct($event)
                      },
                      optionChanged: _vm.optionChanged
                    },
                    model: {
                      value: _vm.editCartItem.selectedVariantInfo.options,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.editCartItem.selectedVariantInfo,
                          "options",
                          $$v
                        )
                      },
                      expression: "editCartItem.selectedVariantInfo.options"
                    }
                  })
            ]
          })
        ],
        2
      ),
      _vm._v(" "),
      _vm.shop.disclaimer
        ? _c("div", {
            staticClass: "disclaimer",
            domProps: { textContent: _vm._s(_vm.shop.disclaimer) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "TransitionExpand",
        [
          !!_vm.optionAmountErrors.length
            ? _c(
                "DRSTFooterBox",
                { staticClass: "error-box" },
                _vm._l(_vm.optionAmountErrors, function(error) {
                  return _c("DRSTBoxItem", { key: error.groupId }, [
                    _c(
                      "div",
                      {
                        staticClass: "error-box-left",
                        attrs: { slot: "left" },
                        slot: "left"
                      },
                      [
                        _c("div", { staticClass: "icon error" }),
                        _vm._v(" "),
                        _c("p", { staticClass: "error-name" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.optionAmountMap[error.groupId].name) +
                              "\n                    "
                          )
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    error.errorType === _vm.OptionAmountType.Min
                      ? _c(
                          "p",
                          {
                            staticClass: "error-amount",
                            attrs: { slot: "right" },
                            slot: "right"
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$t(
                                    "consumer.optionAmountErrors.minimum",
                                    { amount: error.amount }
                                  )
                                ) +
                                "\n                "
                            )
                          ]
                        )
                      : error.errorType === _vm.OptionAmountType.Max
                      ? _c(
                          "p",
                          {
                            staticClass: "error-amount",
                            attrs: { slot: "right" },
                            slot: "right"
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$t(
                                    "consumer.optionAmountErrors.maximum",
                                    { amount: error.amount }
                                  )
                                ) +
                                "\n                "
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.canOrder
        ? _c("DRSTFloatingFooter", [
            _c(
              "button",
              {
                staticClass: "button primary fill",
                class: { disabled: _vm.addButtonDisabled },
                on: { click: _vm.add }
              },
              [
                _vm.volumeUpsellView
                  ? [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("customer.products.swapVolumeUpsell")) +
                          "\n                "
                      ),
                      _vm.calculatedPrice !== 0
                        ? [
                            _vm._v(
                              "\n                    [" +
                                _vm._s(_vm._f("price")(_vm.calculatedPrice)) +
                                "]\n                "
                            )
                          ]
                        : _vm._e()
                    ]
                  : _vm.shouldCloseWithoutSaving
                  ? [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("customer.products.closeWindow")) +
                          "\n            "
                      )
                    ]
                  : _vm.editing
                  ? [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("customer.products.modify")) +
                          "\n                "
                      ),
                      _vm.calculatedPrice !== 0
                        ? [
                            _vm._v(
                              "\n                    [" +
                                _vm._s(_vm._f("price")(_vm.calculatedPrice)) +
                                "]\n                "
                            )
                          ]
                        : _vm._e()
                    ]
                  : [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("customer.products.confirmOptions")) +
                          "\n            "
                      )
                    ]
              ],
              2
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }