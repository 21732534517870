import {ArrayDecoder, AutoEncoder, BooleanDecoder, field, NumberDecoder, StringDecoder} from '@simonbackx/simple-encoding'

import {TranslatedString, TranslatedStringDecoder} from './Language'
import {CssVariables} from './ShopPersonalisation'

export enum ShopGroup {
    SINTTRUIDEN = 'Sint-Truiden',
    FELIXFOODKLUB = 'FelixFoodKlub',
    BARABOIRE = 'Baraboire',
    GANZERIK = 'Ganzerik',
    PARKKAFFEE = 'Parkkaffee',
    // TELENETFASTBAR = 'TelenetFastbar',
    TIJM = 'Tijm',
    GOLDENBOWLS = 'GoldenBowls',
}

export class ShopGroupHelper {
    static getJsonName(shopgroup: ShopGroup): string {
        switch (shopgroup) {
            case ShopGroup.SINTTRUIDEN:
                return 'sinttruiden.json'
            case ShopGroup.FELIXFOODKLUB:
                return 'felixfoodklub.json'
            case ShopGroup.BARABOIRE:
                return 'baraboire.json'
            case ShopGroup.GANZERIK:
                return 'ganzerik.json'
            case ShopGroup.PARKKAFFEE:
                return 'parkkaffee.json'
            // case ShopGroup.TELENETFASTBAR:
            //     return 'telenetfastbar.json'
            case ShopGroup.TIJM:
                return 'tijm.json'
            case ShopGroup.GOLDENBOWLS:
                return 'goldenbowls.json'
            default: {
                const c: never = shopgroup
                throw new Error(`Unknown shopgroup: ${c}`)
            }
        }
    }

    static getShopGroupFromHost(host: string, path: string): ShopGroup | null {
        const landingPageName = path.substring(1).split('/')[0]
        if (landingPageName === '') {
            switch (host) {
                case '3800online.dorst.app':
                    return ShopGroup.SINTTRUIDEN
                case 'order.tijmutrecht.nl':
                    return ShopGroup.TIJM
                case '3800online.local:8881':
                    return ShopGroup.SINTTRUIDEN // dev
                case 'ordertijm.local:8881':
                    return ShopGroup.TIJM // dev
                case 'felixfoodklub.local:8881':
                    return ShopGroup.FELIXFOODKLUB // dev
                case 'baraboire.local:8881':
                    return ShopGroup.BARABOIRE // dev
                case 'ganzerik.local:8881':
                    return ShopGroup.GANZERIK // dev
                case 'parkkaffee.local:8881':
                    return ShopGroup.PARKKAFFEE // dev
                case 'goldenbowls.local:8881':
                    return ShopGroup.GOLDENBOWLS // dev
                default:
                    return null
            }
        }
        switch (host) {
            case 'dorst.dev': { // dev
                switch (landingPageName) {
                    case 'felixfoodklub':
                        return ShopGroup.FELIXFOODKLUB
                    case 'baraboire':
                        return ShopGroup.BARABOIRE
                    case 'norma':
                        return ShopGroup.BARABOIRE
                    case 'zomerbardeganzerik':
                        return ShopGroup.GANZERIK
                    case 'parkkaffee':
                        return ShopGroup.PARKKAFFEE
                    // case 'telenetfastbar':
                    //     return ShopGroup.TELENETFASTBAR
                    case 'goldenbowls':
                        return ShopGroup.GOLDENBOWLS
                    default:
                        return null
                }
            }

            case 'dorst.app': {
                switch (landingPageName) {
                    case 'felixfoodklub':
                        return ShopGroup.FELIXFOODKLUB
                    case 'baraboire':
                        return ShopGroup.BARABOIRE
                    case 'norma':
                        return ShopGroup.BARABOIRE
                    case 'zomerbardeganzerik':
                        return ShopGroup.GANZERIK
                    case 'parkkaffee':
                        return ShopGroup.PARKKAFFEE
                    // case 'telenetfastbar':
                    //     return ShopGroup.TELENETFASTBAR
                    case 'goldenbowls':
                        return ShopGroup.GOLDENBOWLS
                    default:
                        return null
                }
            }
            default:
                return null
        }
    }
}

export class ShopGroupDataHeader extends AutoEncoder {
    @field({decoder: new ArrayDecoder(StringDecoder)})
    logos: Array<string>

    @field({decoder: TranslatedStringDecoder, optional: true, nullable: true})
    title: TranslatedString | null = null

    @field({decoder: TranslatedStringDecoder})
    introduction: TranslatedString

    @field({decoder: BooleanDecoder, optional: true})
    titleAboveLogos = false

    @field({decoder: BooleanDecoder, optional: true})
    standaloneLogo = false
}

export class CssExceptions extends AutoEncoder {
    @field({decoder: BooleanDecoder})
    logoOnlyHeader: boolean
}

export class ShopGroupDataShop extends AutoEncoder {
    @field({decoder: NumberDecoder})
    id: number

    @field({decoder: StringDecoder})
    title: string

    @field({decoder: StringDecoder})
    image: string

    @field({decoder: TranslatedStringDecoder})
    description: TranslatedString

    @field({decoder: StringDecoder, optional: true})
    imageFit = 'cover'
}

export class ShopGroupDataFooter extends AutoEncoder {
    @field({decoder: TranslatedStringDecoder})
    title: TranslatedString

    @field({decoder: new ArrayDecoder(StringDecoder)})
    logos: Array<string>

    @field({decoder: TranslatedStringDecoder, optional: true, nullable: true})
    disclaimer: TranslatedString | null = null
}

export class ShopGroupData extends AutoEncoder {
    @field({decoder: ShopGroupDataHeader})
    header: ShopGroupDataHeader

    @field({decoder: new ArrayDecoder(ShopGroupDataShop)})
    shops: Array<ShopGroupDataShop>

    @field({decoder: ShopGroupDataFooter})
    footer: ShopGroupDataFooter

    @field({decoder: CssVariables, nullable: true})
    cssVariables: CssVariables | null = null

    @field({decoder: CssExceptions, optional: true, nullable: true})
    cssExceptions: CssExceptions | null = null

    @field({decoder: StringDecoder, optional: true})
    backgroundImage: string | undefined

    @field({decoder: NumberDecoder})
    version: number
}
