import {ArrayDecoder, AutoEncoder, BooleanDecoder, field, PatchType, StringDecoder} from '@simonbackx/simple-encoding'

import {CartItem} from './CartItem'
import {Category} from './Category'

export class ProductStack extends AutoEncoder {
    @field({decoder: CartItem})
    cartItem: CartItem

    @field({decoder: new ArrayDecoder(CartItem), optional: true})
    subItems: Array<CartItem> = []

    @field({decoder: new ArrayDecoder(Category), defaultValue: () => []})
    categoryStack: Array<Category>

    /** whether the main level categories are <= 3 so we have to use the segmented control on min level */
    @field({decoder: BooleanDecoder, defaultValue: () => false})
    squashRootCategories: boolean
}
