








































































































































import {isSimpleError, isSimpleErrors, SimpleErrors} from '@simonbackx/simple-errors'
import {ComponentWithProperties, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {order} from '../actions/order.action'
import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import {PaymentMethodHelper} from '../helpers/PaymentMethodHelper'
import OrderView from './OrderView.vue'
import {
    DRSTBox,
    DRSTBoxItem,
    DRSTFloatingFooter,
    DRSTFooterBox,
    DRSTNavigationBar,
    Radio,
    RemarkBlock,
    SpinnerButton,
    Stepper,
    TextInputRow,
} from '@dorst/components'
import {PaymentMethod, PaymentMethodType, TopUp} from '@dorst/structures'

@Component({
    components: {
        DRSTBox,
        RemarkBlock,
        DRSTBoxItem,
        DRSTFloatingFooter,
        DRSTFooterBox,
        DRSTNavigationBar,
        Radio,
        SpinnerButton,
        Stepper,
        TextInputRow,
    },
})
export default class TopupSelectionView extends Mixins(NavigationMixin) {
    checkout = CartManager.checkout
    loading = false
    error: string | null = null

    topupAmounts = [5, 10, 20, 50, 100, 500].map(amount => amount * 100)

    topupMethod = this.paymentMethods[0]
    topupAmount = this.topupAmounts[0]

    @Prop({required: true, type: Number})
    cardBalance: number

    mounted() {
        this.topupAmount = this.topupAmounts.find((amount) => this.isTopupSufficient(amount)) || this.topupAmounts[this.topupAmounts.length - 1]
    }

    get newBalance(): number {
        return this.cardBalance + this.topupAmount - this.checkout.getPrice()
    }

    get hasPayconic(): boolean {
        return this.paymentMethods.some(el => el.type === PaymentMethodType.Payconiq)
    }

    get paymentMethods() {
        const filteredMethods = [PaymentMethodType.PointOfSale, PaymentMethodType.Starnet, PaymentMethodType.Digitin]
        return ServerManager.shop.paymentMethods.filter(({type}) => !filteredMethods.includes(type))
    }

    getName(method: PaymentMethod) {
        return method.getName(this.$i18n as any, this.checkout.consumptionMode, this.hasPayconic)
    }

    getImages(method: PaymentMethod): Array<string> {
        return PaymentMethodHelper.getImages(method, this.hasPayconic)
    }

    isTopupSufficient(amount: number): boolean {
        return this.cardBalance + amount >= this.checkout.getPrice()
    }

    placeOrder() {
        const checkout = CartManager.checkout
        if (this.loading || checkout.paymentDetails === null) {
            return
        }
        this.loading = true

        checkout.paymentDetails.topup = TopUp.create({
            paymentMethod: this.topupMethod,
            amount: this.topupAmount,
        })

        order(checkout)
            .then(({data}) => {
                if (data.paymentUrl) {
                    window.location.href = data.paymentUrl
                } else {
                    CartManager.clear()
                    this.show(new ComponentWithProperties(OrderView, {order: data.order}))
                }
            }).catch(async (e) => {
                if (isSimpleErrors(e) || isSimpleError(e)) {
                    if (isSimpleError(e)) {
                        e = new SimpleErrors(e)
                    }

                    // Update shop information
                    await ServerManager.loadShop().catch(e => {
                        console.error(e)
                    })

                    alert(e.getHuman())
                } else {
                    console.error(e)
                }
                this.dismiss()
            }).finally(() => {
                this.loading = false
            })
    }
}
