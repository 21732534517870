import {Data, Encodeable, EncodeContext, PlainObject} from '@simonbackx/simple-encoding'
import {SimpleError} from '@simonbackx/simple-errors'

import {Language} from './Language'

export class DecodableRecord<T extends string = string, K extends PlainObject = string | number> implements Encodeable {
    data: Record<T, K>

    constructor(data: Record<T, K>) {
        this.data = data ?? {}
    }

    static decode<T extends string = string, K extends PlainObject = string | number>(data: Data): DecodableRecord<T> {
        if (typeof data.value == 'object') {
            Object.keys(data.value as Record<T, K>).forEach(key => {
                if (typeof data.value[key] != 'string' && typeof data.value[key] != 'number') {
                    throw new SimpleError({
                        code: 'invalid_field',
                        message: 'Expected a string or number as value in Record',
                        field: data.currentField,
                    })
                }
            })
            return new DecodableRecord(data.value)
        }

        throw new SimpleError({
            code: 'invalid_field',
            message: 'Unknown record value (not an object)',
            field: data.currentField,
        })
    }

    encode(context: EncodeContext) {
        return this.data
    }
}

export class DecodableLanguageRecord extends DecodableRecord<Language, string> {}
