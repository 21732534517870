import {AutoEncoder, field, IntegerDecoder, NumberDecoder, StringDecoder} from '@simonbackx/simple-encoding'

export class CloudPosCategory extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    id: number

    @field({decoder: StringDecoder})
    name: string

    @field({decoder: IntegerDecoder})
    modtime: number
}
