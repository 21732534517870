















import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({
    model: {
        prop: 'title',
        event: 'input',
    },
})
export default class ProgressBar extends Vue {
    @Prop({default: 0, type: Number})
    percentage: number

    @Prop({default: true, type: Boolean})
    showValue: boolean

    get normalisedPercentage() {
        return Math.min(100, Math.round(this.percentage * 100) / 100)
    }
}
