import {ArrayDecoder, AutoEncoder, BooleanDecoder, field, IntegerDecoder, PatchType, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {Language, TranslatedString, TranslatedStringDecoder, TranslatedStringPatch} from './Language'
import {OptionMeta, TBOptionMeta} from './OptionMeta'
import {ProductMeta} from './ProductMeta'

export class OptionProductLink extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    productId: string

    @field({decoder: BooleanDecoder, optional: true})
    useProductPrice = false

    @field({decoder: BooleanDecoder, optional: true})
    useProductName = true

    @field({decoder: BooleanDecoder, optional: true})
    useProductVAT = true

    /** If null -> allow all == topbrand ids of pricegroup2 */
    @field({decoder: new ArrayDecoder(StringDecoder), optional: true})
    disabledVariants: Array<string> = []

    /** If null -> allow all == topbrand ids of pricegroup1 */
    @field({decoder: new ArrayDecoder(StringDecoder), optional: true})
    disabledOptionGroups: Array<string> = []

    /** If null -> allow all == topbrand ids of pricegroup1 */
    @field({decoder: new ArrayDecoder(StringDecoder), optional: true})
    disabledOptions: Array<string> = []
}

export class Option extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: BooleanDecoder, optional: true})
    enabled: boolean = true

    @field({decoder: StringDecoder})
    @field({
        decoder: TranslatedStringDecoder,
        version: 69,
        upgrade: (str: string): TranslatedString => {
            return new TranslatedString({
                [Language.Dutch]: str,
            })
        },
        downgrade: (name: TranslatedString): string => {
            return name.getForLanguage(Language.Dutch)
        },
        upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
            if (!str) {
                return new TranslatedStringPatch({})
            }
            return new TranslatedStringPatch({[Language.Dutch]: str})
        },
        downgradePatch: (name: PatchType<TranslatedString>): PatchType<string> => {
            if (name[Language.Dutch]) {
                return name[Language.Dutch] ?? ''
            }
            return undefined
        },
    })
    name: TranslatedString = new TranslatedString({})

    @field({decoder: IntegerDecoder})
    priceChange = 0

    @field({decoder: IntegerDecoder, nullable: true, version: 72, upgrade: () => null})
    minAmount: number | null = null

    @field({decoder: IntegerDecoder, nullable: true, version: 72, upgrade: () => null})
    maxAmount: number | null = null

    @field({decoder: IntegerDecoder, nullable: true, version: 72, upgrade: () => null})
    defaultAmount: number | null = null

    /**
     * do not charge for x amount of this option (but also do not subtract if free included > amount)
     * */
    @field({decoder: IntegerDecoder, optional: true})
    amountIncludedInProductPrice = 0

    @field({decoder: OptionProductLink, optional: true})
    productLink?: OptionProductLink

    /**
     * Not configurable via UI
     */
    @field({decoder: IntegerDecoder, nullable: true, version: 37, upgrade: () => null})
    VAT: number | null = null

    /** Used for Top Brands = extra products as option so we need to send extra ids in order */
    @field({decoder: ProductMeta, version: 69, optional: true})
    @field({
        decoder: OptionMeta, version: 79, optional: true, upgrade: (oldVal: ProductMeta) => {
            if (oldVal === undefined) {
                return OptionMeta.create({})
            }
            return OptionMeta.create({
                ...oldVal,
                topBrands: oldVal.topBrands === null ? null : TBOptionMeta.create({...oldVal.topBrands}),
                digitin: null,
                cloudpos: null,
                deliverect: null,
            })
        },
    })
    meta?: OptionMeta
}

export const OptionPatch = Option.patchType()
