import {AutoEncoder, EnumDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'

import {ConsumptionOptionType} from './ConsumptionOptions'

export class LandingRedirectInfo extends AutoEncoder {
    @field({decoder: new EnumDecoder(ConsumptionOptionType)})
    consumptionMode?: ConsumptionOptionType

    @field({decoder: StringDecoder, optional: true})
    redirectUrl: string

    @field({decoder: StringDecoder, nullable: true})
    productId: string | null
}
