













































import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import DRSTBoxItem from '../DRSTBoxItem.vue'
import TelephoneInput from './TelephoneInput.vue'

@Component({
    components: {
        DRSTBoxItem,
        TelephoneInput,
    },
})

export default class TelephoneRow extends Mixins(NavigationMixin) {
    @Prop({})
    value: string

    @Prop({default: 'label'})
    elementName: string

    @Prop({default: 'BE'})
    defaultCountryCode: string

    @Prop({default: ''})
    placeholder: string

    @Prop({default: false})
    disabled: boolean

    @Prop({default: ''})
    label: string

    get phoneNumber() {
        return this.value
    }

    set phoneNumber(value: string) {
        this.$emit('input', value)
    }

    select() {
        (this.$refs.input as any)?.select()
    }
}
