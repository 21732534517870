
























import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class DRSTBoxItem extends Vue {
    @Prop({default: 'article', type: String})
    elementName!: string

    @Prop({default: false, type: Boolean})
    selectable!: boolean
}
