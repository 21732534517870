import {AutoEncoder, field, StringDecoder} from '@simonbackx/simple-encoding'
import {SimpleError} from '@simonbackx/simple-errors'

export class CloudPosLogin extends AutoEncoder {
    @field({decoder: StringDecoder})
    licenseName = ''

    @field({decoder: StringDecoder})
    token = ''

    validate() {
        if (this.licenseName === '' || this.token === '') {
            throw new SimpleError({code: 'invalid_credentials', message: 'Licensename or token not provided'})
        }
    }
}
