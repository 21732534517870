import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import {AnalyticProcessor} from './AnalyticsProcessorInterface'
import {ScriptLoader} from '@dorst/frontend-helpers'
import {isNullOrEmpty} from '@dorst/helpers'
import {
    CartItem,
    Category,
    OrderConsumer,
    PaymentMethod,
    Product,
} from '@dorst/structures'

/**
 * <https://developers.facebook.com/docs/meta-pixel/implementation/conversion-tracking#standard-events>
 */
export class FacebookPixelAnalytics extends AnalyticProcessor {

    name = 'FacebookPixel'

    isConfigured(): boolean {
        return !isNullOrEmpty(ServerManager.shop.facebookPixelId)
    }

    async grantConsent() {
        const id = ServerManager.shop.facebookPixelId

        if (isNullOrEmpty(id)) {
            return
        }

        await ScriptLoader.loadScript('facebook-pixel', '/fb-pixel.js')

        this.send('init', id)
        this.send('consent', 'grant')
    }

    // @todo facebookpixel add way to revoke consent (cookie banner & way to turn it off again).
    revokeConsent() {
        this.send('consent', 'revoke')
    }

    onHomepageViewWithSegmentedControl(products: Array<Product>) {
        this.send('track', 'ViewContent', {
            content_name: 'Menu',
            content_type: 'product',
            content_ids: products.map(product => product.id),
            contents: products.map(product => {
                return {
                    id: product.id,
                    name: product.name.getForLanguage(ServerManager.shop.language),
                    quantity: 1,
                }
            }),
        })
    }

    onHomepageView(categories: Array<Category>) {
        this.send('track', 'ViewContent', {
            content_name: 'Menu',
            content_type: 'product_group',
            content_ids: categories.map(category => category.id),
            contents: categories.map(category => {
                return {
                    id: category.id,
                    name: category.name.getForLanguage(ServerManager.shop.language),
                    quantity: 1,
                }
            }),
        })
    }

    onCartView() {
        this.send('track', 'ViewContent', {
            content_ids: CartManager.cart.items.map(cartItem => cartItem.product.id),
            content_type: 'product',
            contents: CartManager.cart.items.map(cartItem => {
                return {
                    id: cartItem.product.id,
                    name: cartItem.product.name.getForLanguage(ServerManager.shop.language),
                    quantity: cartItem.amount,
                }
            }),
            num_items: CartManager.cart.items.length,
            currency: ServerManager.shop.currency,
            value: CartManager.cart.getPrice() / 100,
        })
    }

    onAddProductToCart(cartItem: CartItem) {
        this.send('track', 'AddToCart', {
            content_ids: [cartItem.product.id],
            content_name: cartItem.getSelectedVariantName({locale: ServerManager.shop.language}),
            content_type: 'product',
            contents: cartItem.getFacebookPixelContent({locale: ServerManager.shop.language}),
            currency: ServerManager.shop.currency,
            value: cartItem.getPrices().price / 100,
        })
    }

    onCategoryView(category: Category, products: Array<Product>) {
        this.send('track', 'ViewContent', {
            content_ids: [category.id],
            content_type: 'product_group',
            content_name: category.name.getForLanguage(ServerManager.shop.language),
            contents: products.map(product => {
                return {
                    id: product.id,
                    name: product.name.getForLanguage(ServerManager.shop.language),
                    quantity: 1,
                }
            }),
        })
    }

    onProductView(cartItem: CartItem) {
        this.send('track', 'ViewContent', {
            content_ids: [cartItem.product.id],
            content_type: 'cartItem.product',
            content_name: cartItem.product.name.getForLanguage(ServerManager.shop.language),
            contents: [
                {
                    id: cartItem.product.id,
                    quantity: cartItem.amount,
                },
            ],
            currency: ServerManager.shop.currency,
            value: cartItem.getPrices().price / 100,
        })
    }

    onOrderConfirmation(order: OrderConsumer) {
        this.send('track', 'Purchase', {
            content_ids: order.items.map(orderItem => orderItem.product.id),
            content_type: 'product',
            contents: order.items.map(orderItem => {
                return {
                    id: orderItem.product.id,
                    name: orderItem.product.name.getForLanguage(ServerManager.shop.language),
                    quantity: orderItem.amount,
                }
            }),
            currency: ServerManager.shop.currency,
            num_items: order.items.length,
            value: order.price,
        })
    }

    onInitiateCheckout() {
        this.send('track', 'InitiateCheckout', {
            content_ids: CartManager.cart.items.map(cartItem => cartItem.product.id),
            content_type: 'product',
            contents: CartManager.cart.items.map(cartItem => {
                return {
                    id: cartItem.product.id,
                    name: cartItem.product.name.getForLanguage(ServerManager.shop.language),
                    quantity: cartItem.amount,
                }
            }),
            num_items: CartManager.cart.items.length,
            currency: ServerManager.shop.currency,
            value: CartManager.cart.getPrice() / 100,
        })
    }

    onRemoveFromCart(cartItem: CartItem): void {
        // Not implemented https://developers.facebook.com/docs/meta-pixel/reference
    }

    onSelectPaymentMethod(info: PaymentMethod): void {
        this.send('track', 'AddPaymentInfo', {
            content_ids: CartManager.cart.items.map(cartItem => cartItem.product.id),
            content_type: 'product',
            contents: CartManager.cart.items.map(cartItem => {
                return {
                    id: cartItem.product.id,
                    name: cartItem.product.name.getForLanguage(ServerManager.shop.language),
                    quantity: cartItem.amount,
                }
            }),
            num_items: CartManager.cart.items.length,
            currency: ServerManager.shop.currency,
            value: CartManager.cart.getPrice() / 100,
        })
    }

    send(
        eventType: string,
        eventNameOrAppId: string,
        parameters?:
            | facebook.Pixel.AddPaymentInfoParameters
            | facebook.Pixel.AddToCartParameters
            | facebook.Pixel.AddToWishlistParameters
            | facebook.Pixel.CompleteRegistrationParameters
            | facebook.Pixel.InitiateCheckoutParameters
            | facebook.Pixel.LeadParameters
            | facebook.Pixel.PurchaseParameters
            | facebook.Pixel.SearchParameters
            | facebook.Pixel.ViewContentParameters,
    ): void {
        if (parameters === undefined) {
            window.fbq?.(eventType, eventNameOrAppId)
        } else {
            window.fbq?.(eventType, eventNameOrAppId, parameters)
        }
    }
}
