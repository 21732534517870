/**
 * - This is what was found in the documentation
 *
 *   => This is how we implement it in DORST
 *
 * - Must-have options for an article: When an article has must-have options (Options) configured,
 *   you must process all of them and add one item of each option group to the order as
 *   "Must have option".
 *
 *   => Because these options always need a choice, these are implemented as single-select radio
 *      buttons. These are not unselectable and only one can be selected. E.g. "Salt" / "No salt".
 *
 * - In exceptional cases, it happens that a must-have option configured for an article has the
 *   'Compose' setting enabled. When this is done, you can add multiple items from the option group
 *   to the article when you order it.
 *
 *   => A multi select is used and at least one option must be selected.
 *
 * - Free option for an article: When an article has a free option (FreeOption) configured, the
 *   articles in this option group can be added to this article as "Free option". Please note that
 *   the items in this option group are not for free by default (price 0), the option is just not
 *   required to be used.
 *
 *   => Checkbox select, max 1, min 0.
 *
 * - Condiments for an article: When a department has a Condiment (Condiment) configured, the
 *   articles in this option group can be added as "Condiment" to all regular items which belong to
 *   this department.
 *
 *   => Checkbox select, max 1, min 0.
 *
 * - Supplements for an article: When a department has a Supplement (Supplement) configured, the
 *   articles in this option group can be added as "Supplement" to all regular items which belong to
 *   this department.
 *
 *   => Checkboxes, no max, min 0, each option has a number value.
 */
export enum UOrderItemType {
    Normal = 0,
    MustHaveOption = 1,
    FreeOption = 2,
    Supplement = 3,
    Condiment = 4,
    MenuItem = 5,
    ArticleMessage = 6,
}
