import {
    ArrayDecoder,
    AutoEncoder,
    BooleanDecoder,
    EnumDecoder,
    field,
    IntegerDecoder,
    PatchType,
    StringDecoder,
} from '@simonbackx/simple-encoding'
import {DateTime} from 'luxon'

import {IDAddress} from './Address'
import {ConsumptionModeAvailabilityDay} from './ConsumptionModeAvailabilityDay'
import {ConsumptionModeAvailabilityPeriod} from './ConsumptionModeAvailabilityPeriod'
import {ConsumptionModeAvailabilityScheme} from './ConsumptionModeAvailabilityScheme'
import {CustomerType} from './Customer'
import {DeliveryLocation, DeliveryLocationType} from './DeliveryLocation'
import {ExtraCost} from './ExtraCost'
import {I18n} from './I18n'
import {
    Language,
    TranslatedString,
    TranslatedStringDecoder,
    TranslatedStringPatch,
} from './Language'
import {
    TranslatedShopMessage,
    TranslatedStringToTranslatedShopMessage,
} from './ShopMessage'
import {WeekDay} from './WeekDay'
import {DateHelper, isNullOrEmpty} from '@dorst/helpers'
import {Validator} from '@dorst/validation'

export enum ConsumptionOptionType {
    DineIn = 'DineIn',
    TakeAway = 'TakeAway',
    Delivery = 'Delivery',
}

export class ConsumptionOptionTypeHelper {
    static getName(type: ConsumptionOptionType, i18n: I18n) {
        switch (type) {
            case ConsumptionOptionType.DineIn:
                return i18n.t('backoffice.consumptionOptions.dineIn')
            case ConsumptionOptionType.TakeAway:
                return i18n.t('backoffice.consumptionOptions.takeAway')
            case ConsumptionOptionType.Delivery:
                return i18n.t('backoffice.consumptionOptions.delivery')
            default: {
                const t: never = type
                throw new Error(`Unknown consumptionoption type: ${t}`)
            }
        }
    }

    static getSlug(type: ConsumptionOptionType): 'dineIn' | 'takeAway' | 'delivery' {
        switch (type) {
            case ConsumptionOptionType.DineIn:
                return 'dineIn'
            case ConsumptionOptionType.TakeAway:
                return 'takeAway'
            case ConsumptionOptionType.Delivery:
                return 'delivery'
            default: {
                const t: never = type
                throw new Error(`Unknown consumptionoption type: ${t}`)
            }
        }
    }

    static getAvailabilityTitle(type: ConsumptionOptionType, i18n: I18n): string {
        switch (type) {
            case ConsumptionOptionType.TakeAway:
                return i18n.t('common.availability.pickupPossibleOn')
            case ConsumptionOptionType.Delivery:
                return i18n.t('common.availability.deliverableOn')
            default:
                return i18n.t('common.availability.availableOn')
        }
    }
}

export class ConsumptionOption extends AutoEncoder {
    @field({decoder: BooleanDecoder})
    enabled = false

    /**
     * Text that will be in every confirmation email to a consumer
     */
    @field({decoder: StringDecoder, nullable: true})
    @field({
        decoder: TranslatedStringDecoder,
        version: 88,
        nullable: true,
        optional: true,
        upgrade: (str: string | null): TranslatedString | null => {
            return isNullOrEmpty(str)
                ? null
                : new TranslatedString({
                    [Language.Dutch]: str,
                })
        },
        downgrade: (translatedStr: TranslatedString | null): string | null => {
            return translatedStr?.getForLanguage(Language.Dutch) ?? null
        },
        upgradePatch: (str?: PatchType<string>): PatchType<TranslatedString> => {
            if (str === undefined) {
                return new TranslatedStringPatch({})
            }
            return new TranslatedStringPatch({[Language.Dutch]: str})
        },
        downgradePatch: (translatedStr: PatchType<TranslatedString>): PatchType<string> => {
            return translatedStr[Language.Dutch]
        },
    })
    @field({
        decoder: TranslatedStringDecoder,
        version: 92,
        optional: true,
        upgrade: (translatedStr: TranslatedString | null): TranslatedString => {
            return translatedStr === null ? new TranslatedString({}) : translatedStr
        },
        downgrade: (translatedStr: TranslatedString): TranslatedString | null => {
            return translatedStr.isEmpty() ? null : translatedStr
        },
    })
    emailText = new TranslatedString({})

    @field({decoder: new EnumDecoder(ConsumptionOptionType)})
    type: ConsumptionOptionType

    @field({decoder: IntegerDecoder, nullable: true, version: 60})
    maxOrdersPerSlot: number | null = null

    @field({decoder: IntegerDecoder, optional: true})
    minOrderAmountOnlinePayment = 0

    @field({decoder: IntegerDecoder, optional: true})
    minOrderAmountWaiterPayment = 0

    @field({decoder: new ArrayDecoder(ExtraCost), version: 76, upgrade: () => [], optional: true})
    extraCosts: Array<ExtraCost> = []

    @field({decoder: ConsumptionModeAvailabilityScheme, optional: true})
    availabilityScheme: ConsumptionModeAvailabilityScheme = new ConsumptionModeAvailabilityScheme()

    @field({decoder: StringDecoder, version: 58, nullable: true, optional: true})
    @field({
        decoder: TranslatedStringDecoder,
        version: 86,
        nullable: true,
        optional: true,
        upgrade: (str: string | null): TranslatedString | null => {
            return isNullOrEmpty(str)
                ? null
                : new TranslatedString({
                    [Language.Dutch]: str,
                })
        },
        downgrade: (translatedStr: TranslatedString | null): string | null => {
            return translatedStr?.getForLanguage(Language.Dutch) ?? null
        },
        upgradePatch: (str?: PatchType<string>): PatchType<TranslatedString> => {
            if (str === undefined) {
                return new TranslatedStringPatch({})
            }
            return new TranslatedStringPatch({[Language.Dutch]: str})
        },
        downgradePatch: (translatedStr: PatchType<TranslatedString>): PatchType<string> => {
            return translatedStr[Language.Dutch]
        },
    })
    @field({
        decoder: TranslatedStringDecoder,
        version: 92,
        optional: true,
        upgrade: (translatedStr: TranslatedString | null): TranslatedString => {
            return translatedStr === null ? new TranslatedString({}) : translatedStr
        },
        downgrade: (translatedStr: TranslatedString): TranslatedString | null => {
            return translatedStr.isEmpty() ? null : translatedStr
        },
    })
    @field({
        decoder: TranslatedShopMessage,
        version: 93,
        nullable: true,
        optional: true,
        ...TranslatedStringToTranslatedShopMessage,
    })
    todayUnavailableMessage: TranslatedShopMessage | null = null

    /**
     * Enable this to email the shop owner when an order is placed
     */
    @field({decoder: BooleanDecoder, optional: true})
    enableNotificationEmail = false

    /**
     * A `;` separated list of email addresses that will receive a notification email when `enableNotificationEmail = true`
     */
    @field({decoder: StringDecoder, optional: true})
    notificationEmails = ''

    get notificationRecipients(): Array<string> {
        return this.notificationEmails
            .split(';')
            .map(chunk => chunk.trim())
            .filter(email => email !== '' && Validator.email(email))
    }

    isClosedOn(jsForDate: Date, timezone: string, endTimeOffset = 0): boolean {
        const forDate = DateTime.fromJSDate(jsForDate, {zone: timezone})

        // Get the exception day and its periods
        const exceptionDay = this.availabilityScheme.variable.find(
            ({date}) => date !== null && DateHelper.isSameDay(forDate, DateTime.fromJSDate(date, {zone: timezone})),
        )
        const periods = exceptionDay?.periods ?? []

        // Exceptions on the same day always overrule, so only if there is no exception...
        if (exceptionDay === undefined) {
            // Get the exceptions from the previous day
            const previousExceptionDay = this.availabilityScheme.variable.find(
                ({date}) => date !== null && DateHelper.isSameDay(forDate.minus({day: 1}), DateTime.fromJSDate(date, {zone: timezone})),
            )

            // We only need the periods that continue past midnight and we need to cut off the startTimes at midnight
            const previousDayExceptionPeriods = previousExceptionDay?.periods ?? []
            periods.push(
                ...previousDayExceptionPeriods
                    .filter(period => period.doesContinuePastMidnight())
                    .map(period => ConsumptionModeAvailabilityPeriod.create({
                        ...period,
                        startTime: 0,
                    })),
            )

            // Get the fixed day periods. These are not overruled by yesterday's exceptions
            periods.push(...this.availabilityScheme.fixed.find(({day}) => day === forDate.weekday)?.periods ?? [])

            // If there was no exception yesterday...
            if (previousExceptionDay === undefined) {
                // Get yesterday's fixed day
                const previousDayPeriods = this.availabilityScheme.fixed.find(
                    ({day}) => day === forDate.weekday - 1,
                )?.periods ?? []

                // And here again, we only need the periods that continue past midnight and we need to cut off the
                // startTimes at midnight
                periods.push(
                    ...previousDayPeriods
                        .filter(period => period.doesContinuePastMidnight())
                        .map(period => ConsumptionModeAvailabilityPeriod.create({
                            ...period,
                            startTime: 0,
                        })),
                )
            }
        }

        // Stop here if there aren't any periods
        if (periods.length === 0) {
            return true
        }

        return !periods.some(period => {
            // For dine-in, we want to allow an offset after a timeslot ends so customers can still order whatever was
            // in their cart after the shop closes.
            const endTime = this.type === ConsumptionOptionType.DineIn
                ? period.endTime + endTimeOffset
                : period.endTime

            return DateHelper.isBetweenTimes(forDate, period.startTime, endTime)
        })
    }

    static getPatchByType(type: ConsumptionOptionType) {
        switch (type) {
            case ConsumptionOptionType.DineIn:
                return DineInConsumptionOption.patch({})
            case ConsumptionOptionType.TakeAway:
                return TakeAwayConsumptionOption.patch({})
            case ConsumptionOptionType.Delivery:
                return DeliveryConsumptionOption.patch({})
            default: {
                const t: never = type
                throw new Error(`Unknown consumptionoption type: ${t}`)
            }
        }
    }
}

export class EnabledOrderingMethods extends AutoEncoder {
    @field({decoder: BooleanDecoder, optional: true})
    [CustomerType.Consumer] = true

    @field({decoder: BooleanDecoder, optional: true})
    [CustomerType.Kiosk] = true

    @field({decoder: BooleanDecoder, optional: true})
    [CustomerType.Waiter] = true
}

export class DineInConsumptionOption extends ConsumptionOption {
    @field({decoder: new EnumDecoder(ConsumptionOptionType)})
    type = ConsumptionOptionType.DineIn

    @field({decoder: BooleanDecoder, optional: true})
    enableDineInOpeningHours = false

    /**
     * Enable to email the customer after placing an order
     */
    @field({decoder: BooleanDecoder, optional: true})
    enableConfirmationEmail = false

    @field({decoder: EnabledOrderingMethods, optional: true})
    enabledOrderingMethods: EnabledOrderingMethods = EnabledOrderingMethods.create({})
}

export class AvailabilityConsumptionOption extends ConsumptionOption {

    /* Maximum days in advance that an order can be made */
    maxDaysInAdvance: number

    /* Interval (in minutes) for timeslots */
    interval: number

    /**
     * Enable to email the customer after placing an order
     */
    @field({decoder: BooleanDecoder, optional: true})
    enableConfirmationEmail = true
}

export class TakeAwayConsumptionOption extends AvailabilityConsumptionOption {
    @field({decoder: new EnumDecoder(ConsumptionOptionType)})
    type = ConsumptionOptionType.TakeAway

    @field({decoder: new ArrayDecoder(ConsumptionModeAvailabilityPeriod), field: 'availabilityPeriods'})
    @field({
        decoder: ConsumptionModeAvailabilityScheme, version: 54, defaultValue: () => new ConsumptionModeAvailabilityScheme(), upgrade: function (this: TakeAwayConsumptionOption, old) {
            // we used hardcoded ids so it gets correctly patched on settings upgrade.
            // will be the same for all shops but unique within shop
            if (old.length) {
                old = old[0] // we had an array of periods but only used 1 (it was an array to allow for multiple days later)
            } else {
                return new ConsumptionModeAvailabilityScheme()
            }
            const defaultFixed = [
                ConsumptionModeAvailabilityDay.create({
                    day: WeekDay.Monday,
                    periods: [ConsumptionModeAvailabilityPeriod.create({startTime: old.startTime, endTime: old.endTime, preparationTime: ConsumptionModeAvailabilityPeriod.defaultPrepTime({time: this.minPreparationTime, id: 'i48427c-d53c-42da-a47d-73f8050b18c2', day: WeekDay.Monday})})].map((el, index) => {
                        el['id'] = 'b48427c-d53c-42da-a47d-73f8050b18c2'
                        return el
                    }),
                }),
                ConsumptionModeAvailabilityDay.create({
                    day: WeekDay.Tuesday,
                    periods: [ConsumptionModeAvailabilityPeriod.create({startTime: old.startTime, endTime: old.endTime, preparationTime: ConsumptionModeAvailabilityPeriod.defaultPrepTime({time: this.minPreparationTime, id: 'j48427c-d53c-42da-a47d-73f8050b18c2', day: WeekDay.Tuesday})})].map((el, index) => {
                        el['id'] = 'c48427c-d53c-42da-a47d-73f8050b18c2'
                        return el
                    }),
                }),
            ConsumptionModeAvailabilityDay.create({
                day: WeekDay.Wednesday,
                periods: [ConsumptionModeAvailabilityPeriod.create({startTime: old.startTime, endTime: old.endTime, preparationTime: ConsumptionModeAvailabilityPeriod.defaultPrepTime({time: this.minPreparationTime, id: 'k48427c-d53c-42da-a47d-73f8050b18c2', day: WeekDay.Wednesday})})].map((el, index) => {
                    el['id'] = 'd48427c-d53c-42da-a47d-73f8050b18c2'
                    return el
                }),
            }),
            ConsumptionModeAvailabilityDay.create({
                day: WeekDay.Thursday,
                periods: [ConsumptionModeAvailabilityPeriod.create({startTime: old.startTime, endTime: old.endTime, preparationTime: ConsumptionModeAvailabilityPeriod.defaultPrepTime({time: this.minPreparationTime, id: 'l48427c-d53c-42da-a47d-73f8050b18c2', day: WeekDay.Thursday})})].map((el, index) => {
                    el['id'] = 'e48427c-d53c-42da-a47d-73f8050b18c2'
                    return el
                }),
            }),
            ConsumptionModeAvailabilityDay.create({
                day: WeekDay.Friday,
                periods: [ConsumptionModeAvailabilityPeriod.create({startTime: old.startTime, endTime: old.endTime, preparationTime: ConsumptionModeAvailabilityPeriod.defaultPrepTime({time: this.minPreparationTime, id: 'm48427c-d53c-42da-a47d-73f8050b18c2', day: WeekDay.Friday})})].map((el, index) => {
                    el['id'] = 'f48427c-d53c-42da-a47d-73f8050b18c2'
                    return el
                }),
            }),
                ConsumptionModeAvailabilityDay.create({
                    day: WeekDay.Saturday,
                    periods: [ConsumptionModeAvailabilityPeriod.create({startTime: old.startTime, endTime: old.endTime, preparationTime: ConsumptionModeAvailabilityPeriod.defaultPrepTime({time: this.minPreparationTime, id: 'n48427c-d53c-42da-a47d-73f8050b18c2', day: WeekDay.Saturday})})].map((el, index) => {
                        el['id'] = 'g48427c-d53c-42da-a47d-73f8050b18c2'
                        return el
                    }),
                }),
                ConsumptionModeAvailabilityDay.create({
                    day: WeekDay.Sunday,
                    periods: [ConsumptionModeAvailabilityPeriod.create({startTime: old.startTime, endTime: old.endTime, preparationTime: ConsumptionModeAvailabilityPeriod.defaultPrepTime({time: this.minPreparationTime, id: 'o48427c-d53c-42da-a47d-73f8050b18c2', day: WeekDay.Sunday})})].map((el, index) => {
                        el['id'] = 'h48427c-d53c-42da-a47d-73f8050b18c2'
                        return el
                    }),
                }),
            ].map((el, index) => {
                el['id'] = `${index.toString()}a48427c-d53c-42da-a47d-73f8050b18c2`
                return el
            })
            const defaultWeek = ConsumptionModeAvailabilityScheme.create({
                id: '1118427c-d53c-42da-a47d-73f8050b18c2',
                fixed: defaultFixed,
            })
            return defaultWeek
        },
    })
    availabilityScheme: ConsumptionModeAvailabilityScheme = new ConsumptionModeAvailabilityScheme()

    @field({decoder: IntegerDecoder, version: 54, upgrade: () => 1, defaultValue: () => 7})
    maxDaysInAdvance = 7

    /* Interval (in minutes) for take away timeslots */
    @field({decoder: IntegerDecoder, defaultValue: () => 15})
    interval = 15

    /* only used to upgrade version 53->54 (availabilityScheme) */
    @field({decoder: IntegerDecoder, defaultValue: () => 30})
    minPreparationTime = 30

    /**
     * takeAwayAddresses should move to tables in the future + tables should have an optional address to make everything consistent.
     */
    @field({decoder: new ArrayDecoder(IDAddress), optional: true, version: 55, upgrade: () => []})
    takeAwayAddresses: Array<IDAddress> = []

    @field({decoder: BooleanDecoder, optional: true})
    useTables = false
}

export class DeliveryConsumptionOption extends AvailabilityConsumptionOption {
    @field({decoder: new EnumDecoder(ConsumptionOptionType)})
    type = ConsumptionOptionType.Delivery

    @field({decoder: ConsumptionModeAvailabilityScheme, version: 59})
    availabilityScheme: ConsumptionModeAvailabilityScheme = new ConsumptionModeAvailabilityScheme()

    /* Interval (in minutes) for delivery timeslots */
    @field({decoder: IntegerDecoder, version: 59})
    maxDaysInAdvance = 7

    /* Interval (in minutes) for delivery timeslots */
    @field({decoder: IntegerDecoder, version: 59})
    interval = 15

    @field({decoder: new EnumDecoder(DeliveryLocationType), optional: true})
    activeDeliveryLocationsType: DeliveryLocationType = DeliveryLocationType.PostalCodes

    @field({decoder: new ArrayDecoder(DeliveryLocation), optional: true, version: 59, upgrade: () => []})
    deliveryLocations: Array<DeliveryLocation> = []
}

export class ConsumptionOptions extends AutoEncoder {
    @field({decoder: DineInConsumptionOption, defaultValue: () => DineInConsumptionOption.create({enabled: true})})
    dineIn: DineInConsumptionOption

    @field({decoder: TakeAwayConsumptionOption, defaultValue: () => TakeAwayConsumptionOption.create({enabled: false})})
    takeAway: TakeAwayConsumptionOption

    @field({decoder: DeliveryConsumptionOption, defaultValue: () => DeliveryConsumptionOption.create({enabled: false})})
    delivery: DeliveryConsumptionOption

    getAll(): Array<ConsumptionOption> {
        return [this.dineIn, this.takeAway, this.delivery]
    }

    getEnabledConsumptionOptions(): Array<ConsumptionOptionType> {
        return this.getAll().filter(co => co.enabled).map(co => co.type)
    }
}

export const ConsumptionOptionPatch = ConsumptionOption.patchType()
export const DineInConsumptionOptionPatch = DineInConsumptionOption.patchType()
export const TakeAwayConsumptionOptionPatch = TakeAwayConsumptionOption.patchType()
export const DeliveryConsumptionOptionPatch = DeliveryConsumptionOption.patchType()
export const ConsumptionOptionsPatch = ConsumptionOptions.patchType()
