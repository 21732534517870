





















































import {ComponentWithProperties, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {debounce} from 'debounce'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import {PriceHelper} from '../helpers/PriceHelper'
import EditCartItemOptionsView from './EditCartItemOptionsView.vue'
import VolumeUpsellProposalBox from './VolumeUpsellProposalBox.vue'
import {
    AvailabilityLabel,
    DRSTBoxItem,
    Stepper,
} from '@dorst/components'
import {isNullOrEmpty} from '@dorst/helpers'
import {CartItem, I18n, VolumeUpsellHelper, VolumeUpsellProposal} from '@dorst/structures'

@Component({
    components: {
        DRSTBoxItem,
        Stepper,
        AvailabilityLabel,
        VolumeUpsellProposalBox,
    },
})
export default class CartItemRow extends Mixins(NavigationMixin) {
    isNullOrEmty = isNullOrEmpty
    @Prop({required: true, type: CartItem})
    cartItem: CartItem

    @Prop({type: Boolean, default: false})
    useUnitPrice!: boolean

    @Prop({required: true, type: Array})
    subItems: Array<CartItem>

    @Prop({required: false, type: Boolean})
    upsellRow: boolean = false

    get name() {
        return this.cartItem.product.getNameForI18n(this.$i18n as any)
    }

    get prices() {
        return this.cartItem.getPrices(CartManager.cart.subItems)
    }

    get price() {
        return this.useUnitPrice
            ? this.prices.unitPrice
            : this.prices.price
    }

    get formattedUnitPrice() {
        if (this.cartItem.amount <= 1) {
            return null
        }
        const formattedUnitPrice = PriceHelper.format({
            price: this.prices.unitPrice * this.cartItem.amount,
        })
        if (isNullOrEmpty(formattedUnitPrice)) {
            return null
        }
        return `(${formattedUnitPrice})`
    }

    get volumeUpsellsProposal(): VolumeUpsellProposal | null {
        const volumeUpsells = ServerManager.shop.getVolumeUpsellsWithConsumerProducts(this.cartItem.product.id, this.cartItem.selectedVariantInfo.variantId)
        return VolumeUpsellHelper.calculateVolumeUpsell(this.cartItem, volumeUpsells)
    }

    convertIntoVolumeUpsellsProposal() {
        if (this.volumeUpsellsProposal === null) {
            return
        }

        const {item, subItems} = this.volumeUpsellsProposal
        if (this.volumeUpsellsProposal.item.selectedVariant.hasOptionGroups) {
            this.showUpsellProposalDetail(item, subItems)
        } else {
            CartManager.convertItem(this.cartItem, item, subItems)
        }
    }

    showUpsellProposalDetail = debounce((item: CartItem, subItems: Array<CartItem>) => {
        this.present(new ComponentWithProperties(EditCartItemOptionsView, {
            cartItem: item,
            subCartItems: subItems,
            editing: true,
            volumeUpsellView: true,
            onSave: (newCartItem: CartItem, subItems: Array<CartItem>) => {
                CartManager.convertItem(this.cartItem, newCartItem, subItems)
            },
        }).setDisplayStyle('popup'))
        return {item, subItems}
    }, 500, true)

    deleteItem() {
        this.$emit('delete')
    }

    change() {
        this.$emit('change')
    }

    get optionDescription(): string {
        return this.cartItem.optionDescriptionForI18n(this.subItems, this.$i18n as any)
    }

    get availabilityTexts() {
        return this.cartItem.product.getAvailabilityTexts(ServerManager.shop.timezone, this.$i18n as I18n)
    }
}
