import {AutoEncoder, BooleanDecoder, field} from '@simonbackx/simple-encoding'

import {POSSystemType} from './POSSystemLink'
import {CloudPosCategory} from './cloudpos/CloudPosCategory'
import {DeliverectCategory} from './deliverect/DeliverectCategory'
import {DICategory} from './digitin/DigitinCategory'
import {LCategory} from './lightspeed/LCategory'
import {TBCategoriesMeta, TBCategory} from './topbrands/TBCategory'
import {UDeparment} from './untill/UDepartment'

export class CategorySyncedFields extends AutoEncoder {
    @field({decoder: BooleanDecoder, optional: true})
    name = true

    @field({decoder: BooleanDecoder, optional: true})
    description = true

    @field({decoder: BooleanDecoder, optional: true})
    enabled = true

    @field({decoder: BooleanDecoder, optional: true})
    categoryId = true

    static getFieldTranslationKey(field: string) {
        // cast to get auto-suggested cases
        switch (field as Exclude<keyof CategorySyncedFields, keyof AutoEncoder>) {
            case 'categoryId':
                return 'common.fields.categories.posSync.categoryId'
            case 'description':
                return 'common.fields.categories.posSync.description'
            case 'enabled':
                return 'common.fields.categories.posSync.enabled'
            case 'name':
                return 'common.fields.categories.posSync.name'
            default:
                console.error(`No translation found for field "${field}" in CategorySyncedFields`)
                return ''
        }
    }
}

export class CategoryMeta extends AutoEncoder {
    @field({decoder: CategorySyncedFields, optional: true})
    syncedFields = CategorySyncedFields.create({})

    @field({decoder: LCategory, nullable: true, optional: true})
    lightspeedL: LCategory | null = null

    @field({decoder: UDeparment, nullable: true, optional: true})
    untill: UDeparment | null = null

    @field({decoder: TBCategory, version: 69, nullable: true, optional: true})
    @field({
        decoder: TBCategoriesMeta, version: 82, nullable: true, optional: true, upgrade: (old: TBCategory | null) => {
            return old === null ? null : TBCategoriesMeta.create({category: old})
        },
    })
    topBrands: TBCategoriesMeta | null = null

    @field({decoder: DICategory, nullable: true, optional: true})
    digitin: DICategory | null = null

    @field({decoder: CloudPosCategory, nullable: true, optional: true})
    cloudpos: CloudPosCategory | null = null

    @field({decoder: DeliverectCategory, nullable: true, optional: true})
    deliverect: DeliverectCategory | null = null

    get isExternal(): boolean {
        return this.posType !== undefined
    }

    get posType(): POSSystemType | undefined {
        if (this.digitin != null) {
            return POSSystemType.Digitin
        } else if (this.lightspeedL != null) {
            return POSSystemType.Lightspeed
        } else if (this.untill != null) {
            return POSSystemType.Untill
        } else if (this.cloudpos != null) {
            return POSSystemType.CloudPos
        } else if (this.topBrands != null) {
            return POSSystemType.TopBrands
        } else if (this.deliverect != null) {
            return POSSystemType.Deliverect
        }
        return undefined
    }

    categoryBelongsToPosSystem(posSystemType: POSSystemType): boolean {
        return this.posType === posSystemType
    }
}
