var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ContextMenu",
    _vm._b(
      { staticClass: "country-code-context", attrs: { scrollable: true } },
      "ContextMenu",
      { x: _vm.x, y: _vm.y },
      false
    ),
    [
      _c(
        "TextInputRow",
        {
          ref: "inputRow",
          staticClass: "search-bar",
          attrs: {
            autocomplete: "search",
            placeholder: _vm.$t("customer.search.placeholder"),
            type: "search"
          },
          model: {
            value: _vm.query,
            callback: function($$v) {
              _vm.query = $$v
            },
            expression: "query"
          }
        },
        [
          _c("span", {
            staticClass: "icon search",
            attrs: { slot: "left" },
            slot: "left"
          }),
          _vm._v(" "),
          _c("button", {
            staticClass: "button icon close",
            attrs: { slot: "right" },
            on: {
              click: function($event) {
                $event.preventDefault()
                $event.stopPropagation()
                _vm.query = ""
              }
            },
            slot: "right"
          })
        ]
      ),
      _vm._v(" "),
      _vm.activeCountryObject
        ? _c(
            "DRSTBoxItem",
            {
              staticClass: "active-rule country-code-item",
              attrs: { "element-name": "label", selectable: false }
            },
            [
              _c(
                "div",
                { staticClass: "flag-name" },
                [
                  _c("country-flag", {
                    staticClass: "drst-flag",
                    attrs: {
                      country: _vm.activeCountryObject.code,
                      size: "normal"
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "country-name" }, [
                    _vm._v(_vm._s(_vm.activeCountryObject.name))
                  ]),
                  _vm._v(" "),
                  _c("img", {
                    staticClass: "check-mark",
                    attrs: {
                      src: require("@dorst/assets/icons/primary/check-line.svg")
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "country-code" }, [
                _vm._v(_vm._s(_vm.activeCountryObject.dialCode))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.filteredCodes.length === 0
        ? _c("DRSTBoxItem", { staticClass: "error-box-item" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("consumer.validationErrors.noCountryMatches")) +
                "\n    "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.filteredCodes, function(country) {
        return _c(
          "DRSTBoxItem",
          {
            key: country.code,
            staticClass: "country-code-item",
            class: { "active-rule": _vm.isActive(country.code) },
            attrs: { "element-name": "label", selectable: true },
            on: {
              click: function($event) {
                return _vm.update(country.dialCode, country.code)
              }
            }
          },
          [
            _c(
              "div",
              { staticClass: "flag-name" },
              [
                _c("country-flag", {
                  staticClass: "drst-flag",
                  attrs: { country: country.code, size: "normal" }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "country-name" }, [
                  _vm._v(_vm._s(country.name))
                ]),
                _vm._v(" "),
                _vm.isActive(country.code)
                  ? [
                      _c("img", {
                        staticClass: "check-mark",
                        attrs: {
                          src: require("@dorst/assets/icons/primary/check-line.svg")
                        }
                      })
                    ]
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            _c("span", { staticClass: "country-code" }, [
              _vm._v(_vm._s(country.dialCode))
            ])
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }