var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ContextMenu",
    _vm._b({}, "ContextMenu", { x: _vm.x, y: _vm.y }, false),
    [
      _c(
        "DRSTBoxItem",
        {
          attrs: { selectable: true },
          on: {
            click: function($event) {
              _vm.pop()
              _vm.onEdit()
            }
          }
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("customer.details.editAddress")) +
              "\n    "
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "DRSTBoxItem",
        {
          attrs: { selectable: true },
          on: {
            click: function($event) {
              _vm.pop()
              _vm.onDelete()
            }
          }
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("customer.details.deleteAddress")) +
              "\n    "
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }