export enum TBBrands {
    PIZZAHUT = 'Pizza hut',
    PIZZAHUTDEV = 'Pizza hut dev',
    WASBAR = 'Wasbar',
    PAUL = 'Paul',
}

export class TBBrandsHelper {
    static getSlug(type: TBBrands) {
        switch (type) {
            case TBBrands.PIZZAHUT:
                return 'pizzahut'
            case TBBrands.PIZZAHUTDEV:
                return 'pizzahut-acc'
            case TBBrands.WASBAR:
                return 'wasbar'
            case TBBrands.PAUL:
                return 'paul'
        }
    }
}
