


































import Fuse from 'fuse.js'
import {Component, Prop, Vue} from 'vue-property-decorator'

import DRSTBox from './DRSTBox.vue'
import DRSTBoxItem from './DRSTBoxItem.vue'
import TextInputRow from './inputs/TextInputRow.vue'

@Component({
    components: {
        DRSTBox,
        DRSTBoxItem,
        TextInputRow,
    },
})
export default class DRSTBoxFilterable extends Vue {
    @Prop({required: true})
    items: Array<any>

    @Prop({type: Number, default: 10})
    maxItems: number = 10

    @Prop({required: true})
    keys: Array<string>

    query: string = ''

    get filteredItems(): Array<any> {
        if (this.query === '' || this.keys.length === 0) {
            return this.items
        }

        return new Fuse(this.items, {
            keys: [...this.keys],
            findAllMatches: true,
            includeScore: true,
        }).search(this.query).filter(it => (it.score ?? 1) < 0.4).map((it: {item: any}) => it.item)
    }

    clear() {
        this.query = ''
    }
}
