import {Environment} from './environment.interface'
import {Language} from '@dorst/structures'

export const environment: Environment = {
    api: 'https://api.dorst.app',
    availableLanguages: [
        Language.Dutch,
        Language.English,
        Language.French,
        Language.Portuguese,
    ],
    defaultCountry: 'BE',
    domain: 'dorst',
    easterEggEnabled: true,
    enableSearchEngineIndexing: true,
    environment: 'production',
    features: {
        tealium: false,
    },
    metaTitle: 'Dorst',
    sentry: {
        dsn: 'https://3810f5b7df2d43b1bec61e46573c540e@o1110032.ingest.sentry.io/6161081',
        enabled: true,
    },
}
