























































import {ComponentWithProperties, NavigationMixin} from '@simonbackx/vue-app-navigation'
import numbro from 'numbro'
import VueI18n from 'vue-i18n'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {googleDistanceMatrix} from '../actions/location'
import {AddressManager} from '../classes/AddressManager'
import {ServerManager} from '../classes/ServerManager'
import {environment} from '../environments/environment'
import AddressContextMenu from './AddressContextMenu.vue'
import DeliveryAddressView from './DeliveryAddressView.vue'
import {DRSTBox, DRSTBoxItem, LongPressDirective, Radio, RemarkBlock} from '@dorst/components'
import {
    Address,
    AddressBookEntry,
    AddressBookEntryPatch,
    DeliveryLocation,
    DeliveryLocationHelper,
    DeliveryLocationType,
    DeliveryRangeUnit,
    DistanceMatrixRequest,
    I18n,
} from '@dorst/structures'
import {Formatter} from '@dorst/validation'

type EnrichedEntry = AddressBookEntry & {location?: DeliveryLocation; errorMessage?: VueI18n.TranslateResult}

@Component({
    components: {
        DRSTBox,
        RemarkBlock,
        DRSTBoxItem,
        Radio,
    },
    directives: {
        longpress: LongPressDirective,
    },
})
export default class AddressBookBox extends Mixins(NavigationMixin) {
    @Prop({required: false})
    title: string | undefined

    entries: Array<AddressBookEntry> = []
    selectedEntry: string | null = null

    distanceMap = new Map<string, number | null>()
    forceEnrich = 0

    async mounted() {
        await this.refreshEntries()
        const entry = this.enrichedEntries.find(({id}) => id === this.selectedEntry)
        this.selectEntry(entry ?? null)
    }

    async activated() {
        await this.refreshEntries()

        const entry = this.enrichedEntries.find(({id}) => id === this.selectedEntry)
        this.selectEntry(entry ?? null)
    }

    async fetchDistanceToShop() {
        if (ServerManager.shop.address === null) {
            return
        }
        for (const entry of this.entries) {
            if (entry.address.latLng && !this.distanceMap.has(entry.id)) {
                const {distanceInMeters} = await googleDistanceMatrix(DistanceMatrixRequest.create({
                    from: entry.address,
                    to: ServerManager.shop.address,
                }))
                this.distanceMap.set(entry.id, distanceInMeters)
            }
        }
        this.forceEnrich++
    }

    get deliveryLocations(): Array<DeliveryLocation> {
        return ServerManager.shop.consumptionOptions.delivery.deliveryLocations
    }

    get enrichedEntries(): Array<EnrichedEntry> {
        if (this.forceEnrich) {/** */}
        const activeDeliveryLocations = this.deliveryLocations.filter(el => el.type === ServerManager.shop.consumptionOptions.delivery.activeDeliveryLocationsType)
        if (ServerManager.shop.consumptionOptions.delivery.activeDeliveryLocationsType === DeliveryLocationType.PostalCodes) {
            return this.entries.map((entry) => {
                const location = activeDeliveryLocations
                    .filter(location => location.postalCodes.some(code => code.code == entry.address.postalCode))
                    .sort((a, b) => a.price - b.price)[0]

                if (location === undefined) {
                    return {
                        ...entry,
                        errorMessage: this.$t('customer.details.errors.noDeliveryToAddressShort'),
                    } as EnrichedEntry
                } else {
                    return {...entry, location} as EnrichedEntry
                }
            })
        } else if (ServerManager.shop.consumptionOptions.delivery.activeDeliveryLocationsType === DeliveryLocationType.Ranges) {
            return this.entries.map(entry => {
                const distance = this.distanceMap.get(entry.id) ?? Infinity
                const location = activeDeliveryLocations
                    .filter(location => distance <= location.range.transformAmount(DeliveryRangeUnit.m))
                    .sort((a, b) => a.price - b.price)[0]
                if (location === undefined) {
                    let formattedDistance = DeliveryLocationHelper.transformDistance(distance, DeliveryRangeUnit.m, DeliveryRangeUnit.km)
                    formattedDistance = Formatter.roundToDigit(formattedDistance, -1)

                    return {
                        ...entry,
                        errorMessage: isFinite(distance)
                            ? this.$t('customer.details.errors.deliveryToAddressTooFar', {
                                amount: numbro(formattedDistance).format({
                                    mantissa: 1,
                                    trimMantissa: true,
                                }),
                                unit: DeliveryLocationHelper.getDeliveryRangeUnitShort(DeliveryRangeUnit.km, this.$i18n as I18n),
                            })
                            : this.$t('customer.details.errors.deliveryAddressNotFound'),
                    } as EnrichedEntry
                } else {
                    return {...entry, location} as EnrichedEntry
                }
            })
        }
        return this.entries
    }

    async refreshEntries() {
        this.entries = AddressManager.entries
        this.selectedEntry = AddressManager.lastSelected
        await this.fetchDistanceToShop()
    }

    selectEntry(entry: EnrichedEntry | null) {
        this.$emit('select', {
            address: entry?.address ?? null,
            location: entry?.location ?? null,
        })
        this.selectedEntry = entry?.id ?? null
        AddressManager.setLastSelected(this.selectedEntry)
    }

    async addAddress() {
        const address = Address.createDefault(ServerManager.shop.address?.country ?? environment.defaultCountry)

        this.present(new ComponentWithProperties(DeliveryAddressView, {
            address,
            onSave: async (patch) => {
                const entry = AddressManager.addAddress(address.patch(patch))
                await this.refreshEntries()
                const enrichedEntry = this.enrichedEntries.find(({id}) => id === entry.id)
                this.selectEntry(enrichedEntry ?? null)
            },
        }).setDisplayStyle('popup'))
    }

    rightClickMenu(event, entryId: string) {
        const entry = this.entries.find(el => el.id === entryId)
        if (entry === undefined) {
            return
        }
        const displayedComponent = new ComponentWithProperties(
            AddressContextMenu,
            {
                x: event.touches && event.touches.length > 0 ? event.touches[0].clientX : event.clientX,
                y: event.touches && event.touches.length > 0 ? event.touches[0].clientY + 20 : event.clientY + 10,
                onEdit: () => {
                    this.present(new ComponentWithProperties(DeliveryAddressView, {
                        address: entry.address, onSave: async (patch) => {
                            AddressManager.updateEntry(AddressBookEntryPatch.create({id: entry.id, address: entry.address.patch(patch)}))
                            this.distanceMap.delete(entry.id)
                            await this.refreshEntries()
                            const updatedEntry = this.enrichedEntries.find(({id}) => id === this.selectedEntry)
                            if (updatedEntry) {
                                this.selectEntry(updatedEntry)
                            }
                        },
                    }).setDisplayStyle('popup'))
                },
                onDelete: async () => {
                    AddressManager.deleteEntry(entry.id)
                    await this.refreshEntries()
                },
            },
        )
        this.present(displayedComponent.setDisplayStyle('overlay'))
    }
}
