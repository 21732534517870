import {ArrayDecoder, AutoEncoder, AutoEncoderPatchType, BooleanDecoder, EnumDecoder, field, IntegerDecoder, PartialWithoutMethods, PatchType, StringDecoder} from '@simonbackx/simple-encoding'

import {Address} from './Address'
import {ConsumptionOptions} from './ConsumptionOptions'
import {IDCategory} from './IDCategory'
import {IDTableCategory} from './IDTableCategory'
import {Image} from './Image'
import {Language, LanguageDecoder, TranslatedString, TranslatedStringDecoder, TranslatedStringPatch} from './Language'
import {PaymentMethod, PaymentMethodType} from './PaymentMethod'
import {PaymentProvider} from './PaymentProvider'
import {Product} from './Product'
import {ShopExternalLinks} from './ShopExternalLinks'
import {
    ShopMessage,
    TranslatedShopMessage,
    TranslatedShopMessageToTranslatedShopMessage,
    TranslatedStringToTranslatedShopMessage,
} from './ShopMessage'
import {ShopPersonalisation} from './ShopPersonalisation'
import {ShopType, ShopTypeDecoder} from './ShopType'
import {Table} from './Table'
import {TransactionFees} from './TransactionFees'
import {UpsellGroup} from './Upsell'
import {VolumeUpsell} from './VolumeUpsell'
import {DiscountSource} from './discounts/DiscountSource'
import {Currency} from '@dorst/enums'

export class IDShopConsumer extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    id: number

    @field({decoder: StringDecoder})
    name: string

    @field({decoder: StringDecoder})
    uri: string

    @field({decoder: StringDecoder, nullable: true, version: 47})
    domain: string | null = null

    /**
     * The language used when a product doesn't have a translation in a given language. This is also the language used for communication towards the shop and the default language when editing products
     */
    @field({decoder: LanguageDecoder, version: 30, upgrade: () => Language.Dutch})
    language: Language

    @field({decoder: new EnumDecoder(Currency), version: 52, upgrade: () => Currency.EUR})
    currency: Currency = Currency.EUR

    @field({decoder: ShopTypeDecoder, version: 43, upgrade: () => ShopType.Default})
    type: ShopType = ShopType.Default

    @field({decoder: Address, version: 40, upgrade: () => null, nullable: true})
    address: Address | null = null

    /**
     * Products are not sorted (move operations in patches do not have any effect). The backend might decide to not return all products in the shop (because that might be too much).
     * You'll need to load them manually when needed and append them in here manually.
     * For memory management it is a good idea to clear all products from time to time when not needed anymore
     */
    @field({decoder: new ArrayDecoder(Product)})
    products: Array<Product>

    /**
     * Categories are not sorted (move operations in patches do not have any effect). The backend might decide to not return all categories of a shop (because that might be too much).
     * You'll need to load them manually when needed and append them in here manually.
     * For memory management it is a good idea to clear all categories from time to time when not needed anymore
     */
    @field({decoder: new ArrayDecoder(IDCategory)})
    categories: Array<IDCategory>

    @field({decoder: new ArrayDecoder(UpsellGroup), version: 73, upgrade: () => []})
    upsellGroups: Array<UpsellGroup>

    @field({decoder: new ArrayDecoder(VolumeUpsell), version: 87, upgrade: () => []})
    volumeUpsells: Array<VolumeUpsell>

    @field({decoder: new ArrayDecoder(StringDecoder)})
    mainCategoryIds: Array<string>

    @field({decoder: new ArrayDecoder(Table)})
    tables: Array<Table>

    @field({decoder: new ArrayDecoder(IDTableCategory)})
    tableCategories: Array<IDTableCategory>

    @field({decoder: new ArrayDecoder(StringDecoder)})
    mainTableCategoryIds: Array<string>

    @field({decoder: BooleanDecoder})
    enableOrdering: boolean

    @field({decoder: StringDecoder, nullable: true, optional: true})
    facebookPixelId: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    googleTagManagerId: string | null = null

    /**
     * When the order system is off, this property determines whether any order buttons such as
     * _Add to cart_ are shown to customers. Shops that only use Dorst for displaying their menu
     * would find their customers seeing a button stating _This shop is closed_ with this option set
     * to `false`.
     */
    @field({decoder: BooleanDecoder})
    hideOrderButtonsWhenOrderSystemIsOff: boolean

    @field({decoder: new ArrayDecoder(new EnumDecoder(PaymentMethodType)), version: 11, upgrade: () => []})
    @field({
        decoder: new ArrayDecoder(PaymentMethod), version: 41, upgrade: (old: Array<PaymentMethodType>) => {
            return old.map(type => PaymentMethod.create({type, transactionFees: TransactionFees.default(type, PaymentProvider.Paynl), provider: PaymentProvider.Paynl}))
        }, downgrade: (n: Array<PaymentMethod>) => {
            return n.map(({type}) => type)
        },
    })
    paymentMethods: Array<PaymentMethod>

    @field({decoder: StringDecoder, nullable: true, version: 20, upgrade: () => null})
    @field({
        decoder: TranslatedStringDecoder,
        version: 71,
        nullable: true,
        upgrade: (str: string | null): TranslatedString | null => {
            return str ? new TranslatedString({
                [Language.Dutch]: str,
            }) : null
        },
        downgrade: (translatedStr: TranslatedString | null): string | null => {
            return translatedStr ? translatedStr.getForLanguage(Language.Dutch) : null
        },
        upgradePatch: (str: PatchType<string>): PatchType<TranslatedString> => {
            if (!str) {
                return new TranslatedStringPatch({})
            }
            return new TranslatedStringPatch({[Language.Dutch]: str})
        },
        downgradePatch: (translatedStr: PatchType<TranslatedString>): PatchType<string> => {
            if (translatedStr[Language.Dutch]) {
                return translatedStr[Language.Dutch] ?? ''
            }
            return undefined
        },
    })
    @field({
        decoder: TranslatedShopMessage,
        version: 91,
        nullable: true,
        optional: true,
        ...TranslatedStringToTranslatedShopMessage,
    })
    orderConfirmedMessage: TranslatedShopMessage | null = null

    @field({decoder: BooleanDecoder, version: 23, upgrade: () => false})
    enableTips = false

    @field({decoder: BooleanDecoder, version: 32, upgrade: () => false})
    enableVoiceNotifications = false

    /**
     * null = don't ask for dine in; ask for take away / delivery (default)
     * true = always ask
     * false = never ask (also not for delivery/take away)
     */
    @field({decoder: BooleanDecoder, version: 52, upgrade: () => false})
    @field({decoder: BooleanDecoder, nullable: true, version: 64, upgrade: (old: boolean) => old ? true : null, downgrade: (old: boolean | null) => old === true})
    enableEmail: boolean | null = null

    @field({decoder: BooleanDecoder, optional: true})
    enableName = true

    /**
     * null = default value (alwasy ask for now because of covid)
     * true = always ask (to collect data)
     * false = never ask (also not for delivery/take away)
     */
    @field({decoder: BooleanDecoder, nullable: true, version: 64})
    @field({
        decoder: BooleanDecoder, nullable: true, version: 78, upgrade: (old) => {
            if (old === null) {
                return false
            }
            return old
        },
    })
    enablePhone = false

    @field({decoder: BooleanDecoder, version: 52, upgrade: () => true})
    enableSeparatedName = true

    @field({decoder: BooleanDecoder})
    enableRemarkField = false

    @field({decoder: BooleanDecoder, optional: true})
    enableHidePrices = false

    @field({decoder: StringDecoder, version: 52, upgrade: () => 'Europe/Brussels'})
    timezone = 'Europe/Brussels'

    @field({decoder: BooleanDecoder, version: 43, upgrade: () => false})
    enableTableCategories = false

    @field({decoder: ShopMessage, version: 47, nullable: true, upgrade: () => null})
    @field({
        decoder: TranslatedShopMessage,
        nullable: true,
        version: 71,
        upgrade: (shopMessage: ShopMessage | null): TranslatedShopMessage | null => {
            if (!shopMessage) {
                return null
            }
            return TranslatedShopMessage.create({
                title: new TranslatedString({
                    [Language.Dutch]: shopMessage.title,
                }),
                text: new TranslatedString({
                    [Language.Dutch]: shopMessage.text,
                }),
            })
        },
        downgrade: (translatedShopMessage: TranslatedShopMessage | null): ShopMessage | null => {
            if (!translatedShopMessage) {
                return null
            }
            return ShopMessage.create({
                title: translatedShopMessage.title.getForLanguage(Language.Dutch),
                text: translatedShopMessage.text.getForLanguage(Language.Dutch),
            })
        },
        upgradePatch: (shopMessage: PartialWithoutMethods<AutoEncoderPatchType<ShopMessage>>): AutoEncoderPatchType<TranslatedShopMessage> => {
            const patch = TranslatedShopMessage.patch({})
            if (shopMessage.title) {
                patch.title = new TranslatedStringPatch({[Language.Dutch]: shopMessage.title})
            }
            if (shopMessage.text) {
                patch.text = new TranslatedStringPatch({[Language.Dutch]: shopMessage.text})
            }
            return patch
        },
        downgradePatch: (translatedShopMessage: PartialWithoutMethods<AutoEncoderPatchType<TranslatedShopMessage>>): AutoEncoderPatchType<ShopMessage> | undefined => {
            return ShopMessage.patch({title: translatedShopMessage.title?.[Language.Dutch] ?? '', text: translatedShopMessage.text?.[Language.Dutch] ?? ''})
        },
    })
    @field({
        decoder: TranslatedShopMessage,
        nullable: true,
        optional: true,
        version: 91,
        ...TranslatedShopMessageToTranslatedShopMessage,
    })
    customerMessage: TranslatedShopMessage | null = null

    @field({decoder: TranslatedShopMessage, version: 70, nullable: true, optional: true, upgrade: () => null})
    @field({
        decoder: TranslatedShopMessage,
        nullable: true,
        version: 91,
        ...TranslatedShopMessageToTranslatedShopMessage,
    })
    shopMessage: TranslatedShopMessage | null = null

    @field({decoder: StringDecoder, version: 66, nullable: true, optional: true})
    disclaimer: string | null = null

    @field({decoder: ShopPersonalisation, version: 45, nullable: true, upgrade: () => null})
    personalisation: ShopPersonalisation | null = null

    @field({decoder: ShopExternalLinks, nullable: true, optional: true})
    externalLinks: ShopExternalLinks | null = null

    @field({decoder: Image, optional: true, nullable: true})
    shopThumbnail: Image | null = null

    @field({decoder: Image, optional: true, nullable: true})
    shopBanner: Image | null = null

    @field({decoder: ConsumptionOptions, version: 52, upgrade: () => ConsumptionOptions.create({})})
    consumptionOptions: ConsumptionOptions = ConsumptionOptions.create({})

    @field({decoder: StringDecoder, nullable: true, version: 61, upgrade: () => ''})
    scoverId = ''

    @field({decoder: new ArrayDecoder(DiscountSource), optional: true})
    discountSources: Array<DiscountSource> = []

    /**
     * Remove products and categories that are not reachable via main categories
     */
    removeUnreachable() {
        const reachableProductIds: Array<string> = []
        let n: Array<string> = this.mainCategoryIds.slice(0)
        const newCategories: Array<IDCategory> = []

        while (n.length > 0) {
            const prev = n
            n = []
            for (const id of prev) {
                const category = this.categories.find(c => c.id == id)
                if (!category) {
                    // Invalid category
                    console.warn(`Received invalid category: ${id}`)
                    continue
                }

                const alreadyFound = newCategories.findIndex(c => c.id == id)
                if (alreadyFound !== -1) {
                    continue
                }
                newCategories.push(category)

                n.push(...category.categoryIds)
                reachableProductIds.push(...category.productIds)
            }
        }

        const newProducts: Array<Product> = []
        for (const productId of reachableProductIds) {
            const product = this.products.find(c => c.id == productId)
            if (product) {
                newProducts.push(product)
            }
        }

        this.products = newProducts
        this.categories = newCategories
    }

    // Remove information that shouldn't be visible to the consumer
    removeNonConsumer() {
        this.paymentMethods.forEach((method) => {
            if (method.settings) {
                method.settings.starnetIP = null
                method.settings.apiKey = null
                method.settings.shopIdentifier = null
            }
            method.transactionFees = TransactionFees.create({})
        })
    }
}

export const IDShopConsumerPatch = IDShopConsumer.patchType()
