var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drst-view shop-selection" }, [
    _c("main", { staticClass: "inner-wrapper" }, [
      _c(
        "section",
        { staticClass: "header", class: { "logo-only": _vm.logoOnlyHeader } },
        [
          _vm.titleAboveLogos && _vm.title
            ? _c("h1", [
                _vm._v(
                  "\n                " + _vm._s(_vm.title) + "\n            "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.headerLogos
            ? _c(
                "div",
                { staticClass: "logos-container" },
                _vm._l(_vm.headerLogos, function(logoUrl, index) {
                  return _c(
                    "div",
                    {
                      key: "headerlogo-" + index,
                      staticClass: "logo",
                      class: { "is-standalone": _vm.standaloneLogo }
                    },
                    [_c("img", { attrs: { src: logoUrl, alt: "" } })]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.titleAboveLogos && _vm.title
            ? _c("h1", [
                _vm._v(
                  "\n                " + _vm._s(_vm.title) + "\n            "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("p", [_vm._v(_vm._s(_vm.description))])
        ]
      ),
      _vm._v(" "),
      _vm.shops
        ? _c(
            "section",
            { staticClass: "shops-thumbs-container" },
            _vm._l(_vm.shops, function(shop, index) {
              return _c("DRSTThumbnail", {
                key: index,
                attrs: {
                  title: shop.title,
                  "image-url": shop.image,
                  description: shop.description,
                  "image-fit": shop.imageFit,
                  selectable: true
                },
                nativeOn: {
                  click: function($event) {
                    return _vm.goToShop(shop.id)
                  }
                }
              })
            }),
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("section", { staticClass: "footer" }, [
        _c("h2", [_vm._v(_vm._s(_vm.footerTitle))]),
        _vm._v(" "),
        _vm.footerLogos
          ? _c(
              "div",
              { staticClass: "logos-container" },
              _vm._l(_vm.footerLogos, function(logoUrl, index) {
                return _c(
                  "div",
                  { key: "footerlogo-" + index, staticClass: "logo" },
                  [_c("img", { attrs: { src: logoUrl, alt: "" } })]
                )
              }),
              0
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.disclaimer
          ? _c("p", { staticClass: "disclaimer" }, [
              _vm._v(
                "\n                " + _vm._s(_vm.disclaimer) + "\n            "
              )
            ])
          : _vm._e()
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }