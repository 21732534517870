export class CancelToken {
    private _cancelled = false

    isCancelled() {
        return this._cancelled
    }

    cancel() {
        this._cancelled = true
    }
}

export class Loadable<T> {
    private _loading = false
    private _value: T | null = null

    private _onCancel = () => { /** Do nothing */
    }

    constructor(value: T | null = null, getValue?: () => Promise<T>) {
        this._value = value

        if (getValue) {
            this.load(getValue)
        }
    }

    get loading() {
        return this._loading
    }

    get value() {
        return this._value
    }

    load(getValue: (ct: CancelToken) => Promise<T | null>, onCancel = () => { /** Do nothing */
    }) {
        if (this._onCancel) {
            this._onCancel()
        }
        this._loading = true

        const ct = new CancelToken()
        this._onCancel = () => {
            ct.cancel()
            onCancel()
        }

        void getValue(ct)
            .then((t) => {
                if (ct.isCancelled()) {
                    return
                }

                this._value = t
            })
            .catch(() => {
                if (ct.isCancelled()) {
                    return
                }

                this._value = null
            })
            .finally(() => {
                if (ct.isCancelled()) {
                    return
                }

                this._loading = false
                this.cancel()
            })
    }

    cancel() {
        if (this._onCancel) {
            this._onCancel()
        }

        this._loading = false
    }

    reset(value: T | null = null) {
        this.cancel()
        this._value = value
    }
}

export class LoadableArray<T> {
    private _loading = false
    private _value: Array<T> = []

    private _onCancel = () => { /** Do nothing */
    }

    constructor(value: Array<T> = [], getValue?: () => Promise<Array<T>>) {
        this._value = value

        if (getValue) {
            this.load(getValue)
        }
    }

    get loading() {
        return this._loading
    }

    get value() {
        return this._value
    }

    load(getValue: (ct: CancelToken) => Promise<Array<T>>, onCancel = () => { /** Do nothing */
    }) {
        if (this._onCancel) {
            this._onCancel()
        }
        this._loading = true

        const ct = new CancelToken()
        this._onCancel = () => {
            ct.cancel()
            onCancel()
        }

        void getValue(ct)
            .then((t) => {
                if (ct.isCancelled()) {
                    return
                }

                this._value = t
            })
            .catch(() => {
                if (ct.isCancelled()) {
                    return
                }

                this._value = []
            })
            .finally(() => {
                if (ct.isCancelled()) {
                    return
                }

                this._loading = false
            })
    }

    cancel() {
        if (this._onCancel) {
            this._onCancel()
        }

        this._loading = false
    }

    reset(value: Array<T> = []) {
        this.cancel()
        this._value = value
    }
}
