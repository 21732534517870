import {Decoder, EnumDecoder} from '@simonbackx/simple-encoding'

import {ServerManager} from '../classes/ServerManager'
import {
    InnovendStatusWrapper,
    OrderConsumer,
} from '@dorst/structures'

export class ExternalIntegrationActions {
    static async InnovendExternalValidationStatus(
        order: OrderConsumer,
    ): Promise<InnovendStatusWrapper> {
        return new Promise((resolve, reject) => {
            ServerManager.getServer()
                .request({
                    method: 'GET',
                    path: `/consumers/shops/${ServerManager.shop.id}/order/innovend-status/${order.uuid}`,
                    decoder: InnovendStatusWrapper as Decoder<InnovendStatusWrapper>,
                })
                .then(({data}) => resolve(data))
                .catch(reject)
        })
    }
}
