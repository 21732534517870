
























import {Component, Prop, Vue} from 'vue-property-decorator'

import {isRtl} from '@dorst/frontend-translations'

@Component({})
export default class ContextMenu extends Vue {
    @Prop({
        default: 0,
    })
    x!: number
    @Prop({
        default: false,
    })
    scrollable!: boolean

    /**
     * If scrollable, max height is 300px unless fullHeight is true. Has no usecase without scrollable.
     */
    @Prop({
        default: false,
    })
    fullHeight!: boolean

    @Prop({
        default: 0,
    })
    y!: number

    initialized = false
    top = 0
    inlineStart = 0

    get isRtl() {
        return isRtl()
    }

    mounted() {
        // setTimeout is needed so we use the correct rendered widths
        setTimeout(this.calculatePosition)
    }

    calculatePosition() {
        // Calculate position
        const width = (this.$refs.context as HTMLElement).offsetWidth
        const height = (this.$refs.context as HTMLElement).offsetHeight

        const viewPadding = 15

        const win = window
        const doc = document
        const docElem = doc.documentElement
        const body = doc.getElementsByTagName('body')[0]
        const clientWidth = win.innerWidth || docElem.clientWidth || body.clientWidth
        const clientHeight = win.innerHeight || docElem.clientHeight || body.clientHeight
        this.inlineStart = this.x - Math.max(0, width - (clientWidth - viewPadding - this.x))
        this.top = this.y - Math.max(0, height - (clientHeight - viewPadding - this.y))
        this.$el.addEventListener('contextmenu', this.pop, {passive: true})
        this.initialized = true
    }

    beforeDestroy() {
        this.$el.removeEventListener('contextmenu', this.pop)
    }

    pop() {
        this.$parent.$parent.$emit('pop')
    }

    activated() {
        document.addEventListener('keydown', this.onKey)
    }

    deactivated() {
        document.removeEventListener('keydown', this.onKey)
    }

    onKey(event) {
        if (event.defaultPrevented || event.repeat) {
            return
        }

        const key = event.key || event.keyCode

        if (key === 'Escape' || key === 'Esc' || key === 27) {
            this.pop()
            event.preventDefault()
        }
    }
}
