





























































import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({
    model: {
        prop: 'title',
        event: 'input',
    },
})
export default class DRSTBox extends Vue {
    @Prop({default: '', type: String})
    title!: string

    @Prop({default: false})
    editable!: boolean

    @Prop({default: false})
    hasError!: boolean

    /** Used to show input fields even when they are not editable. When editable == true this can be omitted */
    @Prop({default: false})
    forceRightSlot!: boolean

    @Prop({default: false})
    onlyAnnotation!: boolean

    @Prop({default: false})
    showAnnotationRight!: boolean

    @Prop({default: ''})
    titlePlaceholder: string

    @Prop({default: true})
    withBg!: boolean

    get editTitle() {
        return this.title
    }

    set editTitle(title: string) {
        this.$emit('input', title)
    }

    get annotationRight() {
        return this.hasTitleAnnotation && this.showAnnotationRight
    }

    get hasTitleAnnotation() {
        return this.title.length !== 0 && this.$slots['annotation'] != null
    }

    selectIfNotFocus(event) {
        if (event.target === document.activeElement) {
            return
        }
        event.target.select()
        event.preventDefault()
    }
}
