import {Formatter, PriceFormatterParams} from '@dorst/validation'

export const defaultLocale: string | Array<string> = (() => {
    if (window.navigator?.languages !== undefined) {
        return [...window.navigator.languages]
    } else if (window.navigator?.language === undefined) {
        return 'nl-BE'
    } else {
        return window.navigator.language
    }
})()

/**
 * Formats the given number as a price. Uses the user agent's preferred language for the formatting
 * by default.
 * For backend, see `Formatter.price` which requires the locale.
 * For backoffice, see `ShopManager.formatPrice` which sets the currency code based on the current
 * shop.
 * For consumers, see `PriceHelper.formatPrice` which sets the currency code base on the current
 * shop and hides the price if the `enableHidePrices` is enabled.
 */
export function formatPrice(input: PriceFormatterParams<number>): string
export function formatPrice(input: PriceFormatterParams<null | undefined>): null
export function formatPrice(input: PriceFormatterParams<number | null | undefined>): string | null
export function formatPrice(input: PriceFormatterParams<number | null | undefined>): string | null {
    return Formatter.price({
        ...input,
        locale: input.locale ?? defaultLocale,
    })
}
