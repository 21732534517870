import {ArrayDecoder, AutoEncoder, BooleanDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {Image} from './Image'
import {TranslatedString, TranslatedStringDecoder} from './Language'

export class PersonalisedText extends AutoEncoder {
    @field({decoder: StringDecoder})
    id!: string

    @field({decoder: TranslatedStringDecoder})
    value: TranslatedString
}

export class CssVariables extends AutoEncoder {
    @field({decoder: StringDecoder, nullable: true, optional: true})
    colorPrimary: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    colorPrimaryTouch: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    colorAccent: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    colorDark: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    colorDarkLight: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    colorRed: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    colorBackgroundShade: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    colorBackgroundShadeDarker: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    colorBackground: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    colorListBackground: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    colorBorder: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    colorBorderShade: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    colorBorderDarker: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    colorStepperButton: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    colorSplashscreenLanguageSwitcher: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    colorSplashscreenSocialIcons: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    borderWidth: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    borderRadius: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    borderRadiusSmall: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    borderRadiusButton: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    borderRadiusBox: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    buttonPadding: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    buttonHeight: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    buttonCounterPadding: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    segmentedControlTextOffset: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    fontNaturalBottomPadding: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    textShadowButton: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    textShadowTitle: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    textShadowSegmentedControl: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    emailColorBackground: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    emailColorContentBackground: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    emailColorTitle: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    emailColorText: string | null = null
}

export const CssVariablesPatch = CssVariables.patchType()

export class PersonalisationExceptions extends AutoEncoder {
    @field({decoder: BooleanDecoder, optional: true})
    hideBgImageOnTermsPage = false

    @field({decoder: BooleanDecoder, optional: true})
    hideBgImageOnPrivacyPolicyPage = false

    @field({decoder: BooleanDecoder, optional: true})
    hideBgImageOnShopMessage = false

    @field({decoder: BooleanDecoder, optional: true})
    hideBgImageOnOrderConfirmedMessage = false

    @field({decoder: BooleanDecoder, optional: true})
    hideBgImageOnPolicyCheckboxes = false
}

export class PersonalisationHeaderButton extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: TranslatedStringDecoder})
    name: TranslatedString = new TranslatedString({})

    @field({decoder: StringDecoder})
    url = ''

    @field({decoder: BooleanDecoder})
    enabled = false
}

export class ShopPersonalisation extends AutoEncoder {
    @field({decoder: Image, nullable: true, optional: true})
    logo: Image | null = null

    @field({decoder: BooleanDecoder, optional: true})
    logoIncludesShopName = false

    @field({decoder: BooleanDecoder, optional: true})
    alwaysShowSplashScreen = false

    @field({decoder: BooleanDecoder, optional: true})
    hideLogoOnMenu = false

    @field({decoder: BooleanDecoder, optional: true})
    hideLogoOnSplashScreen = false

    @field({decoder: Image, nullable: true, optional: true})
    backgroundImage: Image | null = null

    @field({decoder: Image, nullable: true, optional: true})
    splashBackgroundImage: Image | null = null

    @field({decoder: CssVariables, nullable: true, optional: true})
    cssVariables: CssVariables | null = null

    @field({decoder: new ArrayDecoder(PersonalisedText), optional: true})
    personalisedText: Array<PersonalisedText> = []

    @field({decoder: PersonalisationExceptions, optional: true, nullable: true})
    personalisationExceptions: PersonalisationExceptions | null = null

    @field({decoder: new ArrayDecoder(PersonalisationHeaderButton), optional: true})
    headerButtons: Array<PersonalisationHeaderButton> = []

    @field({decoder: StringDecoder, nullable: true, optional: true})
    emailIntro: string | null = null

    @field({decoder: Image, nullable: true, optional: true})
    emailLogo: Image | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    emailLogoLink: string | null = null
}

export const ShopPersonalisationPatch = ShopPersonalisation.patchType()
