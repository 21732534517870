





























import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {ServerManager} from '../../classes/ServerManager'
import {PruneHelper} from '../../helpers/PruneHelper'
import OptionGroupBox from './OptionGroupBox.vue'
import {Checkbox, DRSTBox, DRSTBoxItem, Radio, SegmentedControl, Stepper} from '@dorst/components'
import {i18n} from '@dorst/frontend-translations'
import {isNullOrEmpty} from '@dorst/helpers'
import {CartItem, CartItemOption, ConsumptionOptionType, Option, OptionGroup, OptionGroupMode, Product, TranslatedString} from '@dorst/structures'

@Component({
    components: {
        DRSTBoxItem,
        DRSTBox,
        Radio,
        Checkbox,
        Stepper,
        OptionGroupBox,
        SegmentedControl,
    },
    filters: {
        i18n: (translatedStr: TranslatedString) => translatedStr.getForI18n(i18n),
    },
})
export default class OptionGroupGroupBox extends Mixins(NavigationMixin) {
    @Prop({required: true, type: Array})
    optionGroups: Array<OptionGroup>

    @Prop({required: true, type: Map})
    optionGroupPriceOffsets: Map<string, number>

    @Prop({required: true, type: Array})
    subItems: Array<CartItem>

    @Prop({required: true})
    editable: boolean

    @Prop({required: true})
    editCartItem: CartItem

    @Prop({required: true})
    amountErrorsMap: Map<string, boolean>

    @Prop({required: true})
    consumptionMode: ConsumptionOptionType

    // You can pass options from optionGroups, we'll leave those untouched
    @Prop({type: Array})
    value: Array<CartItemOption>

    get tabLabels() {
        return this.optionGroups.map(el => el.name.getForI18n(i18n))
    }

    selectedGroupIdEdit: string | null = null
    set selectedGroupId(id: string) {
        this.selectedGroupIdEdit = id
    }
    get selectedGroupId() {
        return this.selectedGroupIdEdit ? this.selectedGroupIdEdit : this.optionGroups?.[0].id
    }

    get selectedGroup() {
        const selectedGroup = this.optionGroups.filter(el => this.selectedGroupId === el.id)
        return selectedGroup
    }

    setActiveGroup(id) {
        const newSelectedGroup = this.optionGroups.find(el => el.id === id)
        if (newSelectedGroup === undefined) {
            console.error(`Could not find the selected group with id ${id}`)
            return
        }

        const newDefaultOptions = this.getDefaultOptionsForGroup(newSelectedGroup)

        for (const option of newDefaultOptions) { // also add option-product-cartitems for default options if they have one.
            if (option.option.productLink === undefined) {
                continue
            }
            const optionProduct = this.getProductById(option.option.productLink!.productId, false)
            if (optionProduct === null) {
                console.error(`Could not find the selected group with id ${id}`)
                return
            }
            // pass empty array because subitems with their own subitems is not supported.
            let {cartItem} = CartItem.defaultForProduct(Product.formatProductForOption(optionProduct, option.option), [])
            cartItem = cartItem.overwriteOptionLinkData(
                this.editCartItem.id,
                option.option,
            )
            cartItem.selectedVariantInfo.options = this.getCartItemOptions(cartItem.selectedVariant.bundleOptionsForVariant())
            this.addOptionProduct(cartItem)
            option.subCartItemId = cartItem.id
        }

        // remove all options from the prev. selected category
        const updatedValue = this.value.filter(el => el.optionGroup.id !== this.selectedGroupId)
        // for all items that we will remove, also check if there are cartItems that need to be removed..
        for (const removedOption of this.value.filter(el => el.optionGroup.id === this.selectedGroupId)) {
            if (!isNullOrEmpty(removedOption.subCartItemId)) {
                this.removeOptionProduct(removedOption.subCartItemId)
            }
        }
        this.$emit('input', [...updatedValue, ...newDefaultOptions])
        this.selectedGroupIdEdit = id
    }

    getCartItemOptions(bundledOptionGroups: Array<Array<OptionGroup>>): Array<CartItemOption> {
        const cartItemOptions: Array<CartItemOption> = []
        for (const [defaultGroup] of bundledOptionGroups) {
            if (defaultGroup === undefined) {
                continue
            }
            const alreadyExists = this.editCartItem.options.some(o => o.optionGroup.id === defaultGroup.id)
            if (alreadyExists) {
                continue
            }

            if (defaultGroup.mode === OptionGroupMode.Single) {
                /** single (always a default) */
                const optionToSet = defaultGroup.defaultOption ?? defaultGroup.options[0]
                const [success, cartItemId] = this.addOptionProductIfNeeded(optionToSet, bundledOptionGroups)
                if (!success) {
                    console.error(`Skipping because we failed to add optionProudct for option with id: ${optionToSet.id}`)
                    continue
                }
                cartItemOptions.push(CartItemOption.create({
                    optionGroup: defaultGroup,
                    option: optionToSet,
                    amount: 1,
                    subCartItemId: cartItemId,
                }))
            } else {
                /** multiple */
                for (const option of defaultGroup.options) {
                    if ((option.minAmount !== null && option.minAmount > 0)
                        || (option.defaultAmount !== null && option.defaultAmount > 0)
                    ) {
                        const [success, cartItemId] = this.addOptionProductIfNeeded(option, bundledOptionGroups)
                        if (!success) {
                            console.error(`Skipping because we failed to add optionProudct for option with id: ${option.id}`)
                            continue
                        }
                        cartItemOptions.push(CartItemOption.create({
                            optionGroup: defaultGroup,
                            option: option,
                            amount: Math.max(0, option.minAmount ?? 0, option.defaultAmount ?? 0),
                            subCartItemId: cartItemId,
                        }))
                    }
                }

                if (defaultGroup.options.length === 1 && defaultGroup.minAmount !== null) {
                    const [success, cartItemId] = this.addOptionProductIfNeeded(defaultGroup.options[0], bundledOptionGroups)
                    if (!success) {
                        console.error(`Skipping because we failed to add optionProudct for option with id: ${defaultGroup.options[0].id}`)
                        continue
                    }
                    cartItemOptions.push(CartItemOption.create({
                        optionGroup: defaultGroup,
                        option: defaultGroup.options[0],
                        amount: defaultGroup.minAmount,
                        subCartItemId: cartItemId,
                    }))
                }
            }
        }
        return cartItemOptions
    }

    addOptionProductIfNeeded(option: Option, bundledOptionGroups: Array<Array<OptionGroup>>): [boolean, string | null] {
        if (option.productLink === undefined) {
            return [true, null]
        }
        const optionProduct = this.getProductById(option.productLink!.productId, false)
        if (optionProduct === null) {
            console.error(`Could not find optionProduct with ID: ${option.productLink!.productId}`)
            return [false, null]
        }
        const {cartItem} = CartItem.defaultForProduct(Product.formatProductForOption(optionProduct, option), []) // pass empty array because menu-items linked to menu-items is not supported.
        cartItem.selectedVariantInfo.options = [...this.editCartItem.options, ...this.getCartItemOptions(bundledOptionGroups)]
        this.addOptionProduct(cartItem.overwriteOptionLinkData(
            this.editCartItem.id,
            option,
        ))
        return [true, cartItem.id]
    }

    getProductById(id: string, excludeMenuProducts: boolean): Product | null {
        return PruneHelper.getProductById(ServerManager.shop, this.consumptionMode, id, excludeMenuProducts)
    }

    groupHasAmountError(groupId): boolean {
        return this.amountErrorsMap.get(groupId) ?? false
    }

    changedOptions($event) {
        this.$emit('input', $event)
    }

    getDefaultOptionsForGroup(group: OptionGroup): Array<CartItemOption> {
        const cartItemOptions: Array<CartItemOption> = []
        if (this.editCartItem.options.findIndex(o => o.optionGroup.id === group.id) > -1) {
            return cartItemOptions
        }

        for (const option of group.options) {
            if (option.minAmount || option.defaultAmount) {
                cartItemOptions.push(CartItemOption.create({
                    optionGroup: group,
                    option: option,
                    amount: Math.max(0, option.minAmount ?? 0, option.defaultAmount ?? 0),
                }))
            }
        }

        if (group.options.length === 1 && group.minAmount !== null) {
            cartItemOptions.push(CartItemOption.create({
                optionGroup: group,
                option: group.options[0],
                amount: group.minAmount,
            }))
        }

        if (group.mode === OptionGroupMode.Single) {
            if (group.defaultOption) {
                cartItemOptions.push(CartItemOption.create({
                    optionGroup: group,
                    option: group.defaultOption,
                    amount: 1,
                }))
            }
        }
        return cartItemOptions
    }

    removeOptionProduct(id: string) {
        this.$emit('removeOptionProduct', id)
    }
    addOptionProduct(cartItem: CartItem) {
        this.$emit('addOptionProduct', cartItem)
    }
}
