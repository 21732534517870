import {ArrayDecoder, AutoEncoder, BooleanDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'

import {TBTranslateableText, TBTranslateHelper} from './TBHelpers'

export class TBDefinitionSubCategory extends AutoEncoder {
    @field({decoder: StringDecoder, field: 'subCategoryId'})
    categoryId: string

    @field({decoder: new ArrayDecoder(TBTranslateableText)})
    name: Array<TBTranslateableText> = []

    @field({decoder: new ArrayDecoder(TBTranslateableText), optional: true})
    description: Array<TBTranslateableText> = []

    /**
     * Field definition by Kurt (TopBrands)
     * Als er een subcategory gekoppeld is aan de toppings moet je controleren op multipleSelection
     * Indien deze op false staat dan is het single select.
     *
     * Als er geen subcategory gekoppeld is of de multipleSelection = true dan
     * Checkboxes indien max=1, anders steppers
     * */
    @field({decoder: BooleanDecoder, optional: true})
    multipleSelection = false

    @field({decoder: StringDecoder, optional: true})
    image?: string

    get translatedDescription() {
        return TBTranslateHelper.getTranslation(this.description)
    }

    get translatedName() {
        return TBTranslateHelper.getTranslation(this.name)
    }
}

export class TBCategory extends AutoEncoder {
    @field({decoder: StringDecoder, optional: true})
    categoryId?: string

    @field({decoder: new ArrayDecoder(TBTranslateableText), optional: true})
    name: Array<TBTranslateableText> = []

    @field({decoder: new ArrayDecoder(TBTranslateableText), optional: true})
    description: Array<TBTranslateableText> = []

    @field({decoder: StringDecoder, optional: true})
    priceCategoryId?: string

    @field({decoder: BooleanDecoder, version: 82, defaultValue: () => false, upgrade: () => false})
    isToppingCategory = false

    /**
     * Field definition by Kurt (TopBrands)
     * Als er een subcategory gekoppeld is aan de toppings moet je controleren op multipleSelection
     * Indien deze op false staat dan is het single select.
     *
     * Als er geen subcategory gekoppeld is of de multipleSelection = true dan
     * Checkboxes indien max=1, anders steppers
     * */
    @field({decoder: BooleanDecoder, optional: true})
    multipleSelection = false

    @field({decoder: new ArrayDecoder(TBDefinitionSubCategory), optional: true})
    subCategories: Array<TBDefinitionSubCategory> = []

    @field({decoder: StringDecoder, optional: true})
    image?: string

    get translatedDescription() {
        return TBTranslateHelper.getTranslation(this.description)
    }

    get translatedName() {
        return TBTranslateHelper.getTranslation(this.name)
    }
}

export class TBCategoriesMeta extends AutoEncoder {
    @field({decoder: TBCategory})
    category: TBCategory

    @field({decoder: StringDecoder, optional: true})
    subCategoryId?: string
}

// ::: VB WASBAR :::
//
// {
//     "categoryId": "BAGELBURGER",
//     "name": [
//         {
//             "languageId": "NLB",
//             "languageValue": "Bagel Burgers"
//         },
//         {
//             "languageId": "FRB",
//             "languageValue": "Bagel Burgers"
//         },
//         {
//             "languageId": "ENU",
//             "languageValue": "Bagel Burgers"
//         }
//     ],
//     "description": [
//         {
//             "languageId": "NLB",
//             "languageValue": "Geserveerd met aardappelwafeltjes en truffelmayo"
//         },
//         {
//             "languageId": "FRB",
//             "languageValue": "Servi avec des gaufrettes de pommes de terre et mayo aux truffes"
//         },
//         {
//             "languageId": "ENU",
//             "languageValue": "Served with potato waffles and truffle mayo"
//         }
//     ],
//     "image": "assets/wasbar/dorst/20201109165654361.jpg",
//     "priceCategoryId": ""
// }
