


























import {Component, Prop, Vue} from 'vue-property-decorator'

import CloudPosIcon from '@dorst/assets/images/pos/cloudpos-icon.svg'
import DeliverectIcon from '@dorst/assets/images/pos/deliverect-icon.svg'
import DigitinIcon from '@dorst/assets/images/pos/digitin-icon.svg'
import DorstIcon from '@dorst/assets/images/pos/dorst-icon.svg'
import LightSpeedIcon from '@dorst/assets/images/pos/lightspeed-icon.svg'
import TopBrandsIcon from '@dorst/assets/images/pos/topbrands-icon.svg'
import UntillIcon from '@dorst/assets/images/pos/untill-icon.svg'
import {isNullOrEmpty} from '@dorst/helpers'
import {CategoryMeta, POSSystemType, ProductMeta, TableCategoryMeta, TableMeta} from '@dorst/structures'

@Component({})
export default class POSIcon extends Vue {
    @Prop({required: true, type: Object})
    meta: ProductMeta | CategoryMeta | TableMeta | TableCategoryMeta

    /**
     * Accepts the name of a meta.syncedFields property.
     * If the meta.syncedFields for this field is set to false we will display the dorst icon instead (because the POSlink is broken).
     */
    @Prop({required: false, type: String})
    field?: string

    /**
     * Direction we will apply the margin to the icon.
     */
    @Prop({required: false, default: () => 'end', type: String})
    marginPosition: 'start' | 'end' | 'both' | 'none'

    @Prop({required: false, default: () => false, type: Boolean})
    locked: boolean

    get posSystemIcon(): {
        src: string
        alt: string
    } {
        if (!isNullOrEmpty(this.field) && this.meta.syncedFields[this.field!] !== true) {
            return {
                src: DorstIcon,
                alt: 'Dorst',
            }
        }

        switch (this.meta.posType) {
            case POSSystemType.CloudPos:
                return {
                    src: CloudPosIcon,
                    alt: 'Cloudpos',
                }
            case POSSystemType.Deliverect:
                return {
                    src: DeliverectIcon,
                    alt: 'Deliverect',
                }
            case POSSystemType.Digitin:
                return {
                    src: DigitinIcon,
                    alt: 'Digitin',
                }
            case POSSystemType.Lightspeed:
                return {
                    src: LightSpeedIcon,
                    alt: 'LightSpeed',
                }
            case POSSystemType.TopBrands:
                return {
                    src: TopBrandsIcon,
                    alt: 'TopBrands',
                }
            case POSSystemType.Untill:
                return {
                    src: UntillIcon,
                    alt: 'Untill',
                }
            default:
                return {
                    src: DorstIcon,
                    alt: 'Dorst',
                }
        }
    }
}
