import {
    Decoder,
    ObjectData,
} from '@simonbackx/simple-encoding'
import {SimpleError} from '@simonbackx/simple-errors'
import {Vue} from 'vue-property-decorator'

import {DiscountActions} from '../actions/Discounts'
import {GetDayWithTimeslots} from '../actions/Timeslots'
import {AnalyticsController} from '../analytics/AnalyticsController'
import {environment} from '../environments/environment'
import {KioskModeService} from './KioskModeService'
import {ServerManager} from './ServerManager'
import {TealiumManager} from './TealiumManager'
import {i18n} from '@dorst/frontend-translations'
import {isNullOrEmpty} from '@dorst/helpers'
import {
    Cart,
    CartItem,
    Checkout,
    ConsumptionOptionType,
    Customer,
    CustomerType,
    IDCart,
    IDValidateCart,
    PartialResponseBuilder,
    PaymentStatus,
    PaymentStatusResponseWrapper,
    Table,
    TableCategory,
    Version,
} from '@dorst/structures'

export class CartManagerStatic {
    cartErrors: Array<SimpleError> | null = null // We use null because that is vue reactive
    checkout: Checkout
    didScan = false

    constructor() {
        this.createNewCheckout()
    }

    mounted() {
        this.setCustomerType()
    }

    createNewCheckout() {
        this.checkout = Checkout.create({
            cart: Cart.create({}),
        })
    }

    setCustomerType() {
        if (this.checkout.customer === undefined) {
            this.checkout.customer = Customer.create({})
        }
        this.checkout.customer.type =
            KioskModeService.isKioskModeEnabled()
                ? CustomerType.Kiosk
                : ServerManager.hasPassword()
                    ? CustomerType.Waiter
                    : CustomerType.Consumer
    }

    private _currentOrderUuid: null | string = null
    get currentOrderUuid() {
        return this._currentOrderUuid
    }

    set currentOrderUuid(uuid: string | null) {
        this._currentOrderUuid = uuid
        this.save()
    }

    get cart() {
        return this.checkout.cart
    }

    set cart(cart: Cart) {
        this.checkout.cart = cart
    }

    get orderingAllowedForCustomerType() {
        return this.checkout.consumptionMode !== ConsumptionOptionType.DineIn
                || ServerManager.shop.consumptionOptions.dineIn.enabledOrderingMethods[this.checkout.customer?.type ?? CustomerType.Consumer]
    }

    addItem(cartItem: CartItem, subItems: Array<CartItem> = []) {
        const allSubItems = [...this.cart.subItems, ...subItems]
        const existing = this.cart.items.find(el => {
            return el.isSameAs(cartItem, allSubItems)
        })
        if (existing) {
            existing.amount += cartItem.amount
        } else {
            this.cart.items.push(cartItem)
            this.cart.subItems = this.cart.subItems.concat(subItems)
        }
        this.cartErrors = null
        this.save()
        if (environment.features.tealium) {
            void TealiumManager.onAddProductToCart(i18n, cartItem)
        }
        AnalyticsController.get().event('onAddProductToCart', cartItem)
    }

    deleteItem(cartItem: CartItem) {
        this.cart.items = this.cart.items.filter(el => {
            return !el.isSameAs(cartItem, this.cart.subItems)
        })
        this.cart.subItems = this.cart.subItems.filter(el => {
            return el.parentId !== cartItem.id
        })
        this.cartErrors = null
        this.save()
        AnalyticsController.get().event('onRemoveFromCart', cartItem)
    }

    /**
     * @improvement If we want to include stock validation on dorst, we need extra validation for volume upsells.
     */
    convertItem(cartItem: CartItem, item: CartItem, subItems: Array<CartItem>) {
        this.deleteItem(cartItem)
        this.addItem(item, subItems)
    }

    setTable(table: Table | undefined) {
        Vue.set(this.checkout, 'table', table)
        this.save()
    }

    setRemark(val: string) {
        Vue.set(this.checkout, 'description', val)
        this.save()
    }

    setTableCategories(tableCategories: Array<TableCategory> | undefined) {
        Vue.set(this.checkout, 'tableCategories', tableCategories)
        this.save()
    }

    setDeliveryDate(deliveryDate: Date | null) {
        Vue.set(this.checkout, 'deliveryDate', deliveryDate)
        this.save()
    }

    getConsumptionMode(): ConsumptionOptionType {
        return this.checkout.consumptionMode
    }

    setConsumptionMode(consumptionMode: ConsumptionOptionType) {
        this.checkout.consumptionMode = consumptionMode
        if (consumptionMode !== ConsumptionOptionType.Delivery) {
            this.checkout.deliveryAddress = null
            this.checkout.deliveryCostPosProductId = null
            this.checkout.deliveryPrice = 0
            this.checkout.deliveryDate = null
        }
        if (this.checkout.table?.type != consumptionMode) {
            delete this.checkout.table
        }
    }

    async getDayWithTimeslots(consumptionMode: ConsumptionOptionType = this.checkout.consumptionMode, day?: Date) {
        const itemProductIds = this.checkout.cart.items.map(({product: {id}}) => id)
        return await GetDayWithTimeslots(consumptionMode, itemProductIds, day)
    }

    clear() {
        if (KioskModeService.isKioskModeEnabled()) {
            this.createNewCheckout()
            this.setCustomerType()
            this.save()
            return
        }

        this.cart = Cart.create({})
        this.checkout.tip = 0
        this.checkout.description = null
        this.checkout.deliveryDate = null
        this.checkout.deliveryPrice = 0
        this.checkout.extraCosts = []
        if (this.checkout.paymentDetails?.topup) {
            this.checkout.paymentDetails.topup = null
        }
        this.checkout.discount = null
        this.currentOrderUuid = null
        this.save()
    }

    /** Use dryRun if you do not want to overwrite to the updated cart (in validation we sometimes remove inactive items) */
    async validate(mode: ConsumptionOptionType, dryRun = false): Promise<boolean> {
        const response = await ServerManager.getServer().request({
            method: 'POST',
            path: `/consumer/shops/${ServerManager.shop.id}/validate`,
            body: IDValidateCart.create({cart: IDCart.from(this.cart), consumptionMode: mode}),
            decoder: new PartialResponseBuilder(Cart as Decoder<Cart>),
        })
        if (dryRun) {
            return !response.data.errors || response.data.errors.length === 0
        }
        this.cart = response.data.result
        this.cartErrors = response.data.errors ?? null
        const hasErrors = this.cartErrors !== null && this.cartErrors.length > 0
        if (hasErrors) {
            await ServerManager.loadShop().catch(console.error)
        }
        this.save()
        return !hasErrors
    }

    async getOrderPaymentStatus(uuid: string): Promise<PaymentStatus | undefined> {
        try {
            const result = await ServerManager.getServer().request({
                method: 'GET',
                path: `/consumer/shops/${ServerManager.shop.id}/order-payment-status/${uuid}`,
                decoder: PaymentStatusResponseWrapper as Decoder<PaymentStatusResponseWrapper>,
            })
            return result?.data?.paymentStatus
        } catch (error) {
            console.error(`Failed to get order payment status for order ${uuid}`, error)
        }
    }

    save() {
        const encoded = JSON.stringify({
            version: Version,
            shopId: ServerManager.shop.id,
            checkout: this.checkout.encode({version: Version}),
            orderUuid: this.currentOrderUuid,
            date: new Date().getTime(),
        })
        localStorage.setItem('checkout', encoded)
    }

    async load(shopId: number) {
        this.checkout = Checkout.create({
            cart: Cart.create({}),
        })

        const json = localStorage.getItem('checkout')
        if (json) {
            try {
                const encoded = JSON.parse(json)
                if (encoded.checkout && encoded.version && encoded.shopId && Number.isInteger(encoded.version) && Number.isInteger(encoded.shopId) && encoded.shopId == shopId) {
                    const checkout = Checkout.decode(new ObjectData(encoded.checkout, {version: encoded.version}))
                    if (encoded.date && encoded.date < new Date().getTime() - 3600 * 1000 * 2) {
                        // Delete cart older than 2 hours
                        console.info('Cart deleted because it is too old')
                        this.clear()
                    }
                    if (encoded.date && encoded.date < new Date().getTime() - 1000 * 60 * 60 * 24) {
                        // Delete table and table categories older than 24 hours
                        console.info('Table and table categories deleted because they\'re too old')
                        checkout.table = undefined
                        checkout.tableCategories = undefined
                        checkout.paymentMethod = null
                    }
                    if (checkout.discount !== null) {
                        try {
                            checkout.discount = await DiscountActions.VerifyDiscount(checkout.discount.code, checkout.discount.verification)
                        } catch (e) {
                            console.error(e)
                            checkout.discount = null
                        }
                    }
                    /*
                    * There is a case where users leave the app on the PSP confirmation polling page, in this case the cart
                    * is never cleared. But we do set the uuid of the order before redirecting to the payment page.
                    * So if there is a currentOrderUuid we should first check if that order was successfully paid, because
                    * if it is we do not need to restore this checkout.
                    */
                    if (!isNullOrEmpty(encoded.orderUuid)) {
                        const paymentStatus = await this.getOrderPaymentStatus(encoded.orderUuid)
                        if (paymentStatus === PaymentStatus.Succeeded) {
                            checkout.cart = Cart.create({})
                            this.currentOrderUuid = null
                        }
                    }
                    this.currentOrderUuid = encoded.orderUuid
                    this.checkout = checkout
                    this.setCustomerType()
                    await this.validate(checkout.consumptionMode)
                }
            } catch (e) {
                console.warn('Restoring checkout failed:')
                console.warn(e)
            }
        }
    }
}

export const CartManager = new CartManagerStatic()
