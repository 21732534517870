





























import {ArrayDecoder, Decoder, ObjectData} from '@simonbackx/simple-encoding'
import {ComponentWithProperties, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import DRSTBoxItem from '../DRSTBoxItem.vue'
import CountryCodeContextMenu from './CountryCodeContextMenu.vue'
import CountryCodes from '@dorst/assets/json/countryCodes.json'
import {applyRtlWindow} from '@dorst/frontend-translations'
import {Phone, SortHelper} from '@dorst/helpers'
import {CountryDialCode, Version} from '@dorst/structures'

@Component({
    components: {
        DRSTBoxItem,
    },
})

export default class TelephoneInput extends Mixins(NavigationMixin) {
    @Prop({})
    value!: string

    @Prop({default: 'BE'})
    defaultCountryCode!: string

    @Prop({default: ''})
    placeholder!: string

    @Prop({default: false})
    disabled!: boolean

    allCountryCodesList: Array<CountryDialCode> = []
    editDialCode = ''
    editPhone = ''
    countryCode = ''

    valid: boolean | null = null

    async mounted() {
        await this.init()
    }
    async activated() {
        await this.init()
    }

    async init() {
        if (this.allCountryCodesList.length === 0) {
            this.allCountryCodesList = new ObjectData(CountryCodes, {version: Version}).decode(new ArrayDecoder(CountryDialCode as Decoder<CountryDialCode>))
        }
        if (this.value.length > 0) {
            try {
                const {countryCode, phone} = await Phone.splitPhoneNumber(this.value)
                // if we receive empty it is possible we did send a countrycode but the phonenumber itself was too short to determine it
                if (!countryCode && !phone) {
                    const parts = this.value.split(' ')
                    this.setCountryByDialCode(parts[0])
                    if (parts[1]) {
                        this.phone = parts[1]
                    }
                } else {
                    this.setCountryByDialCode(countryCode ? `+${countryCode}` : this.defaultCountry?.dialCode ?? '')
                    if (phone) {
                        this.phone = phone
                    }
                }
            } catch (e: any) { // TSUpgrade any
                console.error(e.code)
            }
        } else {
            this.dialCode = this.defaultCountry?.dialCode ?? '+32'
            this.countryCode = this.defaultCountry?.code ?? 'BE'
        }
    }

    get phone() {
        return this.editPhone || ''
    }

    set phone(value: string) {
        this.valid = null
        this.editPhone = value
        void this.fullPhoneNumber.then(nr => {
            this.$emit('input', nr)
        })
    }

    get dialCode() {
        return this.editDialCode || '+32'
    }

    set dialCode(value) {
        this.editDialCode = value
        void this.fullPhoneNumber.then(nr => {
            this.$emit('input', nr)
        })
    }

    async blur() {
        this.phone = this.phone.trim()
        await this.validate()
    }

    select() {
        (this.$refs.input as any)?.select()
    }

    async validate() {
        try {
            this.valid = await Phone.validate(await Phone.format(`${this.editDialCode}${this.phone}`), this.countryCode)
        } catch (error) {
            console.error(error)
            this.valid = false
        }
    }

    get fullPhoneNumber(): Promise<string> {
        const nonFormatted = `${this.editDialCode} ${this.phone}`
        return Phone.format(nonFormatted).catch(() => nonFormatted)
    }

    get defaultCountry() {
        const defaultCode = this.allCountryCodesList.find(countryCode => countryCode.code === this.defaultCountryCode)
        if (defaultCode) {
            return defaultCode
        }
        return this.allCountryCodesList.find(countryCode => countryCode.code === 'BE')
    }

    setCountryByDialCode(dialCode) {
        const defaultCode = this.allCountryCodesList.find(countryCode => countryCode.dialCode === dialCode)
        if (defaultCode) {
            this.countryCode = defaultCode.code
            this.dialCode = defaultCode.dialCode ?? ''
        }
    }

    async setCountryCode(dialCode: string, countryCode: string) {
        this.dialCode = dialCode
        this.countryCode = countryCode
        if (this.phone !== '') {
            await this.validate()
        }
    }

    toggleDropdown(event) {
        const displayedComponent = new ComponentWithProperties(CountryCodeContextMenu, {
            x: applyRtlWindow(event.clientX),
            y: event.clientY + 10,
            setCountryCode: this.setCountryCode,
            countryCodes: this.allCountryCodesList.sort(SortHelper.byName),
            activeCountry: this.countryCode,
        })
        this.present(displayedComponent.setDisplayStyle('overlay'))
    }

}
