




















import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {ServerManager} from '../classes/ServerManager'
import {
    DRSTBox,
    DRSTBoxItem,
    DRSTFloatingFooter,
    DRSTList,
    DRSTNavigationBar,
    LoadingCover,
    RemarkBlock,
} from '@dorst/components'
import {isNullOrEmpty} from '@dorst/helpers'
import {TranslatedShopMessage} from '@dorst/structures'

@Component({
    components: {
        DRSTBox,
        RemarkBlock,
        DRSTBoxItem,
        DRSTFloatingFooter,
        DRSTList,
        DRSTNavigationBar,
        LoadingCover,
    },
})
export default class ShopMessageView extends Mixins(NavigationMixin) {
    @Prop({type: TranslatedShopMessage})
    message: TranslatedShopMessage | undefined

    @Prop({type: String})
    titleOverride: string | undefined

    @Prop({type: String})
    textOverride: string | undefined

    mounted() {
        if (!this.message && !(this.titleOverride || this.textOverride)) {
            this.dismiss()
        }
    }

    get title() {
        return this.titleOverride ? this.titleOverride : this.message?.title.getForI18n(this.$i18n)
    }

    get hasTitle() {
        return !isNullOrEmpty(this.title)
    }

    get text() {
        return this.textOverride ? this.textOverride : this.message?.text.getForI18n(this.$i18n)
    }

    get hideBackgroundImage() {
        return ServerManager.shop.personalisation?.personalisationExceptions?.hideBgImageOnShopMessage ?? false
    }
}
