import {Decoder, ObjectData} from '@simonbackx/simple-encoding'
import {Request, RequestMiddleware, Server} from '@simonbackx/simple-networking'
import {Vue} from 'vue-property-decorator'

import {AnalyticsController} from '../analytics/AnalyticsController'
import {environment} from '../environments/environment'
import {CartManager} from './CartManager'
import {IDShopConsumer, ShopFull, Version} from '@dorst/structures'
import {Formatter} from '@dorst/validation'

export async function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export class ServerManagerStatic implements RequestMiddleware {
    shop: ShopFull
    password?: string
    uri: string

    setPassword(password: string) {
        this.password = password
        localStorage.setItem(`password-${this.shop.id}`, this.password)
        CartManager.setCustomerType()
    }

    readPasswordForShop() {
        this.password = undefined
        // Check localstorage
        const json = localStorage.getItem(`password-${this.shop.id}`)
        if (json) {
            try {
                this.password = new ObjectData(json, {version: Version}).string
            } catch (e) {
                console.error(e)
            }
        }
    }

    removePassword() {
        localStorage.removeItem(`password-${this.shop.id}`)
        this.password = undefined
        CartManager.setCustomerType()
    }

    getServer(): Server {
        const server = new Server(environment.api)
        server.middlewares = [this]
        return server
    }

    hasPassword(): boolean {
        return this.password !== undefined
    }

    get domain(): string | null {
        let domain = this.shop?.domain
        if (domain == null) {
            domain = window.location.hostname
            if (domain == 'dorst.app' || domain == 'soif.app' || domain == 'order24.com') {
                domain = null
            }
        }
        return domain
    }

    async loadShop(useUri?: string, useDomain?: string): Promise<ShopFull> {
        let uri = useUri ?? this.shop?.uri
        const domain = useDomain ?? this.domain

        if (uri === undefined) {
            const path = window.location.pathname
            const parts = path.substring(1).split('/')

            if (!domain && (!path || parts.length < 1)) {
                console.error(`Invalid path ${path}`)
                throw new Error('Not implemented')
            }
            uri = parts[0] ?? ''
        }
        this.uri = uri

        const response = await this.getServer()
            .request({
                method: 'GET',
                path: uri ? `/consumer/shops/${uri}` : '/consumer/shops',
                query: {full: true, domain: domain ?? undefined},
                decoder: IDShopConsumer as Decoder<IDShopConsumer>,
            })

        // notify vue
        Vue.set(this, 'shop', ShopFull.fromID(response.data))
        await AnalyticsController.get().grantConsent(AnalyticsController.get().getAllConfiguredProcessors())

        Formatter.setCurrency(response.data.currency)

        this.readPasswordForShop()

        return this.shop
    }

    onBeforeRequest?(request: Request<any>): Promise<void> {
        request.version = Version;
        (request as any).retryCount = ((request as any).retryCount ?? 0) + 1
        return Promise.resolve()
    }

    async shouldRetryNetworkError(request: Request<any>, error: Error): Promise<boolean> {
        if (request.path.startsWith('/consumer/shops/') && request.path.endsWith('/order')) {
            return false
        }
        if (request.path.endsWith('/cashless/balance')) {
            return false
        }

        if ((error as any).type === 'abort') {
            return false
        }

        const retryCount: number = (request as any).retryCount ?? 0
        if (retryCount >= 5) {
            return false
        }

        await sleep(Math.min(retryCount * 3000, 20000))
        return Promise.resolve(true)
    }
}

export const ServerManager = new ServerManagerStatic();

// debugging tools
(window as any).ServerManager = ServerManager
