import {AutoEncoder, BooleanDecoder, field, IntegerDecoder, StringDecoder} from '@simonbackx/simple-encoding'

import {Language, LanguageDecoder} from './Language'
import {ShopExternalLinks} from './ShopExternalLinks'
import {ShopPersonalisation} from './ShopPersonalisation'
import {ShopType, ShopTypeDecoder} from './ShopType'

export class Shop extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    id: number

    @field({decoder: StringDecoder})
    name: string

    @field({decoder: StringDecoder})
    uri: string

    @field({decoder: StringDecoder, nullable: true, version: 47})
    domain: string | null = null

    /**
     * The language used when a product doesn't have a translation in a given language. This is also the language used for communication towards the shop and the default language when editing products
     */
    @field({decoder: LanguageDecoder, version: 30, upgrade: () => Language.Dutch})
    language: Language

    @field({decoder: ShopTypeDecoder, version: 43, upgrade: () => ShopType.Default, optional: true})
    type: ShopType = ShopType.Default

    @field({decoder: BooleanDecoder})
    enableOrdering = false

    @field({decoder: ShopPersonalisation, version: 45, nullable: true, upgrade: () => null})
    personalisation: ShopPersonalisation | null = null

    @field({decoder: ShopExternalLinks, nullable: true, optional: true})
    externalLinks: ShopExternalLinks | null = null
}
