var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view order-poll-view" },
    [
      !_vm.order || _vm.isPending
        ? [
            _c("main", { staticClass: "message" }, [
              _c(
                "div",
                { staticClass: "spinner-container" },
                [_c("Spinner", { staticClass: "gray" })],
                1
              ),
              _vm._v(" "),
              _c("h1", [
                _vm._v(_vm._s(_vm.$t("customer.paymentLoading.title")))
              ]),
              _vm._v(" "),
              _c("p", [_vm._v(_vm._s(_vm.$t("customer.paymentLoading.text")))])
            ])
          ]
        : _vm.isPending ||
          (_vm.order.paymentStatus == "Timeout" && !_vm.paidUsingCashless)
        ? _c("main", { staticClass: "message" }, [
            _c("h1", [
              _vm._v("❌ " + _vm._s(_vm.$t("customer.paymentFailed.title")))
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("customer.paymentFailed.text")))])
          ])
        : _vm.order.paymentStatus == "Timeout"
        ? _c("main", { staticClass: "message" }, [
            _c("h1", [
              _vm._v("❌ " + _vm._s(_vm.$t("customer.paymentTimeout.title")))
            ]),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("customer.paymentTimeout.text")))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.order && !_vm.isPending
        ? _c("DRSTFloatingFooter", [
            _c(
              "button",
              { staticClass: "button primary fill", on: { click: _vm.close } },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("customer.paymentFailed.close")) +
                    "\n        "
                )
              ]
            )
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }