var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ContextMenu",
    _vm._b(
      { staticClass: "consumption-mode-ctx-menu" },
      "ContextMenu",
      { x: _vm.x, y: _vm.y },
      false
    ),
    _vm._l(_vm.modes, function(m) {
      return _c(
        "DRSTBoxItem",
        {
          key: m,
          attrs: { "element-name": "label", selectable: true },
          on: {
            click: function($event) {
              _vm.setMode(m)
              _vm.pop()
            }
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.getModeName(m)) + "\n        "),
          _vm.mode === m
            ? _c("template", { slot: "right" }, [
                _c("span", { staticClass: "icon check" })
              ])
            : _vm._e()
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }