var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "drst-view search-view" }, [
    _c(
      "header",
      { ref: "box", staticClass: "fix-offset" },
      [
        _c(
          "DRSTBox",
          [
            _c(
              "TextInputRow",
              {
                ref: "input",
                attrs: {
                  autocomplete: "search",
                  placeholder: _vm.$t("customer.search.placeholder"),
                  type: "search"
                },
                model: {
                  value: _vm.query,
                  callback: function($$v) {
                    _vm.query = $$v
                  },
                  expression: "query"
                }
              },
              [
                _c("span", {
                  staticClass: "icon search",
                  attrs: { slot: "left" },
                  slot: "left"
                }),
                _vm._v(" "),
                _c("button", {
                  staticClass: "button icon close",
                  attrs: { slot: "right" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.close($event)
                    }
                  },
                  slot: "right"
                })
              ]
            )
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "main",
      [
        _vm.products.length > 0
          ? _c(
              "DRSTBox",
              { attrs: { title: _vm.$t("customer.search.resultsTitle") } },
              _vm._l(_vm.products, function(product) {
                return _c("ProductBoxItem", {
                  key: product.id,
                  attrs: { product: product },
                  on: {
                    click: function($event) {
                      return _vm.goToProduct(product)
                    }
                  }
                })
              }),
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }