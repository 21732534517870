import {
    ArrayDecoder,
    AutoEncoder,
    field,
    IntegerDecoder,
    NumberDecoder,
    StringDecoder,
} from '@simonbackx/simple-encoding'

export class CloudPosSubproduct extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    id: number

    @field({decoder: StringDecoder})
    name: string

    @field({decoder: StringDecoder})
    price: string

    @field({decoder: NumberDecoder})
    tax: number

    @field({decoder: NumberDecoder})
    tax_table: number

    @field({decoder: NumberDecoder})
    quantity: number

    @field({decoder: new ArrayDecoder(NumberDecoder)})
    product_ids: Array<number>

    @field({decoder: IntegerDecoder})
    modtime: number
}
