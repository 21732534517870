var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DRSTBoxItem",
    {
      staticClass: "select-row text-input-row",
      class: { "has-label": _vm.label !== undefined && _vm.label !== "" },
      attrs: {
        "element-name": "label",
        for: _vm.id,
        selectable: !_vm.hasFocus && !_vm.disabled
      },
      on: {
        click: function($event) {
          return _vm.$emit("click", $event)
        }
      }
    },
    [
      _vm.label !== undefined && _vm.label !== ""
        ? [
            _vm._t("label", [
              _c("div", {
                staticClass: "text-label",
                domProps: { textContent: _vm._s(_vm.label) }
              })
            ]),
            _vm._v(" "),
            _c("template", { slot: "right" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.text,
                      expression: "text"
                    }
                  ],
                  attrs: { id: _vm.id, disabled: _vm.disabled },
                  on: {
                    blur: _vm.blur,
                    focus: _vm.onFocus,
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.text = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _vm.placeholder.length > 0
                    ? _c("option", { attrs: { value: "", disabled: "" } }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.placeholder) +
                            "\n                "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(_vm.options, function(option) {
                    return _c(
                      "option",
                      {
                        key: option.text + "-" + option.value,
                        domProps: { value: option.value }
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(option.text) +
                            "\n                "
                        )
                      ]
                    )
                  })
                ],
                2
              )
            ])
          ]
        : _c("div", [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.text,
                    expression: "text"
                  }
                ],
                attrs: { id: _vm.id, disabled: _vm.disabled },
                on: {
                  blur: _vm.blur,
                  focus: _vm.onFocus,
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.text = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _vm.placeholder.length > 0
                  ? _c("option", { attrs: { value: "", disabled: "" } }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.placeholder) +
                          "\n            "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.options, function(option) {
                  return _c(
                    "option",
                    {
                      key: option.text + "-" + option.value,
                      domProps: { value: option.value }
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(option.text) +
                          "\n            "
                      )
                    ]
                  )
                })
              ],
              2
            )
          ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }