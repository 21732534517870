





















































import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({
    components: {},
})
export default class DateInput extends Vue {
    @Prop({})
    value!: string

    editDay: string | null = null
    editMonth: string | null = null
    editYear: string | null = null

    get day(): string {
        if (this.editDay !== null) {
            return this.editDay
        }
        return this.value.split('/')[0] ?? ''
    }

    set day(day: string) {
        this.editDay = day
        this.emit()
    }

    get month(): string {
        if (this.editMonth !== null) {
            return this.editMonth
        }
        return this.value.split('/')[1] ?? ''
    }

    set month(month: string) {
        this.editMonth = month
        this.emit()
    }

    get year(): string {
        if (this.editYear !== null) {
            return this.editYear
        }
        return this.value.split('/')[2] ?? ''
    }

    set year(year: string) {
        this.editYear = year
        this.emit()
    }

    backspaceMonth(e: KeyboardEvent) {
        if (this.month === '') {
            (this.$refs.day as HTMLElement).focus()
            e.preventDefault()
        }
    }

    backspaceYear(e: KeyboardEvent) {
        if (this.year === '') {
            (this.$refs.month as HTMLElement).focus()
            e.preventDefault()
        }
    }

    formatDay() {
        if (this.day.length == 1) {
            this.day = `0${this.day}`
        }
    }

    formatMonth() {
        if (this.month.length == 1) {
            this.month = `0${this.month}`
        }
    }
    formatYear() {
        if (this.year.length == 2) {
            if (parseInt(this.year) > new Date().getFullYear() % 100) {
                this.year = `19${this.year}`
            } else {
                this.year = `20${this.year}`
            }
        }
    }

    keyDay(e: KeyboardEvent) {
        if (e.key.length > 1) {
            return
        }
        const nv = parseInt(`${this.day}${e.key}`)
        if (isNaN(nv)) {
            e.preventDefault()
        }
        if (nv > 31) {
            e.preventDefault()
        }
    }

    keyMonth(e: KeyboardEvent) {
        if (e.key.length > 1) {
            return
        }
        const nv = parseInt(`${this.month}${e.key}`)
        if (isNaN(nv)) {
            e.preventDefault()
        }
        if (nv > 12) {
            e.preventDefault()
        }
    }

    keyYear(e: KeyboardEvent) {
        if (e.key.length > 1) {
            return
        }
        const nv = parseInt(`${this.year}${e.key}`)
        if (isNaN(nv)) {
            e.preventDefault()
        }
        if (nv > new Date().getFullYear()) {
            e.preventDefault()
        }
    }

    keyUpDay(e: KeyboardEvent) {
        if (isNaN(parseInt(e.key))) {
            return
        }
        if (this.day.length == 2) {
            (this.$refs.month as HTMLElement).focus()
        }
    }

    keyUpMonth(e: KeyboardEvent) {
        if (isNaN(parseInt(e.key))) {
            return
        }
        if (this.month.length == 2) {
            (this.$refs.year as HTMLElement).focus()
        }
    }

    emit() {
        this.$emit('input', `${this.day}/${this.month}/${this.year}`)
    }
}
