import {ArrayDecoder, AutoEncoder, DateDecoder, EnumDecoder, field, IntegerDecoder, StringDecoder} from '@simonbackx/simple-encoding'

import {Address} from './Address'
import {Checkout} from './Checkout'
import {ConsumptionOptionType} from './ConsumptionOptions'
import {Customer} from './Customer'
import {ExtraCost} from './ExtraCost'
import {IDCart} from './IDCart'
import {PaymentDetails} from './PaymentDetails'
import {PaymentMethod, PaymentMethodType} from './PaymentMethod'
import {PaymentProvider} from './PaymentProvider'
import {TransactionFees} from './TransactionFees'
import {Discount} from './discounts/Discount'

/**
 * Make all properties in T required
 */
type RequiredNonNull<T> = {
    [P in keyof T]-?: NonNullable<T[P]>
}

export class IDCheckout extends AutoEncoder {
    @field({decoder: IDCart})
    cart: IDCart

    @field({decoder: Customer})
    customer: Customer

    @field({decoder: StringDecoder, nullable: true})
    tableId: string | null = null

    @field({decoder: new ArrayDecoder(StringDecoder), version: 43, upgrade: () => []})
    tableCategoryIds: Array<string>

    @field({decoder: DateDecoder, nullable: true, version: 52})
    deliveryDate: Date | null = null

    @field({decoder: Address, version: 59, nullable: true, upgrade: () => null})
    deliveryAddress: Address | null = null

    @field({decoder: IntegerDecoder, version: 59, upgrade: () => 0})
    deliveryPrice = 0

    @field({decoder: StringDecoder, nullable: true, optional: true})
    deliveryCostPosProductId: string | null = null

    @field({decoder: new ArrayDecoder(ExtraCost), version: 76, defaultValue: () => []})
    extraCosts: Array<ExtraCost>

    @field({decoder: new EnumDecoder(PaymentMethodType)})
    @field({
        decoder: PaymentMethod, version: 41, nullable: true, upgrade: (old: PaymentMethodType | null) => {
            if (old === null) {
                return null
            }
            return PaymentMethod.create({type: old, transactionFees: TransactionFees.default(old, PaymentProvider.Paynl), provider: PaymentProvider.Paynl})
        }, downgrade: (n: PaymentMethod | null) => {
            if (n === null) {
                return null
            }
            return n.type
        },
    })
    paymentMethod: PaymentMethod

    @field({decoder: PaymentDetails, nullable: true, version: 42, optional: true})
    paymentDetails: PaymentDetails | null = null

    @field({decoder: IntegerDecoder, version: 23, upgrade: () => 0})
    tip = 0

    @field({decoder: new EnumDecoder(ConsumptionOptionType), version: 52, upgrade: () => ConsumptionOptionType.DineIn})
    consumptionMode: ConsumptionOptionType = ConsumptionOptionType.DineIn

    @field({decoder: Discount, nullable: true, optional: true})
    discount: Discount | null = null

    @field({decoder: StringDecoder, optional: true, nullable: true})
    description: string | null = null

    static from(checkout: RequiredNonNull<Checkout>): IDCheckout {
        return IDCheckout.create({
            cart: IDCart.from(checkout.cart),
            customer: checkout.customer,
            tableId: checkout.table ? checkout.table.id : null,
            tableCategoryIds: checkout.tableCategories ? checkout.tableCategories.map(c => c.id) : [],
            paymentMethod: checkout.paymentMethod,
            paymentDetails: checkout.paymentDetails,
            extraCosts: checkout.extraCosts,
            tip: checkout.tip,
            deliveryDate: checkout.deliveryDate,
            deliveryAddress: checkout.deliveryAddress,
            deliveryPrice: checkout.deliveryPrice,
            deliveryCostPosProductId: checkout.deliveryCostPosProductId,
            consumptionMode: checkout.consumptionMode,
            discount: checkout.discount,
            description: checkout.description,
        })
    }
}
