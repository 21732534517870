















































































import {Decoder, ObjectData} from '@simonbackx/simple-encoding'
import {ComponentWithProperties, HistoryManager, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Meta} from '@sophosoft/vue-meta-decorator'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {ShopDomain} from '../../actions/ShopDomain'
import {environment} from '../../environments/environment'
import ShopNotFoundView from '../ShopNotFoundView.vue'
import {componentFromUrl} from '../componentFromUrl'
import {DRSTBox, DRSTBoxItem, DRSTFloatingFooter, DRSTList, DRSTNavigationBar, DRSTThumbnail, LoadingCover} from '@dorst/components'
import {CSSVariableHelper} from '@dorst/frontend-helpers'
import {ShopGroup, ShopGroupData, ShopGroupDataShop, ShopGroupHelper, Version} from '@dorst/structures'

@Component({
    components: {
        DRSTNavigationBar,
        DRSTBox,
        DRSTList,
        DRSTBoxItem,
        DRSTFloatingFooter,
        LoadingCover,
        DRSTThumbnail,
    },
})
export default class ShopSelectionView extends Mixins(NavigationMixin) {
    @Meta
    getMetaInfo() {
        return {
            title: `${environment.metaTitle} - ${this.shopGroup}`,
        }
    }

    @Prop({required: true})
    shopGroup: ShopGroup

    shopGroupData: ShopGroupData | null = null

    async mounted() {
        HistoryManager.setUrl('/')
        this.shopGroupData = new ObjectData((await import(`./${ShopGroupHelper.getJsonName(this.shopGroup)}`)).default, {version: Version}).decode(ShopGroupData as Decoder<ShopGroupData>)
        if (this.shopGroupData.cssVariables) {
            CSSVariableHelper.applyToRoot(this.shopGroupData.cssVariables)
        }
        if (this.shopGroupData.backgroundImage) {
            CSSVariableHelper.applyBackgroundImage(this.shopGroupData.backgroundImage)
        }
    }

    activated() {
        HistoryManager.setUrl('/')
        if (this.shopGroupData?.cssVariables) {
            CSSVariableHelper.applyToRoot(this.shopGroupData.cssVariables)
        }
        if (this.shopGroupData?.backgroundImage) {
            CSSVariableHelper.applyBackgroundImage(this.shopGroupData.backgroundImage)
        }
    }

    get logoOnlyHeader(): boolean {
        return this.shopGroupData?.cssExceptions?.logoOnlyHeader ?? false
    }

    get shops(): Array<ShopGroupDataShop> | null {
        if (this.shopGroupData && this.shopGroupData.shops) {
            // return this.shopGroupData.shops.sort(( a:ShopGroupDataShop, b:ShopGroupDataShop ) => a.title < b.title ? -1 : 1);
            return this.shopGroupData.shops
        }
        return null
    }

    get title(): string | null {
        if (this.shopGroupData && this.shopGroupData.header.title) {
            return this.shopGroupData.header.title.getForI18n(this.$i18n as any)
        }
        return null
    }

    get description(): string {
        if (this.shopGroupData) {
            return this.shopGroupData.header.introduction.getForI18n(this.$i18n as any)
        }
        return ''
    }

    get headerLogos(): Array<string> | null {
        if (this.shopGroupData) {
            return this.shopGroupData.header.logos ?? null
        }
        return null
    }

    get footerLogos(): Array<string> | null {
        if (this.shopGroupData) {
            return this.shopGroupData.footer.logos ?? null
        }
        return null
    }

    get footerTitle(): string {
        if (this.shopGroupData) {
            return this.shopGroupData.footer.title.getForI18n(this.$i18n as any)
        }
        return ''
    }

    get disclaimer(): string | null {
        if (this.shopGroupData && this.shopGroupData.footer.disclaimer) {
            return this.shopGroupData.footer.disclaimer.getForI18n(this.$i18n as any)
        }
        return null
    }

    get titleAboveLogos() {
        return this.shopGroupData?.header?.titleAboveLogos ?? false
    }

    get standaloneLogo() {
        return this.shopGroupData?.header.standaloneLogo ?? false
    }

    async goToShop(shopId: number) {
        ShopDomain(shopId).then(({data: {domain, uri, isExternal}}) => {
            if (isExternal) {
                window.location.assign(`https://${domain}/${uri}`)
            } else {
                if (this.shopGroupData?.cssVariables) {
                    CSSVariableHelper.removeFromRoot(this.shopGroupData.cssVariables)
                }
                if (this.shopGroupData?.backgroundImage) {
                    CSSVariableHelper.removeBackgroundImage()
                }
                this.show(componentFromUrl(`/${shopId}`))
            }
        }).catch((e) => {
            console.error(e)
            return new ComponentWithProperties(ShopNotFoundView, {})
        })
    }
}
