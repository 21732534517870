import {ArrayDecoder, AutoEncoder, field, StringDecoder} from '@simonbackx/simple-encoding'
import {SimpleError} from '@simonbackx/simple-errors'
import {v4 as uuidv4} from 'uuid'

import {ConsumptionModeAvailabilityDay} from './ConsumptionModeAvailabilityDay'
import {ConsumptionModeAvailabilityPeriod} from './ConsumptionModeAvailabilityPeriod'
import {WeekDay} from './WeekDay'
import {Formatter} from '@dorst/validation'

export class ConsumptionModeAvailabilityScheme extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: new ArrayDecoder(ConsumptionModeAvailabilityDay), defaultValue: () => ConsumptionModeAvailabilityScheme.defaultWeek()})
    fixed: Array<ConsumptionModeAvailabilityDay>

    @field({decoder: new ArrayDecoder(ConsumptionModeAvailabilityDay), defaultValue: () => []})
    variable: Array<ConsumptionModeAvailabilityDay>

    isValid(): boolean {
        return ![...this.fixed, ...this.variable].find(el => !el.isValid())
    }

    static defaultWeek(): Array<ConsumptionModeAvailabilityDay> {
        const days = [WeekDay.Monday, WeekDay.Tuesday, WeekDay.Wednesday, WeekDay.Thursday, WeekDay.Friday, WeekDay.Saturday, WeekDay.Sunday]
        const week: Array<ConsumptionModeAvailabilityDay> = []
        for (const [i, day] of days.entries()) {
            week.push(
                ConsumptionModeAvailabilityDay.create({
                    id: `${i}118427c-d53c-42da-a47d-73f8050b18c2`,
                    day: day,
                    periods: [
                        ConsumptionModeAvailabilityPeriod.create({
                            id: `${i}2248427c-d53c-42da-a47d-73f8050b18c2`,
                        }),
                    ],
                }),
            )
        }
        return week
    }

    getPeriodsForDay(date: Date, timezone: string): Array<ConsumptionModeAvailabilityPeriod> {
        let day: ConsumptionModeAvailabilityDay | undefined
        day = this.variable.find(el => el.date && Formatter.date(new Date(el.date), timezone) == Formatter.date(date, timezone))
        if (!day) {
            day = this.variable.find(el => el.day && el.day == date.getDay())
        }
        if (day) {
            return day.periods
        } else {
            throw new SimpleError({
                code: 'invalid_day',
                message: `Could not find a matching date to get the timeslot scheme : [utc=]${date.getUTCDate()}`,
            })
        }
    }
}

export const ConsumptionModeAvailabilitySchemePatch = ConsumptionModeAvailabilityScheme.patchType()
