var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view shop-splash-view" },
    [
      _c("DRSTNavigationBar", { attrs: { title: _vm.shop.name } }, [
        _vm.canPop
          ? _c("button", {
              staticClass: "button icon back",
              attrs: { slot: "left" },
              on: { click: _vm.pop },
              slot: "left"
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { slot: "right" }, slot: "right" },
          [_c("LanguageSwitcher")],
          1
        )
      ]),
      _vm._v(" "),
      _vm.logo && !_vm.hideLogoOnSplashScreen
        ? _c("div", {
            staticClass: "shop-logo",
            style: { backgroundImage: "url(" + _vm.logo + ")" }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("main", [
        !_vm.logoIncludesShopName
          ? _c("h1", [
              _vm._v("\n            " + _vm._s(_vm.shop.name) + "\n        ")
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "DRSTFloatingFooter",
        [
          _vm.allConsumptionOptionsEnabled
            ? _c("div", { staticClass: "button-group" }, [
                _c(
                  "button",
                  {
                    staticClass: "button primary fill",
                    on: {
                      click: function($event) {
                        return _vm.selectMode(
                          _vm.ConsumptionOptionType.TakeAway
                        )
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$t("backoffice.consumptionOptions.takeAway")
                        ) +
                        "\n            "
                    )
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button primary fill",
                    on: {
                      click: function($event) {
                        return _vm.selectMode(
                          _vm.ConsumptionOptionType.Delivery
                        )
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$t("backoffice.consumptionOptions.delivery")
                        ) +
                        "\n            "
                    )
                  ]
                )
              ])
            : [
                _vm.takeAwayEnabled
                  ? _c(
                      "button",
                      {
                        staticClass: "button primary fill",
                        on: {
                          click: function($event) {
                            return _vm.selectMode(
                              _vm.ConsumptionOptionType.TakeAway
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t("backoffice.consumptionOptions.takeAway")
                            ) +
                            "\n            "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.deliveryEnabled
                  ? _c(
                      "button",
                      {
                        staticClass: "button primary fill",
                        on: {
                          click: function($event) {
                            return _vm.selectMode(
                              _vm.ConsumptionOptionType.Delivery
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.$t("backoffice.consumptionOptions.delivery")
                            ) +
                            "\n            "
                        )
                      ]
                    )
                  : _vm._e()
              ],
          _vm._v(" "),
          _vm.dineInEnabled
            ? _c(
                "button",
                {
                  staticClass: "button primary fill",
                  on: {
                    click: function($event) {
                      return _vm.selectMode(_vm.ConsumptionOptionType.DineIn)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.$t("backoffice.consumptionOptions.dineIn")) +
                      "\n        "
                  )
                ]
              )
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm.hasExternalLinksEnabled
        ? _c("DRSTExternalLinksFooter", {
            attrs: { links: _vm.shop.externalLinks }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }