import {
    AutoEncoder,
    BooleanDecoder,
    field,
    IntegerDecoder,
    NumberDecoder,
    StringDecoder,
} from '@simonbackx/simple-encoding'

export class CloudPosProduct extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    id: number

    @field({decoder: StringDecoder})
    name: string

    @field({decoder: NumberDecoder})
    category_id: number

    @field({decoder: StringDecoder})
    price: string

    @field({decoder: StringDecoder})
    purchaseprice: string

    @field({decoder: NumberDecoder})
    tax: number

    @field({decoder: NumberDecoder})
    tax_table: number

    @field({decoder: StringDecoder})
    barcode: string

    @field({decoder: IntegerDecoder})
    quantity: number

    @field({decoder: BooleanDecoder})
    popup: boolean

    @field({decoder: BooleanDecoder})
    menupopup: boolean

    @field({decoder: BooleanDecoder})
    openprice: boolean

    @field({decoder: IntegerDecoder})
    modtime: number
}
