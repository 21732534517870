var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "spinner-container" }, [
    _c("div", { staticClass: "spinner" }, [
      _c(
        "svg",
        {
          attrs: {
            width: "28",
            height: "28",
            viewBox: "0 0 28 28",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M14 24C8.47715 24 4 19.5228 4 14C4 8.47715 8.47715 4 14 4C19.5228 4 24 8.47715 24 14C24 19.5228 19.5228 24 14 24Z",
              stroke: "#0057FF",
              "stroke-width": "2",
              "stroke-linecap": "round",
              "stroke-dasharray": "100 100"
            }
          })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }