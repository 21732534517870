




































































import {ComponentWithProperties, NavigationController, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {AnalyticsController} from '../analytics/AnalyticsController'
import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import {TealiumManager} from '../classes/TealiumManager'
import {environment} from '../environments/environment'
import {PruneHelper} from '../helpers/PruneHelper'
import CartView from './CartView.vue'
import OfferCard from './OfferCard.vue'
import ProductBoxItem from './ProductBoxItem.vue'
import ProductView from './ProductView.vue'
import {DRSTBox, DRSTBoxItem, DRSTFloatingFooter, DRSTList, DRSTNavigationBar, LoadingCover} from '@dorst/components'
import {i18n} from '@dorst/frontend-translations'
import {CartItem, Category, ConsumptionOptionType, Product} from '@dorst/structures'
import {Formatter} from '@dorst/validation'

@Component({
    components: {
        DRSTNavigationBar,
        DRSTBox,
        DRSTList,
        DRSTBoxItem,
        ProductBoxItem,
        DRSTFloatingFooter,
        LoadingCover,
        OfferCard,
    },
})
export default class CategoryView extends Mixins(NavigationMixin) {
    @Prop()
    category: Category
    CartManager = CartManager

    activated() {
        if (environment.features.tealium) {
            void TealiumManager.onSubcategoryView(this.$i18n as any, this.category)
        }

        AnalyticsController.get().event('onCategoryView', this.category, this.products)
    }

    get shop() {
        return ServerManager.shop
    }

    get consumptionMode(): ConsumptionOptionType {
        return CartManager.getConsumptionMode()
    }

    get products(): Array<Product> {
        return PruneHelper.filterProducts(this.category, this.consumptionMode)
    }

    get categories(): Array<Category> {
        return PruneHelper.filterCategories(this.category, this.consumptionMode)
    }

    get categoryName(): string {
        return this.category.getNameForI18n(this.$i18n as any)
    }

    get categoryDescription(): string {
        return this.category.description.getForI18n(this.$i18n as any)
    }

    getCategoryName(category): string {
        return category.getNameForI18n(this.$i18n as any)
    }

    goToProduct(product: Product) {
        const {cartItem, subItems} = CartItem.defaultForProduct(product, ServerManager.shop.getLinkedProducts(product))
        this.show(new ComponentWithProperties(ProductView, {cartItem, subCartItems: subItems}))
    }

    goToCategory(category: Category) {
        this.show(new ComponentWithProperties(CategoryView, {category}))
    }

    next() {
        this.present(new ComponentWithProperties(NavigationController, {root: new ComponentWithProperties(CartView)}))
    }
}
