var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.root
    ? _c(
        "div",
        { attrs: { id: "app" } },
        [
          _c("ModalStackComponent", { ref: "modal", attrs: { root: _vm.root } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }