













import {ComponentWithProperties, HistoryManager, ModalStackComponent} from '@simonbackx/vue-app-navigation'
import 'reflect-metadata'
import {Component, Vue} from 'vue-property-decorator'

import {environment} from '../environments/environment'
import {componentFromUrl} from './componentFromUrl'

@Component({
    metaInfo: {
        title: environment.metaTitle,
    },
    components: {
        ModalStackComponent,
    },
})
export default class App extends Vue {
    root: ComponentWithProperties | null = null

    mounted() {
        HistoryManager.activate()
        this.root = componentFromUrl()
    }
}
