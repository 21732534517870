import {ComponentWithProperties, NavigationController} from '@simonbackx/vue-app-navigation'

import {DynamicPromiseView} from '../../../components/src'
import {AddressManager} from '../classes/AddressManager'
import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import CartView from './CartView.vue'
import CategoryView from './CategoryView.vue'
import KioskModeView from './KioskModeView.vue'
import OrderPollView from './OrderPollView.vue'
import ProductView from './ProductView.vue'
import ShopNotFoundView from './ShopNotFoundView.vue'
import ShopSplashView from './ShopSplashView.vue'
import ShopView from './ShopView.vue'
import ShopSelectionView from './shopgroups/ShopSelectionView.vue'
import {PersonalisationHelper} from '@dorst/frontend-helpers'
import {i18n, load as loadTranslations} from '@dorst/frontend-translations'
import {
    ConsumptionOptionType,
    LandingRedirectInfo,
    Pathname,
    ShopFull,
    ShopGroupHelper,
    TableCategory,
} from '@dorst/structures'

export function componentFromUrl(usePath?: string, properties?: Record<string, any>) {
    const path = usePath ?? window.location.pathname
    const parts = path.substring(1).split('/')

    // Check where we need to go
    const shopGroup = ShopGroupHelper.getShopGroupFromHost(location.host, path)
    if (shopGroup !== null) {
        return new ComponentWithProperties(NavigationController, {root: new ComponentWithProperties(ShopSelectionView, {shopGroup: shopGroup})})
    }

    // VillaVita redirect
    if (parts[0].toLowerCase() === 'villavita') {
        window.location.href = `https://bestel.villavita.life/${parts.slice(1).join('/')}`
    }

    // EK Barbomma redirect
    if (parts[0].toLowerCase() === 'ekbarbomma') {
        window.location.href = `https://dorst.app/ek-barbommabelgierusland/${parts.slice(1).join('/')}`
    }

    return new ComponentWithProperties(DynamicPromiseView, {
        promise: async () => {
            await Promise.all([loadTranslations()])

            let loadEmptyUri = false

            if (parts.length == 1 && parts[0] == 'order') {
                // Order page
                loadEmptyUri = true
            } else if (parts.length == 1 && parts[0] == 'cart') {
                // Order page
                loadEmptyUri = true
            } else if (parts.length == 2 && parts[0] == 'ober') {
                loadEmptyUri = true
            } else if (parts.length >= 2 && Object.values(Pathname).map(p => p.toString()).includes(parts[0])) {
                // Check table URL
                loadEmptyUri = true
            }

            // set redirect data from base64 (smakelijk & fb-feed)
            if (parts.length == 3 && parts[2] && (parts[1] == 'smakelijk' || parts[1] == 'fb')) {
                const redirectData: LandingRedirectInfo = LandingRedirectInfo.create(JSON.parse(atob(parts[2])))
                properties = {...properties, redirectData: redirectData}
            }

            try {
                await ServerManager.loadShop(loadEmptyUri ? '' : parts[0])
            } catch (e) {
                console.error(e)
                return new ComponentWithProperties(ShopNotFoundView, properties)
            }

            const {personalisation} = ServerManager.shop
            if (personalisation) {
                PersonalisationHelper.apply(personalisation, i18n.locale)
            }

            const callback = (shop: ShopFull) => {
                const {personalisation} = shop
                if (personalisation) {
                    PersonalisationHelper.remove(personalisation)
                }
            }

            await CartManager.load(ServerManager.shop.id)
            void AddressManager.load()
            if ((parts.length == 2 && parts[1] == 'order') || (parts.length == 1 && parts[0] == 'order')) {
                // Order page
                return new ComponentWithProperties(NavigationController, {root: new ComponentWithProperties(OrderPollView)})
            } else if ((parts.length == 2 && parts[1] == 'cart') || (parts.length == 1 && parts[0] == 'cart')) {
                // Order page
                return new ComponentWithProperties(NavigationController, {initialComponents: [new ComponentWithProperties(ShopView, {callback}), new ComponentWithProperties(CartView)]})
            } else if (parts.length == 3 && parts[1] == 'ober') {
                ServerManager.setPassword(parts[2])
                return new ComponentWithProperties(NavigationController, {root: new ComponentWithProperties(ShopView, {callback})})
            } else if (parts.length == 2 && parts[1] == 'toggle-kiosk-mode') {
                return new ComponentWithProperties(KioskModeView)
            } else {
                // Check table URL
                if (parts.length >= 3 && Object.values(Pathname).map(p => p.toString()).includes(parts[1])) {
                    const type = parts[1] === 'location' ? ConsumptionOptionType.TakeAway : ConsumptionOptionType.DineIn
                    setPreferredTable(parts.slice(2).join('/'), type)
                } else if (parts.length >= 2 && Object.values(Pathname).map(p => p.toString()).includes(parts[0])) {
                    const type = parts[0] === 'location' ? ConsumptionOptionType.TakeAway : ConsumptionOptionType.DineIn
                    setPreferredTable(parts.slice(1).join('/'), type)
                }
                // Shop page
                if (properties && properties.redirectData) {
                    if (properties.redirectData.consumptionMode) {
                        CartManager.setConsumptionMode(properties.redirectData.consumptionMode)
                        if (properties.redirectData.productId) { // product page (with category stack)
                            const stack = ServerManager.shop.getProductStack(properties.redirectData.productId, properties.redirectData.consumptionMode)
                            if (stack && stack.cartItem) { // push shopview + categoryviews + productview
                                const componentsStack: Array<ComponentWithProperties> = []
                                if (stack?.squashRootCategories && stack.categoryStack.length > 0) {
                                    componentsStack.push(new ComponentWithProperties(ShopView, {callback, ...properties, defaultSelectedCategory: stack?.categoryStack[0]}))
                                    stack?.categoryStack.shift()
                                } else {
                                    componentsStack.push(new ComponentWithProperties(ShopView, {callback, ...properties}))
                                }
                                stack?.categoryStack.forEach(category => {
                                    componentsStack.push(new ComponentWithProperties(CategoryView, {callback, ...properties, category: category, shop: ServerManager.shop}))
                                })
                                componentsStack.push(new ComponentWithProperties(ProductView, {callback, ...properties, cartItem: stack.cartItem, subItems: stack.subItems}))
                                return new ComponentWithProperties(NavigationController, {initialComponents: componentsStack})
                            }
                        }
                    }
                }

                const enabledConsumptionModes = ServerManager.shop.consumptionOptions.getEnabledConsumptionOptions()

                if (enabledConsumptionModes.length > 1 || (ServerManager.shop.personalisation?.alwaysShowSplashScreen ?? false)) {
                    return new ComponentWithProperties(NavigationController, {root: new ComponentWithProperties(ShopSplashView, {callback})})
                }

                if (enabledConsumptionModes.length === 1) {
                    const enabledMode = enabledConsumptionModes[0]
                    CartManager.setConsumptionMode(enabledMode)
                }

                return new ComponentWithProperties(NavigationController, {root: new ComponentWithProperties(ShopView, {callback, ...properties})})
            }
        },
    })
}

function setPreferredTable(preferred: string, type: ConsumptionOptionType) {
    const parts = preferred.split('/')

    if (parts.length == 0) {
        return false
    }
    let pool = ServerManager.shop.tableCategories.filter(c => c.type == type)

    if (!ServerManager.shop.enableTableCategories && type == ConsumptionOptionType.DineIn) {
        // Find our table if we can
        const lastPart = parts[parts.length - 1]

        const table = ServerManager.shop.tables.filter(c => c.type == type).find(t => lastPart && t.number.toLowerCase() === lastPart.toLowerCase())
        if (table) {
            CartManager.setTable(table)
            CartManager.setTableCategories([])
            CartManager.didScan = true
            return true
        }

        return false
    }

    const lastPart = parts[parts.length - 1]

    // Find our categories if we can and afterwards check for our table
    const categories = parts.reduce<Array<TableCategory>>((categories, part, index) => {
        if (index != categories.length) {
            return []
        }

        const cat = pool.find(c => c.number.toLowerCase() === part.toLowerCase())
        if (cat) {
            pool = cat.tableCategories
            return [...categories, cat]
        }
        return categories
    }, [])

    if (categories.length > 0) {
        const nestedTable = categories[categories.length - 1].tables.filter(c => c.type == type).find(t => lastPart && t.number.toLowerCase() === lastPart.toLowerCase())
        if (nestedTable) {
            CartManager.setTableCategories(categories)
            CartManager.setTable(nestedTable)
            CartManager.didScan = true
            return true
        }

        // Try to maintain current categories if already selected
        if (CartManager.checkout.tableCategories && CartManager.checkout.tableCategories.length > categories.length) {
            let matching = true
            for (const [index, cat] of CartManager.checkout.tableCategories.entries()) {
                if (index >= categories.length) {
                    break
                }
                if (cat.id != categories[index].id) {
                    matching = false
                    break
                }
            }

            if (!matching) {
                CartManager.setTableCategories(categories)
                CartManager.setTable(undefined)
                return true
            }
            return false
        }

        CartManager.setTableCategories(categories)
        CartManager.setTable(undefined)
        return true
    }

    console.log('Invalid table url', preferred)
    return false
}
