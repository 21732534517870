

















import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import OrderItemRow from './OrderItemRow.vue'
import {DRSTBox, DRSTBoxItem} from '@dorst/components'
import {OrderConsumer, OrderItem} from '@dorst/structures'

@Component({
    components: {
        DRSTBox,
        OrderItemRow,
        DRSTBoxItem,
    },
})
export default class OrderDetailBox extends Mixins(NavigationMixin) {
    @Prop({default: '', type: String})
    title!: string

    @Prop({required: true, type: OrderConsumer})
    order: OrderConsumer

    getSubItems(item: OrderItem): Array<OrderItem> {
        return this.order.getSubItemsForCartItemId(item.id)
    }
}
