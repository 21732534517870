import {AutoEncoder, field, NumberDecoder, ObjectData, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {encoding} from '../package.json'
import {Country, CountryDecoder} from './CountryDecoder'

export class LatLng extends AutoEncoder {
    @field({decoder: NumberDecoder})
    lat: number

    @field({decoder: NumberDecoder})
    lng: number
}
export class Address extends AutoEncoder {
    @field({decoder: StringDecoder, optional: true, nullable: true})
    street: string | null = null

    @field({decoder: StringDecoder, optional: true, nullable: true})
    number: string | null = null

    @field({decoder: StringDecoder, optional: true, nullable: true})
    address1: string | null = null

    @field({decoder: StringDecoder, optional: true, nullable: true})
    address2: string | null = null

    @field({decoder: StringDecoder, optional: true, nullable: true})
    postalCode: string | null = null

    @field({decoder: StringDecoder})
    city = ''

    @field({decoder: CountryDecoder})
    country: Country

    /** if null => we searched for latlng but could not determine it. */
    @field({decoder: LatLng, optional: true, nullable: true})
    latLng?: LatLng | null

    static createDefault(country: Country = 'BE'): Address {
        return Address.create({country: country})
    }

    get line1() {
        return this.address1 ? this.address1 : `${this.street ?? ''} ${this.number ?? ''}`
    }

    get line2() {
        return `${this.postalCode ? `${this.postalCode} ` : ''}${this.address2 ? `${this.address2}\n` : ''}${this.city}`
    }

    toString() {
        return `${this.line1}, ${this.line2}, ${this.country}`
    }

    get multilineString() {
        return `${this.line1}\n${this.line2}, ${this.country}`
    }

    isValid() {
        if (!this.country) {
            return false
        }
        if (this.country === 'BE' || this.country === 'NL' || this.country === 'FR') {
            if (!this.postalCode || !this.city || !this.street || !this.number) {
                return false
            }
        } else if (this.country === 'AE' || this.country === 'IN') {
            if (!this.address1 || !this.address2) {
                return false
            }
        }
        return true
    }

    /** Have we set something else than the default? (beside country) */
    hasAddressData() {
        return this.street != null || this.number != null || this.city != '' || this.address1 != null || this.address2 != null
    }

    clone() {
        return Address.decode(new ObjectData(this.encode({version: encoding.version}), {version: encoding.version}))
    }

    static getAddressTypeForCountry(country: Country): AddressType {
        switch (country) {
            case 'BE':
            case 'NL':
            case 'FR':
            case 'GB':
                return AddressType.DEFAULT
            case 'AE':
            case 'IN':
                return AddressType.INTERNATIONAL
            default: {
                // const t: never = country;
                throw `unknown country: ${country}`
            }
        }
    }
}

export enum AddressType {
    DEFAULT = 0,
    INTERNATIONAL = 1,
}

export class IDAddress extends Address {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string
}

export const AddressPatch = Address.patchType()
export const IDAddressPatch = IDAddress.patchType()
