var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view discount-selection-view" },
    [
      _c(
        "DRSTNavigationBar",
        { attrs: { title: _vm.$t("consumer.discounts.discounts") } },
        [
          _vm.canPop
            ? _c("button", {
                staticClass: "button icon back",
                attrs: { slot: "left" },
                on: { click: _vm.pop },
                slot: "left"
              })
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "main",
        [
          _c("h1", [_vm._v(_vm._s(_vm.$t("consumer.discounts.discounts")))]),
          _vm._v(" "),
          _c(
            "DRSTBox",
            _vm._l(_vm.discountSources, function(source) {
              return _c(
                "DRSTBoxItem",
                {
                  key: source.id,
                  attrs: { selectable: true },
                  on: {
                    click: function($event) {
                      return _vm.selectSource(source)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.getName(source)) +
                      "\n                "
                  ),
                  _c(
                    "div",
                    { attrs: { slot: "right" }, slot: "right" },
                    [
                      _c("Radio", {
                        attrs: { name: "discount-source", value: source },
                        nativeOn: {
                          click: function($event) {
                            $event.preventDefault()
                          }
                        },
                        model: {
                          value: _vm.selectedSource,
                          callback: function($$v) {
                            _vm.selectedSource = $$v
                          },
                          expression: "selectedSource"
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            }),
            1
          ),
          _vm._v(" "),
          _vm.error
            ? _c("RemarkBlock", { attrs: { type: "error" } }, [
                _vm._v("\n            " + _vm._s(_vm.error) + "\n        ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "DRSTBox",
            [
              _c("TextInputRow", {
                attrs: { placeholder: _vm.$t("backoffice.discounts.code") },
                model: {
                  value: _vm.code,
                  callback: function($$v) {
                    _vm.code = $$v
                  },
                  expression: "code"
                }
              }),
              _vm._v(" "),
              _vm.selectedSource.type === _vm.DiscountType.EMPLOYEE
                ? _c(
                    "DRSTBoxItem",
                    { attrs: { selectable: true } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("backoffice.discounts.dateOfBirth")) +
                          "\n                "
                      ),
                      _c("DateInput", {
                        attrs: { slot: "right" },
                        slot: "right",
                        model: {
                          value: _vm.verification,
                          callback: function($$v) {
                            _vm.verification = $$v
                          },
                          expression: "verification"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("DRSTFloatingFooter", [
        _c(
          "button",
          {
            staticClass: "button primary fill",
            attrs: { disabled: !_vm.canVerify },
            on: { click: _vm.verifyDiscount }
          },
          [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("consumer.discounts.apply")) +
                "\n        "
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }