import {AutoEncoder} from '@simonbackx/simple-encoding'

import {DecodableRecord} from '../DecodableRecord'
import {Language, TranslatedString} from '../Language'

export class DeliverectTranslatable extends AutoEncoder {
    // Note: the protected properties' type must match the typing of the deliverect classes
    // that extend `DeliverectTranslatable`...

    protected name: string
    protected nameTranslations: Partial<Record<Language, string>>
    protected description: string
    protected descriptionTranslations: Partial<Record<Language, string>>

    // ... but since those classes use `@field` decorators with a `DecodableLanguageRecord` decoder,
    // we have to cast when using the `this` values below.

    getTranslatedName(language: Language) {
        const nameTranslations = this.nameTranslations as unknown as DecodableRecord<Language, string>
        const hasTranslations = Object.keys(nameTranslations.data).length > 0
        const hasName = this.name.length > 0

        if (hasTranslations) {
            return new TranslatedString(nameTranslations.data)
        }

        if (hasName) {
            return new TranslatedString({[language]: this.name})
        }

        return new TranslatedString({})
    }

    getTranslatedDescription(language: Language) {
        const descriptionTranslations = this.descriptionTranslations as unknown as DecodableRecord<Language, string>
        const hasTranslations = Object.keys(descriptionTranslations.data).length > 0
        const hasDescription = this.description.length > 0

        if (hasTranslations) {
            return new TranslatedString(descriptionTranslations.data)
        }

        if (hasDescription) {
            return new TranslatedString({[language]: this.description})
        }

        return new TranslatedString({})
    }
}
