var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("label", { staticClass: "status-select" }, [
    _c("div", { staticClass: "status-select-body" }, [
      _vm.value === true ? _c("span", { staticClass: "icon plus" }) : _vm._e(),
      _vm._v(" "),
      _vm.value === false ? _c("span", { staticClass: "icon minus" }) : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }