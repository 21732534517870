import {AutoEncoder, BooleanDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'

export class ShopDomain extends AutoEncoder {
    @field({decoder: StringDecoder, nullable: true})
    domain: string | null = null

    @field({decoder: StringDecoder, nullable: true})
    uri: string | null = null

    @field({decoder: BooleanDecoder})
    isExternal: boolean
}
