var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "drst-box" }, [
    _vm.title !== "" ||
    _vm.editable ||
    _vm.forceRightSlot ||
    _vm.$slots.title !== undefined
      ? _c(
          "div",
          { staticClass: "title-bar" },
          [
            _vm._t("title", [
              _vm.title && !_vm.editable
                ? _c(
                    "div",
                    {
                      staticClass: "title-container",
                      class: {
                        "is-multi-row": _vm.hasTitleAnnotation,
                        "annotation-right": _vm.annotationRight
                      }
                    },
                    [
                      _c("div", { staticClass: "title-wrapper" }, [
                        _c("h2", { staticClass: "title" }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.title) +
                              "\n                    "
                          )
                        ]),
                        _vm._v(" "),
                        _vm.hasError
                          ? _c("div", { staticClass: "icon error" })
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _vm.hasTitleAnnotation
                        ? _c(
                            "div",
                            { staticClass: "title-annotation" },
                            [_vm._t("annotation")],
                            2
                          )
                        : _vm._e()
                    ]
                  )
                : _vm.editable
                ? _c("div", { staticClass: "title-input" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.editTitle,
                          expression: "editTitle"
                        }
                      ],
                      attrs: {
                        type: "text",
                        placeholder: _vm.titlePlaceholder
                      },
                      domProps: { value: _vm.editTitle },
                      on: {
                        mousedown: _vm.selectIfNotFocus,
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.editTitle = $event.target.value
                        }
                      }
                    })
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.title === "" ||
            !_vm.editable ||
            (_vm.$slots.title === undefined && _vm.forceRightSlot)
              ? _c("div", { staticClass: "title-input" })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "right" }, [_vm._t("right")], 2)
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "content", class: { "with-bg": _vm.withBg } },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }