import {AnalyticProcessor, AnalyticsEvents} from './AnalyticsProcessorInterface'
import {FacebookPixelAnalytics} from './FacebookPixelAnalytics'
import {GoogleTagManagerAnalytics} from './GoogleTagManagerAnalytics'

export class AnalyticsController {
    private static singleton: AnalyticsController | undefined

    private constructor(
        private readonly processors: Array<AnalyticProcessor>,
    ) {
    }

    static get(): AnalyticsController {
        if (this.singleton !== undefined) {
            return this.singleton
        }

        this.singleton = new AnalyticsController([
            new FacebookPixelAnalytics(),
            new GoogleTagManagerAnalytics(),
        ])

        return this.singleton
    }

    event<T extends keyof AnalyticsEvents>(event: T, ...args: Parameters<AnalyticsEvents[T]>): void {
        for (const processor of this.processors) {
            try {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                processor[event](...args)
            } catch (error) {
                console.error({
                    message: 'Could not process analytics',
                    processor: processor.name,
                    error: error,
                    args,
                })
            }
        }
    }

    async grantConsent(processorsNames: Array<string>): Promise<any> {
        const grantConsentPromises = this.processors
            .filter(processor => processorsNames.includes(processor.name))
            .map(async processor => processor.grantConsent())
        return Promise.allSettled(grantConsentPromises)
    }

    getAllConfiguredProcessors(): Array<string> {
        return this.processors
            .filter(implementer => implementer.isConfigured())
            .map(implementer => implementer.name)
    }

    revokeConsent(processorsNames: Array<string>): void {
        for (const processor of this.processors) {
            if (processorsNames.includes(processor.name)) {
                processor.revokeConsent()
            }
        }
    }
}
