var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "availability-label",
      on: {
        click: function($event) {
          $event.stopPropagation()
        }
      }
    },
    [
      _c(
        "button",
        {
          directives: [
            {
              name: "clicked-outside",
              rawName: "v-clicked-outside",
              value: _vm.onClickedOutside,
              expression: "onClickedOutside"
            }
          ],
          staticClass: "availability-button",
          class: { active: _vm.collapsed },
          on: { click: _vm.toggleCollapse }
        },
        [
          _c("span", { staticClass: "availability-title" }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("common.availability.customAvailability")) +
                "\n            "
            ),
            _c("i", { staticClass: "icon arrow-down-simple" })
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "availability-list" },
            _vm._l(_vm.availabilityTexts, function(availabilityText, index) {
              return _c(
                "span",
                {
                  key: _vm.productId + "_" + index + "_availabilityText",
                  staticClass: "availability-list-item"
                },
                [
                  _c(
                    "span",
                    { staticClass: "availability-list-item--content" },
                    [_vm._v(_vm._s(availabilityText))]
                  )
                ]
              )
            }),
            0
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }