var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view shop-message-view", on: { click: _vm.dismiss } },
    [
      _c("button", {
        staticClass: "button icon close",
        attrs: { slot: "right" },
        slot: "right"
      }),
      _vm._v(" "),
      _c("main", { staticClass: "message" }, [
        _c(
          "div",
          { class: { "hide-background-image": _vm.hideBackgroundImage } },
          [
            _vm.hasTitle
              ? _c("h1", [
                  _vm._v(
                    "\n                " + _vm._s(_vm.title) + "\n            "
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.text))])
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }