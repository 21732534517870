var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view timeslot-selection-view" },
    [
      _c(
        "DRSTNavigationBar",
        {
          attrs: {
            title: _vm.takeAwayMode
              ? _vm.$t("customer.details.selectTimeSlotButton")
              : _vm.$t("customer.details.selectDeliveryTimeSlotButton")
          }
        },
        [
          _c("button", {
            staticClass: "button icon back",
            attrs: { slot: "left" },
            on: { click: _vm.pop },
            slot: "left"
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "main",
        [
          _c("h1", [
            _vm._v(
              _vm._s(
                _vm.takeAwayMode
                  ? _vm.$t("customer.details.selectTimeTitle")
                  : _vm.$t("customer.details.selectDeliveryTimeSlotTitle")
              )
            )
          ]),
          _vm._v(" "),
          _c(
            "DRSTBox",
            [
              _c(
                "DRSTBoxItem",
                { staticClass: "timeslot-selection-header" },
                [
                  _c("button", {
                    staticClass: "button icon arrow-left",
                    class: {
                      invisible:
                        !_vm.hasPreviousDay || _vm.dayWithTimeslots === null
                    },
                    on: { click: _vm.previousDay }
                  }),
                  _vm._v(" "),
                  _vm.day !== null
                    ? _c("h1", [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.dateWithDay) +
                            "\n                "
                        )
                      ])
                    : _c("Spinner"),
                  _vm._v(" "),
                  _c("button", {
                    staticClass: "button icon arrow-right",
                    class: {
                      invisible:
                        !_vm.hasNextDay || _vm.dayWithTimeslots === null
                    },
                    on: { click: _vm.nextDay }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.dayWithTimeslots !== null && !_vm.showingToday
            ? _c(
                "DRSTBox",
                { staticClass: "timeslot-selection-warning" },
                [
                  _c("DRSTBoxItem", [
                    _c("img", {
                      attrs: {
                        src: require("@dorst/assets/icons/color/warning.svg")
                      }
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$t("customer.timeslots.notOrderingForToday")
                        ) +
                        "\n            "
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          Object.keys(_vm.itemAvailabilityTexts).length > 0
            ? _c(
                "DRSTBox",
                { staticClass: "timeslot-products-warning" },
                [
                  _c("DRSTBoxItem", [
                    _c("h3", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.$t(
                              "customer.timeslots.limitedAvailabilityWarning"
                            )
                          ) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "ul",
                      _vm._l(_vm.itemAvailabilityTexts, function(
                        texts,
                        productName,
                        index
                      ) {
                        return _c("li", { key: index + "_" + productName }, [
                          _vm._v(
                            "\n                        " +
                              _vm._s(productName) +
                              "\n                        "
                          ),
                          _c(
                            "ul",
                            _vm._l(texts, function(text, idx) {
                              return _c(
                                "li",
                                { key: index + "_" + idx + "_text" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(text) +
                                      "\n                            "
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      }),
                      0
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.dayWithTimeslots !== null
            ? [
                _vm.timeslots.length > 0
                  ? _c(
                      "DRSTBox",
                      _vm._l(_vm.timeslots, function(timeslot) {
                        return _c(
                          "DRSTBoxItem",
                          {
                            key: timeslot.start,
                            staticClass: "timeslot-row-selection",
                            class: {
                              "selected-timeslot":
                                _vm.selectedTimeslot === timeslot.start
                            },
                            attrs: { selectable: true, "element-name": "label" }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm._f("minuteToTime")(timeslot.start)) +
                                " – " +
                                _vm._s(_vm._f("minuteToTime")(timeslot.end)) +
                                "\n                    "
                            ),
                            _c(
                              "div",
                              { attrs: { slot: "right" }, slot: "right" },
                              [
                                _c("Radio", {
                                  attrs: {
                                    value: timeslot.start,
                                    name: "timeslot-scanned"
                                  },
                                  model: {
                                    value: _vm.selectedTimeslot,
                                    callback: function($$v) {
                                      _vm.selectedTimeslot = $$v
                                    },
                                    expression: "selectedTimeslot"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      }),
                      1
                    )
                  : _vm.hasNextDay || _vm.hasPreviousDay
                  ? _c(
                      "DRSTBox",
                      [
                        _c(
                          "DRSTBoxItem",
                          { attrs: { "element-name": "label" } },
                          [
                            _c("img", {
                              attrs: {
                                slot: "left",
                                src: require("@dorst/assets/icons/color/error.svg"),
                                alt: "Error icon"
                              },
                              slot: "left"
                            }),
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$t("customer.timeslots.noneAvailable")
                                ) +
                                "\n                "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _c(
                      "DRSTBox",
                      [
                        _c(
                          "DRSTBoxItem",
                          { attrs: { "element-name": "label" } },
                          [
                            _c("img", {
                              attrs: {
                                slot: "left",
                                src: require("@dorst/assets/icons/color/error.svg"),
                                alt: "Error icon"
                              },
                              slot: "left"
                            }),
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.$t(
                                    "customer.timeslots.orderingNotPossible"
                                  )
                                ) +
                                "\n                "
                            )
                          ]
                        )
                      ],
                      1
                    )
              ]
            : _vm.day !== null
            ? _c("Spinner")
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "DRSTFloatingFooter",
        [
          _c("SpinnerButton", { attrs: { loading: _vm.saveLoading } }, [
            _c(
              "button",
              {
                staticClass: "button primary fill",
                attrs: { disabled: !_vm.validDeliveryDate },
                on: { click: _vm.save }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(
                      _vm.buttonText ? _vm.buttonText : _vm.$t("general.save")
                    ) +
                    "\n            "
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }