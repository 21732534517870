import {applyShopTranslationOverrides} from '@dorst/frontend-translations'
import {CssVariables, Image, PersonalisedText, ShopPersonalisation} from '@dorst/structures'

export class CSSVariableHelper {
    static applyToRoot(variables: CssVariables | undefined | null) {
        if (!variables) {
            return
        }

        const style = document.createElement('style')
        document.head.appendChild(style)

        /** Passing a negative amount will make the color go darker*/
        function lighten(color, amount) {
            return `#${color.replace(/^#/, '').replace(/../g, color => `0${Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)}`.substr(-2))}`
        }

        CssVariables.fields
            .map(({property}) => {
                const value = variables[property]
                const name = toParamCase(property)
                return {name, value}
            })
            .filter(({value}) => value != null && value.length > 0)
            .forEach(({name, value}) => {
                document.documentElement.style.setProperty(`--${name}`, value as string)
            })
    }

    static removeFromRoot(variables: CssVariables | undefined | null) {
        if (!variables) {
            return
        }
        CssVariables.fields
            .map(({property}) => {
                const value = variables[property]
                const name = toParamCase(property)
                return {name, value}
            })
            .filter(({value}) => value != null && value.length > 0)
            .forEach(({name, value}) => {
                document.documentElement.style.removeProperty(`--${name}`)
            })
    }

    static applyBackgroundImage(image: Image | string | undefined | null, {variableName = 'background-image', cssSelector = '.drst-view'} = {}) {
        if (!image) {
            return
        }

        const style = document.createElement('style')
        style.dataset.variableName = variableName

        document.head.appendChild(style)

        style.sheet?.insertRule(`:root { --${variableName}: url("${typeof image === 'string' ? image : image.getPathForSize()}") }`)
        style.sheet?.insertRule(`${cssSelector} { background: var(--color-current-background, $color-background-shade) var(--${variableName}) center center no-repeat fixed; background-size: cover; }`)
    }

    static removeBackgroundImage({variableName = 'background-image'} = {}) {
        const style = document.head.querySelector(`style[data-variable-name="${variableName}"]`)
        if (!style) {
            return
        }

        document.head.removeChild(style)
    }
}

export class PersonalisedTextHelper {
    static refreshTranslationOverrides(personalisedText: Array<PersonalisedText>, currentLanguage: string) {
        if (!currentLanguage) {
            return
        }

        void applyShopTranslationOverrides(personalisedText, currentLanguage.substr(0, 2))
    }
}

export class PersonalisationHelper {
    static apply({backgroundImage, splashBackgroundImage, cssVariables, personalisedText}: ShopPersonalisation, currentLanguage: string) {
        CSSVariableHelper.applyBackgroundImage(backgroundImage)
        CSSVariableHelper.applyBackgroundImage(splashBackgroundImage, {cssSelector: '.drst-view.shop-splash-view', variableName: 'background-image-splash'})
        CSSVariableHelper.applyToRoot(cssVariables)
        PersonalisedTextHelper.refreshTranslationOverrides(personalisedText, currentLanguage)
    }

    static remove({cssVariables}: ShopPersonalisation) {
        CSSVariableHelper.removeFromRoot(cssVariables)
        CSSVariableHelper.removeBackgroundImage({variableName: 'background-image-splash'})
        CSSVariableHelper.removeBackgroundImage()
    }
}

function toParamCase(str: string): string {
    return str.replace(/([a-z])([A-Z])/g, (_, a: string, b: string) => `${a}-${b.toLowerCase()}`)
}
