











import {ComponentWithProperties, FramedComponent, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop, Vue} from 'vue-property-decorator'

import LoadingCover from './LoadingCover.vue'

@Component({
    components: {
        FramedComponent,
        LoadingCover,
    },
})
export default class DynamicPromiseView extends Mixins(NavigationMixin) {
    @Prop({required: true})
    promise: () => Promise<ComponentWithProperties>

    root: ComponentWithProperties | null = null

    mounted() {
        this.run()
    }

    run() {
        this.promise.call(this).then((value) => {
           this.root = value
        }).catch(e => {
            console.error(e)
            console.error('Promise error not caught, defaulting to dismiss behaviour in DynamicPromiseView')
            this.dismiss()
        })
    }

    reload() {
        this.root = null
        this.run()
    }

    async shouldNavigateAway(): Promise<boolean> {
        if (!this.root) {
            return true
        }
        return await this.root.shouldNavigateAway()
    }
}
