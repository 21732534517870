import {AutoEncoder, field, IntegerDecoder, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

export class ProductPrice extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string
    /**
     * Name of this price. You can leave this empty if you only have one price
     */
    @field({decoder: StringDecoder})
    name = ''
    /**
     * Price, in cents
     */
    @field({decoder: IntegerDecoder})
    price = 0
}

export const ProductPricePatch = ProductPrice.patchType()
