
































import {Component, Prop, Vue} from 'vue-property-decorator'

import SelectRow from './SelectRow.vue'
import TextInputRow from './TextInputRow.vue'
import {AddressField, AddressMatrix, InputFieldHelper} from '@dorst/frontend-helpers'
import {Address, Country, CountryHelper} from '@dorst/structures'

@Component({
    components: {
        TextInputRow,
        SelectRow,
    },
})
export default class DRSTAddressMatrixInputBox extends Vue {
    AddressField = AddressField
    InputFieldHelper = InputFieldHelper

    @Prop({default: '', type: String})
    title!: string

    @Prop({required: true})
    matrix!: AddressMatrix

    @Prop({required: true})
    domain!: string

    get editMatrix() {
        return new AddressMatrix([...this.matrix.matrix])
    }

    @Prop()
    value!: Address

    @Prop()
    defaultCountry!: Country | undefined

    @Prop({default: false})
    disabled: boolean

    updateValue(field: AddressField, value: string | Country) {
        const copy = this.value ? this.value.clone() : Address.createDefault()
        for (const key of Object.keys(copy)) {
            if (!this.editMatrix.matrix.find(el => el.addressField === key)) {
                delete copy[key]
            }
        }
        this.$set(copy, field, value)
        this.$emit('input', copy)
    }

    get availableCountries() {
        return CountryHelper.getList(this.$i18n as any, this.domain)
    }
}
