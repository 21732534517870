













import {
    ComponentWithProperties,
    NavigationController,
    NavigationMixin,
} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {KioskModeService} from '../classes/KioskModeService'
import {ServerManager} from '../classes/ServerManager'
import ShopSplashView from './ShopSplashView.vue'
import ShopView from './ShopView.vue'
import {Toggle} from '@dorst/components'

@Component({
    components: {
        Toggle,
    },
})
export default class KioskModeView extends Mixins(NavigationMixin) {

    @Prop({type: String})
    readonly shopSlug: string

    get kioskModeEnabled(): boolean {
        return KioskModeService.isKioskModeEnabled()
    }

    set kioskModeEnabled(value: boolean) {
        KioskModeService.setKioskModeEnabled(value)
    }

    goToMenu() {
        const enabledConsumptionModes = ServerManager.shop.consumptionOptions.getEnabledConsumptionOptions()
        const nextView = enabledConsumptionModes.length > 1 || (ServerManager.shop.personalisation?.alwaysShowSplashScreen ?? false)
            ? ShopSplashView
            : ShopView

        this.show(new ComponentWithProperties(NavigationController, {root: new ComponentWithProperties(nextView)}))
    }
}
