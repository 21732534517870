var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DRSTBoxItem", { staticClass: "order-item" }, [
    _c(
      "div",
      { staticClass: "amount-times", attrs: { slot: "left" }, slot: "left" },
      [
        _vm._v("\n        " + _vm._s(_vm.item.amount) + "\n        "),
        _c("span", { staticClass: "icon times" })
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "order-item-detail" }, [
      _c("h3", [_c("span", [_vm._v(_vm._s(_vm.name))])]),
      _vm._v(" "),
      _c("pre", { staticClass: "description" }, [
        _vm._v(_vm._s(_vm.getOptionDescription()))
      ]),
      _vm._v(" "),
      _c("footer", [
        _c("p", { staticClass: "price" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm._f("price")(_vm.getOptionPrice())) +
              "\n            "
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }