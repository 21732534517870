export class OpeningHourHelper {
    /**
     * Allows performing an action every minute, starting from the closest minute.
     * @param callback - Callback to trigger on every minute
     * @returns Callbacks to start and stop the timers that trigger the callback
     */
    static getStartStop(callback: () => void) {
        let nextMinuteTimeout: ReturnType<typeof setTimeout>
        let nextMinuteInterval: ReturnType<typeof setInterval>

        const start = () => {
            const now = new Date()
            const msToNextMinute = (1000 * 60) - now.getSeconds() * 1000 - now.getMilliseconds()
            nextMinuteTimeout = setTimeout(() => {
                callback()
                nextMinuteInterval = setInterval(callback, 1000 * 60) // every minute
            }, msToNextMinute)
        }

        const stop = () => {
            clearTimeout(nextMinuteTimeout)
            clearInterval(nextMinuteInterval)
        }

        return {start, stop}
    }
}
