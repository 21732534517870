var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view" },
    [
      _c("DRSTNavigationBar", {
        attrs: { title: _vm.$t("customer.order.processing") }
      }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "polling-view" },
        [
          _c(
            "div",
            { staticClass: "polling-view-message" },
            [
              !_vm.errorOnValidation
                ? [
                    _c("h1", [
                      _vm._v(_vm._s(_vm.$t("customer.order.processing")))
                    ]),
                    _vm._v(" "),
                    _c("Spinner"),
                    _vm._v(
                      "\n\n                " +
                        _vm._s(_vm.$t("customer.order.oneMomentPlease")) +
                        "\n            "
                    )
                  ]
                : [
                    _c("h1", { staticClass: "error-title" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("customer.order.processingFailed")) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "icon error" }),
                    _vm._v(
                      "\n\n                " +
                        _vm._s(_vm.$t("customer.order.goToPos")) +
                        "\n            "
                    )
                  ]
            ],
            2
          ),
          _vm._v(" "),
          _c("OrderDetailBox", {
            attrs: {
              title: _vm.$t("customer.order.detailTitle"),
              order: _vm.order
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }