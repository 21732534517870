




































































































































































import {ComponentWithProperties, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {CartManager} from '../../classes/CartManager'
import {ServerManager} from '../../classes/ServerManager'
import {PriceHelper} from '../../helpers/PriceHelper'
import {PruneHelper} from '../../helpers/PruneHelper'
import EditCartItemOptionsView from '../EditCartItemOptionsView.vue'
import {Checkbox, DRSTBox, DRSTBoxItem, Radio, RemarkBlock, Stepper} from '@dorst/components'
import LongPressDirective from '@dorst/components/src/LongPressDirective'
import {isNullOrEmpty} from '@dorst/helpers'
import {
    CartItem,
    CartItemOption,
    ConsumptionOptionType,
    Image,
    Option,
    OptionGroup,
    OptionGroupMode,
    Product,
    Resolution,
} from '@dorst/structures'

@Component({
    components: {
        Checkbox,
        DRSTBox,
        RemarkBlock,
        DRSTBoxItem,
        Radio,
        Stepper,
    },
    directives: {
        longpress: LongPressDirective,
    },
})
export default class OptionGroupBox extends Mixins(NavigationMixin) {
    OptionGroupMode = OptionGroupMode

    // When adding props, make sure it's compatible with OptionGroupGroupBox

    @Prop({required: true, type: OptionGroup})
    optionGroup: OptionGroup

    @Prop({required: true, type: Number})
    priceOffset: number

    @Prop({required: true, type: Array})
    subItems: Array<CartItem>

    @Prop({required: true})
    editable: boolean

    @Prop({required: true})
    editCartItem: CartItem

    // You can pass options from optionGroups, we'll leave those untouched
    @Prop({type: Array})
    value: Array<CartItemOption>

    @Prop({default: false})
    hasAmountError: boolean

    @Prop({default: false})
    partOfLinkedGroup: boolean

    @Prop({default: false})
    onlyAnnotation: boolean

    @Prop({required: true})
    consumptionMode: ConsumptionOptionType

    get showMinMaxLabels() {
        if (this.optionGroup.mode === OptionGroupMode.Single) {
            return false
        }
        if (this.optionGroup.mode === OptionGroupMode.Multiple && this.optionGroup.maxAmount == this.optionGroup.options.length) {
            return false
        }
        if (this.optionGroup.minAmount && this.currentTotalOptionAmount < this.optionGroup.minAmount) {
            return true
        }
        return !(this.optionGroup.maxAmount === null || this.optionGroup.maxAmount === 0)
    }

    get amountMap(): Record<string, number> {
        const obj = {}

        for (const option of this.optionGroup.options) {
            obj[option.id] = 0
        }

        for (const option of this.value) {
            if (obj[option.option.id] !== undefined && option.optionGroup.id === this.optionGroup.id) {
                obj[option.option.id] = option.amount
            }
        }

        return obj
    }

    get activeOptionsWithinActiveGroup(): Array<CartItemOption> {
        return this.value.filter(el => el.optionGroup.id === this.optionGroup.id) ?? []
    }

    /**
     *
     * @param id - CartItem ID to fetch.
     * @returns {CartItem | undefined}
     */
    getSubItemById(id: string | null | undefined): CartItem | undefined {
        if (id == null) {
            return
        }
        return this.subItems.find(el => el.id === id)
    }

    removeSubItemById(id: string) {
        this.$emit('removeOptionProduct', id)
    }

    addSubItem(cartItem: CartItem) {
        this.$emit('addOptionProduct', cartItem)
    }

    onSelectOption(option: Option) {
        switch (this.optionGroup.mode) {
            case OptionGroupMode.Single:
                this.onSingleOptionSelect(option)
                break
            case OptionGroupMode.Multiple:
                this.onMultipleOptionSelect(option)
                break
            case OptionGroupMode.MultipleWithAmounts:
                this.onMultipleWithAmountsOptionSelect(option)
                break
            default:
                console.error(`Unknown optiongroupmode: ${this.optionGroup.mode} for option with id: ${option.id}`)
        }
    }

    onMultipleWithAmountsOptionSelect(option: Option, operation: 'subtract' | 'add' = 'add') {
        const optionProd = this.getOptionProduct(option)
        const currentCartOption = this.activeOptionsWithinActiveGroup.find(el => {
            return el.option.id === option.id
        })
        if (operation === 'subtract') {
            if (currentCartOption === undefined) {
                return
            }
            if (currentCartOption.amount === 1 && optionProd !== null) {// remove linked cartitem
                const itemToRemove = this.getSubItemById(currentCartOption.subCartItemId)
                if (itemToRemove !== undefined) {
                    this.removeSubItemById(itemToRemove.id)
                }
            }
            this.removeOption(option)
        } else if (optionProd === null) {// add option
            this.setOption(option)
        } else {// add option + linked cart item
            const cartItem = this.getSubItemById(option.productLink?.productId) ?? CartItem.defaultForProduct(optionProd, []).cartItem
            if (currentCartOption === undefined && optionProd.hasAdditionalChoices()) { // if that product has variants and/or optiongroups
                this.present(new ComponentWithProperties(EditCartItemOptionsView, {
                    cartItem: cartItem.overwriteOptionLinkData(
                        this.editCartItem.id,
                        option,
                    ),
                    priceOffset: this.priceOffset,
                    editing: true,
                    alwaysSave: true,
                    // Note: not using the second parameter `subItems` for now.
                    onSave: (newCartItem: CartItem) => {
                        this.$emit('optionChanged')
                        // add everything after save (will only save if the newcartitem has valid options selected in popup.)
                        this.addSubItem(newCartItem)
                        this.setOption(option, cartItem.id)
                    },
                }).setDisplayStyle('popup'))
            } else {
                if (currentCartOption === undefined) {
                    this.addSubItem(cartItem.overwriteOptionLinkData(
                        this.editCartItem.id,
                        option,
                    ))
                }
                this.setOption(option, cartItem.id)
            }
        }
    }

    onMultipleOptionSelect(option: Option) {
        const currentCartOption = this.activeOptionsWithinActiveGroup.find(el => {
            return el.option.id === option.id
        })
        const optionProd = this.getOptionProduct(option)
        if (currentCartOption !== undefined) {// uncheck checkbox
            if (optionProd !== null) {// remove linked cartitem
                const itemToRemove = this.getSubItemById(currentCartOption?.subCartItemId)
                if (itemToRemove !== undefined) {
                    this.removeSubItemById(itemToRemove.id)
                }
            }
            this.removeOption(option)
        } else if (optionProd === null) {// add option
            this.setOption(option)
        } else {// add option + linked cart item
            const cartItem = this.getSubItemById(option.productLink?.productId) ?? CartItem.defaultForProduct(optionProd, []).cartItem
            if (optionProd.hasAdditionalChoices()) { // if that product has variants and/or optiongroups
                this.present(new ComponentWithProperties(EditCartItemOptionsView, {
                    cartItem: cartItem.overwriteOptionLinkData(
                        this.editCartItem.id,
                        option,
                    ),
                    priceOffset: this.priceOffset,
                    editing: true,
                    alwaysSave: true,
                    // Note: not using the second parameter `subItems` for now.
                    onSave: (newCartItem: CartItem) => {
                        this.$emit('optionChanged')
                        // add everything after save (will only save if the newcartitem has valid options selected in popup.)
                        this.addSubItem(newCartItem)
                        this.setOption(option, cartItem.id)
                    },
                }).setDisplayStyle('popup'))
            } else {
                this.addSubItem(
                    cartItem.overwriteOptionLinkData(
                        this.editCartItem.id,
                        option,
                    ),
                )
                this.setOption(option, cartItem.id)
            }
        }
    }

    onSingleOptionSelect(option: Option) {
        const currentCartOption = this.activeOptionsWithinActiveGroup.find(el => {
            return el.option.id === this.selectedOption.id
        })
        const wasAlreadySelectedBefore = option.id === currentCartOption?.option.id
        const optionProd = this.getOptionProduct(option)
        if (wasAlreadySelectedBefore && (optionProd === null || !optionProd.hasAdditionalChoices())) {
            return
        }

        const existingSubItem = this.getSubItemById(currentCartOption?.subCartItemId)

        // If it was already selected and has an option with additional choices
        if (wasAlreadySelectedBefore) {
            const cartItem = existingSubItem?.clone()
            if (cartItem === undefined) { // there should always already be a linked cart item
                console.error(`Skipping because we failed to clone the cartitem for option with id: ${currentCartOption?.subCartItemId}`)
                return
            }
            this.present(new ComponentWithProperties(EditCartItemOptionsView, {
                cartItem: cartItem,
                priceOffset: this.priceOffset,
                editing: true,
                alwaysSave: true,
                // Note: not using the second parameter `subItems` for now.
                onSave: (newCartItem: CartItem) => {
                    this.$emit('optionChanged')
                    this.addSubItem(newCartItem)
                },
            }).setDisplayStyle('popup'))
            return
        }

        // if the option has a product linked to it, create a cart item for it and link it to the cartitem option.
        if (optionProd === null) {
            this.setOption(option, null, true)
        } else if (optionProd.hasAdditionalChoices()) { // if that product has variants and/or optiongroups
                // if we already had this selected, select that cartItem...
                const cartItem = wasAlreadySelectedBefore && existingSubItem ? existingSubItem.clone() : CartItem.defaultForProduct(optionProd, []).cartItem
                // present edit popup and only switch after successful save...
                this.present(new ComponentWithProperties(EditCartItemOptionsView, {
                    cartItem: cartItem.overwriteOptionLinkData(
                        this.editCartItem.id,
                        option,
                    ),
                    priceOffset: this.priceOffset,
                    editing: true,
                    alwaysSave: true,
                    // Note: not using the second parameter `subItems` for now.
                    onSave: (newCartItem: CartItem) => {
                        this.$emit('optionChanged')
                        // add everything after save (will only save if the newCartItem has valid options selected in popup.)
                        this.addSubItem(newCartItem)
                        this.setOption(option, cartItem.id, true)
                        if (!wasAlreadySelectedBefore) { // remove the product linked to the previous selected radioButton
                            const itemToRemove = this.getSubItemById(currentCartOption?.subCartItemId)
                            if (itemToRemove) {
                                this.removeSubItemById(itemToRemove.id)
                            }
                        }
                    },
                }).setDisplayStyle('popup'))
            } else {
                const cartItem = this.getSubItemById(option.productLink?.productId) ?? CartItem.defaultForProduct(optionProd, []).cartItem
                this.addSubItem(cartItem.overwriteOptionLinkData(
                    this.editCartItem.id,
                    option,
                ))
                this.setOption(option, cartItem.id, true)
                if (!wasAlreadySelectedBefore) { // remove the product linked to the previous selected radioButton
                    const itemToRemove = this.getSubItemById(currentCartOption?.subCartItemId)
                    if (itemToRemove) {
                        this.removeSubItemById(itemToRemove.id)
                    }
                }
            }
    }

    get maxHasBeenReached() {
        return (this.optionGroup.maxAmount ?? Infinity) <= this.currentTotalOptionAmount
    }

    get optionGroupName() {
        return this.optionGroup.name.getForI18n(this.$i18n)
    }

    get isSingleSelection() {
        return this.optionGroup.mode === OptionGroupMode.Single
    }

    get isMultiple() {
        return this.optionGroup.mode === OptionGroupMode.Multiple
    }

    get isMultipleWithAmounts() {
        return this.optionGroup.mode === OptionGroupMode.MultipleWithAmounts
    }

    getDisplayIncludedLabel(option: Option) {
        return option.amountIncludedInProductPrice > 0
    }

    getDisplayAdditionalOptionsLabel(option: Option) {
        return this.getOptionProduct(option)?.hasAdditionalChoices() === true
    }

    getDisplayOptionLabels(option: Option) {
        return this.getDisplayIncludedLabel(option) || this.getDisplayAdditionalOptionsLabel(option)
    }

    getOptionPriceDescription(option: Option, optionGroup: OptionGroup): string {
        if (this.amountMap[option.id] < option.amountIncludedInProductPrice) {
            return 'Free'
        }

        const selectedFreeOptionsInGroup = this.value.filter(el => el.optionGroup.id === optionGroup.id).reduce((acc, curr) => {
            acc += Math.max(0, curr.amount - curr.option.amountIncludedInProductPrice)
            return acc
        }, 0)

        if (optionGroup.freeOptionsAmount > selectedFreeOptionsInGroup) {
            return optionGroup.mode === OptionGroupMode.Single ? '' : 'Free'
        }

        const cartOption = this.activeOptionsWithinActiveGroup.find(el => {
            return el.option.id === option.id
        })

        const subItem = this.getSubItemById(cartOption?.subCartItemId)
        if (subItem !== undefined) {
            const value = subItem.getPrices(CartManager.cart.subItems).unitPrice - this.priceOffset
            if (value === 0) {
                return ''
            }
            return PriceHelper.format({
                price: value,
                signDisplay: 'exceptZero',
            })
        }

        if (option.productLink === undefined) {
            const value = option.priceChange - this.priceOffset
            if (value === 0) {
                return ''
            }
            return PriceHelper.format({
                price: value,
                signDisplay: 'exceptZero',
            })
        } else {
            const productToLink = this.getOptionProduct(option)
            if (productToLink === null) {
                console.error(`Could not find product to link for option with id ${option.id}`)
                return PriceHelper.format({
                    price: 0,
                    signDisplay: 'exceptZero',
                })
            }
            return this.getPriceDescriptionForProduct(productToLink)
        }
    }

    getPriceDescriptionForProduct(product: Product): string {
        const linkedProducts = ServerManager.shop.getLinkedProducts(product)
        const prices = product.variants.reduce<Array<number>>((acc, curr) => {
            const {item} = CartItem.fromProductAndVariantId(product, curr.id, linkedProducts)
            if (item !== null) {
                acc.push(item.getPrices(CartManager.cart.subItems).unitPrice)
            }
            return acc
        }, [])
        const minPrice = Math.min(...prices)
        const hasMultiplePrices = new Set(prices).size > 1
        const formattedMinPrice = PriceHelper.format({price: minPrice, signDisplay: 'exceptZero'})
        const description = hasMultiplePrices
            ? this.$t('customer.products.fromPrice', {price: formattedMinPrice}).toString()
            : formattedMinPrice
        const price = minPrice - this.priceOffset
        return price === 0 ? '' : description
    }

    getOptionName(option: Option) {
        const optionProduct = option.productLink?.useProductName ? this.getProductById(option.productLink?.productId, false) : null
        return optionProduct?.name.getForI18n(this.$i18n) ?? option.name.getForI18n(this.$i18n)
    }

    get hasAnOptionWithImage(): boolean {
        return this.optionGroup.options.some(el => {
            const product = this.getProductById(el.productLink?.productId ?? '', false)
            return product === null ? false : product.images.length > 0
        })
    }

    getOptionThumbnail(option: Option): string | null {
        const optionProduct = option.productLink?.useProductName === true ? this.getProductById(option.productLink!.productId, false) : null
        const image = optionProduct !== null && optionProduct.images.length ? optionProduct.images[0] : null
        if (image !== null) {
            return this.imageToThumbnailUrl(image)
        }
        return null
    }

    imageToThumbnailUrl(image: Image): string {
        const res = image.resolutions.length > 0
            ? image.getResolutionForSize(64 * (window.devicePixelRatio ?? 1), 64 * (window.devicePixelRatio ?? 1))
            : new Resolution({
                file: this.editCartItem.product.images[0].source,
                width: 0,
                height: 0,
            })
        return `url(${encodeURI(res.file.getPublicPath())})`
    }

    getOptionProduct(option: Option): Product | null {
        if (option.productLink === undefined) {
            return null
        }
        const optionProduct = this.getProductById(option.productLink!.productId, false)
        return optionProduct === null ? null : Product.formatProductForOption(optionProduct, option)
    }

    getProductById(id: string, excludeMenuProducts: boolean): Product | null {
        return PruneHelper.getProductById(ServerManager.shop, this.consumptionMode, id, excludeMenuProducts)
    }

    isOptionActive(option: Option) {
        return this.value.some(el => el.option.id === option.id)
    }

    get currentTotalOptionAmount(): number {
        return this.selectedOptionsForGroup.map(option => option.amount).reduce((a, b) => a + b, 0)
    }

    get selectedOptionsForGroup() {
        return this.value.filter(o => o.optionGroup.id === this.optionGroup.id)
    }

    get otherOptions() {
        return this.value.filter(o => o.optionGroup.id !== this.optionGroup.id)
    }

    // If multiple choice is disabled, returns the first one, or creates a new one
    get selectedOption(): Option {
        return this.selectedOptionsForGroup[0]?.option ?? this.optionGroup.options[0]
    }

    // If multiple choice is disabled, returns the first one, or creates a new one
    setOption(option: Option, subCartItemId: string | null = null, hasSingleSelectionGroup = false) {
        const options = hasSingleSelectionGroup ? [...this.value.filter(o => o.optionGroup.id !== this.optionGroup.id)] : [...this.value] // for single select drop the current selected of that optiongroup.
        const existingOption = this.value.find(el => el.option.id === option.id)
        if (existingOption === undefined) {
            this.$emit('input', [
                CartItemOption.create({
                    optionGroup: this.optionGroup,
                    option,
                    subCartItemId,
                }),
                ...options,
            ])
        } else if (existingOption.amount < (existingOption.option.maxAmount ?? Infinity)) {
            existingOption.amount++
            this.$emit('input', [
                ...options.filter(el => el.option.id != existingOption.option.id),
                existingOption,
            ])
        }
    }

    // substracts 1 from amount or removes if it had only 1 amount
    removeOption(option: Option) {
        const newOptions = this.value.map(el => {
            if (el.option.id === option.id) {
                el.amount--
            }
            return el
        }).filter(el => el.amount > 0)
        this.$emit('input', newOptions)
    }

    getOptionMaximum(option: Option): number {
        return option.maxAmount ?? Infinity
    }

    getOptionMinimum(option: Option): number {
        // use group minimum if its the only option
        if (this.optionGroup.options.length === 1) {
            return Math.max(this.optionGroup.minAmount ?? 0, option.minAmount ?? 0)
        }
        return option.minAmount ?? 0
    }

    getSubItem(option: Option): CartItem | undefined {
        const cartItemOption = this.value.find(el => el.option.id === option.id && el.subCartItemId)
        if (cartItemOption === undefined) {
            return
        }
        return this.getSubItemById(cartItemOption.subCartItemId)
    }

    hasEditableSubItem(option): boolean {
        const cartItem = this.getSubItem(option)
        return cartItem !== undefined && cartItem.product.hasAdditionalChoices()
    }

    configureSubItem($event, option) {
        if (this.optionGroup.mode === OptionGroupMode.Single || !this.hasEditableSubItem(option)) {
            return
        }
        const cartItem = this.getSubItem(option)
        if (cartItem === undefined) {
            alert('something went wrong..') // should never happen
            console.error(`failed to get linked cartitem for option with id: ${option.id}`)
            return
        }

        this.present(new ComponentWithProperties(EditCartItemOptionsView, {
            cartItem: cartItem.clone(),
            priceOffset: this.priceOffset,
            editing: true,
            alwaysSave: true,
            onSave: (newCartItem: CartItem) => {
                this.$emit('optionChanged')
                this.addSubItem(newCartItem)
            },
        }).setDisplayStyle('popup'))
    }

    get hasDefaultOptions() {
        return this.optionGroup.mode !== OptionGroupMode.Single && this.optionGroup.options.some(el => el.defaultAmount !== null && el.defaultAmount > 0)
    }
}
