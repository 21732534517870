






















import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {CartManager} from '../classes/CartManager'
import {environment} from '../environments/environment'
import DRSTBoxItem from '@dorst/components/src/DRSTBoxItem.vue'
import Checkbox from '@dorst/components/src/inputs/Checkbox.vue'
import ContextMenu from '@dorst/components/src/overlays/ContextMenu.vue'
import {switchLanguage} from '@dorst/frontend-translations'
import {Customer, Language, LanguageHelper} from '@dorst/structures'
import {Formatter} from '@dorst/validation'

@Component({
    components: {
        ContextMenu,
        DRSTBoxItem,
        Checkbox,
    },
})
export default class LanguageSwitcherContextMenu extends Mixins(NavigationMixin) {
    @Prop({default: 0})
    x!: number

    @Prop({default: 0})
    y!: number

    @Prop({default: () => environment.availableLanguages})
    enabledLanguages: Array<Language>

    isActive(lang: Language) {
        return this.$i18n.locale.substr(0, 2) === lang
    }

    setLanguage(language: Language) {
        void switchLanguage(language, true)
        if (CartManager.checkout.customer === undefined) {
            CartManager.checkout.customer = Customer.create({
                locale: language,
            })
        } else {
            this.$set(CartManager.checkout.customer, 'locale', language)
        }
    }

    getLanguageName(language: Language) {
        return Formatter.capitalizeFirstLetter(LanguageHelper.getNativeName(language, this.$i18n as any) ?? '')
    }
}
