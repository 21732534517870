import {AutoEncoder, BooleanDecoder, field, IntegerDecoder} from '@simonbackx/simple-encoding'

import {POSSystemType} from './POSSystemLink'
import {DeliverectFloor} from './deliverect/DeliverectFloor'
import {DIFloor} from './digitin/DigitinFloor'
import {LFloor} from './lightspeed/LTable'

export class TableCategorySyncedFields extends AutoEncoder {
    @field({decoder: BooleanDecoder, optional: true})
    enabled = true

    @field({decoder: BooleanDecoder, optional: true})
    name = true

    @field({decoder: BooleanDecoder, optional: true})
    number = true

    static getFieldTranslationKey(field: string) {
        // cast to get auto-suggested cases
        switch (field as Exclude<keyof TableCategorySyncedFields, keyof AutoEncoder>) {
            case 'enabled':
                return 'common.fields.tableCategories.posSync.enabled'
            case 'name':
                return 'common.fields.tableCategories.posSync.name'
            case 'number':
                return 'common.fields.tableCategories.posSync.number'
            default:
                console.error(`No translation found for field "${field}" in TableCategorySyncedFields`)
                return ''
        }
    }
}

export class TableCategoryMeta extends AutoEncoder {
    @field({decoder: TableCategorySyncedFields, optional: true})
    syncedFields = TableCategorySyncedFields.create({})

    @field({decoder: IntegerDecoder, nullable: true, optional: true})
    untill: number | null = null

    @field({decoder: LFloor, nullable: true, optional: true})
    lightspeedL: LFloor | null = null

    @field({decoder: DIFloor, nullable: true, optional: true})
    digitin: DIFloor | null = null

    @field({decoder: IntegerDecoder, nullable: true, optional: true})
    cloudpos: number | null = null

    @field({decoder: DeliverectFloor, nullable: true, optional: true})
    deliverect: DeliverectFloor | null = null

    get isExternal(): boolean {
        return this.posType !== undefined
    }

    get posType(): POSSystemType | undefined {
        if (this.digitin != null) {
            return POSSystemType.Digitin
        } else if (this.lightspeedL != null) {
            return POSSystemType.Lightspeed
        } else if (this.untill != null) {
            return POSSystemType.Untill
        } else if (this.cloudpos != null) {
            return POSSystemType.CloudPos
        } else if (this.deliverect != null) {
            return POSSystemType.Deliverect
        }
    }

    get externalId(): number | string | undefined {
        if (this.digitin != null) {
            return this.digitin?.groupID ?? undefined
        } else if (this.lightspeedL != null) {
            return this.lightspeedL.id
        } else if (this.untill != null) {
            return this.untill
        } else if (this.cloudpos != null) {
            return this.cloudpos
        } else if (this.deliverect != null) {
            return this.deliverect.id
        }
    }
}
