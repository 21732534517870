import {
    ArrayDecoder,
    BooleanDecoder,
    field,
    IntegerDecoder,
    StringDecoder,
} from '@simonbackx/simple-encoding'

import {DecodableLanguageRecord} from '../DecodableRecord'
import {Language} from '../Language'
import {DeliverectTranslatable} from './DeliverectTranslatable'

export class DeliverectProduct extends DeliverectTranslatable {
    @field({decoder: StringDecoder})
    _id: string

    @field({decoder: StringDecoder})
    parentId: string

    @field({decoder: StringDecoder})
    account: string

    @field({decoder: StringDecoder})
    location: string

    @field({decoder: IntegerDecoder})
    productType: number

    @field({decoder: StringDecoder})
    plu: string

    @field({decoder: IntegerDecoder})
    price: number

    @field({decoder: StringDecoder})
    name: string

    @field({decoder: DecodableLanguageRecord})
    nameTranslations: Partial<Record<Language, string>> = {}

    @field({decoder: StringDecoder})
    description: string

    @field({decoder: DecodableLanguageRecord})
    descriptionTranslations: Partial<Record<Language, string>> = {}

    @field({decoder: IntegerDecoder, optional: true})
    deliveryTax?: number

    @field({decoder: IntegerDecoder, optional: true})
    takeawayTax?: number

    @field({decoder: IntegerDecoder, optional: true})
    eatInTax?: number

    @field({decoder: new ArrayDecoder(StringDecoder)})
    subProducts: Array<string>

    @field({decoder: new ArrayDecoder(IntegerDecoder)})
    productTags: Array<number>

    @field({decoder: StringDecoder, optional: true})
    imageUrl: string | undefined = undefined

    @field({decoder: IntegerDecoder, optional: true})
    max: number = 0

    @field({decoder: IntegerDecoder, nullable: true})
    min: number | null = null

    @field({decoder: BooleanDecoder, optional: true})
    visible: boolean = true

    @field({decoder: BooleanDecoder, optional: true})
    snoozed: boolean = false

    /**
     * This field cannot really be used to deduce if a product is a menu-type product.
     * Analysis has revealed that regardless of this flag, a product can contain bundles and/or modifierGroups.
     */
    @field({decoder: BooleanDecoder, optional: true})
    isCombo: boolean = false

    // Possible other unused fields:
    // nameTranslations: Record<string, unknown>
    // descriptionTranslations: Record<string, unknown>
    // priceLevels: Record<string, unknown>
    // sortOrder: number
    // multiply: number
    // posProductId: string
    // posProductCategoryId: Array<string>
    // posCategoryIds: Array<string>
    // capacityUsages: Array<unknown>
    // subProductSortOrder: Array<unknown>
}
