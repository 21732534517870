import {Data, Decoder, Encodeable, EnumDecoder, ObjectData, Patchable, PatchType, StringDecoder} from '@simonbackx/simple-encoding'
import {SimpleError} from '@simonbackx/simple-errors'

import {encoding} from '../package.json'
import {I18n} from './I18n'
import {isNullOrEmpty} from '@dorst/helpers'

export enum Language {
    Dutch = 'nl',
    French = 'fr',
    English = 'en',
    Spanish = 'es',
    Portuguese = 'pt',
    German = 'de',
    Arabic = 'ar',
}

export const LanguageDecoder = new EnumDecoder(Language)

export class LanguageHelper {
    static getName(language: Language, i18n: I18n) {
        switch (language) {
            case Language.Dutch:
                return i18n.t('languages.dutch')
            case Language.French:
                return i18n.t('languages.french')
            case Language.English:
                return i18n.t('languages.english')
            case Language.Spanish:
                return i18n.t('languages.spanish')
            case Language.Portuguese:
                return i18n.t('languages.portuguese')
            case Language.German:
                return i18n.t('languages.german')
            case Language.Arabic:
                return i18n.t('languages.arabic')
            default: {
                // compile error = not all caught
                const t: never = language
                throw new Error(`Unknown language: ${t}`)
            }
        }
    }

    static getNativeName(language: Language, i18n: I18n) {
        const nativeName = nativeLanguageNames[language.substr(0, 2)]

        if (!nativeName) {
            return this.getName(language, i18n)
        }

        return nativeName.nativeName
    }

    static getLocaleByLanguage(language: Language): string {
        switch (language) {
            case Language.Dutch:
                return 'nl-be'
            case Language.French:
                return 'fr'
            case Language.English:
                return 'en-us'
            case Language.Spanish:
                return 'es'
            case Language.Portuguese:
                return 'pt'
            case Language.German:
                return 'de'
            case Language.Arabic:
                return 'ar-AE'
            default: {
                // compile error = not all caught
                const t: never = language
                throw new Error(`Unknown language: ${t}`)
            }
        }
    }
}
export class TranslatedStringPatch implements Encodeable, Patchable<TranslatedStringPatch> {
    translations: {[key in Language]?: string | null}

    constructor(translations: {[key in Language]?: string | null}) {
        this.translations = translations
    }

    patch(patch: TranslatedStringPatch): this {
        const c = new TranslatedStringPatch({...this.translations}) as this
        for (const key in patch.translations) {
            if (patch.translations.hasOwnProperty(key)) {
                const value = patch.translations[key] as string | null
                c.translations[key] = value
            }
        }
        return c
    }

    encode() {
        return this.translations
    }

}

export class TranslatedString implements Encodeable, Patchable<TranslatedStringPatch> {
    translations: {[key in Language]?: string}

    constructor(translations: {[key in Language]?: string}) {
        this.translations = translations
    }

    merge(other: TranslatedString): TranslatedString {
        return this.patch(new TranslatedStringPatch(other.translations))
    }

    /**
     * When Patching a TranslatedString, you can delete translations by setting it to null
     */
    patch(patch: TranslatedStringPatch): this {
        const c = new TranslatedString({...this.translations}) as this
        for (const key in patch.translations) {
            if (patch.translations.hasOwnProperty(key)) {
                const value = patch.translations[key] as string | null
                if (isNullOrEmpty(value)) {
                    delete c.translations[key]
                } else {
                    c.translations[key] = value
                }
            }
        }

        return c
    }

    /**
     * Create a patch that will also delete translations that are not set
     */
    createPatchSync(): PatchType<TranslatedString> {
        const patch: PatchType<TranslatedString> = new TranslatedStringPatch({})

        // Set all translations to null to delete them if needed
        for (const language of Object.values(Language)) {
            patch.translations[language] = null
        }

        for (const key in this.translations) {
            if (this.translations.hasOwnProperty(key)) {
                const value = this.translations[key] as string | undefined
                if (value !== undefined) {
                    patch.translations[key] = value
                }
            }
        }

        return patch
    }

    static create(str: string, language: Language): TranslatedString {
        return new this({[language]: str})
    }

    static createForI18n(str: string, i18n: {locale: string}): TranslatedString {
        let lang = i18n.locale.substr(0, 2)
        if (!(Object.values(Language) as Array<string>).includes(lang)) {
            lang = Language.English
        }
        return new this({[lang as Language]: str})
    }

    static createPatch(str: string | null, language: Language): PatchType<TranslatedString> {
        return new TranslatedStringPatch({[language]: str})
    }

    static createPatchI18n(str: string, i18n: {locale: string}): PatchType<TranslatedString> {
        let lang = i18n.locale.substr(0, 2)
        if (!(Object.values(Language) as Array<string>).includes(lang)) {
            lang = Language.English
        }
        return new TranslatedStringPatch({[lang as Language]: str})
    }

    getForI18n(i18n: {locale: string}) {
        let lang = i18n.locale.substr(0, 2)
        if (!(Object.values(Language) as Array<string>).includes(lang)) {
            lang = Language.English
        }
        return this.getForLanguage(lang as Language)
    }

    getForI18nStrict(i18n: {locale: string}): string | undefined {
        const lang = i18n.locale.substr(0, 2)
        return this.getForLanguageStrict(lang as Language)
    }

    /**
     * Do not return a value if it is not translated
     */
    getForLanguageStrict(language: Language): string | undefined {
        if (typeof this.translations[language] !== 'undefined' && this.translations[language]) {
            return this.translations[language] as string // weird typescript?
        }
        return undefined
    }

    getForLanguage(language: Language): string {
        if (typeof this.translations[language] !== 'undefined' && this.translations[language]) {
            return this.translations[language] as string // weird typescript?
        }

        // Search for a default value
        const keys = Object.keys(this.translations)
        if (keys.length == 0) {
            return ''
        }
        return this.translations[keys[0]]
    }

    encode() {
        return this.translations
    }

    isSameAs(otherTranslatedStr) {
        const keys1 = Object.keys(this.translations)
        const keys2 = Object.keys(otherTranslatedStr.translations)
        if (keys1.length !== keys2.length) {
            return false
        }
        for (const key of keys1) {
            if (this.translations[key] !== otherTranslatedStr.translations[key]) {
                return false
            }
        }
        return true
    }

    isEmpty() {
        const keys = Object.keys(this.translations)
        for (const key of keys) {
            if (this.translations[key] && this.translations[key] !== '') {
                return false
            }
        }
        return true
    }

    clone() {
        return TranslatedStringDecoder.decode(new ObjectData(this.encode(), {version: encoding.version}))
    }

    includesPart(query: string) {
        let includes = false
        for (const text of Object.values(this.translations)) {
            if (!text) {
                return
            }
            const parts = text?.toLowerCase().split(' ')
            if (parts?.find(part => part.startsWith(query.toLowerCase()))) {
                includes = true
            }
        }
        return includes
    }

    hasValueForAnyLanguage(query: string) {
        let equals = false
        for (const text of Object.values(this.translations)) {
            if (!text || equals) {
                return
            }
            equals = text?.toLowerCase() === query.toLowerCase()
        }
        return equals
    }
}

export class TranslatedStringPatchDecoderStatic implements Decoder<PatchType<TranslatedString>> {
    decode(data: Data): PatchType<TranslatedString> {
        // Data should be an object
        if (typeof data.value != 'object') {
            throw new SimpleError({
                code: 'invalid_field',
                message: 'Expected an object with language keys',
                field: data.currentField,
            })
        }

        const dictionary: PatchType<TranslatedString> = new TranslatedStringPatch({})
        for (const key in data.value) {
            if (data.value.hasOwnProperty(key)) {
                const language = LanguageDecoder.decode(new ObjectData(key, data.context, data.addToCurrentField(key)))
                const str = data.field(key).nullable(StringDecoder)
                dictionary.translations[language] = str
            }
        }

        return dictionary
    }

    patchType() {
        return TranslatedStringPatchDecoder
    }

    patchIdentifier(): Decoder<string | number> {
        throw new Error('Translated string can not be used inside a patchable array.')
    }
}

export const TranslatedStringPatchDecoder = new TranslatedStringPatchDecoderStatic()

export class TranslatedStringDecoderStatic implements Decoder<TranslatedString> {

    patchType() {
        return TranslatedStringPatchDecoder
    }

    patchIdentifier(): Decoder<string | number> {
        throw new Error('Translated string can not be used inside a patchable array.')
    }

    decode(data: Data): TranslatedString {
        // Data should be an object
        if (typeof data.value != 'object') {
            throw new SimpleError({
                code: 'invalid_field',
                message: 'Expected an object with language keys',
                field: data.currentField,
            })
        }

        const dictionary: {[key in Language]?: string} = {}
        for (const key in data.value) {
            if (data.value.hasOwnProperty(key)) {
                const language = LanguageDecoder.decode(new ObjectData(key, data.context, data.addToCurrentField(key)))
                const str = data.field(key).string
                dictionary[language] = str
            }
        }

        return new TranslatedString(dictionary)
    }
}

export const TranslatedStringDecoder = new TranslatedStringDecoderStatic()

interface NativeLanguageObject {
    name: string
    nativeName: string
    isRTL?: boolean
}
type NativeLanguageNames = Record<string, NativeLanguageObject>

/**
 * @author Phil Teare
 * using wikipedia data
 */
const nativeLanguageNames: NativeLanguageNames = {
    ab: {
        name: 'Abkhaz',
        nativeName: 'аҧсуа',
    },
    aa: {
        name: 'Afar',
        nativeName: 'Afaraf',
    },
    af: {
        name: 'Afrikaans',
        nativeName: 'Afrikaans',
    },
    ak: {
        name: 'Akan',
        nativeName: 'Akan',
    },
    sq: {
        name: 'Albanian',
        nativeName: 'Shqip',
    },
    am: {
        name: 'Amharic',
        nativeName: 'አማርኛ',
    },
    ar: {
        name: 'Arabic',
        nativeName: 'العربية',
        isRTL: true,
    },
    an: {
        name: 'Aragonese',
        nativeName: 'Aragonés',
    },
    hy: {
        name: 'Armenian',
        nativeName: 'Հայերեն',
    },
    as: {
        name: 'Assamese',
        nativeName: 'অসমীয়া',
    },
    av: {
        name: 'Avaric',
        nativeName: 'авар мацӀ',
    },
    ae: {
        name: 'Avestan',
        nativeName: 'avesta',
    },
    ay: {
        name: 'Aymara',
        nativeName: 'aymar aru',
    },
    az: {
        name: 'Azerbaijani',
        nativeName: 'azərbaycan dili',
    },
    bm: {
        name: 'Bambara',
        nativeName: 'bamanankan',
    },
    ba: {
        name: 'Bashkir',
        nativeName: 'башҡорт теле',
    },
    eu: {
        name: 'Basque',
        nativeName: 'euskara',
    },
    be: {
        name: 'Belarusian',
        nativeName: 'Беларуская',
    },
    bn: {
        name: 'Bengali',
        nativeName: 'বাংলা',
    },
    bh: {
        name: 'Bihari',
        nativeName: 'भोजपुरी',
    },
    bi: {
        name: 'Bislama',
        nativeName: 'Bislama',
    },
    bs: {
        name: 'Bosnian',
        nativeName: 'bosanski jezik',
    },
    br: {
        name: 'Breton',
        nativeName: 'brezhoneg',
    },
    bg: {
        name: 'Bulgarian',
        nativeName: 'български език',
    },
    my: {
        name: 'Burmese',
        nativeName: 'ဗမာစာ',
    },
    ca: {
        name: 'Catalan',
        nativeName: 'Català',
    },
    ch: {
        name: 'Chamorro',
        nativeName: 'Chamoru',
    },
    ce: {
        name: 'Chechen',
        nativeName: 'нохчийн мотт',
    },
    ny: {
        name: 'Chichewa',
        nativeName: 'chiCheŵa',
    },
    zh: {
        name: 'Chinese',
        nativeName: '中文',
    },
    cv: {
        name: 'Chuvash',
        nativeName: 'чӑваш чӗлхи',
    },
    kw: {
        name: 'Cornish',
        nativeName: 'Kernewek',
    },
    co: {
        name: 'Corsican',
        nativeName: 'corsu',
    },
    cr: {
        name: 'Cree',
        nativeName: 'ᓀᐦᐃᔭᐍᐏᐣ',
    },
    hr: {
        name: 'Croatian',
        nativeName: 'hrvatski',
    },
    cs: {
        name: 'Czech',
        nativeName: 'česky',
    },
    da: {
        name: 'Danish',
        nativeName: 'dansk',
    },
    dv: {
        name: 'Dhivehi',
        nativeName: 'Maldivian',
    },
    nl: {
        name: 'Dutch',
        nativeName: 'Nederlands',
    },
    en: {
        name: 'English',
        nativeName: 'English',
    },
    eo: {
        name: 'Esperanto',
        nativeName: 'Esperanto',
    },
    et: {
        name: 'Estonian',
        nativeName: 'eesti',
    },
    ee: {
        name: 'Ewe',
        nativeName: 'Eʋegbe',
    },
    fo: {
        name: 'Faroese',
        nativeName: 'føroyskt',
    },
    fj: {
        name: 'Fijian',
        nativeName: 'vosa Vakaviti',
    },
    fi: {
        name: 'Finnish',
        nativeName: 'suomi',
    },
    fr: {
        name: 'French',
        nativeName: 'français',
    },
    ff: {
        name: 'Fula',
        nativeName: 'Fulfulde',
    },
    gl: {
        name: 'Galician',
        nativeName: 'Galego',
    },
    ka: {
        name: 'Georgian',
        nativeName: 'ქართული',
    },
    de: {
        name: 'German',
        nativeName: 'Deutsch',
    },
    el: {
        name: 'Greek',
        nativeName: 'Ελληνικά',
    },
    gn: {
        name: 'Guaraní',
        nativeName: 'Avañeẽ',
    },
    gu: {
        name: 'Gujarati',
        nativeName: 'ગુજરાતી',
    },
    ht: {
        name: 'Haitian',
        nativeName: 'Kreyòl ayisyen',
    },
    ha: {
        name: 'Hausa',
        nativeName: 'هَوُسَ',
    },
    he: {
        name: 'Hebrew',
        nativeName: 'עברית',
        isRTL: true
        ,
    },
    hz: {
        name: 'Herero',
        nativeName: 'Otjiherero',
    },
    hi: {
        name: 'Hindi',
        nativeName: 'हिन्दी, हिंदी',
    },
    ho: {
        name: 'Hiri Motu',
        nativeName: 'Hiri Motu',
    },
    hu: {
        name: 'Hungarian',
        nativeName: 'Magyar',
    },
    ia: {
        name: 'Interlingua',
        nativeName: 'Interlingua',
    },
    id: {
        name: 'Indonesian',
        nativeName: 'Bahasa Indonesia',
    },
    ie: {
        name: 'Interlingue',
        nativeName: 'Interlingue',
    },
    ga: {
        name: 'Irish',
        nativeName: 'Gaeilge',
    },
    ig: {
        name: 'Igbo',
        nativeName: 'Asụsụ Igbo',
    },
    ik: {
        name: 'Inupiaq',
        nativeName: 'Iñupiaq',
    },
    io: {
        name: 'Ido',
        nativeName: 'Ido',
    },
    is: {
        name: 'Icelandic',
        nativeName: 'Íslenska',
    },
    it: {
        name: 'Italian',
        nativeName: 'Italiano',
    },
    iu: {
        name: 'Inuktitut',
        nativeName: 'ᐃᓄᒃᑎᑐᑦ',
    },
    ja: {
        name: 'Japanese',
        nativeName: '日本語',
    },
    jv: {
        name: 'Javanese',
        nativeName: 'basa Jawa',
    },
    kl: {
        name: 'Kalaallisut',
        nativeName: 'kalaallisut',
    },
    kn: {
        name: 'Kannada',
        nativeName: 'ಕನ್ನಡ',
    },
    kr: {
        name: 'Kanuri',
        nativeName: 'Kanuri',
    },
    ks: {
        name: 'Kashmiri',
        nativeName: ' كشميري‎',
        isRTL: true,
    },
    kk: {
        name: 'Kazakh',
        nativeName: 'Қазақ тілі',
    },
    km: {
        name: 'Khmer',
        nativeName: 'ភាសាខ្មែរ',
    },
    ki: {
        name: 'Kikuyu',
        nativeName: 'Gĩkũyũ',
    },
    rw: {
        name: 'Kinyarwanda',
        nativeName: 'Ikinyarwanda',
    },
    ky: {
        name: 'Kirghiz',
        nativeName: 'кыргыз тили',
    },
    kv: {
        name: 'Komi',
        nativeName: 'коми кыв',
    },
    kg: {
        name: 'Kongo',
        nativeName: 'KiKongo',
    },
    ko: {
        name: 'Korean',
        nativeName: '한국어',
    },
    ku: {
        name: 'Kurdish',
        nativeName: 'كوردی‎',
        isRTL: true,
    },
    kj: {
        name: 'Kwanyama',
        nativeName: 'Kuanyama',
    },
    la: {
        name: 'Latin',
        nativeName: 'latine',
    },
    lb: {
        name: 'Luxembourgish',
        nativeName: 'Lëtzebuergesch',
    },
    lg: {
        name: 'Luganda',
        nativeName: 'Luganda',
    },
    li: {
        name: 'Limburgish',
        nativeName: 'Limburgs',
    },
    ln: {
        name: 'Lingala',
        nativeName: 'Lingála',
    },
    lo: {
        name: 'Lao',
        nativeName: 'ພາສາລາວ',
    },
    lt: {
        name: 'Lithuanian',
        nativeName: 'lietuvių kalba',
    },
    lu: {
        name: 'Luba-Katanga',
        nativeName: 'Luba',
    },
    lv: {
        name: 'Latvian',
        nativeName: 'latviešu valoda',
    },
    gv: {
        name: 'Manx',
        nativeName: 'Gaelg',
    },
    mk: {
        name: 'Macedonian',
        nativeName: 'македонски јазик',
    },
    mg: {
        name: 'Malagasy',
        nativeName: 'Malagasy fiteny',
    },
    ms: {
        name: 'Malay',
        nativeName: 'بهاس ملايو‎',
        isRTL: true,
    },
    ml: {
        name: 'Malayalam',
        nativeName: 'മലയാളം',
    },
    mt: {
        name: 'Maltese',
        nativeName: 'Malti',
    },
    mi: {
        name: 'Māori',
        nativeName: 'te reo Māori',
    },
    mr: {
        name: 'Marathi',
        nativeName: 'मराठी',
    },
    mh: {
        name: 'Marshallese',
        nativeName: 'Kajin M̧ajeļ',
    },
    mn: {
        name: 'Mongolian',
        nativeName: 'монгол',
    },
    na: {
        name: 'Nauru',
        nativeName: 'Ekakairũ Naoero',
    },
    nv: {
        name: 'Navajo',
        nativeName: 'Dinékʼehǰí',
    },
    nb: {
        name: 'Norwegian Bokmål',
        nativeName: 'Norsk bokmål',
    },
    nd: {
        name: 'North Ndebele',
        nativeName: 'isiNdebele',
    },
    ne: {
        name: 'Nepali',
        nativeName: 'नेपाली',
    },
    ng: {
        name: 'Ndonga',
        nativeName: 'Owambo',
    },
    nn: {
        name: 'Norwegian Nynorsk',
        nativeName: 'Norsk nynorsk',
    },
    no: {
        name: 'Norwegian',
        nativeName: 'Norsk',
    },
    ii: {
        name: 'Nuosu',
        nativeName: 'ꆈꌠ꒿ Nuosuhxop',
    },
    nr: {
        name: 'South Ndebele',
        nativeName: 'isiNdebele',
    },
    oc: {
        name: 'Occitan',
        nativeName: 'Occitan',
    },
    oj: {
        name: 'Ojibwe, Ojibwa',
        nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ',
    },
    cu: {
        name: 'Church Slavic',
        nativeName: 'ѩзыкъ словѣньскъ',
    },
    om: {
        name: 'Oromo',
        nativeName: 'Afaan Oromoo',
    },
    or: {
        name: 'Oriya',
        nativeName: 'ଓଡ଼ିଆ',
    },
    os: {
        name: 'Ossetian',
        nativeName: 'ирон æвзаг',
    },
    pa: {
        name: 'Panjabi',
        nativeName: 'پنجابی‎',
        isRTL: true,
    },
    pi: {
        name: 'Pāli',
        nativeName: 'पाऴि',
    },
    fa: {
        name: 'Persian',
        nativeName: 'فارسی',
        isRTL: true,
    },
    pl: {
        name: 'Polish',
        nativeName: 'polski',
    },
    ps: {
        name: 'Pashto',
        nativeName: 'پښتو',
    },
    pt: {
        name: 'Portuguese',
        nativeName: 'Português',
    },
    qu: {
        name: 'Quechua',
        nativeName: 'Kichwa',
    },
    rm: {
        name: 'Romansh',
        nativeName: 'rumantsch',
    },
    rn: {
        name: 'Kirundi',
        nativeName: 'kiRundi',
    },
    ro: {
        name: 'Romanian',
        nativeName: 'română',
    },
    ru: {
        name: 'Russian',
        nativeName: 'русский язык',
    },
    sa: {
        name: 'Sanskrit',
        nativeName: 'संस्कृतम्',
    },
    sc: {
        name: 'Sardinian',
        nativeName: 'sardu',
    },
    sd: {
        name: 'Sindhi',
        nativeName: ' سنڌي، سندھی‎',
        isRTL: true,
    },
    se: {
        name: 'Northern Sami',
        nativeName: 'Davvisámegiella',
    },
    sm: {
        name: 'Samoan',
        nativeName: 'gagana faa Samoa',
    },
    sg: {
        name: 'Sango',
        nativeName: 'yângâ tî sängö',
    },
    sr: {
        name: 'Serbian',
        nativeName: 'српски језик',
    },
    gd: {
        name: 'Scottish Gaelic; Gaelic',
        nativeName: 'Gàidhlig',
    },
    sn: {
        name: 'Shona',
        nativeName: 'chiShona',
    },
    si: {
        name: 'Sinhala, Sinhalese',
        nativeName: 'සිංහල',
    },
    sk: {
        name: 'Slovak',
        nativeName: 'slovenčina',
    },
    sl: {
        name: 'Slovene',
        nativeName: 'slovenščina',
    },
    so: {
        name: 'Somali',
        nativeName: 'Soomaaliga',
    },
    st: {
        name: 'Southern Sotho',
        nativeName: 'Sesotho',
    },
    es: {
        name: 'Spanish',
        nativeName: 'español',
    },
    su: {
        name: 'Sundanese',
        nativeName: 'Basa Sunda',
    },
    sw: {
        name: 'Swahili',
        nativeName: 'Kiswahili',
    },
    ss: {
        name: 'Swati',
        nativeName: 'SiSwati',
    },
    sv: {
        name: 'Swedish',
        nativeName: 'svenska',
    },
    ta: {
        name: 'Tamil',
        nativeName: 'தமிழ்',
    },
    te: {
        name: 'Telugu',
        nativeName: 'తెలుగు',
    },
    tg: {
        name: 'Tajik',
        nativeName: 'تاجیکی‎',
        isRTL: true,
    },
    th: {
        name: 'Thai',
        nativeName: 'ไทย',
    },
    ti: {
        name: 'Tigrinya',
        nativeName: 'ትግርኛ',
    },
    bo: {
        name: 'Tibetan',
        nativeName: 'བོད་ཡིག',
    },
    tk: {
        name: 'Turkmen',
        nativeName: 'Türkmen, Түркмен',
    },
    tl: {
        name: 'Tagalog',
        nativeName: 'ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔',
    },
    tn: {
        name: 'Tswana',
        nativeName: 'Setswana',
    },
    to: {
        name: 'Tonga',
        nativeName: 'faka Tonga',
    },
    tr: {
        name: 'Turkish',
        nativeName: 'Türkçe',
    },
    ts: {
        name: 'Tsonga',
        nativeName: 'Xitsonga',
    },
    tt: {
        name: 'Tatar',
        nativeName: 'تاتارچا‎',
        isRTL: true,

    },
    tw: {
        name: 'Twi',
        nativeName: 'Twi',
    },
    ty: {
        name: 'Tahitian',
        nativeName: 'Reo Tahiti',
    },
    ug: {
        name: 'Uighur',
        nativeName: 'ئۇيغۇرچە‎',
    },
    uk: {
        name: 'Ukrainian',
        nativeName: 'українська',
    },
    ur: {
        name: 'Urdu',
        nativeName: 'اردو',
        isRTL: true,
    },
    uz: {
        name: 'Uzbek',
        nativeName: 'أۇزبېك‎',
        isRTL: true,
    },
    ve: {
        name: 'Venda',
        nativeName: 'Tshivenḓa',
    },
    vi: {
        name: 'Vietnamese',
        nativeName: 'Tiếng Việt',
    },
    vo: {
        name: 'Volapük',
        nativeName: 'Volapük',
    },
    wa: {
        name: 'Walloon',
        nativeName: 'Walon',
    },
    cy: {
        name: 'Welsh',
        nativeName: 'Cymraeg',
    },
    wo: {
        name: 'Wolof',
        nativeName: 'Wollof',
    },
    fy: {
        name: 'Western Frisian',
        nativeName: 'Frysk',
    },
    xh: {
        name: 'Xhosa',
        nativeName: 'isiXhosa',
    },
    yi: {
        name: 'Yiddish',
        nativeName: 'ייִדיש',
        isRTL: true,
    },
    yo: {
        name: 'Yoruba',
        nativeName: 'Yorùbá',
    },
    za: {
        name: 'Zhuang',
        nativeName: 'Saɯ cueŋƅ',
    },
}
