import {ArrayDecoder, AutoEncoder, BooleanDecoder, DateDecoder, EnumDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {ConsumptionModeAvailabilityPeriod} from './ConsumptionModeAvailabilityPeriod'
import {WeekDay} from './WeekDay'

export class ConsumptionModeAvailabilityDay extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: new EnumDecoder(WeekDay), nullable: true, optional: true, defaultValue: () => null})
    day: WeekDay | null

    @field({decoder: DateDecoder, nullable: true, optional: true, defaultValue: () => null})
    date: Date | null

    @field({decoder: BooleanDecoder, optional: true, defaultValue: () => false})
    alwaysVisible: boolean

    @field({
        decoder: new ArrayDecoder(ConsumptionModeAvailabilityPeriod), defaultValue: function (this: ConsumptionModeAvailabilityDay) {
            const options = this.day ? {day: this.day} : {}
            return [ConsumptionModeAvailabilityPeriod.create({preparationTime: ConsumptionModeAvailabilityPeriod.defaultPrepTime(options)})]
        },
    })
    periods: Array<ConsumptionModeAvailabilityPeriod>

    get isClosed(): boolean {
        return this.periods == null || this.periods.length == 0
    }

    hasValidDate(): boolean {
        return this.date != null || this.day != null
    }

    hasValidPeriods(): boolean {
        return this.periods.every(p => p.isValid(this.periods))
    }

    isValid(): boolean {
        return this.hasValidDate() && this.hasValidPeriods()
    }
}

export const ConsumptionModeAvailabilityDayPatch = ConsumptionModeAvailabilityDay.patchType()
