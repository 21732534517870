var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DRSTBox",
    {
      staticClass: "address-book-box",
      attrs: {
        title: _vm.title ? _vm.title : _vm.$t("customer.details.addressBook")
      }
    },
    [
      _vm._l(_vm.enrichedEntries, function(entry) {
        return _c(
          "DRSTBoxItem",
          {
            directives: [
              {
                name: "longpress",
                rawName: "v-longpress",
                value: function(e) {
                  return _vm.rightClickMenu(e, entry.id)
                },
                expression: "(e) => rightClickMenu(e, entry.id)"
              }
            ],
            key: entry.id,
            staticClass: "right-stack address-item",
            attrs: { selectable: true, "element-name": "label" },
            nativeOn: {
              contextmenu: function($event) {
                $event.preventDefault()
                return (function(e) {
                  return _vm.rightClickMenu(e, entry.id)
                })($event)
              }
            }
          },
          [
            _c("span", [
              _vm._v(
                "\n            " +
                  _vm._s(entry.address.multilineString) +
                  "\n        "
              )
            ]),
            _vm._v(" "),
            _c(
              "template",
              { slot: "right" },
              [
                entry.location
                  ? _c("RemarkBlock", [
                      _c("p", [
                        entry.location.price
                          ? _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("price")(entry.location.price))
                              )
                            ])
                          : _c("span", [
                              _vm._v(_vm._s(_vm.$t("general.priceFree")))
                            ]),
                        _vm._v(" "),
                        entry.location.minOrderAmount
                          ? _c("span", [
                              _vm._v(
                                "(" +
                                  _vm._s(_vm.$t("general.min")) +
                                  " " +
                                  _vm._s(
                                    _vm._f("price")(
                                      entry.location.minOrderAmount
                                    )
                                  ) +
                                  ")"
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("span", { staticClass: "asterisk" }, [_vm._v("*")])
                      ])
                    ])
                  : _c("RemarkBlock", { attrs: { type: "error" } }, [
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            entry.errorMessage === undefined
                              ? _vm.$t(
                                  "customer.details.errors.noDeliveryToAddressShort"
                                )
                              : entry.errorMessage
                          )
                        )
                      ])
                    ]),
                _vm._v(" "),
                _c("Radio", {
                  attrs: {
                    "model-value": _vm.selectedEntry,
                    value: entry.id,
                    name: "delivery-address"
                  },
                  on: {
                    change: function($event) {
                      return _vm.selectEntry(entry)
                    }
                  }
                })
              ],
              1
            )
          ],
          2
        )
      }),
      _vm._v(" "),
      _c(
        "DRSTBoxItem",
        {
          staticClass: "right-stack",
          attrs: { selectable: true },
          on: { click: _vm.addAddress }
        },
        [
          _c("div", [
            _vm._v(
              "\n            " +
                _vm._s(_vm.$t("customer.details.addAddress")) +
                "\n        "
            )
          ]),
          _vm._v(" "),
          _c("template", { slot: "right" }, [
            _c("button", { staticClass: "button icon plus" })
          ])
        ],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }