import {Decoder} from '@simonbackx/simple-encoding'

import {ServerManager} from '../classes/ServerManager'
import {
    OrderConsumer,
    POSSystemOrderValidationStatus,
    POSSystemOrderValidationStatusWrapper,
} from '@dorst/structures'

export class POSSystemActions {
    static async PosOrderValidationStatus(
        order: OrderConsumer,
    ): Promise<POSSystemOrderValidationStatus> {
        return new Promise((resolve, reject) => {
            ServerManager.getServer()
                .request({
                    method: 'GET',
                    path: `/backoffice/shops/${ServerManager.shop.id}/order/posvalidation/${order.uuid}`,
                    decoder: POSSystemOrderValidationStatusWrapper as Decoder<POSSystemOrderValidationStatusWrapper>,
                })
                .then(({data: {status}}) => resolve(status))
                .catch(reject)
        })
    }
}
