import {
    ArrayDecoder,
    AutoEncoder,
    DateDecoder,
    EnumDecoder,
    field,
    StringDecoder,
} from '@simonbackx/simple-encoding'

import {ConsumptionOptionType} from './ConsumptionOptions'

export class GetTimeslotsRequest extends AutoEncoder {
    /**
     * The date to find timeslots for.
     * Leaving this empty will find slots for "today" or the closest day with available slots.
     * Filling it in will return you slots for that specific day, even if there are none.
     */
    @field({decoder: DateDecoder, optional: true})
    date?: Date

    @field({decoder: new ArrayDecoder(StringDecoder)})
    productIds: Array<string>

    @field({decoder: new EnumDecoder(ConsumptionOptionType)})
    consumptionOptionType: ConsumptionOptionType
}
