var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "spinner-button", class: { loading: _vm.loading } },
    [
      _c("div", [_vm._t("default")], 2),
      _vm._v(" "),
      _c("div", [_c("Spinner")], 1)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }