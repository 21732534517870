/**
 * ISO 4217 Currency codes supported by Dorst products.
 */
export enum Currency {
    EUR = 'EUR',
    GBP = 'GBP',
    AED = 'AED',
    INR = 'INR',
}

export type CurrencyType =
    | 'AED'
    | 'EUR'
    | 'GBP'
    | 'INR'

/**
 * Unexecuted function that prevents Currency type and enum from deviating.
 */
function keepEnumsInSync() {
    const errorsIfEnumIsMissingValues: `${Currency}` = '' as CurrencyType
    const errorsIfTypeIsMissingValues: CurrencyType = '' as `${Currency}`
}

export class CurrencyHelper {
    static getSign(currency: Currency): string {
        switch (currency) {
            case Currency.EUR:
                return '€'
            case Currency.GBP:
                return '£'
            case Currency.AED:
                return 'AED'
            case Currency.INR:
                return '₹'
            default: {
                const c: never = currency
                throw new Error(`Unknown currency: ${c}`)
            }
        }
    }

    static getSuffix(currency: Currency): string {
        switch (currency) {
            case Currency.EUR:
                return 'euro'
            case Currency.GBP:
                return 'GBP'
            case Currency.AED:
                return 'AED'
            case Currency.INR:
                return 'INR'
            default: {
                const c: never = currency
                throw new Error(`Unknown currency: ${c}`)
            }
        }
    }

    // ISO 4217
    static getCode(currency: Currency): number {
        switch (currency) {
            case Currency.EUR: return 978
            case Currency.GBP: return 826
            case Currency.AED: return 784
            case Currency.INR: return 356
            default: {
                const c: never = currency
                throw new Error(`Unknown currency: ${c}`)
            }
        }
    }

    static getISO4217Code(currency: Currency): string {
        switch (currency) {
            case Currency.EUR:
                return 'EUR'
            case Currency.GBP:
                return 'GBP'
            case Currency.AED:
                return 'AED'
            case Currency.INR:
                return 'INR'
            default: {
                const c: never = currency
                throw new Error(`Unknown currency: ${c}`)
            }
        }
    }
}
