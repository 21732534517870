var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "drst-thumbnail", class: { selectable: _vm.selectable } },
    [
      _c("div", { staticClass: "thumb-img" }, [
        _c("img", {
          style: { "object-fit": _vm.imageFit },
          attrs: { src: _vm.imageUrl, alt: _vm.title }
        })
      ]),
      _vm._v(" "),
      _vm.title
        ? _c(
            "div",
            {
              staticClass: "thumb-content",
              class: { "no-description": !_vm.translatedDescription.length }
            },
            [
              _c("h2", [
                _vm._v("\n            " + _vm._s(_vm.title) + "\n        ")
              ]),
              _vm._v(" "),
              !!_vm.translatedDescription.length
                ? _c("p", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.translatedDescription) +
                        "\n        "
                    )
                  ])
                : _vm._e()
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }