import {ArrayDecoder, BooleanDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {Language, TranslatedString} from './Language'
import {LegacyProductBackoffice} from './LegacyProduct'
import {Option} from './Option'
import {OptionGroup} from './OptionGroup'
import {Product, ProductVariant} from './Product'
import {ProductMeta} from './ProductMeta'
import {ProductPrice} from './ProductPrice'
import {UpsellGroup} from './Upsell'

export class ProductBackoffice extends Product {
    @field({decoder: StringDecoder, nullable: true, upgrade: () => null, version: 12})
    templateId: string | null = null

    @field({decoder: StringDecoder, nullable: true, upgrade: () => null, version: 50})
    templateVariantId: string | null = null

    @field({decoder: BooleanDecoder, version: 12})
    useTemplateName = false

    @field({decoder: BooleanDecoder, version: 12})
    useTemplateDescription = false

    @field({decoder: BooleanDecoder, version: 12})
    useTemplateImages = false

    @field({
        decoder: BooleanDecoder, version: 13, upgrade: function (this: ProductBackoffice) {
            if (this.templateId) {
                return true
            }
            return false
        },
    })
    useTemplateVAT = false

    @field({decoder: ProductMeta, version: 36, defaultValue: () => ProductMeta.create({})})
    meta: ProductMeta

    @field({decoder: new ArrayDecoder(UpsellGroup), optional: true, version: 73, defaultValue: () => []})
    upsellGroups: Array<UpsellGroup>

    static fromLegacy(product: LegacyProductBackoffice): ProductBackoffice {
        return ProductBackoffice.create({
            ...product,
            variants: product.prices.map(price => {
                return ProductVariant.create({
                    id: price.id,
                    price: price.price,
                    optionGroups: product.optionGroups.map(og => {
                        og.options = og.options.map(el => {
                            return Option.create({...el, id: uuidv4()})
                        })
                        return OptionGroup.create({...og, id: uuidv4()})
                    }),
                    default: true,
                    enabled: true,
                    name: new TranslatedString(price.name ? {[Language.Dutch]: price.name} : {}),
                })
            }),
        })
    }

    static toLegacy(product: ProductBackoffice): LegacyProductBackoffice {
        return LegacyProductBackoffice.create({
            ...product,
            optionGroups: product.variants[0].optionGroups,
            prices: product.variants.map(el => {
                return ProductPrice.create({
                    id: el.id,
                    price: el.price,
                    name: el.name.getForLanguage(Language.Dutch),
                })
            }),
        })
    }
}

export const ProductBackofficePatch = ProductBackoffice.patchType()
