var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view shop-view" },
    [
      _c("DRSTNavigationBar", { attrs: { title: _vm.shop.name } }, [
        _vm.hasPassword
          ? _c(
              "span",
              {
                staticClass: "order-as-waiter",
                attrs: { slot: "left" },
                slot: "left"
              },
              [
                _vm._v("\n            Ober\n            "),
                _c("button", {
                  staticClass: "button icon trash",
                  attrs: { type: "button" },
                  on: { click: _vm.removePassword }
                })
              ]
            )
          : _vm.canPop
          ? _c("button", {
              staticClass: "button icon back",
              attrs: { slot: "left" },
              on: { click: _vm.pop },
              slot: "left"
            })
          : _vm.hasRedirect
          ? _c("button", {
              staticClass: "button icon back",
              attrs: { slot: "left" },
              on: { click: _vm.redirectBack },
              slot: "left"
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nav-right", attrs: { slot: "right" }, slot: "right" },
          [
            _c("LanguageSwitcher"),
            _vm._v(" "),
            _c("button", {
              staticClass: "button icon search",
              on: { click: _vm.goToSearch }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.logo
        ? _c("div", {
            staticClass: "shop-logo",
            style: { backgroundImage: "url(" + _vm.logo + ")" }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "main",
        [
          !_vm.logoIncludesShopName
            ? _c("h1", [
                _vm._v("\n            " + _vm._s(_vm.shop.name) + "\n        ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showConsumptionModeSelector
            ? _c(
                "DRSTBox",
                {
                  staticClass: "mode-selector",
                  nativeOn: {
                    click: function($event) {
                      return _vm.selectConsumptionMode($event)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.getModeName(_vm.consumptionMode)) +
                      "\n            "
                  ),
                  _vm.enabledConsumptionModes.length > 1
                    ? _c("button", {
                        staticClass: "button icon arrow-down-simple"
                      })
                    : _vm._e()
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.scoverLink
            ? _c(
                "DRSTBox",
                {
                  nativeOn: {
                    click: function($event) {
                      return _vm.openExternalUrl(_vm.scoverLink)
                    }
                  }
                },
                [
                  _c("DRSTBoxItem", { attrs: { selectable: true } }, [
                    _c("p", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("customer.scoverText")) +
                          "\n                "
                      )
                    ]),
                    _vm._v(" "),
                    _c("button", {
                      staticClass: "button icon disclosure",
                      attrs: { slot: "right" },
                      slot: "right"
                    })
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.shopMessageTitle === undefined &&
          _vm.shopMessageText !== undefined
            ? _c("p", {
                staticClass: "shop-message-text single",
                domProps: { textContent: _vm._s(_vm.shopMessageText) }
              })
            : _vm.shopMessageTitle !== undefined ||
              _vm.shopMessageText !== undefined
            ? _c(
                "DRSTBox",
                { attrs: { title: _vm.shopMessageTitle } },
                [
                  _c("template", { slot: "right" }, [
                    _c("span", { staticClass: "icon info" })
                  ]),
                  _vm._v(" "),
                  _vm.shopMessageText !== undefined
                    ? _c("DRSTBoxItem", [
                        _c("p", {
                          staticClass: "shop-message-text",
                          domProps: { textContent: _vm._s(_vm.shopMessageText) }
                        })
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.shop.enableOrdering && !_vm.hideOrderButtons
            ? _c(
                "DRSTBox",
                { staticClass: "no-ordering-warning" },
                [
                  _c("DRSTBoxItem", [
                    _c("img", {
                      attrs: {
                        src: require("@dorst/assets/icons/color/warning.svg")
                      }
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm.$t("consumer.warnings.orderingNotPossible")
                        ) +
                        "\n            "
                    )
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showSegmentedCategoriesControl
            ? _c("SegmentedControl", {
                key: _vm.consumptionMode,
                attrs: {
                  items: _vm.shopCategories,
                  labels: _vm.categoriesLabels
                },
                model: {
                  value: _vm.selectedCategory,
                  callback: function($$v) {
                    _vm.selectedCategory = $$v
                  },
                  expression: "selectedCategory"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("OfferCard"),
          _vm._v(" "),
          _vm.selectedCategory !== null &&
          !_vm.selectedCategory.description.isEmpty()
            ? _c("p", {
                staticClass: "category-description",
                domProps: {
                  textContent: _vm._s(
                    _vm.selectedCategory.description.getForI18n(_vm.$i18n)
                  )
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedCategories.length > 0
            ? _c(
                "DRSTList",
                _vm._l(_vm.selectedCategories, function(category) {
                  return _c(
                    "DRSTBoxItem",
                    {
                      key: category.id,
                      attrs: { selectable: true },
                      on: {
                        click: function($event) {
                          return _vm.goToCategory(category)
                        }
                      }
                    },
                    [
                      _c("p", { staticClass: "style-row-title-1" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.getCategoryName(category)) +
                            "\n                "
                        )
                      ]),
                      _vm._v(" "),
                      _c("button", {
                        staticClass: "button icon disclosure",
                        attrs: { slot: "right" },
                        slot: "right"
                      })
                    ]
                  )
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedProducts.length > 0
            ? _c(
                "DRSTBox",
                _vm._l(_vm.selectedProducts, function(product) {
                  return _c("ProductBoxItem", {
                    key: product.id,
                    attrs: { product: product },
                    on: {
                      click: function($event) {
                        return _vm.goToProduct(product)
                      }
                    }
                  })
                }),
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.CartManager.cart.items.length > 0 &&
      _vm.shop.enableOrdering &&
      _vm.CartManager.orderingAllowedForCustomerType
        ? _c("DRSTFloatingFooter", [
            _c(
              "button",
              {
                staticClass: "button primary fill columns",
                on: { click: _vm.next }
              },
              [
                _c("span", { staticClass: "icon-shadow" }, [
                  _c("span", { staticClass: "icon cart" })
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.$t("customer.products.openCart")))
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "counter" }, [
                  _vm._v(_vm._s(_vm.CartManager.cart.getCount()))
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.hasExternalLinksEnabled
        ? _c("DRSTExternalLinksFooter", {
            attrs: { links: _vm.shop.externalLinks }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("img", {
        staticStyle: { opacity: "0" },
        attrs: {
          src: "https://assets.orderbilly.com/dorst/dorst?name=" + _vm.shop.name
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }