






import {Component, Prop, Vue} from 'vue-property-decorator'

@Component
export default class DRSTFooterBox extends Vue {
    @Prop({default: '', type: String})
    title!: string
}
