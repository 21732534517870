



























































































































import {Component, Mixins} from 'vue-property-decorator'

import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import {PriceHelper} from '../helpers/PriceHelper'
import {CheckoutMixin} from '../mixins/CheckoutMixin'
import AddressBookBox from './AddressBookBox.vue'
import {
    DRSTBox,
    DRSTFloatingFooter,
    DRSTNavigationBar,
    RemarkBlock,
    TelephoneRow,
    TextInputRow,
} from '@dorst/components'
import {i18n} from '@dorst/frontend-translations'
import {isNullOrEmpty} from '@dorst/helpers'
import {
    Address,
    ConsumptionOptionType,
    Customer,
    DeliveryLocation,
    DeliveryLocationHelper,
    DeliveryLocationType,
    I18n,
} from '@dorst/structures'

interface DeliveryDescription {
    expandable: boolean
    feeAndMinAmount: string
    id: string
    rangeItems: Array<string>
    trimmedRangeItems: Array<string>
}

@Component({
    components: {
        AddressBookBox,
        DRSTBox,
        RemarkBlock,
        DRSTFloatingFooter,
        DRSTNavigationBar,
        TelephoneRow,
        TextInputRow,
    },
})
export default class UserDetailsView extends Mixins(CheckoutMixin) {
    name = ''
    firstName = ''
    lastName = ''
    email = ''
    editPhone = ''
    address: Address | null = null
    location: DeliveryLocation | null = null

    checkout = CartManager.checkout

    error: string | null = null

    /**
     * @type {Array<string>} - Array of DeliveryLocation ids that are expanded
     */
    expandedDeliveryDescriptions: Array<string> = []

    mounted() {
        this.initValues() // Try to read from localstorage
    }

    activated() {
        this.error = null
        this.initValues() // Update to what is saved in the 'session'
    }

    initValues() {
        if (this.firstName.length == 0 && this.shouldSplitName) {
            this.firstName = CartManager.checkout.customer?.firstName ?? ''
        }
        if (this.lastName.length == 0 && this.shouldSplitName) {
            this.lastName = CartManager.checkout.customer?.lastName ?? ''
        }
        if (this.email.length == 0) {
            this.email = CartManager.checkout.customer?.email ?? ''
        }
        if (this.name.length == 0 && !this.shouldSplitName) {
            this.name = CartManager.checkout.customer?.name ?? ''
        }
    }

    get defaultCountry() {
        return ServerManager.shop.address?.country ?? ''
    }

    get deliveryLocations(): Array<DeliveryLocation> {
        return ServerManager.shop.consumptionOptions.delivery.deliveryLocations
    }

    get shouldSplitName() {
        return ServerManager.shop.enableSeparatedName
    }

    get phone(): string {
        return this.editPhone || (CartManager.checkout.customer?.phone ?? '')
    }

    set phone(value: string) {
        this.editPhone = value
    }

    get tableButtonText() {
        if (this.type === ConsumptionOptionType.TakeAway) {
            return this.$t('customer.takeAwayLocations.title').toString()
        }
        if (this.canSkipTableSelection) {
            return this.$t('customer.details.continueToPaymentButton').toString()
        }
        return this.isTableMode ? this.$t('customer.details.selectTableButton').toString() : this.$t('customer.details.selectPlaceButton').toString()
    }

    get usePostalCodes() {
        return ServerManager.shop.consumptionOptions.delivery.activeDeliveryLocationsType === DeliveryLocationType.PostalCodes
    }

    get deliveryDescriptionTitle(): string {
        return `${
            this.usePostalCodes
                ? this.$t('customer.details.deliveryDescription')
                : this.$t('customer.details.deliveryDescriptionKmRange')}`
    }

    get deliveryDescriptions(): Array<DeliveryDescription> {
        const locations = this.deliveryLocations.filter(location => this.usePostalCodes ? location.postalCodes.length > 0 : location.range.amount > 0).sort((a, b) => {
            if (a.price === b.price) {
                return a.price - b.price
            } else {
                return a.minOrderAmount - b.minOrderAmount
            }
        })

        const maximumInitiallyDisplayedRangeItems = 5
        return locations.map(location => {
            const rangeItems = this.usePostalCodes
                ? location.postalCodes.map(code => code.code)
                : [`${location.range.amount} ${DeliveryLocationHelper.getDeliveryRangeUnitShort(location.range.unit, this.$i18n as I18n)}`]

            const fee = location.price === 0
                ? this.$t('general.priceFree')
                : PriceHelper.format({price: location.price})

            const minAmount = location.minOrderAmount === 0
                ? ''
                : `\n${this.$t('general.minOrderAmount')}: ${PriceHelper.format({price: location.minOrderAmount})}`

            return {
                id: location.id,
                trimmedRangeItems: rangeItems.slice(0, maximumInitiallyDisplayedRangeItems),
                rangeItems,
                feeAndMinAmount: ServerManager.shop.enableHidePrices
                    ? ''
                    : `${this.$t('general.deliveryFee')}: ${fee}${minAmount}`,
                expandable: rangeItems.length > maximumInitiallyDisplayedRangeItems,
            }
        })
    }

    toggleDeliveryLocationDescription(deliveryLocationDescription: DeliveryDescription) {
        const index = this.expandedDeliveryDescriptions.indexOf(deliveryLocationDescription.id)
        if (index > -1) {
            this.expandedDeliveryDescriptions.splice(index, 1)
        } else {
            this.expandedDeliveryDescriptions.push(deliveryLocationDescription.id)
        }
    }

    isDeliveryLocationCollapsed(deliveryLocationDescriptionId: string): boolean {
        return this.expandedDeliveryDescriptions.includes(deliveryLocationDescriptionId)
    }

    getItemsToShowForDeliveryLocationDescription(deliveryLocationDescription: DeliveryDescription) {
        if (this.isDeliveryLocationCollapsed(deliveryLocationDescription.id)) {
            return deliveryLocationDescription.rangeItems
        } else {
            return deliveryLocationDescription.trimmedRangeItems
        }
    }

    goToNext() {
        const customer = Customer.create({
            firstName: this.firstName,
            lastName: this.lastName,
            phone: isNullOrEmpty(this.phone)
                ? null
                : this.phone,
            email: isNullOrEmpty(this.email)
                ? null
                : this.email,
            locale: i18n.locale.substring(0, 2),
            type: CartManager.checkout.customer?.type,
        })

        if (!this.shouldSplitName) {
            customer.name = this.name
        }

        const country = ServerManager.shop.address?.country ?? 'BE'
        customer.validate(this.nameRequired, this.phoneRequired, this.emailRequired, this.shouldSplitName, country, this.$i18n as I18n).then(() => {
            this.error = null
            // Reactive set customer
            this.$set(CartManager.checkout, 'customer', customer)

            if (this.addressRequired) {
                if (!this.address) {
                    this.error = this.$i18n.t('customer.details.errors.noAddress').toString()
                    return
                }

                if (this.location == null) {
                    if (this.usePostalCodes) {
                        this.error = this.$i18n.t('customer.details.errors.noDeliveryToAddressPostalcode').toString()
                    } else {
                        this.error = this.$i18n.t('customer.details.errors.noDeliveryToAddress').toString()
                    }
                    return
                }

                if (this.location.minOrderAmount > CartManager.checkout.cart.getPrice()) {
                    this.error = this.$i18n.t('customer.details.errors.belowMinOrderAmount').toString()
                    return
                }

                this.$set(CartManager.checkout, 'deliveryCostPosProductId', this.location.productId)
                this.$set(CartManager.checkout, 'deliveryAddress', this.address)
                this.$set(CartManager.checkout, 'deliveryPrice', this.location.price)
            }

            CartManager.save()

            if (this.isNextTimeSlot) {
                this.showTimeSlotView()
            } else if (this.canSkipTableSelection) {
                this.autoSelectTableAndPay(this.dineInTables[0])
            } else {
                this.editTable(false)
            }
        }).catch((e) => {
            if (e.getHuman) {
                this.error = e.getHuman()
            } else {
                console.log(e)
                throw e
            }
        })
    }
}
