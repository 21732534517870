import {ArrayDecoder, AutoEncoder, field, StringDecoder} from '@simonbackx/simple-encoding'

export class DIModifierOption extends AutoEncoder {
    @field({decoder: StringDecoder, optional: true})
    modifierOptionID: string

    @field({decoder: StringDecoder, optional: true})
    companyID: string

    @field({decoder: StringDecoder, optional: true})
    modifierID: string

    @field({decoder: StringDecoder, optional: true})
    modifierOptionName: string

    @field({decoder: StringDecoder, optional: true})
    productID: string

    @field({decoder: StringDecoder, optional: true})
    priceImpact: string

    @field({decoder: StringDecoder, optional: true})
    priceImpactType: string

    @field({decoder: StringDecoder, optional: true})
    modifierOptionActive: string

    @field({decoder: StringDecoder, optional: true})
    modifierVat: string

    @field({decoder: StringDecoder, optional: true})
    fixedVat: string

    @field({decoder: StringDecoder, optional: true})
    modifierOptionCreated: string

    @field({decoder: StringDecoder, optional: true, nullable: true})
    modifierOptionUpdated: string | null

    @field({decoder: StringDecoder, optional: true})
    status: string
}

export class DIModifier extends AutoEncoder {
    @field({decoder: StringDecoder, optional: true})
    modifierName: string

    @field({decoder: StringDecoder, optional: true})
    modifierID: string

    @field({decoder: StringDecoder, optional: true})
    modifierTypeID: string

    @field({decoder: StringDecoder, optional: true, nullable: true, defaultValue: () => null})
    productIDs: string | null = null

    @field({decoder: StringDecoder, optional: true, nullable: true, defaultValue: () => null})
    categoryIDs: string | null = null

    @field({decoder: StringDecoder, optional: true})
    modifierRequired: string

    @field({decoder: StringDecoder, optional: true})
    modifierIcon: string

    @field({decoder: new ArrayDecoder(DIModifierOption), optional: true, nullable: true, defaultValue: () => null})
    modifierOptions: Array<DIModifierOption> | null = null
}
