import { render, staticRenderFns } from "./ShopSplashView.vue?vue&type=template&id=c0eaae08&"
import script from "./ShopSplashView.vue?vue&type=script&lang=ts&"
export * from "./ShopSplashView.vue?vue&type=script&lang=ts&"
import style0 from "./ShopSplashView.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/wannes.vermeulen/Repositories/dorst-app/dorst/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c0eaae08')) {
      api.createRecord('c0eaae08', component.options)
    } else {
      api.reload('c0eaae08', component.options)
    }
    module.hot.accept("./ShopSplashView.vue?vue&type=template&id=c0eaae08&", function () {
      api.rerender('c0eaae08', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ShopSplashView.vue"
export default component.exports