















































































































































































































import {ComponentWithProperties} from '@simonbackx/vue-app-navigation'
import {Component, Mixins} from 'vue-property-decorator'

import {AnalyticsController} from '../analytics/AnalyticsController'
import {CartManager} from '../classes/CartManager'
import {TealiumManager} from '../classes/TealiumManager'
import {environment} from '../environments/environment'
import {PriceHelper} from '../helpers/PriceHelper'
import {ProductViewMixin} from '../mixins/ProductViewMixin'
import CartItemRow from './CartItemRow.vue'
import EditCartItemOptionsView from './EditCartItemOptionsView.vue'
import OptionGroupBox from './product/OptionGroupBox.vue'
import OptionGroupGroupBox from './product/OptionGroupGroupBox.vue'
import ProductVariantBox from './product/ProductVariantBox.vue'
import UpsellBoxItem from './product/UpsellBoxItem.vue'
import DRSTBox from '@dorst/components/src/DRSTBox.vue'
import DRSTBoxItem from '@dorst/components/src/DRSTBoxItem.vue'
import DRSTFloatingFooter from '@dorst/components/src/DRSTFloatingFooter.vue'
import DRSTFooterBox from '@dorst/components/src/DRSTFooterBox.vue'
import DRSTNavigationBar from '@dorst/components/src/DRSTNavigationBar.vue'
import Stepper from '@dorst/components/src/inputs/Stepper.vue'
import TransitionExpand from '@dorst/components/src/transitions/TransitionExpand.vue'
import {i18n} from '@dorst/frontend-translations'
import {isNullOrEmpty} from '@dorst/helpers'
import {
    CartItem,
    ConsumptionOptionTypeHelper,
    I18n,
    TranslatedString,
    UpsellGroupWithProducts,
    UpsellWithProduct,
} from '@dorst/structures'

@Component({
    components: {
        DRSTBox,
        UpsellBoxItem,
        CartItemRow,
        DRSTBoxItem,
        DRSTFooterBox,
        DRSTFloatingFooter,
        DRSTNavigationBar,
        TransitionExpand,
        OptionGroupBox,
        ProductVariantBox,
        Stepper,
        OptionGroupGroupBox,
    },
    filters: {
        i18n: (translatedStr: TranslatedString) => translatedStr.getForI18n(i18n),
    },
})

export default class ProductView extends Mixins(ProductViewMixin) {

    /** Check ProductViewMixin for extra props/functionality */

    selectedUpsells: Array<CartItem> = []
    forceUpdateUpsells = 0
    hasTriedToAdd = false

    activated() {
        if (environment.features.tealium) {
            void TealiumManager.onProductView(this.$i18n as any, this.cartItem)
        }
        AnalyticsController.get().event('onProductView', this.cartItem)
    }

    get tags() {
        return this.editCartItem.product.tags
            .map(tag => tag.name.getForI18n(this.$i18n))
            .join(', ')
    }

    /** Upsell logic */
    get upsellGroups(): Array<UpsellGroupWithProducts> {
        return this.shop.getUpsellGroupsForProduct(this.cartItem.product.id, this.consumptionMode)
    }

    get hasUpsells() {
        return this.upsellGroups.length > 0
    }

    get availabilityTitle() {
        return ConsumptionOptionTypeHelper.getAvailabilityTitle(this.cartItem.product.consumptionMode, this.$i18n as I18n)
    }

    hasExtraUpsellOptions(upsellItem: UpsellWithProduct): boolean {
        return upsellItem.product.variants.length > 1 || upsellItem.product.defaultVariant.optionGroups.length > 0
    }

    addUpsell(cartItem: CartItem) {
        const exisiting = this.selectedUpsells.find(el => {
            return el.isSameAs(cartItem, CartManager.cart.subItems)
        })
        if (exisiting) {
            exisiting.amount += cartItem.amount
            return
        }
        this.selectedUpsells.push(cartItem)
    }

    deleteUpsell(cartItem: CartItem) {
        const index = this.selectedUpsells.findIndex(el => el.id === cartItem.id)
        if (index >= 0) {
            this.selectedUpsells.splice(index, 1)
        }
    }

    updateUpsell(oldCartItem: CartItem, newCartItem: CartItem) {
        const addToExistingUpsell = this.selectedUpsells.find(el => {
            return el.id != oldCartItem.id && el.isSameAs(newCartItem, CartManager.cart.subItems)
        })
        // if we have no other item equal to the new one => update the old to new (so we do not have a re-order with delete & add)
        // if we have other item equal to the new one => remove and add (this will automaticly increase the amount of the other item instead)
        if (addToExistingUpsell) {
            this.deleteUpsell(oldCartItem)
            this.addUpsell(newCartItem)
        } else {
            const oldItemIndex = this.selectedUpsells.findIndex(el => {
                return el.id == oldCartItem.id
            })
            this.selectedUpsells[oldItemIndex] = newCartItem
        }
        this.forceUpdateUpsells++
    }

    editUpsell(cartItem: CartItem) {
        this.present(new ComponentWithProperties(EditCartItemOptionsView, {
            cartItem,
            editing: true,
            subCartItems: this.editSubCartItems,
            onSave: (newCartItem: CartItem) => {
                this.updateUpsell(cartItem, newCartItem)
                // Note: not using the second parameter `subItems` for now. Menu-items as upsell is not (yet) supported
            },
        }).setDisplayStyle('popup'))
    }

    /** Add to cart logic */
    get combinedPrice() {
        const productTotal = this.editCartItem.getPrices(this.editSubCartItems).price
        const upsellTotal = this.selectedUpsells.reduce((acc, cartItem: CartItem) => {
            acc += cartItem.getPrices(CartManager.cart.subItems).price
            return acc
        }, 0)
        return PriceHelper.format({price: productTotal + upsellTotal})
    }

    get hideOrderButtons() {
        return this.shop.hideOrderButtonsWhenOrderSystemIsOff
            || !CartManager.orderingAllowedForCustomerType
    }

    get addButtonText() {
        return this.canOrder
            ? `${this.$t('customer.products.addToCart')} ${isNullOrEmpty(this.combinedPrice) ? '' : `(${this.combinedPrice})`}`
            : this.isClosed || this.isAvailable
                ? this.$t('customer.order.shopClosed')
                : this.$t('customer.products.notAvailable')
    }

    addAllToBasket() {
        // visually keep enabled until second attempt to click add button
        if (!this.hasTriedToAdd) {
            this.hasTriedToAdd = true
        }

        if (this.optionAmountErrors.length) {
            const scrollEl = document.querySelector('.error-box')
            if (scrollEl) {
                scrollEl.scrollIntoView({behavior: 'smooth'})
            }
            return
        }

        this.addItemToBasket(this.editCartItem, this.editSubCartItems)
        for (const item of this.selectedUpsells) {
            this.addItemToBasket(item, [])
        }
        this.pop()
    }

    addItemToBasket(item: CartItem, subItems: Array<CartItem>) {
        CartManager.addItem(item, subItems)
    }
}
