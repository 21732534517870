import {AutoEncoder, DateDecoder, EnumDecoder, field, IntegerDecoder, StringDecoder} from '@simonbackx/simple-encoding'

export enum CallStatus {
    Queued = 'queued',
    Ringing = 'ringing',
    InProgress = 'in-progress',
    Canceled = 'canceled',
    Completed = 'completed',
    Busy = 'busy',
    NoAnswer = 'no-answer',
    Failed = 'failed',
}

export class Call extends AutoEncoder {

    /**
     * Cost of this call in cents, negative signifying it cost us money.
     */
    @field({decoder: IntegerDecoder, nullable: true})
    price: number | null

    @field({decoder: StringDecoder})
    currency: 'USD'

    @field({decoder: StringDecoder, nullable: true})
    answeredBy: string | null = null

    @field({decoder: StringDecoder})
    sid: string

    @field({decoder: IntegerDecoder})
    duration = 0

    @field({decoder: DateDecoder, nullable: true})
    startTime: Date | null = null

    @field({decoder: new EnumDecoder(CallStatus)})
    status: CallStatus
}
