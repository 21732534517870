












import {Component, Prop, Vue} from 'vue-property-decorator'

import Spinner from './Spinner.vue'

@Component({
    components: {Spinner},
})
export default class LoadingCover extends Vue {
}
