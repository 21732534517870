import {AutoEncoder, field, IntegerDecoder, StringDecoder} from '@simonbackx/simple-encoding'

import {Address} from './Address'
import {PaymentMethod} from './PaymentMethod'

export class TopUp extends AutoEncoder {
    @field({decoder: PaymentMethod})
    paymentMethod: PaymentMethod

    @field({decoder: IntegerDecoder})
    amount: number
}

/**
 * @deprecated
 * Keep this for now to keep the old code working in case we need to switch back to the direct api of network psp
 */
export class CreditCard extends AutoEncoder {
    @field({decoder: StringDecoder})
    number: string

    @field({decoder: IntegerDecoder})
    expireYear: number

    @field({decoder: IntegerDecoder})
    expireMonth: number

    @field({decoder: StringDecoder})
    cvc: string

    /**
     * Cardholder name
     */
    @field({decoder: StringDecoder})
    name: string

    /**
     * Billing address (is required for some payment methods)
     */
    @field({decoder: Address, optional: true, nullable: true})
    address: Address | null = null
}

export class PaymentDetails extends AutoEncoder {
    @field({decoder: StringDecoder, nullable: true})
    cardID: string | null = null

    @field({decoder: StringDecoder, nullable: true, version: 46, upgrade: () => null})
    verification: string | null = null

    @field({decoder: TopUp, nullable: true, version: 44, upgrade: () => null})
    topup: TopUp | null = null
}
