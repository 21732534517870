import {AutoEncoder, BooleanDecoder, field, NumberDecoder} from '@simonbackx/simple-encoding'

import {Address, LatLng} from '../Address'

export class DistanceMatrixRequest extends AutoEncoder {
    @field({decoder: Address})
    from: Address

    @field({decoder: Address})
    to: Address
}

export class DistanceMatrixResponse extends AutoEncoder {
    @field({decoder: NumberDecoder, nullable: true})
    distanceInMeters: number | null
}
