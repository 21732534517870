import {AutoEncoderPatchType, ObjectData} from '@simonbackx/simple-encoding'

import {googleGeoCoding} from '../actions/location'
import {Address, AddressBook, AddressBookEntry, AddressBookPatch, GeoCodingRequest, Version} from '@dorst/structures'

export class AddressManagerStatic {
    addressBook: AddressBook

    constructor() {
        this.addressBook = AddressBook.create({})
    }

    get entries(): Array<AddressBookEntry> {
        return this.addressBook.entries
    }

    get lastSelected(): string | null {
        return this.addressBook.lastSelected
    }

    addAddress(address: Address, label?: string): AddressBookEntry {
        const entry = AddressBookEntry.create({address, label})
        this.addEntry(entry)

        return entry
    }

    addEntry(entry: AddressBookEntry) {
        const patch = AddressBookPatch.create({})
        patch.entries.addPut(entry)

        this.applyPatch(patch)
    }

    updateEntry(entryPatch: AutoEncoderPatchType<AddressBookEntry>) {
        const patch = AddressBookPatch.create({})
        patch.entries.addPatch(entryPatch)
        this.applyPatch(patch)
    }

    deleteEntry(id: string) {
        const patch = AddressBookPatch.create({})
        patch.entries.addDelete(id)

        this.applyPatch(patch)

        if (this.addressBook.lastSelected === id) {
            this.setLastSelected(null)
        }
    }

    setLastSelected(id: string | null) {
        const patch = AddressBookPatch.create({lastSelected: id})

        this.applyPatch(patch)
    }

    applyPatch(patch: AutoEncoderPatchType<AddressBook>) {
        this.addressBook = this.addressBook.patch(patch)
        this.save()
    }

    save() {
        const encoded = JSON.stringify({
            version: Version,
            addressBook: this.addressBook.encode({version: Version}),
            date: new Date().getTime(),
        })
        localStorage.setItem('address_book', encoded)
    }

    async load() {
        const json = localStorage.getItem('address_book')
        if (json) {
            try {
                const encoded = JSON.parse(json)
                if (encoded.addressBook != null && encoded.version != null && Number.isInteger(encoded.version)) {
                    const addressBook = AddressBook.decode(new ObjectData(encoded.addressBook, {version: encoded.version}))
                    // fetch latlng if we have not tried yet for this address.
                    addressBook.entries = await Promise.all(addressBook.entries.map(async (el) => {
                        if (el.address.latLng == null) {
                            el.address.latLng = (await googleGeoCoding(GeoCodingRequest.create({
                                address: el.address,
                            }))).latLng
                        }
                        return el
                    }))
                    this.addressBook = addressBook
                }
            } catch (e) {
                console.warn('Restoring address book failed:')
                console.warn(e)
            }
        }
    }
}

export const AddressManager = new AddressManagerStatic()
