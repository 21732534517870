














































































import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import CountryFlag from 'vue-country-flag'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import DRSTBoxItem from '../DRSTBoxItem.vue'
import ContextMenu from '../overlays/ContextMenu.vue'
import TextInputRow from './TextInputRow.vue'
import {CountryDialCode} from '@dorst/structures'

@Component({
    components: {
        ContextMenu,
        DRSTBoxItem,
        CountryFlag,
        TextInputRow,
    },
})
export default class CountryCodeContextMenu extends Mixins(NavigationMixin) {
    @Prop({default: 0})
    x!: number

    @Prop({default: 0})
    y!: number

    @Prop()
    setCountryCode: (code: string, suffix: string) => Promise<void>

    @Prop({required: true})
    activeCountry!: string

    @Prop()
    countryCodes: Array<CountryDialCode>

    query = ''

    async mounted() {
        setTimeout(() => {
            this.focusInput()
        }, 0)
    }

    get activeCountryObject(): CountryDialCode | undefined {
        return this.countryCodes.find(el => {
            return el.code === this.activeCountry
        })
    }

    get filteredCodes() {
        if (!this.query) {
            return this.countryCodes
        }
        return this.countryCodes.filter(el => {
            if (el.dialCode && el.dialCode.replaceAll('+', '').replaceAll(' ', '').includes(this.query.replaceAll('+', '').replaceAll(' ', ''))) {
                return true
            }
            return el.name.toLowerCase().split(' ').find(part => part.startsWith(this.query.toLowerCase()))
        })
    }

    async update(dialCode: string, countryCode: string): Promise<void> {
        await this.setCountryCode(dialCode, countryCode)
        this.pop()
    }

    isActive(countryCode: string) {
        return countryCode === this.activeCountry
    }

    focusInput() {
        (this.$refs.inputRow as any)?.focus()
    }
}
