





























import {ComponentWithProperties, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import {ExternalIntegrationActions} from '../actions/ExternalIntegrations'
import {CartManager} from '../classes/CartManager'
import OrderDetailBox from './OrderDetailBox.vue'
import OrderDetailView from './OrderDetailView.vue'
import OrderItemRow from './OrderItemRow.vue'
import OrderView from './OrderView.vue'
import {DRSTNavigationBar, Spinner} from '@dorst/components'
import {InnovendStatus, InnovendStatusWrapper, OrderConsumer} from '@dorst/structures'

@Component({
    components: {
        DRSTNavigationBar,
        Spinner,
        OrderItemRow,
        OrderDetailBox,
    },
})
export default class InnovendPollingView extends Mixins(NavigationMixin) {
    @Prop({required: true})
    order: OrderConsumer
    /**
     * After how many minutes of polling we have to mark it as failed?
     */
    pollingDurationInMinutes = 15

    /**
     * How long should we wait between requests?
     */
    pollingSpeedInSeconds = 5

    innovendStatus: InnovendStatus = InnovendStatus.CREATED
    pollingInterval?: number

    get errorOnValidation() {
        return this.innovendStatus === InnovendStatus.ERROR
    }

    pollForStatus() {
        if (this.pollingInterval !== undefined) {
            clearInterval(this.pollingInterval)
            delete this.pollingInterval
        }

        const handler = () => {
            ExternalIntegrationActions.InnovendExternalValidationStatus(this.order)
                .then((statusWrapper: InnovendStatusWrapper) => {
                    this.innovendStatus = statusWrapper.status
                    if (this.innovendStatus === InnovendStatus.FILLED) {
                        clearInterval(this.pollingInterval)
                        delete this.pollingInterval
                        if (statusWrapper.innovendPickupInfo === undefined) {
                            this.innovendStatus = InnovendStatus.ERROR
                        } else {
                            this.show(new ComponentWithProperties(OrderView, {
                                order: this.order,
                                innovendPickupInfo: statusWrapper.innovendPickupInfo,
                            }))
                        }
                    } else if (this.innovendStatus === InnovendStatus.ERROR) {
                        clearInterval(this.pollingInterval)
                        delete this.pollingInterval
                    }
                }).catch(error => {
                    console.error('Failed to get innovend validation status', error)
                })
        }

        this.pollingInterval = setInterval(handler, this.pollingSpeedInSeconds * 1000) as unknown as number
    }

    mounted() {
        this.pollForStatus()
        setTimeout(() => {
            clearInterval(this.pollingInterval)
            delete this.pollingInterval
            this.innovendStatus = InnovendStatus.ERROR
        }, this.pollingDurationInMinutes * 60 * 1000) // if not validated after x minutes show error screen
    }

    showDetails() {
        this.present(new ComponentWithProperties(OrderDetailView, {order: this.order}))
    }
}
