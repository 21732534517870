interface PhoneNumberSplit {
    countryCode: string
    phone: string
}

export class Phone {

    static async validate(number: string, country = 'BE'): Promise<boolean> {
        if (number === '') {
            return false
        }

        const {PhoneNumberUtil} = await import('google-libphonenumber')
        const phoneUtil = PhoneNumberUtil.getInstance()

        try {
            const nrObject = phoneUtil.parseAndKeepRawInput(number, country)
            return phoneUtil.isValidNumber(nrObject)
        } catch (e) {
            return false
        }
    }

    static async format(number: string, country = 'BE'): Promise<string> {
        const {PhoneNumberFormat, PhoneNumberUtil} = await import('google-libphonenumber')
        const phoneUtil = PhoneNumberUtil.getInstance()
        const nrObject = phoneUtil.parseAndKeepRawInput(number, country)

        return phoneUtil.format(nrObject, PhoneNumberFormat.INTERNATIONAL)
    }

    static async splitPhoneNumber(number: string): Promise<PhoneNumberSplit> {
        try {
            const {PhoneNumberUtil} = await import('google-libphonenumber')
            const phoneUtil = PhoneNumberUtil.getInstance()

            // Parse number with country code and keep raw input.
            const nrObject = phoneUtil.parseAndKeepRawInput(number, 'BE')
            return {
                countryCode: nrObject.getCountryCode().toString(),
                phone: nrObject.getNationalNumber().toString(),
            }
        } catch (error) {
            console.error(error)
            return {
                countryCode: '',
                phone: '',
            }
        }
    }
}
