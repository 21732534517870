import {ArrayDecoder, AutoEncoder, Data, field, IntegerDecoder, ObjectData, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {Option, OptionGroup, Version} from '../index'
import {CartItemOption} from './CartItemOption'
import {CartItemPrice} from './CartItemPrice'
import {I18n} from './I18n'
import {Language} from './Language'
import {LegacyProduct} from './LegacyProduct'
import {OptionGroupMode} from './OptionGroup'
import {Product, ProductVariant} from './Product'
import {ProductPrice} from './ProductPrice'
import {isNullOrEmpty} from '@dorst/helpers'
import {Formatter} from '@dorst/validation'

export class CartItemVariant extends AutoEncoder {
    /*
    * Id of the chosen variant
    */
    @field({decoder: StringDecoder})
    variantId: string

    /**
     * Options that the customer has chosen
     */
    @field({decoder: new ArrayDecoder(CartItemOption)})
    options: Array<CartItemOption>

    static defaultForVariant(variant: ProductVariant, linkedProducts: Array<Product>, cartItemId: string): {cartItemVariant: CartItemVariant; subItems: Array<CartItem>} {
        const subItems: Array<CartItem> = []
        /**
         * If we have option groups linked to eachother, we can only add options from one or the other
         * So if we have an optionGroupGroupLink, only create a default for the first optiongroup.
         */
        const shouldSkipGroupDefault = (optionGroupId: string): boolean => {
            for (const optionGroupGroupLink of variant.optionGroupLinks) {
                if (optionGroupGroupLink.optionGroupIds.includes(optionGroupId) && optionGroupGroupLink.optionGroupIds[0] != optionGroupId) {
                    return true
                }
            }
            return false
        }

        const cartItemVariant = CartItemVariant.create({
            variantId: variant.id,
            options: variant.optionGroups.reduce<Array<CartItemOption>>((accOptions, currGroup) => {
                if (currGroup.options.length == 0) {
                    return accOptions
                }
                if (shouldSkipGroupDefault(currGroup.id)) {
                    return accOptions
                }
                if (currGroup.mode === OptionGroupMode.Single) {
                    if (!currGroup.defaultOption) {
                        return accOptions
                    }
                    let linkedProd = linkedProducts.find(el => el.id === currGroup.defaultOption!.productLink?.productId)
                    const cartItemOption = CartItemOption.create({optionGroup: currGroup, option: currGroup.defaultOption})
                    if (linkedProd) {
                        // if we do not want to use productprice, map the variant prices
                        linkedProd = Product.formatProductForOption(linkedProd, currGroup.defaultOption!)
                        const {cartItem} = CartItem.defaultForProduct(linkedProd, []) // pass empty erray because nested menu-products are not allowed.
                        if (cartItem) {
                            cartItem.parentId = cartItemId
                            subItems.push(cartItem)
                            cartItemOption.subCartItemId = cartItem.id // create link from option => cartitem
                        }
                    }
                    accOptions.push(cartItemOption)
                } else { // todo add support for other option groups in menu.
                    for (const option of currGroup.options) {
                        if (option.defaultAmount) {
                            let linkedProd = linkedProducts.find(el => el.id === currGroup.defaultOption!.productLink?.productId)
                            const cartItemOption = CartItemOption.create({optionGroup: currGroup, option, amount: option.defaultAmount})
                            if (linkedProd) {
                                linkedProd = Product.formatProductForOption(linkedProd, currGroup.defaultOption!)
                                const {cartItem} = CartItem.defaultForProduct(linkedProd, []) // pass empty erray because nested menu-products are not allowed.
                                if (cartItem) {// if product has other price, recreate the cartItem (can not update because of infinite loop)
                                    subItems.push(cartItem)
                                    cartItemOption.subCartItemId = cartItem.id // create link from option => cartitem
                                }
                            }
                            accOptions.push(cartItemOption)
                        }
                    }
                }
                return accOptions
            }, []),
        })

        return {
            cartItemVariant,
            subItems,
        }
    }
}

export class CartItem extends AutoEncoder {
    @field({decoder: StringDecoder, optional: true})
    id: string = uuidv4()

    /**
     * Product that the customer has chosen
     */
    @field({decoder: LegacyProduct})
    @field({
        decoder: Product, version: 75, upgrade: function (this: CartItem, old: LegacyProduct) {
            return Product.fromLegacy(old)
        },
    })
    product: Product

    /** Selected variant with selected price and options. */
    @field({
        decoder: CartItemVariant, version: 75, upgrade: function (this: CartItem) {
            return CartItemVariant.create({
                variantId: this.legacy_price?.id ?? this.product.defaultVariant.id,
                options: this.legacy_options,
            })
        },
    })
    selectedVariantInfo: CartItemVariant

    /** Used for option-products (menu item) where the parentId == id of main product's cartitem(menu). */
    @field({decoder: StringDecoder, optional: true})
    parentId?: string

    /**
     * True for cart."subItems"
     * False for cart."Items"
     * */
    get isSubItem(): boolean {
        return !isNullOrEmpty(this.parentId)
    }

    // @deprecated only used for selectedVariantInfo upgrade (v75)
    @field({decoder: ProductPrice, field: 'price', optional: true})
    legacy_price?: ProductPrice

    // @deprecated only used for selectedVariantInfo upgrade (v75)
    @field({decoder: new ArrayDecoder(CartItemOption), field: 'options', optional: true})
    legacy_options?: Array<CartItemOption>

    get selectedVariant(): ProductVariant {
        return this.product.variants.find(v => v.id === this.selectedVariantInfo.variantId)!
    }

    /** Price of the selected variant without its options */
    get basePrice(): number {
        return this.selectedVariant.price
    }

    /** Selected options of the selected variant */
    get options(): Array<CartItemOption> {
        return this.selectedVariantInfo.options
    }

    /**
     * How many times it is in the cart
     */
    @field({decoder: IntegerDecoder, defaultValue: () => 1})
    amount = 1

    get hasVariants() {
        return this.product.variants.length > 1
    }

    static decode(data: Data): CartItem {
        return super.decode(data) as CartItem
    }

    /**
     * Calculated prices
     * @param otherCartItems - We should pass the cart.subItems here.
     * We will filter the linked cartitems that apply to this cartItem
     */
    getPrices(otherCartItems: Array<CartItem> = []): CartItemPrice {
        const unitPrice = this.calculatePrice(otherCartItems)
        return new CartItemPrice({
            unitPrice,
            price: unitPrice * this.amount,
        })
    }

    /** DEPRECATED - no longer used? */
    get code(): string {
        return `${this.product.id}-${this.selectedVariantInfo.variantId}-${this.options.map(o => o.option.id).join('-')}`
    }

    /**
     * Some shops might change the name of a product, option or price instead of creating a new one.
     * That might cause some issues, because 'fries' and 'potatoes' now share the same ID. To prevent
     * that we combine them in production lists, we need a detailed code that includes the name AND the ID.
     * That is the reason we need a language here
     */
    detailedCode(language: Language): string {
        return `${this.product.id}/${Formatter.slug(this.product.name.getForLanguage(language))}-${this.selectedVariantInfo.variantId}/${this.selectedVariant?.name.getForLanguage(language) ? Formatter.slug(this.selectedVariant.name.getForLanguage(language)) : ''}-${this.options.map(o => {
            return `${o.optionGroup.id}/${Formatter.slug(o.optionGroup.name.getForLanguage(language))}_${o.option.id}/${Formatter.slug(o.option.name.getForLanguage(language))}`
        }).sort().join('-')}`
    }

    /*
    * linkedProducts are products that are used within some optiongroups of the main product (menu-products)
     */
    static defaultForProduct(product: Product, linkedProducts: Array<Product>): {cartItem: CartItem; subItems: Array<CartItem>} {
        const generatedId = uuidv4()
        const {cartItemVariant, subItems} = CartItemVariant.defaultForVariant(product.defaultVariant, linkedProducts, generatedId)
        return {
            cartItem: CartItem.create({
                id: generatedId,
                product,
                selectedVariantInfo: cartItemVariant,
            }),
            subItems,
        }
    }

    /** If we do not pass a variantId, we will take the default */
    static fromProductAndVariantId(product: Product, variantId: string | undefined = undefined, linkedProducts: Array<Product> = []): {item: CartItem | null; subItems: Array<CartItem>} {
        if (variantId === undefined) {
            variantId = product.defaultVariant.id
        }
        const variant = product.variants.find(el => el.id === variantId)
        if (!variant) {
            return {item: null, subItems: []}
        }
        const generatedId = uuidv4()
        const {cartItemVariant, subItems} = CartItemVariant.defaultForVariant(variant, linkedProducts, generatedId)
        return {
            item: CartItem.create({
                id: generatedId,
                product,
                selectedVariantInfo: cartItemVariant,
            }),
            subItems: subItems,
        }
    }

    /**
     * Used to retrieve the lowest variant price of a cartItem.
     * At the moment we use this to get the base-price ( price without optional options )
     * We can for example update an options' price (when useProductPrice === true) that has this cartItem.product linked to it.
     * @param cartItem
     * @returns {number}
     *
     */
    static getLowestVariantPrice(cartItem: CartItem): number {
        return cartItem.product.variants.reduce((acc, curr) => {
            if (acc === 0) {
                return curr.price
            }
            return curr.price < acc ? curr.price : acc
        }, 0)
    }

    /**
     *
     * @param parentCartItemId - Parent cart Id we want to reference on this cartItem
     * @param optionWithLink - Option that contains info about how the linked product should look like.
     * We are mostly interested in the productLink property inside this option. But we also need the option.priceChange
     * in case we do not want to use the product-price within this combo-menu product.
     * @returns {this} - returns the CartItem with mapped product and or parent CartItem.id.
     */
    overwriteOptionLinkData(parentCartItemId: string | null = null, optionWithLink?: Option): CartItem {
        // Clone the product first so it does not overwrite the original.
        this.product = this.product.clone()
        if (optionWithLink !== undefined) {
            const {productLink, priceChange} = optionWithLink
            if (productLink !== undefined) {
                this.product.variants = this.product.variants.flatMap(variant => {
                    if (productLink.disabledVariants.includes(variant.id)) {
                        return []
                    }
                    if (!productLink.useProductPrice) {
                        const newPrice = variant.price - CartItem.getLowestVariantPrice(this) + priceChange
                        if (newPrice != variant.price) {
                            variant.price = newPrice
                        }
                    }
                    variant.optionGroups = variant.optionGroups.reduce<Array<OptionGroup>>((acc, curr) => {
                        if (productLink.disabledOptionGroups.includes(curr.id)) {
                            return acc
                        }
                        curr.options.filter(el => productLink.disabledOptions.includes(el.id))
                        acc.push(curr)
                        return acc
                    }, [])
                    return variant
                })
            }
        }

        if (parentCartItemId !== null) {
            this.parentId = parentCartItemId
        }
        return this
    }

    clone(): CartItem {
        return CartItem.decode(new ObjectData(this.encode({version: Version}), {version: Version}))
    }

    cloneWithNewId(): CartItem {
        const newItem = CartItem.decode(new ObjectData(this.encode({version: Version}), {version: Version}))
        newItem.id = uuidv4()
        return newItem
    }

    calculatePrice(subItems: Array<CartItem>): number {
        return Array.from(this.getPricePerVAT(subItems).values()).reduce((accumulator, currentValue) => accumulator + currentValue)
    }

    /**
     * Prices always include VAT
     * @param subItems - We should pass the cart.subItems here.
     * We will filter the linked cartitems that apply to this cartItem
     */
    getPricePerVAT(subItems: Array<CartItem>): Map<number, number> {
        const byVAT = new Map<number, number>()
        byVAT.set(this.product.VAT, this.basePrice)

        // sort cheapest first so we give the cheapest as free if applicable

        const sortedOptions = [...this.options].sort((a, b) => a.option.priceChange - b.option.priceChange)

        const freeAmountsMap = new Map<string, number>()
        for (const option of sortedOptions) {
            const optionVAT = option.option.VAT ?? option.optionGroup.VAT ?? this.product.VAT
            const dependantCartItem = subItems.find(el => {
                return el.id === option.subCartItemId
            })
            const unitPrice = dependantCartItem === undefined
                ? option.option.priceChange
                : dependantCartItem.calculatePrice([]) * option.amount ?? 0

            if (option.optionGroup.mode === OptionGroupMode.Single && option.optionGroup.defaultOptionsAmount > 0) {
                continue
            }

            if (option.optionGroup.freeOptionsAmount > 0) {
                if (freeAmountsMap.get(option.optionGroup.id) === undefined) {
                    freeAmountsMap.set(option.optionGroup.id, option.optionGroup.freeOptionsAmount)
                }
            }

            const freeAmountsForGroup = freeAmountsMap.get(option.optionGroup.id) ?? 0
            let amountsToCharge = option.amount
            amountsToCharge -= option.option.amountIncludedInProductPrice
            if (amountsToCharge === 0) {
                continue
            }

            if (freeAmountsForGroup > 0) {
                const freeIncluded = Math.min(amountsToCharge, freeAmountsForGroup)
                amountsToCharge -= freeIncluded
                freeAmountsMap.set(option.optionGroup.id, freeAmountsForGroup - freeIncluded)
            }

            byVAT.set(optionVAT, (byVAT.get(optionVAT) ?? 0) + unitPrice * amountsToCharge)
        }
        return byVAT
    }

    get sortedOptions(): Array<CartItemOption> {
        const optionsOrder = this.selectedVariant.optionGroups.reduce<Array<string>>((acc, curr) => {
            acc.push(...curr.options.map(el => el.id))
            return acc
        }, [])

        return [...this.options].sort((a, b) => {
            return optionsOrder.indexOf(a.option.id) - optionsOrder.indexOf(b.option.id)
        })
    }

    optionDescription(subItems: Array<CartItem>, lang: Language, indent = false): string {
        let str = this.product.variants.length > 1 && this.selectedVariant ? this.selectedVariant?.name.getForLanguage(lang) : ''
        for (const option of this.sortedOptions) {
            const name = option.option.name.getForLanguage(lang) + (option.amount > 1 ? `(x${option.amount})` : '')

            if (str.length != 0) {
                str += '\n'
            }
            if (name.length <= 3) {
                str += `${(indent ? '- ' : '') + option.optionGroup.name.getForLanguage(lang)} ${name}`
            } else {
                str += `${(indent ? '- ' : '') + name}`
            }
            if (option.subCartItemId) {
                const subItem = subItems.find(el => el.id === option.subCartItemId)
                if (subItem !== undefined) {
                    const subItemDescription = subItem.optionDescription([], lang, true)
                    if (subItemDescription.length > 0) {
                        str += `\n${subItemDescription}`
                    }
                }
                str += '\n' // extra whitespace between menu items
            }
        }
        return str
    }

    /**
     *
     * @param subItems - Array of subItems for this CartItem.
     * @param i18n
     * @param indent - Whether we want the option to be indented in the string (used for subitems).
     * @returns {string} - The Description for all options in this CartItem.
     */
    optionDescriptionForI18n(subItems: Array<CartItem>, i18n: I18n, indent = false): string {
        let str = this.product.variants.length > 1 && this.selectedVariant ? `${indent ? '- ' : ''}${this.selectedVariant?.name.getForLanguage(i18n as any)}` : ''
        for (const option of this.sortedOptions) {
            const name = option.option.name.getForI18n(i18n as any) + (option.amount > 1 ? `(x${option.amount})` : '')
            if (str.length != 0) {
                str += '\n'
            }
            if (name.length <= 3) {
                str += `${(indent ? '- ' : '') + option.optionGroup.name.getForI18n(i18n as any)} ${name}`
            } else {
                str += `${(indent ? '- ' : '') + name}`
            }
            if (option.subCartItemId) {
                const subItem = subItems.find(el => el.id === option.subCartItemId)
                if (subItem !== undefined) {
                    const subItemDescription = subItem.optionDescriptionForI18n([], i18n, true)
                    if (subItemDescription.length > 0) {
                        str += `\n${subItemDescription}`
                    }
                }
                str += '\n' // extra whitespace between menu items
            }
        }
        return str
    }

    isSameAs(otherItem: CartItem, subItems: Array<CartItem>): boolean {
        if (this.product.id !== otherItem.product.id || this.parentId !== otherItem.parentId || this.selectedVariantInfo.variantId !== otherItem.selectedVariantInfo.variantId || this.options.length !== otherItem.options.length) {
            return false
        }
        for (const [i, option] of this.options.entries()) {
            const otherOption = otherItem.options?.[i]
            if (
                option.amount !== otherOption.amount
                || option.option.id !== otherOption.option.id
                || option.optionGroup.id !== otherOption.optionGroup.id
                || option.optionGroup.VAT !== otherOption.optionGroup.VAT
                || option.option.VAT !== otherOption.option.VAT
                || option.option.priceChange !== otherOption.option.priceChange
                || option.option.productLink?.productId !== otherOption.option.productLink?.productId
            ) {
                return false
            }
            if (!isNullOrEmpty(option.subCartItemId)) {
                if (isNullOrEmpty(otherOption.subCartItemId)) {
                    return false
                } else {
                    // compare the optionproduct.
                    const cartItem = subItems.find(el => el.id === option.subCartItemId)
                    const subItem = subItems.find(el => el.id === otherOption.subCartItemId)
                    if (cartItem === undefined || subItem === undefined || !cartItem.isSameAs(subItem, subItems)) {
                        return false
                    }
                }
            }
        }
        return true
    }

    stripNonConsumerData() {
        this.product.stripNonConsumerData()
        delete this.legacy_options
        delete this.legacy_price
    }

    getSelectedVariantName(i18n: {locale: string}) {
        return `${this.product.name.getForI18n(i18n)}${this.selectedVariant.name.isEmpty() ? '' : ` - ${this.selectedVariant.name.getForI18n(i18n)}`}`
    }

    getFacebookPixelContent(i18n: {locale: string}) {
        return [
            {
                id: this.product.id,
                name: this.product.name.getForI18n(i18n),
                quantity: this.amount,
            },
            ...this.selectedVariantInfo.options.map(option => {
                return {
                    id: option.option.id,
                    name: option.option.name.getForI18n(i18n),
                    quantity: option.amount,
                }
            }),
        ]
    }
}
