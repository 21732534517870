import {
    AutoEncoder,
    field,
    StringDecoder,
} from '@simonbackx/simple-encoding'

export class OrderExternalIds extends AutoEncoder {

    @field({decoder: StringDecoder, optional: true})
    paynlTransactionId?: string
}
