import {AutoEncoder, EnumDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'

import {TBBrands} from './TBBrands'
import {TBEstablishment} from './TBEstablishment'

// I made all fields optional because they can save brand without entering userdetails
// We will check if we have all 3 filled in before importing products
export class TopBrandsLoginStruct extends AutoEncoder {
    @field({decoder: StringDecoder, optional: true})
    username?: string

    @field({decoder: StringDecoder, optional: true})
    password?: string

    @field({decoder: new EnumDecoder(TBBrands), optional: true})
    brand?: TBBrands

    @field({decoder: StringDecoder, version: 74, optional: true, upgrade: () => 'https://development.topbrands.be', defaultValue: () => 'https://development.topbrands.be'})
    apiUrl: string

    @field({decoder: TBEstablishment, nullable: true, version: 69})
    establishment: TBEstablishment | null = null

    get hasAllFieldsFilledIn(): boolean {
        const isNullUndefinedOrEmpty = (el: string | undefined | null) => {
            return el == null || el.length === 0
        }
        return !isNullUndefinedOrEmpty(this.username) && !isNullUndefinedOrEmpty(this.password) && !isNullUndefinedOrEmpty(this.brand) && !isNullUndefinedOrEmpty(this.apiUrl) && this.establishment != null
    }
}
