import {AutoEncoder, BooleanDecoder, field} from '@simonbackx/simple-encoding'

import {LProduct} from '../lightspeed/LProduct'

export class DiscountSyncedFields extends AutoEncoder {
    @field({decoder: BooleanDecoder, optional: true})
    use = true

    @field({decoder: BooleanDecoder, optional: true})
    from = true

    @field({decoder: BooleanDecoder, optional: true})
    to = true

    @field({decoder: BooleanDecoder, optional: true})
    enabled = true

    static getFieldTranslationKey(field: string) {
        // cast to get auto-suggested cases
        switch (field as Exclude<keyof DiscountSyncedFields, keyof AutoEncoder>) {
            case 'use':
                return 'common.fields.discounts.posSync.use'
            case 'from':
                return 'common.fields.discounts.posSync.from'
            case 'to':
                return 'common.fields.discounts.posSync.to'
            case 'enabled':
                return 'common.fields.discounts.posSync.enabled'
            default:
                console.error(`No translation found for field "${field}" in ProductSyncedFields`)
                return ''
        }
    }
}

export class DiscountMeta extends AutoEncoder {
    @field({decoder: DiscountSyncedFields, optional: true})
    syncedFields = DiscountSyncedFields.create({})

    @field({decoder: LProduct, nullable: true, optional: true})
    lightspeedL: LProduct | null = null

    get isExternal(): boolean {
        return this.lightspeedL !== null
    }
}
