import {
    ArrayDecoder,
    AutoEncoder,
    BooleanDecoder,
    Data,
    DateDecoder,
    EnumDecoder,
    field,
    IntegerDecoder,
    ObjectData,
    PartialWithoutMethods,
    StringDecoder,
} from '@simonbackx/simple-encoding'

import {Version} from '../index'
import {Address} from './Address'
import {Call} from './Call'
import {ConsumptionOptionType} from './ConsumptionOptions'
import {Customer} from './Customer'
import {ExtraCost, ExtraCostType} from './ExtraCost'
import {TranslatedString} from './Language'
import {OrderExternalIds} from './OrderExternalIds'
import {OrderItem} from './OrderItem'
import {POSSystemOrderSendStatus} from './POSSystemLink'
import {PaymentDetails} from './PaymentDetails'
import {PaymentMethod, PaymentMethodType} from './PaymentMethod'
import {PaymentProvider} from './PaymentProvider'
import {PaymentStatus} from './PaymentStatus'
import {PreparationLocation} from './PreparationLocation'
import {Table} from './Table'
import {TableCategory} from './TableCategory'
import {TransactionFees} from './TransactionFees'
import {Discount} from './discounts/Discount'
import {Currency} from '@dorst/enums'
import {isNullOrEmpty} from '@dorst/helpers'

export interface OrderProductionLocationStatus {
    delivered: boolean
    produced: boolean
    claimed: boolean
    printed: boolean
}

export interface ProductRecipe {
    name: TranslatedString
    recipe: TranslatedString
}

export interface PreparationLocationGroup {
    location: PreparationLocation | null
    items: Array<OrderItem>
    subItems: Array<OrderItem>
    status: OrderProductionLocationStatus
}

type preparationLocationContext = Record<'preparationLocations', Array<PreparationLocation>>

export abstract class OrderStructureBase extends AutoEncoder {
    @field({decoder: new EnumDecoder(Currency)})
    currency: Currency

    @field({decoder: IntegerDecoder, nullable: true})
    orderNumber: number | null = null

    /**
     * Returns all top-level items (not including possible sub-items that are linked to them)
     */
    @field({decoder: new ArrayDecoder(OrderItem), defaultValue: () => []})
    items: Array<OrderItem> = []

    /**
     * Returns all sub-items that are linked to parent cartItems
     * eg:
     * "Product pizzamenu (pizzaA+drinkA)" === mainItem (with option <Option>pizzaA and <Option>drinkA that have productId set to <Product>pizzaA.id & <Product>drinkA.id)
     * "pizzaA & drinkA" === subItems (these will have the cartItem.parentId set to mainItem.id)
     * Linked means that one of the options of the original mainItem references the linkedItem.product via mainItem.optiongroups.option.productLink
     * We will have 3 items in the order (1main & 2 linkedItems)
     */
    @field({decoder: new ArrayDecoder(OrderItem), optional: true})
    subItems: Array<OrderItem> = []

    /**
     * Returns all items in cart (including sub-items that are linked to parent cartItems)
     */
    get allItems(): Array<OrderItem> {
        return this.items.concat(this.subItems)
    }

    getAllShopLocationIds(shop: preparationLocationContext): Array<string> {
        return ['global', ...shop.preparationLocations.map(i => i.id)]
    }

    getLocationIdsInItems() {
        return this.items.map(el => {
            return el.product.preparationLocationId ?? 'global'
        })
    }

    /**
     * @param cartItemId - Id of the OrderItem you want to retrieve subItems for
     */
    getSubItemsForCartItemId(cartItemId: string): Array<OrderItem> {
        return this.subItems.filter(el => el.parentId === cartItemId)
    }

    @field({decoder: Customer})
    customer: Customer

    @field({decoder: Table})
    @field({
        decoder: Table, nullable: true, version: 52, downgrade: (o: Table | null): Table => {
            if (o === null) {
                // Pass a fake table to old frontends
                return Table.create({name: 'NONE', number: '/'})
            }
            return o
        },
    })
    table: Table | null = null

    @field({decoder: new ArrayDecoder(TableCategory), version: 43, upgrade: () => [], optional: true}) // PROBLEM WITH ORDER24: deployed version 43 from different commit, causing upgrade issue here, optional is added to fix this
    tableCategories: Array<TableCategory>

    @field({decoder: new EnumDecoder(PaymentMethodType)})
    @field({
        decoder: PaymentMethod, version: 41, nullable: true, upgrade: (old: PaymentMethodType | null) => {
            if (old === null) {
                return null
            }
            return PaymentMethod.create({type: old, transactionFees: TransactionFees.default(old, PaymentProvider.Paynl), provider: PaymentProvider.Paynl})
        }, downgrade: (n: PaymentMethod | null) => {
            if (n === null) {
                return null
            }
            return n.type
        },
    })
    paymentMethod: PaymentMethod

    @field({decoder: PaymentDetails, version: 44, nullable: true, upgrade: () => null})
    paymentDetails: PaymentDetails | null = null

    @field({decoder: new EnumDecoder(PaymentStatus)})
    paymentStatus: PaymentStatus

    // Status booleans
    @field({decoder: BooleanDecoder})
    produced: boolean

    @field({decoder: new ArrayDecoder(ExtraCost), version: 76, defaultValue: () => []})
    extraCosts: Array<ExtraCost>

    get inclusiveExtraCosts() {
        return this.extraCosts.filter(el => el.extraCostType === ExtraCostType.Inclusive)
    }

    get exclusiveExtraCosts() {
        return this.extraCosts.filter(el => el.extraCostType === ExtraCostType.Exclusive)
    }

    @field({decoder: BooleanDecoder})
    delivered: boolean

    @field({decoder: BooleanDecoder, version: 9})
    printed = false

    @field({decoder: IntegerDecoder})
    createdAt: number

    @field({decoder: IntegerDecoder})
    updatedAt: number

    @field({decoder: IntegerDecoder, version: 27, upgrade: () => null, nullable: true})
    paidAt: number | null = null

    @field({decoder: IntegerDecoder, version: 8, upgrade: () => 0})
    price: number

    @field({decoder: IntegerDecoder, version: 23, upgrade: () => 0})
    tip: number

    @field({decoder: Call, nullable: true, version: 32, upgrade: () => null})
    call: Call | null = null

    @field({decoder: IntegerDecoder, version: 35, upgrade: () => 1})
    callCount = 0

    @field({decoder: new EnumDecoder(POSSystemOrderSendStatus), optional: true})
    sendToPosStatus: POSSystemOrderSendStatus = POSSystemOrderSendStatus.None

    @field({decoder: IntegerDecoder, nullable: true, version: 44, upgrade: () => null})
    dependingOrderId: number | null = null

    @field({decoder: new EnumDecoder(ConsumptionOptionType), version: 52, upgrade: () => ConsumptionOptionType.DineIn})
    consumptionMode: ConsumptionOptionType = ConsumptionOptionType.DineIn

    @field({decoder: DateDecoder, version: 52, nullable: true})
    deliveryDate: Date | null = null

    @field({decoder: IntegerDecoder, nullable: true, optional: true})
    deliveryInterval: number | null = null

    @field({decoder: Address, version: 59, nullable: true, upgrade: () => null})
    deliveryAddress: Address | null = null

    @field({decoder: IntegerDecoder, version: 59, upgrade: () => 0})
    deliveryPrice = 0

    @field({decoder: StringDecoder, nullable: true, optional: true})
    deliveryCostPosProductId: string | null = null

    @field({decoder: Discount, nullable: true, optional: true})
    discount: Discount | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    description: string | null = null

    @field({decoder: StringDecoder})
    uuid: string

    static decode(data: Data): OrderBackoffice {
        return super.decode(data) as OrderBackoffice
    }

    canGetClaimed(deviceId: string, includeAlreadyClaimed: boolean = true): boolean {
        for (const item of this.items) {
            if (item.deviceId === null || (includeAlreadyClaimed && item.deviceId === deviceId)) {
                return true
            }
        }
        return false
    }

    isPartiallyClaimedBy(deviceId: string) {
        for (const item of this.items) {
            if (item.deviceId == deviceId) {
                return true
            }
        }
        return this.items.length == 0
    }

    getAmount(): number {
        return this.items.reduce((acc, curr) => acc + curr.amount, 0)
    }

    getTotalDiscount(): number | null {
        return this.discount?.calculateForPrice(this.cartPrice) ?? null
    }

    /**
     * Returns whether this order is delivered in the viewport of one or more production locations
     */
    isDeliveredFor(shop: preparationLocationContext, locationIds: Array<string> | null = null): boolean | null {
        if (locationIds === null) {
            return this.delivered
        }

        let found = false
        const ids = this.getAllShopLocationIds(shop)
        for (const item of this.items) {
            if (item.delivered && found) {
                continue
            }
            if (item.product.preparationLocationId === null || locationIds.includes(item.product.preparationLocationId) || !ids.includes(item.product.preparationLocationId)) {
                if (!item.delivered) {
                    return false
                }
                found = true
            }
        }

        if (found) {
            return true
        }
        return null
    }

    /**
     * Returns whether this order is produced partially in one location (a part of the order can get delivered already)
     */
    isPartiallyProducedAndReadyForDelivery(shop: preparationLocationContext) {
        if (this.produced) {
            return true
        }

        for (const location of shop.preparationLocations) {
            // Check if all produced
            if (this.isProducedFor(shop, [location.id]) && !this.isDeliveredFor(shop, [location.id])) {
                return true
            }
        }

        return false
    }

    /**
     * Returns whether this order is produced partially in one location (a part of the order can get delivered already)
     */
    isPartiallyDelivered(shop: preparationLocationContext) {
        if (this.delivered) {
            return true
        }

        for (const location of shop.preparationLocations) {
            // Check if all produced
            if (this.isDeliveredFor(shop, [location.id])) {
                return true
            }
        }

        return false
    }

    /**
     * Get production status for a single location id. Or null = all items without location or invalid location
     */
    getStatusFor(shop: preparationLocationContext, locationId: string | null): OrderProductionLocationStatus {
        let found = false
        let claimed = true
        let delivered = true
        let produced = true
        let printed = true
        const ids = this.getAllShopLocationIds(shop)
        for (const item of this.items) {
            if (
                item.product.preparationLocationId === locationId
                || (
                    locationId === null
                    && !isNullOrEmpty(item.product.preparationLocationId)
                    && !ids.includes(item.product.preparationLocationId)
                )
            ) {
                if (!item.produced) {
                    produced = false
                }
                if (!item.delivered) {
                    delivered = false
                }
                if (item.deviceId === null) {
                    claimed = false
                }
                if (!item.printed) {
                    printed = false
                }
                found = true
            }
        }

        return {
            delivered: found && delivered,
            produced: found && produced,
            claimed: found && claimed,
            printed: found && printed,
        }
    }

    /**
     * Returns whether this order is delivered in the viewport of one or more production locations
     */
    isProducedFor(shop: preparationLocationContext, locationIds: Array<string> | null = null): boolean | null {
        if (locationIds === null) {
            return this.produced
        }

        const ids = this.getAllShopLocationIds(shop)
        let found = false
        for (const item of this.items) {
            if (item.produced && found) {
                continue
            }
            const preparationLocationId = item.product.preparationLocationId ?? 'global'
            if (locationIds.includes(preparationLocationId) || !ids.includes(preparationLocationId)) {
                if (!item.produced) {
                    return false
                }
                found = true
            }
        }

        if (found) {
            return true
        }
        return null // unknown -> no item in these locations visible
    }

    /**
     * Returns whether all items in this order linked to the locations we want to show are "produced"
     */
    hasProductsFromLocationIds(shop: preparationLocationContext, locationIdsToDisplay: Array<string>): boolean {
        const ids = this.getAllShopLocationIds(shop)
        return this.items.some(item => {
              const preparationLocationId = item.product.preparationLocationId ?? 'global'
            // Only check items that are either set to a visible preparation location or locations that no longer exist.
            // Because we want void locations to count as if none was set (= display on "global").
            return locationIdsToDisplay.includes(preparationLocationId)
                || (preparationLocationId !== 'global' && !ids.includes(preparationLocationId))
        })
    }

    /**
     * Returns whether this order is printed in the viewport of one or more production locations
     */
    isPrintedFor(shop: preparationLocationContext, locationIds: Array<string> | null = null): boolean {
        if (this.printed) {
            return true
        }
        if (locationIds === null) {
            return this.printed
        }

        const ids = this.getAllShopLocationIds(shop)

        for (const item of this.items) {
            if (item.printed) {
                continue
            }
            if (
                (item.product.preparationLocationId === null || !ids.includes(item.product.preparationLocationId)) && locationIds.includes('global')
                || (item.product.preparationLocationId !== null && locationIds.includes(item.product.preparationLocationId))
            ) {
                if (!item.printed) {
                    return false
                }
            }
        }
        return true
    }

    /**
     *
     * @param preparationLocationContext - object with array of preparationLocations
     * @param ids - preparationlocation ids that are supposed to be shown/printed
     * @param returnNonMatching - you can also use the function to only show the items that do not belong to the locations passed in the ids prop.
     * @returns {Array<PreparationLocationGroup>}
     */
    groupByPreparationLocation(preparationLocationContext: preparationLocationContext, ids: Array<string> | null = null, returnNonMatching = false): Array<PreparationLocationGroup> {
        const data: Array<PreparationLocationGroup> = []
        let remaining = this.items.slice()

        for (const location of preparationLocationContext.preparationLocations) {
            const items: Array<OrderItem> = []

            remaining = remaining.filter((item) => {
                if (item.product.preparationLocationId === location.id) {
                    items.push(item)
                    return false
                }
                return true
            })

            if (ids !== null && !ids.includes(location.id)) {
                if (!returnNonMatching) {
                    continue
                }
            } else if (returnNonMatching) {
                continue
            }

            if (items.length > 0) {
                data.push({
                    location: location,
                    items: items,
                    subItems: items.flatMap(el => this.getSubItemsForCartItemId(el.id)),
                    status: this.getStatusFor(preparationLocationContext, location.id),
                })
            }
        }

        const hasGlobalGrouped = ids === null || ids.includes('global')
        if (remaining.length > 0 && hasGlobalGrouped != returnNonMatching) {
            data.push({
                location: null,
                items: remaining,
                subItems: remaining.flatMap(el => this.getSubItemsForCartItemId(el.id)),
                status: this.getStatusFor(preparationLocationContext, null),
            })
        }

        return data
    }

    get cartPrice() {
        return this.items.reduce((acc, current) => {
            return acc + current.getPrices(this.subItems).price
        }, 0)
    }

    getLowestVATOfProducts(): number {
        const vat: number = this.items.reduce((acc, next) => {
            if (next.product.VAT < acc) {
                return next.product.VAT
            }
            return acc
        }, Infinity)
        return vat === Infinity ? 21 : vat
    }

    get sortDate(): number {
        if (this.consumptionMode === ConsumptionOptionType.DineIn) {
            return this.paidAt ?? this.createdAt
        }
        return this.deliveryDate?.getTime() ?? 0
    }

    /**
     * Returns an array with all items + their subItems (in case of menu-products)
     */
    get bundledItems(): Array<{
        item: OrderItem
        subItems: Array<OrderItem>
    }> {
        return this.items.map(item => {
            return {
                item: item,
                subItems: this.getSubItemsForCartItemId(item.id),
            }
        })
    }

    /**
     * Returns Array of ProductRecipes which includes the productname and it's recipe.
     * Recipes are mostly used for take away orders that need some preperation actions before consuming
     * eg:
     * name: Frozen pizza hawai
     * recipe: Put in oven for 12mins @ 220 degrees
     * @returns {Array<ProductRecipe>}
     */
    get recipes(): Array<ProductRecipe> {
        const recipes: Array<ProductRecipe> = []
        for (const {item, subItems} of this.bundledItems) {
            if (!item.product.recipe.isEmpty()) {
                recipes.push({
                    name: item.product.name,
                    recipe: item.product.recipe,
                })
            }
            for (const subItem of subItems) {
                if (!subItem.product.recipe.isEmpty()) {
                    recipes.push({
                        name: subItem.product.name,
                        recipe: subItem.product.recipe,
                    })
                }
            }
        }
        return recipes
    }

    /**
     *
     * @param allowedIds - pass an array of ids from all allowed items
     * @returns {OrderItem[]}
     * Will return the main items that are included in the id array + all its subitems
     */
    filterItems(allowedIds: Array<string>): {items: Array<OrderItem>; subItems: Array<OrderItem>} {
        const returnVal: {items: Array<OrderItem>; subItems: Array<OrderItem>} = {
            items: [],
            subItems: [],
        }
        if (allowedIds.length === 0) {
            return returnVal
        }

        returnVal.items = this.items.filter(item => {
            return allowedIds!.includes(item.product.id)
        })
        returnVal.subItems = this.subItems.filter(item => {
            return isNullOrEmpty(item.parentId) ? false : allowedIds!.includes(item.parentId)
        })
        return returnVal
    }
}

export class OrderMeta extends AutoEncoder {
    @field({decoder: StringDecoder, optional: true})
    innovendMachine?: string
}

export class OrderBackoffice extends OrderStructureBase {

    /**
     * References to payment systems and POS systems.
     */
    @field({decoder: OrderExternalIds, nullable: true, optional: true})
    externalIds?: OrderExternalIds | null

    @field({decoder: IntegerDecoder})
    id: number

    /**
     * Extra information that we want to add to the printed ticket.
     */
    @field({decoder: OrderMeta, nullable: true, optional: true})
    meta: OrderMeta | null = null

    clone(): OrderBackoffice {
        return OrderBackoffice.decode(new ObjectData(this.encode({version: Version}), {version: Version}))
    }

    cloneConsumer(): OrderConsumer {
        return OrderConsumer.create(this.clone())
    }
}

export class OrderConsumer extends OrderStructureBase {
}

const originalOrderConsumerCreate = OrderConsumer.create.bind(OrderConsumer) as (
    input: PartialWithoutMethods<InstanceType<typeof OrderConsumer>>,
) => InstanceType<typeof OrderConsumer>
OrderConsumer.create = input => {
    const order: OrderConsumer = originalOrderConsumerCreate(input)

    if (order.paymentMethod.settings) {
        order.paymentMethod.settings.starnetIP = null
        order.paymentMethod.settings.apiKey = null
        order.paymentMethod.settings.shopIdentifier = null
    }

    for (const item of order.items) {
        delete (item.product as any).meta // TSUpgrade any
        for (const option of item.options) {
            delete option.option.meta
        }
    }

    for (const subItem of order.subItems) {
        delete (subItem.product as any).meta // TSUpgrade any
        for (const option of subItem.options) {
            delete option.option.meta
        }
    }

    order.paymentMethod.transactionFees = TransactionFees.create({})
    return order
}

export const OrderBackofficePatch = OrderBackoffice.patchType()
export const OrderConsumersPatch = OrderConsumer.patchType()
