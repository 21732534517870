
























































































































































import {Component, Mixins, Prop} from 'vue-property-decorator'

import {CartManager} from '../classes/CartManager'
import {ProductViewMixin} from '../mixins/ProductViewMixin'
import OptionGroupBox from './product/OptionGroupBox.vue'
import OptionGroupGroupBox from './product/OptionGroupGroupBox.vue'
import ProductVariantBox from './product/ProductVariantBox.vue'
import UpsellBoxItem from './product/UpsellBoxItem.vue'
import DRSTBoxItem from '@dorst/components/src/DRSTBoxItem.vue'
import DRSTFloatingFooter from '@dorst/components/src/DRSTFloatingFooter.vue'
import DRSTFooterBox from '@dorst/components/src/DRSTFooterBox.vue'
import DRSTNavigationBar from '@dorst/components/src/DRSTNavigationBar.vue'
import TransitionExpand from '@dorst/components/src/transitions/TransitionExpand.vue'
import {i18n} from '@dorst/frontend-translations'
import {CartItem, TranslatedString} from '@dorst/structures'

@Component({
    components: {
        DRSTNavigationBar,
        UpsellBoxItem,
        DRSTFloatingFooter,
        OptionGroupBox,
        OptionGroupGroupBox,
        TransitionExpand,
        ProductVariantBox,
        DRSTFooterBox,
        DRSTBoxItem,
    },
    filters: {
        i18n: (translatedStr: TranslatedString) => translatedStr.getForI18n(i18n),
    },
})

export default class EditCartItemOptionsView extends Mixins(ProductViewMixin) {
    // Check ProductViewMixin for extra props/functionality

    @Prop({default: false, required: false})
    editing!: boolean

    @Prop({default: false, required: false})
    alwaysSave!: boolean

    @Prop({default: false, required: false})
    volumeUpsellView!: boolean

    /*
    * We use this for single-select option-linked-products because we then offset the price with the default selected item.
    */
    @Prop({default: 0, required: false})
    priceOffset!: number

    @Prop({required: false})
    onSave: (cartItem: CartItem, subItems?: Array<CartItem>) => void

    optionProductChanged = false
    optionChanged() {
        this.optionProductChanged = true
    }

    get editCartItemName() {
        return !this.volumeUpsellView && this.editCartItem.product.variants.length > 1
            ? this.editCartItem.product.getNameForI18n(this.$i18n)
            : this.editCartItem.getSelectedVariantName(this.$i18n)
    }

    get hasChanged() {
        return this.optionProductChanged || !this.editCartItem.isSameAs(this.cartItem, CartManager.cart.subItems)
    }

    get calculatedPrice() {
        return this.editCartItem.getPrices(CartManager.cart.subItems).unitPrice - this.priceOffset
    }

    get shouldCloseWithoutSaving() {
        return !this.alwaysSave && this.editing && !this.hasChanged && !this.volumeUpsellView
    }

    get selectedVariantPrice() {
        return this.defaultPrice - this.priceOffset
    }

    /** Use absolute prices if we have the variants (volume upsell) so the right price is shown in the right top corner. */
    get showAbsolutePrices() {
        return this.volumeUpsellView || this.editCartItem.product.showAbsolutePrices
    }

    add() {
        if (!this.hasTriedToAdd) {
            this.hasTriedToAdd = true
        }
        if (this.optionAmountErrors.length) {
            const scrollEl = document.querySelector('.error-box')
            if (scrollEl) {
                scrollEl.scrollIntoView({behavior: 'smooth'})
            }
            return
        } else {
            if (!this.shouldCloseWithoutSaving) {
                this.onSave?.(this.editCartItem, this.editSubCartItems)
            }
            this.pop()
        }
    }
}
