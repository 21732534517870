import {ArrayDecoder, AutoEncoder, field, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {CartItem} from './CartItem'
import {I18n} from './I18n'

export class Cart extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    /**
     * Returns all top-level items (not including possible sub-items that are linked to them)
     */
    @field({decoder: new ArrayDecoder(CartItem), defaultValue: () => []})
    items: Array<CartItem> = []

    /**
     * Returns all sub-items that are linked to parent cartItems
     * eg:
     * "Product pizzamenu (pizzaA+drinkA)" === mainItem (with option <Option>pizzaA and <Option>drinkA that have productId set to <Product>pizzaA.id & <Product>drinkA.id)
     * "pizzaA & drinkA" === subitems (these will have the cartItem.parentId set to mainItem.id)
     * Linked means that one of the options of the original mainItem references the linkedItem.product via mainItem.optiongroups.option.productLink
     * We will have 3 items in the order (1main & 2 linkedItems)
     */
    @field({decoder: new ArrayDecoder(CartItem), optional: true})
    subItems: Array<CartItem> = []

    /**
     * Returns all items in cart (including sub-items that are linked to parent cartItems)
     */
    get allItems(): Array<CartItem> {
        return this.items.concat(this.subItems)
    }

    /**
     * @param id - The CartItem ID for which we want to get linked CartItems
     * @returns {CartItem[]}
     */
    getSubItemsForCartItemId(id: string): Array<CartItem> {
        return this.subItems.filter(el => el.parentId === id)
    }

    /**
     *
     * @param id - CartItem ID to fetch.
     * @returns {CartItem | undefined}
     */
    getSubItemById(id: string | null | undefined): CartItem | undefined {
        if (id == null) {
            return
        }
        return this.subItems.find(el => el.id === id)
    }

    getPrice(): number {
        return this.items.reduce((acc, current) => {
            return acc + current.getPrices(this.subItems).price
        }, 0)
    }

    getCount(): number {
        return this.items.reduce((acc, current) => {
            return acc + current.amount
        }, 0)
    }

    stripNonConsumerData() {
        this.allItems.forEach(item => {
            item.stripNonConsumerData()
        })
    }
}
