import {AutoEncoder, EnumDecoder, field, IntegerDecoder} from '@simonbackx/simple-encoding'

export enum DiscountMode {
    NONE = 'None',
    ABSOLUTE = 'Absolute',
    RELATIVE = 'Relative',
}

export class DiscountDetails extends AutoEncoder {
    @field({decoder: new EnumDecoder(DiscountMode), optional: true})
    mode: DiscountMode = DiscountMode.NONE

    // Absolute discount
    @field({decoder: IntegerDecoder, nullable: true, optional: true})
    absolute: number | null = null

    // Relative discount
    @field({decoder: IntegerDecoder, nullable: true, optional: true})
    relative: number | null = null
}
