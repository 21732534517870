




































































import {NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component, Mixins, Prop} from 'vue-property-decorator'

import DRSTBoxItem from '../DRSTBoxItem.vue'

@Component({
    components: {
        DRSTBoxItem,
    },
})
export default class SelectRow extends Mixins(NavigationMixin) {
    @Prop({})
    value!: string

    @Prop({default: false})
    disabled!: boolean

    @Prop({required: true})
    options!: Array<{text: string; value: any}>

    @Prop({default: ''})
    placeholder!: string

    @Prop()
    label?: string

    hasFocus = false

    id = `select-${new Date().getTime()}-${Math.floor(Math.random() * 1000000)}`

    get text() {
        return this.value
    }

    set text(value: string) {
        this.$emit('input', value)
    }

    onFocus() {
        this.hasFocus = true
    }

    blur() {
        this.hasFocus = false;
        (this.$refs.input as any)?.blur()
    }

    focus() {
        (this.$refs.input as any)?.focus()
    }
}
