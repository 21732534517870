import {DateTime} from 'luxon'

import type {CurrencyType} from '@dorst/enums'
import {Currency} from '@dorst/enums'
import {RequiredProperties} from '@dorst/helpers'

interface i18n {
    t: (key: string, options?: any) => string
}

/**
 * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#currencyDisplay
 */
export type CurrencyDisplay = 'code' | 'name' | 'narrowSymbol' | 'none' | 'symbol'
export type SignDisplay = 'auto' | 'always' | 'exceptZero' | 'never'

export interface PriceFormatterParams<Price extends number | null | undefined> {
    currencyCode: CurrencyType

    /**
     * How to display the currency.
     * - `code`: use the ISO currency code.
     * - `name`: use a localized currency name such as "dollar".
     * - `narrowSymbol`: use a narrow format symbol ("$100" rather than "US$100").
     * - `symbol`: use a localized currency symbol such as €.
     *
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#currencyDisplay
     */
    display?: CurrencyDisplay
    locale?: string | Array<string>
    price: Price

    /**
     * When to display the sign for the number. The default is `auto`.
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#signdisplay
     */
    signDisplay?: SignDisplay
}

export class Formatter {
    /**
     * We use static properties for currency and timezone properties. Because we will never combine multiple currencies or
     * timezones in one view.
     * During setup and loading of a shop we can set the currency and timezone once instead of having to keep track of it.
     */
    static currency: Currency = Currency.EUR

    static setCurrency(currency: Currency) {
        this.currency = currency
    }

    static capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
    }

    /**
     *
     * @param number 0 based index of the month (Januari = 0 / December = 11)
     * @param i18n
     */
    static month(number: number, i18n: i18n) {
        if (number < 0) {
            number = 0
        }
        if (number > 11) {
            number = 11
        }
        return i18n.t(`months.${number + 1}`)
    }

    static minutesToTime(time: number): string {
        const {hours, minutes} = this.minutesToHoursAndMinutes(time)
        return `${hours}:${String(minutes).padStart(2, '0')}`
    }

    static minutesToHoursAndMinutes(time: number): {hours: number; minutes: number} {
        const hours = Math.floor(time / 60)
        return {
            hours,
            minutes: time - hours * 60,
        }
    }

    static timeToMinutes(str: string): number {
        const split = str.split(':')
        let hours = parseInt(split[0])
        let minutes = parseInt(split[1] ?? '0')
        if (isNaN(hours)) {
            hours = 0
        }
        if (isNaN(minutes)) {
            minutes = 0
        }
        return hours * 60 + minutes
    }

    static jsDateToDayAndMinutes(date: Date, timezone): {day_month: string; minutes: number} {
        const dateTime = DateTime.fromJSDate(date).setZone(timezone)
        return {
            day_month: `${dateTime.day}_${dateTime.month}`,
            minutes: (dateTime.hour * 60) + dateTime.minute,
        }
    }

    static date(date: Date, timezone: string): string {
        const dateTime = DateTime.fromJSDate(date).setZone(timezone)
        return `${dateTime.day}/${String(dateTime.month).padStart(2, '0')}/${dateTime.year}`
    }

    static dateWithDay(date: Date, timezone: string): string {
        const dateTime = DateTime.fromJSDate(date).setZone(timezone)
        return `${dateTime.weekdayShort} ${dateTime.day}/${String(dateTime.month).padStart(2, '0')}/${dateTime.year}`
    }

    static dateWithDayShort(date: Date, timezone: string): string {
        const dateTime = DateTime.fromJSDate(date).setZone(timezone)
        return `${dateTime.weekdayShort}, ${String(dateTime.day).padStart(2, '0')}`
    }

    static dateWithDayMonthShort(date: Date, timezone: string): string {
        const dateTime = DateTime.fromJSDate(date).setZone(timezone)
        return `${dateTime.weekdayShort}, ${String(dateTime.day).padStart(2, '0')} ${dateTime.monthShort}`
    }

    static dateTime(date: Date, timezone: string): string {
        const dateTime = DateTime.fromJSDate(date).setZone(timezone)
        return `${dateTime.day}/${String(dateTime.month).padStart(2, '0')}/${dateTime.year} ${dateTime.hour}:${String(dateTime.minute).padStart(2, '0')}`
    }

    static dateTimeFilename(date: Date, timezone: string): string {
        const dateTime = DateTime.fromJSDate(date).setZone(timezone)
        return dateTime.toFormat(`yyyy-MM-dd'T'HH-mm`)
    }

    static dateTimeShort(date: Date, timezone: string): string {
        const dateTime = DateTime.fromJSDate(date).setZone(timezone)
        return `${dateTime.day}/${String(dateTime.month).padStart(2, '0')} ${dateTime.hour}:${String(dateTime.minute).padStart(2, '0')}`
    }

    static formatDate(
        date: Date | number | null | undefined,
        format: 'short' | 'shortDate' | 'medium' | 'withDay' = 'medium',
        timezone = 'local',
    ): string {
        if (date == null) {
            return ''
        }

        if (typeof date === 'number') {
            date = new Date(date)
        }

        switch (format) {
            case 'medium':
                return Formatter.dateTime(date, timezone)
            case 'short':
                return Formatter.dateTimeShort(date, timezone)
            case 'shortDate':
                return Formatter.date(date, timezone)
            case 'withDay':
                return Formatter.dateWithDay(date, timezone)
        }
    }

    static time(date: Date, timezone: string): string {
        const dateTime = DateTime.fromJSDate(date).setZone(timezone)
        return `${dateTime.hour}:${String(dateTime.minute).padStart(2, '0')}`
    }

    static price(input: RequiredProperties<PriceFormatterParams<number>, 'locale'>): string
    static price(input: RequiredProperties<PriceFormatterParams<null | undefined>, 'locale'>): null
    static price(input: RequiredProperties<PriceFormatterParams<number | null | undefined>, 'locale'>): string | null
    static price(
        {
            currencyCode,
            display,
            locale,
            price,
            signDisplay,
        }: RequiredProperties<PriceFormatterParams<number | null | undefined>, 'locale'>,
    ): string | null {
        if (price == null) {
            return null
        }

        if (display === 'none') {
            // Number formatted as currency without the currency symbol/name being displayed
            // https://stackoverflow.com/a/68550501/2512498
            // Use replace instead of formatToParts to support iOS >= 13.
            return new Intl
                .NumberFormat(
                    locale,
                    {
                        currency: currencyCode,
                        currencyDisplay: 'code',
                        signDisplay: signDisplay,
                        style: 'currency',
                    },
                )
                .format(price / 100)
                .replace(currencyCode, '')
                .trim()
        }

        return new Intl.NumberFormat(
            locale,
            {
                currency: currencyCode,
                currencyDisplay: display,
                signDisplay: signDisplay,
                style: 'currency',
            },
        ).format(price / 100)
    }

    static floatStringToCentInteger(str: string): number | null {
        // replace first comma with decimal
        str = str.replace(',', '.')
        // round number
        const number = Math.round(Number(str) * 100)
        return isNaN(number) ? null : number
    }

    static percentage(value: number, includePercentSign = true): string {
        value = value / 100
        return `${value.toFixed(2)}${includePercentSign ? ' %' : ''}`
    }

    /**
     * Rounds a given number to a specified position.
     *
     * @example
     * (1234, 2) => 1200
     * @example
     * (5678, 2) => 5700
     * @example
     * (1.234, 0) => 1
     * @example
     * (1.234, -1) => 1.2
     * @example
     * (5.678, -2) => 5.68
     * @param number The number to round.
     * @param position The position to round to.
     */
    static roundToDigit(number: number, position: number): number {
        const shifter = 10 ** position
        return Math.round(number / shifter) * shifter
    }

    static slug(name: string): string {
        name = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        return name.toLowerCase().replace(/ +/g, '').replace(/[^a-z0-9-]+/g, '-').replace(/^-+/, '').replace(/-+$/, '')
    }

    static uri(uri: string): string {
        return encodeURIComponent(decodeURIComponent(uri).replace(/\s+/g, '')).toLowerCase()
    }

    /**
     * Remove non a-Z 0-9 chars with spaces, points, comma's, /
     */
    static clean(name: string): string {
        name = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        return name.replace(/[^a-zA-Z0-9.,/]+/g, ' ').replace(/^ +/, '').replace(/ +$/, '')
    }

    /**
     * Remove non a-Z 0-9 and space chars
     */
    static readableClean(name: string): string {
        name = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        return name.replace(/[^a-zA-Z0-9 /]+/g, '').replace(/^ +/, '').replace(/ +$/, '')
    }

    static readableFilename(name: string): string {
        name = name.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        return name.replace(/[^a-zA-Z0-9-]+/g, '-').replace(/^-+/g, '').replace(/-+$/g, '')
    }

    static readableSlug(name: string): string {
        return this.readableClean(name).toLowerCase()
    }
}
