




































import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({
    model: {
        prop: 'checked',
        event: 'change',
    },
})
export default class Checkbox extends Vue {
    @Prop({default: '', type: String})
    name!: string

    @Prop({default: false})
    checked!: boolean

    @Prop({default: false})
    disabled!: boolean

    @Prop()
    inputId?: string

    get checkboxValue() {
        return this.checked
    }

    set checkboxValue(value) {
        this.$emit('change', value)
    }
}
