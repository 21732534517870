


















import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({})
/**
 * RemarkBlock can be used stand alone, within a DRSTBox or within the right slot of a DRSTBoxItem.
 */
export default class RemarkBlock extends Vue {
    @Prop({default: 'info', type: String})
    type!: 'error' | 'info' | 'success' | 'warning'

    @Prop({default: true, type: Boolean})
    useIcon: Boolean
}
