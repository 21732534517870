import {AutoEncoder, field, StringDecoder} from '@simonbackx/simple-encoding'

import {IDCheckout} from './IDCheckout'

export class OrderRequest extends AutoEncoder {
    @field({decoder: StringDecoder})
    returnUrl: string

    @field({decoder: IDCheckout})
    checkout: IDCheckout
}
