var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "drst-view" },
    [
      _c(
        "DRSTNavigationBar",
        { attrs: { title: _vm.$t("customer.cart.title") } },
        [
          _c("button", {
            staticClass: "button icon back",
            attrs: { slot: "left" },
            on: { click: _vm.exitCartView },
            slot: "left"
          }),
          _vm._v(" "),
          _vm.cart.items.length > 0
            ? _c("button", {
                staticClass: "button icon trash",
                attrs: { slot: "right" },
                on: { click: _vm.clear },
                slot: "right"
              })
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c(
        "main",
        [
          _c(
            "h1",
            {
              class: { "has-errors": _vm.hasValidationErrors },
              on: { click: _vm.scrollToErrors }
            },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.$t("customer.cart.title")) + " "
              ),
              _vm.hasValidationErrors
                ? _c("span", { staticClass: "icon error" })
                : _vm._e()
            ]
          ),
          _vm._v(" "),
          _vm.errors.length > 0
            ? _c(
                "DRSTBox",
                { attrs: { title: _vm.$t("customer.cart.changed") } },
                _vm._l(_vm.errors, function(error) {
                  return _c("DRSTBoxItem", { key: error.id }, [
                    _c("img", {
                      attrs: {
                        slot: "left",
                        src: require("@dorst/assets/icons/color/error.svg"),
                        alt: "Error icon"
                      },
                      slot: "left"
                    }),
                    _vm._v(
                      "\n                " +
                        _vm._s(error.human || error.message) +
                        "\n            "
                    )
                  ])
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("OfferCard"),
          _vm._v(" "),
          _c(
            "DRSTBox",
            _vm._l(_vm.cart.items, function(item) {
              return _c("CartItemRow", {
                key: "selected-upsell-" + item.id,
                attrs: {
                  "sub-items": _vm.getSubItemsForCartItemId(item),
                  selectable: item.hasVariants,
                  "cart-item": item
                },
                on: {
                  click: function($event) {
                    return _vm.clickedCartItem(item)
                  },
                  delete: function($event) {
                    return _vm.deleteItem(item)
                  },
                  change: function($event) {
                    return _vm.saveCart()
                  }
                }
              })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.shop.disclaimer
        ? _c("div", {
            staticClass: "disclaimer",
            domProps: { textContent: _vm._s(_vm.shop.disclaimer) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.hasValidationErrors
        ? _c(
            "RemarkBlock",
            {
              staticClass: "validation-errors",
              attrs: { type: "error" },
              on: { click: _vm.scrollToErrors }
            },
            _vm._l(_vm.validationErrors, function(err, i) {
              return _c("p", { key: i }, [
                _vm._v("\n            " + _vm._s(err) + "\n        ")
              ])
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.displayCartPrices
        ? _c(
            "DRSTFooterBox",
            [
              _vm.minOrderAmountNotReached
                ? _c("DRSTBoxItem", { staticClass: "min-order-amount" }, [
                    _c("p", { staticClass: "table-label" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.$t("customer.cart.minOrder")) +
                          "\n            "
                      )
                    ]),
                    _vm._v(" "),
                    _c("p", { attrs: { slot: "right" }, slot: "right" }, [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm._f("price")(_vm.minOrderAmount)) +
                          "\n            "
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.extraCosts.length > 0
                ? [
                    _vm._l(_vm.inclusiveExtraCosts, function(extracost) {
                      return _c(
                        "DRSTBoxItem",
                        { key: extracost.id, staticClass: "extra-cost" },
                        [
                          _c("p", { staticClass: "table-label" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.$t("customer.cart.inclusiveShort")) +
                                " " +
                                _vm._s(_vm.translateForI18n(extracost.name)) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "total-price",
                              attrs: { slot: "right" },
                              slot: "right"
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.formatExtraCostValue(extracost)) +
                                  "\n                "
                              )
                            ]
                          )
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c("DRSTBoxItem", [
                      _c("p", { staticClass: "table-label" }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("customer.cart.subtotal")))
                        ])
                      ]),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "total-price",
                          attrs: { slot: "right" },
                          slot: "right"
                        },
                        [
                          _c("strong", [
                            _vm._v(_vm._s(_vm._f("price")(_vm.cartPrice)))
                          ])
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.exclusiveExtraCosts, function(extracost) {
                      return _c(
                        "DRSTBoxItem",
                        { key: extracost.id, staticClass: "extra-cost" },
                        [
                          _c("p", { staticClass: "table-label" }, [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.translateForI18n(extracost.name)) +
                                "\n                "
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "total-price",
                              attrs: { slot: "right" },
                              slot: "right"
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.formatExtraCostValue(extracost)) +
                                  "\n                "
                              )
                            ]
                          )
                        ]
                      )
                    })
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("DRSTBoxItem", [
                _c("p", { staticClass: "table-label" }, [
                  _c("strong", [_vm._v(_vm._s(_vm.$t("customer.cart.total")))])
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "total-price",
                    attrs: { slot: "right" },
                    slot: "right"
                  },
                  [
                    _c("strong", [
                      _vm._v(
                        _vm._s(
                          _vm._f("price")(
                            _vm.cartPrice + _vm.exclusiveExtraCost
                          )
                        )
                      )
                    ])
                  ]
                )
              ])
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "DRSTFloatingFooter",
        { staticClass: "modal-fix" },
        [
          _c("SpinnerButton", { attrs: { loading: _vm.loading } }, [
            _c(
              "button",
              {
                staticClass: "button primary fill",
                class: { disabled: _vm.addButtonDisabled },
                attrs: {
                  disabled: !_vm.canCheckout,
                  "data-testid": "goto-checkout-button"
                },
                on: { click: _vm.startCheckout }
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.checkoutButtonText) +
                    "\n            "
                )
              ]
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }