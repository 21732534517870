var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "links" }, [
    _vm.hasWebsiteOnly
      ? _c("span", { staticClass: "visit" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm.$t("backoffice.externalLinks.visit")) +
              "\n    "
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.links.facebook
      ? _c("a", {
          staticClass: "button icon facebook",
          attrs: {
            href: _vm.links.facebook,
            target: "_blank",
            rel: "noopener noreferrer"
          }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.links.instagram
      ? _c("a", {
          staticClass: "button icon instagram",
          attrs: {
            href: _vm.links.instagram,
            target: "_blank",
            rel: "noopener noreferrer"
          }
        })
      : _vm._e(),
    _vm._v(" "),
    _vm.links.website
      ? _c("a", {
          staticClass: "button icon globe",
          attrs: {
            href: _vm.links.website,
            target: "_blank",
            rel: "noopener noreferrer"
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }