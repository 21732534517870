import applePayImg from '@dorst/assets/images/payment-methods/apple-pay.svg'
import bancontactImg from '@dorst/assets/images/payment-methods/bancontact.svg'
import digitinImg from '@dorst/assets/images/payment-methods/digitin.svg'
import googlePayImg from '@dorst/assets/images/payment-methods/google-pay.svg'
import idealImg from '@dorst/assets/images/payment-methods/ideal.svg'
import mastercardImg from '@dorst/assets/images/payment-methods/mastercard.svg'
import masterpassImg from '@dorst/assets/images/payment-methods/masterpass.svg'
import mbWayImg from '@dorst/assets/images/payment-methods/mbway.svg'
import multibancoImg from '@dorst/assets/images/payment-methods/multibanco.svg'
import payconiqImg from '@dorst/assets/images/payment-methods/payconiq.svg'
import paypalImg from '@dorst/assets/images/payment-methods/paypal.svg'
import ratepayImg from '@dorst/assets/images/payment-methods/ratepay.svg'
import samsungPayImg from '@dorst/assets/images/payment-methods/samsung-pay.svg'
import sepaImg from '@dorst/assets/images/payment-methods/sepa.svg'
import sofortImg from '@dorst/assets/images/payment-methods/sofort.svg'
import starnetImg from '@dorst/assets/images/payment-methods/starnet.svg'
import visaImg from '@dorst/assets/images/payment-methods/visa.svg'
import {PaymentMethod, PaymentMethodType} from '@dorst/structures'

export class PaymentMethodHelper {
    static getImages(method: PaymentMethod, hasPayconic = false): Array<string> {
        if (method.image) {
            return [method.image.getPathForSize(undefined, 30 * (window.devicePixelRatio ?? 1))]
        }

        switch (method.type) {
            case PaymentMethodType.ApplePay:
                return [applePayImg]
            case PaymentMethodType.Bancontact:
                return hasPayconic ? [bancontactImg] : [bancontactImg, payconiqImg]
            case PaymentMethodType.BancontactMobile:
                return [bancontactImg]
            case PaymentMethodType.CreditCard:
                return [mastercardImg, visaImg]
            case PaymentMethodType.Digitin:
                return [digitinImg]
            case PaymentMethodType.GooglePay:
                return [googlePayImg]
            case PaymentMethodType.IDeal:
                return [idealImg]
            case PaymentMethodType.Masterpass:
                return [masterpassImg]
            case PaymentMethodType.MBWay:
                return [mbWayImg]
            case PaymentMethodType.Multibanco:
                return [multibancoImg]
            case PaymentMethodType.Payconiq:
                return [payconiqImg]
            case PaymentMethodType.PayPal:
                return [paypalImg]
            case PaymentMethodType.PointOfSale:
                return []
            case PaymentMethodType.RatePay:
                return [ratepayImg]
            case PaymentMethodType.RatePayDirectDebit:
                return [ratepayImg]
            case PaymentMethodType.SamsungPay:
                return [samsungPayImg]
            case PaymentMethodType.SepaDirectDebit:
                return [sepaImg]
            case PaymentMethodType.Sofort:
                return [sofortImg]
            case PaymentMethodType.Starnet:
                return [starnetImg]
            case PaymentMethodType.VisaClickToPay:
                return [visaImg]
            default: {
            // compile error = not all caught
                const t: never = method.type
                throw new Error(`Unknown payment method: ${t}`)
            }
        }
    }
}
