import {ArrayDecoder, AutoEncoder, BooleanDecoder, EnumDecoder, field, NumberDecoder, ObjectData, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {Version} from '../index'
import {ConsumptionOptionType} from './ConsumptionOptions'
import {TranslatedString, TranslatedStringDecoder} from './Language'

export class Upsell extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: StringDecoder}) // product to suggest (=product.id)
    upsellId: string

    @field({decoder: StringDecoder})
    upsellGroupId: string

    @field({decoder: BooleanDecoder})
    enabled = true
}

export class UpsellGroup extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: NumberDecoder})
    shopId!: number

    @field({decoder: TranslatedStringDecoder})
    description: TranslatedString = new TranslatedString({})

    @field({decoder: new ArrayDecoder(Upsell)})
    upsells: Array<Upsell> = []

    @field({decoder: StringDecoder, nullable: true}) // suggest it for this product (=product.id)
    productId: string | null = null

    @field({decoder: StringDecoder, nullable: true}) // suggest it for all products in this category (=category.id)
    categoryId: string | null = null

    @field({decoder: new EnumDecoder(ConsumptionOptionType), version: 74, upgrade: () => ConsumptionOptionType.DineIn})
    consumptionMode: ConsumptionOptionType

    clone(): UpsellGroup {
        return UpsellGroup.decode(new ObjectData(this.encode({version: Version}), {version: Version}))
    }
}
