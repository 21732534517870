import {DateTime} from 'luxon'

const showFrom = DateTime.fromObject({
    month: 4,
    day: 1,
    hour: 9,
    minute: 30,
    zone: 'Europe/Brussels',
})
const showUntil = showFrom.plus({hours: 7})

/**
 * Register Easter egg by detecting the network.
 */
export async function registerEasterEgg(fn: Function) {
    if (new Date().getTime() > showUntil.toMillis()) {
        localStorage.removeItem('lastEggRegisterAttempt')
        return
    }

    const lastAttempt = localStorage.getItem('lastEggRegisterAttempt')
    const eggId = localStorage.getItem('eggId')

    if (eggId !== null) {
        scheduleEasterEgg(fn)
        return
    }

    if (lastAttempt !== null && DateTime.fromISO(lastAttempt).diffNow('hours').hours > -1) {
        // Maximum 1 attempt per hour
        return
    }

    const abortController = new AbortController()
    const timeoutId = setTimeout(() => {
        abortController.abort()
    }, 3000)
    try {
        const registerResult = await fetch('https://egg.dorst.app/register', {
            method: 'POST',
            signal: abortController.signal,
        })
        localStorage.setItem('eggId', await registerResult.text())
        scheduleEasterEgg(fn)
    } finally {
        localStorage.setItem('lastEggRegisterAttempt', new Date().toISOString())
        clearTimeout(timeoutId)
    }
}

/**
 * Schedule the Easter egg function to run on `showFrom` start or run it immediately if between
 * `showFrom` and `showUntil`.
 */
export function scheduleEasterEgg(fn: Function) {
    const now = new Date()
    if (now.getTime() > showUntil.toMillis()) {
        // Time interval has passed
        return
    } else if (now.getTime() > showFrom.toMillis()) {
        fn()
    } else {
        setTimeout(fn, showFrom.diffNow('milliseconds').milliseconds)
    }
}

let sausageStyles: HTMLStyleElement | undefined
export function addSausageToDOM() {
    let container: HTMLDivElement | undefined = document.createElement('div')
    let outer: HTMLDivElement | undefined = document.createElement('div')
    let inner: HTMLDivElement | undefined = document.createElement('div')
    let rope: HTMLImageElement | undefined = document.createElement('img')
    let sausage: HTMLImageElement | undefined = document.createElement('img')

    if (sausageStyles === undefined) {
        sausageStyles = document.createElement('style')
        document.head.appendChild(sausageStyles)
        sausageStyles.innerHTML = `
            .wust-container {
                position: fixed;
                left: 0;
                top: 0;
                width: 100vw;
                height: 100vh;
                z-index: 999;
                overflow: hidden;
                transform: translateZ(0);
            }
            .wust-outer {
                position: absolute;
                top: min(100px, max(-100px, -8.62vh));
                left: 50%;
                transform: translateX(-50%);
                will-change: transform;
            }
            .wust-outer.fall {
                animation: fall forwards ease-in 1s;
            }
            .wust-inner {
                transform: rotate(-20deg);
                transform-origin: min(146px, max(96px, 10.138vw)) 0;
                animation: rope-swing infinite ease-in-out 3s;
                filter: drop-shadow(5px 5px 10px rgba(0,0,0,.4));
            }
            .wust-rope {
                width: min(172px, max(114px, 11.94vw));
            }
            .wust-sausage {
                position: absolute;
                top: calc(100% - 40px);
                width: min(90px, max(60px, 6.25vw));
                left: min(113px, max(74px, 7.84vw));
                z-index: -1;
                transform: rotate(-20deg);
                transform-origin: 60% 0;
                animation: sausage-swing infinite ease-in-out 3s;
            }
            @keyframes rope-swing {
                0% {
                    transform: rotate(-20deg);
                }
                50% {
                    transform: rotate(20deg);
                }
                100% {
                    transform: rotate(-20deg);
                }
            }
            @keyframes sausage-swing {
                0% {
                    transform: rotate(-10deg);
                }
                50% {
                    transform: rotate(10deg);
                }
                100% {
                    transform: rotate(-10deg);
                }
            }
            @keyframes fall {
                0% {
                    transform: translateY(0);
                }
                100% {
                    transform: translateY(200vh);
                }
            }
        `
    }

    container.classList.add('wust-container')
    outer.classList.add('wust-outer')
    inner.classList.add('wust-inner')
    rope.classList.add('wust-rope')
    sausage.classList.add('wust-sausage')

    rope.src = 'https://dorst.ams3.digitaloceanspaces.com/assets/easter-egg/rope.png'
    sausage.src = 'https://dorst.ams3.digitaloceanspaces.com/assets/easter-egg/sausage.png'

    inner.appendChild(rope)
    inner.appendChild(sausage)
    outer.appendChild(inner)
    container.appendChild(outer)
    document.body.appendChild(container)

    container.addEventListener('click', () => {
        outer?.classList.add('fall')
        // cleanup
        setTimeout(() => {
            if (container !== undefined) {
                document.body.removeChild(container)
            }
            if (sausageStyles !== undefined) {
                document.head.removeChild(sausageStyles)
            }
            sausageStyles = container = outer = inner = rope = sausage = undefined
        }, 1000)
    })
}
