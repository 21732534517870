var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { appear: "", name: "show" } }, [
    _c(
      "div",
      { staticClass: "context-menu-container", on: { click: _vm.pop } },
      [
        _c(
          "div",
          {
            key: _vm.top + "-" + _vm.isRtl + "-" + _vm.inlineStart,
            ref: "context",
            staticClass: "context-menu",
            class: {
              scrollable: _vm.scrollable,
              "full-height": _vm.fullHeight
            },
            style:
              ((_obj = { top: _vm.top + "px" }),
              (_obj[_vm.isRtl ? "right" : "left"] = _vm.inlineStart + "px"),
              (_obj.opacity = _vm.initialized ? 1 : 0),
              _obj),
            attrs: { oncontextmenu: "return false;" },
            on: {
              click: function($event) {
                $event.stopPropagation()
              }
            }
          },
          [_vm._t("default")],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }