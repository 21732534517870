import {AutoEncoder, BooleanDecoder, EnumDecoder, field, IntegerDecoder, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {ConsumptionOptionType} from './ConsumptionOptions'

export class TableQRInfo extends AutoEncoder {
    @field({decoder: StringDecoder})
    path: string

    @field({decoder: StringDecoder})
    pathName: string

    @field({decoder: StringDecoder})
    tableNumber: string

    @field({decoder: StringDecoder})
    type: 'category' | 'table' | 'shop'

    @field({decoder: StringDecoder, optional: true})
    posId?: string

    @field({decoder: StringDecoder})
    url: string
}

export class Table extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string

    @field({decoder: StringDecoder})
    name = ''

    @field({decoder: BooleanDecoder, version: 43, optional: true}) // PROBLEM WITH ORDER24: deployed version 43 from different commit, causing upgrade issue here, optional is added to fix this
    enabled = true

    @field({decoder: IntegerDecoder})
    @field({decoder: StringDecoder, version: 6, upgrade: (int) => String(int)})
    number = '1'

    @field({decoder: StringDecoder})
    color = '#0057ff'

    /**
     * For which consumption option this should be available
     */
    @field({decoder: new EnumDecoder(ConsumptionOptionType), version: 63})
    type = ConsumptionOptionType.DineIn
}

export const TablePatch = Table.patchType()
