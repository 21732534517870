import {AutoEncoder, field, IntegerDecoder, StringDecoder} from '@simonbackx/simple-encoding'
import {SimpleError} from '@simonbackx/simple-errors'

export class UntillLogin extends AutoEncoder {
    @field({decoder: StringDecoder})
    host = ''

    @field({decoder: StringDecoder})
    port = ''

    @field({decoder: StringDecoder})
    database = ''

    @field({decoder: StringDecoder})
    username = ''

    @field({decoder: StringDecoder})
    password = ''

    @field({decoder: IntegerDecoder, nullable: true, defaultValue: () => null})
    areaId: number | null

    verify() {
        if (this.host === '' || this.port === '' || this.database === '' || this.username === '') {
            throw new SimpleError({code: 'invalid_credentials', message: 'The given credentials are not valid.'})
        }
    }
}

export const UntillLoginPatch = UntillLogin.patchType()
