import {ComponentWithProperties, NavigationMixin} from '@simonbackx/vue-app-navigation'
import {Component} from 'vue-property-decorator'

import {CartManager} from '../classes/CartManager'
import {ServerManager} from '../classes/ServerManager'
import PaymentSelectionView from '../views/PaymentSelectionView.vue'
import TableSelectionView from '../views/TableSelectionView.vue'
import TimeSlotSelectionView from '../views/TimeSlotSelectionView.vue'
import {ConsumptionOptionType, ShopTypeHelper, Table, TableCategory} from '@dorst/structures'

@Component
export class CheckoutMixin extends NavigationMixin {
    enableVoiceNotifications = ServerManager.shop.enableVoiceNotifications

    shouldAskUserDetails() {
        return this.nameRequired
            || this.emailRequired
            || this.phoneRequired
            || this.addressRequired
    }

    get nameRequired() {
        return ServerManager.shop.enableName
    }

    get phoneRequired() {
        return ServerManager.shop.enablePhone
    }

    get emailRequired() {
        if (
            ServerManager.shop.enableEmail === true
            || (this.isDineInMode && ServerManager.shop.consumptionOptions.dineIn.enableConfirmationEmail)
            || (this.isTakeAwayMode && ServerManager.shop.consumptionOptions.takeAway.enableConfirmationEmail)
            || (this.isDeliveryMode && ServerManager.shop.consumptionOptions.delivery.enableConfirmationEmail)
        ) {
            return true
        }

        if (ServerManager.shop.enableEmail === false) {
            return false
        }

        // default behaviour (only for delivery or take away)
        return this.isTakeAwayMode || this.isDeliveryMode
    }

    get addressRequired(): boolean {
        return this.isDeliveryMode
    }

    get isTableMode(): boolean {
        return ShopTypeHelper.isTableMode(ServerManager.shop.type)
    }

    get isNextTimeSlot() {
        return this.isDeliveryMode || (this.isTakeAwayMode && (!ServerManager.shop.consumptionOptions.takeAway.useTables || CartManager.didScan))
    }

    get dineInTables() {
        return ServerManager.shop.tables.filter(t => t.type === ConsumptionOptionType.DineIn)
    }

    get isDineInMode(): boolean {
        return CartManager.checkout.consumptionMode == ConsumptionOptionType.DineIn
    }

    get isTakeAwayMode(): boolean {
        return CartManager.checkout.consumptionMode == ConsumptionOptionType.TakeAway
    }

    get isDeliveryMode(): boolean {
        return CartManager.checkout.consumptionMode == ConsumptionOptionType.Delivery
    }

    get type() {
        return CartManager.checkout.consumptionMode
    }

    get canSkipTableSelection() {
        return this.isDineInMode && this.dineInTables.length === 1
    }

    editTable(returnToDetails = true, component: NavigationMixin | null = null) {
        (component ?? this).show(new ComponentWithProperties(TableSelectionView, {
            callback: (_, component: NavigationMixin) => {
                if (returnToDetails) {
                    component.pop()
                } else if (this.isTakeAwayMode && ServerManager.shop.consumptionOptions.takeAway.useTables) {
                    this.showTimeSlotView(component)
                } else {
                    this.showPaymentSelectionView(component)
                }
            },
            buttonText: returnToDetails
                ? this.$t('general.save')
                : this.isTakeAwayMode && ServerManager.shop.consumptionOptions.takeAway.useTables
                    ? this.$t('customer.details.selectTimeSlotButton')
                    : this.$t('customer.details.continueToPaymentButton'),
        }))
    }

    showTimeSlotView(component: NavigationMixin | null = null) {
        (component ?? this).show(new ComponentWithProperties(TimeSlotSelectionView, {
            callback: (component: NavigationMixin) => {
                this.showPaymentSelectionView(component)
            },
            buttonText: this.$t('customer.details.continueToPaymentButton'),
        }))
    }

    getTableCategories(categories: Array<TableCategory>, table: Table, catStack: Array<TableCategory> = []): {found: boolean; categories: Array<TableCategory>} {
        let found = false
        const ret = [...catStack, ...categories.reduce((prev, next) => {
            if (found) {
                return prev
            }
            prev.push(next)
            if (next.tableCategories.length) {
                const catResult = this.getTableCategories(next.tableCategories, table, prev)
                if (catResult.found) {
                    found = true
                    prev = catResult.categories
                }
            } else if (next.tables.includes(table)) {
                found = true
            }
            if (!found) {
                return []
            }
            return prev
        }, [] as Array<TableCategory>)]

        return {
            found: found,
            categories: ret,
        }
    }

    autoSelectTableAndPay(table: Table, component: NavigationMixin | null = null) {
        const {categories} = this.getTableCategories(ServerManager.shop.tableCategories, table)
        CartManager.setTableCategories(categories)
        CartManager.setTable(table)
        this.showPaymentSelectionView(component)
    }

    showPaymentSelectionView(component: NavigationMixin | null = null) {
        (component ?? this).show(new ComponentWithProperties(PaymentSelectionView))
    }
}
