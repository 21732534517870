import {ServerManager} from '../classes/ServerManager'
import {formatPrice} from '@dorst/frontend-helpers'
import {PriceFormatterParams} from '@dorst/validation'

interface PriceFormatterExtendedConsumerOptions {

    /**
     * If the shop has `enableHidePrices` enabled, setting this to true will force the displaying of
     * the price.
     */
    forceDisplay?: boolean
}

export type PriceHelperOptions<Price extends number | null | undefined = number | null | undefined> =
    Partial<PriceFormatterParams<Price>> & PriceFormatterExtendedConsumerOptions

export class PriceHelper {
    static format(input: PriceHelperOptions<number>): string
    static format(input: PriceHelperOptions<null | undefined>): null
    static format(input: PriceHelperOptions): string | null {
        const {enableHidePrices} = ServerManager.shop
        if (enableHidePrices && input.forceDisplay !== true) {
            return ''
        }

        return formatPrice({
            currencyCode: ServerManager.shop.currency,
            price: input.price,
            ...input,
        })
    }
}
