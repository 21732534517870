import {ArrayDecoder, AutoEncoder, BooleanDecoder, EnumDecoder, field, IntegerDecoder, NumberDecoder, StringDecoder} from '@simonbackx/simple-encoding'

import {UOrderItemType} from './UOrderItemType'

export class UPrice extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    articleId: number

    @field({decoder: IntegerDecoder})
    priceId: number

    @field({decoder: NumberDecoder})
    amount: number

    @field({decoder: NumberDecoder, optional: true})
    vat: number

}

export class UProductOptions extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    optionId: number

    @field({decoder: BooleanDecoder})
    compose: boolean

    @field({decoder: IntegerDecoder})
    maxQuantity: number
}

export class UProduct extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    id: number

    @field({decoder: StringDecoder})
    name: string

    @field({decoder: IntegerDecoder})
    number: number

    @field({decoder: new ArrayDecoder(IntegerDecoder), optional: true, defaultValue: () => []})
    saleAreaIds: Array<number>

    @field({decoder: new ArrayDecoder(UPrice), optional: true, defaultValue: () => []})
    prices: Array<UPrice>

    @field({decoder: IntegerDecoder})
    departmentId: number

    @field({decoder: new ArrayDecoder(UProductOptions), optional: true, defaultValue: () => []})
    articleOptions: Array<UProductOptions>

    @field({decoder: BooleanDecoder})
    menu: boolean

    @field({decoder: BooleanDecoder})
    manualPrice: boolean

    @field({decoder: BooleanDecoder})
    active: boolean

    @field({decoder: BooleanDecoder})
    promo: boolean

    @field({decoder: StringDecoder})
    hqId: string

    @field({decoder: IntegerDecoder})
    courseId: number

    @field({decoder: BooleanDecoder})
    dailyStockActive: boolean

    @field({decoder: IntegerDecoder})
    dailyStockQuantity: number

    @field({decoder: StringDecoder})
    info: string

    @field({decoder: IntegerDecoder})
    plu: number

    @field({decoder: IntegerDecoder, optional: true})
    freeOptionId?: number

    @field({decoder: new EnumDecoder(UOrderItemType), optional: true, defaultValue: () => UOrderItemType.Normal})
    type = UOrderItemType.Normal
}
