

































import {Component, Prop, Vue} from 'vue-property-decorator'

@Component({})
export default class Stepper extends Vue {
    @Prop({default: 1})
    minimum!: number

    @Prop({default: null})
    maximum!: number | null

    @Prop({default: 1})
    step!: number

    @Prop({default: 1})
    value!: number

    @Prop({default: false})
    disabled!: boolean

    @Prop({default: false})
    hasVisibleValue!: boolean

    @Prop({default: false})
    disableOnMaxOrMin!: boolean

    get checkboxValue() {
        return this.value
    }

    set checkboxValue(value) {
        this.$emit('input', value)
    }

    get decrementButtonDisabled() {
        if (this.disabled) {
            return true
        }

        if (this.disableOnMaxOrMin) {
            return this.minimum === this.checkboxValue || this.minimum > this.checkboxValue - this.step
        }

        return false
    }

    get incrementButtonDisabled() {
        if (this.disabled) {
            return true
        }

        if (Number.isInteger(this.maximum) && this.disableOnMaxOrMin) {
            return this.maximum === this.checkboxValue || this.maximum! < this.checkboxValue + this.step
        }

        return false
    }

    plus() {
        if (this.disabled) {
            return
        }
        if (this.maximum) {
            this.checkboxValue = Math.min(this.maximum, this.checkboxValue + this.step)
        } else {
            this.checkboxValue = this.checkboxValue + this.step
        }
        this.$emit('plus')
    }

    minus() {
        if (this.disabled) {
            return
        }
        this.checkboxValue = Math.max(this.minimum, this.checkboxValue - this.step)
        this.$emit('minus')
    }
}
