var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.elementName,
    { tag: "component", staticClass: "drst-box", on: { click: _vm.onClick } },
    [
      _c(
        "div",
        {
          staticClass: "drst-box-item",
          class: { "right-stack": _vm.rightStack, selectable: _vm.selectable }
        },
        [
          _c("div", { staticClass: "left" }, [_vm._t("left")], 2),
          _vm._v(" "),
          _c("div", { staticClass: "main" }, [
            _c("div", [
              _c("div", { staticClass: "middle" }, [_vm._t("default")], 2),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _vm._t("right"),
                  _vm._v(" "),
                  _vm.showChevron
                    ? _c("span", {
                        staticClass: "icon arrow-down-simple",
                        class: {
                          reversed: _vm.isExpanded
                        }
                      })
                    : _vm._e()
                ],
                2
              )
            ]),
            _vm._v(" "),
            !_vm.isExpanded ? _c("hr") : _vm._e()
          ])
        ]
      ),
      _vm._v(" "),
      _c("TransitionExpand", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isExpanded,
                expression: "isExpanded"
              }
            ],
            staticClass: "dorst-box-item-expansion"
          },
          [_vm._t("expansion")],
          2
        )
      ]),
      _vm._v(" "),
      _vm.isExpanded ? _c("hr", { staticClass: "expanded-hr" }) : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }