import {AutoEncoder, Data, EnumDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'
import {SimpleError, SimpleErrors} from '@simonbackx/simple-errors'

import {I18n} from './I18n'
import {Phone} from '@dorst/helpers'
import {Validator} from '@dorst/validation'

export enum CustomerType {
    Consumer = 'consumer',
    Kiosk = 'kiosk',
    Waiter = 'waiter',
}

export class Customer extends AutoEncoder {
    @field({decoder: StringDecoder, field: 'name'})
    @field({
        decoder: StringDecoder, version: 38,
        upgrade: function (old: string) {
            return old.split(' ')[0]
        },
        downgrade: function (this: Customer) {
            return this.name
        },
    })
    firstName: string = ''

    @field({decoder: StringDecoder, field: 'name'})
    @field({
        decoder: StringDecoder, version: 38,
        upgrade: function (old: string) {
            const firstName = old.split(' ')[0]
            return old.substr(firstName.length + 1)
        },
        downgrade: function (this: Customer) {
            return this.name
        },
    })
    lastName: string = ''

    @field({decoder: StringDecoder, nullable: true})
    phone: string | null = null

    @field({decoder: StringDecoder, nullable: true, version: 52})
    email: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    locale: string | null = null

    @field({decoder: new EnumDecoder(CustomerType), nullable: true, optional: true})
    type: CustomerType | null = null

    get name() {
        return `${this.firstName} ${this.lastName}`
    }

    set name(name: string) {
        this.firstName = name.split(' ')[0]
        this.lastName = name.substr(this.firstName.length + 1)
    }

    static decode(data: Data): Customer {
        return super.decode(data) as Customer
    }

    // Throws errors if something is invalid
    async validate(nameRequired = false, phoneRequired = false, emailRequired = false, lastNameRequired = false, country = 'BE', i18n: I18n) {
        const errors = new SimpleErrors()

        if (nameRequired) {
            if (this.firstName.length == 0) {
                errors.addError(new SimpleError({
                    code: 'invalid_field',
                    message: 'This field is empty',
                    human: i18n.t('consumer.validationErrors.emptyFirstName'),
                    field: 'firstName',
                }))
            } else if (this.firstName.length < 2) {
                errors.addError(new SimpleError({
                    code: 'invalid_field',
                    message: 'This field is too short',
                    human: i18n.t('consumer.validationErrors.firstNameTooShort'),
                    field: 'firstName',
                }))
            } else if (this.firstName.length > 50) {
                errors.addError(new SimpleError({
                    code: 'invalid_field',
                    message: 'This field is too long',
                    human: i18n.t('consumer.validationErrors.firstNameTooLong'),
                    field: 'firstName',
                }))
            }

            if (lastNameRequired) {
                if (this.lastName.length == 0) {
                    errors.addError(new SimpleError({
                        code: 'invalid_field',
                        message: 'This field is empty',
                        human: i18n.t('consumer.validationErrors.emptyLastName'),
                        field: 'lastName',
                    }))
                } else if (this.lastName.length < 2) {
                    errors.addError(new SimpleError({
                        code: 'invalid_field',
                        message: 'This field is too short',
                        human: i18n.t('consumer.validationErrors.lastNameTooShort'),
                        field: 'lastName',
                    }))
                } else if (this.lastName.length > 50) {
                    errors.addError(new SimpleError({
                        code: 'invalid_field',
                        message: 'This field is too long',
                        human: i18n.t('consumer.validationErrors.lastNameTooLong'),
                        field: 'lastName',
                    }))
                }
            }
        }

        /** Phone is required */
        if (phoneRequired && (!this.phone || this.phone.length == 0)) {
            errors.addError(new SimpleError({
                code: 'invalid_field',
                message: 'This field is empty',
                human: i18n.t('consumer.validationErrors.emptyPhoneNumber'),
                field: 'phone',
            }))
        }

        /** If we have a Phonenumber it should be valid */
        if (this.phone && this.phone.length > 0) {
            try {
                this.phone = await Phone.format(this.phone)
                if (!await Phone.validate(this.phone, country)) {
                    throw 'invalid'
                }
            } catch (error) {
                errors.addError(new SimpleError({
                    code: 'invalid_field',
                    message: 'This phone number is invalid',
                    human: i18n.t('consumer.validationErrors.invalidPhoneNumber'),
                    field: 'phone',
                }))
            }
        }

        if (emailRequired && (!this.email || this.email.length == 0)) {
            errors.addError(new SimpleError({
                code: 'invalid_field',
                message: 'This field is empty',
                human: i18n.t('consumer.validationErrors.emptyEmailAddress'),
                field: 'email',
            }))
        }

        if (this.email && this.email.length > 0 && !Validator.email(this.email)) {
            errors.addError(new SimpleError({
                code: 'invalid_field',
                message: 'This email address is invalid',
                human: i18n.t('consumer.validationErrors.invalidEmailAddress'),
                field: 'email',
            }))
        }

        if (errors.errors.length) {
            throw new SimpleError({
                code: 'invalid_fields',
                message: 'errors',
                human: errors.errors.map((e) => e.getHuman()).join(', '),
            })
        }
    }
}
