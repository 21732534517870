var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DRSTBoxItem",
    { attrs: { selectable: true }, on: { click: _vm.clickedItem } },
    [
      _vm.product.images.length > 0
        ? _c("div", {
            staticClass: "product-photo-placeholder",
            style: { backgroundImage: "url(" + _vm.imagePath + ")" },
            attrs: { slot: "left" },
            slot: "left"
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "product-detail" }, [
        _c("h3", [_vm._v(_vm._s(_vm.name))]),
        _vm._v(" "),
        _vm.availabilityTexts.length > 0
          ? _c(
              "div",
              { staticClass: "availability-text" },
              _vm._l(_vm.availabilityTexts, function(availabilityText, index) {
                return _c(
                  "p",
                  { key: _vm.cartItem.id + "_" + index + "_availabilityText" },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(availabilityText) +
                        "\n            "
                    )
                  ]
                )
              }),
              0
            )
          : _c("p", { staticClass: "description" }, [
              _vm._v("\n            " + _vm._s(_vm.description) + "\n        ")
            ]),
        _vm._v(" "),
        _c("p", { staticClass: "price" }, [
          _vm._v("\n            " + _vm._s(_vm.priceDescription) + "\n        ")
        ])
      ]),
      _vm._v(" "),
      _c("button", {
        staticClass: "button icon plus",
        attrs: { slot: "right" },
        slot: "right"
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }