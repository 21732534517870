import {Decoder} from '@simonbackx/simple-encoding'

import {ServerManager} from '../classes/ServerManager'
import {
    DistanceMatrixRequest,
    DistanceMatrixResponse,
    GeoCodingRequest,
    GeoCodingResponse,
    GisAutocompleteDetailRequest,
    GisAutocompleteDetailResponse,
    GisAutocompleteRequest,
    GisAutocompleteResponse,
} from '@dorst/structures'

export async function gisAutocomplete(request: GisAutocompleteRequest): Promise<GisAutocompleteResponse> {
    const {data} = await ServerManager.getServer()
        .request({
            method: 'POST',
            path: '/consumer/gis/autocomplete',
            body: request,
            decoder: GisAutocompleteResponse as Decoder<GisAutocompleteResponse>,
        })

    return data
}

export async function gisAutocompleteDetails(request: GisAutocompleteDetailRequest): Promise<GisAutocompleteDetailResponse> {
    const {data} = await ServerManager.getServer()
        .request({
            method: 'POST',
            path: '/consumer/gis/autocomplete/details',
            body: request,
            decoder: GisAutocompleteDetailResponse as Decoder<GisAutocompleteDetailResponse>,
        })

    return data
}

export async function googleGeoCoding(request: GeoCodingRequest): Promise<GeoCodingResponse> {
    const {data} = await ServerManager.getServer()
        .request({
            method: 'POST',
            path: '/consumer/gis/geocode',
            body: request,
            decoder: GeoCodingResponse as Decoder<GeoCodingResponse>,
        })

    return data
}

export async function googleDistanceMatrix(request: DistanceMatrixRequest): Promise<DistanceMatrixResponse> {
    const {data} = await ServerManager.getServer()
        .request({
            method: 'POST',
            path: '/consumer/gis/distancematrix',
            body: request,
            decoder: DistanceMatrixResponse as Decoder<DistanceMatrixResponse>,
        })

    return data
}
