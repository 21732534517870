import {ArrayDecoder, AutoEncoder, BooleanDecoder, DateDecoder, EnumDecoder, field, IntegerDecoder, NumberDecoder, StringDecoder} from '@simonbackx/simple-encoding'
import {v4 as uuidv4} from 'uuid'

import {WeekDay} from './WeekDay'

export enum PreparationTimeType {
    TIME = 'time',
    DAYTIME = 'dayTime',
    DATETIME = 'dateTime',
}

interface i18n {
    t: (key: string, options?: any) => string
}
export class PreparationTimeTypeHelper {
    static getLabel(type: PreparationTimeType, i18n: i18n) {
        switch (type) {
            case PreparationTimeType.TIME:
                return i18n.t('backoffice.settings.takeAway.prepTimeLabel')
            case PreparationTimeType.DAYTIME:
                return i18n.t('backoffice.settings.takeAway.prepDayTimeLabel')
            case PreparationTimeType.DATETIME:
                return i18n.t('backoffice.settings.takeAway.prepDayTimeLabel')
            default: {
                // compile error = not all caught
                const t: never = type
                throw new Error(`Unknown legal entity: ${t}`)
            }
        }
    }
}

export class PreparationTime extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id!: string

    @field({decoder: NumberDecoder, optional: false})
    timeValue!: number

    @field({decoder: new EnumDecoder(PreparationTimeType), optional: false})
    type!: PreparationTimeType

    @field({decoder: new EnumDecoder(WeekDay), optional: true, nullable: true, defaultValue: () => null})
    dayValue: WeekDay | null

    @field({decoder: DateDecoder, optional: true, nullable: true, defaultValue: () => null})
    dateValue: Date | null

    @field({decoder: BooleanDecoder, defaultValue: () => false})
    active: boolean

    getLabel(i18n: i18n): string {
        return PreparationTimeTypeHelper.getLabel(this.type, i18n)
    }

    isValid(): boolean {
        let valid = true
        if (this.active) { // only validate if active
            switch (this.type) {
                case PreparationTimeType.TIME:
                    if (!this.timeValue && this.timeValue != 0) {
                        valid = false
                    }
                    break
                case PreparationTimeType.DAYTIME:
                    if ((!this.timeValue && this.timeValue != 0) || !this.dayValue) {
                        valid = false
                    }
                    break
            case PreparationTimeType.DATETIME:
                if ((!this.timeValue && this.timeValue != 0) || !this.dateValue) {
                    valid = false
                }
                break
            }
        }
        return valid
    }
}

export class ConsumptionModeAvailabilityPeriod extends AutoEncoder {
    @field({decoder: StringDecoder, defaultValue: () => uuidv4()})
    id: string
    /**
     * Starttime in minutes since start of day (60 = 1:00, 12:23 = 743)
     */
    @field({decoder: IntegerDecoder, defaultValue: () => 60 * 17})
    startTime: number // (= 17:00)
    /**
     * Endtime in minutes (exclusive) since start of day (60 = 1:00, 12:23 = 743)
     */
    @field({decoder: IntegerDecoder, defaultValue: () => 60 * 22})
    endTime: number // (= 22:00)

    // only used to build new preparationTime upgrade

    @field({
        decoder: new ArrayDecoder(PreparationTime), version: 54, defaultValue: () => {
            return ConsumptionModeAvailabilityPeriod.defaultPrepTime()
        },
    })
    preparationTime: Array<PreparationTime>

    static defaultPrepTime(options: {time?: number; id?: string; day?: WeekDay} = {}): Array<PreparationTime> {
        const time: number = options.time ?? 30
        const day: WeekDay = options.day ?? WeekDay.Monday
        const id: string = options.id ?? uuidv4()
        return [PreparationTime.create({id: `1${id.slice(1)}`, type: PreparationTimeType.TIME, timeValue: time, active: true}), PreparationTime.create({id: `2${id.slice(1)}`, type: PreparationTimeType.DAYTIME, timeValue: 720, dayValue: day, active: false}), PreparationTime.create({id: `3${id.slice(1)}`, type: PreparationTimeType.DATETIME, timeValue: 720, dateValue: null, active: false})]
    }

    isCompatibleWithOtherPeriods(periods: Array<ConsumptionModeAvailabilityPeriod>): boolean {
        periods = periods.filter(el => el.id != this.id)
        return this.startTime != this.endTime && this.isValidStartTime(periods, this.startTime) && this.isValidEndTime(periods, this.endTime)
    }

    isValidStartTime(periods: Array<ConsumptionModeAvailabilityPeriod>, time: number): boolean {
        for (const period of periods) {
            if (period.doesContinuePastMidnight()) { // if other period runs over 24:00
                if (this.doesContinuePastMidnight() || time >= period.startTime || time < period.endTime) {
                    return false
                }
            } else if (time >= period.startTime && time < period.endTime) {
                return false
            }
        }
        return true
    }

    isValidEndTime(periods: Array<ConsumptionModeAvailabilityPeriod>, time: number): boolean {
        for (const period of periods) {
            if (period.doesContinuePastMidnight()) {
                if (this.doesContinuePastMidnight() || time > period.startTime) {
                    return false
                }
            } else if (this.doesContinuePastMidnight()) {
                if (time <= period.endTime && time > period.startTime) {
                    return false
                }
            } else if (time <= period.endTime && time > period.startTime) {
                return false
            }
        }
        return true
    }

    isValid(otherPeriods: Array<ConsumptionModeAvailabilityPeriod>): boolean {
        return this.isCompatibleWithOtherPeriods(otherPeriods) && this.hasValidPreparationTime()
    }

    hasValidPreparationTime(): boolean {
        let valid = true
        for (const prepTime of this.preparationTime) {
            if (!prepTime.isValid()) {
                valid = false
                break
            }
        }
        return valid
    }

    doesContinuePastMidnight(): boolean {
        return this.endTime < this.startTime
    }
}

export const ConsumptionModeAvailabilityPeriodPatch = ConsumptionModeAvailabilityPeriod.patchType()
export const PreparationTimePatch = PreparationTime.patchType()
