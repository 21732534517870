/**
 * id: 166352,
    name: '100% Discount on product',
    visible: true,
    imageLocation: 'http://staging-integration.posios.com/posimages/MAIN/images/products/p1.png',
    kitchenImageLocation: '',
    cfdImageLocation: '',
    price: 100,
    priceWithoutVat: 100,
    takeawayPrice: 100,
    takeawayPriceWithoutVat: 100,
    deliveryPrice: 100,
    deliveryPriceWithoutVat: 100,
    productType: 'REDUCTIONONEPERCENTAGE',
    sku: 'D1',
    taxClass: 'BUILTIN-12.00',
    deliveryTaxClass: 'BUILTIN-21.00',
    takeawayTaxClass: 'BUILTIN-6.00',
    stockAmount: 0,
    groupIds: [ 63587 ],
    info: ''

    {
    id: 166350,
    name: 'Steak',
    visible: true,
    imageLocation: '',
    kitchenImageLocation: '',
    cfdImageLocation: '',
    price: 25,
    priceWithoutVat: 22.3214,
    takeawayPrice: 25,
    takeawayPriceWithoutVat: 25,
    deliveryPrice: 25,
    deliveryPriceWithoutVat: 25,
    productType: '',
    sku: 'M1',
    taxClass: 'BUILTIN-12.00',
    deliveryTaxClass: 'BUILTIN-0.00',
    takeawayTaxClass: 'BUILTIN-0.00',
    stockAmount: 0,
    groupIds: [ 63586 ],
    info: ''
  },

 */

import {ArrayDecoder, AutoEncoder, BooleanDecoder, field, IntegerDecoder, NumberDecoder, StringDecoder} from '@simonbackx/simple-encoding'

import {ConsumptionOptionType} from '../ConsumptionOptions'

export enum LightspeedProductType {
    Choice = 'CHOICE',
    ReductionOne = 'REDUCTIONONE',
    ReductionOnePercentage = 'REDUCTIONONEPERCENTAGE',
    ReductionAllCurrent = 'REDUCTIONALLCURRENT',
    ReductionAllCurrentPercentage = 'REDUCTIONALLCURRENTPERCENTAGE',
    ReductionAll = 'REDUCTIONALL',
    ReductionAllPercentage = 'REDUCTIONALLPERCENTAGE',
    Group = 'GROUP',
}

export class LAdditionValue extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    id: number

    @field({decoder: StringDecoder})
    name: string

    @field({decoder: StringDecoder, optional: true})
    info = ''

    @field({decoder: NumberDecoder, optional: true})
    price = 0

    @field({decoder: NumberDecoder, optional: true})
    priceWithoutVat = 0

    @field({decoder: StringDecoder, optional: true})
    plu = ''

    @field({decoder: BooleanDecoder, optional: true})
    default = false

    get VAT() {
        if (this.price == 0) {
            return 0
        }
        return Math.round(Math.max(0, (this.price / this.priceWithoutVat - 1) * 100))
    }
}

export class LAddition extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    id: number

    @field({decoder: StringDecoder})
    name: string

    @field({decoder: StringDecoder, optional: true})
    displayName = ''

    @field({decoder: BooleanDecoder, optional: true})
    multiselect = false

    @field({decoder: IntegerDecoder, optional: true})
    minSelectedAmount?: number

    @field({decoder: IntegerDecoder, optional: true})
    maxSelectedAmount?: number

    @field({decoder: new ArrayDecoder(LAdditionValue), optional: true, defaultValue: () => []})
    values: Array<LAdditionValue>
}

export class LProduct extends AutoEncoder {
    @field({decoder: IntegerDecoder})
    id: number

    @field({decoder: StringDecoder})
    name: string

    @field({decoder: StringDecoder, optional: true})
    info = ''

    @field({decoder: BooleanDecoder, optional: true})
    visible = true

    @field({decoder: StringDecoder, optional: true})
    imageLocation = ''

    @field({decoder: NumberDecoder, optional: true})
    price = 0

    @field({decoder: NumberDecoder, optional: true})
    priceWithoutVat = 0

    @field({decoder: NumberDecoder, optional: true})
    takeawayPrice = 0

    @field({decoder: NumberDecoder, optional: true})
    takeawayPriceWithoutVat = 0

    @field({decoder: NumberDecoder, optional: true})
    deliveryPrice = 0

    @field({decoder: NumberDecoder, optional: true})
    deliveryPriceWithoutVat = 0

    getPriceForConsumptionOptionType(type: ConsumptionOptionType): number {
        switch (type) {
            case ConsumptionOptionType.DineIn:
                return Math.round(this.price * 100)
            case ConsumptionOptionType.Delivery:
                return Math.round(this.deliveryPrice * 100)
            case ConsumptionOptionType.TakeAway:
                return Math.round(this.takeawayPrice * 100)
        }
    }

    getPriceWithoutVATForConsumptionOptionType(type: ConsumptionOptionType): number {
        switch (type) {
            case ConsumptionOptionType.DineIn:
                return Math.round(this.priceWithoutVat * 100)
            case ConsumptionOptionType.Delivery:
                return Math.round(this.deliveryPriceWithoutVat * 100)
            case ConsumptionOptionType.TakeAway:
                return Math.round(this.takeawayPriceWithoutVat * 100)
        }
    }

    @field({decoder: StringDecoder, optional: true})
    productType = ''

    @field({decoder: StringDecoder, optional: true})
    sku = ''

    @field({decoder: new ArrayDecoder(IntegerDecoder), optional: true, defaultValue: () => []})
    groupIds: Array<number>

    @field({decoder: new ArrayDecoder(LAddition), optional: true, defaultValue: () => []})
    additions: Array<LAddition>

    getAdditionFromId(id: number) {
        for (const addition of this.additions) {
            if (addition.id == id) {
                return addition
            }
        }
        return null
    }

    getVATForConsumptionOptionType(type: ConsumptionOptionType) {
        const price = this.getPriceForConsumptionOptionType(type)
        const priceWithoutVat = this.getPriceWithoutVATForConsumptionOptionType(type)
        if (price == 0 || priceWithoutVat == 0) {
            return 0
        }
        return Math.round(Math.max(0, (price / priceWithoutVat - 1) * 100))
    }

    get shouldImport() {
        return this.name !== 'SPACER'
            && this.name !== 'LINE'
            && this.productType !== LightspeedProductType.Choice
            && this.productType !== LightspeedProductType.ReductionOne
            && this.productType !== LightspeedProductType.ReductionOnePercentage
            && this.productType !== LightspeedProductType.ReductionAllCurrent
            && this.productType !== LightspeedProductType.ReductionAllCurrentPercentage
            && this.productType !== LightspeedProductType.Group
            && this.productType !== LightspeedProductType.ReductionAll
            && this.productType !== LightspeedProductType.ReductionAllPercentage
    }

    get isDiscount() {
        // Two types of discounts that we consider to be valid discounts
        return (
            this.productType === LightspeedProductType.ReductionAll
            || this.productType === LightspeedProductType.ReductionAllPercentage
        )
    }
}
