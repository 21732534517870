import {ArrayDecoder, AutoEncoder, BooleanDecoder, field, StringDecoder} from '@simonbackx/simple-encoding'

import {POSSystemType} from './POSSystemLink'
import {CloudPosProduct} from './cloudpos/CloudPosProduct'
import {DeliverectProduct} from './deliverect/DeliverectProduct'
import {DIProduct} from './digitin/DigitinProduct'
import {LProduct} from './lightspeed/LProduct'
import {UProduct} from './untill/UProduct'

export class VariantSyncedFields extends AutoEncoder {
    @field({decoder: StringDecoder})
    id: string

    @field({decoder: BooleanDecoder, optional: true})
    name = true

    @field({decoder: BooleanDecoder, optional: true})
    enabled = true
}

export class OptionSyncedFields extends VariantSyncedFields {
}

export class ProductSyncedFields extends AutoEncoder {
    @field({decoder: BooleanDecoder, optional: true})
    availabilityPeriods = true

    @field({decoder: BooleanDecoder, optional: true})
    name = true

    @field({decoder: BooleanDecoder, optional: true})
    description = true

    @field({decoder: BooleanDecoder, optional: true})
    enabled = true

    @field({decoder: BooleanDecoder, optional: true})
    images = true

    @field({decoder: BooleanDecoder, optional: true})
    VAT = true

    @field({decoder: BooleanDecoder, optional: true})
    categoryId = true

    @field({decoder: BooleanDecoder, optional: true})
    preparationLocationId = true

    @field({decoder: BooleanDecoder, optional: true})
    recipe = true

    @field({decoder: BooleanDecoder, optional: true})
    templateId = true

    @field({decoder: new ArrayDecoder(VariantSyncedFields), optional: true})
    syncedVariants: Array<VariantSyncedFields> = []

    @field({decoder: new ArrayDecoder(OptionSyncedFields), optional: true})
    syncedOptions: Array<OptionSyncedFields> = []

    static getFieldTranslationKey(field: string) {
        // cast to get auto-suggested cases
        switch (field as
            Exclude<keyof ProductSyncedFields, keyof AutoEncoder>
            | 'syncedVariants.name'
            | 'syncedVariants.enabled'
            | 'syncedOptions.name'
            | 'syncedOptions.enabled') {
            case 'availabilityPeriods':
                return 'common.fields.products.posSync.availabilityPeriods'
            case 'categoryId':
                return 'common.fields.products.posSync.categoryId'
            case 'description':
                return 'common.fields.products.posSync.description'
            case 'enabled':
                return 'common.fields.products.posSync.enabled'
            case 'images':
                return 'common.fields.products.posSync.images'
            case 'name':
                return 'common.fields.products.posSync.name'
            case 'preparationLocationId':
                return 'common.fields.products.posSync.preparationLocationId'
            case 'recipe':
                return 'common.fields.products.posSync.recipe'
            case 'templateId':
                return 'common.fields.products.posSync.templateId'
            case 'VAT':
                return 'common.fields.products.posSync.vat'
            case 'syncedVariants.name':
                return 'common.fields.products.posSync.variants.name'
            case 'syncedVariants.enabled':
                return 'common.fields.products.posSync.variants.enabled'
            case 'syncedOptions.name':
                return 'common.fields.products.posSync.options.name'
            case 'syncedOptions.enabled':
                return 'common.fields.products.posSync.options.enabled'
            default:
                console.error(`No translation found for field "${field}" in ProductSyncedFields`)
                return ''
        }
    }
}

export class TBProductMeta extends AutoEncoder {
    @field({decoder: StringDecoder, nullable: true, optional: true})
    itemId: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    categoryId: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    navisionId: string | null = null

    @field({decoder: StringDecoder, nullable: true, optional: true})
    image: string | null = null

    get uniqueId() {
        return `${this.categoryId ?? ''}_${this.itemId ?? ''}`
    }
}

export class ProductMeta extends AutoEncoder {
    @field({decoder: ProductSyncedFields, optional: true})
    syncedFields = ProductSyncedFields.create({})

    @field({decoder: LProduct, nullable: true, optional: true})
    lightspeedL: LProduct | null = null

    @field({decoder: TBProductMeta, version: 69, nullable: true, optional: true, upgrade: () => null})
    topBrands: TBProductMeta | null = null

    @field({decoder: UProduct, version: 79, nullable: true, optional: true})
    untill: UProduct | null = null

    @field({decoder: DIProduct, version: 79, nullable: true, optional: true})
    digitin: DIProduct | null = null

    @field({decoder: CloudPosProduct, nullable: true, optional: true})
    cloudpos: CloudPosProduct | null = null

    @field({decoder: DeliverectProduct, nullable: true, optional: true})
    deliverect: DeliverectProduct | null = null

    get isExternal(): boolean {
        return this.posType !== undefined
    }

    get posType(): POSSystemType | undefined {
        if (this.digitin !== null) {
            return POSSystemType.Digitin
        } else if (this.lightspeedL !== null) {
            return POSSystemType.Lightspeed
        } else if (this.untill !== null) {
            return POSSystemType.Untill
        } else if (this.cloudpos !== null) {
            return POSSystemType.CloudPos
        } else if (this.topBrands != null) {
            return POSSystemType.TopBrands
        } else if (this.deliverect != null) {
            return POSSystemType.Deliverect
        }
        return
    }

    productBelongsToPosSystem(posSystemType: POSSystemType): boolean {
        return this.posType === posSystemType
    }
}

export class TBVariantMeta extends AutoEncoder {
    @field({decoder: StringDecoder, optional: true})
    priceGroup2 = ''

    @field({decoder: StringDecoder, nullable: true, optional: true})
    navisionId: string | null = null
}

export class VariantMeta extends AutoEncoder {
    @field({decoder: TBVariantMeta, nullable: true, optional: true})
    topBrands: TBVariantMeta | null = null
}

export class TBOptionGroupMeta extends AutoEncoder {
    @field({decoder: StringDecoder, optional: true})
    priceGroup2 = ''

    @field({decoder: StringDecoder, nullable: true, optional: true})
    categoryId: string | null = null
}

export class OptionGroupMeta extends AutoEncoder {
    @field({decoder: TBOptionGroupMeta, nullable: true, optional: true})
    topBrands: TBOptionGroupMeta | null = null
}
