import {ArrayDecoder, AutoEncoder, field, StringDecoder} from '@simonbackx/simple-encoding'

import {Address} from '../Address'

export class GisAutocompleteRequest extends AutoEncoder {
    @field({decoder: StringDecoder})
    query: string

    @field({decoder: StringDecoder})
    country = 'BE'

    @field({decoder: StringDecoder, optional: true})
    session?: string
}

export class GisAutocompleteResult extends AutoEncoder {
    @field({decoder: StringDecoder})
    description: string

    @field({decoder: StringDecoder})
    placeId: string
}

export class GisAutocompleteResponse extends AutoEncoder {
    @field({decoder: new ArrayDecoder(GisAutocompleteResult)})
    results: Array<GisAutocompleteResult>

    @field({decoder: StringDecoder, optional: true})
    session?: string
}

export class GisAutocompleteDetailRequest extends AutoEncoder {
    @field({decoder: StringDecoder})
    placeId: string

    @field({decoder: StringDecoder, optional: true})
    session?: string
}

export class GisAutocompleteDetailResponse extends AutoEncoder {
    @field({decoder: Address})
    address: Address

    @field({decoder: StringDecoder, optional: true})
    session?: string
}
