var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "drst-navigation-bar",
      class: {
        scrolled: _vm.scrolled,
        sticky: _vm.sticky,
        "show-title": _vm.showTitle
      }
    },
    [
      _c("div", [_vm._t("left")], 2),
      _vm._v(" "),
      _vm._t("middle", [
        _c("h1", [_vm._v("\n            " + _vm._s(_vm.title) + "\n        ")])
      ]),
      _vm._v(" "),
      _c("div", [_vm._t("right")], 2)
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }